/**
 *
 * Api Modify Token
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/money.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/create-request.svg';
import '!file-loader?name=[name].[ext]!../../../images/refund.svg';
import '!file-loader?name=[name].[ext]!../../../images/transaction-notification.svg';
import styled from 'styled-components';
import {
  PageTitle,
  Container,
  ToggleTile,
  CaptionSmall,
  Paragraph,
  Button,
  InputTile,
  Toggle,
  Header4,
} from 'amber';
import messages from './messages';
import {
  ContainerBox,
  ContainerRowExplanation,
  ImageWrapperExplanation,
  ImageExplanation,
  ExplanationText,
  Header2Card,
  Header2Manual,
  FooterContent,
  FooterWrapper,
  ErrorPageWithContact,
  BackButton,
} from '../styledComponents';
import { Color, FontSize, Radii, Space } from '../../styleguide';
import { analyticsPageview } from '../../../ga4';

const Tile = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  box-sizing: border-box;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  background-color: ${Color.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  flex: 0 0 100%;
  border-radius: ${Radii.s8};
  padding: ${Space.s24};
  color: ${Color.gray01};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded;
`;

const ToggleExplanation = styled.div`
  width: 25%;
  display: inline-block;
  height: 100%;
`;

const Toggles = styled.div`
  width: 70%;
  display: inline-block;
  height: 100%;
`;
const ToggleDescr = styled.div`
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded;
  margin: 0 0 ${Space.s32} ${Space.s72};
  padding-top: ${Space.s8};
`;

const ToggleCaption = styled(CaptionSmall)`
  margin-top: ${Space.s8} !important;
`;

/* eslint-disable react/prefer-stateless-function */
export class ApiModifyToken extends React.Component {
  constructor(props) {
    super(props);
    const { apiToken } = this.props;
    this.state = {
      paymentRequestRight: apiToken.rights.paymentRequestRight,
      refundRight: apiToken.rights.refundRight,
      transactionDetailsRight: apiToken.rights.transactionDetailsRight,
      cashbackRight: apiToken.rights.cashbackRight,
      name: apiToken.name,
      apiToken: apiToken.appToken,
      enabled: apiToken.enabled,
      stateChanged: false,
    };
    this.props.loadApiTokens();
  }

  componentDidMount() {
    analyticsPageview();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.modifyApiTokenSuccess !== prevProps.modifyApiTokenSuccess &&
      this.props.modifyingApiToken === false
    ) {
      this.props.updateApiTokenState();
    }
  }

  handleFieldChange = (fieldId, value) => {
    this.setState({ [fieldId]: value });
    this.setState({ stateChanged: true });
  };

  handleEnabledChange = () => {
    this.setState((prevState) => ({
      enabled: !prevState.enabled,
      stateChanged: true,
    }));
  };

  showCashbackOption = () => this.props.cashbackAllowed;

  modifyApiToken = () => {
    const rights = {
      paymentRequestRight: this.state.paymentRequestRight,
      refundRight: this.state.refundRight,
      transactionDetailsRight: this.state.transactionDetailsRight,
      cashbackRight: this.state.cashbackRight,
    };
    this.props.modifyApiToken(
      this.state.enabled,
      this.state.name,
      rights,
      this.props.apiToken.appToken,
    );
    this.setState({ stateChanged: false });
  };

  cancel = () => {
    const { apiToken } = this.props;
    this.setState({
      paymentRequestRight: apiToken.rights.paymentRequestRight,
      refundRight: apiToken.rights.refundRight,
      transactionDetailsRight: apiToken.rights.transactionDetailsRight,
      cashbackRight: apiToken.rights.cashbackRight,
      name: apiToken.name,
      apiToken: apiToken.appToken,
      enabled: apiToken.enabled,
      stateChanged: false,
    });
  };

  setRights = (right) => {
    switch (right) {
      case 'PaymentRequestRight': {
        this.setState((prevState) => ({
          paymentRequestRight: !prevState.paymentRequestRight,
          stateChanged: true,
        }));
        break;
      }
      case 'RefundRight': {
        this.setState((prevState) => ({
          refundRight: !prevState.refundRight,
          stateChanged: true,
        }));
        break;
      }
      case 'TransactionDetailsRight': {
        this.setState((prevState) => ({
          transactionDetailsRight: !prevState.transactionDetailsRight,
          stateChanged: true,
        }));
        break;
      }
      case 'CashbackRight': {
        this.setState((prevState) => ({
          cashbackRight: !prevState.cashbackRight,
          stateChanged: true,
        }));
        break;
      }
      default:
        break;
    }
  };

  disableButton = () => !this.state.name;

  render() {
    const { goToApiOverview } = this.props;
    const { stateChanged } = this.state;

    if (this.props.modifyApiTokenError) {
      return ErrorPageWithContact({
        pageTitle: messages.headerNew.defaultMessage,
        onClickBackOverview: goToApiOverview,
        errorType: 'ModifyToken',
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={goToApiOverview}
          text={messages.terug.defaultMessage}
        />
        <PageTitle>{messages.headerNew.defaultMessage}</PageTitle>
        <Container row mb={24}>
          <InputTile
            title={messages.descriptionTitle.defaultMessage}
            desc={messages.descriptionTextApiToken.defaultMessage}
            value={this.state.name}
            maxLength={100}
            onChange={this.handleFieldChange}
            id="name"
          />
        </Container>
        <Container row mb={24}>
          <InputTile
            title={messages.descriptionToken.defaultMessage}
            desc={messages.descriptionTextToken.defaultMessage}
            value={this.state.apiToken}
            disabled
          />
        </Container>
        <Container row mb={24}>
          <Tile>
            <ToggleExplanation>
              <Header4>Mogelijkheden</Header4>
              <ToggleCaption>Wat moet de token kunnen?</ToggleCaption>
            </ToggleExplanation>
            <Toggles>
              <Toggle
                onClick={() => this.setRights('PaymentRequestRight')}
                on={this.state.paymentRequestRight}
              />
              <ToggleDescr>Aanmaken betaalverzoekjes</ToggleDescr>
              <Toggle
                onClick={() => this.setRights('RefundRight')}
                on={this.state.refundRight}
              />
              <ToggleDescr>Terugbetaling betaalde verzoekjes </ToggleDescr>
              <Toggle
                onClick={() => this.setRights('TransactionDetailsRight')}
                on={this.state.transactionDetailsRight}
              />
              <ToggleDescr>
                Notificatie van gebundelde uitbetalingen{' '}
              </ToggleDescr>
              {this.showCashbackOption() && (
                <>
                  <Toggle
                    onClick={() => this.setRights('CashbackRight')}
                    on={this.state.cashbackRight}
                  />
                  <ToggleDescr>Tikkie Terug (geldterugacties) </ToggleDescr>
                </>
              )}
            </Toggles>
          </Tile>
        </Container>
        <Container row mb={24}>
          <ToggleTile
            title={messages.active.defaultMessage}
            desc={messages.descriptionTextActive.defaultMessage}
            on={this.state.enabled}
            onClick={this.handleEnabledChange}
          />
        </Container>
        <Container row mb={24} />
        <Header2Manual>
          <FormattedMessage {...messages.manual} />
        </Header2Manual>
        <ContainerBox>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle1} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText1} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/create-request.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/refund.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle2} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText2} />
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle3} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText3} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/transaction-notification.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          {this.showCashbackOption() && (
            <ContainerRowExplanation>
              <>
                <ImageWrapperExplanation>
                  <ImageExplanation>
                    <img src="/cashback-api.png" alt="" />
                  </ImageExplanation>
                </ImageWrapperExplanation>
                <ExplanationText>
                  <Header2Card>
                    <FormattedMessage {...messages.manualTitle5} />
                  </Header2Card>
                  <Paragraph>
                    <FormattedMessage {...messages.manualText5} />
                  </Paragraph>
                </ExplanationText>
              </>
              {/* )} */}
            </ContainerRowExplanation>
          )}
        </ContainerBox>
        <FooterWrapper slideIn={stateChanged}>
          {stateChanged && (
            <FooterContent>
              <Button type="secondary" onClick={this.cancel}>
                <FormattedMessage {...messages.cancelButton} />
              </Button>
              <Button
                type="primary"
                dataTestId="Api-SaveButton"
                onClick={this.modifyApiToken}
                disabled={this.disableButton()}
              >
                <FormattedMessage {...messages.saveButton} />
              </Button>
            </FooterContent>
          )}
        </FooterWrapper>
      </Container>
    );
  }
}

ApiModifyToken.propTypes = {
  gotoPortalOverview: PropTypes.func,
  gotoSettingsDashboard: PropTypes.func,
  goToApiOverview: PropTypes.func,
  settingsData: PropTypes.object.isRequired,
  apiTokens: PropTypes.array.isRequired,
  loadApiTokens: PropTypes.func.isRequired,
  apiTokensLoading: PropTypes.bool.isRequired,
  apiTokensLoadingSuccess: PropTypes.bool.isRequired,
  modifyApiToken: PropTypes.func.isRequired,
  modifyApiTokenError: PropTypes.object,
  apiToken: PropTypes.object.isRequired,
  modifyingApiToken: PropTypes.bool,
  modifyApiTokenSuccess: PropTypes.bool,
  updateApiTokenState: PropTypes.func.isRequired,
  cashbackAllowed: PropTypes.bool.isRequired,
};

export default injectIntl(ApiModifyToken);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Header2, CaptionSmall, Space, Color } from '../../styleguide';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => (props.expand ? 1 : 0)};
  padding: ${(props) => (props.padding ? `0 ${Space.s24}` : '')};
  border-left: ${(props) => (props.border ? `2px solid ${Color.gray05}` : '')};

  @media ${(props) => props.theme.device.mobile} {
    border-left: none;
    padding: 0px 0px ${Space.s16} 0px;
    margin-bottom: ${Space.s16};
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const Text = styled(Header2)`
  margin-top: 8px;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    white-space: normal;
    overflow: auto;
    text-overflow: clip;
    word-break: break-word;
    font-size: 18px;
  }
`;

const TextSmall = styled(Text)`
  font-size: 16px;
`;

function PaymentRequestDetailItem(props) {
  return (
    <Item border={props.border} padding={props.padding} expand={props.expand}>
      <CaptionSmall>{props.label}</CaptionSmall>
      {props.big && <Text data-test-id={props.dataTestId}>{props.value}</Text>}
      {!props.big && (
        <TextSmall data-test-id={props.dataTestId}>
          {props.value}
          {props.children}
        </TextSmall>
      )}
    </Item>
  );
}

PaymentRequestDetailItem.propTypes = {
  label: PropTypes.object,
  value: PropTypes.any,
  border: PropTypes.bool,
  padding: PropTypes.bool,
  dataTestId: PropTypes.string,
  expand: PropTypes.bool,
  big: PropTypes.bool,
};

export default PaymentRequestDetailItem;

import '!file-loader?name=[name].[ext]!./images/close-icon.svg';

import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TitleWrapper = styled.div`
  padding: ${(props) => props.padding || 'none'};
  display: flex;
  justify-content: space-between;
`;

const TitleStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledTitle = styled.div`
  margin-top: 0px;
  margin-right: 24px;
`;

export const StatusSmallWrapper = styled.div`
  min-width: 90px;
  max-height: 50px;
`;

export const Status = styled.div`
  border-radius: 40px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#fdf9ec'};
  color: ${(props) => (props.color ? props.color : '#f1c347')};
  font-family: MuseoSansRounded700;
  font-size: 16px;
  margin-right: 16px;
  margin-left: ${(props) => (props.ml ? props.ml : '0px')};
  padding: 8px 13px 9px 14px;
`;

const Close = styled.div`
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  user-select: none;
  float: right;
  margin: 0px;

  img {
    width: 20px;
    height: 20px;
  }

  @media ${(props) => props.theme.device.mobile} {
    img {
      height: 16px;
      width: 16px;
    }
  }
`;

const CloseButtonWrapper = styled.img`
  height: 20px;
  cursor: pointer;
`;

const CloseButton = (props) => (
  <CloseButtonWrapper src={props.src} onClick={props.onClick} />
);

CloseButton.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ModalClose = (props) => (
  <Close>
    <CloseButton src="/close-icon.svg" onClick={props.onClick} />
  </Close>
);

ModalClose.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: #000000;
  opacity: 0.3;
`;

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  z-index: 501;
  border-radius: 8px;
  box-shadow: 0 2px 110px 0 rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.background || 'white'};
  padding: ${(props) => props.padding || '40px'};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 'inherit')};
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
`;

export const ModalTitle = (props) => (
  <TitleWrapper padding={props.padding}>
    <TitleStatusWrapper>
      <StyledTitle>{props.title}</StyledTitle>
      {props.status && (
        <StatusSmallWrapper>
          <Status>{props.status}</Status>
        </StatusSmallWrapper>
      )}
    </TitleStatusWrapper>
    {props.onClose && <ModalClose onClick={props.onClose} />}
  </TitleWrapper>
);

ModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  onClose: PropTypes.func,
  padding: PropTypes.string,
};

const ScrollBox = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const Modal = (props) => (
  <Fragment>
    <Overlay onClick={props.onClose} />
    <ModalWrapper
      fixed={props.stayInPosition}
      padding={props.padding}
      background={props.background}
      width={props.width}
      marginTop={props.marginTop}
    >
      <ModalTitle
        title={props.title}
        onClose={props.onClose}
        status={props.status}
        padding={props.titlePadding}
      />
      <ScrollBox>{props.children}</ScrollBox>
    </ModalWrapper>
  </Fragment>
);

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  children: PropTypes.any,
  stayInPosition: PropTypes.bool,
  padding: PropTypes.string,
  marginTop: PropTypes.string,
  titlePadding: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
};

export const Label = styled.div`
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: #57565b;
  margin-left: 16px;
  width: ${(props) => (props.width ? `${props.width}%` : '')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';

import FormWrapperContainer from '../FormWrapperContainer';
import ContactPersonUbo from '../../../components/OnboardingKYC/UBO/ContactPersonUbo';
import { otherUboSteps, overallSteps, actions } from './constants';
import {
  selectContactData,
  selectPendingUbo,
  selectUboDetailsList,
  selectPossibleAddresses,
  selectSubmitPartialAddressError,
  selectOrganisationName,
  selectProductType,
} from '../selectors';
import {
  submitUbo,
  startAddUbo,
  startEditUbo,
  setUboName,
  setUboBirthDate,
  setUboCountry,
  clearUboAddress,
  setUboAddress,
  submitPartialUboAddress,
} from '../actions';
import UboOverview from '../../../components/OnboardingKYC/UBO/UboOverview';
import ContactName from '../../../components/OnboardingKYC/ContactName';
import BirthDate from '../../../components/OnboardingKYC/BirthDate';
import ChooseCountry from '../../../components/OnboardingKYC/ChooseCountry';
import AddressInput from '../../../components/OnboardingKYC/AddressInput';
import addressSteps from '../../../components/OnboardingKYC/AddressInput/constants';
import UboInvolvement from '../../../components/OnboardingKYC/UBO/Involvement';
import { getFullName } from '../../../utils';

const otherUboStepsArr = [
  otherUboSteps.name,
  otherUboSteps.birthDate,
  otherUboSteps.country,
  otherUboSteps.address,
  otherUboSteps.involvement,
];
const addressStepsArr = [addressSteps.address, addressSteps.selectAddress];

function UboContainer(props) {
  const {
    getNextStep,
    getPreviousStep,
    contactPersonData,
    submit,
    pendingUbo,
    uboDetailsList,
    startNewUboFlow,
    startEditUboFlow,
    setName,
    setBirthDate,
    setCountry,
    clearAddress,
    setAddress,
    submitPartialAddress,
    possibleAddresses,
    submitPartialAddressError,
    organisationName,
    productType,
  } = props;

  const [currentOverallStep, setCurrentOverallStep] = useState(
    overallSteps.startScreen,
  );
  const goToStartScreen = () => setCurrentOverallStep(overallSteps.startScreen);
  const goToOtherUbo = () => {
    setCurrentOtherUboStep(otherUboSteps.name);
    setCurrentOverallStep(overallSteps.otherUbo);
  };
  const goToContactUbo = () => {
    startNewUboFlow();
    setCurrentOverallStep(overallSteps.contactPersonUbo);
  };

  const prevContactUboStep = () =>
    setCurrentOverallStep(overallSteps.startScreen);

  const [currentOtherUboStep, setCurrentOtherUboStep] = useState(
    otherUboSteps.name,
  );
  const nextOtherUboStep = () =>
    setCurrentOtherUboStep(getNextStep(otherUboStepsArr, currentOtherUboStep));
  const prevOtherUboStep = () =>
    setCurrentOtherUboStep(
      getPreviousStep(otherUboStepsArr, currentOtherUboStep),
    );

  const [currentDutchAddressStep, setCurrentDutchAddressStep] = useState(
    addressSteps.address,
  );
  const nextDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getNextStep(addressStepsArr, currentDutchAddressStep),
    );
  const prevDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getPreviousStep(addressStepsArr, currentDutchAddressStep),
    );

  const dutchAddress = pendingUbo.address.countryCode === 'NL';

  const onStartNewUbo = () => {
    startNewUboFlow();
    setCurrentDutchAddressStep(addressSteps.address);
    goToOtherUbo();
  };

  const onStartEdit = (ubo) => {
    startEditUboFlow(ubo);
    setCurrentDutchAddressStep(addressSteps.address);
    goToOtherUbo();
  };

  const contactPersonAsUbo = (involvement) => ({
    firstName: contactPersonData.firstName || 'extract-from-ID',
    lastName: contactPersonData.lastName || 'extract-from-ID',
    birthDate: contactPersonData.birthDate || '0001-01-01',
    address: contactPersonData.address,
    contactPerson: true,
    involvement,
  });

  const getPendingUbo = () => ({
    id: pendingUbo.id ? pendingUbo.id : null,
    firstName: pendingUbo.firstName,
    lastNamePrefix: pendingUbo.lastNamePrefix,
    lastName: pendingUbo.lastName,
    birthDate: pendingUbo.birthDate,
    address: pendingUbo.address,
    involvement: pendingUbo.involvement,
  });

  const addUbo = (ubo) => {
    submit(actions.addUbo, ubo);
  };

  const addContactPersonAsUbo = (involvement) => {
    addUbo(contactPersonAsUbo(involvement));
  };

  const addNewUbo = (involvement) => {
    const pendingUboWithInvolvement = {
      ...getPendingUbo(),
      involvement,
    };
    addUbo(pendingUboWithInvolvement);
  };

  const editUbo = (involvement) => {
    const pendingUboWithInvolvement = {
      ...getPendingUbo(),
      involvement,
    };
    submit(actions.editUbo, pendingUboWithInvolvement);
  };

  const deleteUbo = (ubo) => {
    submit(actions.deleteUbo, ubo);
  };

  const stopUboFlow = () => {
    submit(actions.stopUboFlow);
  };

  const addUboNameAndGoNext = (nameData) => {
    setName(nameData);
    nextOtherUboStep();
  };

  const addUboBirthDateAndGoNext = (birthDate) => {
    setBirthDate(birthDate);
    nextOtherUboStep();
  };

  const addUboCountryAndGoNext = (country) => {
    setCountry(country);
    nextOtherUboStep();
  };

  const addUboAddressAndGoNext = (address) => {
    setAddress(address);
    nextOtherUboStep();
  };

  const fullNamePendingUbo = () => getFullName(pendingUbo);

  return (
    <FormWrapperContainer portalBacking>
      {currentOverallStep === overallSteps.startScreen && (
        <>
          {isEmpty(uboDetailsList) && (
            <ContactPersonUbo
              goToContactUbo={goToContactUbo}
              onStartNewUbo={onStartNewUbo}
              skipUBO={stopUboFlow}
              productType={productType}
            />
          )}
          {!isEmpty(uboDetailsList) && (
            <UboOverview
              contactPersonNickName={contactPersonData.nickName}
              uboDetailsList={uboDetailsList}
              stopUboFlow={stopUboFlow}
              onStartNewUbo={onStartNewUbo}
              getFullName={getFullName}
              onStartEdit={onStartEdit}
              deleteUbo={deleteUbo}
            />
          )}
        </>
      )}
      {currentOverallStep === overallSteps.contactPersonUbo && (
        <UboInvolvement
          saveAsUbo={addContactPersonAsUbo}
          prevStep={prevContactUboStep}
          organisationName={organisationName}
          submitted={!pendingUbo.add && !pendingUbo.edit}
          nextStep={goToStartScreen}
          contactPersonAsUbo
        />
      )}
      {currentOverallStep === overallSteps.otherUbo && (
        <>
          {currentOtherUboStep === otherUboSteps.name && (
            <ContactName
              ubo
              onClickBack={goToStartScreen}
              setContactName={addUboNameAndGoNext}
              pendingNames={pendingUbo}
            />
          )}
          {currentOtherUboStep === otherUboSteps.birthDate && (
            <BirthDate
              birthDate={pendingUbo.birthDate}
              setBirthDate={setBirthDate}
              submit={() => addUboBirthDateAndGoNext(pendingUbo.birthDate)}
              ubo
              onClickBack={prevOtherUboStep}
              uboFullName={fullNamePendingUbo()}
            />
          )}
          {currentOtherUboStep === otherUboSteps.country && (
            <ChooseCountry
              saveCountry={addUboCountryAndGoNext}
              selectedCountry={pendingUbo.address.countryCode}
              ubo
              onClickBack={prevOtherUboStep}
              uboFullName={fullNamePendingUbo()}
              clearAddress={clearAddress}
            />
          )}
          {currentOtherUboStep === otherUboSteps.address && (
            <AddressInput
              submitPartialAddress={submitPartialAddress}
              addressDetails={pendingUbo.address}
              possibleAddresses={possibleAddresses}
              submitAddress={addUboAddressAndGoNext}
              notFoundError={submitPartialAddressError}
              dutchAddress={dutchAddress}
              nextDutchAddressStep={nextDutchAddressStep}
              prevDutchAddressStep={prevDutchAddressStep}
              currentDutchAddressStep={currentDutchAddressStep}
              ubo
              uboFullName={fullNamePendingUbo()}
              uboPrevStep={prevOtherUboStep}
            />
          )}
          {currentOtherUboStep === otherUboSteps.involvement && (
            <UboInvolvement
              saveAsUbo={pendingUbo.edit ? editUbo : addNewUbo}
              prevStep={prevOtherUboStep}
              organisationName={organisationName}
              uboFullName={fullNamePendingUbo()}
              submitted={!pendingUbo.add && !pendingUbo.edit}
              nextStep={goToStartScreen}
              involvement={pendingUbo.involvement}
            />
          )}
        </>
      )}
    </FormWrapperContainer>
  );
}

UboContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,

  contactPersonData: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  pendingUbo: PropTypes.object.isRequired,
  uboDetailsList: PropTypes.array.isRequired,
  startNewUboFlow: PropTypes.func.isRequired,
  startEditUboFlow: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setBirthDate: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  clearAddress: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,

  submitPartialAddress: PropTypes.func.isRequired,
  possibleAddresses: PropTypes.array.isRequired,
  submitPartialAddressError: PropTypes.bool.isRequired,

  organisationName: PropTypes.string.isRequired,

  productType: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  contactPersonData: selectContactData,
  pendingUbo: selectPendingUbo,
  uboDetailsList: selectUboDetailsList,
  possibleAddresses: selectPossibleAddresses,
  submitPartialAddressError: selectSubmitPartialAddressError,
  organisationName: selectOrganisationName,
  productType: selectProductType,
});

const mapDispatchToProps = (dispatch) => ({
  submit: (action, ubo) => dispatch(submitUbo(action, ubo)),
  startNewUboFlow: () => dispatch(startAddUbo()),
  startEditUboFlow: (ubo) => dispatch(startEditUbo(ubo)),
  setName: (nameData) => dispatch(setUboName(nameData)),
  setBirthDate: (birthDate) => dispatch(setUboBirthDate(birthDate)),
  setCountry: (country) => dispatch(setUboCountry(country)),
  clearAddress: () => dispatch(clearUboAddress()),
  setAddress: (address) => dispatch(setUboAddress(address)),
  submitPartialAddress: (address) => dispatch(submitPartialUboAddress(address)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(UboContainer);

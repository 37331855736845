/* eslint-disable jsx-a11y/anchor-is-valid */
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  blokkieTheme,
  BlokkieTypography,
  BlokkieTextLink,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import '!file-loader?name=[name].[ext]!../../../images/idv-qr-help.png';
import '!file-loader?name=[name].[ext]!../../../images/curved-arrow.svg';
import '!file-loader?name=[name].[ext]!../../../images/scan-qr-icon.png';
import { CenterWrapper } from '../styledComponents';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IDVQRCodeHelpProps {
  prevStep: () => void;
}

function IDVQRCodeHelp({ prevStep }: IDVQRCodeHelpProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  useOnboardingPageView('idv-qr-code-help');

  return (
    <>
      <NavigationHeader clickLeft={prevStep as () => void} noMarginBottom />
      <CenterWrapper>
        <div>
          <img className={classes.left} src="/scan-qr-icon.png" alt="" />
          <img className={classes.left} src="/curved-arrow.svg" alt="" />
          <img className={classes.image} src="/idv-qr-help.png" alt="" />
        </div>
        <Title textAlign="center">{t(`onboarding.idv.QRCodeHelp.title`)}</Title>
        <BlokkieTextLink
          href={`mailto:${BUSINESS_EMAIL_ADDRESS}`}
          variant="pl700"
          underline
          style={{
            color: blokkieTheme.colors.primary.green,
            marginTop: blokkieTheme.spacing(2),
          }}
        >
          {t(`onboarding.idv.openApp.getHelp`)}
        </BlokkieTextLink>
      </CenterWrapper>
    </>
  );
}

const useStyles = createUseStyles({
  left: {
    float: 'left',
  },
  image: {
    marginTop: -blokkieTheme.spacing(6),
  },
});

export default compose(injectIntl)(IDVQRCodeHelp);

/*
 * ResetPassword Messages
 *
 * This contains all the text for the ResetPassword component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  enterNewPassword: {
    id: 'app.components.ResetPassword.enterNewPassword',
    defaultMessage: 'Enter your new password',
  },
  enterNewPasswordFirstPw: {
    id: 'app.components.ResetPassword.enterNewPasswordFirstPw',
    defaultMessage: 'kies een wachtwoord 🤓',
  },
  verifyNewPassword: {
    id: 'app.components.ResetPassword.verifyNewPassword',
    defaultMessage: 'Verify your new password',
  },
  enterNewPasswordPlaceholder: {
    id: 'app.components.ResetPassword.enterNewPasswordPlaceholder',
    defaultMessage: 'password',
  },
  resetPasswordNextButton: {
    id: 'app.components.ResetPassword.resetPasswordNextButton',
    defaultMessage: 'Next',
  },
  passwordUppercase: {
    id: 'app.components.ResetPassword.passwordUppercase',
    defaultMessage: '1 uppercase',
  },
  passwordLowercase: {
    id: 'app.components.ResetPassword.passwordLowercase',
    defaultMessage: '1 lowercase',
  },
  passwordNumber: {
    id: 'app.components.ResetPassword.passwordNumber',
    defaultMessage: '1 number',
  },
  passwordSpecial: {
    id: 'app.components.ResetPassword.passwordSpecial',
    defaultMessage: '1 special character (no space)',
  },
  passwordLength: {
    id: 'app.components.ResetPassword.passwordLength',
    defaultMessage: '8 characters',
  },
  passwordMatches: {
    id: 'app.components.ResetPassword.passwordMatches',
    defaultMessage: 'Passwords match',
  },
  passwordHasWeirdCharactersError: {
    id: 'app.components.ResetPassword.passwordHasWeirdCharactersError',
    defaultMessage: 'Oops, only these characters are allowed: -!@#$%^&+=',
  },
  passwordHasBeenUsedError: {
    id: 'app.components.ResetPassword.passwordHasBeenUsedError',
    defaultMessage: 'This password was used in the past',
  },
  expiryErrorTitle: {
    id: 'app.components.ResetPassword.expiryErrorTitle',
    defaultMessage: 'Reset link expired!',
  },
  expiryErrorText: {
    id: 'app.components.ResetPassword.expiryErrorText',
    defaultMessage:
      'Your reset link has expired. Request a new reset link to reset your password.',
  },
  expiryErrorButton: {
    id: 'app.components.ResetPassword.expiryErrorButton',
    defaultMessage: 'Send me a new link',
  },
  expiryErrorTitleFirstPw: {
    id: 'app.components.ResetPassword.expiryErrorTitleFirstPw',
    defaultMessage: '✉️ Oops...',
  },
  expiryErrorTextFirstPw: {
    id: 'app.components.ResetPassword.expiryErrorTextFirstPw',
    defaultMessage: 'Deze link is verlopen.',
  },
  expiryErrorButtonFirstPw: {
    id: 'app.components.ResetPassword.expiryErrorButtonFirstPw',
    defaultMessage: 'Stuur een nieuwe link',
  },
  resetPasswordSuccessTitle: {
    id: 'app.components.ResetPassword.resetPasswordSuccessTitle',
    defaultMessage: 'Done!',
  },
  resetPasswordSuccessText: {
    id: 'app.components.ResetPassword.resetPasswordSuccessText',
    defaultMessage: 'We updated your password, you can now log in.',
  },
  resetPasswordSuccessButton: {
    id: 'app.components.ResetPassword.resetPasswordSuccessButton',
    defaultMessage: 'Log in',
  },
  resetPasswordSuccessTitleFirstPw: {
    id: 'app.components.ResetPassword.resetPasswordSuccessTitleFirstPw',
    defaultMessage: 'Gelukt! ✨',
  },
  resetPasswordSuccessTextFirstPw: {
    id: 'app.components.ResetPassword.resetPasswordSuccessTextFirstPw',
    defaultMessage: 'Je account is gereed, en je kunt aan de slag!',
  },
  resetPasswordSuccessButtonFirstPw: {
    id: 'app.components.ResetPassword.resetPasswordSuccessButtonFirstPw',
    defaultMessage: 'Door naar de portal',
  },
  resetPasswordErrorTitle: {
    id: 'app.components.ResetPassword.resetPasswordErrorTitle',
    defaultMessage: 'Oops!',
  },
  resetPasswordErrorText: {
    id: 'app.components.ResetPassword.resetPasswordErrorText',
    defaultMessage:
      'Something went wrong while trying to change your password.',
  },
  resetPasswordErrorButton: {
    id: 'app.components.ResetPassword.resetPasswordErrorButton',
    defaultMessage: 'Try again',
  },
  badLinkErrorTitle: {
    id: 'app.components.ResetPassword.badLinkErrorTitle',
    defaultMessage: 'Check your link!',
  },
  badLinkErrorText: {
    id: 'app.components.ResetPassword.badLinkErrorText',
    defaultMessage:
      "The link doesn't seem right. Did you copy and paste it correctly?",
  },
  badLinkErrorButton: {
    id: 'app.components.ResetPassword.badLinkErrorButton',
    defaultMessage: 'Get a new link',
  },
  badLinkErrorTextFirstPw: {
    id: 'app.components.ResetPassword.badLinkErrorTextFirstPw',
    defaultMessage:
      "The link doesn't seem right. Did you copy and paste it correctly?",
  },
  resendLinkGenericError: {
    id: 'app.components.ResetPassword.resendLinkGenericError',
    defaultMessage: 'Er ging iets mis bij het verzenden.',
  },
});

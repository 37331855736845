import {
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

interface Props {
  currentPage: string;
  previousPage: {
    link: string;
    text: string;
  };
}

export function CashbackCampaignsBreadCrumb({
  previousPage,
  currentPage,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.breadCrumb}>
      <BlokkieTextLink href={previousPage.link} variant="pl700">
        {t(previousPage.text)}
      </BlokkieTextLink>

      <img
        src="/chevron.svg"
        alt="chevron"
        style={{
          margin: `0 ${blokkieTheme.spacing(2)}px`,
          rotate: '180deg',
        }}
      />

      <BlokkieTypography variant="pl700">{currentPage}</BlokkieTypography>
    </div>
  );
}

const useStyles = createUseStyles({
  breadCrumb: {
    width: 'fit-content',
    padding: `${blokkieTheme.spacing(2)}px ${blokkieTheme.spacing(3)}px`,
    borderRadius: blokkieTheme.spacing(1),
    backgroundColor: blokkieTheme.colors.grey.white,
    marginBottom: blokkieTheme.spacing(3),

    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
  },
});

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../components/components';
import TransactionDetail from '../../../components/Transactions/TransactionDetail';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import { selectUser } from '../../App/selectors';
import {
  selectTransaction,
  selectTransactionIsLoading,
  selectTransactionErrorMessage,
} from './selectors';
import { loadTransaction } from './actions';
import { usePrevious } from '../../../hooks';

function TransactionDetailContainer(props) {
  const history = useHistory();
  const match = useRouteMatch();

  const [viewTransaction, setViewTransaction] = useState({});

  useEffect(() => {
    const { transactionId } = match.params;
    props.loadTransaction(transactionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevTransaction = usePrevious(props.transaction);
  const prevTransactionID = usePrevious(match.params.transactionId);

  useEffect(() => {
    const isUpdatedTransaction =
      isEmpty(prevTransaction) && !isEmpty(props.transaction);
    const isUpdatedParams = prevTransactionID !== match.params.transactionId;

    if (isUpdatedTransaction) {
      setViewTransaction(props.transaction);
    } else if (isUpdatedParams) {
      props.loadTransaction(match.params.transactionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.transaction, match.params.transactionId, viewTransaction]);

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <TransactionDetail
            transaction={viewTransaction}
            transactionIsLoading={props.transactionIsLoading}
            transactionErrorMessage={props.transactionErrorMessage}
            loadTransaction={props.loadTransaction}
            showTransactionList={() => history.push(`/transactions`)}
            showTikkieDetail={(paymentRequest) =>
              history.push(`/paymentrequest/${paymentRequest.token}`)
            }
            showTransactionDetail={(transaction) =>
              history.push(`/transaction/${transaction.token}`)
            }
            showPayerDetails={props.user.showPayerDetailsPortal}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

TransactionDetailContainer.propTypes = {
  user: PropTypes.object,
  // Load transaction
  transaction: PropTypes.object,
  transactionIsLoading: PropTypes.bool,
  transactionErrorMessage: PropTypes.string,
  loadTransaction: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  // User
  user: selectUser,
  // Load transaction
  transaction: selectTransaction,
  transactionIsLoading: selectTransactionIsLoading,
  transactionErrorMessage: selectTransactionErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  loadTransaction: (transactionToken) =>
    dispatch(loadTransaction(transactionToken)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'transactionDetailContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'transactionDetailContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(TransactionDetailContainer);

export const selectUser = (state) => state.get('app').get('user').toJS();
export const selectUserIsLoading = (state) =>
  state.get('app').get('userIsLoading');
export const selectUserErrorMessage = (state) =>
  state.get('app').get('userErrorMessage');

export const selectLinkedOrganisations = (state) =>
  state.get('menuContainer').get('organisations').toJS();
export const selectLinkedOrganisationsIsLoading = (state) =>
  state.get('menuContainer').get('organisationsIsLoading');
export const selectLinkedOrganisationsLoadError = (state) =>
  state.get('menuContainer').get('organisationsLoadError').toJS();

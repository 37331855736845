export const selectExpandOnboardingKYCContainer = (state) =>
  state
    .get('onboardingKYCContainer')
    .get('config')
    .get('expandOnboardingKYCContainer');
export const selectInitialGetCurrentItem = (state) =>
  state.get('onboardingKYCContainer').get('initialGetCurrentItem');
export const selectCurrentItemNumber = (state) =>
  state.get('onboardingKYCContainer').get('currentItemNumber');
export const selectTotalItems = (state) =>
  state.get('onboardingKYCContainer').get('totalItems');
export const selectConfirmEmailSuccess = (state) =>
  state.get('onboardingKYCContainer').get('confirmEmailSuccess');
export const selectKYCSearchResultPending = (state) =>
  state.get('onboardingKYCContainer').get('searchBusinessPending');
export const selectKYCSearchResultError = (state) =>
  state.get('onboardingKYCContainer').get('searchBusinessError').toJS();
export const selectKYCSearchResult = (state) =>
  state.get('onboardingKYCContainer').get('searchResult').toJS();
export const selectOnboardingKYCData = (state) =>
  state.get('onboardingKYCContainer').get('data').toJS();
export const selectStartOnboardingPending = (state) =>
  state.get('onboardingKYCContainer').get('startOnboardingPending');
export const selectSendEmailResult = (state) =>
  state.get('onboardingKYCContainer').get('sendEmailResult').toJS();
export const selectEmail = (state) =>
  state.get('onboardingKYCContainer').get('data').get('email');
export const selectPhoneNumber = (state) =>
  state.get('onboardingKYCContainer').get('data').get('phoneNumber');

export const selectNickName = (state) =>
  state
    .get('onboardingKYCContainer')
    .get('data')
    .get('contactPersonDetails')
    .get('nickName');

export const selectOrganisationName = (state) =>
  state.get('onboardingKYCContainer').get('data').get('organisationName');
export const selectLegalEntity = (state) =>
  state.get('onboardingKYCContainer').get('data').get('legalEntity');
export const selectContactData = (state) =>
  state
    .get('onboardingKYCContainer')
    .get('data')
    .get('contactPersonDetails')
    .toJS();
export const selectSecondApplicantData = (state) =>
  state
    .get('onboardingKYCContainer')
    .get('data')
    .get('secondApplicantDetails')
    .toJS();
export const selectUboDetailsList = (state) =>
  state.get('onboardingKYCContainer').get('uboDetailsList').toJS();
export const selectPendingUbo = (state) =>
  state.get('onboardingKYCContainer').get('pendingUbo').toJS();
export const selectResendingSms = (state) =>
  state.get('onboardingKYCContainer').get('resendingSms');
export const selectIsSmsResent = (state) =>
  state.get('onboardingKYCContainer').get('isSmsResent');

export const selectIsUploading = (state) =>
  state.get('onboardingKYCContainer').get('isUploading');
export const selectTerms = (state) =>
  state.get('onboardingKYCContainer').get('terms');
export const selectIsGettingTerms = (state) =>
  state.get('onboardingKYCContainer').get('isGettingTerms');
export const selectBirthDate = (state) =>
  state.get('onboardingKYCContainer').get('data').get('birthDate');
export const selectCountry = (state) =>
  state.get('onboardingKYCContainer').get('data').get('country');
export const selectAddressDetails = (state) =>
  state.get('onboardingKYCContainer').get('data').get('addressDetails').toJS();
export const selectPossibleAddresses = (state) =>
  state.get('onboardingKYCContainer').get('possibleAddresses').toJS();
export const selectSubmitPartialAddressError = (state) =>
  state.get('onboardingKYCContainer').get('submitPartialAddressError');
export const selectIssuers = (state) =>
  state.get('onboardingKYCContainer').get('issuers').toJS();

export const selectIssuersPending = (state) =>
  state.get('onboardingKYCContainer').get('issuersPending');
export const selectPOSData = (state) =>
  state.get('onboardingKYCContainer').get('posData').toJS();
export const selectPOSDataPending = (state) =>
  state.get('onboardingKYCContainer').get('posDataPending');
export const selectBsn = (state) =>
  state.get('onboardingKYCContainer').get('data').get('bsn');
export const selectBadSector = (state) =>
  state.get('onboardingKYCContainer').get('data').get('badSector');
export const selectInitiatingPersonalPayment = (state) =>
  state.get('onboardingKYCContainer').get('initiatingPersonalPayment');
export const selectInitiatePersonalPaymentSuccess = (state) =>
  state.get('onboardingKYCContainer').get('initiatePersonalPaymentSuccess');
export const selectCheckingPersonalPayment = (state) =>
  state.get('onboardingKYCContainer').get('checkingPersonalPayment');
export const selectPersonalPaymentStatus = (state) =>
  state.get('onboardingKYCContainer').get('personalPaymentStatus');
export const selectPersonalPayment = (state) =>
  state.get('onboardingKYCContainer').get('personalPayment').toJS();
export const selectInitiatingBusinessPayment = (state) =>
  state.get('onboardingKYCContainer').get('initiatingBusinessPayment');
export const selectInitiateBusinessPaymentSuccess = (state) =>
  state.get('onboardingKYCContainer').get('initiatePersonalBusinessSuccess');
export const selectResetPasswordHasError = (state) =>
  state.get('onboardingKYCContainer').get('resetPasswordHasError');
export const selectResetPasswordtoken = (state) =>
  state.get('onboardingKYCContainer').get('resetPasswordToken');
export const selectResetPasswordSuccess = (state) =>
  state.get('onboardingKYCContainer').get('resetPasswordSuccess');
export const selectResetPasswordError = (state) =>
  state.get('onboardingKYCContainer').get('resetPasswordError').toJS();
export const selectCheckingBusinessPayment = (state) =>
  state.get('onboardingKYCContainer').get('checkingBusinessPayment');
export const selectBusinessPaymentStatus = (state) =>
  state.get('onboardingKYCContainer').get('businessPaymentStatus');
export const selectBusinessPayment = (state) =>
  state.get('onboardingKYCContainer').get('businessPayment').toJS();
export const selectGenericError = (state) =>
  state.get('onboardingKYCContainer').get('genericError').toJS();
export const selectGenericPending = (state) =>
  state.get('onboardingKYCContainer').get('genericPending');
export const selectPOSSupplier = (state) =>
  state.get('onboardingKYCContainer').get('posSupplier');
export const selectPOSSystem = (state) =>
  state.get('onboardingKYCContainer').get('posSystem');
export const selectPOSEmail = (state) =>
  state.get('onboardingKYCContainer').get('posEmail');
export const selectAabOnboarding = (state) =>
  state.get('onboardingKYCContainer').get('data').get('aabOnboarding');
export const selectCddOnboarding = (state) =>
  state.get('onboardingKYCContainer').get('data').get('cddOnboarding');
export const selectProductType = (state) =>
  state.get('onboardingKYCContainer').get('data').get('productType');
export const selectHasAbnBank = (state) =>
  state.get('onboardingKYCContainer').get('data').get('hasAbnBank');
export const selectOnboardingFlow = (state) =>
  state.get('onboardingKYCContainer').get('data').get('onboardingFlow');
export const selectOverruleOnlyAbnOption = (state) =>
  state.get('onboardingKYCContainer').get('data').get('overruleOnlyAbnOption');
export const selectIsProductTypeSet = (state) =>
  state.get('onboardingKYCContainer').get('data').get('isProductTypeSet');
export const selectQRCode = (state) =>
  state.get('onboardingKYCContainer').get('qrCode');
export const selectIdvStartUrl = (state) =>
  state.get('onboardingKYCContainer').get('idvStartUrl');
export const selectIdvStatus = (state) =>
  state.get('onboardingKYCContainer').get('idvStatus');
export const selectApplicant2Data = (state) =>
  state.get('onboardingKYCContainer').get('data').get('applicant2').toJS();
export const selectFilledInAuthorizedToSign = (state) =>
  state.get('onboardingKYCContainer').get('filledInAuthorizedToSign');
export const selectIsSecondApplicant = (state) =>
  state.get('onboardingKYCContainer').get('isSecondApplicant');

/*
 * BulkDetailView Messages
 *
 * This contains all the text for the BulkDetailView component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'app.components.BulkDetail.back',
    defaultMessage: 'Bulk uploads',
  },
  header: {
    id: 'app.components.BulkDetail.header',
    defaultMessage: 'Upload details',
  },
  createdHeader: {
    id: 'app.components.BulkDetail.createdHeader',
    defaultMessage: '👏🏼 Tikkies aangemaakt!',
  },
  status: {
    id: 'app.components.BulkDetail.status',
    defaultMessage: "🚀 Hoe wil je 'm delen?",
  },
  ready: {
    id: 'app.components.BulkDetail.ready',
    defaultMessage: 'Deel via sms',
  },
  sending: {
    id: 'app.components.BulkDetail.sending',
    defaultMessage: 'We versturen nu alle Tikkies via sms!',
  },
  send: {
    id: 'app.components.BulkDetail.send',
    defaultMessage: 'Sms versturen',
  },
  sendError: {
    id: 'app.components.BulkDetail.sendError',
    defaultMessage:
      'Sorry, het is op dit moment helaas niet mogelijk om de Tikkies via sms te versturen.',
  },
  labelTotal: {
    id: 'app.components.BulkDetail.labelTotal',
    defaultMessage: 'Totaal opgehaald',
  },
  labelTikkiesPaid: {
    id: 'app.components.BulkDetail.labelTikkiesPaid',
    defaultMessage: 'Aantal',
  },
  labelTitle: {
    id: 'app.components.BulkDetail.labelTitle',
    defaultMessage: 'Titel',
  },
  labelCreated: {
    id: 'app.components.BulkDetail.labelCreated',
    defaultMessage: 'Aangemaakt op',
  },
  buttonSend: {
    id: 'app.components.BulkDetail.buttonSend',
    defaultMessage: 'Verstuur all Tikkies',
  },
  buttonDownloadPayments: {
    id: 'app.components.BulkDetail.buttonDownloadPayments',
    defaultMessage: 'Download transacties',
  },
  buttonDownloadPaymentRequests: {
    id: 'app.components.BulkDetail.buttonDownloadPaymentRequests',
    defaultMessage: 'Download betaalverzoekjes',
  },
  contentHeader: {
    id: 'app.components.BulkDetail.contentHeader',
    defaultMessage: 'Tikkies',
  },
  oopsTitle: {
    id: 'app.components.BulkDetail.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.BulkDetail.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  errorDetailsHeader: {
    id: 'app.components.BulkDetail.errorDetailsHeader',
    defaultMessage: 'Toelichting gevonden fouten',
  },
  errorDetailsSubtitle: {
    id: 'app.components.BulkDetail.errorDetailsSubtitle',
    defaultMessage:
      'Deze meldingen geven aan welke fouten er in het CSV-bestand zijn gevonden.',
  },
  errorDetailsTableErrorHeading: {
    id: 'app.components.BulkDetail.errorDetailsTableErrorHeading',
    defaultMessage: 'Foutmelding',
  },
  errorDetailsTableColumnHeading: {
    id: 'app.components.BulkDetail.errorDetailsTableColumnHeading',
    defaultMessage: 'Kolom',
  },
  errorDetailsTableRowHeading: {
    id: 'app.components.BulkDetail.errorDetailsTableRowHeading',
    defaultMessage: 'Regel',
  },
  validationError: {
    id: 'app.components.BulkDetail.validationError',
    defaultMessage:
      'Oeps, er zitten fouten in het CSV-bestand. Bekijk hieronder de toelichting.',
  },
});

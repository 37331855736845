import '!file-loader?name=[name].[ext]!../../../../images/trash.svg';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'amber';
import PropTypes from 'prop-types';
import { ConfirmBalloon } from '../../../Confirm';
import { DropdownWrapper } from '../../../Dropdown/Dropdown';
import DropdownMenu from '../../../Dropdown/DropdownMenu';
import { Color, FilterItemWrapper, SecondaryButton } from '../../../styleguide';

const DropdownButton = styled(Button)`
  width: 100% !important;
  display: block !important;
`;

const ButtonBox = styled.div`
  padding: 24px 24px 4px 24px;
  background-color: white;
  border-radius: 10px;
  width: 280px;
`;

const Info = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-family: MuseoSansRounded;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: ${Color.gray03};
  white-space: initial;
`;

export function Delete({
  user,
  deleteUserFromOneOrganisation,
  deleteUserFromAllOrganisations,
}) {
  const [showDeleteUserDropdown, setShowDeleteUserDropdown] = useState(false);
  const toggleShowDeleteUserDropdown = () => {
    setShowDeleteUserDropdown(!showDeleteUserDropdown);
    setShowAreYouSureOneOrg2(false);
    setShowAreYouSureAllOrgs(false);
  };
  const [showAreYouSureOneOrg1, setShowAreYouSureOneOrg1] = useState(false);
  const toggleShowAreYouSureOneOrg1 = () =>
    setShowAreYouSureOneOrg1(!showAreYouSureOneOrg1);
  const [showAreYouSureOneOrg2, setShowAreYouSureOneOrg2] = useState(false);
  const toggleShowAreYouSureOneOrg2 = () =>
    setShowAreYouSureOneOrg2(!showAreYouSureOneOrg2);
  const [showAreYouSureAllOrgs, setShowAreYouSureAllOrgs] = useState(false);
  const toggleShowAreYouSureAllOrgs = () =>
    setShowAreYouSureAllOrgs(!showAreYouSureAllOrgs);

  const hasAccessToOtherPermittedOrganisations = () =>
    user.linkedOrganisations && user.linkedOrganisations.length > 1;

  useEffect(() => {
    if (showAreYouSureOneOrg2 && showAreYouSureAllOrgs)
      setShowAreYouSureAllOrgs(false);
  }, [showAreYouSureOneOrg2]);

  return (
    <FilterItemWrapper mr="0">
      <SecondaryButton
        data-test-id="user-details-remove-user"
        style={{ borderRadius: 24 }}
        onClick={
          hasAccessToOtherPermittedOrganisations()
            ? toggleShowDeleteUserDropdown
            : toggleShowAreYouSureOneOrg1
        }
      >
        <img src="/trash.svg" alt="" />
        Medewerker verwijderen
        <ConfirmBalloon
          show={showAreYouSureOneOrg1}
          confirmText="Ja, verwijder"
          onConfirm={deleteUserFromOneOrganisation}
          onCancel={toggleShowAreYouSureOneOrg1}
        />
      </SecondaryButton>
      <DropdownWrapper>
        <DropdownMenu
          show={showDeleteUserDropdown}
          handleOutsideClick={toggleShowDeleteUserDropdown}
          mobileFullScreen={false}
          dataTestId="user-management-user-delete-drop-down-menu"
        >
          <ButtonBox>
            <DropdownButton
              type="secondary"
              onClick={toggleShowAreYouSureOneOrg2}
              data-test-id="user-management-user-delete-drop-down-menu-option-1"
            >
              Alleen deze organisatie
            </DropdownButton>
            <Info>
              Deze persoon blijft toegang houden tot andere portals
              <ConfirmBalloon
                show={showAreYouSureOneOrg2}
                confirmText="Ja, verwijder"
                onConfirm={deleteUserFromOneOrganisation}
                onCancel={toggleShowAreYouSureOneOrg2}
                withoutOverlay
                noPosition
              />
            </Info>
            <DropdownButton
              type="secondary"
              onClick={toggleShowAreYouSureAllOrgs}
              data-test-id="user-management-user-delete-drop-down-menu-option-2"
            >
              Alle organisaties
            </DropdownButton>
            <Info>
              Deze persoon wordt overal verwijderd
              <ConfirmBalloon
                show={showAreYouSureAllOrgs}
                confirmText="Ja, verwijder"
                onConfirm={deleteUserFromAllOrganisations}
                onCancel={toggleShowAreYouSureAllOrgs}
                withoutOverlay
                noPosition
              />
            </Info>
          </ButtonBox>
        </DropdownMenu>
      </DropdownWrapper>
    </FilterItemWrapper>
  );
}

Delete.propTypes = {
  user: PropTypes.object.isRequired,
  deleteUserFromOneOrganisation: PropTypes.func.isRequired,
  deleteUserFromAllOrganisations: PropTypes.func.isRequired,
};

export const BACKGROUND_LOGOUT = 'app/BACKGROUND_LOGOUT';
export const LOAD_USER = 'app/LOAD_USER';
export const LOAD_USER_SUCCESS = 'app/LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'app/LOAD_USER_ERROR';
export const UPDATE_USER = 'app/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'app/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/UPDATE_USER_ERROR';
export const RESET_USER_UPDATE = 'app/RESET_USER_UPDATE';
export const SAVE_PAYMENT_REQUEST_DRAFT = 'app/SAVE_PAYMENT_REQUEST_DRAFT';
export const SET_CURRENT_PAGE_NAME = 'app/SET_CURRENT_PAGE_NAME';
export const SWITCH_ORGANISATION = 'app/SWITCH_ORGANISATION';
export const CONFIRM_EMAIL_CHANGE = 'app/CONFIRM_EMAIL_CHANGE';
export const CONFIRM_EMAIL_CHANGE_SUCCESS = 'app/CONFIRM_EMAIL_CHANGE_SUCCESS';
export const CONFIRM_EMAIL_CHANGE_ERROR = 'app/CONFIRM_EMAIL_CHANGE_ERROR';

import { fromJS } from 'immutable';

import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_ERROR,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  LOAD_API_TOKENS,
  LOAD_API_TOKENS_SUCCESS,
  LOAD_API_TOKENS_ERROR,
  CREATE_API_TOKEN,
  CREATE_API_TOKEN_SUCCESS,
  CREATE_API_TOKEN_ERROR,
  MODIFY_API_TOKEN,
  MODIFY_API_TOKEN_SUCCESS,
  MODIFY_API_TOKEN_ERROR,
  CLEAR_SETTINGS,
  FETCH_AUDIT_LOGS,
  FETCH_AUDIT_LOGS_SUCCESS,
  FETCH_AUDIT_LOGS_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  ADD_USER_TO_ACTIVE_ORGANISATION,
  ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
  ADD_USER_TO_ACTIVE_ORGANISATION_ERROR,
  RESET_ADD_USER_TO_ACTIVE_ORGANISATION,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR,
  RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  LOAD_PERMITTED_ORGANISATIONS,
  LOAD_PERMITTED_ORGANISATIONS_SUCCESS,
  LOAD_PERMITTED_ORGANISATIONS_ERROR,
  FETCH_PERMITTED_ORGANISATIONS_USERS,
  FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS,
  FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESET_UPDATE_USER,
  RESET_DELETE_USER,
  RESET_UPDATE_SETTINGS,
  DEACTIVATE_ORGANISATION,
  DEACTIVATE_ORGANISATION_SUCCESS,
  DEACTIVATE_ORGANISATION_ERROR,
  RESET_DEACTIVATE_ORGANISATION,
} from './constants';

const initialState = fromJS({
  data: {},
  settingsLoading: true,
  settingsLoadingSuccess: false,
  settingsLoadingError: false,
  settingsLoadingErrorMessage: null,
  settingsLoadingErrorStatusCode: null,
  settingsUpdating: false,
  settingsUpdatingSuccess: false,
  settingsUpdatingError: false,
  settingsUpdatingErrorMessage: null,
  apiTokensLoading: false,
  apiTokensLoadingSuccess: false,
  apiTokensLoadingError: false,
  apiTokens: [],
  creatingApiToken: false,
  createApiTokenSuccess: false,
  createApiTokenError: false,
  modifyingApiToken: false,
  modifyApiTokenSuccess: false,
  modifyApiTokenError: false,
  apiToken: {},
  auditLogs: {
    auditLogs: [],
    totalElements: 0,
  },
  auditLogsLoading: false,
  auditLogsError: {},
  users: {
    users: [],
    totalElements: 0,
  },
  usersLoading: false,
  usersError: {},
  addUserToActiveOrganisationSuccess: {},
  addUserToActiveOrganisationLoading: false,
  addUserToActiveOrganisationError: {},
  permittedOrganisationsLoading: false,
  loadPermittedOrganisationsSuccess: [],
  loadPermittedOrganisationsError: {},
  permittedOrganisationsUsersLoading: false,
  permittedOrganisationsUsers: {
    users: [],
    totalElements: 0,
  },
  permittedOrganisationsUsersError: {},
  addExistingUserToActiveOrganisationSuccess: {},
  addExistingUserToActiveOrganisationLoading: false,
  addExistingUserToActiveOrganisationError: {},
  fetchUserLoading: false,
  user: {},
  fetchUserError: {},
  updateUserLoading: false,
  updateUserError: {},
  requestPasswordResetMailError: {},
  deleteUserLoading: false,
  deleteUserError: {},
  deactivationSuccess: {},
  deactivationLoading: false,
  deactivationError: {},
});

function smsSettingsContainerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SETTINGS:
      return state
        .set('settingsLoading', true)
        .set('settingsLoadingSuccess', false)
        .set('settingsLoadingError', false)
        .set('settingsLoadingErrorMessage', null)
        .set('data', fromJS({}));
    case LOAD_SETTINGS_SUCCESS:
      return state
        .set('settingsLoading', false)
        .set('settingsLoadingSuccess', true)
        .set('settingsLoadingError', false)
        .set('settingsLoadingErrorMessage', null)
        .set('data', fromJS(action.payload.settings));
    case LOAD_SETTINGS_ERROR:
      return state
        .set('settingsLoading', false)
        .set('settingsLoadingSuccess', false)
        .set('settingsLoadingError', true)
        .set('settingsLoadingErrorMessage', action.payload.error)
        .set('settingsLoadingErrorStatusCode', action.payload.statusCode)
        .set('data', fromJS({}));
    case CLEAR_SETTINGS:
      return state
        .set('settingsLoading', initialState.get('settingsLoading'))
        .set(
          'settingsLoadingSuccess',
          initialState.get('settingsLoadingSuccess'),
        )
        .set('settingsLoadingError', initialState.get('settingsLoadingError'))
        .set(
          'settingsLoadingErrorMessage',
          initialState.get('settingsLoadingErrorMessage'),
        )
        .set(
          'settingsLoadingErrorStatusCode',
          initialState.get('settingsLoadingErrorStatusCode'),
        )
        .set('data', fromJS(initialState.get('data')));
    case UPDATE_SETTINGS:
      return state
        .set('settingsUpdating', true)
        .set('settingsUpdatingSuccess', false)
        .set('settingsUpdatingError', false)
        .set('settingsUpdatingErrorMessage', null)
        .set('data', fromJS({}));
    case UPDATE_SETTINGS_SUCCESS:
      return state
        .set('settingsUpdating', false)
        .set('settingsUpdatingSuccess', true)
        .set('settingsUpdatingError', false)
        .set('settingsUpdatingErrorMessage', null)
        .set('data', fromJS(action.payload.updatedSettings));
    case UPDATE_SETTINGS_ERROR:
      return state
        .set('settingsUpdating', false)
        .set('settingsUpdatingSuccess', false)
        .set('settingsUpdatingError', true)
        .set('settingsUpdatingErrorMessage', action.payload.error)
        .set('data', fromJS({}));
    case RESET_UPDATE_SETTINGS:
      return state
        .set('settingsUpdating', false)
        .set('settingsUpdatingSuccess', false)
        .set('settingsUpdatingError', false)
        .set('settingsUpdatingErrorMessage', null);
    case LOAD_API_TOKENS:
      return state
        .set('apiTokensLoading', true)
        .set('apiTokensLoadingSuccess', false)
        .set('apiTokensLoadingError', false)
        .set('createApiTokenError', false)
        .set('modifyApiTokenError', false);
    case LOAD_API_TOKENS_SUCCESS:
      return state
        .set('apiTokensLoading', false)
        .set('apiTokensLoadingSuccess', true)
        .set('apiTokensLoadingError', false)
        .set('apiTokens', fromJS(action.payload));
    case LOAD_API_TOKENS_ERROR:
      return state
        .set('apiTokensLoading', false)
        .set('apiTokensLoadingSuccess', false)
        .set('apiTokensLoadingError', true)
        .set('apiTokens', fromJS([]));
    case CREATE_API_TOKEN:
      return state
        .set('creatingApiToken', true)
        .set('createApiTokenSuccess', false)
        .set('createApiTokenError', false);
    case CREATE_API_TOKEN_SUCCESS:
      return state
        .set('creatingApiToken', false)
        .set('createApiTokenSuccess', true)
        .set('createApiTokenError', false);
    case CREATE_API_TOKEN_ERROR:
      return state
        .set('creatingApiToken', false)
        .set('createApiTokenSuccess', false)
        .set('createApiTokenError', true);
    case MODIFY_API_TOKEN:
      return state
        .set('modifyingApiToken', true)
        .set('modifyApiTokenSuccess', false)
        .set('modifyApiTokenError', false);
    case MODIFY_API_TOKEN_SUCCESS:
      return state
        .set('modifyingApiToken', false)
        .set('modifyApiTokenSuccess', true)
        .set('modifyApiTokenError', false)
        .set('apiToken', fromJS(action.payload));
    case MODIFY_API_TOKEN_ERROR:
      return state
        .set('modifyingApiToken', false)
        .set('modifyApiTokenSuccess', false)
        .set('modifyApiTokenError', true);
    case FETCH_AUDIT_LOGS:
      return state
        .setIn(['auditLogs', 'auditLogs'], fromJS([]))
        .set('auditLogsLoading', true)
        .set('auditLogsError', fromJS({}));
    case FETCH_AUDIT_LOGS_SUCCESS:
      return state
        .set('auditLogs', fromJS(action.payload))
        .set('auditLogsLoading', false)
        .set('auditLogsError', fromJS({}));
    case FETCH_AUDIT_LOGS_ERROR:
      return state
        .set('auditLogs', fromJS({ auditLogs: [], totalElements: 0 }))
        .set('auditLogsLoading', false)
        .set('auditLogsError', fromJS(action.payload));
    case FETCH_USERS:
      return state
        .setIn(['users', 'users'], fromJS([]))
        .set('usersLoading', true)
        .set('usersError', fromJS({}));
    case FETCH_USERS_SUCCESS:
      return state
        .set('users', fromJS(action.payload))
        .set('usersLoading', false)
        .set('usersError', fromJS({}));
    case FETCH_USERS_ERROR:
      return state
        .set('users', fromJS({ auditLogs: [], totalElements: 0 }))
        .set('usersLoading', false)
        .set('usersError', fromJS(action.payload));
    case ADD_USER_TO_ACTIVE_ORGANISATION:
      return state
        .set('addUserToActiveOrganisationSuccess', fromJS({}))
        .set('addUserToActiveOrganisationLoading', true)
        .set('addUserToActiveOrganisationError', fromJS({}));
    case ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS:
      return state
        .set('addUserToActiveOrganisationSuccess', fromJS(action.payload))
        .set('addUserToActiveOrganisationLoading', false)
        .set('addUserToActiveOrganisationError', fromJS({}));
    case ADD_USER_TO_ACTIVE_ORGANISATION_ERROR:
      return state
        .set('addUserToActiveOrganisationSuccess', fromJS({}))
        .set('addUserToActiveOrganisationLoading', false)
        .set('addUserToActiveOrganisationError', fromJS(action.payload));
    case RESET_ADD_USER_TO_ACTIVE_ORGANISATION:
      return state
        .set('addUserToActiveOrganisationSuccess', fromJS({}))
        .set('addUserToActiveOrganisationError', fromJS({}));
    case ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION:
      return state
        .set('addExistingUserToActiveOrganisationSuccess', fromJS({}))
        .set('addExistingUserToActiveOrganisationLoading', true)
        .set('addExistingUserToActiveOrganisationError', fromJS({}));
    case ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS:
      return state
        .set(
          'addExistingUserToActiveOrganisationSuccess',
          fromJS(action.payload),
        )
        .set('addExistingUserToActiveOrganisationLoading', false)
        .set('addExistingUserToActiveOrganisationError', fromJS({}));
    case ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR:
      return state
        .set('addExistingUserToActiveOrganisationSuccess', fromJS({}))
        .set('addExistingUserToActiveOrganisationLoading', false)
        .set(
          'addExistingUserToActiveOrganisationError',
          fromJS(action.payload),
        );
    case RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION:
      return state
        .set('addExistingUserToActiveOrganisationSuccess', fromJS({}))
        .set('addExistingUserToActiveOrganisationLoading', false)
        .set('addExistingUserToActiveOrganisationError', fromJS({}));
    case LOAD_PERMITTED_ORGANISATIONS:
      return state
        .set('loadPermittedOrganisationsSuccess', fromJS([]))
        .set('permittedOrganisationsLoading', true)
        .set('loadPermittedOrganisationsError', fromJS({}));
    case LOAD_PERMITTED_ORGANISATIONS_SUCCESS:
      return state
        .set('loadPermittedOrganisationsSuccess', fromJS(action.payload))
        .set('permittedOrganisationsLoading', false)
        .set('loadPermittedOrganisationsError', fromJS({}));
    case LOAD_PERMITTED_ORGANISATIONS_ERROR:
      return state
        .set('loadPermittedOrganisationsSuccess', fromJS([]))
        .set('permittedOrganisationsLoading', false)
        .set('loadPermittedOrganisationsError', fromJS(action.payload));
    case FETCH_PERMITTED_ORGANISATIONS_USERS:
      return state
        .setIn(['permittedOrganisationsUsers', 'users'], fromJS([]))
        .set('permittedOrganisationsUsersLoading', true)
        .set('permittedOrganisationsUsersError', fromJS({}));
    case FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS:
      return state
        .set('permittedOrganisationsUsers', fromJS(action.payload))
        .set('permittedOrganisationsUsersLoading', false)
        .set('permittedOrganisationsUsersError', fromJS({}));
    case FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR:
      return state
        .set(
          'permittedOrganisationsUsers',
          fromJS({ users: [], totalElements: 0 }),
        )
        .set('permittedOrganisationsUsersLoading', false)
        .set('permittedOrganisationsUsersError', fromJS(action.payload));
    case FETCH_USER:
      return state
        .set('user', fromJS({}))
        .set('fetchUserLoading', true)
        .set('fetchUserError', fromJS({}));
    case FETCH_USER_SUCCESS:
      return state
        .set('user', fromJS(action.payload))
        .set('fetchUserLoading', false)
        .set('fetchUserError', fromJS({}));
    case FETCH_USER_ERROR:
      return state
        .set('user', fromJS({}))
        .set('fetchUserLoading', false)
        .set('fetchUserError', fromJS(action.payload));
    case UPDATE_USER:
      return state
        .set('user', fromJS({}))
        .set('updateUserLoading', true)
        .set('updateUserError', fromJS({}));
    case UPDATE_USER_SUCCESS:
      return state
        .set('user', fromJS(action.payload))
        .set('updateUserLoading', false)
        .set('updateUserError', fromJS({}));
    case UPDATE_USER_ERROR:
      return state
        .set('user', fromJS({}))
        .set('updateUserLoading', false)
        .set('updateUserError', fromJS(action.payload));
    case RESET_UPDATE_USER:
      return state.set('user', fromJS({})).set('updateUserError', fromJS({}));
    case REQUEST_PASSWORD_RESET:
      return state
        .set('requestPasswordResetMailSuccess', fromJS({}))
        .set('requestPasswordResetMailError', fromJS({}));
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return state
        .set('requestPasswordResetMailSuccess', fromJS({ reset: true }))
        .set('requestPasswordResetMailError', fromJS({}));
    case REQUEST_PASSWORD_RESET_ERROR:
      return state
        .set('requestPasswordResetMailSuccess', fromJS({}))
        .set('requestPasswordResetMailError', fromJS(action.payload));
    case DELETE_USER:
      return state
        .set('deleteUserLoading', true)
        .set('deleteUserError', fromJS({}));
    case DELETE_USER_SUCCESS:
      return state
        .set('deleteUserLoading', false)
        .set('deleteUserError', fromJS({}));
    case DELETE_USER_ERROR:
      return state
        .set('deleteUserLoading', false)
        .set('deleteUserError', fromJS(action.payload));
    case RESET_DELETE_USER:
      return state.set('deleteUserError', fromJS({}));
    case DEACTIVATE_ORGANISATION:
      return state
        .set('deactivationLoading', true)
        .set('deactivationSuccess', fromJS({}))
        .set('deactivationError', fromJS({}));
    case DEACTIVATE_ORGANISATION_SUCCESS:
      return state
        .set('deactivationLoading', false)
        .set('deactivationSuccess', fromJS(action.payload))
        .set('data', fromJS(action.payload))
        .set('deactivationError', fromJS({}));
    case DEACTIVATE_ORGANISATION_ERROR:
      return state
        .set('deactivationLoading', false)
        .set('deactivationSuccess', fromJS({}))
        .set('deactivationError', fromJS(action.payload));
    case RESET_DEACTIVATE_ORGANISATION:
      return state
        .set('deactivationLoading', false)
        .set('deactivationSuccess', fromJS({}))
        .set('deactivationError', fromJS({}));
    default:
      return state;
  }
}

export default smsSettingsContainerReducer;

export { initialState };

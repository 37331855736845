import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import choosePasswordSteps from './constants';
import onboardingKYCContainerHOC from '../hoc';
import EmailConfirm from '../../../components/OnboardingKYC/EmailConfirm';

import {
  selectConfirmEmailSuccess,
  selectEmail,
  selectGenericError,
  selectSendEmailResult,
} from '../selectors';
import { sendEmail, setPassword } from '../actions';
import Password from '../../../components/OnboardingKYC/Password';
import FormWrapperContainer from '../FormWrapperContainer';

const choosePasswordStepsArr = [
  choosePasswordSteps.confirmEmail,
  choosePasswordSteps.choosePassword,
];

export class ChoosePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: choosePasswordSteps.confirmEmail,
    };
  }

  nextStep = () => {
    this.setState((prevState) => ({
      currentStep: this.props.getNextStep(
        choosePasswordStepsArr,
        prevState.currentStep,
      ),
    }));
  };

  prevStep = () => {
    this.setState((prevState) => ({
      currentStep: this.props.getPreviousStep(
        choosePasswordStepsArr,
        prevState.currentStep,
      ),
    }));
  };

  render() {
    const {
      confirmEmailSuccess,
      email,
      resendEmail,
      resendEmailResult,
      genericError,
      getCurrentItem,
    } = this.props;

    const noProgress =
      this.state.currentStep === choosePasswordSteps.confirmEmail;

    const expiredToken = genericError.errorCode === 2078;
    return (
      <FormWrapperContainer
        noProgressBar={noProgress}
        hasSpecificError={expiredToken}
        prevStep={this.prevStep}
      >
        {this.state.currentStep === choosePasswordSteps.confirmEmail && (
          <EmailConfirm
            nextStep={this.nextStep}
            confirmEmailSuccess={confirmEmailSuccess}
            resendEmailResult={resendEmailResult}
            expiredToken={expiredToken}
            resendEmail={resendEmail}
            email={email}
            getCurrentItem={getCurrentItem}
          />
        )}
        {this.state.currentStep === choosePasswordSteps.choosePassword && (
          <Password
            prevStep={this.prevStep}
            sendPassword={this.props.setPassword}
          />
        )}
      </FormWrapperContainer>
    );
  }
}

ChoosePasswordContainer.propTypes = {
  // hoc
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  getCurrentItem: PropTypes.func.isRequired,

  // confirm email
  confirmEmailSuccess: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  resendEmail: PropTypes.func.isRequired,
  resendEmailResult: PropTypes.object.isRequired,

  // choose password
  setPassword: PropTypes.func.isRequired,
  genericError: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  // confirm email
  confirmEmailSuccess: selectConfirmEmailSuccess,
  email: selectEmail,
  resendEmailResult: selectSendEmailResult,
  genericError: selectGenericError,
});

const mapDispatchToProps = (dispatch) => ({
  resendEmail: (email) => dispatch(sendEmail(email)),
  setPassword: (password) => dispatch(setPassword(password)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(ChoosePasswordContainer);

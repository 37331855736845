import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useTranslation } from 'react-i18next';
import {
  BlokkieTypography,
  blokkieTheme,
  BlokkieButton,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { Wrapper, Input, OnboardingParagraph } from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import InputKeyboard from '../components/InputKeyboard';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface GetMobileProps {
  phoneNumber: string;
  sendSMS: (param: string) => void;
  nextStep: () => void;
  savePhoneNumber: (param: string) => void;
}

function GetMobile({
  phoneNumber,
  sendSMS,
  nextStep,
  savePhoneNumber,
}: GetMobileProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mobileNumber, setMobileNumber] = useState(phoneNumber);
  const [info, setInfo] = useState(false);
  const showInfo = (): void => setInfo(!info);

  useOnboardingPageView('get-mobile');

  const next = (): void => {
    if (phoneNumber !== mobileNumber) {
      savePhoneNumber(mobileNumber);
      sendSMS(mobileNumber);
    }
    nextStep();
  };
  const disableButton = (): boolean =>
    mobileNumber.length === 0 || !mobileNumber.match(/^([0-9]{8}$)$/);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const code = event.key;

    if (code === 'Enter' && !disableButton()) {
      next();
    }
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader clickRight={showInfo} />
        <Title>{t('onboarding.getMobile.header1')}</Title>
        <BlokkieTypography
          variant="ps500"
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            marginBottom: blokkieTheme.spacing(2),
          }}
        >
          {t('onboarding.getMobile.header2')}
        </BlokkieTypography>
        <div className={classes.row}>
          <Input
            readOnly
            style={{ width: '62px', color: blokkieTheme.colors.grey.veryDark }}
            value={t('onboarding.getMobile.preFilled')}
            data-test-id="Onboarding-GetMobile-PreFilled"
          />
          <InputKeyboard
            value={mobileNumber}
            placeholder="12345678"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMobileNumber(e.target.value)
            }
            onKeyDown={handleKeyDown}
            name="mobile"
            autoComplete="off"
            autoCorrect="off"
            autoFocus
            maxLength={8}
            data-test-id="Onboarding-GetMobile-MobileNumber"
            relatedButtonId="button-footer"
          />
        </div>
        <ButtonFooter>
          <BlokkieButton
            disabled={disableButton()}
            onClick={() => next()}
            data-test-id="onboarding-GetMobile-nextButton"
            variant="primary"
            size="large"
          >
            {t(`next`)}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={t('onboarding.getMobile.infoTitle')}
        onClick={showInfo}
        fromPage={GetMobile.name}
      >
        <OnboardingParagraph>
          {t('onboarding.getMobile.infoBody1')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}
const useStyles = createUseStyles({
  row: {
    display: 'flex',
  },
});

export default injectIntl(GetMobile);

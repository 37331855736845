import React from 'react';
import { useTranslation } from 'react-i18next';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { isMobileOnly } from 'react-device-detect';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/champagne-dancing.mp4';
import { CenterWrapper } from '../styledComponents';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface SignUpPendingProps {
  name: string;
  forSecondApplicant: boolean;
  organisationName: string;
}

function SignUpPending({
  name,
  forSecondApplicant,
  organisationName,
}: SignUpPendingProps): JSX.Element {
  const { t } = useTranslation();

  useOnboardingPageView(undefined, 'business-onboarding-SignUpPending-page');

  return (
    <CenterWrapper>
      <BlokkieGifContainer
        source="../../champagne-dancing.mp4"
        gifOffset="-25px"
        style={{ marginBottom: `${blokkieTheme.spacing(2)}px` }}
      />
      <Title data-test-id="Onboarding-SignUpPending">
        {t('onboarding.signUpPending.title').replace('{user}', name)}
      </Title>
      <BlokkieTypography
        style={{
          textAlign: 'center',
          marginTop: '5px',
          color: blokkieTheme.colors.grey.veryDark,
          width: isMobileOnly ? '342px;' : '440px',
        }}
        variant="pl500"
      >
        {!forSecondApplicant
          ? t('onboarding.signUpPending.description')
          : t('onboarding.signUpPending.descriptionSecondApplicant').replace(
              '{organisation}',
              organisationName,
            )}
      </BlokkieTypography>
    </CenterWrapper>
  );
}

export default injectIntl(SignUpPending);

/*
 *
 * LoginContainer actions
 *
 */

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_INVALID_CREDENTIALS,
  LOGIN_INVALID_OTP_CODE,
  LOGIN_EXPIRED_OTP_CODE,
  LOGIN_TOO_FRESH_OTP_CODE,
  LOGIN_BLOCKED_USER,
  LOGIN_SMS_SEND_FAILED,
  LOGIN_GENERIC_ERROR,
  LOGIN_RESET,
  SEND_RESET_LINK,
  SEND_RESET_LINK_SUCCESS,
  SEND_RESET_LINK_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VALIDATE_REUSED_PASSWORD,
  VALIDATE_REUSED_PASSWORD_SUCCESS,
  VALIDATE_REUSED_PASSWORD_ERROR,
  VALIDATE_PASSWORD_RESET_TOKEN,
  VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS,
  VALIDATE_PASSWORD_RESET_TOKEN_ERROR,
  LOGIN_ONBOARDING_KYC_USER_SUCCESS,
  OTP,
  RESEND_PASSWORD_SET_LINK,
  RESEND_PASSWORD_SET_LINK_SUCCESS,
  RESEND_PASSWORD_SET_LINK_ERROR,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
} from './constants';

export function loginUser(username, password, otp, rememberMe, action, url) {
  return {
    type: LOGIN_USER,
    payload: {
      username,
      password,
      otp,
      rememberMe,
      action,
      url,
    },
  };
}

export function loginUserSuccess(otp, rememberMe) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      otp,
      rememberMe,
    },
  };
}

export function loginOnboardingKycUserSuccess(res, otp, rememberMe) {
  return {
    type: LOGIN_ONBOARDING_KYC_USER_SUCCESS,
    payload: {
      res,
      otp,
      rememberMe,
    },
  };
}

export function loginInvalidCredentials() {
  return {
    type: LOGIN_INVALID_CREDENTIALS,
  };
}

export function loginInvalidOtpCode() {
  return {
    type: LOGIN_INVALID_OTP_CODE,
  };
}

export function loginExpiredOtpCode() {
  return {
    type: LOGIN_EXPIRED_OTP_CODE,
  };
}

export function loginTooFreshOtpCode() {
  return {
    type: LOGIN_TOO_FRESH_OTP_CODE,
  };
}

export function loginBlockedUser() {
  return {
    type: LOGIN_BLOCKED_USER,
  };
}

export function loginSmsSendFailed() {
  return {
    type: LOGIN_SMS_SEND_FAILED,
  };
}

export function loginGenericError(error) {
  return {
    type: LOGIN_GENERIC_ERROR,
    payload: {
      error,
    },
  };
}

export function loginReset() {
  return {
    type: LOGIN_RESET,
  };
}

export function sendResetLink(email, url) {
  return {
    type: SEND_RESET_LINK,
    payload: {
      email,
      url,
    },
  };
}

export function sendResetLinkSuccess() {
  return {
    type: SEND_RESET_LINK_SUCCESS,
  };
}

export function sendResetLinkError(error) {
  return {
    type: SEND_RESET_LINK_ERROR,
    payload: {
      error,
    },
  };
}

export function resetPassword(password, resetToken) {
  return {
    type: RESET_PASSWORD,
    payload: {
      password,
      resetToken,
    },
  };
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: {
      error,
    },
  };
}

export function validatePasswordResetToken(token) {
  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN,
    payload: {
      token,
    },
  };
}

export function validatePasswordResetTokenSuccess() {
  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS,
  };
}

export function validatePasswordResetTokenError(status, error) {
  return {
    type: VALIDATE_PASSWORD_RESET_TOKEN_ERROR,
    payload: {
      status,
      error,
    },
  };
}

export function validateReusedPassword(password, resetToken) {
  return {
    type: VALIDATE_REUSED_PASSWORD,
    payload: {
      password,
      resetToken,
    },
  };
}

export function validateReusedPasswordSuccess() {
  return {
    type: VALIDATE_REUSED_PASSWORD_SUCCESS,
  };
}

export function validateReusedPasswordError(error) {
  return {
    type: VALIDATE_REUSED_PASSWORD_ERROR,
    payload: {
      error,
    },
  };
}

export function storeOtp(otp) {
  return {
    type: OTP,
    payload: {
      otp,
    },
  };
}

export function validateToken(token) {
  return {
    type: VALIDATE_TOKEN,
    payload: {
      token,
    },
  };
}

export function validateTokenSuccess(response) {
  return {
    type: VALIDATE_TOKEN_SUCCESS,
    payload: response,
  };
}

export function validateTokenError(status, error) {
  return {
    type: VALIDATE_TOKEN_ERROR,
    payload: {
      status,
      error,
    },
  };
}

export function setPassword(password, token) {
  return {
    type: SET_PASSWORD,
    payload: {
      password,
      token,
    },
  };
}

export function setPasswordSuccess() {
  return {
    type: SET_PASSWORD_SUCCESS,
  };
}

export function setPasswordError(status, error) {
  return {
    type: SET_PASSWORD_ERROR,
    payload: {
      status,
      error,
    },
  };
}

export function resendPasswordSetLink(token) {
  return {
    type: RESEND_PASSWORD_SET_LINK,
    payload: {
      token,
    },
  };
}

export function resendPasswordSetLinkSuccess() {
  return {
    type: RESEND_PASSWORD_SET_LINK_SUCCESS,
  };
}

export function resendPasswordSetLinkError(status, error) {
  return {
    type: RESEND_PASSWORD_SET_LINK_ERROR,
    payload: {
      status,
      error,
    },
  };
}

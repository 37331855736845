/* eslint-disable import/no-unresolved */
import '!file-loader?name=[name].[ext]!../../images/downloadicon.svg';
import '!file-loader?name=[name].[ext]!../../images/print.svg';

import { BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { Color, FontSize, PrimaryButton, Space } from '../styleguide';
import Loader from '../OnboardingKYC/Loader';
import Tooltip from '../Tooltip/Tooltip';
import theme from '../../theme';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => (!props.overviewStyle ? '252px' : '188px')};
  border-radius: ${Space.s8};
  margin-bottom: ${Space.s16};
  padding: ${(props) =>
    !props.overviewStyle
      ? Space.s32
      : `${Space.s24} ${Space.s32} ${Space.s24} ${Space.s24}`};
  background-color: ${Color.white};
  box-shadow: ${(props) =>
    props.focussed
      ? '0 5px 20px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 4px 0 rgba(0, 0, 0, 0.05)'};
  opacity: ${(props) => (props.disabled ? '50%' : '100%')};
`;

const Label = styled.div`
  font-family: MuseoSansRounded700;
  font-size: 20px;
  color: ${Color.gray01};
  user-select: none;
  margin-left: 0;
  line-height: 1.2;
  position: relative;
  flex: 0 0 158px;
`;

const QRImage = styled.div`
  width: ${(props) => (props.isMobile ? '100%' : '140px')};
  height: ${(props) => (props.isMobile ? '' : '140px')};
  padding: ${Space.s8};
  border-radius: ${Space.s8};
  border: solid 2px rgba(233, 233, 233, 0.7);
  background-color: ${Color.white};
  margin-top: ${(props) => (props.isMobile ? Space.s8 : '0px')};
  display: ${(props) => (props.isPrint ? 'none' : 'inline-block')};
  ${(props) => (props.overviewStyle ? 'cursor: pointer;' : '')} > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SecondaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: ${FontSize.s16};
  color: ${Color.gray01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  background-color: ${Color.white};
  border: 2px solid ${Color.gray05};
  border-radius: ${(props) => (props.rounded ? '50px' : '0px')};
  text-decoration: none;

  img {
    width: 20px;
    height: 20px;
    margin-right: ${Space.s8};
  }

  :disabled {
    opacity: 0.4;
  }
`;

const Button = styled(SecondaryButton)`
  width: ${(props) => (props.isMobile ? '' : '200px')};
  margin-right: ${(props) => (props.isMobile ? '' : '16px')};
  border-radius: 24px;
  img {
    margin-right: 8px;
  }
  margin-top: ${(props) => (props.isMobile ? Space.s8 : '0px')};
  @media ${theme.device.tablet} {
    margin-right: 0;
  }
`;

const DownloadButton = styled(Button)`
  margin-right: ${(props) => (!props.overviewStyle ? '16px' : '0')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer:  ${(props) => (props.disabled ? 'default' : 'auto')};
`;

const PrintButton = styled(PrimaryButton)`
  border-radius: 24px;
  @media ${theme.device.tablet} {
    width: 100%;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  @media ${theme.device.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`;

interface QRCodeProps {
  link: string;
  label: FormattedMessage.MessageDescriptor;
  button: FormattedMessage.MessageDescriptor;
  buttonQRCodePrint: FormattedMessage.MessageDescriptor;
  fetchingQRCode: boolean;
  fetchQRCodeError: boolean;
  qrCode: string | undefined;
  fetchQRCode(link: string): void;
  onCopy(): void;
  isMobile: boolean;
  tip: string;
  overviewStyle?: boolean;
  disabled: boolean;
}

export default function QRCode(props: QRCodeProps) {
  const {
    qrCode,
    fetchingQRCode,
    fetchQRCodeError,
    fetchQRCode,
    link,
    isMobile,
    overviewStyle,
    tip,
    button,
    buttonQRCodePrint,
    label,
    disabled,
  } = props;
  const componentRef = useRef<HTMLImageElement>(null);
  if (!qrCode && !fetchingQRCode && !fetchQRCodeError && fetchQRCode) {
    fetchQRCode(link);
  }
  if (fetchingQRCode || fetchQRCodeError || !qrCode) {
    return (
      <Row>
        <Loader />
      </Row>
    );
  }
  if (isMobile) {
    return (
      <div>
        <QRImage isMobile>
          <img src={qrCode} alt={link} />
        </QRImage>
        <Button href={qrCode} download="QRCode.png" isMobile>
          <img src="/downloadicon.svg" alt="" />
          <FormattedMessage {...button} />
        </Button>
      </div>
    );
  }
  const openInNewTab = () => {
    if (overviewStyle) {
      const image = new Image();
      image.src = qrCode;
      const w = window.open();
      if (w) {
        w.document.write(
          `<body style="background: #ffff;"><img style="margin: auto; display: block;" src="${qrCode}"></img><body>`,
        );
        w.document.location = '#';
      }
    }
  };
  return (
    <Row overviewStyle={overviewStyle} disabled={disabled}>
      <Label overviewStyle={overviewStyle}>
        <FormattedMessage {...label} />
        <br />
        {tip && (
          <Tooltip
            style={{ left: '-30%', right: 'unset', transform: 'unset' }}
            arrowStyle={{ left: '18%' }}
            position="top"
          >
            <div>
              <BlokkieTypography
                variant="pl500"
                style={{
                  color: Color.gray01,
                  marginBottom: blokkieTheme.spacing(1),
                }}
              >
                {tip}
              </BlokkieTypography>
            </div>
          </Tooltip>
        )}
      </Label>
      <QRImage onClick={openInNewTab} overviewStyle={overviewStyle}>
        <img
          style={{ maxWidth: '350px', height: 'auto' }}
          src={qrCode}
          alt={link}
          ref={componentRef}
        />
      </QRImage>
      <Buttons>
        <DownloadButton
          overviewStyle={overviewStyle}
          href={qrCode}
          download="QRCode.png"
          disabled={disabled}
        >
          <img src="/downloadicon.svg" alt="" />
          <FormattedMessage {...button} />
        </DownloadButton>
        {buttonQRCodePrint && (
          <ReactToPrint
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={() => (
              <PrintButton disabled={disabled}>
                <img src="/print.svg" alt="" />
                <FormattedMessage {...buttonQRCodePrint} />
              </PrintButton>
            )}
            content={() => componentRef.current}
          />
        )}
      </Buttons>
    </Row>
  );
}
/*
          

*/

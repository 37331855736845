/*
 * ShareTikkie Messages
 *
 * This contains all the text for the ShareTikkie component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ShareTikkie.header',
    defaultMessage: '👏🏼 Tikkie aangemaakt!',
  },
  shareAgainHeader: {
    id: 'app.components.ShareTikkie.shareAgainHeader',
    defaultMessage: 'Deel Tikkie',
  },
  shareHeader: {
    id: 'app.components.ShareTikkie.shareHeader',
    defaultMessage: "🚀 Hoe wil je 'm delen?",
  },
  back: {
    id: 'app.components.ShareTikkie.back',
    defaultMessage: 'Overzicht',
  },
  description: {
    id: 'app.components.ShareTikkie.description',
    defaultMessage: 'Omschrijving',
  },
  invoiceNumber: {
    id: 'app.components.ShareTikkie.invoiceNumber',
    defaultMessage: 'Kenmerk',
  },
  amount: {
    id: 'app.components.ShareTikkie.amount',
    defaultMessage: 'Bedrag',
  },
  open: {
    id: 'app.components.ShareTikkie.open',
    defaultMessage: 'Open',
  },
  shareViaLink: {
    id: 'app.components.ShareTikkie.shareViaLink',
    defaultMessage: 'Deel de link',
  },
  shareViaSuggestionLink: {
    id: 'app.components.ShareTikkie.shareViaSuggestionLink',
    defaultMessage: 'Deel de link met voorstel',
  },
  shareViaSms: {
    id: 'app.components.ShareTikkie.shareViaSms',
    defaultMessage: 'Deel via sms',
  },
  buttonCopyLink: {
    id: 'app.components.ShareTikkie.buttonCopyLink',
    defaultMessage: 'Link kopiëren',
  },
  buttonSmsLink: {
    id: 'app.components.ShareTikkie.buttonSmsLink',
    defaultMessage: 'Sms Versturen',
  },
  buttonQRCodeDownload: {
    id: 'app.components.ShareTikkie.buttonDownloadQR',
    defaultMessage: 'QR downloaden',
  },
  buttonQRCodePrint: {
    id: 'app.components.ShareTikkie.buttonPrintQR',
    defaultMessage: 'QR printen',
  },
  addAnotherNumber: {
    id: 'app.components.ShareTikkie.addAnotherNumber',
    defaultMessage: 'Nog een 06-nummer',
  },
  sentStatusOk: {
    id: 'app.components.ShareTikkie.sentStatusOk',
    defaultMessage: 'Verstuurd',
  },
  sentStatusNOk: {
    id: 'app.components.ShareTikkie.sentStatusNOk',
    defaultMessage: 'Versturen mislukt',
  },
  copied: {
    id: 'app.components.ShareTikkie.copied',
    defaultMessage: 'Link gekopieerd op klembord!',
  },
  shareViaQRCode: {
    id: 'app.components.ShareTikkie.shareViaQRCode',
    defaultMessage: 'Deel de QR code',
  },
  shareViaSuggestionQRCode: {
    id: 'app.components.ShareTikkie.shareViaSuggestionQRCode',
    defaultMessage: 'Deel de QR-code met voorstel',
  },
  qrCodeInfo: {
    id: 'app.components.ShareTikkie.qrCodeInfo',
    defaultMessage:
      'Iedereen kan deze QR-code scannen met een smartphone-camera.',
  },
  qrCodeInfoTip: {
    id: 'app.components.ShareTikkie.qrCodeInfoTip',
    defaultMessage: 'Tip: download en print de QR-code en plak deze ergens op.',
  },
});

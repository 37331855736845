const select = 'SELECT';
const percentage = 'PERCENTAGE';
const owner = 'OWNER';
const control = 'CONTROL';

export const involvementSteps = {
  select,
  percentage,
};

export const involvementTypes = {
  owner,
  control,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Line } from 'rc-progress';
import React from 'react';

import '!file-loader?name=[name].[ext]!../../../images/bg-pattern.svg';
import '!file-loader?name=[name].[ext]!../../../images/businesslogo.png';
import '!file-loader?name=[name].[ext]!../../../images/checkmark2-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/portalbacking.jpg';
import '!file-loader?name=[name].[ext]!../../../images/tikkie-logo-onboarding.svg';

import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
  BlokkieIcon,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Color } from '../../styleguide';
import Error, { IError } from '../ErrorScreen';
import Loader from '../Loader';
import PortalBacking from './PortalBacking';
import Footer from './footer';
import Header from './header';
import {
  productTypeToProductInfoUrlMap,
  productTypeToLokaliseTag,
  tikkieBusiness,
} from '../../../containers/OnboardingKYCContainer/config';

interface IPropsProgressBar {
  percent: number;
}

function ProgressBar({ percent }: IPropsProgressBar): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.bar}>
      <Line
        className="rounded"
        percent={percent}
        strokeWidth={2.5}
        trailWidth={2.5}
        strokeLinecap="round"
        strokeColor={blokkieTheme.colors.primary.green}
        trailColor={percent === 0 ? Color.white : Color.backgroundColor}
      />
    </div>
  );
}

interface IProps {
  children: React.ReactNode;
  progressPercentage: number;
  pending: boolean;
  portalBacking: boolean;
  organisationName: string;
  legalEntity: string;
  hasError?: boolean;
  error?: IError;
  expandOnboardingKYCContainer: boolean;
  logout: () => void;
  clearError?: () => void;
  productType?: string;
}

function FormWrapper({
  portalBacking,
  progressPercentage,
  children,
  hasError,
  error,
  pending,
  organisationName,
  legalEntity,
  expandOnboardingKYCContainer,
  logout,
  clearError,
  productType,
}: IProps): JSX.Element {
  const goToHowItWorks = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const url = productTypeToProductInfoUrlMap.get(productType!)!;
    window.open(url);
  };

  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productTag = productTypeToLokaliseTag.get(
    productType || tikkieBusiness,
  )!;

  return (
    <>
      {portalBacking && (
        <PortalBacking logout={logout} organisationName={organisationName}>
          <div
            className={[classes.wrapperInner, classes.portalBacking].join(' ')}
          >
            <div
              className={[
                classes.formWrapper,
                expandOnboardingKYCContainer && classes.expand,
              ].join(' ')}
            >
              {!hasError && <ProgressBar percent={progressPercentage} />}
              {pending && <Loader />}
              {hasError && (
                <Error
                  error={error}
                  organisationName={organisationName}
                  legalEntity={legalEntity}
                  clearError={clearError}
                />
              )}
              {!pending && !hasError && children}
            </div>
          </div>
        </PortalBacking>
      )}
      {!portalBacking && (
        <div className={classes.openBacking}>
          <Header />
          <div className={classes.wrapperInner}>
            <div className={classes.infoContainer}>
              <div>
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.white,
                    fontSize: '40px',
                    lineHeight: '48px',
                    fontFamily: 'MuseoSansRounded900',
                  }}
                  variant="pl700"
                >
                  {t(`onboarding.backdrop.${productTag}.title`)}
                </BlokkieTypography>
                <span className={classes.tikkieSticker}>
                  <BlokkieIcon
                    variant="tikkieSticker"
                    color={`${blokkieTheme.colors.primary.green}`}
                  />
                </span>
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.white,
                    fontSize: '40px',
                    lineHeight: '48px',
                    fontFamily: 'MuseoSansRounded900',
                  }}
                  variant="pl700"
                >
                  {productType === tikkieBusiness ? '?' : ''}
                </BlokkieTypography>
              </div>
              <div className={classes.reasonsContainer}>
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.white,
                  }}
                  variant="h3"
                >
                  {t(`onboarding.backdrop.${productTag}.reason1`)}
                </BlokkieTypography>
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.white,
                  }}
                  variant="h3"
                >
                  {t(`onboarding.backdrop.${productTag}.reason2`)}
                </BlokkieTypography>
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.white,
                  }}
                  variant="h3"
                >
                  {t(`onboarding.backdrop.${productTag}.reason3`)}
                </BlokkieTypography>
              </div>
              <div className={classes.infoButtonsContainer}>
                <BlokkieButton
                  onClick={goToHowItWorks}
                  data-test-id="Onboarding-Form-HowItWorksButton"
                  variant="secondary"
                  size="small"
                  style={{
                    background: blokkieTheme.colors.grey.white,
                  }}
                >
                  {t('onboarding.backdrop.howButton')}
                </BlokkieButton>
              </div>
            </div>
            <div
              className={[
                classes.formWrapper,
                expandOnboardingKYCContainer && classes.expand,
              ].join(' ')}
            >
              <ProgressBar percent={progressPercentage} />
              {pending && <Loader />}
              {hasError && (
                <Error
                  error={error}
                  organisationName={organisationName}
                  legalEntity={legalEntity}
                  clearError={clearError}
                />
              )}
              {!pending && !hasError && children}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

const useStyles = createUseStyles({
  openBacking: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    backgroundColor: `${blokkieTheme.colors.primary.purple}`,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [blokkieTheme.mediaQueries.tablet]: {
      justifyContent: 'start',
      overflow: 'auto',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      justifyContent: 'start',
    },
  },
  tikkieSticker: {
    position: 'relative',
    top: -blokkieTheme.spacing(1),
    marginLeft: blokkieTheme.spacing(0.5),
    marginRight: blokkieTheme.spacing(0.2),
  },
  reasonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: blokkieTheme.spacing(2),
    marginTop: blokkieTheme.spacing(2),
  },
  infoButtonsContainer: {
    display: 'flex',
    gap: blokkieTheme.spacing(4),
    marginTop: blokkieTheme.spacing(8),
  },
  infoContainer: {
    width: '440px',
    marginRight: '70px',
    marginTop: blokkieTheme.spacing(4),
    order: 1,
    alignSelf: 'flex-start',
    [blokkieTheme.mediaQueries.tablet]: {
      alignSelf: 'center',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'none',
    },
  },
  wrapperInner: {
    width: '1031px',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    order: 1,
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'initial',
      height: '100%',
    },
    [blokkieTheme.mediaQueries.tablet]: {
      width: '100%',
      flexFlow: 'column-reverse',
      justifyContent: 'center',
      flexGrow: 1,
    },
  },
  portalBacking: {
    extend: 'wrapperInner',
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    backgroundImage: 'url("/portalbacking.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  formWrapper: {
    position: 'relative',
    order: 2,
    width: '504px',
    height: '480px',
    boxShadow: '0 30px 40px 10px rgba(0, 0, 0, 0.15)',
    fontFamily: 'MuseoSansRounded',
    fontWeight: 900,
    marginBottom: '20px',
    borderRadius: '10px',
    backgroundColor: blokkieTheme.colors.grey.white,
    display: 'flex',
    flexDirection: 'column',
    padding: blokkieTheme.spacing(4),
    overflow: 'hidden',
    zIndex: 9,
    [blokkieTheme.mediaQueries.tablet]: {
      right: '24px',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
      margin: 0,
      borderRadius: 0,
      height: '100%',
      padding: blokkieTheme.spacing(3),
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  expand: {
    height: 'initial',
    minHeight: '480px',
  },
  bar: {
    marginTop: '-4px',
    width: '106%',
    position: 'absolute',
    top: 0,
    left: '-15px',
    right: 0,
    '& .rounded': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      marginTop: '-5px',
    },
  },
});

export default FormWrapper;

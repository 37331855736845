import { Campaign } from '../../types/cashback-campaigns';

export const campaignTypeIcon = (
  campaign: Campaign,
): { src: string; alt: string } => {
  if (campaign.type === 'barcode') {
    return { src: '/icon-cashback-barcode.svg', alt: campaign.type };
  }
  if (campaign.type === 'receipt') {
    return { src: '/icon-cashback-receipt.svg', alt: campaign.type };
  }
  return { src: '/icon-cashback-token.svg', alt: campaign.type };
};

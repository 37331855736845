/*
 * Search Filter Messages
 *
 *
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  search_placeholder: {
    id: 'app.components.searchFilter.search_placeholder',
    defaultMessage: 'Omschrijving of kenmerk...',
  },
  search: {
    id: 'app.components.searchFilter.search',
    defaultMessage: 'Zoeken',
  },
});

export const LOAD_SETTINGS = 'app/SmsSettingsContainer/LOAD_SETTINGS';
export const LOAD_SETTINGS_SUCCESS =
  'app/SmsSettingsContainer/LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_ERROR =
  'app/SmsSettingsContainer/LOAD_SETTINGS_ERROR';
export const UPDATE_SETTINGS = 'app/SmsSettingsContainer/UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS =
  'app/SmsSettingsContainer/UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_ERROR =
  'app/SmsSettingsContainer/UPDATE_SETTINGS_ERROR';
export const RESET_UPDATE_SETTINGS =
  'app/SmsSettingsContainer/RESET_UPDATE_SETTINGS';
export const LOAD_API_TOKENS = 'app/SmsSettingsContainer/LOAD_API_TOKENS';
export const LOAD_API_TOKENS_SUCCESS =
  'app/SmsSettingsContainer/LOAD_API_TOKENS_SUCCESS';
export const LOAD_API_TOKENS_ERROR =
  'app/SmsSettingsContainer/LOAD_API_TOKENS_ERROR';
export const CREATE_API_TOKEN = 'app/SmsSettingsContainer/CREATE_API_TOKEN';
export const CREATE_API_TOKEN_SUCCESS =
  'app/SmsSettingsContainer/CREATE_API_TOKEN_SUCCESS';
export const CREATE_API_TOKEN_ERROR =
  'app/SmsSettingsContainer/CREATE_API_TOKEN_ERROR';
export const MODIFY_API_TOKEN = 'app/SmsSettingsContainer/MODIFY_API_TOKEN';
export const MODIFY_API_TOKEN_SUCCESS =
  'app/SmsSettingsContainer/MODIFY_API_TOKEN_SUCCESS';
export const MODIFY_API_TOKEN_ERROR =
  'app/SmsSettingsContainer/MODIFY_API_TOKEN_ERROR';

export const CLEAR_SETTINGS = 'app/SmsSettingsContainer/CLEAR_SETTINGS';

export const FETCH_AUDIT_LOGS = 'app/AuditLogContainer/FETCH_AUDIT_LOGS';
export const FETCH_AUDIT_LOGS_SUCCESS =
  'app/AuditLogContainer/FETCH_AUDIT_LOGS_SUCCESS';
export const FETCH_AUDIT_LOGS_ERROR =
  'app/AuditLogContainer/FETCH_AUDIT_LOGS_ERROR';

export const FETCH_USERS = 'app/UserManagementContainer/FETCH_USERS';
export const FETCH_USERS_SUCCESS =
  'app/UserManagementContainer/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR =
  'app/UserManagementContainer/FETCH_USERS_ERROR';

export const ADD_USER_TO_ACTIVE_ORGANISATION =
  'app/UserManagementContainer/ADD_USER_TO_ACTIVE_ORGANISATION';
export const ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS =
  'app/UserManagementContainer/ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS';
export const ADD_USER_TO_ACTIVE_ORGANISATION_ERROR =
  'app/UserManagementContainer/ADD_USER_TO_ACTIVE_ORGANISATION_ERROR';
export const RESET_ADD_USER_TO_ACTIVE_ORGANISATION =
  'app/UserManagementContainer/RESET_ADD_USER_TO_ACTIVE_ORGANISATION';

export const ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION =
  'app/UserManagementContainer/ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION';
export const ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS =
  'app/UserManagementContainer/ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS';
export const ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR =
  'app/UserManagementContainer/ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR';
export const RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION =
  'app/UserManagementContainer/RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION';

export const LOAD_PERMITTED_ORGANISATIONS =
  'app/UserManagementContainer/LOAD_PERMITTED_ORGANISATIONS';
export const LOAD_PERMITTED_ORGANISATIONS_SUCCESS =
  'app/UserManagementContainer/LOAD_PERMITTED_ORGANISATIONS_SUCCESS';
export const LOAD_PERMITTED_ORGANISATIONS_ERROR =
  'app/UserManagementContainer/LOAD_PERMITTED_ORGANISATIONS_ERROR';

export const FETCH_PERMITTED_ORGANISATIONS_USERS =
  'app/UserManagementContainer/FETCH_PERMITTED_ORGANISATIONS_USERS';
export const FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS =
  'app/UserManagementContainer/FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS';
export const FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR =
  'app/UserManagementContainer/FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR';

export const FETCH_USER = 'app/UserManagementContainer/FETCH_USER';
export const FETCH_USER_SUCCESS =
  'app/UserManagementContainer/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/UserManagementContainer/FETCH_USER_ERROR';

export const UPDATE_USER = 'app/UserManagementContainer/UPDATE_USER';
export const UPDATE_USER_SUCCESS =
  'app/UserManagementContainer/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR =
  'app/UserManagementContainer/UPDATE_USER_ERROR';
export const RESET_UPDATE_USER =
  'app/UserManagementContainer/RESET_UPDATE_USER';

export const REQUEST_PASSWORD_RESET =
  'app/UserManagementContainer/REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS =
  'app/UserManagementContainer/REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_ERROR =
  'app/UserManagementContainer/REQUEST_PASSWORD_RESET_ERROR';

export const DELETE_USER = 'app/UserManagementContainer/DELETE_USER';
export const DELETE_USER_SUCCESS =
  'app/UserManagementContainer/DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR =
  'app/UserManagementContainer/DELETE_USER_ERROR';
export const RESET_DELETE_USER =
  'app/UserManagementContainer/RESET_DELETE_USER';

export const DEACTIVATE_ORGANISATION =
  'app/DeactivationSettingsContainer/DEACTIVATE_ORGANISATION';
export const DEACTIVATE_ORGANISATION_SUCCESS =
  'app/DeactivationSettingsContainer/DEACTIVATE_ORGANISATION_SUCCESS';
export const DEACTIVATE_ORGANISATION_ERROR =
  'app/DeactivationSettingsContainer/DEACTIVATE_ORGANISATION_ERROR';
export const RESET_DEACTIVATE_ORGANISATION =
  'app/DeactivationSettingsContainer/RESET_DEACTIVATE_ORGANISATION';

import React, { MouseEventHandler } from 'react';
import { blokkieTheme } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { Color, Radii } from '../../styleguide';

interface OnboardingTextBalloonProps {
  show: boolean;
  center?: boolean;
  error?: boolean;
  width?: number;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  clickable?: boolean;
  horizontalBoxCenter?: boolean;
  marginTop?: string;
}

function OnboardingTextBalloon({
  children,
  ...props
}: OnboardingTextBalloonProps): JSX.Element {
  const classes = useStyles(props);

  return (
    <div className={classes.box} {...props}>
      {children}
    </div>
  );
}
const useStyles = createUseStyles({
  box: (props: OnboardingTextBalloonProps) => ({
    position: 'relative',
    marginLeft: props.horizontalBoxCenter ? 'auto' : 'initial',
    marginRight: props.horizontalBoxCenter ? 'auto' : 'initial',
    marginTop: props.marginTop ? props.marginTop : '0',
    left: props.horizontalBoxCenter ? '0' : 'initial',
    right: props.horizontalBoxCenter ? '0' : 'initial',
    padding: `${blokkieTheme.spacing(1.5)}px`,
    borderRadius: Radii.s10,
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.15)',
    width: props.width ? `${props.width}px` : '100%',
    alignItems: props.center ? 'center' : 'initial',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 15,
    display: props.show ? 'flex' : 'none',
    backgroundColor: props.error ? Color.errorColor : Color.white,
    color: props.error ? Color.white : Color.brandColor01,
    cursor: props.onClick && !props.clickable ? 'pointer' : 'cursor',
    '& p, & div': {
      backgroundColor: props.error ? Color.errorColor : Color.white,
      color: props.error ? Color.white : Color.brandColor01,
    },
  }),
});

export default OnboardingTextBalloon;

import '!file-loader?name=[name].[ext]!../../../images/icons/cogwheel.svg';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Color } from '../../styleguide';
import { uppercaseFirstLetterAndLowercaseTheRest } from '../../../utils/strings';

const UserListItemWrapper = styled.div`
  position: relative;
  display: block;
  height: 72px;
  line-height: 72px;
  padding: 0 24px 0 0;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? '#f0f0f0' : Color.white)};
  box-shadow: 0 2px 4px 0
    ${(props) =>
      props.selected ? 'rgba(57, 164, 161, 0.2)' : 'rgba(0, 0, 0, 0.15)'};
  ${(props) => (props.color ? `border-left: 8px solid ${props.color}` : '')};
  ${(props) =>
    props.selected ? `outline: rgba(57, 164, 161, 0.2) solid 2px` : ''};
  vertical-align: middle;
  cursor: ${(props) => !props.disabled && 'pointer'};
  font-family: MuseoSansRounded700;
  font-size: 16px;

  :hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 0;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 20px 16px 20px 24px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin: 0px 0px 16px 0px;
  }
`;

const ItemIcon = styled.div`
  i {
    color: ${Color.brandColor02};
    opacity: 0.41;
  }
`;

const Username = styled.div`
  float: left;
  width: 300px;
  margin-left: 16px;
  height: 72px;
  line-height: 72px;
  font-family: MuseoSansRounded;
  color: ${(props) => (props.selected ? Color.brandColor01 : '#57565b')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    height: 24px;
    margin-left: 0px;
    line-height: 24px;
  }
`;

const Email = styled.div`
  float: left;
  width: 380px;
  display: block;
  height: 72px;
  line-height: 72px;
  font-family: MuseoSansRounded;
  color: ${(props) => (props.selected ? Color.brandColor01 : '#57565b')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  @media ${(props) => props.theme.device.mobile} {
    width: 300px;
    height: 24px;
    line-height: 24px;
  }
`;

const LastItem = styled.div`
  width: auto;
  height: 72px;
  line-height: 72px;
  font-family: MuseoSansRounded;
  color: ${Color.gray02};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

export function UserListItem({
  user,
  dataTestId,
  selected,
  onClick,
  disabled,
  color,
}) {
  const getLinkedOrganisationsAsText = () => {
    const { linkedOrganisations } = user;
    return linkedOrganisations.length < 2
      ? linkedOrganisations
      : `${linkedOrganisations[0]} + ${
          linkedOrganisations.length - 1
        } andere locatie${linkedOrganisations.length > 2 ? `s` : ''}`;
  };

  return (
    <UserListItemWrapper
      onClick={disabled ? () => {} : onClick}
      selected={selected}
      data-test-id={dataTestId || 'user-list-item'}
      disabled={disabled}
      color={color}
    >
      <ItemIconWrapper>
        <ItemIcon>
          <i className="fa fa-user" />
        </ItemIcon>
      </ItemIconWrapper>
      <ContentWrapper>
        <Username
          selected={selected}
          data-test-id="username"
        >{`${user.firstName} ${user.lastName}`}</Username>
        <Email selected={selected}>{user.email}</Email>
        {user.functionalRole && (
          <LastItem>
            {uppercaseFirstLetterAndLowercaseTheRest(user.functionalRole)}
          </LastItem>
        )}
        {user.linkedOrganisations && (
          <LastItem>{getLinkedOrganisationsAsText()}</LastItem>
        )}
      </ContentWrapper>
    </UserListItemWrapper>
  );
}

UserListItem.propTypes = {
  user: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

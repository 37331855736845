import React from 'react';
import { injectIntl } from 'react-intl';
import { BlokkieButton, blokkieTheme } from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '!file-loader?name=[name].[ext]!../../../images/gifs/cat-computer.gif';
import {
  Label,
  Wrapper,
  CenterWrapper,
  OnboardingParagraph,
} from '../styledComponents';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import { useOnboardingPageView } from '../../../hooks';

interface ReferToAbnProps {
  prevStep: () => void;
}

function ReferToAbn(props: ReferToAbnProps): JSX.Element {
  const { t } = useTranslation();
  const { prevStep } = props;
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);

  useOnboardingPageView('refer-to-abn');

  const goToIbz = (): void => {
    analyticsEvent(
      'refer_to_IBZ',
      AnalyticCategories.ONBOARDING,
      'onboarding-event',
      {
        flow: onboardingFlow,
        product: analyticsProductTypeMapper(productType),
        event: 'click_button',
        hasAbnBank,
      },
    );
    window.open('https://www.tikkie.me/zakelijk/aanvragen');
  };

  return (
    <Wrapper>
      <NavigationHeader clickLeft={prevStep as () => void} />
      <CenterWrapper padding="0px" {...props}>
        <BlokkieGifContainer
          source="../hardworking-cat.mp4"
          style={{
            marginBottom: `${blokkieTheme.spacing(2)}px`,
          }}
        />
        <Label textAlign="center">
          {t('onboarding.referToAbnScreen.ibzHeader')}
        </Label>
        <OnboardingParagraph narrow textAlign="center">
          {t('onboarding.referToAbnScreen.ibzText')}
          <br />
        </OnboardingParagraph>
        <ButtonFooter>
          <BlokkieButton
            onClick={goToIbz}
            data-test-id="Onboarding-WhichBank-IBZ-NextButton"
            variant="primary"
            size="large"
          >
            {t('onboarding.referToAbnScreen.ibzButton')}
          </BlokkieButton>
        </ButtonFooter>
      </CenterWrapper>
    </Wrapper>
  );
}

export default injectIntl<ReferToAbnProps>(ReferToAbn);

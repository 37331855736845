import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import injectSaga from '../../../../utils/injectSaga';
import saga from '../../saga';
import {
  addExistingUserToActiveOrganisation,
  fetchPermittedOrganisationsUsers,
  loadPermittedOrganisations,
  resetAddExistingUserToActiveOrganisation,
} from '../../actions';
import {
  selectAddExistingUserToActiveOrganisationError,
  selectAddExistingUserToActiveOrganisationLoading,
  selectAddExistingUserToActiveOrganisationSuccess,
  selectPermittedOrganisations,
  selectPermittedOrganisationsError,
  selectPermittedOrganisationsLoading,
  selectPermittedOrganisationsUsers,
  selectPermittedOrganisationsUsersError,
  selectPermittedOrganisationsUsersLoading,
} from '../../selectors';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../../components/components';
import AddExistingUser from '../../../../components/Settings/UserManagement/AddExistingUser';
import Loader from '../../../../components/OnboardingKYC/Loader';

function AddExistingUserContainer({
  settingsLoading,
  users,
  usersLoading,
  usersError,
  getUsers,
  getPermittedOrganisations,
  permittedOrganisations,
  permittedOrganisationsLoading,
  permittedOrganisationsError,
  addExistingUserToActiveOrganisationSuccess,
  addExistingUserToActiveOrganisationLoading,
  addExistingUserToActiveOrganisationError,
  dispatchAddExistingUserToActiveOrganisation,
  dispatchResetAddExistingUserToActiveOrganisation,
}) {
  const history = useHistory();

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const goToListUsers = () => {
    history.push('/settings/user-management/users');
  };

  useEffect(() => {
    if (isEmpty(permittedOrganisations)) {
      getPermittedOrganisations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(settingsLoading ||
        permittedOrganisationsLoading ||
        addExistingUserToActiveOrganisationLoading) && <Loader />}
      {!settingsLoading &&
        !permittedOrganisationsLoading &&
        !addExistingUserToActiveOrganisationLoading && (
          <DashboardWrapper>
            <Container>
              <InnerContainer highMargin>
                <AddExistingUser
                  gotoPortalOverview={gotoPortalOverview}
                  goBack={goToListUsers}
                  users={users}
                  usersLoading={usersLoading}
                  usersError={usersError}
                  fetchUsers={getUsers}
                  permittedOrganisations={permittedOrganisations}
                  permittedOrganisationsError={permittedOrganisationsError}
                  addExistingUserToActiveOrganisationSuccess={
                    addExistingUserToActiveOrganisationSuccess
                  }
                  addExistingUserToActiveOrganisationError={
                    addExistingUserToActiveOrganisationError
                  }
                  addExistingUserToActiveOrganisation={
                    dispatchAddExistingUserToActiveOrganisation
                  }
                  resetAddExistingUserToActiveOrganisation={
                    dispatchResetAddExistingUserToActiveOrganisation
                  }
                />
              </InnerContainer>
            </Container>
          </DashboardWrapper>
        )}
    </>
  );
}

AddExistingUserContainer.propTypes = {
  users: PropTypes.shape({
    users: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  settingsLoading: PropTypes.bool,
  addExistingUserToActiveOrganisationLoading: PropTypes.bool,
  usersLoading: PropTypes.bool.isRequired,
  usersError: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getPermittedOrganisations: PropTypes.func.isRequired,
  permittedOrganisations: PropTypes.array,
  permittedOrganisationsLoading: PropTypes.bool.isRequired,
  permittedOrganisationsError: PropTypes.object.isRequired,
  addExistingUserToActiveOrganisationSuccess: PropTypes.object.isRequired,
  addExistingUserToActiveOrganisationError: PropTypes.object.isRequired,
  dispatchAddExistingUserToActiveOrganisation: PropTypes.func.isRequired,
  dispatchResetAddExistingUserToActiveOrganisation: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  users: selectPermittedOrganisationsUsers,
  usersLoading: selectPermittedOrganisationsUsersLoading,
  usersError: selectPermittedOrganisationsUsersError,
  permittedOrganisations: selectPermittedOrganisations,
  permittedOrganisationsLoading: selectPermittedOrganisationsLoading,
  permittedOrganisationsError: selectPermittedOrganisationsError,
  addExistingUserToActiveOrganisationSuccess:
    selectAddExistingUserToActiveOrganisationSuccess,
  addExistingUserToActiveOrganisationLoading:
    selectAddExistingUserToActiveOrganisationLoading,
  addExistingUserToActiveOrganisationError:
    selectAddExistingUserToActiveOrganisationError,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (pagination) =>
    dispatch(fetchPermittedOrganisationsUsers(pagination)),
  getPermittedOrganisations: () => dispatch(loadPermittedOrganisations()),
  dispatchAddExistingUserToActiveOrganisation: (user) =>
    dispatch(addExistingUserToActiveOrganisation(user)),
  dispatchResetAddExistingUserToActiveOrganisation: () =>
    dispatch(resetAddExistingUserToActiveOrganisation()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'AddExistingUserContainer', saga });

export default compose(withSaga, withConnect)(AddExistingUserContainer);

/**

Transaction Bundle Component

* */

import React from 'react';
import PropTypes from 'prop-types';

import TransactionLineItem from '../TransactionLineItem';
import TransactionBundleHeader from '../TransactionBundleHeader';

function TransactionBundle(props) {
  const isBundle = (bundle) => bundle.payoutMethod === 'BUNDLED';

  const isBundlePaid = (bundle) =>
    isBundle(bundle) && bundle.bundleStatus === 'PAID';

  return (
    <div>
      <TransactionBundleHeader
        isBundle={isBundle(props.bundle)}
        isBundlePaid={isBundlePaid(props.bundle)}
        date={props.bundle.date}
        amountInCents={props.bundle.totalAmountInCents}
        downloadCamt={props.downloadCamt}
        hasInternationalTransaction={props.bundle.hasInternationalTransaction}
      />
      {props.bundle.transactions.map((transaction) => (
        <TransactionLineItem
          showPayerDetails={props.showPayerDetails}
          key={`${transaction.id}${transaction.token}`}
          payment={transaction}
          invoiceNumber={transaction.referenceId}
          description={transaction.description}
          showTransactionDetail={() => props.showTransactionDetail(transaction)}
          showOnlyTime={props.showBundleHeader}
        />
      ))}
    </div>
  );
}

TransactionBundle.propTypes = {
  showTransactionDetail: PropTypes.func.isRequired,
  bundle: PropTypes.object.isRequired,
  showPayerDetails: PropTypes.bool.isRequired,
  showBundleHeader: PropTypes.bool.isRequired,
  downloadCamt: PropTypes.func.isRequired,
};

export default TransactionBundle;

import {
  BACKGROUND_LOGOUT,
  LOAD_USER,
  LOAD_USER_ERROR,
  LOAD_USER_SUCCESS,
  RESET_USER_UPDATE,
  SAVE_PAYMENT_REQUEST_DRAFT,
  SET_CURRENT_PAGE_NAME,
  SWITCH_ORGANISATION,
  CONFIRM_EMAIL_CHANGE,
  CONFIRM_EMAIL_CHANGE_SUCCESS,
  CONFIRM_EMAIL_CHANGE_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from './constants';

export function backgroundLogout() {
  return { type: BACKGROUND_LOGOUT };
}

export function loadUser() {
  return {
    type: LOAD_USER,
  };
}

export function loadUserSuccess(user) {
  return {
    type: LOAD_USER_SUCCESS,
    payload: {
      user,
    },
  };
}

export function loadUserError(error) {
  return {
    type: LOAD_USER_ERROR,
    payload: {
      error,
    },
  };
}

export function confirmEmailChange(token, password) {
  return { type: CONFIRM_EMAIL_CHANGE, payload: { token, password } };
}

export function confirmEmailChangeSuccess() {
  return { type: CONFIRM_EMAIL_CHANGE_SUCCESS };
}

export function confirmEmailChangeError() {
  return { type: CONFIRM_EMAIL_CHANGE_ERROR };
}

export function updateUser(user, doEmailUpdate) {
  return {
    type: UPDATE_USER,
    payload: { user, doEmailUpdate },
  };
}

export function updateUserSuccess(user, checkEmail) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: { user, checkEmail },
  };
}

export function updateUserError(error) {
  return {
    type: UPDATE_USER_ERROR,
    payload: error,
  };
}

export function resetUserUpdate() {
  return {
    type: RESET_USER_UPDATE,
  };
}

export function savePaymentRequestDraft(paymentRequestDraft) {
  return {
    type: SAVE_PAYMENT_REQUEST_DRAFT,
    payload: paymentRequestDraft,
  };
}

export function setCurrentPageName(pageName) {
  return {
    type: SET_CURRENT_PAGE_NAME,
    payload: pageName,
  };
}

export function switchOrganisation(id) {
  return {
    type: SWITCH_ORGANISATION,
    payload: {
      id,
    },
  };
}

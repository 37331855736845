import React, { ChangeEventHandler, KeyboardEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { blokkieTheme } from '@tikkie/blokkie';
import { FontSize, FontWeight } from '../../styleguide';
import InputKeyboard from '../components/InputKeyboard';

interface InputLabelProps {
  dataTestId?: string;
  style?: React.CSSProperties;
  label?: string;
  text?: string;
  name?: string;
  disabled?: boolean;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
  },
  label: {
    fontSize: FontSize.s14,
    lineHeight: FontSize.s18,
    fontWeight: FontWeight.s500,
    fontFamily: 'MuseoSansRounded700',
    color: blokkieTheme.colors.grey.veryDark,
    marginBottom: blokkieTheme.spacing(1),
  },
  input: {
    fontSize: FontSize.s18,
    lineHeight: FontSize.s24,
    fontFamily: 'MuseoSansRounded700',
    padding: '12px 16px',
    textAlign: 'left',
    width: '100%',
    color: blokkieTheme.colors.primary.green,
    backgroundColor: blokkieTheme.colors.grey.white,
    borderRadius: '6px',
    border: `2px solid ${blokkieTheme.colors.grey.light}`,
    outline: 'none',
    '&:focus': {
      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.18)',
    },
    '&:disabled': {
      color: blokkieTheme.colors.grey.dark,
      WebkitTextFillColor: blokkieTheme.colors.grey.dark,
      opacity: 1,
      backgroundColor: blokkieTheme.colors.grey.veryLight,
    },
    '&::placeholder': {
      color: blokkieTheme.colors.grey.default,
    },
  },
  [blokkieTheme.mediaQueries.smallPhone]: {
    input: {
      fontSize: FontSize.s14,
      lineHeight: FontSize.s18,
    },
  },
});

export function InputLabel({
  dataTestId,
  style,
  label,
  placeholder,
  name,
  text,
  onChange,
  onKeyDown,
  maxLength,
  disabled,
}: InputLabelProps): JSX.Element {
  const classes = useStyles();
  return (
    <div style={style} className={classes.wrapper} data-test-id={dataTestId}>
      {label && <div className={classes.label}>{label}</div>}
      {disabled ? (
        <input
          name={name}
          maxLength={maxLength}
          placeholder={placeholder}
          className={classes.input}
          disabled={disabled}
          defaultValue={text}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      ) : (
        <InputKeyboard
          name={name}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={text}
          onChange={onChange}
          onKeyDown={onKeyDown}
          relatedButtonId="button-footer"
          labelInput
        />
      )}
    </div>
  );
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { OnboardingEntry } from '../../../components/OnboardingKYC';
import ChooseCountry from '../../../components/OnboardingKYC/ChooseCountry';
import LinkNotFound from '../../../components/OnboardingKYC/LinkNotFound';
import ProcessExplanation from '../../../components/OnboardingKYC/ProcessExplanation';
import VerifyOrganisation from '../../../components/OnboardingKYC/VerifyOrganisation';
import FormWrapperContainer from '../FormWrapperContainer';
import { entrySteps as organisationSteps } from '../StartOnboardingContainer/constants';
import { setCountry } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectCountry,
  selectGenericError,
  selectOnboardingKYCData,
  selectProductType,
  selectTerms,
} from '../selectors';
import startSteps from './constants';

const startStepsArr = [
  startSteps.agreement,
  startSteps.processExplanation,
  startSteps.kvkOverview,
  startSteps.country,
];

function StartSecondApplicantContainer(props) {
  const [currentStep, setCurrentStep] = useState(startSteps.agreement);

  const nextStep = () =>
    setCurrentStep(props.getNextStep(startStepsArr, currentStep));
  const prevStep = () =>
    setCurrentStep(props.getPreviousStep(startStepsArr, currentStep));

  const tokenOrFileNotFound = props.genericError.errorCode === 2117;
  const hasSpecificError = tokenOrFileNotFound;
  const firstStep = currentStep === organisationSteps.agreement;
  return (
    <FormWrapperContainer
      noProgressBar={firstStep}
      prevStep={prevStep}
      hasSpecificError={hasSpecificError}
    >
      {!hasSpecificError && (
        /* eslint-disable-next-line react/jsx-no-useless-fragment */
        <>
          {currentStep === startSteps.agreement && (
            <OnboardingEntry
              nextStep={nextStep}
              productType={props.productType}
            />
          )}
          {currentStep === startSteps.processExplanation && (
            <ProcessExplanation
              nextStep={nextStep}
              previousStep={prevStep}
              forSecondApplicant
            />
          )}
          {currentStep === startSteps.kvkOverview && (
            <VerifyOrganisation
              nextStep={nextStep}
              onboardingData={props.onboardingData}
              prevStep={prevStep}
              forSecondApplicant
            />
          )}
          {currentStep === startSteps.country && (
            <ChooseCountry
              saveCountry={props.saveCountry}
              selectedCountry={props.country}
              onClickBack={prevStep}
            />
          )}
        </>
      )}
      {hasSpecificError && tokenOrFileNotFound && <LinkNotFound />}
    </FormWrapperContainer>
  );
}

StartSecondApplicantContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
  genericError: PropTypes.object.isRequired,
  onboardingData: PropTypes.object.isRequired,
  country: PropTypes.string,
  saveCountry: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  terms: selectTerms,
  productType: selectProductType,
  genericError: selectGenericError,
  onboardingData: selectOnboardingKYCData,
  country: selectCountry,
});

const mapDispatchToProps = (dispatch) => ({
  saveCountry: (countryCode) => dispatch(setCountry(countryCode, true)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(StartSecondApplicantContainer);

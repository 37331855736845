import { BlokkieIconButton, IconVariantType } from '@tikkie/blokkie';
import React, { useState } from 'react';

interface PasswordHideOrShowIconProps {
  setInputType: React.Dispatch<React.SetStateAction<string>>;
  inputType: string;
}

function PasswordHideOrShowIcon({
  setInputType,
  inputType,
}: PasswordHideOrShowIconProps): JSX.Element {
  const [iconVariant, setIconVariant] = useState<IconVariantType>('eyeStriked');

  const hideOrShowPassword = () => {
    if (inputType === 'password') {
      setInputType('text');
      setIconVariant('eye');
    } else {
      setInputType('password');
      setIconVariant('eyeStriked');
    }
  };

  return (
    <BlokkieIconButton
      style={{ marginLeft: '-50px', alignSelf: 'center' }}
      variant={iconVariant}
      onClick={hideOrShowPassword}
      data-test-id="eye-icon"
    />
  );
}

export default PasswordHideOrShowIcon;

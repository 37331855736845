import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';
import { Button, Container, Header4 } from 'amber';
import {
  hasAtLeast8Characters,
  hasLowercaseLetter,
  hasNumber,
  hasSpecialCharacterNoSpaces,
  hasUppercaseLetter,
  hasNoWeirdCharacters,
  PasswordMatchVerification,
  PasswordVerification,
} from '../PasswordVerification';
import { InputErrorBox } from '../Settings/styledComponents';
import Password from '../Password/Password';

const PasswordButtonWrapper = styled(Container)`
  height: 24px;
`;

function Title({ children, minHeight }) {
  const Wrapper = minHeight ? PasswordButtonWrapper : Container;
  return (
    <Wrapper col={3} colT={12} colM={12} mt={12} mb={12} mbM={0} verticalCenter>
      <Container row>
        <Container pb={0} col={12} colT={11} colM={10}>
          {children}
        </Container>
      </Container>
    </Wrapper>
  );
}

Title.propTypes = {
  children: PropTypes.any,
  minHeight: PropTypes.bool,
};

function Field({ children, noMarginBottom }) {
  return (
    <Container
      col={9}
      colT={12}
      colM={12}
      mt={12}
      mb={noMarginBottom ? 0 : 12}
      mtM={0}
    >
      <Container row>
        <Container col={12} colT={12} colM={12}>
          {children}
        </Container>
      </Container>
    </Container>
  );
}

Field.propTypes = {
  children: PropTypes.any.isRequired,
  noMarginBottom: PropTypes.bool,
};

export function PasswordUpdate({
  values,
  onChange,
  cancelled,
  onCancelPassword,
  readyToUpdate,
  setReadyToUpdate,
  updateUserError,
  showPasswordUpdateInitial,
}) {
  const { currentPassword, password } = values;
  const [showUpdatePassword, setShowUpdatePassword] = useState(
    showPasswordUpdateInitial,
  );
  const [invalidCurrentPasswordError, setInvalidCurrentPasswordError] =
    useState(updateUserError && updateUserError.errorCode === 2050);
  const [reusedOldPasswordError, setReusedOldPasswordError] = useState(
    updateUserError && updateUserError.errorCode === 2040,
  );
  const [newPassword, setNewPassword] = useState('');
  const initialValidations = {
    hasUppercaseLetter: false,
    hasLowercaseLetter: false,
    hasNumber: false,
    hasSpecialCharacterNoSpaces: false,
    hasAtLeast8Characters: false,
    hasNoWeirdCharacters: true,
    passwordsMatch: false,
  };
  const [validations, setValidations] = useState(initialValidations);

  const passwordsMatch = (newP, newPasswordConfirm) =>
    newPasswordConfirm !== '' && newP === newPasswordConfirm;

  const verifyNewPassword = (value) => {
    setValidations({
      hasUppercaseLetter: hasUppercaseLetter(value),
      hasLowercaseLetter: hasLowercaseLetter(value),
      hasNumber: hasNumber(value),
      hasSpecialCharacterNoSpaces: hasSpecialCharacterNoSpaces(value),
      hasAtLeast8Characters: hasAtLeast8Characters(value),
      hasNoWeirdCharacters: hasNoWeirdCharacters(value),
      passwordsMatch: passwordsMatch(value, password),
    });
  };

  const verifyNewPasswordConfirm = (value) => {
    setValidations((prevValues) => ({
      ...prevValues,
      passwordsMatch: passwordsMatch(newPassword, value),
    }));
  };

  const validNewPassword = () =>
    !showUpdatePassword ||
    (Object.values(validations).every((v) => v) && currentPassword.length >= 8);

  const cancel = () => {
    setShowUpdatePassword(false);
    setNewPassword('');
    setValidations(initialValidations);
    setInvalidCurrentPasswordError(false);
    setReusedOldPasswordError(false);
    onCancelPassword();
  };

  const checkReadyToUpdate = () => {
    if (validNewPassword() && !readyToUpdate) {
      setReadyToUpdate(true);
    } else if (!validNewPassword() && readyToUpdate) {
      setReadyToUpdate(false);
    }
  };

  useEffect(() => {
    checkReadyToUpdate();
  }, [validations, currentPassword]);

  useEffect(() => {
    verifyNewPassword(newPassword);
  }, [newPassword]);

  useEffect(() => {
    verifyNewPasswordConfirm(values.password);
  }, [values.password]);

  useEffect(() => {
    if (cancelled) cancel();
  }, [cancelled]);

  return (
    <Container row panel rounded mb={24}>
      <Title minHeight>
        <Header4>Wachtwoord</Header4>
      </Title>
      <Container col={9} colT={12} colM={12} verticalCenter>
        {!showUpdatePassword && (
          <Button
            type="secondary"
            dataTestId="current-user-password-update-button"
            onClick={() => setShowUpdatePassword(true)}
          >
            Wachtwoord aanpassen
          </Button>
        )}
        {showUpdatePassword && (
          <Button
            type="secondary"
            dataTestId="current-user-password-cancel-button"
            onClick={cancel}
          >
            Annuleer wachtwoord aanpassen
          </Button>
        )}
      </Container>
      {showUpdatePassword && (
        <>
          <Title>
            <Header4>Huidige wachtwoord</Header4>
          </Title>
          <Field noMarginBottom={invalidCurrentPasswordError}>
            <Password
              inputField
              value={currentPassword}
              onChange={(event) =>
                onChange('currentPassword', event.target.value)
              }
              data-test-id="current-user-password-current-input"
              noAutocomplete
            />
          </Field>
          {invalidCurrentPasswordError && (
            <InputErrorBox errorText="Oeps, je wachtwoord klopt niet" />
          )}
          <Title>
            <Header4>Kies nieuw wachtwoord</Header4>
          </Title>
          <Field
            noMarginBottom={
              !validations.hasNoWeirdCharacters || reusedOldPasswordError
            }
          >
            <Password
              inputField
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              data-test-id="current-user-password-new-input"
            />
          </Field>
          {!validations.hasNoWeirdCharacters && (
            <InputErrorBox errorText="Oeps, alleen deze speciale tekens zijn toegestaan: -!@#$%^&+=" />
          )}
          {reusedOldPasswordError && (
            <InputErrorBox errorText="Je kunt geen wachtwoorden hergebruiken." />
          )}
          {!isMobile && !isTablet && <Title />}
          <Field>
            <PasswordVerification validations={validations} />
          </Field>
          <Title>
            <Header4>Herhaal nieuw wachtwoord</Header4>
          </Title>
          <Field>
            <Password
              inputField
              value={password}
              onChange={(event) => onChange('password', event.target.value)}
              data-test-id="current-user-password-confirm-input"
            />
          </Field>
          {!isMobile && !isTablet && <Title />}
          <Field>
            <PasswordMatchVerification match={validations.passwordsMatch} />
          </Field>
        </>
      )}
    </Container>
  );
}

PasswordUpdate.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancelPassword: PropTypes.func.isRequired,
  cancelled: PropTypes.bool.isRequired,
  readyToUpdate: PropTypes.bool.isRequired,
  setReadyToUpdate: PropTypes.func.isRequired,
  updateUserError: PropTypes.object.isRequired,
  showPasswordUpdateInitial: PropTypes.bool.isRequired,
};

/**
 *
 * CampaignDetail View
 *
 */
import '!file-loader?name=[name].[ext]!../../../images/download-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/checkicon.svg';

import React, { useEffect, useState } from 'react';
import ReactBodymovin from 'react-bodymovin';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'rc-pagination/assets/index.css';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from '../../QRCode/QRCode';
import messages from './messages';
import { toCurrency } from '../../../utils';
import CampaignDetailItem from './CampaignDetailItem';
import CampaignImages from './CampaignImages';
import animationData from '../../../images/animations/green-dots.json';
import {
  Header1,
  Header2,
  Panel,
  NonMobileWrapper,
  MobileWrapper,
  Space,
  PrimaryButton,
  Color,
  SecondaryButton,
} from '../../styleguide';

import { GiphyBox, BackArrow, BreadCrumb } from '../../components';

const CampaignDetailWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 16px;
  }
`;

const DownloadButton = styled(PrimaryButton)`
  float: right;
  margin-left: auto;
  margin-top: 16px;
`;

const Hr = styled.hr`
  border: 1px solid rgb(244, 244, 244);
`;

const Header = styled(Header1)`
  margin: 27px 0px 16px 8px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

const SubHeader = styled(Header2)`
  margin: 27px 0px 18px 8px;
  line-height: normal;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

const OverviewWrapper = styled(Panel)`
  padding: 32px 32px 8px 32px;
  margin-bottom: 40px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 24px;
  }
`;

const NonLastOverviewWrapper = styled(OverviewWrapper)`
  margin-bottom: 14px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${Space.s24};
  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

const GifjeContainer = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 400px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    padding: 0 16px;
  }
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const Label = styled.div`
  float: left;
  width: 158px;
  line-height: 36px;
  font-family: MuseoSansRounded700;
  font-size: 20px;
  color: ${Color.gray01};
  user-select: none;
  margin-right: 54px;
`;

const CampaignLink = styled.div`
  width: 548px;
  height: 30px;
  line-height: 30px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.gray01};
  outline: none;
  text-decoration: 'underline';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.tablet} {
    width: 40%;
  }
`;

const Button = styled(SecondaryButton)`
  //float: right;
  margin-left: auto;
  width: 200px;
  margin-top: -9px;
  border-radius: 24px;

  img {
    margin-right: 8px;
  }
`;

const CopyNotification = styled.div`
  position: fixed;
  bottom: 32px;
  width: 280px;
  height: 38px;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 50px;
  box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);
  background-color: ${Color.brandColor01};
  color: ${Color.white};
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  transition: ${(props) => (props.copied ? 'none' : 'opacity 0.5s ease-in')};
  opacity: ${(props) => (props.copied ? '1' : '0')};

  img {
    margin-right: 8px;
  }

  @media ${(props) => props.theme.device.mobile} {
    bottom: auto;
    left: 0px;
    right: 0px;
    width: auto;
    transform: none;
    border-radius: 0px;
    opacity: 1;
    transition: ${(props) => (props.copied ? 'none' : 'top 0.5s ease-in')};
    top: ${(props) => (props.copied ? '0px' : '-50px')};
  }
`;

const options = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

function CampaignDetail(props) {
  const {
    intl,
    resetQRCode,
    loadCampaign,
    campaign,
    showCampaigns,
    campaignErrorMessage,
    campaignIsLoading,
    downloadPayments,
    fetchingQRCode,
    fetchQRCodeError,
    qrCode,
  } = props;
  const [shareUrlCopied, setShareUrlCopied] = useState(false);
  const match = useRouteMatch();
  const { campaignId } = match.params;

  const oopsTitle = intl.formatMessage({
    id: 'app.components.CampaignDetail.oopsTitle',
  });

  const oopsText = intl.formatMessage({
    id: 'app.components.CampaignDetail.oopsText',
  });

  const oopsButton = intl.formatMessage({
    id: 'app.components.CampaignDetail.oopsButton',
  });

  useEffect(() => {
    resetQRCode();
    loadCampaign(campaignId);

    return () => {
      resetQRCode();
    };
  }, []);

  const onCopy = () => {
    setShareUrlCopied(true);
    setTimeout(() => {
      setShareUrlCopied(false);
    }, 2000);
  };

  const percFormatter = new Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: 0,
  });

  return (
    <CampaignDetailWrapper>
      <MobileWrapper>
        <BackArrow onClick={showCampaigns} />
      </MobileWrapper>
      <NonMobileWrapper>
        <FormattedMessage {...messages.back}>
          {(placeholder) => (
            <BreadCrumb
              id="backToCampaigns"
              start={placeholder}
              onStart={showCampaigns}
              text={campaign.campaignName}
            />
          )}
        </FormattedMessage>
      </NonMobileWrapper>
      <Header>
        <FormattedMessage {...messages.header} />
      </Header>
      {campaignErrorMessage && (
        <GifjeContainer>
          <GifjeWrapper>
            <GiphyBox
              gif="oops.gif"
              onClick={() => {
                loadCampaign();
              }}
              title={oopsTitle}
              text={oopsText}
              button={oopsButton}
            />
          </GifjeWrapper>
        </GifjeContainer>
      )}
      {campaignIsLoading && (
        <LoaderWrapper>
          <AnimationWrapper>
            <ReactBodymovin options={options} />
          </AnimationWrapper>
        </LoaderWrapper>
      )}
      {!campaignErrorMessage &&
        !campaignIsLoading && [
          <OverviewWrapper key="details">
            <Row>
              <CampaignDetailItem
                dataTestId="campaignDetail-description"
                expand
                label={<FormattedMessage {...messages.campaignName} />}
                value={campaign.campaignName}
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-amountRequested"
                label={<FormattedMessage {...messages.campaignTotalBudget} />}
                value={toCurrency(campaign.thresholdAmount)}
                padding
                border
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-amountPaid"
                label={<FormattedMessage {...messages.campaignTotalPayout} />}
                value={toCurrency(
                  campaign.totalPayoutAmount ? campaign.totalPayoutAmount : 0,
                )}
                padding
                border
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-amountPaid"
                label={
                  <FormattedMessage {...messages.campaignRemainingBudget} />
                }
                value={toCurrency(
                  campaign.thresholdAmount -
                    (campaign.totalPayoutAmount
                      ? campaign.totalPayoutAmount
                      : 0),
                )}
                padding
                border
              />
            </Row>
            <Row>
              <CampaignDetailItem
                dataTestId="campaignDetail-description"
                expand
                label={<FormattedMessage {...messages.campaignId} />}
                value={campaign.id}
              />
            </Row>
            <Row>
              <CampaignDetailItem
                dataTestId="campaignDetail-description"
                expand
                label={
                  <FormattedMessage
                    {...messages.campaignPeriod}
                    values={{
                      start: moment(campaign.startDate).format('D MMM YYYY'),
                      end: moment(campaign.endDate).format('D MMM YYYY'),
                    }}
                  />
                }
              />
            </Row>
            <Hr />
            <Row>
              <DownloadButton
                target="_blank"
                onClick={() => downloadPayments(campaignId)}
              >
                <img src="/download-o.svg" alt="" />
                <FormattedMessage {...messages.downloadReport} />
              </DownloadButton>
            </Row>
          </OverviewWrapper>,
          <SubHeader key="performanceHeader">
            <FormattedMessage {...messages.performanceHeader} />
          </SubHeader>,
          <OverviewWrapper key="performance">
            <Row>
              <CampaignDetailItem
                dataTestId="campaignDetail-cards"
                label={<FormattedMessage {...messages.cards} />}
                value={campaign.amountOfCards || '-'}
                flexBasis="100%"
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-cashbacks"
                label={<FormattedMessage {...messages.cashbacks} />}
                value={campaign.amountOfPayouts || '-'}
                flexBasis="100%"
                padding
                border
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-conversion"
                label={<FormattedMessage {...messages.conversion} />}
                value={
                  !campaign.amountOfPayouts
                    ? '-'
                    : `${percFormatter.format(
                        (campaign.amountOfPayouts / campaign.amountOfCards) *
                          100,
                      )}%`
                }
                flexBasis="100%"
                padding
                border
              />
              <CampaignDetailItem
                dataTestId="campaignDetail-cashbackAmount"
                label={<FormattedMessage {...messages.cashbackAmount} />}
                value={
                  campaign.cashbackAmount
                    ? toCurrency(campaign.cashbackAmount)
                    : '-'
                }
                flexBasis="100%"
                paddingLeft
                border
              />
            </Row>
          </OverviewWrapper>,
          <SubHeader key="linksHeader">
            <FormattedMessage {...messages.shareHeader} />
          </SubHeader>,
          <NonLastOverviewWrapper key="shareUrl">
            <Row>
              <Label>
                <FormattedMessage {...messages.shareUrl} />
              </Label>
              <CampaignLink>{campaign.shareUrl}</CampaignLink>
              <CopyToClipboard text={campaign.shareUrl} onCopy={onCopy}>
                <Button>
                  <img src="/copyicon.svg" alt="" />
                  <FormattedMessage {...messages.buttonCopyLink} />
                </Button>
              </CopyToClipboard>
            </Row>
          </NonLastOverviewWrapper>,
          campaign.shareUrl && (
            <QRCode
              key="qrCode"
              link={campaign.shareUrl}
              label={messages.qrCode}
              fetchingQRCode={fetchingQRCode}
              fetchQRCodeError={fetchQRCodeError}
              qrCode={qrCode}
              button={messages.buttonQRCodeDownload}
              overviewStyle
            />
          ),
          <SubHeader key="imagesHeader">
            <FormattedMessage {...messages.imagesHeader} />
          </SubHeader>,
          <CampaignImages key="images" campaign={campaign} />,
          <CopyNotification copied={shareUrlCopied} key="copyNotification">
            <img src="/checkicon.svg" alt="" />
            <FormattedMessage {...messages.shareUrlCopied} />
          </CopyNotification>,
        ]}
    </CampaignDetailWrapper>
  );
}

CampaignDetail.propTypes = {
  intl: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  oopsTitle: PropTypes.string,
  oopsText: PropTypes.string,
  oopsButton: PropTypes.string,
  match: PropTypes.object,
  showCampaigns: PropTypes.func.isRequired,
  loadCampaign: PropTypes.func.isRequired,
  campaignErrorMessage: PropTypes.string,
  campaignIsLoading: PropTypes.bool.isRequired,
  downloadPayments: PropTypes.func,
  fetchingQRCode: PropTypes.bool,
  fetchQRCodeError: PropTypes.bool,
  qrCode: PropTypes.string,
  resetQRCode: PropTypes.func,
};

export default injectIntl(CampaignDetail);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, PageTitle } from 'amber';
import moment from 'moment';
import styled from 'styled-components';
import * as messages from './messages';
import {
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
} from '../styledComponents';
import { List } from '../../List';
import { AuditListItem } from '../../Audit/AuditListItem';
import { isEmpty } from '../../../utils';
import AuditLogFilters from './AuditLogFilters';
import { GifjeContainer, GifjeWrapper } from '../../Gif';
import { GiphyBox } from '../../components';
import { analyticsPageview } from '../../../ga4';

const AuditLogWrapper = styled.div`
  flex: 1;
`;

const auditLogTypes = {
  USER: 'Medewerkers',
  SETTING: 'Bedrijfsinstellingen',
  SUBSCRIPTION: 'Abonnement',
};

function AuditLog({
  gotoPortalOverview,
  gotoSettingsDashboard,
  auditLogs,
  auditLogsLoading,
  auditLogsError,
  fetchAuditLogs,
}) {
  const [selectedType, setSelectedType] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [date, setDate] = useState(null);

  useEffect(() => {
    analyticsPageview();
  }, []);

  const noErrors = isEmpty(auditLogsError);
  const loading = auditLogsLoading && noErrors;

  const pageSize = 20;

  useEffect(() => {
    fetchAuditLogs({
      size: pageSize,
      page: 0,
      type: selectedType,
      search: searchText,
      date: date ? moment(date).format('YYYY-MM-DD') : null,
    });
  }, [fetchAuditLogs, pageSize, selectedType, searchText, date]);

  if (!noErrors) {
    if (auditLogsError.statusCode === 403) {
      return ErrorPageNoContact({
        pageTitle: messages.title,
        showUnauthorizedDescription: true,
        onClickBackOverview: gotoPortalOverview,
      });
    }
    return ErrorPageWithContact({
      pageTitle: messages.title,
      onClickBackOverview: gotoPortalOverview,
    });
  }

  const renderAuditLogItem = (auditLog) => (
    <AuditListItem key={auditLog.id} auditLog={auditLog}></AuditListItem>
  );

  const renderAuditLogNoItem = () => (
    <GifjeContainer>
      <GifjeWrapper>
        <GiphyBox
          gif="no-results.gif"
          title={messages.noResultsTitle}
          text={messages.noResultsText}
        />
      </GifjeWrapper>
    </GifjeContainer>
  );

  const onPageChange = (page) => {
    fetchAuditLogs({
      size: pageSize,
      page,
      type: selectedType,
      search: searchText,
      date: date ? moment(date).format('YYYY-MM-DD') : null,
    });
  };

  const types = Object.entries(auditLogTypes).map(([id, label]) => ({
    id,
    label,
  }));

  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton
        goBack={gotoSettingsDashboard}
        text={messages.backButtonText}
      />
      <PageTitle>{messages.title}</PageTitle>
      <AuditLogWrapper AuditLogWrapper>
        <AuditLogFilters
          onTypeSubmit={setSelectedType}
          onSearch={setSearchText}
          selectedType={selectedType}
          types={types}
          searchText={searchText}
          date={date}
          setDate={setDate}
        />
        <List
          pagination
          loading={loading}
          pageSize={pageSize}
          totalItems={auditLogs.totalElements}
          items={auditLogs.auditLogs}
          onPageChange={onPageChange}
          renderItem={renderAuditLogItem}
          renderNoItem={renderAuditLogNoItem}
        />
      </AuditLogWrapper>
    </Container>
  );
}

AuditLog.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  auditLogs: PropTypes.shape({
    auditLogs: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  auditLogsLoading: PropTypes.bool.isRequired,
  auditLogsError: PropTypes.object.isRequired,
  fetchAuditLogs: PropTypes.func.isRequired,
};

export default AuditLog;

import '!file-loader?name=[name].[ext]!../../../images/geen-knop.png';
import '!file-loader?name=[name].[ext]!../../../images/knop-met-link.png';
import '!file-loader?name=[name].[ext]!../../../images/tik-door-link.png';
import '!file-loader?name=[name].[ext]!../../../images/auto-door-link.png';
import '!file-loader?name=[name].[ext]!../../../images/settings-thank-you-page-manual-geen-knop.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings-thank-you-page-manual-knop-met-link.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings-thank-you-page-manual-tik-em-door.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings-thank-you-page-manual-auto-link.svg';

import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { Container, FormTile, InputTile, Paragraph } from 'amber';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  ContainerBox,
  ContainerProps,
  ContainerRowExplanation,
  ExplanationHeader,
  ExplanationText,
  Header2Card,
  Header2Manual,
  ImageExplanation,
  ImageWrapperExplanation,
  InputErrorBox,
  NotificationBox,
  PendingStatus,
  SelectWrapper,
} from '../styledComponents';
import { WHATSAPP_BASE_URL } from './updateState';
import {
  AUTO_LINK,
  BTN_LINK,
  BTN_TEXT,
  isAutoLink,
  isTikLink,
  linkTypes,
  PENDING_THANK_YOU_IMG,
} from './constants';
import { isValidUrl } from '../../Util';
import config from '../../../config';
import { ImageVerifyUploader } from './ImageVerifyUploader';
import { PreviewPage } from './PreviewPage';
import { isGifFileExtension } from '../../../utils';

function ThankYou({
  values,
  onChange,
  validate,
  errors,
  displayPendingStatus,
}) {
  const { t } = useTranslation();
  const { linkType } = values;
  const linksList = Object.values(linkTypes).map((type) => type);

  const shouldClearUrlField = (value) =>
    !value.conditionalFields.includes(BTN_LINK) &&
    !value.conditionalFields.includes(AUTO_LINK);
  const shouldClearButtonField = (value) =>
    !value.conditionalFields.includes(BTN_TEXT);

  const onChangeLinkType = (value) => {
    onChange('thankYouAutoRedirect', isAutoLink(value));
    if (shouldClearUrlField(value)) {
      onChange('thankYouUrl', '');
    }
    if (shouldClearButtonField(value)) {
      onChange('thankYouButton', '');
    }
    onChange('linkType', value);
  };

  const validateMinInputFields = () => {
    validate(
      !linkType.conditionalFields
        ?.map((field) => inputFields[field].id)
        .every((id) => values[id] !== null && values[id] !== ''),
      'thankYouMinFields',
    );
  };

  const validateUrl = () =>
    validate(
      !isEmpty(values.thankYouUrl) && !isValidUrl(values.thankYouUrl),
      'thankYouUrl',
    );
  const validateText = (value, max, valueStr) =>
    validate(value.length > max, valueStr);
  const validateTitle = (value) =>
    validateText(value || values.thankYouTitle, 20, 'thankYouTitle');
  const validateContent = (value) =>
    validateText(value || values.thankYouContent, 50, 'thankYouContent');
  const validateContent2 = (value) =>
    validateText(value || values.thankYouContent2, 50, 'thankYouContent2');
  const validateButton = (value) =>
    validateText(value || values.thankYouButton, 25, 'thankYouButton');

  const validateMinInputFieldsAndCurrentField = (
    value,
    validateCurrentField,
  ) => {
    validateMinInputFields();
    validateCurrentField(value);
  };
  const validateMinInputFieldsAndButton = (value) =>
    validateMinInputFieldsAndCurrentField(value, () => validateButton(value));
  const [notifications, setNotifications] = useState({ whatsApp: false });
  const notifyWhatsAppMessage = () =>
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      whatsApp:
        getWhatsAppText(values.thankYouUrl).length > 200 ? 'whatsApp' : false,
    }));
  const validateMinInputFieldsAndWhatsApp = (value) =>
    validateMinInputFieldsAndCurrentField(value, notifyWhatsAppMessage);
  const containsTikkieUrl = (text) =>
    text.includes(`${config.TIKKIE_BASE_URL}/pay/`);
  const validateWhatsAppMessage = () => {
    notifyWhatsAppMessage();
    validate(
      !containsTikkieUrl(getWhatsAppText(values.thankYouUrl)),
      'thankYouUrl.whatsApp',
    );
  };

  useEffect(() => {
    validateMinInputFields();
  }, [linkType]);

  useEffect(() => {
    validateUrl();
    validateMinInputFields();
  }, [values]);

  const renderConditionalInputs = () =>
    renderInputs(linkType.conditionalFields);

  const getUrl = (url) => (url && url.startsWith(WHATSAPP_BASE_URL) ? '' : url);

  const getWhatsAppText = (url) =>
    url && url.startsWith(WHATSAPP_BASE_URL)
      ? decodeURI(url.substr(WHATSAPP_BASE_URL.length))
      : '';

  const whatsAppMessageToUrl = (message) =>
    WHATSAPP_BASE_URL + encodeURI(message);

  const inputFields = {
    title: {
      id: 'thankYouTitle',
      title: t('portal.settings.pages.thankYouTitle.title'),
      desc: t('portal.settings.pages.thankYouTitle.description'),
      value: values.thankYouTitle,
      placeholder: t('portal.settings.pages.thankYouTitle.placeholder'),
      validateField: validateTitle,
      onBlur: validateTitle,
      errorsForField: ['thankYouTitle'],
    },
    line1: {
      id: 'thankYouContent',
      title: t('portal.settings.pages.thankYouContent.title'),
      desc: t('portal.settings.pages.thankYouContent.description'),
      value: values.thankYouContent,
      placeholder: t('portal.settings.pages.thankYouContent.placeholder'),
      validateField: validateContent,
      onBlur: validateContent,
      errorsForField: ['thankYouContent'],
    },
    btnText: {
      id: 'thankYouButton',
      title: t('portal.settings.pages.thankYouButton.title'),
      desc: t('portal.settings.pages.thankYouButton.description'),
      value: values.thankYouButton,
      placeholder: t('portal.settings.pages.thankYouButton.placeholder'),
      validateField: validateMinInputFieldsAndButton,
      onBlur: validateButton,
      errorsForField: ['thankYouButton'],
    },
    line2: {
      id: 'thankYouContent2',
      title: t('portal.settings.pages.thankYouContent2.title'),
      desc: t('portal.settings.pages.thankYouContent2.description'),
      value: values.thankYouContent2,
      placeholder: t('portal.settings.pages.thankYouContent2.placeholder'),
      validateField: validateContent2,
      onBlur: validateContent2,
      errorsForField: ['thankYouContent2'],
    },
    btnLink: {
      id: 'thankYouUrl',
      title: t('portal.settings.pages.thankYouUrl.buttonLink.title'),
      desc: t('portal.settings.pages.thankYouUrl.buttonLink.description'),
      value: getUrl(values.thankYouUrl),
      placeholder: t(
        'portal.settings.pages.thankYouUrl.buttonLink.placeholder',
      ),
      validateField: validateMinInputFields,
      onBlur: validateUrl,
      errorsForField: ['thankYouUrl'],
    },
    whatsAppText: {
      id: 'thankYouUrl',
      title: t('portal.settings.pages.thankYouUrl.whatsApp.title'),
      desc: t('portal.settings.pages.thankYouUrl.whatsApp.description'),
      value: getWhatsAppText(values.thankYouUrl),
      placeholder: t('portal.settings.pages.thankYouUrl.whatsApp.placeholder'),
      validateField: validateMinInputFieldsAndWhatsApp,
      onBlur: validateWhatsAppMessage,
      errorsForField: ['thankYouUrl.whatsApp'],
      notification: 'whatsApp',
    },
    autoLink: {
      id: 'thankYouUrl',
      title: t('portal.settings.pages.thankYouUrl.autoLink.title'),
      desc: t('portal.settings.pages.thankYouUrl.autoLink.description'),
      value: getUrl(values.thankYouUrl),
      placeholder: t('portal.settings.pages.thankYouUrl.autoLink.title'),
      validateField: validateMinInputFields,
      onBlur: validateUrl,
      errorsForField: ['thankYouUrl'],
    },
  };

  const onChangeField = (fieldId, value) => {
    if (fieldId === 'thankYouUrl' && isTikLink(linkType)) {
      onChange(fieldId, whatsAppMessageToUrl(value));
    } else {
      onChange(fieldId, value);
    }
  };

  const renderInputTile = (
    id,
    title,
    desc,
    value,
    placeholder = '',
    validateField = () => {},
    onBlur = () => {},
    errorsForField = [],
    notification = '',
  ) => (
    <Container row mb={24} key={id}>
      <InputTile
        key={id}
        id={id}
        title={title}
        desc={desc}
        value={value}
        placeholder={placeholder}
        onChange={(fieldId, val) => {
          validateField(val);
          onChangeField(fieldId, val);
        }}
        onBlur={onBlur}
      >
        {!isEmpty(notification) && notifications[notification] && (
          <NotificationBox
            errorText={t(
              `portal.settings.pages.thankYouUrl.${notification}.note`,
            )}
          />
        )}
        {!isEmpty(errorsForField) &&
          errorsForField.map(
            (error) =>
              errors[error] && (
                <InputErrorBox
                  errorText={t(`portal.settings.pages.${error}.error`)}
                />
              ),
          )}
      </InputTile>
    </Container>
  );

  const renderInitialInputs = () => renderInputs(linkType.initialFields);

  const renderInputs = (fields) =>
    fields
      ?.map((field) => inputFields[field])
      .map(
        ({
          id,
          title,
          desc,
          value,
          placeholder,
          validateField,
          onBlur,
          errorsForField,
          notification,
        }) =>
          renderInputTile(
            id,
            title,
            desc,
            value,
            placeholder,
            validateField,
            onBlur,
            errorsForField,
            notification,
          ),
      );

  return (
    <>
      <PendingStatus display={displayPendingStatus} />
      <Container col={9}>
        <ContainerProps row mb={24} maxHeight="100%">
          {config.get(config.LOGO_UPLOAD_ENABLED) && (
            <ImageVerifyUploader
              desc={t('portal.settings.pages.general.gif.description')}
              input={values.inputThankYouImage}
              original={values.thankYouImage}
              pending={values.pendingThankYouImage}
              entityName="gifje"
              pendingVariable={PENDING_THANK_YOU_IMG}
              maxFileSize={2048}
              requiredExtensionType="gif"
              verifyFileExtension={isGifFileExtension}
              onLoad={(file) => {
                onChange('inputThankYouImage', file);
              }}
              onChange={onChange}
            />
          )}
          {renderInitialInputs()}
          <Container row mb={24}>
            <FormTile
              title={t('portal.settings.pages.thankyou.nextAction.title')}
              desc={t('portal.settings.pages.thankyou.nextAction.description')}
            >
              <Container row>
                <SelectWrapper>
                  <select
                    value={linkType.label}
                    onChange={(e) => {
                      onChangeLinkType(
                        linksList.find((link) => link.label === e.target.value),
                      );
                    }}
                  >
                    {linksList.map((type) => (
                      <option value={type.value} key={type.label}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </SelectWrapper>
              </Container>
            </FormTile>
          </Container>
          {renderConditionalInputs()}
        </ContainerProps>
      </Container>
      <Container col={3} center={isMobile}>
        <PreviewPage>
          <img src={linkType.img} alt="thankyou-page-example" />
        </PreviewPage>
      </Container>
      <Container row col={12}>
        <Header2Manual>
          {t('portal.settings.pages.thankyou.nextAction.header')}
        </Header2Manual>
        <ContainerBox>
          <div>
            <ExplanationHeader>
              <Header2Card>
                {t('portal.settings.pages.thankyou.nextAction.subheader')}
              </Header2Card>
              <Paragraph>
                {t('portal.settings.pages.thankyou.nextAction.text')}
              </Paragraph>
            </ExplanationHeader>
          </div>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img
                  src="/settings-thank-you-page-manual-geen-knop.svg"
                  alt="Voorbeeldscherm met vervolgactie geen knop"
                />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.noButton.title',
                )}
              </Header2Card>
              <Paragraph>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.noButton.instruction',
                )}
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.buttonWithLink.title',
                )}
              </Header2Card>
              <Paragraph>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.buttonWithLink.instruction',
                )}
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img
                  src="/settings-thank-you-page-manual-knop-met-link.svg"
                  alt="Voorbeeldscherm met vervolgactie knop met link"
                />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img
                  src="/settings-thank-you-page-manual-tik-em-door.svg"
                  alt="Voorbeeldscherm met vervolgactie tik 'm door"
                />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.tikmDoorLink.title',
                )}
              </Header2Card>
              <Paragraph>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.tikmDoorLink.instruction',
                )}
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.autoLink.title',
                )}
              </Header2Card>
              <Paragraph>
                {t(
                  'portal.settings.pages.thankyou.nextAction.options.autoLink.instruction',
                )}
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img
                  src="/settings-thank-you-page-manual-auto-link.svg"
                  alt="Voorbeeldscherm met vervolgactie auto doorlinken"
                />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
        </ContainerBox>
      </Container>
    </>
  );
}

ThankYou.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  displayPendingStatus: PropTypes.bool.isRequired,
};

export default ThankYou;

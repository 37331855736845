import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import moment from 'moment';

import config from '../../../config';
import request from '../../../utils/request';

import {
  LOAD_BULK_FILE,
  LOAD_PAYMENT_REQUESTS,
  SEND_BULK_UPLOAD,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
} from './constants';

import {
  loadBulkFileSuccess,
  loadBulkFileError,
  loadPaymentRequestsError,
  loadPaymentRequestsSuccess,
  sendBulkUploadError,
  sendBulkUploadSuccess,
} from './actions';

export default function* watcherSaga() {
  yield takeLatest(LOAD_BULK_FILE, loadBulkFileSaga);
  yield takeLatest(SEND_BULK_UPLOAD, sendBulkUploadSaga);
  yield takeLatest(LOAD_PAYMENT_REQUESTS, loadPaymentRequestsSaga);
  yield takeLatest(
    DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
    downloadMultiplePaymentRequestsSaga,
  );
}

export function* loadBulkFileSaga(action) {
  const { id } = action.payload;

  const requestUrl = `${config.API_URL}/batch/${id}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadBulkFileSuccess(response));
  } catch (err) {
    yield put(loadBulkFileError(err.message));
  }
}

export function* loadPaymentRequestsSaga(action) {
  const { page, from, to, status, search, paymentRequestBatchId } =
    action.payload;

  const currentPage = page - 1;
  let fromDate = '';
  let toDate = '';
  let statusList = '';
  const searchText = encodeURIComponent(search);
  const batchId = paymentRequestBatchId || '';

  if (from > 0 && to > 0) {
    fromDate = moment(from).format('YYYY-MM-DD');
    toDate = moment(to).format('YYYY-MM-DD');
  }

  if (status.length > 0) {
    statusList = status.join(',');
  }

  const requestUrl = `${config.API_URL}/search?paymentRequestBatchId=${batchId}&fromDate=${fromDate}&toDate=${toDate}&search=${searchText}&status=${statusList}&page=${currentPage}&size=20&withPaymentInfo=false`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadPaymentRequestsSuccess(response));
  } catch (err) {
    yield put(loadPaymentRequestsError(err.message));
  }
}

export function* sendBulkUploadSaga(action) {
  const cookies = new Cookies();
  const paymentRequestBatchId = action.payload.id;

  const requestUrl = `${config.API_URL}/batch/send?paymentRequestBatchId=${paymentRequestBatchId}`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(sendBulkUploadSuccess(action.payload.id));
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(sendBulkUploadSuccess());
    } else {
      const errResponse = yield err.response.json().catch(() => ({}));
      let message = err.response.userMessage || 'Oeps, verzenden niet gelukt';

      let validationErrors = null;
      // 1337 --> csv.has.validation.errors
      if (errResponse.errorCode === 1337) {
        validationErrors = errResponse.errorDetails;
        message = errResponse.userMessage || 'Oeps, verzenden niet gelukt';
      }

      yield put(
        sendBulkUploadError(err.response.status, message, validationErrors),
      );
    }
  }
}

export function* downloadMultiplePaymentRequestsSaga(action) {
  const {
    from,
    to,
    status,
    search,
    allSelected,
    selectedPaymentRequests,
    csvType,
    paymentRequestBatchId,
  } = action.payload;

  let fromDate = '';
  let toDate = '';
  let statusList = '';
  const searchText = encodeURIComponent(search);
  const batchId = paymentRequestBatchId || '';
  const selectAll = allSelected !== 'no';

  if (from > 0 && to > 0) {
    fromDate = moment(from).format('YYYY-MM-DD');
    toDate = moment(to).format('YYYY-MM-DD');
  }

  if (status.length > 0) {
    statusList = status.join(',');
  }

  const requestUrl = `${config.API_URL}/csv/?paymentRequestBatchId=${batchId}&fromDate=${fromDate}&toDate=${toDate}&search=${searchText}&status=${statusList}&selectAll=${selectAll}&ids=${selectedPaymentRequests}&csvType=${csvType}`;
  window.location = requestUrl;
}

import React, { useState, useEffect } from 'react';
import { involvementSteps, involvementTypes } from './constants';
import UboInvolvementSelect from './UboInvolvementSelect';
import UboInvolvementPercentage from './UboInvolvementPercentage';
import { InvolvementType } from '../types';
import { useOnboardingPageView } from '../../../../hooks';

interface UboInvolvementProps {
  saveAsUbo: (type: InvolvementType) => void;
  prevStep: () => void;
  organisationName: string;
  uboFullName?: string;
  contactPersonAsUbo?: boolean;
  submitted: boolean;
  nextStep: () => void;
  involvement: InvolvementType;
}

function UboInvolvement({
  prevStep,
  saveAsUbo,
  organisationName,
  uboFullName,
  contactPersonAsUbo,
  submitted,
  nextStep,
  involvement,
}: UboInvolvementProps): JSX.Element {
  useEffect(() => {
    if (submitted) {
      nextStep();
    }
  }, [submitted, nextStep]);
  const [owner, setOwner] = useState(
    involvement ? involvement.types.includes(involvementTypes.owner) : false,
  );
  const [control, setControl] = useState(
    involvement ? involvement.types.includes(involvementTypes.control) : false,
  );
  const switchOwner = (): void => setOwner(!owner);
  const switchControl = (): void => setControl(!control);
  const [ownerPercentage, setOwnerPercentage] = useState(
    involvement && involvement.ownerPercentage
      ? parseFloat(String(involvement.ownerPercentage)).toFixed(2)
      : '',
  );
  const [currentStep, setCurrentStep] = useState(involvementSteps.select);
  useOnboardingPageView('ubo-involvement');

  const saveUbo = (): void => {
    saveAsUbo({
      types: [
        ...(owner ? [involvementTypes.owner] : []),
        ...(control ? [involvementTypes.control] : []),
      ],
      ownerPercentage:
        ownerPercentage !== '' && owner ? ownerPercentage : undefined,
    });
  };

  const goToPercentageScreen = (): void =>
    setCurrentStep(involvementSteps.percentage);
  const goBackToSelectScreen = (): void =>
    setCurrentStep(involvementSteps.select);

  return (
    <>
      {currentStep === involvementSteps.select && (
        <UboInvolvementSelect
          prevStep={prevStep}
          nextStep={owner ? goToPercentageScreen : saveUbo}
          control={control}
          switchControl={switchControl}
          owner={owner}
          switchOwner={switchOwner}
          organisationName={organisationName}
          uboFullName={uboFullName}
          contactPersonAsUbo={contactPersonAsUbo}
        />
      )}
      {currentStep === involvementSteps.percentage && (
        <UboInvolvementPercentage
          prevStep={goBackToSelectScreen}
          nextStep={saveUbo}
          percentage={ownerPercentage}
          setPercentage={setOwnerPercentage}
          uboFullName={uboFullName}
          contactPersonAsUbo={contactPersonAsUbo}
        />
      )}
    </>
  );
}

export default UboInvolvement;

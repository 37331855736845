import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import emailSteps from './constants';
import GetEmail from '../../../components/OnboardingKYC/GetEmail';
import CheckYourEmail from '../../../components/OnboardingKYC/CheckYourEmail';
import { selectOnboardingKYCData, selectSendEmailResult } from '../selectors';
import { sendEmail } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import FormWrapperContainer from '../FormWrapperContainer';

const emailStepsArr = [emailSteps.getEmail, emailSteps.checkYourEmail];

function EmailContainer(props) {
  const [currentStep, setCurrentStep] = useState(emailSteps.getEmail);

  const nextStep = () => {
    setCurrentStep(props.getNextStep(emailStepsArr, currentStep));
  };

  const prevStep = () => {
    setCurrentStep(props.getPreviousStep(emailStepsArr, currentStep));
  };

  return (
    <FormWrapperContainer prevStep={prevStep}>
      {currentStep === emailSteps.getEmail && (
        <GetEmail
          nextStep={nextStep}
          onboardingData={props.onboardingData}
          sendEmail={props.sendEmail}
        />
      )}
      {currentStep === emailSteps.checkYourEmail && (
        <CheckYourEmail
          nextStep={nextStep}
          previousStep={prevStep}
          email={props.onboardingData.email}
          sendEmailResult={props.sendEmailResult}
        />
      )}
    </FormWrapperContainer>
  );
}

EmailContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  sendEmailResult: PropTypes.object.isRequired,
  onboardingData: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  onboardingData: selectOnboardingKYCData,
  sendEmailResult: selectSendEmailResult,
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (email) => dispatch(sendEmail(email)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(EmailContainer);

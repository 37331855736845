import React from 'react';
import { createUseStyles } from 'react-jss';
import { Color, Space } from '../../styleguide';

interface CheckButtonProps {
  checked: boolean;
}

function CheckButton({ checked }: CheckButtonProps): JSX.Element {
  const classes = useStyles(checked);

  const img = checked ? '/checkmark2.svg' : '/blank.svg';
  return (
    <div className={classes.checkWrapper}>
      <div className={classes.check}>
        <img src={img} alt="check" />
      </div>
    </div>
  );
}

interface CheckBoxProps {
  id: string;
  checked: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

function CheckBox({
  id,
  checked,
  onClick,
  children,
}: CheckBoxProps): JSX.Element {
  const classes = useStyles(checked);

  return (
    <div
      id={id}
      role="checkbox"
      className={classes.checkButtonWrapper}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      aria-checked="false"
    >
      <CheckButton checked={checked} />
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  checkWrapper: {
    display: 'flex',
    width: '10%',
  },
  check: (checked: boolean) => ({
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    lineHeight: '16px',
    borderRadius: '4px',
    border: `solid 2px ${checked ? Color.brandColor01 : '#e9e9e9'}`,
    backgroundColor: checked ? Color.brandColor01 : '#f7f7f9',
    '& img': {
      width: '14px',
      height: '14px',
      marginLeft: '3px',
      marginTop: '3px',
    },
  }),
  checkButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '35px',
    lineHeight: '26px',
    cursor: 'pointer',
    // textAlign: 'center', TODO: check 'center/left'
    fontFamily: 'MuseoSansRounded700',
    fontSize: '16px',
    color: Color.gray01,
    backgroundColor: Color.white,
    fontWeight: '500',
    textAlign: 'left',
    border: 'none',
    padding: Space.s0,
  },
});

export default CheckBox;

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Space } from '../../../styleguide';
import OnboardingTextBalloon from '../../Shared/OnboardingTextBalloon';
import ButtonFooter from '../../components/ButtonFooter';
import NavigationHeader from '../../components/NavigationHeader';
import { Input, Wrapper } from '../../styledComponents';
import { UboInvolvementInfoScreen } from './UboInvolvementInfoScreen';
import InputKeyboard from '../../components/InputKeyboard';
import { useOnboardingPageView } from '../../../../hooks';
import Title from '../../Title';

interface UboInvolvementPercentageProps {
  prevStep: () => void;
  nextStep: () => void;
  percentage: string;
  setPercentage: (percentage: string) => void;
  uboFullName?: string;
  contactPersonAsUbo?: boolean;
}

function UboInvolvementPercentage({
  prevStep,
  nextStep,
  percentage,
  setPercentage,
  uboFullName,
  contactPersonAsUbo,
}: UboInvolvementPercentageProps): JSX.Element {
  const classes = useStyles();
  const regexPercentageToReturn =
    /(^100.00$)|(^([1-9]([0-9])?|0)([.][0-9]{2})$)/;
  const regexPercentageToDisplay =
    /(^100.00$)|(^([1-9]([0-9])?|0)([,][0-9]{2})$)/;
  const validPercentage = (): boolean =>
    percentage.match(regexPercentageToReturn) != null &&
    percentageToDisplay.match(regexPercentageToDisplay) != null;

  const [info, setInfo] = useState(false);
  const showInfo = (): void => setInfo(!info);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useOnboardingPageView('ubo-involvement-percentage');

  const validInput = (): boolean =>
    validPercentage() &&
    parseFloat(percentage) >= 25 &&
    parseFloat(percentage) <= 100;

  const [percentageToDisplay, setPercentageToDisplay] = useState(percentage);

  const onHandleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const { key } = e;
    const ctrlV = (e.ctrlKey || e.metaKey) && key === 'v';
    const ctrlC = (e.ctrlKey || e.metaKey) && key === 'c';

    if (
      key === 'ArrowLeft' ||
      key === 'RightArrow' ||
      key === 'Backspace' ||
      key === 'Tab' ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    const value = percentage + key;

    if (!value.match(/^[0-9]*([.|,]{1}[0-9]*)?$/)) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && validInput()) {
      nextStep();
    }
  };

  const checkError = (): void => {
    if (percentage !== '' && parseFloat(percentage) < 25) {
      setError(true);
      setErrorMessage(
        t('onboarding.ubo.involvement.percentage.errorTooLow') || '',
      );
    } else if (parseFloat(percentage) > 100) {
      setError(true);
      setErrorMessage(
        t('onboarding.ubo.involvement.percentage.errorTooHigh') || '',
      );
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const createValidPercentageFromInput = (): void => {
    if (validPercentage() || percentage === '') return;
    const value = percentage.replace(',', '.');
    let number = Number(value);
    if (number < 0) {
      number *= -1;
    }

    const newPercentage = parseFloat(String(number)).toFixed(2);
    setPercentage(newPercentage);
  };

  const createPercentageToDisplay = () => {
    if (validPercentage() || percentageToDisplay === '') return;
    const valueFormatted = percentageToDisplay.replace(',', '.');
    let number = Number(valueFormatted);
    if (number < 0) {
      number *= -1;
    }

    const newPercentage = parseFloat(String(number))
      .toFixed(2)
      .replace('.', ',');

    setPercentageToDisplay(newPercentage);
  };

  const onHandleBlur = (): void => {
    createValidPercentageFromInput();
    createPercentageToDisplay();
    checkError();
  };

  const onHandleChange = (value: string) => {
    setPercentage(value);
    setPercentageToDisplay(value);
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={prevStep as () => void}
          clickRight={showInfo}
          noMarginBottom
        />
        {contactPersonAsUbo && (
          <Title>{t('onboarding.ubo.involvement.percentage.header')}</Title>
        )}
        {!contactPersonAsUbo && (
          <>
            <BlokkieTypography
              style={{
                marginTop: blokkieTheme.spacing(4),
                color: blokkieTheme.colors.grey.dark,
              }}
              variant="pl700"
            >
              {t('onboarding.ubo.involvement.select.otherUboDescription')}
            </BlokkieTypography>
            <Title>
              {t('onboarding.ubo.involvement.percentage.otherUboHeader', {
                uboFullName,
              })}
            </Title>
          </>
        )}
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.dark,
          }}
          variant="ps700"
        >
          {t('onboarding.ubo.involvement.percentage.description')}
        </BlokkieTypography>
        <BlokkieTextLink
          onClick={showInfo}
          underline
          color={blokkieTheme.colors.grey.dark}
          style={{
            marginTop: blokkieTheme.spacing(),
            marginBottom: blokkieTheme.spacing(),
          }}
          variant="pm700"
        >
          {t('onboarding.ubo.involvement.select.more')}
        </BlokkieTextLink>
        <div className={classes.inputBox}>
          <InputKeyboard
            value={percentageToDisplay}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onHandleChange(e.currentTarget.value)
            }
            onKeyDown={onHandleKeyDown}
            onBlur={() => onHandleBlur()}
            autoComplete="off"
            autoCorrect="off"
            width="16"
            placeholder="25,00"
            autoFocus
            maxLength={6}
            data-test-id="Onboarding-UboInvolvementPercentage-Input"
            relatedButtonId="button-footer"
          />
          <Input readOnly value="%" width="84" />
        </div>
        <OnboardingTextBalloon
          show={error !== false}
          error
          marginTop={Space.s16}
        >
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.white,
            }}
            variant="pl700"
          >
            {errorMessage}
          </BlokkieTypography>
        </OnboardingTextBalloon>
        <ButtonFooter>
          <BlokkieButton
            disabled={!validInput()}
            onClick={nextStep}
            data-test-id="Onboarding-UboInvolvementPercentage-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <UboInvolvementInfoScreen
        show={info}
        showInfo={showInfo}
        fromPage={`${UboInvolvementPercentage.name}`}
      />
    </>
  );
}

const useStyles = createUseStyles({
  inputBox: {
    display: 'flex',
  },
});

export default UboInvolvementPercentage;

/*
 * TransactionList Messages
 *
 * This contains all the text for the TransactionList component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.TransactionList.header',
    defaultMessage: 'Transacties',
  },
  tabTikkieTransactions: {
    id: 'app.components.TransactionList.tab.tikkie.transactions',
    defaultMessage: 'Tikkie Transacties',
  },
  oopsTitle: {
    id: 'app.components.TransactionList.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.TransactionList.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  oopsButton: {
    id: 'app.components.TransactionList.oopsButton',
    defaultMessage: 'Probeer opnieuw',
  },
  firstUseTitle: {
    id: 'app.components.TransactionList.firstUseTitle',
    defaultMessage: 'Er zijn nog geen Transacties.',
  },
  firstUseText: {
    id: 'app.components.TransactionList.firstUseText',
    defaultMessage:
      'Er zijn nog geen transacties! Wanneer een Tikkie of bestelling is betaald komen de transacties hier te staan.',
  },
  noResultsTitle: {
    id: 'app.components.TransactionList.noResultsTitle',
    defaultMessage: 'Geen resultaten',
  },
  noResultsText: {
    id: 'app.components.TransactionList.noResultsText',
    defaultMessage:
      'Wij kunnen geen transacties vinden voor de huidige filter selectie.',
  },
  noResultsButton: {
    id: 'app.components.TransactionList.noResultsButton',
    defaultMessage: 'Laat alle transacties zien',
  },
});

/*
 * Dropdown Messages
 *
 *
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  reset: {
    id: 'app.components.DropdownMobile.reset',
    defaultMessage: 'Reset',
  },
  filter: {
    id: 'app.components.DropdownMobile.filter',
    defaultMessage: 'Filter toepassen',
  },
  transactionType: {
    id: 'app.components.DropdownMobile.transactionType',
    defaultMessage: 'Transactie type',
  },
  paymentRequest: {
    id: 'app.components.DropdownMobile.paymentRequest',
    defaultMessage: 'Betaalverzoekje',
  },
  refund: {
    id: 'app.components.DropdownMobile.refund',
    defaultMessage: 'Terugboeking',
  },
  date: {
    id: 'app.components.DropdownMobile.date',
    defaultMessage: 'Datum',
  },
  today: {
    id: 'app.components.DropdownMobile.today',
    defaultMessage: 'Vandaag',
  },
  thisWeek: {
    id: 'app.components.DropdownMobile.thisWeek',
    defaultMessage: 'Deze week',
  },
  thisMonth: {
    id: 'app.components.DropdownMobile.thisMonth',
    defaultMessage: 'Deze maand',
  },
  thisYear: {
    id: 'app.components.DropdownMobile.thisYear',
    defaultMessage: 'Dit jaar',
  },
  customDate: {
    id: 'app.components.DropdownMobile.thisYear',
    defaultMessage: 'Kies datum...',
  },
});

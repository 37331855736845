/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { Space } from '../../styleguide';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import ButtonFooter from '../components/ButtonFooter';
import NavigationHeader from '../components/NavigationHeader';
import {
  OnboardingParagraphDescription,
  RadioButtonContainer,
  Wrapper,
} from '../styledComponents';
import { UboInfoScreen } from './UboInfoScreen';
import messages from './messages';
import { simplifiedDueDiligenceProducts } from '../../../containers/OnboardingKYCContainer/config';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface ContactPersonUboProps {
  goToContactUbo: () => void;
  onStartNewUbo: () => void;
  skipUBO: () => void;
}

function ContactPersonUbo({
  goToContactUbo,
  onStartNewUbo,
  skipUBO,
}: ContactPersonUboProps): JSX.Element {
  const classes = useStyles();
  const [info, setInfo] = useState(false);
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);
  const [contactIsUbo, setContactIsUbo] = useState(
    // contact can't be UBO for simplified due dilligence
    !simplifiedDueDiligenceProducts.includes(productType),
  );
  const choiceMade = contactIsUbo !== null;
  const showInfo = (): void => setInfo(!info);
  useOnboardingPageView('ubo-contact-person');

  const nextStep = (): void => {
    if (contactIsUbo) {
      goToContactUbo();
    } else {
      onStartNewUbo();
    }
  };

  const skipUboStep = (): void => {
    analyticsEvent(
      'skip_step',
      AnalyticCategories.ONBOARDING,
      'onboarding-event',
      {
        step: 'ubo',
        flow: onboardingFlow,
        product: analyticsProductTypeMapper(productType),
        hasAbnBank,
      },
    );
    skipUBO();
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader clickRight={showInfo} noMarginBottom />
        <Title>{t(`onboarding.ubo.start.title`)}</Title>
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.dark,
            marginBottom: blokkieTheme.spacing(4),
          }}
          variant="ps700"
        >
          {t('onboarding.ubo.start.description')}
        </BlokkieTypography>
        <RadioButtonContainer>
          {!simplifiedDueDiligenceProducts.includes(productType) && (
            <OnboardingRadioButton
              key={messages.contactPersonUboYes.defaultMessage}
              id={messages.contactPersonUboYes.id}
              onClick={() => setContactIsUbo(true)}
              checked={choiceMade && contactIsUbo}
              text={t('onboarding.ubo.start.yes') || ''}
              data-test-id="Onboarding-ContactPersonUbo-Yes"
            />
          )}
          <OnboardingRadioButton
            key={messages.contactPersonUboNo.defaultMessage}
            id={messages.contactPersonUboNo.id}
            onClick={() => setContactIsUbo(false)}
            checked={choiceMade && !contactIsUbo}
            text={t('onboarding.ubo.start.no') || ''}
            data-test-id="Onboarding-ContactPersonUbo-No"
          />
        </RadioButtonContainer>
        <OnboardingParagraphDescription className={classes.wrapper}>
          <BlokkieTextLink
            color={blokkieTheme.colors.grey.veryDark}
            variant="pm700"
            underline
            onClick={skipUboStep}
          >
            {t('onboarding.ubo.start.skip')}
          </BlokkieTextLink>
        </OnboardingParagraphDescription>
        <ButtonFooter>
          <BlokkieButton
            disabled={!choiceMade}
            onClick={() => nextStep()}
            data-test-id="Onboarding-ContactPersonUbo-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <UboInfoScreen
        show={info}
        showInfo={showInfo}
        fromPage={ContactPersonUbo.name}
      />
    </>
  );
}
const useStyles = createUseStyles({
  wrapper: {
    marginTop: Space.s24,
  },
});

export default ContactPersonUbo;

import React from 'react';
import { Container } from 'amber';
import { GiphyBox } from '../../components';

export function EmailChangeConfirmed() {
  return (
    <Container mt={60}>
      <GiphyBox
        gif="succes.gif"
        title="Yes, gelukt!"
        text="We hebben je e-mailadres gewijzigd. Je kunt nu daarmee inloggen"
        button="Naar inloggen"
        onClick={() => {
          window.location = '/login';
        }}
      />
    </Container>
  );
}

/**
 *
 * Payment
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '!file-loader?name=[name].[ext]!../../../images/transactionicon.svg';

import { injectIntl, FormattedMessage } from 'react-intl';
import { Color } from '../../styleguide';
import { toCurrency, formatDate } from '../../../utils';

import messages from './messages';

const TransactionLineItemWrapper = styled.div`
  position: relative;
  display: block;
  height: 72px;
  line-height: 72px;
  padding: 0 24px 0 0;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-left: 8px solid ${(props) => props.color};
  vertical-align: middle;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;

  :hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  width: auto;
  margin-right: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 0;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 20px 16px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin: 0px 0px 16px 0px;
  }
`;

const ItemIcon = styled.img``;

const Created = styled.div`
  float: left;
  margin-right: 16px;
  width: 80px;
  height: 72px;
  line-height: 72px;
  color: ${Color.gray03};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const InvoiceNumber = styled.div`
  float: left;
  height: 72px;
  line-height: 72px;
  width: 222px;
  margin-right: 16px;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.tablet} {
    width: 100px;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const Description = styled.div`
  width: auto;
  height: 72px;
  line-height: 72px;
  margin-right: 16px;
  font-family: MuseoSansRounded;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 48px;
    line-height: 24px;
  }
`;

const DescriptionItem = styled.div`
  float: left;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

const Status = styled.div`
  float: right;
  height: 36px;
  min-width: 104px;
  line-height: 36px;
  margin: 18px 0px;
  padding: 0px 17px;
  border-radius: 40px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
    right: 16px;
    top: 0px;
  }
`;

const TransactionLineItem = injectIntl((props) => {
  const textColor = () => Color.gray01;

  const determineBackgroundColor = (payment) =>
    payment.type === 'REFUND' ? 'rgba(237, 85, 101, 0.05)' : '#ECF9F8';

  const determineBubbleStatusColor = (payment) =>
    payment.type === 'REFUND' ? Color.gray01 : Color.brandColor01;

  const determineTileStatusColor = (payment) =>
    payment.type === 'REFUND' ? Color.brandColor02 : Color.brandColor01;

  const determineBubbleStatusText = (payment) =>
    payment.type === 'REFUND'
      ? `- ${toCurrency(payment.amountInCents)}`
      : `+ ${toCurrency(payment.amountInCents)}`;

  const determineStatusId = (payment) =>
    payment.type === 'REFUND' ? 'refund' : 'paid';

  const trimDesc = (desc) => desc?.replace(/^(E: )/, '');

  return (
    <TransactionLineItemWrapper
      color={determineTileStatusColor(props.payment)}
      onClick={props.showTikkieDetail}
    >
      <ItemIconWrapper>
        <ItemIcon src="/transactionicon.svg" />
      </ItemIconWrapper>
      <Status
        id={determineStatusId(props.payment)}
        name="transactionAmount"
        color={determineBubbleStatusColor(props.payment)}
        background={determineBackgroundColor(props.payment)}
      >
        {determineBubbleStatusText(props.payment)}
      </Status>
      <ContentWrapper onClick={props.showTransactionDetail}>
        <Created id="creationDate">
          <FormattedMessage {...messages.yesterday}>
            {(yesterdayText) => (
              <span>
                {formatDate(
                  yesterdayText,
                  props.payment.created,
                  props.showOnlyTime,
                )}
              </span>
            )}
          </FormattedMessage>
        </Created>
        {props.showPayerDetails && (
          <InvoiceNumber id="payer" color={textColor(props.payment)}>
            {props.payment.counterPartyName}
          </InvoiceNumber>
        )}
        <Description color={textColor(props.payment)}>
          <DescriptionItem id="description">
            {trimDesc(props.description)}
          </DescriptionItem>
          <DescriptionItem>{trimDesc(props.invoiceNumber)}</DescriptionItem>
        </Description>
      </ContentWrapper>
    </TransactionLineItemWrapper>
  );
});

TransactionLineItem.propTypes = {
  showOnlyTime: PropTypes.bool,
  payment: PropTypes.object.isRequired,
  showTikkieDetail: PropTypes.func,
};

export default TransactionLineItem;

export const selectPaymentRequests = (state) =>
  state.get('paymentRequest/listContainer').get('paymentRequests').toJS();
export const selectPaymentRequestsIsLoading = (state) =>
  state.get('paymentRequest/listContainer').get('paymentRequestsIsLoading');
export const selectPaymentRequestsErrorMessage = (state) =>
  state.get('paymentRequest/listContainer').get('paymentRequestsErrorMessage');
export const selectFilterDateFrom = (state) =>
  state.get('paymentRequest/listContainer').get('filterDateFrom');
export const selectFilterDateTo = (state) =>
  state.get('paymentRequest/listContainer').get('filterDateTo');
export const selectFilterDateLabel = (state) =>
  state.get('paymentRequest/listContainer').get('filterDateLabel');
export const selectFilterStatus = (state) =>
  state.get('paymentRequest/listContainer').get('filterStatus').toJS();
export const selectFilterSearch = (state) =>
  state.get('paymentRequest/listContainer').get('filterSearch');

import React from 'react';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { campaignTypeIcon } from '../shared';
import { Campaign } from '../../../types/cashback-campaigns';
import { toCurrency } from '../../../utils';

interface Props {
  campaign: Campaign;
}

export function CashbackCampaignsListItem(props: Props) {
  const { campaign } = props;

  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const icon = campaignTypeIcon(campaign);

  const now = moment();
  const isCampaignActive =
    moment(campaign.startDate).isBefore(now) &&
    moment(campaign.endDate).isAfter(now);

  const labelColour = blokkieTheme.colors.grey.dark;
  const contentColour = isCampaignActive
    ? blokkieTheme.colors.grey.veryDark
    : blokkieTheme.colors.grey.default;

  const link = `/campaigns/${campaign.id}`;
  const navigateToDetail = () => history.push(link);

  return (
    <div role="listitem">
      <div
        role="link"
        data-href={link}
        tabIndex={0}
        className={styles.card}
        onKeyDown={(event) => event.key === 'Enter' && navigateToDetail()}
        onClick={navigateToDetail}
      >
        <div className={styles.campaignNameWrapper}>
          <div className={styles.iconWrapper}>
            <img src={icon.src} alt={icon.alt} />
          </div>
          <div className={styles.title}>
            <BlokkieTypography tabIndex={0} variant="h3" color={contentColour}>
              {campaign.campaignData.campaignName}
            </BlokkieTypography>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.textContent}>
            <BlokkieTypography tabIndex={0} variant="ps700" color={labelColour}>
              {t('deals.remainingBudget')}
            </BlokkieTypography>

            <div className={styles.text}>
              <BlokkieTypography
                tabIndex={0}
                variant="h3"
                color={contentColour}
              >
                {toCurrency(campaign.budget.availableInCents)}
              </BlokkieTypography>
            </div>
          </div>

          <div className={styles.textContent}>
            <BlokkieTypography tabIndex={0} variant="ps700" color={labelColour}>
              {t('deals.endDate')}
            </BlokkieTypography>

            <div className={styles.text}>
              <BlokkieTypography
                tabIndex={0}
                variant="h3"
                color={contentColour}
              >
                {moment.tz(campaign.endDate, 'UTC').format('D MMM YYYY')}
              </BlokkieTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  card: {
    width: '100%',
    marginBottom: blokkieTheme.spacing(2),
    borderRadius: blokkieTheme.spacing(1),
    backgroundColor: blokkieTheme.colors.grey.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    textDecoration: 'none',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    '&:hover': {
      boxShadow: `0 ${blokkieTheme.spacing(0.5)}px ${blokkieTheme.spacing(
        3,
      )}px 0 rgba(0, 0, 0, 0.15)`,
    },
  },
  iconWrapper: {
    width: blokkieTheme.spacing(4),
    height: blokkieTheme.spacing(4),
    marginRight: blokkieTheme.spacing(2),
    borderRadius: '6.4px',
    backgroundColor: 'rgba(104, 104, 193, 0.1)',
    '& img': {
      width: blokkieTheme.spacing(4),
      height: blokkieTheme.spacing(4),
    },
  },
  contentWrapper: {
    width: 'auto',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'right',
    marginRight: blokkieTheme.spacing(3),
  },
  campaignNameWrapper: {
    margin: `${blokkieTheme.spacing(3)}px ${blokkieTheme.spacing(4)}px`,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'left',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    width: 'auto',
    overflow: 'hidden',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContent: {
    margin: `${blokkieTheme.spacing(3)}px ${blokkieTheme.spacing(4)}px`,
    marginRight: blokkieTheme.spacing(3),
    width: 'auto',
    overflow: 'hidden',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: blokkieTheme.spacing(1),
  },
});

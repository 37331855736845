import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

import BulkList from '../../../components/Bulk/BulkList';
import {
  selectBulkFiles,
  selectTotalBulkFiles,
  selectBulkFilesErrorMessage,
  selectBulkFilesIsLoading,
  selectBulkListPage,
} from './selectors';
import { loadBulkFiles } from './actions';

import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';
import { selectUser } from '../../App/selectors';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';

function BulkListContainer(props) {
  const history = useHistory();

  useEffect(() => {
    loadBulks(1);
  }, []);

  const loadBulks = (currentPage) => props.loadBulkFiles(currentPage);
  const showBulkDetail = (bulkFile) => history.push(`/bulk/${bulkFile.id}`);

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }
  return (
    <DashboardWrapper id="dashboard">
      <Container>
        <InnerContainer>
          <BulkList
            bulkFiles={props.bulkFiles}
            totalBulkFiles={props.totalBulkFiles}
            bulkFilesIsLoading={props.bulkFilesIsLoading}
            bulkFilesErrorMessage={props.bulkFilesErrorMessage}
            loadBulkFiles={loadBulks}
            showBulkDetail={showBulkDetail}
            currentPage={props.bulkListPage}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

BulkListContainer.propTypes = {
  user: PropTypes.object,
  bulkFiles: PropTypes.array,
  totalBulkFiles: PropTypes.number,
  bulkFilesIsLoading: PropTypes.bool,
  bulkFilesErrorMessage: PropTypes.string,
  loadBulkFiles: PropTypes.func,
  bulkListPage: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  bulkFiles: selectBulkFiles,
  totalBulkFiles: selectTotalBulkFiles,
  bulkFilesIsLoading: selectBulkFilesIsLoading,
  bulkFilesErrorMessage: selectBulkFilesErrorMessage,
  bulkListPage: selectBulkListPage,
});

const mapDispatchToProps = (dispatch) => ({
  loadBulkFiles: (currentPage) => dispatch(loadBulkFiles(currentPage)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'bulkListContainer', reducer });
const withSaga = injectSaga({ key: 'bulkListContainer', saga });

export default compose(withReducer, withSaga, withConnect)(BulkListContainer);

import '!file-loader?name=[name].[ext]!../../../images/gifs/the-office-sorry.gif';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from '../../styleguide';
import { OnboardingParagraph } from '../styledComponents';
import Screen from './Screen';

interface GenericProps {
  organisationName: string;
  onboardingUrl: string;
  dismissError: () => void;
}

function AuthorizedToSignInvalid({
  dismissError,
  organisationName,
  onboardingUrl,
}: GenericProps): JSX.Element {
  const { t } = useTranslation();

  const handleClick = () => {
    const encodedSubject = encodeURI(
      t('onboarding.authorizedToSignScreen.invalidError.mailto.subject'),
    );
    const encodedBody = encodeURI(
      t('onboarding.authorizedToSignScreen.invalidError.mailto.body', {
        onboardingUrl,
      }),
    );
    window.open(`mailto:?subject=${encodedSubject}&body=${encodedBody}`);
  };

  return (
    <Screen
      showGif
      header={t('onboarding.authorizedToSignScreen.invalidError.header')}
      buttonText={t(
        'onboarding.authorizedToSignScreen.invalidError.buttonMail',
      )}
      buttonClick={handleClick}
      previousStep={dismissError}
    >
      <OnboardingParagraph
        textAlign="center"
        marginTop={Space.s0}
        marginBottom={Space.s16}
      >
        {t('onboarding.authorizedToSignScreen.invalidError.body', {
          organisationName,
        })}
      </OnboardingParagraph>
    </Screen>
  );
}

export default AuthorizedToSignInvalid;

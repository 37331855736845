/**
 *
 * Transaction Search Filter Component
 *
 */

/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import messages from './messages';
import { Color, InputField, PrimaryButton } from '../styleguide';

const SearchInputContainer = styled.div`
  flex: 1;
  position: ${(props) => (props.showMobileInput ? 'fixed' : 'inherit')};
  width: ${(props) => (props.showMobileInput ? '100%' : 'initial')};
  height: ${(props) => (props.showMobileInput ? '100%' : 'initial')};
  top: ${(props) => (props.showMobileInput ? 0 : 'initial')};
  left: ${(props) => (props.showMobileInput ? 0 : 'initial')};
  padding: ${(props) => (props.showMobileInput ? '36px' : 0)};
  background: ${(props) => (props.showMobileInput ? 'white' : 'transparent')};
`;

const SearchInput = styled(InputField)`
  z-index: 999;
`;

const BackArrow = styled.img`
  margin-left: 8px;
  font-size: 24px;
  height: 20px;
  line-height: 20px;
  width: 27px;
  color: ${Color.brandColor01};
  cursor: pointer;
  margin: 16px 0;
`;

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      isSearched: false,
      showInputMobile: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.searchedText) {
      this.setState({
        searchText: this.props.searchedText,
        isSearched: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchedText !== this.state.searchText) {
      this.setState({ searchText: nextProps.searchedText });
    }
  }

  setSearchText = (event) =>
    this.setState({ searchText: event.target.value, isSearched: false });

  onSearchKeyDown = (event) => {
    const code = event.keyCode || event.charCode;
    if (code === 13) {
      this.search();
    } else if (code === 27) {
      this.clearSearch();
    }
  };

  clearSearch = () => {
    this.resetState();
    this.props.onSearch('');
  };

  search = () => {
    if (this.state.searchText.length > 2) {
      this.setState({
        isSearched: true,
        showInputMobile: false,
      });
      this.props.onSearch(this.state.searchText);
    } else if (this.props.searchedText && this.state.searchText.length === 0) {
      this.clearSearch();
    }
  };

  showClearSearch = () => this.state.searchText !== '' && this.state.isSearched;

  showSearchButton = () => {
    const doShow =
      (!this.state.isSearched && this.state.searchText.length > 2) ||
      (this.props.searchedText && this.state.searchText.length === 0);
    if (!isMobileOnly) {
      return doShow;
    }
    return doShow && this.state.showInputMobile;
  };

  resetState = () => {
    this.setState({
      searchText: '',
      isSearched: false,
      showInputMobile: false,
    });
  };

  showMobileInput = () => {
    if (isMobileOnly) {
      this.setState({
        showInputMobile: true,
      });
    }
  };

  render() {
    return (
      <SearchInputContainer showMobileInput={this.state.showInputMobile}>
        {isMobileOnly && !this.state.showInputMobile && (
          <SearchInput data-test-id={this.props.dataTestId || 'search-text'}>
            <i className="fa fa-search" />
            <FormattedMessage {...messages.search}>
              {(placeholder) => (
                <input
                  type="text"
                  placeholder={placeholder}
                  value={this.props.searchedText}
                  onFocus={this.showMobileInput}
                  readOnly
                />
              )}
            </FormattedMessage>
            {this.showClearSearch() && (
              <a onClick={this.clearSearch}>
                <i className="fa fa-remove" />
              </a>
            )}
          </SearchInput>
        )}
        {isMobileOnly && this.state.showInputMobile && (
          <BackArrow src="/arrow-back.svg" onClick={this.clearSearch} />
        )}
        {(!isMobileOnly || (isMobileOnly && this.state.showInputMobile)) && (
          <SearchInput data-test-id={this.props.dataTestId || 'search-text'}>
            <i className="fa fa-search" />
            <FormattedMessage {...messages.search_placeholder}>
              {(placeholder) => (
                <input
                  type="text"
                  placeholder={this.props.placeholderText || placeholder}
                  onChange={this.setSearchText}
                  onKeyDown={this.onSearchKeyDown}
                  value={this.state.searchText}
                  autoComplete="off"
                  autoFocus
                />
              )}
            </FormattedMessage>
            {this.showClearSearch() && (
              <a onClick={this.clearSearch}>
                <i className="fa fa-remove" />
              </a>
            )}
            {this.showSearchButton() && (
              <PrimaryButton id="search" onClick={this.search}>
                <FormattedMessage {...messages.search} />
              </PrimaryButton>
            )}
          </SearchInput>
        )}
      </SearchInputContainer>
    );
  }
}

SearchFilter.propTypes = {
  placeholderText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searchedText: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

export default SearchFilter;

/* eslint-disable no-shadow */
import ReactGA4 from 'react-ga4';

export const measurementId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

export const analyticsPageview = () => {
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

export const analyticsEvent = (
  name: string,
  category: string,
  label?: string,
  rest?: object,
) =>
  ReactGA4.event(name, {
    category,
    label,
    ...rest,
  });

export enum AnalyticCategories {
  ONBOARDING = 'onboarding',
  SETTINGS = 'settings',
}

export enum AnalyticOnboardingProducts {
  TIKKIE_BUSINESS = 'Business',
  RECYCLING = 'Recycling',
  CASHBACK = ' Cashback',
}

export enum OnboardingServiceProductType {
  TIKKIE_ALL = 'TIKKIE_ALL',
  TIKKIE_BUSINESS = 'TIKKIE_BUSINESS', // Future proofing - rename ALL -> BUSINESS marked as TODO in current onboarding service
  TIKKIE_RECYCLING = 'TIKKIE_RECYCLING',
  TIKKIE_CASHBACK = 'TIKKIE_CASHBACK',
}

export const analyticsProductTypeMapper = (
  type: OnboardingServiceProductType,
) => {
  switch (type) {
    case OnboardingServiceProductType.TIKKIE_RECYCLING:
      return AnalyticOnboardingProducts.RECYCLING;

    case OnboardingServiceProductType.TIKKIE_CASHBACK:
      return AnalyticOnboardingProducts.CASHBACK;

    // ie. TIKKIE_ALL, TIKKIE_BUSINESS
    default:
      return AnalyticOnboardingProducts.TIKKIE_BUSINESS;
  }
};

import '!file-loader?name=[name].[ext]!../../../images/close-icon.svg';
import { BlokkieButton } from '@tikkie/blokkie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectIntl } from 'react-intl';
import { InfoScreen } from '../InfoScreen';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import Title from '../Title';
import ButtonFooter from '../components/ButtonFooter';
import NavigationHeader from '../components/NavigationHeader';
import {
  OnboardingParagraph,
  RadioButtonContainer,
  Wrapper,
} from '../styledComponents';
import { RiskAnswerOptions } from './RiskAnswerOptions';
import { useOnboardingPageView } from '../../../hooks';

interface RiskQuestionGenericProp {
  setRiskAnswerHandler(answer: RiskAnswerOptions): void;
  previousStep(): void;
  nextStep(): void;
  questionType: string;
  currentAnswer: RiskAnswerOptions;
}

function RiskQuestionGeneric({
  setRiskAnswerHandler,
  previousStep,
  nextStep,
  questionType,
  currentAnswer,
}: RiskQuestionGenericProp): JSX.Element {
  const { t } = useTranslation();

  const [info, setInfo] = useState(false);

  const showInfo = (): void => setInfo(!info);

  const isNextButtonDisabled = (): boolean =>
    currentAnswer === RiskAnswerOptions.UNANSWERED;

  useOnboardingPageView(`${questionType}-risk-question`);
  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={previousStep as () => void}
          clickRight={showInfo}
        />
        <Title>
          {t(`onboarding.fluentflow.riskQuestion.${questionType}.question`)}
        </Title>
        <RadioButtonContainer>
          <OnboardingRadioButton
            key={`${questionType}RiskYes`}
            id={`${questionType}RiskYes`}
            checked={currentAnswer === RiskAnswerOptions.YES}
            onClick={() => setRiskAnswerHandler(RiskAnswerOptions.YES)}
            text={t('onboarding.fluentflow.riskAnswers.yes')}
            data-test-id={`Onboarding-${questionType}Risk-Yes`}
          />
          <OnboardingRadioButton
            key={`${questionType}RiskNo`}
            id={`${questionType}RiskNo`}
            checked={currentAnswer === RiskAnswerOptions.NO}
            onClick={() => setRiskAnswerHandler(RiskAnswerOptions.NO)}
            text={t('onboarding.fluentflow.riskAnswers.no')}
            data-test-id={`Onboarding-${questionType}Risk-No`}
          />
        </RadioButtonContainer>
        <ButtonFooter>
          <BlokkieButton
            onClick={nextStep}
            data-test-id={`Onboarding-${questionType}Risk-NextButton`}
            variant="primary"
            size="large"
            disabled={isNextButtonDisabled()}
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={t('onboarding.fluentflow.riskQuestion.infoHeader')}
        onClick={showInfo}
        fromPage={RiskQuestionGeneric.name}
      >
        <OnboardingParagraph>
          {t(`onboarding.fluentflow.riskQuestion.${questionType}.explanation`)}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

export default injectIntl(RiskQuestionGeneric);

export const EMPTY_UPLOAD_FILE = 'app/BulkCreateContainer/EMPTY_UPLOAD_FILE';
export const BULK_UPLOAD = 'app/BulkCreateContainer/BULK_UPLOAD';
export const BULK_UPLOAD_SUCCESS =
  'app/BulkCreateContainer/BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_ERROR = 'app/BulkCreateContainer/BULK_UPLOAD_ERROR';
export const DELETE_BULK_UPLOAD = 'app/BulkCreateContainer/DELETE_BULK_UPLOAD';
export const DELETE_BULK_UPLOAD_SUCCESS =
  'app/BulkCreateContainer/DELETE_BULK_UPLOAD_SUCCESS';
export const DELETE_BULK_UPLOAD_ERROR =
  'app/BulkCreateContainer/DELETE_BULK_UPLOAD_ERROR';

export const SEND_BULK_UPLOAD = 'app/BulkCreateContainer/SEND_BULK_UPLOAD';
export const SEND_BULK_UPLOAD_SUCCESS =
  'app/BulkCreateContainer/SEND_BULK_UPLOAD_SUCCESS';
export const SEND_BULK_UPLOAD_ERROR =
  'app/BulkCreateContainer/SEND_BULK_UPLOAD_ERROR';

export const RESET_BULK_UPLOAD = 'app/BulkCreateContainer/RESET_BULK_UPLOAD';

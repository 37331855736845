import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InfoScreen } from '../InfoScreen';
import MarkDownParser from './MarkDownParser';
import { productTypeToLokaliseTag } from '../../../containers/OnboardingKYCContainer/config';
import { selectProductType } from '../../../containers/OnboardingKYCContainer/selectors';

interface TikkieTermsProps {
  show: boolean;
  toggleShowTerms: () => void;
  terms: string;
}

function TikkieTerms({
  show,
  toggleShowTerms,
  terms,
}: TikkieTermsProps): JSX.Element {
  const { t } = useTranslation();
  const productType = useSelector(selectProductType);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productTag = productTypeToLokaliseTag.get(productType)!;

  return (
    <InfoScreen
      show={show}
      title={t(`onboarding.terms.infoScreen.${productTag}.header`)}
      onClick={toggleShowTerms}
      fromPage={TikkieTerms.name}
      data-test-id="terms-info-screen"
    >
      <MarkDownParser input={terms} />
    </InfoScreen>
  );
}

export default TikkieTerms;

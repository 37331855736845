import styled from 'styled-components';

const getBottomPx = (slideIn, high) => {
  if (slideIn) {
    if (high) {
      return '96px';
    }
    return '0px';
  }
  return '-96px';
};

export const FooterWrapper = styled.div`
  position: ${(props) => props.position || 'fixed'};
  bottom: ${(props) => getBottomPx(props.slideIn, props.high)};
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 0 -4px 30px 0 rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s;
  display: flex;
  flex-direction: row;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 1056px;
  margin: 0 auto;
  padding: 24px 0px 24px 0px;
  line-height: 48px;
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: #57565b;

  a {
    font-family: MuseoSansRounded700;
    color: #40c4b8;
  }

  button {
    float: right;
    margin-right: 19px;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: auto;
    margin: 0;
    padding: 24px 32px 24px 32px;
  }

  @media ${(props) => props.theme.device.mobile} {
    align-items: center;
    flex-direction: column;
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import Deactivation from '../../../components/Settings/Deactivation';
import { loadSettings, deactivateOrganisation } from '../actions';
import {
  selectSettingsData,
  selectSettingsLoading,
  selectDeactivationError,
  selectDeactivationSuccess,
} from '../selectors';

function DeactivationSettingsContainer(props) {
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(props.settingsData)) {
      props.loadSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  if (props.settingsLoading) {
    return <EmptyLoader />;
  }

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer highMargin>
          <Deactivation
            gotoSettingsDashboard={gotoSettingsDashboard}
            settingsData={props.settingsData}
            deactivateOrganisation={props.dispatchDeactivateOrganisation}
            deactivationError={props.deactivationError}
            deactivationSuccess={props.deactivationSuccess}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

DeactivationSettingsContainer.propTypes = {
  settingsData: PropTypes.object,
  settingsLoading: PropTypes.bool,
  loadSettings: PropTypes.func,
  dispatchDeactivateOrganisation: PropTypes.func,
  deactivationError: PropTypes.object,
  deactivationSuccess: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loadSettings,
  settingsLoading: selectSettingsLoading,
  settingsData: selectSettingsData,
  deactivationError: selectDeactivationError,
  deactivationSuccess: selectDeactivationSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
  dispatchDeactivateOrganisation: (motivation) =>
    dispatch(deactivateOrganisation(motivation)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'DeactivationSettingsContainer', saga });

export default compose(withSaga, withConnect)(DeactivationSettingsContainer);

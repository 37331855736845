import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import onboardingKYCContainerHOC from '../hoc';
import SignUpPending from '../../../components/OnboardingKYC/SignUpPending';
import FormWrapperContainer from '../FormWrapperContainer';
import {
  selectOnboardingKYCData,
  selectSecondApplicantData,
} from '../selectors';

function EndSecondApplicantContainer(props) {
  return (
    <FormWrapperContainer noProgressBar>
      <SignUpPending
        name={
          props.secondApplicantData.firstName !== undefined
            ? props.secondApplicantData.firstName
            : ''
        }
        forSecondApplicant
        organisationName={props.onboardingData.organisationName}
      />
    </FormWrapperContainer>
  );
}

EndSecondApplicantContainer.propTypes = {
  secondApplicantData: PropTypes.object.isRequired,
  onboardingData: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  secondApplicantData: selectSecondApplicantData,
  onboardingData: selectOnboardingKYCData,
});

const withConnect = connect(mapStateToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(EndSecondApplicantContainer);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import Dropdown, { DropdownWrapper } from '../../Dropdown/Dropdown';
import {
  FilterItemFilledWrapper,
  FilterItemWrapper,
  FiltersWrapper,
} from '../../styleguide';
import SearchFilter from '../../SearchFilter';
import { AddButton } from '../styledComponents';
import * as messages from './ListUsers/messages';
import DropdownMenu from '../../Dropdown/DropdownMenu';

const Option = styled.div`
  font-family: MuseoSansRounded;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: ${(props) => props.top && '24px'};
  padding-bottom: ${(props) => props.bottom && '24px'};
`;

function UserManagementFilters({
  selectedFilter,
  filterOptions,
  onFilterSubmit,
  filterTitle,
  searchText,
  onSearch,
  gotoAddUser,
  gotoAddExistingUser,
  hasOtherPermittedOrganisations,
}) {
  const [showAddUserDropdown, setShowAddUserDropdown] = useState(false);
  const toggleShowAddUserDropdown = () =>
    setShowAddUserDropdown(!showAddUserDropdown);

  const dataTestIdStart = () =>
    gotoAddExistingUser
      ? 'user-management-list-users-'
      : 'user-management-add-existing-user-';

  const renderButton = () => (
    <FilterItemWrapper mr="0px">
      <AddButton
        text={messages.addUserButtonText}
        alt={messages.addUserButtonText}
        relative
        onClick={
          hasOtherPermittedOrganisations
            ? toggleShowAddUserDropdown
            : gotoAddUser
        }
        dataTestId={`${dataTestIdStart()}add-user-button`}
      />
      <DropdownWrapper>
        <DropdownMenu
          mobileFullScreen={false}
          show={showAddUserDropdown}
          handleOutsideClick={toggleShowAddUserDropdown}
          dataTestId={`${dataTestIdStart()}add-user-drop-down-menu`}
        >
          <Option
            onClick={gotoAddUser}
            top
            data-test-id={`${dataTestIdStart()}add-new-user-drop-down-menu-option`}
          >
            Nieuwe medewerker
          </Option>
          <Option
            onClick={gotoAddExistingUser}
            bottom
            data-test-id={`${dataTestIdStart()}add-existing-user-drop-down-menu-option`}
          >
            Bestaande medewerker
          </Option>
        </DropdownMenu>
      </DropdownWrapper>
    </FilterItemWrapper>
  );

  const ButtonAndFilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  `;

  return (
    <FiltersWrapper
      flexDirection={isMobileOnly && gotoAddUser ? 'column' : 'row'}
    >
      {isMobileOnly && (
        <>
          {!gotoAddUser && (
            <FilterItemWrapper>
              <Dropdown
                selected={selectedFilter}
                onSubmit={onFilterSubmit}
                options={filterOptions}
                dataTestId={`${dataTestIdStart()}filters-drop-down`}
              >
                {filterTitle}
              </Dropdown>
            </FilterItemWrapper>
          )}
          <FilterItemFilledWrapper
            ml={gotoAddUser ? '0px' : '8px'}
            pl="0px"
            mr="0px"
          >
            <SearchFilter
              onSearch={onSearch}
              searchedText={searchText}
              placeholderText="Zoek op naam of e-mail"
              dataTestId={`${dataTestIdStart()}search-text`}
            />
          </FilterItemFilledWrapper>
          {gotoAddUser && (
            <ButtonAndFilterWrapper>
              <FilterItemWrapper>
                <Dropdown
                  selected={selectedFilter}
                  options={filterOptions}
                  onSubmit={onFilterSubmit}
                  dataTestId={`${dataTestIdStart()}filters-drop-down`}
                >
                  {filterTitle}
                </Dropdown>
              </FilterItemWrapper>
              {renderButton()}
            </ButtonAndFilterWrapper>
          )}
        </>
      )}
      {!isMobileOnly && (
        <>
          <FilterItemWrapper pr="0px">
            <Dropdown
              selected={selectedFilter}
              options={filterOptions}
              onSubmit={onFilterSubmit}
              dataTestId={`${dataTestIdStart()}filters-drop-down`}
            >
              {filterTitle}
            </Dropdown>
          </FilterItemWrapper>
          <FilterItemFilledWrapper pl="0px" mr={gotoAddUser ? '16px' : '0px'}>
            <SearchFilter
              onSearch={onSearch}
              searchedText={searchText}
              placeholderText="Zoek op naam of e-mail"
              dataTestId={`${dataTestIdStart()}search-text`}
            />
          </FilterItemFilledWrapper>
          {gotoAddUser && renderButton()}
        </>
      )}
    </FiltersWrapper>
  );
}

UserManagementFilters.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string,
  filterOptions: PropTypes.array.isRequired,
  filterTitle: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  gotoAddUser: PropTypes.func,
  hasOtherPermittedOrganisations: PropTypes.bool,
  gotoAddExistingUser: PropTypes.func,
};

export default UserManagementFilters;

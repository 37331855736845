import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'amber';
import { Overlay } from '../styleguide';

const TextBalloonBox = styled.div`
  position: absolute;
  top: ${(props) => (props.revert || props.noPosition ? '' : '115%')};
  bottom: ${(props) => (props.revert ? '115%' : '')};
  left: ${(props) => (props.right || props.noPosition ? '' : '0')};
  right: ${(props) => (props.right ? '0' : '')};
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  width: 300px;
  align-items: ${(props) => (props.center ? 'center' : 'initial')};
  justify-content: center;
  flex-direction: column;
  z-index: 15;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  background-color: ${(props) => (props.error ? '#ED5565' : '#ffffff')};
  color: ${(props) => (props.error ? '#ffffff' : '#57565b')};
`;

const UpArrow = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  top: ${(props) => (props.revert ? '' : '-9px')};
  bottom: ${(props) => (props.revert ? '-9px' : '')};
  left: ${(props) => (props.right ? '' : '50px')};
  right: ${(props) => (props.right ? '50px' : '')};
  transform: rotate(-45deg);
  box-shadow: ${(props) =>
    props.revert
      ? '-10px 10px 30px rgba(0, 0, 0, 0.15)'
      : '10px -10px 30px rgba(0, 0, 0, 0.15)'};
  z-index: -5;
  background-color: ${(props) => (props.error ? '#ED5565' : '#ffffff')};
`;

export function TextBalloon(props) {
  return (
    <TextBalloonBox {...props}>
      <UpArrow error={props.error} right={props.right} revert={props.revert} />
      {props.children}
    </TextBalloonBox>
  );
}

TextBalloon.propTypes = {
  show: PropTypes.bool.isRequired,
  center: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.any,
  horizontalBoxCenter: PropTypes.bool,
  right: PropTypes.bool,
  revert: PropTypes.bool,
};

export const Text = styled.div`
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: #57565b;
  margin-bottom: 8px;
`;

export const ButtonBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
`;

export function ConfirmBalloon(props) {
  return (
    props.show && (
      <>
        {!props.withoutOverlay && <Overlay onClick={props.onCancel} />}
        <TextBalloon {...props} horizontalBoxCenter>
          <Text>Weet je het zeker?</Text>
          <ButtonBox>
            <Button
              type="secondary"
              onClick={props.onConfirm}
              data-test-id="btn-confirm-balloon-confirm"
            >
              {props.confirmText}
            </Button>
            <Button
              type="link"
              onClick={props.onCancel}
              data-test-id="btn-confirm-balloon-cancel"
            >
              Annuleren
            </Button>
          </ButtonBox>
        </TextBalloon>
      </>
    )
  );
}

ConfirmBalloon.propTypes = {
  show: PropTypes.bool.isRequired,
  left: PropTypes.number,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  right: PropTypes.bool,
  revert: PropTypes.bool,
  withoutOverlay: PropTypes.bool,
  noPosition: PropTypes.bool,
};

import { takeLatest, call, put } from 'redux-saga/effects';
import moment from 'moment';
import request from '../../../utils/request';
import {
  LOAD_PAYMENT_REQUESTS,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
} from './constants';
import {
  loadPaymentRequestsSuccess,
  loadPaymentRequestsError,
} from './actions';
import config from '../../../config';

export default function* watcherSaga() {
  yield takeLatest(LOAD_PAYMENT_REQUESTS, loadPaymentRequestsSaga);
  yield takeLatest(
    DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
    downloadMultiplePaymentRequestsSaga,
  );
}

export function* loadPaymentRequestsSaga(action) {
  const { page, from, to, status, search, paymentRequestBatchId } =
    action.payload;

  const currentPage = page - 1;
  let fromDate = '';
  let toDate = '';
  let statusList = '';
  const searchText = encodeURIComponent(search);
  const batchId = paymentRequestBatchId || '';

  if (from > 0 && to > 0) {
    fromDate = moment(from).format('YYYY-MM-DD');
    toDate = moment(to).format('YYYY-MM-DD');
  }
  const calculatedStatus = [...new Set(status)];
  /* If there's no open/closed flag set filter to only return OPEN tikkies. This will return all non-closed (non-archived) tikkies,
    also the ones with calculated states like expired (unless those are explicity set in the filter also). */
  if (!status.includes('open') && !status.includes('closed')) {
    calculatedStatus.push('open');
  }
  statusList = calculatedStatus.join(',');

  const requestUrl = `${config.API_URL}/search?paymentRequestBatchId=${batchId}&fromDate=${fromDate}&toDate=${toDate}&search=${searchText}&status=${statusList}&page=${currentPage}&size=20&withPaymentInfo=false`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadPaymentRequestsSuccess(response));
  } catch (err) {
    yield put(loadPaymentRequestsError(err.message));
  }
}

export function* downloadMultiplePaymentRequestsSaga(action) {
  const {
    from,
    to,
    status,
    search,
    allSelected,
    selectedPaymentRequests,
    csvType,
    paymentRequestBatchId,
  } = action.payload;

  let fromDate = '';
  let toDate = '';
  let statusList = '';
  const searchText = encodeURIComponent(search);
  const batchId = paymentRequestBatchId || '';
  const selectAll = allSelected !== 'no';

  if (from > 0 && to > 0) {
    fromDate = moment(from).format('YYYY-MM-DD');
    toDate = moment(to).format('YYYY-MM-DD');
  }

  const calculatedStatus = [...status];
  /* If there's no open/closed flag set filter to only return OPEN tikkies. This will return all non-closed (non-archived) tikkies,
    also the ones with calculated states like expired (unless those are explicity set in the filter also). */
  if (!status.includes('open') && !status.includes('closed')) {
    calculatedStatus.push('open');
  }
  statusList = calculatedStatus.join(',');

  const requestUrl = `${config.API_URL}/csv/?paymentRequestBatchId=${batchId}&fromDate=${fromDate}&toDate=${toDate}&search=${searchText}&status=${statusList}&selectAll=${selectAll}&ids=${selectedPaymentRequests}&csvType=${csvType}`;
  window.location = requestUrl;
}

import React, { useEffect, useState } from 'react';
import { Container, InputTile, PageTitle, FormTile, Info } from 'amber';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as messages from './messages';
import {
  AddButton,
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
  InputErrorBox,
  SelectWrapper,
} from '../../styledComponents';
import { isValidEmail, isValidPhone } from '../../../Util';
import { isEmpty, isEmptyString } from '../../../../utils';
import { ROLE_ADMIN, ROLE_USER } from '../../../../global-constants';
import { uppercaseFirstLetterAndLowercaseTheRest } from '../../../../utils/strings';
import { Color } from '../../../styleguide';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsPageview,
} from '../../../../ga4';

function AddUser(props) {
  const {
    goBackToListUsers,
    goToUserDetails,
    goToExistingUsers,
    addUserToActiveOrganisation,
    addUserToActiveOrganisationSuccess,
    addUserToActiveOrganisationError,
    resetAddUserToActiveOrganisation,
    otpEnabled,
  } = props;

  useEffect(() => {
    analyticsPageview();
  }, []);

  useEffect(
    () => () => resetAddUserToActiveOrganisation(),
    [resetAddUserToActiveOrganisation],
  );

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(
    otpEnabled ? '' : '+31699999999',
  );
  const [functionalRole, setFunctionalRole] = useState(null);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);

  const [firstNameValidationError, setFirstNameValidationError] =
    useState(false);
  const [lastNameValidationError, setLastNameValidationError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [phoneValidationError, setPhoneValidationError] = useState(false);

  const [roleTitle, setRoleTitle] = useState(messages.role);

  const hasErrors = !isEmpty(addUserToActiveOrganisationError);

  const addUser = () => {
    const user = {
      firstName,
      lastName,
      email,
      phoneNumber,
      functionalRole,
    };
    analyticsEvent(
      AnalyticCategories.SETTINGS,
      'business-portal-AddUser-add-button',
    );
    addUserToActiveOrganisation(user);
  };

  const userFunctionalRoles = [ROLE_ADMIN, ROLE_USER];

  const validateFirstName = (value) => {
    if (isEmptyString(value) || value.length > 45) {
      setFirstNameValidationError(true);
    } else {
      setFirstNameValidationError(false);
    }
  };

  const validateLastName = (value) => {
    if (isEmptyString(value) || value.length > 45) {
      setLastNameValidationError(true);
    } else {
      setLastNameValidationError(false);
    }
  };

  const validateEmail = (value) => {
    if (isEmptyString(value) || !isValidEmail(value) || value.length > 255) {
      setEmailValidationError(true);
    } else {
      setEmailValidationError(false);
    }
  };

  const validatePhoneNumber = (value) => {
    if (isEmptyString(value) || !isValidPhone(value) || value.length > 15) {
      setPhoneValidationError(true);
    } else {
      setPhoneValidationError(false);
    }
  };

  const phoneHasFormat = (startString, expectedLength) =>
    phoneNumber.startsWith(startString) &&
    phoneNumber.length === expectedLength;

  const convertToInternationalPhone = (firstNumbers) =>
    setPhoneNumber(phoneNumber.replace(firstNumbers, '+316'));

  const onBlurPhone = () => {
    if (phoneHasFormat('00316', 13)) {
      convertToInternationalPhone('00316');
    }
    if (phoneHasFormat('316', 11)) {
      convertToInternationalPhone('316');
    }
    if (phoneHasFormat('06', 10)) {
      convertToInternationalPhone('06');
    }
    if (phoneHasFormat('6', 9)) {
      convertToInternationalPhone('6');
    }
  };

  const updateRoleTitle = (name) => {
    if (name && !isEmptyString(name)) {
      setRoleTitle(messages.role.replace(messages.rolePlaceholder, name));
    } else {
      setRoleTitle(messages.role);
    }
  };

  useEffect(() => {
    if (
      !isEmptyString(firstName) &&
      !isEmptyString(lastName) &&
      !isEmptyString(email) &&
      !isEmptyString(phoneNumber) &&
      !firstNameValidationError &&
      !lastNameValidationError &&
      !emailValidationError &&
      !phoneValidationError &&
      userFunctionalRoles.includes(functionalRole)
    ) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    firstNameValidationError,
    lastNameValidationError,
    emailValidationError,
    phoneValidationError,
    functionalRole,
  ]);

  useEffect(() => {
    if (!isEmpty(addUserToActiveOrganisationSuccess)) {
      goToUserDetails(addUserToActiveOrganisationSuccess.id);
    }
  }, [goToUserDetails, addUserToActiveOrganisationSuccess]);

  if (hasErrors) {
    if (addUserToActiveOrganisationError.statusCode === 403) {
      return ErrorPageNoContact({
        pageTitle: messages.title,
        showUnauthorizedDescription: true,
        onClickBackOverview: goBackToListUsers,
      });
    }

    if (addUserToActiveOrganisationError.statusCode === 423) {
      return ErrorPageNoContact({
        pageTitle: messages.title,
        errorTitle: messages.errorUserAlreadyInOrg.errorTitle,
        description: messages.errorUserAlreadyInOrg.description,
        secondaryButtonLabel: messages.errorUserAlreadyInOrg.buttonLabel,
        onClickBackOverview: () => {
          goToExistingUsers();
        },
      });
    }

    return ErrorPageWithContact({
      pageTitle: messages.title,
      errorType: 'ContactSupport',
      onClickBackOverview: goBackToListUsers,
    });
  }

  return (
    <Container row col={12} ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton
        goBack={goBackToListUsers}
        text={messages.backButtonText}
        dataTestId="user-management-add-user-back-button"
      />
      <PageTitle>{messages.title}</PageTitle>
      <Container row mb={24}>
        <InputTile
          title={messages.firstName}
          desc={messages.firstNameDescription}
          value={firstName}
          onChange={(fieldId, value) => {
            validateFirstName(value);
            setFirstName(value);
            updateRoleTitle(value);
          }}
          type="text"
          id="firstName"
          dataTestId="user-management-add-user-firstname"
        >
          {firstNameValidationError && (
            <InputErrorBox errorText={messages.firstNameValidationError} />
          )}
        </InputTile>
      </Container>
      <Container row mb={24}>
        <InputTile
          title={messages.lastName}
          desc={messages.lastNameDescription}
          value={lastName}
          onChange={(_fieldId, value) => {
            validateLastName(value);
            setLastName(value);
          }}
          type="text"
          id="lastName"
          dataTestId="user-management-add-user-lastname"
        >
          {lastNameValidationError && (
            <InputErrorBox errorText={messages.lastNameValidationError} />
          )}
        </InputTile>
      </Container>
      <Container row mb={24}>
        <InputTile
          title={messages.email}
          desc={messages.emailDescription}
          value={email}
          type="text"
          id="email"
          dataTestId="user-management-add-user-email"
          onChange={(_fieldId, value) => {
            setEmail(value);
          }}
          onBlur={() => validateEmail(email)}
        >
          {emailValidationError && (
            <InputErrorBox errorText={messages.emailVerificationError} />
          )}
        </InputTile>
      </Container>
      {otpEnabled && (
        <Container row mb={24}>
          <InputTile
            title={messages.phoneNumber}
            desc={messages.phoneNumberDescription}
            value={phoneNumber}
            onChange={(_fieldId, value) => {
              setPhoneNumber(value);
            }}
            onBlur={() => {
              onBlurPhone();
              validatePhoneNumber(phoneNumber);
            }}
            type="text"
            id="phoneNumber"
            dataTestId="user-management-add-user-phonenumber"
          >
            {phoneValidationError && (
              <InputErrorBox
                errorText={messages.phoneNumberVerificationError}
              />
            )}
          </InputTile>
        </Container>
      )}
      <Container row mb={24}>
        <FormTile
          title={roleTitle}
          desc={messages.roleDescription}
          id="role"
          dataTestId="user-management-add-user-role"
        >
          <Container col={11}>
            <SelectWrapper
              rounded
              textColor={Color.gray01}
              color={Color.gray07}
            >
              <select
                value={
                  uppercaseFirstLetterAndLowercaseTheRest(functionalRole) ||
                  messages.roleToCreateUserText
                }
                onChange={(e) =>
                  setFunctionalRole(e.target.value.toUpperCase())
                }
              >
                <option value={null} key={messages.roleToCreateUserText}>
                  {messages.roleToCreateUserText}
                </option>
                {userFunctionalRoles.map((role) => (
                  <option
                    value={uppercaseFirstLetterAndLowercaseTheRest(role)}
                    key={role}
                  >
                    {uppercaseFirstLetterAndLowercaseTheRest(role)}
                  </option>
                ))}
              </select>
            </SelectWrapper>
          </Container>
          <Container col={1} center>
            <Info
              dataTestId="user-management-add-user-role-info"
              desc={messages.roleExplanation}
            />
          </Container>
        </FormTile>
      </Container>
      <Container row mb={24}>
        <AddButton
          text={messages.addUserButtonText}
          alt={messages.addUserButtonText}
          onClick={addUser}
          disabled={addButtonDisabled}
        />
      </Container>
    </Container>
  );
}

AddUser.propTypes = {
  goBackToListUsers: PropTypes.func.isRequired,
  goToUserDetails: PropTypes.func.isRequired,
  goToExistingUsers: PropTypes.func.isRequired,
  addUserToActiveOrganisation: PropTypes.func.isRequired,
  addUserToActiveOrganisationSuccess: PropTypes.object.isRequired,
  addUserToActiveOrganisationError: PropTypes.object.isRequired,
  resetAddUserToActiveOrganisation: PropTypes.func.isRequired,
  otpEnabled: PropTypes.bool,
};

export default injectIntl(AddUser);

import React, { ChangeEventHandler } from 'react';
import { createUseStyles } from 'react-jss';
import { blokkieTheme } from '@tikkie/blokkie';
import { FontSize, FontWeight } from '../../styleguide';

interface TextareaLabelProps {
  dataTestId?: string;
  style?: React.CSSProperties;
  label?: string;
  text?: string;
  rows?: number;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
  },
  label: {
    fontWeight: FontWeight.s500,
    fontFamily: 'MuseoSansRounded700',
    fontSize: FontSize.s14,
    lineHeight: FontSize.s18,
    color: blokkieTheme.colors.grey.veryDark,
    marginBottom: blokkieTheme.spacing(1),
  },
  textarea: {
    width: '100%',
    fontFamily: 'MuseoSansRounded700',
    fontSize: FontSize.s18,
    lineHeight: FontSize.s24,
    padding: '12px 16px',
    textAlign: 'left',
    resize: 'none',
    whiteSpace: 'pre-wrap',
  },
  textareaDisabled: {
    color: blokkieTheme.colors.grey.dark,
    WebkitTextFillColor: blokkieTheme.colors.grey.dark,
    opacity: 1,
    backgroundColor: blokkieTheme.colors.grey.veryLight,
    borderRadius: '6px',
    border: `2px solid ${blokkieTheme.colors.grey.light}`,
  },
  [blokkieTheme.mediaQueries.smallPhone]: {
    textarea: {
      fontSize: FontSize.s14,
      lineHeight: FontSize.s18,
    },
  },
});

export function TextAreaLabel({
  dataTestId,
  style,
  label,
  text,
  rows,
  onChange,
  disabled,
}: TextareaLabelProps): JSX.Element {
  const classes = useStyles();

  return (
    <div style={style} className={classes.wrapper} data-test-id={dataTestId}>
      {label && <div className={classes.label}>{label}</div>}
      <textarea
        rows={rows}
        className={`${classes.textarea} ${
          disabled && classes.textareaDisabled
        }`}
        disabled={disabled}
        defaultValue={text}
        onChange={onChange}
      />
    </div>
  );
}

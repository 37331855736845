/**
 *
 * Styleguide by A.M.B.E.R.
 *
 */
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Color = {
  brandColor01: '#40C4B8', // Green
  brandColor02: '#6868C1', // Light purple
  brandColor03: '#424284', // Purple
  brandColor04: '#34346d', // Dark purple

  lightPurple: '#C2C2E6',

  secondaryColor01: '#E25E89', // Pink
  secondaryColor02: '#F1C347', // Yellow
  secondaryColor03: '#38A2C3', // Turquoise

  white: '#FFFFFF',
  gray01: '#57565B',
  gray02: '#9B9B9E',
  gray03: '#BEBEBF',
  gray04: '#DFDFDF',
  gray05: '#F4F4F4',
  gray06: '#f5f6fa',
  gray07: '#c7c7c8',
  gray08: 'rgba(0, 0, 0, 0.05)',

  errorColor: '#ED5565', // Red
  backgroundColor: '#F5F6FA', // Very light gray

  status: {
    green: '#40c4b8',
    lightGreen: '#ecf9f8',
    red: '#ed5565',
    lightRed: '#f2e2e4',
    yellow: '#f1c347',
    lightYellow: '#fdf9ec',
    blue: '#6a6bd4',
    lightBlue: '#e4e4ed',
  },
};

export const Space = {
  s0: '0px',
  s8: '8px',
  s16: '16px',
  s24: '24px',
  s32: '32px',
  s48: '48px',
  s64: '64px',
  s72: '72px',
};

export const FontSize = {
  s14: '14px',
  s16: '16px',
  s18: '18px',
  s22: '22px',
  s24: '24px',
  s30: '30px',
  s40: '40px',
};

export const FontWeight = {
  s100: 100,
  s500: 500,
  s600: 600,
  s700: 700,
  s900: 900,
};

export const Radii = {
  s0: '0px',
  s4: '4px',
  s6: '6px',
  s8: '8px',
  s10: '10px',
  s16: '16px',
  s20: '20px',
  s24: '24px',
  s32: '32px',
  s40: '40px',
  s50: '50px',
  s64: '64px',
  s80: '80px',
};

export const statusMapper = {
  NEW: {
    displayName: 'Actief',
    color: Color.status.green,
    backgroundColor: Color.status.lightGreen,
  },
  ACTIVE: {
    displayName: 'Actief',
    color: Color.status.green,
    backgroundColor: Color.status.lightGreen,
  },
  BLOCKED: {
    displayName: 'Geblokkeerd',
    color: Color.status.red,
    backgroundColor: Color.status.lightRed,
  },
};

export const Header1 = styled.h1`
  margin: 0px;
  padding: 0px;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.33;
  color: ${Color.gray01};
  user-select: none;
  text-align: left;
`;

export const Header2 = styled.h2`
  margin: 0px;
  padding: 0px;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  font-size: 22px;
  line-height: 1;
  color: ${Color.gray01};
  user-select: none;
  text-align: left;
`;

export const Header3 = styled.h3`
  margin: 0px;
  padding: 0px;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  font-size: 18px;
  color: ${Color.gray01};
  user-select: none;
  text-align: left;
`;

export const Header4 = styled.h4`
  object-fit: contain;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  font-size: 16px;
  color: ${Color.gray01};
  user-select: none;
  text-align: left;
`;

export const Paragraph = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: MuseoSansRounded;
  font-size: 16px;
  line-height: 1.5;
  color: ${Color.gray01};
  user-select: none;
`;

export const PrimaryButton = styled.button.attrs((props) => ({
  ...props,
}))`
  height: 48px;
  width: auto;
  padding: 0px 24px;
  text-align: center;
  border-radius: 50px;
  border: solid 2px ${Color.brandColor01};
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.white};
  background-color: ${Color.brandColor01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);

  :disabled {
    opacity: 0.2;
    border: 2px solid transparent;
    box-shadow: none;
    cursor: default;
  }

  :hover:enabled {
    box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.8);
  }

  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

export const SecondaryButton = styled.button`
  display: inline-block;
  height: 48px;
  padding: 0px 24px;
  cursor: pointer;
  text-align: center;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.gray01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  background-color: ${Color.white};
  border: 2px solid ${Color.gray05};
  border-radius: ${(props) => (props.rounded ? '50px' : '0px')};
  margin: ${(props) => (props.margin ? props.margin : '')};

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const TertiaryButton = styled.div`
  line-height: 22px;
  display: inline-block;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.brandColor01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
`;

export const DownloadButton = styled.a`
  position: absolute;
  right: 0px;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  line-height: 28px;
  color: ${Color.brandColor01};
  cursor: pointer;
  text-decoration: none;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }

  @media ${(props) => props.theme.device.tablet} {
    right: 32px;
  }
`;

export const MenuItemWrapper = styled.div`
  height: 48px;
  border-radius: 8px;
  background-color: ${Color.white};
  border: solid 2px ${Color.gray05};
`;

export const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  margin: 10px 24px;
  border-radius: 4px;
  background-color: ${Color.backgroundColor};
  border: solid 2px ${Color.gray04};
  cursor: pointer;
`;

export const RadioOption = styled.div`
  float: left;
  width: 24px;
  height: 24px;
  margin: 16px 11px 16px 0px;
  border-radius: 24px;
  background-color: #f7f7f9;
  border: solid 2px ${Color.gray04};

  @media ${(props) => props.theme.device.mobile} {
    width: 32px;
    height: 32px;
    margin: 8px 11px 8px 0px;
  }
`;

export const ActiveRadioOption = styled(RadioOption)`
  position: relative;
  background-color: ${Color.brandColor01};
  border: solid 2px ${Color.brandColor01};
`;

export const RadioOptionDot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 2px ${Color.white};

  @media ${(props) => props.theme.device.mobile} {
    width: 28px;
    height: 28px;
  }
`;

export const CheckOption = styled.div`
  position: relative;
  float: left;
  width: 24px;
  height: 24px;
  margin: 12px 11px 12px 0px;
  border-radius: 4px;
  background-color: #f7f7f9;
  border: solid 2px ${Color.gray04};
  line-height: 18px;
  text-align: center;

  i {
    font-size: 12px;
    color: ${Color.gray04};
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 32px;
    height: 32px;
    line-height: 26px;

    i {
      font-size: 14px !important;
    }
  }
`;

export const ActiveCheckOption = styled(CheckOption)`
  position: relative;
  background-color: ${Color.brandColor01};
  border: solid 2px ${Color.brandColor01};
  color: white;
  line-height: 18px;
  text-align: center;

  i {
    font-size: 12px;
    color: ${Color.white};
  }
`;

export const InputField = styled(MenuItemWrapper)`
  display: flex;
  position: relative;
  border: none;
  height: 48px;
  border-radius: 8px;
  background-color: ${Color.white};

  i.fa-search {
    position: absolute;
    margin-left: 16px;
    line-height: 44px;
    color: ${Color.gray04};
    margin-right: 8px;
  }

  i + input {
    padding-left: 40px;
  }

  a {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    top: 12px;
    line-height: 22px;
    right: 16px;
    background-color: rgba(64, 196, 184, 0.1);
    font-size: 12px;
    text-align: center;
    cursor: pointer;

    i {
      color: ${Color.brandColor01};
    }
  }

  button {
    position: absolute;
    height: 32px;
    line-height: 29px;
    font-size: 14px;
    right: 16px;
    top: 8px;
    box-shadow: none;
  }

  input {
    flex: 1;
    height: 48px;
    outline: none;
    color: ${Color.gray01};
    border-radius: 8px;
    border: solid 2px ${Color.gray05};
    font-family: MuseoSansRounded;

    ::placeholder {
      font-size: 16px;
      color: ${Color.gray03};
    }

    :hover,
    :focus {
      border: solid 2px ${Color.white};
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    }
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    input {
      width: 45px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  line-height: normal;
  padding: 14px;
  object-fit: contain;
  border-radius: 10px;
  background-color: ${Color.white};
  border: solid 2px ${Color.gray05};
  outline: none;
  font-size: 18px;
  color: ${Color.brandColor01};
  caret-color: ${Color.brandColor01};
  font-family: MuseoSansRounded700;

  :hover,
  :focus {
    border: solid 2px transparent;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
  }

  ::placeholder {
    margin-left: 8px;
    font-size: 18px;
    color: ${Color.gray03};
  }
`;

export const Password = styled(Input)`
  letter-spacing: 4px;

  ::placeholder {
    letter-spacing: normal;
  }
`;

export const Dropdown = styled.button`
  height: 48px;
  width: auto;
  line-height: 44px;
  padding: 0px 24px;
  text-align: center;
  border-radius: 8px;
  border: solid 2px ${Color.gray05};
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.gray01};
  background-color: ${Color.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  i {
    margin-left: 8px;
    color: ${Color.gray02};
    font-size: 20px;
    vertical-align: text-top;
  }

  @media ${(props) => props.theme.device.mobile} {
    padding: 0px 8px 0px 16px;
  }
`;

export const DropdownActive = styled(Dropdown)`
  color: ${Color.brandColor01};
`;

export const DropdownSelected = styled(Dropdown)`
  color: ${Color.white};
  background-color: ${Color.brandColor01};
  border: none;
  line-height: 48px !important;

  :hover:enabled {
    box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.8);
  }

  i {
    padding-left: 0px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    padding: 4px;
    border-radius: 20px;
    color: ${Color.brandColor01};
    background-color: ${Color.white};
  }
`;

export const Caption = styled.div`
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.gray03};
  user-select: none;
  display: inline;
`;

export const CaptionSmall = styled(Caption)`
  font-size: 14px;
  line-height: normal;
  color: ${Color.gray03};
`;

export const CaptionLink = styled(Caption)`
  color: ${Color.brandColor01};
  cursor: pointer;
  border-bottom: 1px solid ${Color.brandColor01};
`;

export const Panel = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${Color.white};
`;

export const NonMobileWrapper = styled.div`
  display: block;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
  }
`;

export const Hidden = styled.div`
  display: none;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  cursor: initial;
`;

export const Divider = styled.div`
  border-top: 2px solid #f2f2f3;
  margin-bottom: 8px;
`;

export const Tabs = styled.div`
  height: 28px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '13px')};
  margin-bottom: ${(props) => props.marginBottom};
  font-size: 18px;

  @media ${(props) => props.theme.device.mobile} {
    text-align: center;
    margin: 0px auto;
    height: 35px;
    width: 100%;
    background-color: ${Color.brandColor02};
    font-size: 16px;
    display: flex;
    overflow-x: scroll;
    padding: 0 50px;
  }
`;

export const TabItem = styled(Link)`
  display: inline-block;
  height: 26px;
  line-height: 26px;
  margin-left: 24px;
  padding-bottom: 2px;
  font-family: MuseoSansRounded700;
  color: ${(props) => (props.active ? '#6868c1' : Color.gray03)};
  cursor: pointer;
  border-bottom: 3px solid
    ${(props) => (props.active ? '#6868c1' : 'transparent')};
  user-select: none;
  text-decoration: none;

  :first-child {
    margin-left: 0px;
  }

  :hover {
    color: #6868c1;
    border-bottom: 3px solid #6868c1;
  }

  :active,
  :focus {
    outline: none;
  }

  @media ${(props) => props.theme.device.mobile} {
    height: 35px;
    padding-bottom: 11px;
    color: ${Color.white};
    opacity: ${(props) => (props.active ? 1 : 0.5)};
    white-space: nowrap;

    :active,
    :visited,
    :hover,
    :focus {
      color: ${Color.white};
      border-bottom: 3px solid
        ${(props) => (props.active ? Color.white : 'transparent')};
    }

    :hover {
      border-bottom: 3px solid ${Color.white};
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 30px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 0px 16px 16px 16px;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  flex-wrap: nowrap;
  user-select: none;
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: ${(props) => props.marginBottom || '16px'};
  width: ${(props) => props.width};
  justify-content: ${(props) => props.justifyContent && props.justifyContent};

  @media ${(props) => props.theme.device.tablet} {
    height: 128px;
    overflow-x: auto;
  }
`;

export const FilterItemWrapper = styled.div`
  position: relative;
  height: 48px;
  margin-top: 0px;
  margin-right: ${(props) => (props.mr ? props.mr : '16px')};
  margin-bottom: 0px;
  margin-left: 0px;

  @media ${(props) => props.theme.device.mobile} {
    display: inline-flex;
    justify-content: flex-start;
    margin: 0px;
    padding-right: ${(props) => (props.pr ? props.pr : '16px')}
    order: 2;
  }
`;

export const FilterItemFilledWrapper = styled(FilterItemWrapper)`
  z-index: 90;
  flex-grow: 1;
  margin-right: ${(props) => (props.mr ? props.mr : '0px')};
  margin-left: ${(props) => (props.ml ? props.ml : '0px')};

  @media ${(props) => props.theme.device.tablet} {
    position: absolute;
    left: 32px;
    right: 32px;
    z-index: 0;
    margin: 0px;
  }

  @media ${(props) => props.theme.device.mobile} {
    order: 0;
    margin-left: ${(props) => (props.ml ? props.ml : '0px')};
    padding-left: ${(props) => (props.pl ? props.pl : '16px')};
  }
`;

export const Shadow = '0 2px 4px 0 rgba(0, 0, 0, 0.05)';

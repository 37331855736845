import { fromJS } from 'immutable';

import {
  CREATE_TIKKIE,
  CREATE_TIKKIE_SUCCESS,
  CREATE_TIKKIE_ERROR,
} from './constants';

const initialState = fromJS({
  amount: 0,
  description: '',
  expiry: 0,
  isCreating: false,
  createTikkieErrorStatus: 0,
  createTikkieErrorMessage: null,
  tikkie: {
    paymentEventUrl: 'https://dev.tikkie.me/pay/e3kk9rpu4u7meh8240v8',
    paymentEventToken: 'e3kk9rpu4u7meh8240v8',
    expectedExpiry: 1502115926000,
    descriptionPaymentPage: 'page',
    displayName: null,
    logo: null,
    url: null,
  },
});

export default function bulkListContainerReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TIKKIE:
      return state
        .set('amount', action.payload.amount)
        .set('description', action.payload.description)
        .set('expiry', action.payload.expiry)
        .set('isCreating', true)
        .set('createTikkieErrorStatus', 0)
        .set('createTikkieErrorMessage', null)
        .set('tikkie', null);
    case CREATE_TIKKIE_SUCCESS:
      return state
        .set('isCreating', false)
        .set('createTikkieErrorStatus', 0)
        .set('createTikkieErrorMessage', null)
        .set('amount', 0)
        .set('description', '')
        .set('expiry', 0)
        .set('tikkie', action.payload.tikkie);
    case CREATE_TIKKIE_ERROR:
      return state
        .set('isCreating', false)
        .set('createTikkieErrorStatus', action.payload.status)
        .set('createTikkieErrorMessage', action.payload.error)
        .set('tikkie', null);
    default:
      return state;
  }
}

export { initialState };

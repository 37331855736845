const device = {
  mobileSmall: `(min-device-width: 320px) and (max-device-width: 568px)`,
  mobileMedium: `(min-device-width: 375px) and (max-device-width: 667px)`,
  mobileLarge: `(min-device-width: 414px) and (max-device-width: 736px)`,
  mobile: `(min-device-width: 320px) and (max-device-width: 736px)`,
  tablet: `(min-device-width: 768px) and (max-device-width: 1024px)`,
};

const orientation = {
  portrait: `(orientation: portrait)`,
  landscape: `(orientation: landscape)`,
};

const palette = {
  green: '#4db6ac',
  yellow: '#f1c347',
  red: '#ed5565',
  purple: '#686ABB',
  gray: '#9b9b9b',
  lightGreen: '#EFF7F6',
  lightYellow: '#FCF9ED',
  lightRed: '#FAEFEF',
  lightPurple: '#EFEFF7',
  lightGray: 'rgba(199, 199, 200, 0.6)',
  white: '#FFFFFF',
};
const theme = {
  device,
  orientation,
  palette,
};

export default theme;

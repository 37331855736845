import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import moment from 'moment';
import config from '../../../config';

import type { Campaign } from '../../../types/cashback-campaigns';
import { CashbackCampaignDetailLoader } from './CashbackCampaignDetailLoader';
import { CashbackCampaignDetailEmpty } from './CashbackCampaignDetailEmpty';
import { CashbackCampaignDetailError } from './CashbackCampaignDetailError';
import { toCurrency } from '../../../utils';

interface Props {
  campaignId: string;
  onCampaignLoad?: (campaign: Campaign) => void;
}

export function CashbackCampaignDetail({ campaignId, onCampaignLoad }: Props) {
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState<Campaign>();
  const { t } = useTranslation();
  const styles = useStyles();

  const fetchCampaign = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    try {
      setLoading(true);

      const { data } = await axios.get(
        `${config.API_URL}/ms/deals/v1/campaigns/${campaignId}`,
        { withCredentials: true },
      );

      setCampaign(data);
      onCampaignLoad?.(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const isCampaignEmpty = () => !Object.keys(campaign || {}).length;

  useEffect(() => {
    fetchCampaign();
  }, [campaign?.id]);

  return (
    <div>
      {isLoading && <CashbackCampaignDetailLoader />}
      {isError && <CashbackCampaignDetailError onClick={fetchCampaign} />}
      {!isLoading && !isError && isCampaignEmpty() && (
        <CashbackCampaignDetailEmpty onClick={fetchCampaign} />
      )}
      {!isLoading && !!campaign && !isCampaignEmpty() && (
        <div role="row" className={styles.card}>
          <div className={styles.row}>
            <div
              className={styles.item}
              style={{ paddingTop: 0, borderTop: 'none' }}
            >
              <BlokkieTypography
                variant="ps700"
                tabIndex={0}
                color={blokkieTheme.colors.grey.dark}
              >
                {t('deals.campaignName')}
              </BlokkieTypography>

              <BlokkieTypography
                variant="h3"
                tabIndex={0}
                color={blokkieTheme.colors.grey.veryDark}
                className={styles.rowContent}
              >
                {campaign.campaignData.campaignName}
              </BlokkieTypography>
            </div>

            <div className={styles.itemGroup}>
              <div className={styles.itemWithBorders}>
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.budget')}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="h3"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.veryDark}
                  className={styles.rowContent}
                >
                  {toCurrency(campaign.budget.totalFundedInCents)}
                </BlokkieTypography>
              </div>

              <div
                className={styles.itemWithBorders}
                style={{ paddingBottom: 0 }}
              >
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.totalPaidOut')}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="h3"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.veryDark}
                  className={styles.rowContent}
                >
                  {toCurrency(campaign.budget.totalPaidOutInCents)}
                </BlokkieTypography>
              </div>

              <div
                className={styles.itemWithBorders}
                style={{ paddingBottom: 0 }}
              >
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.reservedBudget')}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="h3"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.veryDark}
                  className={styles.rowContent}
                >
                  {toCurrency(campaign.budget.reservedInCents)}
                </BlokkieTypography>
              </div>

              <div
                className={styles.itemWithBorders}
                style={{ paddingBottom: 0 }}
              >
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.remainingBudget')}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="h3"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.veryDark}
                  className={styles.rowContent}
                >
                  {toCurrency(campaign.budget.availableInCents)}
                </BlokkieTypography>
              </div>
            </div>

            <div className={styles.row}>
              <div
                className={styles.item}
                style={{ paddingBottom: 0, width: '100%' }}
              >
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.campaignId')}
                </BlokkieTypography>

                <BlokkieTypography
                  variant="h3"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.veryDark}
                  className={styles.rowContent}
                >
                  {campaign.id}
                </BlokkieTypography>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.lastItem}>
                <BlokkieTypography
                  variant="ps700"
                  tabIndex={0}
                  color={blokkieTheme.colors.grey.dark}
                >
                  {t('deals.campaignPeriod', {
                    start: moment
                      .tz(campaign.startDate, 'UTC')
                      .format('D MMM YYYY'),
                    end: moment
                      .tz(campaign.endDate, 'UTC')
                      .format('D MMM YYYY'),
                  })}
                </BlokkieTypography>

                {!!campaign.campaignData.visibleToDate &&
                  !!campaign.campaignData.visibleFromDate && (
                    <div style={{ marginTop: blokkieTheme.spacing(2) }}>
                      <BlokkieTypography
                        variant="ps700"
                        tabIndex={0}
                        color={blokkieTheme.colors.grey.dark}
                      >
                        {t('deals.campaignVisibilityPeriod', {
                          start: moment
                            .tz(campaign.campaignData.visibleFromDate, 'UTC')
                            .format('D MMM YYYY'),
                          end: moment
                            .tz(campaign.campaignData.visibleToDate, 'UTC')
                            .format('D MMM YYYY'),
                        })}
                      </BlokkieTypography>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  card: {
    width: '100%',
    padding: blokkieTheme.spacing(3),
    borderRadius: blokkieTheme.spacing(1),
    backgroundColor: blokkieTheme.colors.grey.white,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
  },
  rowContent: {
    marginTop: blokkieTheme.spacing(1),
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
    marginBottom: blokkieTheme.spacing(3),
    '@media (max-width: 712px)': {
      flexDirection: 'column',
    },
  },
  lastItem: {
    composes: 'item',
    '@media (max-width: 712px)': {
      borderTop: `2px solid ${blokkieTheme.colors.grey.veryLight}`,
      paddingTop: blokkieTheme.spacing(2),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 'auto',
    paddingBottom: blokkieTheme.spacing(2),
    marginTop: blokkieTheme.spacing(2),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@media (max-width: 712px)': {
      borderTop: `2px solid ${blokkieTheme.colors.grey.veryLight}`,
      paddingTop: blokkieTheme.spacing(2),
    },
  },
  itemWithBorders: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 'auto',
    paddingLeft: blokkieTheme.spacing(3),
    paddingRight: blokkieTheme.spacing(3),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderLeft: `2px solid ${blokkieTheme.colors.grey.veryLight}`,
    justifyContent: 'center',
    '@media (max-width: 712px)': {
      borderTop: `2px solid ${blokkieTheme.colors.grey.veryLight}`,
      borderLeft: 'none',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: blokkieTheme.spacing(2),
      paddingBottom: blokkieTheme.spacing(2),
    },
  },
  itemGroup: {
    display: 'flex',
    '@media (max-width: 712px)': {
      flexDirection: 'column',
    },
  },
});

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  selectBulkUploadErrorMessage,
  selectBulkUploadErrorStatus,
  selectBulkUploadValidationErrors,
  selectBulkUploadFile,
  selectDeleteBulkUploadErrorMessage,
  selectDeleteBulkUploadErrorStatus,
  selectIsDeletingBulkUpload,
  selectIsSendingBulkUpload,
  selectIsUploading,
  selectSendBulkUploadErrorMessage,
  selectSendBulkUploadErrorStatus,
} from './selectors';
import {
  emptyUploadFile,
  bulkUpload,
  deleteBulkUpload,
  resetBulkUpload,
  sendBulkUpload,
} from './actions';

import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';
import BulkCreate from '../../../components/Bulk/BulkCreate';
import { savePaymentRequestDraft } from '../../App/actions';

function BulkCreateContainer(props) {
  const history = useHistory();
  const location = useLocation();

  const showTikkieDetail = (viewPaymentRequest) => {
    props.savePaymentRequestDraft(viewPaymentRequest);
    history.push('/paymentrequest/draft');
  };

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <BulkCreate
            isUploading={props.isUploading}
            bulkUploadErrorStatus={props.bulkUploadErrorStatus}
            bulkUploadErrorMessage={props.bulkUploadErrorMessage}
            bulkUploadValidationErrors={props.bulkUploadValidationErrors}
            bulkUploadFile={props.bulkUploadFile}
            bulkUpload={props.bulkUpload}
            isDeletingBulkUpload={props.isDeletingBulkUpload}
            deleteBulkUploadErrorStatus={props.deleteBulkUploadErrorStatus}
            deleteBulkUploadErrorMessage={props.deleteBulkUploadErrorMessage}
            deleteBulkUpload={props.deleteBulkUpload}
            isSendingBulkUpload={props.isSendingBulkUpload}
            sendBulkUploadErrorStatus={props.sendBulkUploadErrorStatus}
            sendBulkUploadErrorMessage={props.sendBulkUploadErrorMessage}
            sendBulkUpload={props.sendBulkUpload}
            showDashboard={() => history.push('/')}
            showBulkList={() => history.push('/bulk')}
            emptyUploadFile={props.emptyUploadFile}
            resetBulkUpload={props.resetBulkUpload}
            showTikkieDetail={showTikkieDetail}
            location={location}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

BulkCreateContainer.propTypes = {
  emptyUploadFile: PropTypes.func,
  isUploading: PropTypes.bool,
  bulkUploadErrorStatus: PropTypes.number,
  bulkUploadErrorMessage: PropTypes.string,
  bulkUploadValidationErrors: PropTypes.array,
  bulkUploadFile: PropTypes.object,
  bulkUpload: PropTypes.func,
  isDeletingBulkUpload: PropTypes.bool,
  deleteBulkUploadErrorStatus: PropTypes.number,
  deleteBulkUploadErrorMessage: PropTypes.string,
  deleteBulkUpload: PropTypes.func,
  isSendingBulkUpload: PropTypes.bool,
  sendBulkUploadErrorStatus: PropTypes.number,
  sendBulkUploadErrorMessage: PropTypes.string,
  sendBulkUpload: PropTypes.func,
  resetBulkUpload: PropTypes.func,
  savePaymentRequestDraft: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  bulkUploadErrorStatus: selectBulkUploadErrorStatus,
  bulkUploadErrorMessage: selectBulkUploadErrorMessage,
  bulkUploadValidationErrors: selectBulkUploadValidationErrors,
  bulkUploadFile: selectBulkUploadFile,
  isUploading: selectIsUploading,
  isDeletingBulkUpload: selectIsDeletingBulkUpload,
  deleteBulkUploadErrorStatus: selectDeleteBulkUploadErrorStatus,
  deleteBulkUploadErrorMessage: selectDeleteBulkUploadErrorMessage,
  isSendingBulkUpload: selectIsSendingBulkUpload,
  sendBulkUploadErrorStatus: selectSendBulkUploadErrorStatus,
  sendBulkUploadErrorMessage: selectSendBulkUploadErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  emptyUploadFile: () => dispatch(emptyUploadFile()),
  bulkUpload: (file, fileAsBinaryString) =>
    dispatch(bulkUpload(file, fileAsBinaryString)),
  deleteBulkUpload: (id) => dispatch(deleteBulkUpload(id)),
  sendBulkUpload: (id, title) => dispatch(sendBulkUpload(id, title)),
  resetBulkUpload: () => dispatch(resetBulkUpload()),
  savePaymentRequestDraft: (paymentRequest) =>
    dispatch(savePaymentRequestDraft(paymentRequest)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'bulkCreateContainer', reducer });
const withSaga = injectSaga({ key: 'bulkCreateContainer', saga });

export default compose(withReducer, withSaga, withConnect)(BulkCreateContainer);

/**
 *
 * Components by A.M.B.E.R.
 *
 */
/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '!file-loader?name=[name].[ext]!../images/arrow-back.svg';
import '!file-loader?name=[name].[ext]!../images/back.svg';
import '!file-loader?name=[name].[ext]!../images/chevron.svg';
import '!file-loader?name=[name].[ext]!../images/checkmark2.svg';
import '!file-loader?name=[name].[ext]!../images/partialchecked.svg';
import '!file-loader?name=[name].[ext]!../images/blank.svg';

import theme from '../theme';

import {
  Color,
  PrimaryButton,
  SecondaryButton,
  RadioOption,
  ActiveRadioOption,
  RadioOptionDot,
  Dropdown,
  DropdownSelected,
  DropdownActive,
  Header2,
  Header3,
  Header4,
  Paragraph,
  CaptionSmall,
  Panel,
} from './styleguide';

const Button = styled(SecondaryButton)`
  height: 56px;
  line-height: 56px;
  text-align: left;
  width: 100%;
  border: none;

  @media ${(props) => theme.device.mobile} {
    height: 48px;
    line-height: 48px;
  }
`;

const FloatText = styled.div`
  float: left;

  @media ${(props) => theme.device.mobile} {
    float: none;
    padding-right: 32px;
  }
`;

const FloatTextEllipsis = styled(FloatText)`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FloatButton = styled.div`
  float: right;
`;

const GiphyBoxContainer = styled.div`
  width: 100%;
  text-align: center;

  @media ${(props) => theme.device.mobile} {
    width: auto;
  }
`;

const GifWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  width: 176px;
  height: 176px;
  border: 12px solid rgba(104, 104, 193, 0.1);
  border-radius: 88px;
`;

const GifWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  border: 8px solid ${Color.brandColor02};
  border-radius: 88px;
`;

const Gifje = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 88px;
`;

const GifjeHeader = styled(Header2)`
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.18;
`;

const GifjeParagraph = styled(Paragraph)`
  text-align: center;
  line-height: 1.5;
`;

const OptionalButton = styled(SecondaryButton)`
  border: 1px solid ${Color.gray05};
  border-radius: 50px;
  margin-top: 16px;
`;

const Footer = styled.div`
  padding-top: 16px;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 16px;
  border-radius: 4px;

  border: solid 2px
    ${(props) => (props.checked === 'no' ? '#e9e9e9' : Color.brandColor01)};
  background-color: ${(props) =>
    props.checked === 'no' ? '#f7f7f9' : Color.brandColor01};

  img {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    margin-top: 3px;
  }
`;

const Loader = styled.div`
  width: 80px;
  margin: 20px auto;
`;

const DropdownButton = (props) => {
  if (props.selected === '' || props.selected === null) {
    return (
      <Dropdown
        onClick={props.onClick}
        id={props.id}
        className="dropdown-is-not-selected"
      >
        {props.children}
        <i className="fa fa-caret-down" />
      </Dropdown>
    );
  } else if (props.active) {
    return (
      <DropdownActive
        onClick={props.onClick}
        id={props.id}
        className="dropdown-is-active"
      >
        {props.selected}
      </DropdownActive>
    );
  }
  return (
    <DropdownSelected
      onClick={props.onClick}
      id={props.id}
      className="dropdown-is-selected"
    >
      <FloatButton onClick={props.onReset}>
        <i className="fa fa-remove" />
      </FloatButton>
      <FloatTextEllipsis>{props.selected}</FloatTextEllipsis>
    </DropdownSelected>
  );
};

DropdownButton.propTypes = {
  selected: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const RadioButton = (props) => (
  <Button onClick={props.onClick} id={props.id}>
    {props.checked && (
      <ActiveRadioOption className="radio-is-selected">
        <RadioOptionDot />
      </ActiveRadioOption>
    )}
    {!props.checked && <RadioOption className="radio-is-not-selected" />}
    {props.children}
  </Button>
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const CheckButton = (props) => {
  const img =
    props.checked === 'no'
      ? '/blank.svg'
      : props.checked === 'partial'
      ? '/partialchecked.svg'
      : '/checkmark2.svg';
  const action = !props.disabled ? props.onClick : () => {};
  return (
    <div onClick={action} id={props.id} data-test-id={props.dataTestId}>
      <CheckboxWrapper checked={props.checked}>
        <img src={img} />
      </CheckboxWrapper>
    </div>
  );
};

CheckButton.propTypes = {
  checked: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};

const GiphyBox = (props) => {
  const img = props.gif.startsWith('/') ? props.gif : `/${props.gif}`;
  return (
    <GiphyBoxContainer>
      <GifWrapper>
        <GifWrapperInner>
          <Gifje src={img} />
        </GifWrapperInner>
      </GifWrapper>
      <GifjeHeader>{props.title}</GifjeHeader>
      <GifjeParagraph>{props.text}</GifjeParagraph>
      {!!props.button && (
        <Footer>
          <PrimaryButton onClick={props.onClick}>{props.button}</PrimaryButton>
          {!!props.secondaryButton && (
            <OptionalButton onClick={props.onSecondaryClick}>
              {props.secondaryButton}
            </OptionalButton>
          )}
        </Footer>
      )}
    </GiphyBoxContainer>
  );
};

const MoreInfo = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-left: 8px;
  padding-bottom: 2px;
  border-radius: 16px;
  color: ${Color.brandColor01};
  background-color: rgba(77, 182, 172, 0.1);
  font-family: MuseoSansRounded700;
  font-size: 20px;
  text-align: center;
`;

const Info = (props) => <MoreInfo onClick={props.onClick}>?</MoreInfo>;

const ErrorWrapper = styled.div`
  display: inline-block;
  padding: 16px;
  position: relative;
  border-radius: 10px;
  background-color: ${Color.errorColor};
  font-family: MuseoSansRounded;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${Color.white};
`;

const ErrorText = styled.div`
  z-index: 99;
`;

const ErrorArrow = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: -6px;
  left: 24px;
  background-color: #ed5565;
  transform: rotate(-45deg);
  z-index: 0;
`;

const ErrorBubble = (props) => (
  <ErrorWrapper>
    <ErrorText>{props.text}</ErrorText>
    <ErrorArrow />
  </ErrorWrapper>
);

const BigErrorWrapper = styled.div`
  position: relative;
  font-family: MuseoSansRounded700;
  color: ${Color.white};
  display: inline-block;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: ${Color.errorColor};
`;

const BigErrorText = styled.div`
  font-size: 14px;
  z-index: 99;
`;

const BigErrorArrow = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: -6px;
  left: 49%;
  background-color: ${Color.errorColor};
  transform: rotate(-45deg);
  z-index: 0;
`;

const BigErrorBubble = (props) => (
  <BigErrorWrapper>
    <BigErrorText>{props.text}</BigErrorText>
    <BigErrorArrow />
  </BigErrorWrapper>
);

const BackButtonWrapper = styled(SecondaryButton)`
  border-radius: 8px;
`;

const BackButton = (props) => (
  <BackButtonWrapper onClick={props.onClick}>
    <BackArrow src="/back.svg" />
    {props.text}
  </BackButtonWrapper>
);

const BackArrowWrapper = styled.img`
  margin: 2px;
`;

const BackArrow = (props) => (
  <BackArrowWrapper src="/back.svg" onClick={props.onClick} />
);

const InputBoxWrapper = styled(Panel)`
  min-height: 88px;
  padding: 24px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: ${Color.white};
`;

const InputBoxLabelWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 170px;
  margin-right: 30px;
`;

const InputBoxLabel = styled(Header3)`
  margin-bottom: 4px;
`;

const InputBoxCaption = styled(CaptionSmall)`
  white-space: nowrap;
  margin-top: 8px;
`;

const InputBoxField = styled.input`
  width: 700px;
  height: 22px;
  line-height: 22px;
  margin-top: 9px;
  font-size: 18px;
  font-family: MuseoSansRounded700;
  color: ${Color.brandColor01};
  outline: none;
  border: none;

  ::placeholder {
    color: ${Color.gray03};
  }
`;

const InputBox = (props) => (
  <InputBoxWrapper>
    <InputBoxLabelWrapper>
      <InputBoxLabel>{props.label}</InputBoxLabel>
      <InputBoxCaption>{props.caption}</InputBoxCaption>
    </InputBoxLabelWrapper>
    <InputBoxField
      id="fileDescription"
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      value={props.value}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onChange={props.onChange}
    />
  </InputBoxWrapper>
);

const LightBox = styled.div`
  position: absolute;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 492px;
  height: 439px;
  transform: translate(0%, -50%);
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 30px 40px 10px rgba(0, 0, 0, 0.15);
  font-family: MuseoSansRounded700;

  @media ${(props) => props.theme.device.tablet} {
    position: relative;
    top: 0;
    left: 0;
    margin: 60px auto 0px auto;
    transform: initial;
  }

  @media ${(props) => props.theme.device.mobile} {
    position: relative;
    width: auto;
    height: 456px;
    top: 0;
    left: 0;
    transform: none;
    margin: 0 16px 20px 16px;
    padding: 40px 16px;
  }
`;

const LightBoxFieldLabel = styled(Header4)`
  margin: 32px 40px 10px 8px;
  height: 19px;
  object-fit: contain;
  font-size: 16px;
  color: #57565b;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`;

const LightBoxErrorMessageBanner = styled.div`
  position: absolute;
  z-index: 2;
  top: 72px;
  left: 40px;
  right: 40px;
  min-height: 47px;
  line-height: 17px;
  padding: 15px;
  border-radius: 10px;
  background-color: ${Color.errorColor};
  font-family: MuseoSans300;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    top: 40px;
    width: auto;
    left: 16px;
    right: 16px;
  }
`;

const LightBoxBackArrow = styled.img`
  margin-bottom: 26px;
  font-size: 24px;
  height: 20px;
  width: 27px;
  color: ${(props) => props.theme.palette.green};
  cursor: pointer;

  &.hidden {
    visibility: hidden;
  }
`;

const BreadCrumbWrapper = styled.div`
  display: inline-block;
  height: 48px;
  padding: 14px 24px;
  text-align: center;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.gray01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  background-color: ${Color.white};
  border: 2px solid ${Color.gray05};
  border-radius: 8px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

  > img {
    margin-right: 8px;
    vertical-align: text-top;
  }

  > a + img {
    transform: rotate(180deg);
    margin-left: 16px;
    margin-right: 16px;
    vertical-align: text-top;
  }
`;

const BreadCrumbItem = styled.a`
  display: inline-block;
  max-width: 300px;
  color: ${Color.brandColor01};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: text-top;
`;

const BreadCrumbCurrent = styled.a`
  display: inline-block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: text-top;
`;

const BreadCrumb = (props) => (
  <BreadCrumbWrapper onClick={props.onClick}>
    {!props.start && !props.previous && <img src="/back.svg" alt="" />}
    {!!props.start && (
      <BreadCrumbItem onClick={props.onStart}>{props.start}</BreadCrumbItem>
    )}
    {!!props.start && <img src="/chevron.svg" alt="" />}
    {!!props.previous && (
      <BreadCrumbItem onClick={props.onPrevious}>
        {props.previous}
      </BreadCrumbItem>
    )}
    {!!props.previous && <img src="/chevron.svg" alt="" />}
    <BreadCrumbCurrent>{props.text}</BreadCrumbCurrent>
  </BreadCrumbWrapper>
);

const InnerContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 1056px;
  margin: 0 auto;
  margin-bottom: ${(props) => (props.highMargin ? '80px' : '0px')};
  padding-bottom: 44px;
  text-align: left;

  @media ${(props) => props.theme.device.tablet} {
    padding: 44px 32px 32px 32px;
    margin: 0px;
    width: auto;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    padding-top: 0px;
    background-color: rgba 142 142 147, 0.12;
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  min-height: 100%;
  font-size: 18px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export {
  DropdownButton,
  RadioButton,
  CheckButton,
  GiphyBox,
  Info,
  ErrorBubble,
  BigErrorBubble,
  BackArrow,
  BackButton,
  InputBox,
  LightBox,
  LightBoxBackArrow,
  LightBoxFieldLabel,
  LightBoxErrorMessageBanner,
  BreadCrumb,
  InnerContainer,
  DashboardWrapper,
  Container,
  Loader,
};

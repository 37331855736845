export const selectIsSendingBulkUpload = (state) =>
  state.get('bulkDetailContainer').get('isSendingBulkUpload');
export const selectSendBulkUploadErrorStatus = (state) =>
  state.get('bulkDetailContainer').get('sendBulkUploadErrorStatus');
export const selectSendBulkUploadErrorMessage = (state) =>
  state.get('bulkDetailContainer').get('sendBulkUploadErrorMessage');
export const selectSendBulkUploadErrorValidationDetails = (state) =>
  state.get('bulkDetailContainer').get('sendBulkUploadErrorValidationDetails');
export const selectPaymentRequests = (state) =>
  state.get('bulkDetailContainer').get('paymentRequests').toJS();
export const selectPaymentRequestsIsLoading = (state) =>
  state.get('bulkDetailContainer').get('paymentRequestsIsLoading');
export const selectPaymentRequestsErrorMessage = (state) =>
  state.get('bulkDetailContainer').get('paymentRequestsErrorMessage');
export const selectBulkFile = (state) =>
  state.get('bulkDetailContainer').get('bulkFile').toJS();
export const selectIsLoadingBulkFile = (state) =>
  state.get('bulkDetailContainer').get('bulkFileIsLoading');

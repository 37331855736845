import React, { useState, KeyboardEvent } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';

import { Input, Wrapper } from '../styledComponents';
import { InfoScreen } from '../InfoScreen/index';
import { UboInfoScreen } from '../UBO/UboInfoScreen';
import { Address, PartialAddress } from './constants';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

const fields = {
  street: 'street',
  houseNumber: 'houseNumber',
  postalCode: 'postalCode',
  city: 'city',
};

interface AddressProps {
  isForeign?: boolean;
  nextStep?: () => void;
  addressDetails: Address;
  setPartialAddress?: (value: PartialAddress) => void;
  setAddress?: (value: Address) => void;
  ubo?: boolean;
  prevStep?: () => void;
  uboFullName?: string;
}

function InputAddress({
  isForeign,
  addressDetails,
  setPartialAddress,
  setAddress,
  nextStep,
  ubo,
  prevStep,
  uboFullName,
}: AddressProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [postalCode, setPostalCode] = useState(
    addressDetails?.postalCode || '',
  );
  const [houseNumber, setHouseNumber] = useState(
    addressDetails?.houseNumber || '',
  );
  const [street, setStreet] = useState(addressDetails?.street || '');
  const [city, setCity] = useState(addressDetails?.city || '');
  const [info, setInfo] = useState(false);
  useOnboardingPageView('input-address');

  const showInfo = (): void => setInfo(!info);

  const validDutchPostalCode = (): boolean =>
    postalCode?.match(/[0-9]{4}[a-zA-Z]{2}/) != null;

  const invalidDutchForm = (): boolean =>
    !postalCode || !houseNumber || !validDutchPostalCode();

  const invalidForeignForm = (): boolean =>
    street.length < 2 ||
    !houseNumber ||
    postalCode.length < 2 ||
    city.length < 2;

  const invalidForm = isForeign ? invalidForeignForm() : invalidDutchForm();

  const validateInput = (e: KeyboardEvent<HTMLInputElement>): void => {
    const fieldName = e.currentTarget.name;
    const c = e.key;
    const ctrlV = (e.ctrlKey || e.metaKey) && e.key === 'v';
    const ctrlC = (e.ctrlKey || e.metaKey) && e.key === 'c';

    if (
      e.key === 'ArrowLeft' ||
      e.key === 'RightArrow' ||
      e.key === 'Backspace' ||
      e.key === 'Tab' ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    if (
      !isForeign &&
      fields.postalCode === fieldName &&
      c.match(/[^A-Za-z0-9]+/)
    ) {
      e.preventDefault();
    }

    if (!isForeign && fields.houseNumber === fieldName && c.match(/[^0-9]+/)) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && !invalidForm) {
      next();
    }
  };

  const next = (): void => {
    if (isForeign && setAddress) {
      setAddress({
        street,
        houseNumber,
        postalCode,
        city,
      });
    } else if (setPartialAddress && nextStep) {
      setPartialAddress({
        postalCode,
        houseNumber,
      });

      nextStep();
    }
  };

  return (
    <>
      <Wrapper>
        {ubo && (
          <>
            <NavigationHeader
              clickLeft={prevStep as () => void}
              clickRight={showInfo}
            />
            <BlokkieTypography
              variant="pl700"
              style={{
                color: blokkieTheme.colors.grey.dark,
              }}
            >
              {t('onboarding.address.uboTitle', { uboFullName })}
            </BlokkieTypography>
            <Title>{t('onboarding.address.title')}</Title>
          </>
        )}
        {!ubo && (
          <>
            <NavigationHeader clickRight={showInfo} />
            <Title>{t('onboarding.address.title')}</Title>
          </>
        )}
        {!isForeign && (
          <>
            <BlokkieTypography
              variant="pl500"
              style={{
                color: blokkieTheme.colors.grey.default,
                marginBottom: blokkieTheme.spacing(2),
              }}
            >
              {t('onboarding.address.subtitle')}
            </BlokkieTypography>
            <Input
              className={classes.smallInput}
              name={fields.postalCode}
              value={postalCode}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPostalCode(e.currentTarget.value.toUpperCase())
              }
              placeholder={t('onboarding.address.input.postalCode')}
              autoComplete="off"
              autoCorrect="off"
              autoFocus
              maxLength={6}
              data-test-id="Onboarding-Address-PostalCode"
            />
            <Input
              className={classes.smallInput}
              name={fields.houseNumber}
              type="numeric"
              value={houseNumber}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setHouseNumber(e.currentTarget.value)
              }
              placeholder={t('onboarding.address.input.houseNumber')}
              autoComplete="off"
              autoCorrect="off"
              maxLength={6}
              data-test-id="Onboarding-Address-HouseNumber"
            />
          </>
        )}
        {isForeign && (
          <>
            <Input
              className={classes.smallInput}
              name={fields.street}
              value={street}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setStreet(e.currentTarget.value)
              }
              placeholder={t('onboarding.address.input.street')}
              autoComplete="off"
              autoCorrect="off"
              autoFocus
              data-test-id="Onboarding-UboAddress-Street"
            />
            <Input
              className={classes.smallInput}
              name={fields.houseNumber}
              value={houseNumber}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setHouseNumber(e.currentTarget.value)
              }
              placeholder={t('onboarding.address.input.houseNumber')}
              autoComplete="off"
              autoCorrect="off"
              data-test-id="Onboarding-UboAddress-HouseNumber"
            />
            <Input
              className={classes.smallInput}
              name={fields.postalCode}
              value={postalCode}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPostalCode(e.currentTarget.value.toUpperCase())
              }
              placeholder={t('onboarding.address.input.postalCode')}
              autoComplete="off"
              autoCorrect="off"
              data-test-id="Onboarding-UboAddress-PostalCode"
            />
            <Input
              className={classes.smallInput}
              name={fields.city}
              value={city}
              onKeyDown={validateInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setCity(e.currentTarget.value)
              }
              placeholder={t('onboarding.address.input.city')}
              autoComplete="off"
              autoCorrect="off"
              data-test-id="Onboarding-UboAddress-City"
            />
          </>
        )}
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            disabled={invalidForm}
            data-test-id="Onboarding-Address-NextButton"
            onClick={next}
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info && !ubo}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={InputAddress.name}
      >
        <BlokkieTypography
          variant="pl500"
          style={{ color: blokkieTheme.colors.grey.veryDark }}
        >
          {t('onboarding.address.infoBody')}
        </BlokkieTypography>
      </InfoScreen>
      <UboInfoScreen
        show={info && !!ubo}
        showInfo={showInfo}
        fromPage={`${InputAddress.name}_ubo`}
      />
    </>
  );
}

export default InputAddress;

const useStyles = createUseStyles({
  smallInput: {
    height: 'initial',
  },
});

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { BlokkieTypography } from '@tikkie/blokkie';
import { Color } from '../styleguide';

interface DatePickerProps {
  className?: string;
  date: moment.Moment;
  focus: boolean;
  buttonText?: string;
  setDate: (date: moment.Moment) => void;
  checkRange: (date: moment.Moment) => boolean;
}

export function DatePicker({
  className,
  date,
  focus,
  setDate,
  checkRange,
  buttonText = 'portal.components.datepicker.apply',
}: DatePickerProps): React.JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(date || moment());
  const rawDate = useMemo(() => date.toDate(), [date]);
  const sameDate = useMemo(
    () => rawDate.toDateString() === selectedDate.toDate().toDateString(),
    [rawDate, selectedDate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const text = useMemo(() => t(buttonText), [buttonText]);

  useEffect(() => date && setSelectedDate(date), [date]);

  return (
    <div>
      {focus && (
        <div className={[classes.calender, className].join(' ')}>
          <i className={classes.triangle} />
          <div className={classes.overlay}>
            <SingleDatePicker
              focused
              id="date-picker"
              date={selectedDate}
              onDateChange={(pickedDate) =>
                pickedDate && setSelectedDate(pickedDate)
              }
              onFocusChange={() => {}} // Build in focus doesn't work (with apply button), so we disable it
              isOutsideRange={(day) => !checkRange(day)}
              numberOfMonths={1}
              initialVisibleMonth={moment}
              navNext={
                <img
                  src="/chevron.svg"
                  alt="chevron"
                  style={{
                    width: '12px',
                    position: 'absolute',
                    right: '3em',
                    top: '24px',
                    rotate: '180deg',
                  }}
                />
              }
              navPrev={
                <img
                  src="/chevron.svg"
                  alt="chevron"
                  style={{
                    width: '12px',
                    position: 'absolute',
                    left: '3em',
                    top: '24px',
                  }}
                />
              }
              hideKeyboardShortcutsPanel
            />
            <button
              id="datepicker-apply"
              style={{
                background: Color.brandColor01,
                color: Color.white,
              }}
              disabled={sameDate}
              type="button"
              className={classes.button}
              onClick={() => setDate(selectedDate)}
            >
              <BlokkieTypography variant="pl700">{text}</BlokkieTypography>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const useStyles = createUseStyles({
  calender: {
    fontFamily: 'MuseoSansRounded700',
    borderRadius: '10px',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '1px 2px 20px rgba(0, 0, 0, 0.15)',

    '& .DateInput': {
      display: 'none',
    },

    '& .SingleDatePicker_picker': {
      position: 'relative',
      top: 'unset !important',
      left: 'unset !important',
    },

    '& .SingleDatePickerInput': {
      border: 'none',
    },

    '& .DayPicker': {
      boxShadow: 'none',
    },

    '& .CalendarDay__default': {
      borderRadius: '100%',
      border: 'none',

      '&:hover': {
        color: Color.gray01,
        background: '#ECF9F8',
      },
    },

    '& .CalendarDay__selected': {
      background: `${Color.brandColor01} !important`,
      color: `${Color.white} !important`,
    },

    '& .CalendarDay__blocked_out_of_range': {
      background: `transparent !important`,
      color: `${Color.gray03} !important`,
    },

    '& .CalendarMonth_caption': {
      color: `${Color.brandColor01} !important`,
      paddingTop: '23px',

      '& strong': {
        fontWeight: '500',
      },
    },

    '& .DayPickerNavigation_button img': {
      boxSizing: 'content-box',
      padding: '2px 6px',
      marginTop: '-2px',
    },
  },

  overlay: {
    background: Color.white,
    borderRadius: '10px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    height: 'fit-content',
    overflow: 'hidden',
  },

  triangle: {
    zIndex: -1,
    background: Color.white,
    transform: 'translateY(-50%)',
    rotate: '45deg',
    position: 'absolute',
    width: '30px',
    height: '30px',
    top: 0,
    boxShadow: '1px 2px 20px rgba(0, 0, 0, 0.15)',
  },

  button: {
    color: Color.gray01,
    height: '48px',
    borderRadius: '50px',
    background: Color.white,
    padding: '0 24px',
    display: 'grid',
    columnGap: '10px',
    fontSize: '16px',
    gridAutoFlow: 'column',
    width: 'fit-content',
    alignItems: 'center',
    cursor: 'pointer',
    border: '2px solid #F4F4F4',

    '&:disabled': {
      cursor: 'not-allowed',
      background: `${Color.gray05} !important`,
      color: `${Color.gray03} !important`,
    },
  },
});

/**
 *
 * TransactionList
 *
 */

import '!file-loader?name=[name].[ext]!../../../images/gifs/oops.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/no-results.gif';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';

import moment from 'moment';
import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

import TransactionBundle from '../TransactionBundle';
import TransactionsFilters from '../TransactionsFilters';

import messages from './messages';
import { Color, Header1, Panel } from '../../styleguide';
import { GiphyBox } from '../../components';

import animationData from '../../../images/animations/green-dots.json';
import ShortDisturbanceMessage from '../../SDM';
import { customDateText } from '../TransactionsFilters/TransactionDateFilter/calendarUtils';
import { toCurrency } from '../../../utils';

const TransactionListWrapper = styled.div``;

const Header = styled(Header1)`
  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-top: 30px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 0px 16px 16px 16px;
  }
`;

const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 116px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 276px;
`;

const TotalPeriodAmountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TotalPeriodAmount = styled.div`
  background-color: ${Color.white};
  padding: 12px 20px;
  border-radius: 8px;
`;

const TotalPeriod = styled.span`
  color: ${Color.gray03};
  margin-right: 28px;
  font-size: 16px;
`;

const TotalAmount = styled.span`
  color: ${Color.brandColor01};
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const FiltersWrapper = styled.div`
  margin-top: 16px;

  @media ${(props) => props.theme.device.mobile} {
    margin: 0px;
    padding: 16px 0 16px 0;
    margin-bottom: -16px;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    &:-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
      background: transparent;
    }
  }
`;

const options = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

/* eslint-disable react/prefer-stateless-function */
class TransactionList extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const oopsTitle = intl.formatMessage({
      id: 'app.components.TransactionList.oopsTitle',
    });

    const oopsText = intl.formatMessage({
      id: 'app.components.TransactionList.oopsText',
    });

    const oopsButton = intl.formatMessage({
      id: 'app.components.TransactionList.oopsButton',
    });

    const firstUseTitle = intl.formatMessage({
      id: 'app.components.TransactionList.firstUseTitle',
    });

    const firstUseText = intl.formatMessage({
      id: 'app.components.TransactionList.firstUseText',
    });

    const noResultsTitle = intl.formatMessage({
      id: 'app.components.TransactionList.noResultsTitle',
    });

    const noResultsText = intl.formatMessage({
      id: 'app.components.TransactionList.noResultsText',
    });

    const noResultsButton = intl.formatMessage({
      id: 'app.components.TransactionList.noResultsButton',
    });

    const dateFilterOptions = new Map(
      Object.entries({
        today: intl.formatMessage({
          id: 'app.components.TransactionDateFilter.today',
        }),
        thisWeek: intl.formatMessage({
          id: 'app.components.TransactionDateFilter.thisWeek',
        }),
        thisMonth: intl.formatMessage({
          id: 'app.components.TransactionDateFilter.thisMonth',
        }),
        thisYear: intl.formatMessage({
          id: 'app.components.TransactionDateFilter.thisYear',
        }),
        customDate: '',
      }),
    );

    this.state = {
      oopsTitle,
      oopsText,
      oopsButton,
      firstUseTitle,
      firstUseText,
      noResultsTitle,
      noResultsText,
      noResultsButton,
      dateFilterOptions,
      selectedDateFilterOption: '',
    };
  }

  loadTransactions = (
    page = this.props.currentPage,
    fromDate = this.props.filterFromDate,
    toDate = this.props.filterToDate,
    type = this.props.filterType,
    search = this.props.filterSearch,
  ) => {
    this.props.loadTransactions(page, fromDate, toDate, type, search);
  };

  UNSAFE_componentWillMount() {
    this.loadTransactions();
  }

  onChangePaging = (currentPage) => {
    this.loadTransactions(currentPage);
  };

  showPaging = () => this.props.transactions.totalElements > 20;

  isFilterApplied = () =>
    !(
      !this.props.filterToDate &&
      !this.props.filterFromDate &&
      this.props.filterType === '' &&
      this.props.filterSearch === ''
    );

  isOnlyDateFilterApplied = () =>
    this.props.filterToDate &&
    this.props.filterFromDate &&
    this.props.filterType === '' &&
    this.props.filterSearch === '';

  dateFilteredTotalDescription = (selectedDateFilterOption) => {
    const selectedPeriod = () => {
      switch (selectedDateFilterOption) {
        case 'today':
          return moment(this.props.filterFromDate).format('D MMM');
        case 'thisWeek':
          return `${moment(this.props.filterFromDate).format(
            'D MMM',
          )} - ${moment(this.props.filterToDate).format('D MMM')}`;
        case 'thisMonth':
          return `${moment(this.props.filterFromDate).format(
            'D MMM',
          )} - ${moment(this.props.filterToDate).format('D MMM')}`;
        case 'thisYear':
          return moment(this.props.filterFromDate).format('YYYY');
        default:
          return customDateText(
            moment(this.props.filterFromDate),
            moment(this.props.filterToDate),
          );
      }
    };

    if (selectedDateFilterOption) {
      return `${this.state.dateFilterOptions
        .get(selectedDateFilterOption)
        .toLowerCase()} (${selectedPeriod()})`;
    }
    return `(${selectedPeriod()})`;
  };

  updateFilterOption = (selectedDate) => {
    this.setState({ selectedDateFilterOption: selectedDate });
  };

  resetFilter = () => {
    this.loadTransactions(1, 0, 0, '', '');
  };

  render() {
    const { selectedDateFilterOption } = this.state;

    return (
      <TransactionListWrapper>
        <ShortDisturbanceMessage context="business-portal-transactions" />
        <Header>
          <FormattedMessage {...messages.header} />
        </Header>
        <FiltersWrapper>
          <TransactionsFilters
            loadTransactions={this.props.loadTransactions}
            currentPage={this.props.currentPage}
            filterType={this.props.filterType}
            filterSearch={this.props.filterSearch}
            filterFromDate={this.props.filterFromDate}
            filterToDate={this.props.filterToDate}
            transactionListCallback={this.updateFilterOption}
            dataTestId="transaction-filters"
          />
        </FiltersWrapper>
        <Content>
          {this.props.transactionsIsLoading && (
            // Transactions are loading
            <LoaderWrapper>
              <AnimationWrapper>
                <ReactBodymovin options={options} />
              </AnimationWrapper>
            </LoaderWrapper>
          )}
          {!this.props.transactionsIsLoading &&
            this.props.transactions.totalElements === 0 && (
              // No transactions to be shown
              <GifjeContainer dataTestId="gifje-container">
                <GifjeWrapper>
                  {!this.isFilterApplied() && (
                    <GiphyBox
                      gif="no-results.gif"
                      title={this.state.firstUseTitle}
                      text={this.state.firstUseText}
                    />
                  )}
                  {this.isFilterApplied() && (
                    <GiphyBox
                      gif="oops.gif"
                      onClick={() => {
                        this.resetFilter();
                      }}
                      title={this.state.noResultsTitle}
                      text={this.state.noResultsText}
                      button={this.state.noResultsButton}
                    />
                  )}
                </GifjeWrapper>
              </GifjeContainer>
            )}
          {!!(
            !this.props.transactionsIsLoading &&
            this.props.transactions.totalElements !== 0 &&
            this.isOnlyDateFilterApplied()
          ) && (
            // Selected period total is displayed
            <TotalPeriodAmountWrapper dataTestId="total-period-amount">
              <TotalPeriodAmount>
                <TotalPeriod>
                  Totaal{' '}
                  {this.dateFilteredTotalDescription(selectedDateFilterOption)}:{' '}
                </TotalPeriod>
                <TotalAmount>
                  {toCurrency(this.props.transactions.totalAmountInCents, 2)}
                </TotalAmount>
              </TotalPeriodAmount>
            </TotalPeriodAmountWrapper>
          )}
          {!this.props.transactionsIsLoading &&
            this.props.transactions.totalElements !== 0 &&
            this.props.transactions.bundles?.map((bundle) => (
              // Transactionbundles are displayed
              <TransactionBundle
                showPayerDetails={this.props.user.showPayerDetailsPortal}
                key={bundle.id}
                bundle={bundle}
                downloadCamt={this.props.downloadCamt}
                showTransactionDetail={this.props.showTransactionDetail}
                dataTestId="transaction-bundle"
              />
            ))}
          {!!this.props.transactionsErrorMessage && (
            // Errormessage
            <GifjeContainer>
              <GifjeWrapper>
                <GiphyBox
                  gif="oops.gif"
                  onClick={() => {
                    this.props.loadTransactions(1);
                  }}
                  title={this.state.oopsTitle}
                  text={this.state.oopsText}
                  button={this.state.oopsButton}
                />
              </GifjeWrapper>
            </GifjeContainer>
          )}
          {this.showPaging() && (
            <PagingWrapper>
              <Pagination
                onChange={this.onChangePaging}
                defaultCurrent={1}
                current={this.props.currentPage}
                pageSize={20}
                simple={isMobileOnly}
                showLessItems={isTablet}
                total={this.props.transactions.totalElements}
                prevIcon={<NavPrev src="/back.svg" />}
                nextIcon={<NavNext src="/back.svg" />}
                showTitle={false}
              />
            </PagingWrapper>
          )}
        </Content>
      </TransactionListWrapper>
    );
  }
}

TransactionList.propTypes = {
  transactions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  transactionsIsLoading: PropTypes.bool.isRequired,
  transactionsErrorMessage: PropTypes.string,
  loadTransactions: PropTypes.func.isRequired,
  downloadCamt: PropTypes.func.isRequired,
  showTransactionDetail: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  filterFromDate: PropTypes.number,
  filterToDate: PropTypes.number,
  filterType: PropTypes.string.isRequired,
  filterSearch: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(TransactionList);

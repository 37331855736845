import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Container } from 'amber';
import styled from 'styled-components';
import { Password, PrimaryButton } from '../../styleguide';
import { LightBoxFieldLabel } from '../../components';
import { ModalWrapper } from '../../../global-components';
import theme from '../../../theme';

export const ProceedButton = styled(PrimaryButton)`
  margin-top: 10px;
  width: 120px;
`;

const ResponsiveLightBoxFieldLabel = styled(LightBoxFieldLabel)`
  @media ${() => theme.device.phone} {
    margin-bottom: 38px;
  }
`;

export function EnterPasswordForConfirmation({ enterPassword }) {
  const [password, setPassword] = useState('');

  const handleOnKeyDown = (key) => {
    if (key.key === 'Enter') {
      enterPassword(password);
    }
  };

  return (
    <Container>
      <ModalWrapper>
        <ResponsiveLightBoxFieldLabel>
          We hebben je wachtwoord nodig om de wijziging van je e-mailadres te
          bevestigen.
        </ResponsiveLightBoxFieldLabel>
        <Password
          onChange={(event) => setPassword(event.target.value)}
          onKeyDown={handleOnKeyDown}
          placeholder="Wachtwoord..."
          autoFocus
          type="password"
          value={password}
        />
        <ProceedButton
          onClick={() => enterPassword(password)}
          disabled={isEmpty(password)}
        >
          Verder
        </ProceedButton>
      </ModalWrapper>
    </Container>
  );
}

EnterPasswordForConfirmation.propTypes = {
  enterPassword: PropTypes.func.isRequired,
};

import i18n from 'i18next';

export const navigationTabs = {
  landing: 'landing',
  thankyou: 'thankyou',
  expiry: 'expiry',
  maxpayments: 'maxpayments',
};

export const isLandingTab = (tab) => tab === navigationTabs.landing;
export const isThankyouTab = (tab) => tab === navigationTabs.thankyou;
export const isExpiryTab = (tab) => tab === navigationTabs.expiry;
export const isMaxPaymentsTab = (tab) => tab === navigationTabs.maxpayments;

export const TITLE = 'title';
export const LINE_1 = 'line1';
export const LINE_2 = 'line2';
export const BTN_TEXT = 'btnText';
export const BTN_LINK = 'btnLink';
export const WA_TEXT = 'whatsAppText';
export const AUTO_LINK = 'autoLink';

export const PENDING_LOGO = 'pendingLogo';
export const PENDING_THANK_YOU_IMG = 'pendingThankYouImage';
export const PENDING_EXPIRY_IMG = 'pendingExpiryImage';
export const PENDING_MAY_PAYMENT_IMG = 'pendingMaxPaymentsImage';

export const linkTypes = {
  noButton: {
    label: i18n.t(
      'portal.settings.pages.thankyou.nextAction.options.noButton.title',
    ),
    img: '/geen-knop.png',
    initialFields: [TITLE, LINE_1],
    conditionalFields: [],
  },
  buttonLink: {
    label: i18n.t(
      'portal.settings.pages.thankyou.nextAction.options.buttonWithLink.title',
    ),
    img: '/knop-met-link.png',
    initialFields: [TITLE, LINE_1],
    conditionalFields: [LINE_2, BTN_TEXT, BTN_LINK],
  },
  tikLink: {
    label: i18n.t(
      'portal.settings.pages.thankyou.nextAction.options.tikmDoorLink.title',
    ),
    img: '/tik-door-link.png',
    initialFields: [TITLE, LINE_1],
    conditionalFields: [LINE_2, BTN_TEXT, WA_TEXT],
  },
  autoLink: {
    label: i18n.t(
      'portal.settings.pages.thankyou.nextAction.options.autoLink.title',
    ),
    img: '/auto-door-link.png',
    initialFields: [TITLE],
    conditionalFields: [AUTO_LINK],
  },
};

export const isNoButton = (linkType) =>
  linkType.label === linkTypes.noButton.label;
export const isButtonLink = (linkType) =>
  linkType.label === linkTypes.buttonLink.label;
export const isTikLink = (linkType) =>
  linkType.label === linkTypes.tikLink.label;
export const isAutoLink = (linkType) =>
  linkType.label === linkTypes.autoLink.label;

/* eslint-disable */
export const isValidUrl = (value) =>
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?/i.test(
    value,
  );

export const isValidEmail = (value) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

export const isValidPhone = (value) =>
  /^(((\+31|0031|31|0)6){1}[1-9]{1}[0-9]{7})$/.test(value);

/**
 *
 * Api New Token
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/money.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/create-request.svg';
import '!file-loader?name=[name].[ext]!../../../images/refund.svg';
import '!file-loader?name=[name].[ext]!../../../images/transaction-notification.svg';
import '!file-loader?name=[name].[ext]!../../../images/cashback-api.png';
import styled from 'styled-components';
import valid from 's-valid';
import {
  PageTitle,
  Container,
  CaptionSmall,
  Paragraph,
  InputTile,
  Toggle,
  Header4,
} from 'amber';
import messages from './messages';
import {
  ContainerBox,
  ContainerRowExplanation,
  ImageWrapperExplanation,
  ImageExplanation,
  ExplanationText,
  Header2Card,
  Header2Manual,
  ErrorPageWithContact,
  BackButton,
  AddButton,
} from '../styledComponents';
import { Color, FontSize, Radii, Space } from '../../styleguide';

const Tile = styled.div`
  width: 100%;
  margin: 0 0 ${Space.s8} 0;
  display: flex;
  box-sizing: border-box;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  background-color: ${Color.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  flex: 0 0 100%;
  border-radius: ${Radii.s8};
  padding: ${Space.s24};
  color: ${Color.gray01};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded;
`;

const ToggleExplanation = styled.div`
  width: 25%;
  display: inline-block;
  height: 100%;
`;

const Toggles = styled.div`
  width: 70%;
  display: inline-block;
  height: 100%;
`;
const ToggleDescr = styled.div`
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded;
  margin: 0 0 ${Space.s32} ${Space.s72};
  padding-top: ${Space.s8};
`;

const ToggleCaption = styled(CaptionSmall)`
  margin-top: ${Space.s8} !important;
`;

/* eslint-disable react/prefer-stateless-function */
export class ApiNewToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentRequestRight: false,
      refundRight: false,
      transactionDetailsRight: false,
      cashbackRight: false,
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.createApiTokenSuccess !== prevProps.createApiTokenSuccess &&
      this.props.creatingApiToken === false
    ) {
      this.props.goToApiOverview();
    }
  }

  handleFieldChange = (fieldId, value) => {
    this.setState({ [fieldId]: value });
  };

  showCashbackOption = () => this.props.cashbackAllowed;

  createApiToken = () => {
    const rights = {
      paymentRequestRight: this.state.paymentRequestRight,
      refundRight: this.state.refundRight,
      transactionDetailsRight: this.state.transactionDetailsRight,
      cashbackRight: this.state.cashbackRight,
    };
    this.props.createApiToken(this.state.name, rights);
  };

  setRights = (right) => {
    switch (right) {
      case 'PaymentRequestRight': {
        this.setState((prevState) => ({
          paymentRequestRight: !prevState.paymentRequestRight,
        }));
        break;
      }
      case 'RefundRight': {
        this.setState((prevState) => ({ refundRight: !prevState.refundRight }));
        break;
      }
      case 'TransactionDetailsRight': {
        this.setState((prevState) => ({
          transactionDetailsRight: !prevState.transactionDetailsRight,
        }));
        break;
      }
      case 'CashbackRight': {
        this.setState((prevState) => ({
          cashbackRight: !prevState.cashbackRight,
        }));
        break;
      }
      default:
        break;
    }
  };

  disableButton = () => !this.state.name || this.props.creatingApiToken;

  render() {
    const { goToApiOverview } = this.props;

    if (this.props.createApiTokenError) {
      return ErrorPageWithContact({
        pageTitle: messages.headerNew.defaultMessage,
        onClickBackOverview: goToApiOverview,
        errorType: 'NewToken',
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={goToApiOverview}
          text={messages.terug.defaultMessage}
        />
        <PageTitle>{messages.headerNew.defaultMessage}</PageTitle>
        <Container row mb={24}>
          <InputTile
            title={messages.descriptionTitle.defaultMessage}
            desc={messages.descriptionTextApiToken.defaultMessage}
            placeholder={messages.placeholderApiToken.defaultMessage}
            value={this.state.name}
            maxLength={100}
            onChange={this.handleFieldChange}
            id="name"
          />
        </Container>
        <Container row mb={24}>
          <Tile>
            <ToggleExplanation>
              <Header4>Mogelijkheden</Header4>
              <ToggleCaption>Wat moet de token kunnen?</ToggleCaption>
            </ToggleExplanation>
            <Toggles>
              <Toggle onClick={() => this.setRights('PaymentRequestRight')} />
              <ToggleDescr>Aanmaken betaalverzoekjes</ToggleDescr>
              <Toggle onClick={() => this.setRights('RefundRight')} />
              <ToggleDescr>Terugbetaling betaalde verzoekjes </ToggleDescr>
              <Toggle
                onClick={() => this.setRights('TransactionDetailsRight')}
              />
              <ToggleDescr>
                Notificatie van gebundelde uitbetalingen
              </ToggleDescr>
              {this.showCashbackOption() && (
                <>
                  <Toggle onClick={() => this.setRights('CashbackRight')} />
                  <ToggleDescr>Tikkie Terug (geldterugacties)</ToggleDescr>
                </>
              )}
            </Toggles>
          </Tile>
        </Container>
        <Container row mb={40}>
          <AddButton
            text="Token aanmaken"
            alt="Token aanmaken"
            onClick={this.createApiToken}
            disabled={this.disableButton()}
          />
        </Container>
        <Header2Manual>
          <FormattedMessage {...messages.manual} />
        </Header2Manual>
        <ContainerBox>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle1} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText1} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/create-request.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/refund.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle2} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText2} />
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle3} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText3} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/transaction-notification.svg" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          {this.showCashbackOption() && (
            <ContainerRowExplanation>
              <>
                <ImageWrapperExplanation>
                  <ImageExplanation>
                    <img src="/cashback-api.png" alt="" />
                  </ImageExplanation>
                </ImageWrapperExplanation>
                <ExplanationText>
                  <Header2Card>
                    <FormattedMessage {...messages.manualTitle5} />
                  </Header2Card>
                  <Paragraph>
                    <FormattedMessage {...messages.manualText5} />
                  </Paragraph>
                </ExplanationText>
              </>
              {/* )} */}
            </ContainerRowExplanation>
          )}
        </ContainerBox>
      </Container>
    );
  }
}

ApiNewToken.propTypes = {
  gotoPortalOverview: PropTypes.func,
  gotoSettingsDashboard: PropTypes.func,
  goToApiOverview: PropTypes.func,
  settingsData: PropTypes.object.isRequired,
  apiTokens: PropTypes.array.isRequired,
  loadApiTokens: PropTypes.func.isRequired,
  apiTokensLoading: PropTypes.bool.isRequired,
  apiTokensLoadingSuccess: PropTypes.bool.isRequired,
  createApiToken: PropTypes.func.isRequired,
  createApiTokenSuccess: PropTypes.bool.isRequired,
  createApiTokenError: PropTypes.object,
  creatingApiToken: PropTypes.bool.isRequired,
  cashbackAllowed: PropTypes.bool.isRequired,
};

export default injectIntl(ApiNewToken);

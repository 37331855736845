/*
 * PaymentRequestDetail Messages
 *
 * This contains all the text for the PaymentRequestDetail component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'app.components.PaymentRequestDetail.back',
    defaultMessage: 'Verzoekjes',
  },
  backToBulk: {
    id: 'app.components.PaymentRequestDetail.backToBulk',
    defaultMessage: 'Bulk upload',
  },
  header: {
    id: 'app.components.PaymentRequestDetail.header',
    defaultMessage: 'Tikkie details',
  },
  previewHeader: {
    id: 'app.components.PaymentRequestDetail.previewHeader',
    defaultMessage: 'Tikkie details (voorbeeld)',
  },
  labelTitle: {
    id: 'app.components.PaymentRequestDetail.labelTitle',
    defaultMessage: 'Omschrijving',
  },
  labelInvoiceNumber: {
    id: 'app.components.PaymentRequestDetail.labelInvoiceNumber',
    defaultMessage: 'Kenmerk',
  },
  labelPhone: {
    id: 'app.components.PaymentRequestDetail.labelPhone',
    defaultMessage: 'Telefoonnummer',
  },
  labelSms: {
    id: 'app.components.PaymentRequestDetail.labelSms',
    defaultMessage: 'Sms-bericht',
  },
  labelDescriptionBankStatement: {
    id: 'app.components.PaymentRequestDetail.labelDescriptionBankStatement',
    defaultMessage: 'Omschrijving op afschrift',
  },
  labelAmount: {
    id: 'app.components.PaymentRequestDetail.labelAmount',
    defaultMessage: 'Bedrag',
  },
  labelTotal: {
    id: 'app.components.PaymentRequestDetail.labelTotal',
    defaultMessage: 'Totaal opgehaald',
  },
  labelCreated: {
    id: 'app.components.PaymentRequestDetail.labelCreated',
    defaultMessage: 'Aangemaakt',
  },
  labelCreatedBy: {
    id: 'app.components.PaymentRequestDetail.labelCreatedBy',
    defaultMessage: 'Aangemaakt door',
  },
  labelCreatedOn: {
    id: 'app.components.PaymentRequestDetail.labelCreatedOn',
    defaultMessage: 'op',
  },
  labelSent: {
    id: 'app.components.PaymentRequestDetail.labelSent',
    defaultMessage: "Verzonden sms'en:",
  },
  buttonSend: {
    id: 'app.components.PaymentRequestDetail.buttonSend',
    defaultMessage: 'Opnieuw delen',
  },
  buttonExpiry: {
    id: 'app.components.PaymentRequestDetail.buttonExpiry',
    defaultMessage: 'Geldig t/m ',
  },
  buttonCopy: {
    id: 'app.components.PaymentRequestDetail.buttonCopy',
    defaultMessage: 'Link kopiëren',
  },
  buttonDownload: {
    id: 'app.components.PaymentRequestDetail.buttonDownload',
    defaultMessage: 'Download transacties',
  },
  contentHeader: {
    id: 'app.components.PaymentRequestDetail.contentHeader',
    defaultMessage: 'Transacties',
  },
  yesterday: {
    id: 'app.components.PaymentRequestDetail.yesterday',
    defaultMessage: 'gisteren',
  },
  oopsTitle: {
    id: 'app.components.PaymentRequestDetail.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.PaymentRequestDetail.oopsText',
    defaultMessage:
      'Er ging even iets mis, herlaad de pagina of probeer het over 5 minuten opnieuw.',
  },
  copied: {
    id: 'app.components.PaymentRequestDetail.copied',
    defaultMessage: 'Link gekopieerd op klembord!',
  },
  openTikkie: {
    id: 'app.components.PaymentRequestDetail.openTikkie',
    defaultMessage: 'open Tikkie',
  },
  archive: {
    id: 'app.components.PaymentRequestDetail.archive',
    defaultMessage: 'Verwijderen',
  },
  confirm: {
    id: 'app.components.PaymentRequestDetail.confirm',
    defaultMessage: 'Weet je het zeker?',
  },
});

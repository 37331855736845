/**
 *
 * PaymentRequest
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/paymentrequesticon.svg';
import { Color } from '../../styleguide';
import { formatDate, toCurrency } from '../../../utils';
import messages from './messages';
import { CheckButton } from '../../components';

const previewSelector = 'preview';

const PaymentRequestLineItemWrapper = styled.div`
  position: relative;
  display: block;
  height: 72px;
  line-height: 72px;
  padding: 0 24px 0 0;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-left: 8px solid ${(props) => props.color};
  vertical-align: middle;
  font-family: MuseoSansRounded700;
  font-size: 16px;

  :not(.${previewSelector}) {
    cursor: pointer;
    :hover {
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  width: auto;
  margin-right: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 0;
  }
`;

const CheckButtonWrapper = styled.div`
  float: left;
  padding-left: 14px;
  padding-top: 24px;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 20px 16px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin: 0px 0px 16px 0px;
  }
`;

const ItemIcon = styled.img``;

const Created = styled.div`
  float: left;
  margin-right: 16px;
  width: 80px;
  height: 72px;
  line-height: 72px;
  color: ${Color.gray03};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const InvoiceNumber = styled.div`
  float: left;
  height: 72px;
  line-height: 72px;
  width: 222px;
  margin-right: 16px;
  font-family: MuseoSansRounded;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.tablet} {
    width: 100px;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const Description = styled.div`
  width: auto;
  height: 72px;
  line-height: 72px;
  margin-right: 16px;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const Amount = styled.div`
  float: right;
  height: 72px;
  line-height: 72px;
  text-align: right;
  margin-right: 16px;
  width: 96px;
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    float: unset;
    margin: unset;
    line-height: unset;
    text-align: unset;
    margin-right: unset;
    height: unset;
  }
`;

const StatusContainer = styled.div`
  float: right;
  width: 155px;
  @media ${(props) => props.theme.device.mobile} {
    width: auto;
  }
`;

const Status = styled.div`
  float: right;
  display: inline-block;
  height: 36px;
  min-width: 104px;
  line-height: 36px;
  margin: 18px 0px;
  padding: 0px 17px;
  border-radius: 40px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    position: absolute;
    right: 16px;
    top: 0px;
  }
`;

class PaymentRequestLineItem extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = props;

    const openTikkie = intl.formatMessage({
      id: 'app.components.PaymentRequestLineItem.openTikkie',
    });

    this.state = {
      openTikkie,
    };
  }

  textColor = (paymentRequest) =>
    this.isExpired(paymentRequest) ? Color.gray03 : Color.gray01;

  determineColor = (paymentRequest) => {
    if (this.isPaid(paymentRequest)) {
      return Color.brandColor01;
    } else if (this.isExpired(paymentRequest)) {
      return Color.gray03;
    } else if (this.isNotExpired(paymentRequest)) {
      return Color.secondaryColor02;
    }
    return 'red';
  };

  determineBackgroundColor = (paymentRequest) => {
    if (this.isPaid(paymentRequest)) {
      return '#ECF9F8';
    } else if (this.isExpired(paymentRequest)) {
      return Color.gray05;
    } else if (this.isNotExpired(paymentRequest)) {
      return '#FDF9EC';
    }
    return 'red';
  };

  determineStatusColor = (paymentRequest) => {
    if (this.isExpired(paymentRequest)) {
      return Color.gray04;
    } else if (this.isPaid(paymentRequest)) {
      return Color.brandColor01;
    } else if (this.isNotExpired(paymentRequest)) {
      return Color.secondaryColor02;
    }
    return 'red';
  };

  determineStatusText = (paymentRequest) => {
    if (paymentRequest.preview) {
      return this.props.intl.formatMessage({
        id: 'app.components.PaymentRequestLineItem.preview',
      });
    }
    if (this.isPaid(paymentRequest)) {
      return `+ ${toCurrency(paymentRequest.totalAmountPaidInCents)}`;
    }
    return this.isNotExpired(paymentRequest)
      ? this.props.intl.formatMessage({
          id: 'app.components.PaymentRequestLineItem.waiting',
        })
      : `${toCurrency(0)}`;
  };

  determineStatusId = (paymentRequest) => {
    if (this.isPaid(paymentRequest) && this.isNotExpired(paymentRequest)) {
      return 'paidAndNotExpired';
    } else if (this.isPaid(paymentRequest) && this.isExpired(paymentRequest)) {
      return 'paidAndExpired';
    } else if (this.isExpired(paymentRequest)) {
      return 'notPaidAndExpired';
    }
    return 'notPaidAndNotExpired';
  };

  isNotExpired = (paymentRequest) => paymentRequest.status !== 'EXPIRED';

  isExpired = (paymentRequest) => paymentRequest.status === 'EXPIRED';

  isPaid = (paymentRequest) => paymentRequest.totalTimesPaid > 0;

  render() {
    return (
      <PaymentRequestLineItemWrapper
        className={this.props.preview && previewSelector}
        color={this.determineStatusColor(this.props.paymentRequest)}
      >
        {!!this.props.togglePaymentRequest && (
          <CheckButtonWrapper>
            <CheckButton
              id="cb"
              checked={this.props.selected}
              onClick={this.props.togglePaymentRequest}
            />
          </CheckButtonWrapper>
        )}
        <ItemIconWrapper>
          <ItemIcon src="/paymentrequesticon.svg" />
        </ItemIconWrapper>
        <StatusContainer>
          <Status
            id={this.determineStatusId(this.props.paymentRequest)}
            name="tikkieStatus"
            color={this.determineColor(this.props.paymentRequest)}
            background={this.determineBackgroundColor(
              this.props.paymentRequest,
            )}
          >
            {this.determineStatusText(this.props.paymentRequest)}
          </Status>
        </StatusContainer>
        <ContentWrapper onClick={this.props.showTikkieDetail}>
          <Amount
            name="tikkieAmount"
            color={this.textColor(this.props.paymentRequest)}
          >
            {this.props.paymentRequest.openAmount
              ? this.state.openTikkie
              : toCurrency(this.props.paymentRequest.amountPerPersonInCents)}
          </Amount>
          <Created>
            <FormattedMessage {...messages.yesterday}>
              {(yesterdayText) => (
                <span>
                  {formatDate(yesterdayText, this.props.paymentRequest.created)}
                </span>
              )}
            </FormattedMessage>
          </Created>
          <InvoiceNumber
            name="tikkieInvoice"
            color={this.textColor(this.props.paymentRequest)}
          >
            {this.props.paymentRequest.referenceId
              ? this.props.paymentRequest.referenceId
              : this.props.paymentRequest.externalId}
          </InvoiceNumber>
          <Description
            name="tikkieDescription"
            color={this.textColor(this.props.paymentRequest)}
          >
            {this.props.paymentRequest.description}
          </Description>
        </ContentWrapper>
      </PaymentRequestLineItemWrapper>
    );
  }
}

PaymentRequestLineItem.propTypes = {
  intl: PropTypes.object.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  selected: PropTypes.string,
  showTikkieDetail: PropTypes.func,
  togglePaymentRequest: PropTypes.func,
  preview: PropTypes.bool,
};

export default injectIntl(PaymentRequestLineItem);

import React, { useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import QRCode from '../QRCode/QRCode';
import ShareLink from '../PaymentRequest/PaymentRequestShare/ShareLink';
import config from '../../config';
import Suggestions from './Suggestions';
import messages from '../PaymentRequest/PaymentRequestShare/messages';

interface SuggestionsWrapperProps {
  onCopied(): void;
  link: string;
}

function SuggestionsWrapper({
  onCopied,
  link,
}: SuggestionsWrapperProps): JSX.Element {
  const { t } = useTranslation();
  const [suggestionsLink, setSuggestionsLink] = useState('');
  const [dirtySuggestions, setDirtySuggestions] = useState(false);
  const [showResultSuggestions, setShowResultSuggestions] = useState(false);
  const [qrSuggestions, setQRSuggestions] = useState({
    loading: false,
    error: false,
    data: undefined,
  });

  const qrCodeContainerEnd = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (!qrCodeContainerEnd.current) return;
    qrCodeContainerEnd.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleQRSuggestionsUpdate = (key: string, value: boolean) => {
    // eslint-disable-next-line no-shadow
    setQRSuggestions((qrSuggestions) => ({
      ...qrSuggestions,
      [key]: value,
    }));
    setDirtySuggestions(false);
  };

  const handleGenerateSuggestions = async (suggestions: string) => {
    setSuggestionsLink(`${link}/${suggestions}`);
    setQRSuggestions({
      loading: false,
      error: false,
      data: undefined,
    });
    setShowResultSuggestions(true);
  };

  const fetchSuggestionsQr = async () => {
    const requestUrl = `${config.QR_SERVICE_URL}/qrcode`;
    const body = {
      url: suggestionsLink,
      size: 30,
      style: 'round',
    };
    try {
      const response = await fetch(requestUrl, {
        method: 'Post',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      handleQRSuggestionsUpdate('data', result.qrCodeImage);
    } catch (err) {
      handleQRSuggestionsUpdate('error', true);
    } finally {
      handleQRSuggestionsUpdate('loading', false);
      scrollToBottom();
    }
  };

  return (
    <>
      <Suggestions
        onGenerate={handleGenerateSuggestions}
        onInputChange={() => setDirtySuggestions(true)}
        onReset={() => setShowResultSuggestions(false)}
      />
      {showResultSuggestions && (
        <ShareLink
          link={suggestionsLink}
          onCopy={onCopied}
          disabled={dirtySuggestions}
          dataTestId="payment-request-suggestions-share-link-input"
          label={messages.shareViaSuggestionLink}
          isSuggestionLink
        />
      )}
      {showResultSuggestions && (
        <QRCode
          link={suggestionsLink}
          label={messages.shareViaSuggestionQRCode}
          fetchQRCode={fetchSuggestionsQr}
          fetchingQRCode={qrSuggestions.loading}
          fetchQRCodeError={qrSuggestions.error}
          qrCode={qrSuggestions.data}
          button={messages.buttonQRCodeDownload}
          buttonQRCodePrint={messages.buttonQRCodePrint}
          onCopy={onCopied}
          tip={t('qrCode')}
          isMobile={isMobileOnly}
          disabled={dirtySuggestions}
        />
      )}
      <div ref={qrCodeContainerEnd} />
    </>
  );
}

export default SuggestionsWrapper;

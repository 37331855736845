/*
 * Transaction Filter Messages
 *
 *
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  allTransactions: {
    id: 'app.components.TransactionFilter.allTransactions',
    defaultMessage: 'Alle transacties',
  },
  paymentRequests: {
    id: 'app.components.TransactionFilter.paymentRequests',
    defaultMessage: 'Betaalverzoekje',
  },
  refund: {
    id: 'app.components.TransactionFilter.refund',
    defaultMessage: 'Terugboeking',
  },
  paymentRequests1: {
    id: 'app.components.TransactionFilter.paymentRequests',
    defaultMessage: 'Betaalverzoekje1',
  },
  refund1: {
    id: 'app.components.TransactionFilter.refund',
    defaultMessage: 'Terugboeking1',
  },
  filter: {
    id: 'app.components.TransactionFilter.filter',
    defaultMessage: 'Filter',
  },
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
import { useOnClickOutside } from 'usehooks-ts';
import { blokkieTheme } from '@tikkie/blokkie';
import Dropdown from '../../Dropdown/Dropdown';
import {
  FilterItemFilledWrapper,
  FilterItemWrapper,
  FiltersWrapper,
} from '../../styleguide';
import SearchFilter from '../../SearchFilter';
import { DropdownButton } from '../../components';
import { DatePicker } from '../../DatePicker/single-date-picker';

const DateAndTypeWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  order: 1;
`;

const useStyle = createUseStyles({
  datePicker: {
    transform: 'translate(-130px, 10px)',

    '& .CalendarMonth_caption': {
      paddingTop: '25px !important',
    },

    [blokkieTheme.mediaQueries.allPhone]: {
      right: '-15px',
      transform: 'translate(0, 60px) !important',

      '& > i': {
        right: '75px',
      },
    },
  },
});

function AuditLogFilters({
  onTypeSubmit,
  onSearch,
  selectedType,
  types,
  searchText,
  date,
  setDate,
}) {
  const classes = useStyle();
  const [focus, setFocus] = useState(false);
  const rawDate = date || moment(0);

  const popup = React.useRef(null);
  useOnClickOutside(popup, () => setFocus(false));

  const selectDate = (selectedDate) => {
    setDate(selectedDate);
    setFocus(false);
  };

  const renderFilters = () => (
    <>
      <FilterItemWrapper>
        <Dropdown
          selected={selectedType}
          options={types}
          onSubmit={onTypeSubmit}
        >
          Type
        </Dropdown>
      </FilterItemWrapper>
      <FilterItemWrapper
        ref={popup}
        style={{
          position: 'relative',
          height: '48px',
          margin: '0px 16px 0px 0px',
        }}
      >
        <DropdownButton
          active={false}
          selected={date ? moment(date).format('DD MMMM YYYY') : ''}
          onClick={() => setFocus(!focus)}
          onReset={() => {
            setDate(null);
          }}
        >
          Datum
        </DropdownButton>
        <DatePicker
          className={classes.datePicker}
          focus={focus}
          date={rawDate}
          setDate={selectDate}
          checkRange={(day) => day.isSameOrBefore(moment().endOf('day'))}
        />
      </FilterItemWrapper>
    </>
  );

  return (
    <FiltersWrapper flexDirection={isMobileOnly ? 'column' : 'row'}>
      {isMobileOnly ? (
        <DateAndTypeWrapper>{renderFilters()}</DateAndTypeWrapper>
      ) : (
        renderFilters()
      )}
      <FilterItemFilledWrapper
        pl={isMobileOnly && '0px'}
        mr={isMobileOnly && '0px'}
      >
        <SearchFilter
          onSearch={onSearch}
          searchedText={searchText}
          placeholderText="Zoek op naam van medewerker of omschrijving"
        />
      </FilterItemFilledWrapper>
    </FiltersWrapper>
  );
}

AuditLogFilters.propTypes = {
  onTypeSubmit: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  types: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
  date: PropTypes.object,
  setDate: PropTypes.func.isRequired,
};

export default AuditLogFilters;

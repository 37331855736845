/**
 *
 * LoginContainer
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import { useRouteMatch, useLocation } from 'react-router-dom';

import '!file-loader?name=[name].[ext]!../../images/tikkie-logo.svg';
import '!file-loader?name=[name].[ext]!../../images/bg-pattern.svg';

/* eslint-disable prefer-destructuring */
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';

import Login from '../../components/Login/Login';
import Otp from '../../components/Login/Otp';
import ForgotPassword from '../../components/Login/ForgotPassword';
import ResetPassword from '../../components/Login/ResetPassword';

import { isEmpty } from '../../utils';

import { selectUser } from '../App/selectors';
import { Color } from '../../components/styleguide';

import {
  selectLoginIsLoading,
  selectLoginIsSuccessful,
  selectLoginInvalidCredentials,
  selectLoginInvalidOtpCode,
  selectLoginExpiredOtpCode,
  selectLoginTooFreshOtpCode,
  selectLoginBlockedUser,
  selectLoginSmsSendFailed,
  selectLoginGenericError,
  selectIsSendingResetLink,
  selectResetLinkSent,
  selectSendResetLinkError,
  selectIsResettingPassword,
  selectPasswordResetSuccess,
  selectPasswordResetError,
  selectIsValidatingPasswordResetToken,
  selectValidatingPasswordResetTokenSuccess,
  selectIsValidatingReusedPassword,
  selectValidatingReusedPasswordSuccess,
  selectValidatingReusedPasswordError,
  selectUsername,
  selectPassword,
  selectOtp,
  selectRememberMe,
  selectAction,
  selectIsValidatingToken,
  selectValidateTokenSuccess,
  selectValidateTokenError,
  selectIsSettingPassword,
  selectPasswordSetSuccess,
  selectPasswordSetError,
  selectFirstName,
  selectIsResendingLink,
  selectLinkResent,
  selectResendLinkError,
  selectPasswordTokenError,
} from './selectors';
import {
  loginUser,
  loginReset,
  sendResetLink,
  resetPassword,
  validatePasswordResetToken,
  validateReusedPassword,
  validateToken,
  setPassword,
  resendPasswordSetLink,
} from './actions';

import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import LinkSent from '../../components/Login/LinkSent';
import { usePrevious } from '../../hooks';

const LoginContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Color.brandColor02};
  background-image: url('/bg-pattern.svg');
  background-size: cover;
  @media ${(props) => props.theme.device.tablet} {
    padding-bottom: 120px;
  }
`;

const Slogan = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 416px;
  height: 183px;
  margin-right: 70px;
  transform: translate(0%, -75%);

  @media ${(props) => props.theme.device.tablet} {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 492px;
    height: auto;
    margin: 0 auto;
    padding-top: 120px;
    text-align: center;
    transform: initial;
  }

  @media ${(props) => props.theme.device.mobile} {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    margin: none;
    padding-top: 0px;
    transform: translate(0%, 0%);
  }
`;

const SloganLogo = styled.img`
  width: 93px;
  height: 34px;
  object-fit: contain;
  margin-bottom: 18px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 34px;
    text-align: center;
    margin: 22px 0;
  }
`;

const SloganText = styled.div`
  font-family: MuseoSansRounded900;
  font-size: 46px;
  font-weight: 900;
  line-height: 1.33;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media ${(props) => props.theme.device.tablet} {
    display: block;
    font-size: 40px;
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const RESEND = 'resend-otp';

export function LoginContainer(props) {
  const {
    isLoading,
    isSuccessful,
    onLoginUser,
    onLoginReset,
    onSendResetLink,
    onResetPassword,
    onValidateReusedPassword,
    invalidCredentials,
    invalidOtpCode,
    expiredOtpCode,
    isSending,
    isResetLinkSent,
    resetLinkError,
    isResettingPassword,
    passwordResetSuccess,
    passwordResetError,
    loginSmsSendFailed,
    isValidatingPasswordResetToken,
    validatingPasswordResetTokenSuccess,
    isValidatingReusedPassword,
    validatingReusedPasswordSuccess,
    validatingReusedPasswordError,
    tooFreshOtpCode,
    blockedUser,
    username,
    password,
    otp,
    action,
    user,
    isValidatingToken,
    validateTokenSuccess,
    validateTokenError,
    isSettingPassword,
    passwordSetSuccess,
    passwordSetError,
    firstName,
    isResendingLink,
    resendLinkError,
    onSetPassword,
    passwordTokenError,
  } = props;

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showLinkSent, setShowLinkSent] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [localToken, setLocalToken] = useState(null);
  const [localRememberMe, setLocalRememberMe] = useState(props.rememberMe);

  const match = useRouteMatch();
  const location = useLocation();

  const prevIsLoading = usePrevious(props.isLoading);
  const prevIsResendingLink = usePrevious(props.isResendingLink);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.indexOf('/reset') === 0) {
      const { token } = match.params;
      if (token) {
        props.onValidatePasswordResetToken(token);
        setLocalToken(token);
        setShowLogin(false);
        setShowResetPassword(!!token);
      } else {
        setShowForgotPassword(true);
      }
    }
    if (pathname.indexOf('/password') === 0) {
      const { token } = match.params;
      props.onValidateToken(token);
      setLocalToken(token);
      setShowLogin(false);
      setShowResetPassword(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevIsLoading && prevIsLoading !== props.isLoading) {
      if (
        !props.isLoading &&
        !props.isSuccessful &&
        !props.blockedUser &&
        !props.invalidCredentials &&
        !props.loginSmsSendFailed
      ) {
        onShowOtp();
      }
    }

    if (prevIsResendingLink && !props.isResendingLink && props.linkResent) {
      onShowLinkSent();
    }
  }, [isLoading, props.isResendingLink, props.linkResent]);

  const onShowLogin = () => {
    setShowLogin(true);
    setShowForgotPassword(false);
    setShowResetPassword(false);
    setShowSetPassword(false);
    setShowLinkSent(false);
    setShowOtp(false);
    setLocalToken(null);
  };

  const onShowOtp = () => {
    setShowLogin(false);
    setShowForgotPassword(false);
    setShowResetPassword(false);
    setShowSetPassword(false);
    setShowLinkSent(false);
    setShowOtp(true);
    setLocalToken(null);
  };

  const onShowForgotPassword = () => {
    props.onLoginReset();
    setShowLogin(false);
    setShowForgotPassword(true);
    setShowResetPassword(false);
    setShowSetPassword(false);
    setShowLinkSent(false);
    setShowOtp(false);
    setLocalToken(null);
  };

  const onShowLinkSent = () => {
    setShowLogin(false);
    setShowForgotPassword(false);
    setShowResetPassword(false);
    setShowSetPassword(false);
    setShowLinkSent(true);
    setShowOtp(false);
    setLocalToken(null);
  };

  const onResendOtp = () => {
    props.onLoginUser(username, password, '', true, RESEND);
  };

  const onToggleRememberMe = () => {
    setLocalRememberMe(!localRememberMe);
  };

  const onResendLink = () => props.onResendPasswordSetLink(localToken);

  if (!isEmpty(user)) {
    return <Redirect to="/" push />;
  }

  return (
    <LoginContainerWrapper>
      <Slogan>
        <SloganLogo src="/tikkie-logo.svg" />
        <SloganText>
          <FormattedMessage {...messages.slogan1} />
        </SloganText>
      </Slogan>
      {showLogin && (
        <Login
          username={username}
          password={password}
          otp={otp}
          rememberMe={localRememberMe}
          isLoading={isLoading}
          isSuccessful={isSuccessful}
          invalidCredentials={invalidCredentials}
          loginSmsSendFailed={loginSmsSendFailed}
          blockedUser={blockedUser}
          isResetLinkSent={isResetLinkSent}
          onLoginUser={onLoginUser}
          onLoginReset={onLoginReset}
          onShowForgotPassword={onShowForgotPassword}
        />
      )}
      {showOtp && (
        <Otp
          username={username}
          password={password}
          rememberMe={localRememberMe}
          action={action}
          isLoading={isLoading}
          isSuccessful={isSuccessful}
          invalidOtpCode={invalidOtpCode}
          expiredOtpCode={expiredOtpCode}
          blockedUser={blockedUser}
          loginSmsSendFailed={loginSmsSendFailed}
          tooFreshOtpCode={tooFreshOtpCode}
          onLoginUser={onLoginUser}
          onShowLogin={onShowLogin}
          onResendOtp={onResendOtp}
          onToggleRememberMe={onToggleRememberMe}
        />
      )}
      {showForgotPassword && (
        <ForgotPassword
          isSending={isSending}
          isResetLinkSent={isResetLinkSent}
          resetLinkError={resetLinkError}
          onSendResetLink={onSendResetLink}
          onShowLogin={onShowLogin}
        />
      )}
      {showResetPassword && (
        <ResetPassword
          token={localToken}
          isSettingPassword={isResettingPassword}
          passwordSetSuccess={passwordResetSuccess}
          hasPasswordSetError={passwordResetError}
          isValidatingToken={isValidatingPasswordResetToken}
          validateTokenSuccess={validatingPasswordResetTokenSuccess}
          validateTokenError={passwordTokenError}
          onSetPassword={onResetPassword}
          onShowLogin={onShowLogin}
          isValidatingReusedPassword={isValidatingReusedPassword}
          validatingReusedPasswordSuccess={validatingReusedPasswordSuccess}
          validatingReusedPasswordError={validatingReusedPasswordError}
          onValidateReusedPassword={onValidateReusedPassword}
          onSendNewLink={onShowForgotPassword}
          resetPassword
        />
      )}
      {showSetPassword && (
        <ResetPassword
          token={localToken}
          onSetPassword={onSetPassword}
          isSettingPassword={isSettingPassword}
          passwordSetSuccess={passwordSetSuccess}
          hasPasswordSetError={passwordSetError !== 0}
          passwordSetError={passwordSetError}
          isValidatingToken={isValidatingToken}
          validateTokenSuccess={validateTokenSuccess}
          validateTokenError={validateTokenError}
          onShowLogin={onShowLogin}
          onSendNewLink={onResendLink}
          firstName={firstName}
          isResendingLink={isResendingLink}
          hasResendLinkError={resendLinkError !== 0}
          validatingReusedPasswordSuccess
        />
      )}
      {showLinkSent && <LinkSent onShowLogin={onShowLogin} />}
    </LoginContainerWrapper>
  );
}

LoginContainer.propTypes = {
  match: PropTypes.object,
  onLoginUser: PropTypes.func,
  onLoginReset: PropTypes.func,
  onSendResetLink: PropTypes.func,
  onResetPassword: PropTypes.func,
  onValidateReusedPassword: PropTypes.func,
  onValidatePasswordResetToken: PropTypes.func,
  isLoading: PropTypes.bool,
  invalidCredentials: PropTypes.bool,
  isSending: PropTypes.bool,
  isResetLinkSent: PropTypes.bool,
  resetLinkError: PropTypes.bool,
  isResettingPassword: PropTypes.bool,
  passwordResetSuccess: PropTypes.bool,
  passwordResetError: PropTypes.bool,
  isValidatingPasswordResetToken: PropTypes.bool,
  validatingPasswordResetTokenSuccess: PropTypes.bool,
  isValidatingReusedPassword: PropTypes.bool,
  validatingReusedPasswordSuccess: PropTypes.bool,
  validatingReusedPasswordError: PropTypes.bool,
  rememberMe: PropTypes.bool,
  isSuccessful: PropTypes.bool,
  blockedUser: PropTypes.bool,
  loginSmsSendFailed: PropTypes.bool,
  username: PropTypes.string,
  password: PropTypes.string,
  invalidOtpCode: PropTypes.bool,
  expiredOtpCode: PropTypes.bool,
  tooFreshOtpCode: PropTypes.bool,
  otp: PropTypes.string,
  action: PropTypes.string,
  user: PropTypes.object,

  // set password
  isValidatingToken: PropTypes.bool,
  validateTokenSuccess: PropTypes.bool,
  validateTokenError: PropTypes.number,
  isSettingPassword: PropTypes.bool,
  passwordSetSuccess: PropTypes.bool,
  passwordSetError: PropTypes.number,
  firstName: PropTypes.string,
  isResendingLink: PropTypes.bool,
  linkResent: PropTypes.bool,
  resendLinkError: PropTypes.number,
  onValidateToken: PropTypes.func,
  onSetPassword: PropTypes.func,
  onResendPasswordSetLink: PropTypes.func,
  passwordTokenError: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  isLoading: selectLoginIsLoading,
  isSuccessful: selectLoginIsSuccessful,
  invalidCredentials: selectLoginInvalidCredentials,
  invalidOtpCode: selectLoginInvalidOtpCode,
  expiredOtpCode: selectLoginExpiredOtpCode,
  tooFreshOtpCode: selectLoginTooFreshOtpCode,
  blockedUser: selectLoginBlockedUser,
  loginSmsSendFailed: selectLoginSmsSendFailed,
  loginGenericError: selectLoginGenericError,
  isSending: selectIsSendingResetLink,
  isResetLinkSent: selectResetLinkSent,
  resetLinkError: selectSendResetLinkError,
  isResettingPassword: selectIsResettingPassword,
  passwordResetSuccess: selectPasswordResetSuccess,
  passwordResetError: selectPasswordResetError,
  isValidatingPasswordResetToken: selectIsValidatingPasswordResetToken,
  validatingPasswordResetTokenSuccess:
    selectValidatingPasswordResetTokenSuccess,
  isValidatingReusedPassword: selectIsValidatingReusedPassword,
  validatingReusedPasswordSuccess: selectValidatingReusedPasswordSuccess,
  validatingReusedPasswordError: selectValidatingReusedPasswordError,
  username: selectUsername,
  password: selectPassword,
  otp: selectOtp,
  rememberMe: selectRememberMe,
  action: selectAction,

  // set password
  isValidatingToken: selectIsValidatingToken,
  validateTokenSuccess: selectValidateTokenSuccess,
  validateTokenError: selectValidateTokenError,
  isSettingPassword: selectIsSettingPassword,
  passwordSetSuccess: selectPasswordSetSuccess,
  passwordSetError: selectPasswordSetError,
  firstName: selectFirstName,
  isResendingLink: selectIsResendingLink,
  linkResent: selectLinkResent,
  resendLinkError: selectResendLinkError,
  passwordTokenError: selectPasswordTokenError,
});

const mapDispatchToProps = (dispatch) => ({
  onLoginUser: (username, password, otp, rememberMe, action) =>
    dispatch(loginUser(username, password, otp, rememberMe, action)),
  onLoginReset: () => dispatch(loginReset()),
  onSendResetLink: (email) => dispatch(sendResetLink(email)),
  onResetPassword: (password, token) =>
    dispatch(resetPassword(password, token)),
  onValidatePasswordResetToken: (token) =>
    dispatch(validatePasswordResetToken(token)),
  onValidateReusedPassword: (password, token) =>
    dispatch(validateReusedPassword(password, token)),
  onValidateToken: (token) => dispatch(validateToken(token)),
  onSetPassword: (password, token) => dispatch(setPassword(password, token)),
  onResendPasswordSetLink: (token) => dispatch(resendPasswordSetLink(token)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'loginContainer', reducer });
const withSaga = injectSaga({ key: 'loginContainer', saga });

export default compose(withReducer, withSaga, withConnect)(LoginContainer);

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';
import { selectOrganisationName } from '../selectors';
import { setContactName } from '../actions';
import ContactName from '../../../components/OnboardingKYC/ContactName';
import FormWrapperContainer from '../FormWrapperContainer';

function ContactNameContainer(props) {
  return (
    <FormWrapperContainer portalBacking>
      <ContactName setContactName={props.setContactName} />
    </FormWrapperContainer>
  );
}

ContactNameContainer.propTypes = {
  setContactName: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
});

const mapDispatchToProps = (dispatch) => ({
  setContactName: (contactNameInfo) =>
    dispatch(setContactName(contactNameInfo)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(ContactNameContainer);

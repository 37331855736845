const get = (feature) => feature === 'true';

export default {
  API_URL: process.env.REACT_APP_API_URL,
  API_GATEWAY_URL: process.env.REACT_APP_BACKEND_API_GATEWAY_HOST,
  ONBOARDING_SERVICE_URL: process.env.REACT_APP_ONBOARDING_SERVICE_URL,
  QR_SERVICE_URL: process.env.REACT_APP_QR_SERVICE_URL,
  TIKKIE_BASE_URL: process.env.REACT_APP_TIKKIE_BASE_URL,
  PAGES_SETTINGS_ENABLED: process.env.REACT_APP_PAGES_SETTINGS_ENABLED,
  USER_MANAGEMENT_ENABLED: process.env.REACT_APP_USER_MANAGEMENT_ENABLED,
  LOGO_UPLOAD_ENABLED: process.env.REACT_APP_LOGO_UPLOAD_ENABLED,
  AUDIT_LOG_ENABLED: process.env.REACT_APP_AUDIT_LOG_ENABLED,
  CDD_FLOW_ENABLED: process.env.REACT_APP_CDD_FLOW_ENABLED,
  PILOT_ENABLED: process.env.REACT_APP_PILOT_ENABLED,
  get,
};

/*
 * ModalWrapper Messages
 *
 * This contains all the text for the ModalWrapper component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  informationHeader: {
    id: 'app.components.ModalWrapper.InformationHeader',
    defaultMessage: 'Waarom kiezen bedrijven voor Tikkie?',
  },
  informationButton: {
    id: 'app.components.ModalWrapper.InformationButton',
    defaultMessage: 'Hoe werkt het?',
  },
  back: {
    id: 'app.components.ModalWrapper.back',
    defaultMessage: 'Terug naar website',
  },
  advantage1: {
    id: 'app.components.ModalWrapper.advantage1',
    defaultMessage: 'Stuur Tikkies in batches',
  },
  advantage2: {
    id: 'app.components.ModalWrapper.advantage2',
    defaultMessage: 'Je eigen custom betaalpagina’s',
  },
  advantage3: {
    id: 'app.components.ModalWrapper.advantage3',
    defaultMessage: 'Supersnel je geld binnen',
  },
  termsAndConditions: {
    id: 'app.components.ModalWrapper.termsAndConditions',
    defaultMessage: 'Voorwaarden',
  },
  privacy: {
    id: 'app.components.ModalWrapper.privacy',
    defaultMessage: 'Privacy',
  },
  cookies: {
    id: 'app.components.ModalWrapper.cookies',
    defaultMessage: 'Cookies',
  },
  copyright1: {
    id: 'app.components.ModalWrapper.copyright1',
    defaultMessage: '© ',
  },
  copyright2: {
    id: 'app.components.ModalWrapper.copyright2',
    defaultMessage: ' Tikkie - ABN AMRO',
  },
  menuItem1: {
    id: 'app.components.Portalbacking.menuItem1',
    defaultMessage: 'Transacties',
  },
  menuItem2: {
    id: 'app.components.Portalbacking.menuItem2',
    defaultMessage: 'Betaalverzoekjes',
  },
  logOut: {
    id: 'app.components.Portalbacking.logOut',
    defaultMessage: 'Uitloggen',
  },
});

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/* eslint no-unused-vars: off */
const selectLoginIsLoading = (state) =>
  state.get('loginContainer').get('isLoading');
const selectLoginIsSuccessful = (state) =>
  state.get('loginContainer')
    ? state.get('loginContainer').get('isSuccessful')
    : false;
const selectPhoneNumberVerified = (state) =>
  state.get('loginContainer').get('phoneNumberVerified');
const selectLoginInvalidCredentials = (state) =>
  state.get('loginContainer').get('invalidCredentials');
const selectLoginInvalidOtpCode = (state) =>
  state.get('loginContainer').get('invalidOTPCode');
const selectLoginExpiredOtpCode = (state) =>
  state.get('loginContainer').get('expiredOTPCode');
const selectLoginTooFreshOtpCode = (state) =>
  state.get('loginContainer').get('tooFreshOTPCode');
const selectLoginBlockedUser = (state) =>
  state.get('loginContainer').get('blockedUser');
const selectLoginSmsSendFailed = (state) =>
  state.get('loginContainer').get('smsSendFailed');
const selectLoginGenericError = (state) =>
  state.get('loginContainer').get('genericError');
const selectIsSendingResetLink = (state) =>
  state.get('loginContainer').get('isSendingResetLink');
const selectResetLinkSent = (state) =>
  state.get('loginContainer').get('resetLinkSent');
const selectSendResetLinkError = (state) =>
  state.get('loginContainer').get('sendResetLinkError');
const selectIsResettingPassword = (state) =>
  state.get('loginContainer').get('isResettingPassword');
const selectPasswordResetSuccess = (state) =>
  state.get('loginContainer').get('passwordResetSuccess');
const selectPasswordResetError = (state) =>
  state.get('loginContainer').get('passwordResetError');
const selectIsValidatingPasswordResetToken = (state) =>
  state.get('loginContainer').get('isValidatingToken');
const selectValidatingPasswordResetTokenSuccess = (state) =>
  state.get('loginContainer').get('validatingTokenSuccess');
const selectIsValidatingReusedPassword = (state) =>
  state.get('loginContainer').get('isValidatingReusedPassword');
const selectValidatingReusedPasswordSuccess = (state) =>
  state.get('loginContainer').get('validatingReusedPasswordSuccess');
const selectValidatingReusedPasswordError = (state) =>
  state.get('loginContainer').get('validatingReusedPasswordError');
const selectUsername = (state) => state.get('loginContainer').get('username');
const selectPassword = (state) => state.get('loginContainer').get('password');
const selectOtp = (state) => state.get('loginContainer').get('otp');
const selectRememberMe = (state) =>
  state.get('loginContainer').get('rememberMe');
const selectAction = (state) => state.get('loginContainer').get('action');
const selectIsValidatingToken = (state) =>
  state.get('loginContainer').get('isValidatingPwToken');
const selectValidateTokenSuccess = (state) =>
  state.get('loginContainer').get('validatingPwTokenSuccess');
const selectValidateTokenError = (state) =>
  state.get('loginContainer').get('validatingPwTokenError');
const selectIsSettingPassword = (state) =>
  state.get('loginContainer').get('isSettingPassword');
const selectPasswordSetSuccess = (state) =>
  state.get('loginContainer').get('passwordSetSuccess');
const selectPasswordSetError = (state) =>
  state.get('loginContainer').get('passwordSetError');
const selectFirstName = (state) => state.get('loginContainer').get('firstName');
const selectIsResendingLink = (state) =>
  state.get('loginContainer').get('isResendingLink');
const selectLinkResent = (state) =>
  state.get('loginContainer').get('linkResent');
const selectResendLinkError = (state) =>
  state.get('loginContainer').get('resendLinkError');
const selectPasswordTokenError = (state) =>
  state.get('loginContainer').get('passwordTokenError');

export {
  selectLoginIsLoading,
  selectLoginIsSuccessful,
  selectLoginInvalidCredentials,
  selectLoginInvalidOtpCode,
  selectLoginExpiredOtpCode,
  selectLoginTooFreshOtpCode,
  selectLoginBlockedUser,
  selectLoginSmsSendFailed,
  selectLoginGenericError,
  selectIsSendingResetLink,
  selectResetLinkSent,
  selectSendResetLinkError,
  selectIsResettingPassword,
  selectPasswordResetSuccess,
  selectPasswordResetError,
  selectIsValidatingPasswordResetToken,
  selectValidatingPasswordResetTokenSuccess,
  selectIsValidatingReusedPassword,
  selectValidatingReusedPasswordSuccess,
  selectValidatingReusedPasswordError,
  selectUsername,
  selectPassword,
  selectOtp,
  selectRememberMe,
  selectAction,
  selectIsValidatingToken,
  selectValidateTokenSuccess,
  selectValidateTokenError,
  selectIsSettingPassword,
  selectPasswordSetSuccess,
  selectPasswordSetError,
  selectFirstName,
  selectIsResendingLink,
  selectLinkResent,
  selectResendLinkError,
  selectPasswordTokenError,
};

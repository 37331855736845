export const riskQuestionSteps = {
  explanation: 'explanationStart',
  sector: 'sector',
  nation: 'nation',
  defence: 'defence',
  financialPartOne: 'financialPartOne',
  financialPartTwo: 'financialPartTwo',
};

export const riskQuestionStepsSequence = [
  riskQuestionSteps.explanation,
  riskQuestionSteps.sector,
  riskQuestionSteps.nation,
  riskQuestionSteps.defence,
  riskQuestionSteps.financialPartOne,
  riskQuestionSteps.financialPartTwo,
];

export const riskQuestionIds = {
  sectorRiskId: 'sectorRisk',
  defenseRiskId: 'defenseRisk',
  nationRiskId: 'nationRisk',
  financialPartOneRiskId: 'financialPartOneRisk',
  financialPartTwoRiskId: 'financialPartTwoRisk',
};

/*
 * Menu Messages
 *
 * This contains all the text for the Menu component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  switch: {
    id: 'app.components.Menu.switch',
    defaultMessage: 'Wissel van organisatie',
  },
  transactions: {
    id: 'app.components.Menu.transactions',
    defaultMessage: 'Transacties',
  },
  paymentRequests: {
    id: 'app.components.Menu.paymentRequests',
    defaultMessage: 'Betaalverzoekjes',
  },
  settings: {
    id: 'app.components.Menu.settings',
    defaultMessage: 'Instellingen',
  },
  profile: {
    id: 'app.components.Menu.profile',
    defaultMessage: 'Mijn profiel',
  },
  organisationSettings: {
    id: 'app.components.Menu.organisationSettings',
    defaultMessage: 'Bedrijfsinstellingen',
  },
  apiSettings: {
    id: 'app.components.Menu.apiSettings',
    defaultMessage: 'API instellingen',
  },
  pagesSettings: {
    id: 'app.components.Menu.pagesSettings',
    defaultMessage: "Pagina's personaliseren",
  },
  smsSettings: {
    id: 'app.components.Menu.smsSettings',
    defaultMessage: 'Sms instellingen',
  },
  bulkSettings: {
    id: 'app.components.Menu.bulkSettings',
    defaultMessage: 'Upload instellingen',
  },
  logout: {
    id: 'app.components.Menu.logout',
    defaultMessage: 'Uitloggen',
  },
  cashback: {
    id: 'app.components.Menu.cashback',
    defaultMessage: 'Tikkie Terug',
  },
  campaigns: {
    id: 'app.components.Menu.campaigns',
    defaultMessage: 'Campagnes',
  },
  search_placeholder: {
    id: 'app.components.Menu.search_placeholder',
    defaultMessage: 'Zoeken',
  },
  allOrganisations: {
    id: 'app.components.Menu.allOrganisations',
    defaultMessage: 'Alle organisaties\u2000',
  },
  errorTitle: {
    id: 'app.components.Menu.errorTitle',
    defaultMessage: 'Oeps, er gaat iets mis',
  },
  errorDescription: {
    id: 'app.components.Menu.errorDescription',
    defaultMessage: 'Probeer het later nog eens.',
  },
});

/*
 * TransactionDetail Messages
 *
 * This contains all the text for the TransactionDetail component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  headerPaymentRequestPayment: {
    id: 'app.components.TransactionDetail.headerPaymentRequestPayment',
    defaultMessage: 'Tikkie transactiedetails',
  },
  headerRefundPayment: {
    id: 'app.components.TransactionDetail.headerRefundPayment',
    defaultMessage: 'Terugboeking transactiedetails',
  },
  transactionList: {
    id: 'app.components.TransactionDetail.transactionList',
    defaultMessage: 'Transacties',
  },
  transactionDetail: {
    id: 'app.components.TransactionDetail.transactionDetail',
    defaultMessage: 'Transactie details',
  },
  amount: {
    id: 'app.components.TransactionDetail.amount',
    defaultMessage: 'Bedrag',
  },
  status: {
    id: 'app.components.TransactionDetail.status',
    defaultMessage: 'Status',
  },
  tikkieId: {
    id: 'app.components.TransactionDetail.tikkieId',
    defaultMessage: 'Tikkie ID',
  },
  paid: {
    id: 'app.components.TransactionDetail.paid',
    defaultMessage: 'Betaald',
  },
  refund: {
    id: 'app.components.TransactionDetail.refund',
    defaultMessage: 'Terugbetaling',
  },
  idNumber: {
    id: 'app.components.TransactionDetail.idNumber',
    defaultMessage: 'Kenmerk',
  },
  description: {
    id: 'app.components.TransactionDetail.description',
    defaultMessage: 'Omschrijving',
  },
  contact: {
    id: 'app.components.TransactionDetail.contact',
    defaultMessage: 'Contactgegevens',
  },
  address: {
    id: 'app.components.TransactionDetail.address',
    defaultMessage: 'Adres',
  },
  paidBy: {
    id: 'app.components.TransactionDetail.paidBy',
    defaultMessage: 'Betaald door',
  },
  paidOn: {
    id: 'app.components.TransactionDetail.paidOn',
    defaultMessage: 'Betaald op',
  },
  paidOnRefund: {
    id: 'app.components.TransactionDetail.paidOnRefund',
    defaultMessage: 'Uitbetaald op',
  },
  hour: {
    id: 'app.components.TransactionDetail.hour',
    defaultMessage: 'uur',
  },
  gotoOriginPaymentRequest: {
    id: 'app.components.TransactionDetail.gotoOriginPaymentRequest',
    defaultMessage: 'Ga naar de bijbehorende Tikkie',
  },
  gotoOriginTikkiePayment: {
    id: 'app.components.TransactionDetail.gotoOriginTikkiePayment',
    defaultMessage: 'Ga naar de oorspronkelijke betaling',
  },
});

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';
import BulkDetail from '../../../components/Bulk/BulkDetail';
import {
  sendBulkUpload,
  loadBulkFile,
  loadPaymentRequests,
  downloadMultiplePaymentRequests,
  clearData,
} from './actions';

import { savePaymentRequestDraft } from '../../App/actions';

import {
  selectIsSendingBulkUpload,
  selectSendBulkUploadErrorMessage,
  selectSendBulkUploadErrorValidationDetails,
  selectSendBulkUploadErrorStatus,
  selectPaymentRequests,
  selectPaymentRequestsErrorMessage,
  selectPaymentRequestsIsLoading,
  selectBulkFile,
  selectIsLoadingBulkFile,
} from './selectors';

import { selectUser } from '../../App/selectors';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';

function BulkDetailContainer(props) {
  const {
    user,
    bulkFile,
    bulkFileErrorMessage,
    sendBulkUploadErrorValidationDetails,
    paymentRequests,
    paymentRequestsIsLoading,
    paymentRequestsErrorMessage,
    isSendingBulkUpload,
    isLoadingBulkFile,
  } = props;
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  useEffect(
    () => () => props.clearData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (isEmpty(user)) {
    return <EmptyLoader />;
  }

  const { bulkId } = match.params;

  const showBulkList = () => history.push('/bulk');

  const loadBulk = (id) => props.loadBulkFile(id);

  const showTikkieDetail = (viewPaymentRequest) => {
    if (viewPaymentRequest.token) {
      history.push(`/paymentrequest/${viewPaymentRequest.token}`);
    } else if (!viewPaymentRequest.token || viewPaymentRequest.token === '') {
      savePaymentRequestDraft(viewPaymentRequest);
      history.push('/paymentrequest/draft');
    }
  };
  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <BulkDetail
            bulkFile={{ id: bulkId, ...bulkFile }}
            bulkFileErrorMessage={bulkFileErrorMessage}
            loadBulkFile={loadBulk}
            paymentRequests={paymentRequests}
            paymentRequestsIsLoading={paymentRequestsIsLoading}
            paymentRequestsErrorMessage={paymentRequestsErrorMessage}
            loadPaymentRequests={props.loadPaymentRequests}
            sendBulkUpload={props.sendBulkUpload}
            sendBulkUploadErrorValidationDetails={
              sendBulkUploadErrorValidationDetails
            }
            isSendingBulkUpload={isSendingBulkUpload}
            showBulkList={showBulkList}
            showTikkieDetail={showTikkieDetail}
            downloadMultiplePaymentRequests={
              props.downloadMultiplePaymentRequests
            }
            downloadOption={user.downloadOption}
            isLoadingBulkFile={isLoadingBulkFile}
            location={location}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

BulkDetailContainer.propTypes = {
  user: PropTypes.object,
  bulkFile: PropTypes.object,
  loadBulkFile: PropTypes.func,
  bulkFileErrorMessage: PropTypes.string,
  loadPaymentRequests: PropTypes.func,
  paymentRequests: PropTypes.object,
  paymentRequestsIsLoading: PropTypes.bool,
  paymentRequestsErrorMessage: PropTypes.string,
  sendBulkUpload: PropTypes.func,
  isSendingBulkUpload: PropTypes.bool,
  sendBulkUploadErrorValidationDetails: PropTypes.object,
  downloadMultiplePaymentRequests: PropTypes.func,
  clearData: PropTypes.func,
  isLoadingBulkFile: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  bulkFile: selectBulkFile,
  paymentRequests: selectPaymentRequests,
  paymentRequestsIsLoading: selectPaymentRequestsIsLoading,
  paymentRequestsErrorMessage: selectPaymentRequestsErrorMessage,
  isSendingBulkUpload: selectIsSendingBulkUpload,
  sendBulkUploadErrorStatus: selectSendBulkUploadErrorStatus,
  sendBulkUploadErrorMessage: selectSendBulkUploadErrorMessage,
  sendBulkUploadErrorValidationDetails:
    selectSendBulkUploadErrorValidationDetails,
  isLoadingBulkFile: selectIsLoadingBulkFile,
});

const mapDispatchToProps = (dispatch) => ({
  sendBulkUpload: (id, title) => dispatch(sendBulkUpload(id, title)),
  loadBulkFile: (id) => dispatch(loadBulkFile(id)),
  loadPaymentRequests: (
    currentPage,
    label,
    from,
    to,
    status,
    search,
    paymentRequestBatchId,
  ) =>
    dispatch(
      loadPaymentRequests(
        currentPage,
        label,
        from,
        to,
        status,
        search,
        paymentRequestBatchId,
      ),
    ),
  downloadMultiplePaymentRequests: (
    label,
    from,
    to,
    status,
    search,
    allSelected,
    selectedPaymentRequests,
    paymentRequestBatchId,
    csvType,
  ) =>
    dispatch(
      downloadMultiplePaymentRequests(
        label,
        from,
        to,
        status,
        search,
        allSelected,
        selectedPaymentRequests,
        paymentRequestBatchId,
        csvType,
      ),
    ),
  savePaymentRequestDraft: (paymentRequest) =>
    dispatch(savePaymentRequestDraft(paymentRequest)),
  clearData: () => dispatch(clearData()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'bulkDetailContainer', reducer });
const withSaga = injectSaga({ key: 'bulkDetailContainer', saga });

export default compose(withReducer, withSaga, withConnect)(BulkDetailContainer);

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';
import {
  selectIssuers,
  selectIssuersPending,
  selectOrganisationName,
  selectInitiatingBusinessPayment,
  selectInitiateBusinessPaymentSuccess,
  selectBusinessPayment,
  selectCheckingBusinessPayment,
  selectBusinessPaymentStatus,
  selectOnboardingKYCData,
} from '../selectors';
import {
  getIssuers,
  initiateBusinessPayment,
  checkBusinessPayment,
  handlePendingPayment,
  getCurrentItem,
  skipBusinessPayment,
} from '../actions';
import Payment from '../../../components/OnboardingKYC/Payment';
import FormWrapperContainer from '../FormWrapperContainer';

function BusinessPaymentContainer(props) {
  const { organisationName, pending, onboardingData } = props;

  return (
    <FormWrapperContainer portalBacking>
      <Payment
        getIssuers={props.getIssuers}
        issuers={props.issuers}
        pending={pending}
        initiatePayment={props.initiatePayment}
        payment={props.payment}
        checkPayment={props.checkPayment}
        paymentStatus={props.paymentStatus}
        goToIdeal={props.goToIdeal}
        organisationName={organisationName}
        skipPayment={props.skipPayment}
        business
        next={props.getCurrentItem}
        showOnlyAbnWhenBusinessPayment={onboardingData.hasAbnBank}
        bankCode={onboardingData.bankCode}
      />
    </FormWrapperContainer>
  );
}

BusinessPaymentContainer.propTypes = {
  organisationName: PropTypes.string,
  getIssuers: PropTypes.func.isRequired,
  issuers: PropTypes.array.isRequired,
  initiatePayment: PropTypes.func.isRequired,
  payment: PropTypes.object,
  checkPayment: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string,
  goToIdeal: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  getCurrentItem: PropTypes.func.isRequired,
  skipPayment: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
};

const pending = createSelector(
  [
    selectIssuersPending,
    selectInitiatingBusinessPayment,
    selectInitiateBusinessPaymentSuccess,
    selectCheckingBusinessPayment,
  ],
  (
    issuersPending,
    initiatePaymentPending,
    initiatePaymentSuccess,
    checkingPayment,
  ) =>
    issuersPending ||
    initiatePaymentPending ||
    initiatePaymentSuccess ||
    checkingPayment,
);

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  issuers: selectIssuers,
  payment: selectBusinessPayment,
  paymentStatus: selectBusinessPaymentStatus,
  onboardingData: selectOnboardingKYCData,
  pending,
});

const mapDispatchToProps = (dispatch) => ({
  getIssuers: () => dispatch(getIssuers()),
  initiatePayment: (issuerCode) =>
    dispatch(initiateBusinessPayment(issuerCode)),
  checkPayment: (paymentToken) => dispatch(checkBusinessPayment(paymentToken)),
  goToIdeal: (idealPaymentUrl) =>
    dispatch(handlePendingPayment(idealPaymentUrl)),
  getCurrentItem: () => dispatch(getCurrentItem()),
  skipPayment: () => dispatch(skipBusinessPayment()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(BusinessPaymentContainer);

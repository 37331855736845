/**
 *
 * Transaction Bundle Header
 *
 */

import '!file-loader?name=[name].[ext]!../../../images/checkicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/downloadicon.svg';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Info } from 'amber';
import { Color } from '../../styleguide';
import messages from './messages';
import { toCurrency } from '../../../utils';

const BundleHeader = styled.div`
  width: 100%;
  height: 24px;
  margin: 24px 0;

  @media ${(props) => props.theme.device.mobile} {
    height: 100%;
  }

  & span {
    padding: 0 8px;
    background: ${Color.backgroundColor};
  }
`;

const Hr = styled.div`
  height: 2px;
  position: relative;
  top: 11px;
  width: 100%;
  background: ${Color.gray04};
`;

const BundleDetail = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;

  float: left;
  position: relative;
  top: -2px;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  margin: 0 0 0 18px;
  color: ${Color.gray03};

  @media ${(props) => props.theme.device.mobile} {
    margin: auto;
    padding: 0 16px;
    width: fit-content;
    height: 100%;
    text-align: center;
  }
`;
const DownloadTransactionsButton = styled.span`
  display: grid;
  grid-auto-flow: column;
  background: ${Color.backgroundColor};
  cursor: pointer;
  margin-right: 64px;

  img {
    margin-top: -2px;
  }

  span {
    padding-left: 16px;
    padding-right: 0;
  }

  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const BundleInfo = styled.div`
  margin-right: 18px;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  color: ${Color.gray03};
  position: relative;
  height: min-height;
  float: right;
`;

const InfoButton = styled.span`
  padding: 0;
  margin-top: -6px;
`;

const BundleTotal = styled.span`
  span {
    padding-left: 0;
    padding-right: 6px;
  }
`;

function TransactionBundleHeader(props) {
  const isTxnsOfToday = (date) => moment(date).isSame(moment.now(), 'day');

  const payoutTypeLabel = (isBundle, date, isPaid) => {
    if (isBundle && isTxnsOfToday(date) && !isPaid) {
      return <FormattedMessage {...messages.currentBalance} />;
    } else if (isBundle) {
      return <FormattedMessage {...messages.bundledPayout} />;
    }
    return <FormattedMessage {...messages.singlePayout} />;
  };

  return (
    <BundleHeader>
      <Hr />
      <BundleDetail>
        {isTxnsOfToday(props.date) ? (
          <FormattedMessage {...messages.today} />
        ) : (
          <span>{moment(props.date).format("D MMM 'YY")}</span>
        )}
        {/* {', '} */}
        {payoutTypeLabel(props.isBundle, props.date, props.isBundlePaid)}{' '}
      </BundleDetail>
      <BundleInfo>
        {props.isBundlePaid && (
          <DownloadTransactionsButton
            onClick={() => props.downloadCamt(props.date)}
          >
            <img alt="" src="/downloadicon.svg" />
            <FormattedMessage {...messages.downloadTransacties} />
          </DownloadTransactionsButton>
        )}
        <BundleTotal>
          <FormattedMessage {...messages.total} />
          {toCurrency(props.amountInCents)}
        </BundleTotal>
        {props.hasInternationalTransaction && (
          <InfoButton>
            <Info
              title="Losse transacties"
              desc="Omdat dit internationale transacties zijn, hebben we iets meer tijd nodig om deze te controleren."
            />
          </InfoButton>
        )}
      </BundleInfo>
    </BundleHeader>
  );
}

TransactionBundleHeader.propTypes = {
  isBundle: PropTypes.bool.isRequired,
  isBundlePaid: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  amountInCents: PropTypes.number,
  downloadCamt: PropTypes.func,
  hasInternationalTransaction: PropTypes.bool,
};

export default TransactionBundleHeader;

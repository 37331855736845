import React from 'react';
import '!file-loader?name=[name].[ext]!../../../images/gifs/the-office-sorry.gif';
import messages from './messages';
import { OnboardingParagraph } from '../styledComponents';
import { Space } from '../../styleguide';
import Screen from './Screen';

interface GenericProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  mailSupport: () => void;
}

function Generic({ error, mailSupport }: GenericProps): JSX.Element {
  return (
    <Screen
      showGif
      header={messages.generalErrorHeader.defaultMessage || ''}
      buttonText={messages.askSupportButtonText.defaultMessage}
      buttonClick={mailSupport}
    >
      <OnboardingParagraph
        textAlign="center"
        marginTop={Space.s0}
        marginBottom={Space.s16}
      >
        {error.errorCode // @ts-expect-error typescript cannot guarantee that the string will exist in the messages
          ? messages[`error${error.errorCode}`].defaultMessage
          : messages.generalError.defaultMessage}
      </OnboardingParagraph>
    </Screen>
  );
}

export default Generic;

import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Color } from '../styleguide';

const top = 'top';

interface TooltipProps {
  position: typeof top;
  isButton?: boolean;
  children: ReactNode;
  style?: CSSProperties;
  arrowStyle?: CSSProperties;
  dataTestId?: string;
}

function Tooltip({
  position,
  children,
  style,
  arrowStyle,
  isButton,
  dataTestId,
}: TooltipProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <span className={classes.tooltipContainer}>
        {!isButton && (
          <BlokkieTypography
            variant="pl700"
            data-test-id={dataTestId}
            style={{
              color: Color.brandColor01,
              borderBottom: `2px solid ${Color.brandColor01}`,
              lineHeight: 1.2,
              marginTop: blokkieTheme.spacing(0.5),
              display: 'flex',
              alignSelf: 'flex-start',
            }}
          >
            {t('whatIsThis')}
          </BlokkieTypography>
        )}
        {isButton && <div className={classes.questionIcon}>?</div>}
        <div
          style={style}
          className={[
            classes.tooltip,
            position === top && classes.top,
            isButton && classes.buttonTop,
          ].join(' ')}
        >
          {children}
          <span
            style={arrowStyle}
            className={[position === top && classes.arrowTop].join(' ')}
          />
        </div>
      </span>
    </div>
  );
}

const useStyles = createUseStyles({
  tooltipContainer: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'left',
    '&:hover $tooltip': {
      display: 'block',
    },
    '&:hover $questionIcon': {
      backgroundColor: Color.brandColor01,
      color: blokkieTheme.colors.grey.white,
    },
    cursor: 'pointer',
  },
  top: {
    bottom: 'calc(8px + 100%)',
    transform: 'translateX(50%)',
    right: '50%',
  },
  buttonTop: {
    bottom: 'calc(10px + 100%)',
  },
  tooltip: {
    minWidth: '350px',
    padding: blokkieTheme.spacing(3),
    color: '#444444',
    backgroundColor: blokkieTheme.colors.grey.white,
    fontWeight: 'normal',
    fontSize: '13px',
    borderRadius: '8px',
    position: 'absolute',
    zIndex: 999,
    boxSizing: 'border-box',
    boxShadow: '0px 7px 28px 0px #00000033',
    display: 'none',
  },
  arrowTop: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-12px',
    width: '24px',
    height: '12px',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '12px',
      height: '12px',
      left: '50%',
      transform: 'translate(-50%,-50%) rotate(45deg)',
      backgroundColor: blokkieTheme.colors.grey.white,
      boxShadow: '0px 7px 28px 0px #00000033',
    },
  },
  questionIcon: {
    display: 'inline-block',
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    borderRadius: '16px',
    color: Color.brandColor01,
    backgroundColor: 'rgba(77,182,172,0.2)',
    fontFamily: 'MuseoSansRounded700',
    fontSize: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  },
});

export default Tooltip;

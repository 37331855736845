import React from 'react';
import moment from 'moment';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { Wrapper, OnboardingParagraph } from '../styledComponents';
import OnboardingDatePicker from '../Shared/OnboardingDatePicker';
import { Space } from '../../styleguide';
import OnboardingTextBalloon from '../Shared/OnboardingTextBalloon';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface BirthdateProps {
  birthDate: string;
  setBirthDate(): void;
  submit(): void;
  ubo?: boolean;
  onClickBack(): void;
  uboFullName?: string;
}

function BirthDate({
  birthDate,
  setBirthDate,
  submit,
  ubo,
  onClickBack,
  uboFullName,
}: BirthdateProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  useOnboardingPageView('birthdate');

  const lengthCheck = (bD: string): boolean => bD.length !== 10;
  const isAdultCheck = (bD: string): boolean =>
    moment(bD).isValid() && moment().diff(bD, 'years') < 18;

  const disableButton = (): boolean =>
    lengthCheck(birthDate) || isAdultCheck(birthDate);

  return (
    <Wrapper>
      {ubo && (
        <>
          <NavigationHeader clickLeft={onClickBack as () => void} />
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.dark,
            }}
            variant="pl700"
          >
            {t('onboarding.birthdate.ubo.header')}
          </BlokkieTypography>
          <Title>{`${t(
            `onboarding.birthdate.ubo.title`,
          )} ${uboFullName}?`}</Title>
        </>
      )}
      {!ubo && (
        <>
          <div className={classes.header} />
          <Title>{t(`onboarding.birthdate.title`)}</Title>
        </>
      )}

      <OnboardingDatePicker
        date={birthDate}
        setDate={setBirthDate}
        readyToSend={!disableButton()}
        submit={submit}
        data-test-id="Onboarding-BirthDate-Input"
        placeholder={t(`onboarding.birthdate.placeholder`)}
      />

      <OnboardingTextBalloon
        show={isAdultCheck(birthDate)}
        error
        width={isMobileOnly ? 286 : 366}
        data-test-id="Onboarding-BirthDate-Check"
        marginTop={`${blokkieTheme.spacing(2)}px`}
      >
        <BlokkieTypography variant="ps500">
          {t(`onboarding.birthdate.error`)}
        </BlokkieTypography>
        <OnboardingParagraph small marginBottom={Space.s0} />
      </OnboardingTextBalloon>

      <BlokkieButton
        variant="primary"
        size="large"
        data-test-id="Onboarding-BirthDate-NextButton"
        onClick={submit}
        disabled={disableButton()}
        style={{
          width: '163px',
          alignSelf: 'flex-end',
          marginTop: 'auto',
        }}
      >
        {t('next')}
      </BlokkieButton>
    </Wrapper>
  );
}

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    marginLeft: -blokkieTheme.spacing(2),
    marginRight: -blokkieTheme.spacing(2),
    marginBottom: `${blokkieTheme.spacing(4)}px`,
  },
});

export default BirthDate;

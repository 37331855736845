import { fromJS } from 'immutable';

import {
  LOAD_CAMPAIGNS,
  LOAD_CAMPAIGNS_SUCCESS,
  LOAD_CAMPAIGNS_ERROR,
} from './constants';

const initialState = fromJS({
  campaigns: [],
  campaignsIsLoading: false,
  campaignsErrorMessage: null,
});

export default function cashbackCampaignsContainerReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_CAMPAIGNS:
      return state
        .set('campaignsIsLoading', true)
        .set('campaignsErrorMessage', null)
        .set('campaigns', fromJS([]));
    case LOAD_CAMPAIGNS_SUCCESS:
      return state
        .set('campaignsIsLoading', false)
        .set('campaignsErrorMessage', null)
        .set('campaigns', fromJS(action.payload.campaigns));
    case LOAD_CAMPAIGNS_ERROR:
      return state
        .set('campaignsIsLoading', false)
        .set('campaignsErrorMessage', action.payload.error)
        .set('bulkFiles', fromJS([]));
    default:
      return state;
  }
}

export { initialState };

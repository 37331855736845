/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  blokkieTheme,
  BlokkieTypography,
  BlokkieButton,
} from '@tikkie/blokkie';
import '!file-loader?name=[name].[ext]!../../../images/abn-app.svg';
import { CenterWrapper } from '../styledComponents';
import { createTransaction } from '../../../containers/OnboardingKYCContainer/actions';
import {
  selectIdvStartUrl,
  selectIdvStatus,
  selectIsSecondApplicant,
} from '../../../containers/OnboardingKYCContainer/selectors';
import Loader from '../Loader';
import { MOBILE } from './constants';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IProps {
  createTransaction: (param: boolean) => void;
  idvStartUrl: string;
  prevStep: () => void;
  forSecondApplicant: boolean;
}

function IDVOpenAppMobile(props: IProps): JSX.Element {
  const { t } = useTranslation();
  const { prevStep, idvStartUrl, forSecondApplicant } = props;
  const createTransactionForApplicant = (): void =>
    // eslint-disable-next-line react/destructuring-assignment
    props.createTransaction(forSecondApplicant);

  const isLoading = (): boolean => isEmpty(idvStartUrl);
  const openApp = (): void => {
    window.location.assign(idvStartUrl);
  };
  useOnboardingPageView('idv-open-app-mobile');

  useEffect(
    () => {
      if (isEmpty(idvStartUrl)) {
        createTransactionForApplicant();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <NavigationHeader clickLeft={prevStep as () => void} noMarginBottom />
      <CenterWrapper>
        {isLoading() && <Loader />}
        {!isLoading() && (
          <>
            <img src="/abn-app.svg" alt="" />

            <Title textAlign="center">
              {t(`onboarding.idv.openApp.titleMobile`)}
            </Title>

            <BlokkieButton
              variant="primary"
              size="large"
              onClick={openApp}
              data-test-id="Onboarding-IDV-Intall-App-NextButton"
              style={{
                marginTop: blokkieTheme.spacing(2),
              }}
            >
              {t(`onboarding.idv.openApp.continue`)}
            </BlokkieButton>
          </>
        )}
      </CenterWrapper>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: { type: any; forSecondApplicant: any };
  }) => any,
) => ({
  createTransaction: (forSecondApplicant: any) =>
    dispatch(createTransaction(MOBILE, forSecondApplicant)),
});

const mapStateToProps = createStructuredSelector({
  idvStartUrl: selectIdvStartUrl,
  idvStatus: selectIdvStatus,
  forSecondApplicant: selectIsSecondApplicant,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(IDVOpenAppMobile);

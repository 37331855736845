import React from 'react';
import '!file-loader?name=[name].[ext]!../../../images/gifs/succes.gif';

import {
  CenterWrapper,
  Header,
  Label,
  OnboardingImage,
  OnboardingParagraph,
  OnboardingPrimaryButton,
} from '../styledComponents';
import messages from './messages';
import { Space } from '../../styleguide';
import { useOnboardingPageView } from '../../../hooks';

interface PaymentDoneProps {
  next: () => void;
}

function PaymentDone({ next }: PaymentDoneProps): JSX.Element {
  useOnboardingPageView('payment-done');

  return (
    <CenterWrapper>
      <Header />
      <OnboardingImage src="/succes.gif" alt="" />
      <Label>{messages.title.defaultMessage}</Label>
      <OnboardingParagraph narrow textAlign="center" marginBottom={Space.s24}>
        {messages.paragraph.defaultMessage}
      </OnboardingParagraph>
      <OnboardingPrimaryButton
        onClick={next}
        data-test-id="Onboarding-Payment-PaymentDoneButton"
      >
        {messages.button.defaultMessage}
      </OnboardingPrimaryButton>
    </CenterWrapper>
  );
}

export default PaymentDone;

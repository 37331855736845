/**
 *
 * EmptyLoader
 *
 */

import React from 'react';

function EmptyLoader() {
  return <div />;
}

EmptyLoader.propTypes = {};

export default EmptyLoader;

import { blokkieTheme } from '@tikkie/blokkie';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import PasswordHideOrShowIcon from './PasswordHideOrShowIcon';

interface IProps extends React.ComponentPropsWithoutRef<'input'> {
  inputField?: boolean;
  noAutocomplete?: boolean;
}

function Password(props: IProps): JSX.Element {
  const { inputField, noAutocomplete } = props;
  const [inputType, setInputType] = useState<string>('password' || 'text');
  const classes = useStyles();

  return (
    <div className={classes.passwordWrapper}>
      <input
        {...props}
        className={inputField ? classes.inputField : classes.input}
        style={{ letterSpacing: inputType === 'password' ? '4px' : 'normal' }}
        type={inputType}
        autoComplete={noAutocomplete ? 'off' : 'on'}
      />
      <PasswordHideOrShowIcon
        setInputType={setInputType}
        inputType={inputType}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  passwordWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  input: {
    width: '100%',
    height: '50px',
    lineHeight: 'normal',
    padding: '14px',
    objectFit: 'contain',
    borderRadius: '10px',
    backgroundColor: `${blokkieTheme.colors.grey.white}`,
    border: `solid 2px ${blokkieTheme.colors.grey.veryLight}`,
    outline: 'none',
    fontSize: '18px',
    color: 'rgb(64, 196, 184)',
    caretColor: `${blokkieTheme.colors.primary.green}`,
    fontFamily: 'MuseoSansRounded700',
    '&::placeholder': {
      marginLeft: '8px',
      fontSize: '18px',
      color: `${blokkieTheme.colors.grey.default}`,
      letterSpacing: 'normal',
    },
    '&:focus': {
      border: 'solid 2px transparent',
      boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.1)',
    },
    '&:hover': {
      border: 'solid 2px transparent',
      boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.1)',
    },
  },
  inputField: {
    float: 'left',
    width: '100%',
    height: '48px',
    padding: '8px 16px',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSansRounded700, Arial, Helvetica, sans-serif',
    fontSize: '16px',
    color: `${blokkieTheme.colors.primary.green}`,
    fillColor: `${blokkieTheme.colors.primary.green}`,
    outline: 'none',
    borderRadius: '10px',
    border: 'solid 2px rgba(218, 218, 218, 0.5)',
    letterSpacing: '4px',

    '&::placeholder': {
      color: `${blokkieTheme.colors.grey.default}`,
      fillColor: `${blokkieTheme.colors.grey.default}`,
    },

    '&:disabled': {
      backgroundColor: `${blokkieTheme.colors.grey.veryLight}`,

      [blokkieTheme.mediaQueries.allPhone]: {
        opacity: '1',
      },
    },
  },
});

export default Password;

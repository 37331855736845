import React from 'react';
import { Container } from 'amber';
import { GiphyBox } from '../../components';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';

export function EmailChangeError() {
  return (
    <Container mt={60}>
      <GiphyBox
        gif="oops2.gif"
        title="Niet gelukt!"
        text="Er is iets misgegaan en we hebben je e-mailadres niet kunnen wijzigen. Probeer het opnieuw of vraag het ons supportteam."
        button="Mailtje sturen"
        onClick={() => {
          window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
        }}
      />
    </Container>
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router/immutable';
import Cookies from 'universal-cookie';
import request from '../../utils/request';

import {
  BACKGROUND_LOGOUT,
  CONFIRM_EMAIL_CHANGE,
  LOAD_USER,
  SWITCH_ORGANISATION,
  UPDATE_USER,
} from './constants';
import { UPDATE_SETTINGS_SUCCESS } from '../SettingsContainer/constants';
import {
  confirmEmailChangeError,
  confirmEmailChangeSuccess,
  loadUser,
  loadUserError,
  loadUserSuccess,
  updateUserError,
  updateUserSuccess,
} from './actions';

import config from '../../config';

export default function* watcherSaga() {
  yield takeLatest(BACKGROUND_LOGOUT, backGroundLogoutSaga);
  yield takeLatest(LOAD_USER, loadUserSaga);
  yield takeLatest(UPDATE_SETTINGS_SUCCESS, loadUserSaga);
  yield takeLatest(UPDATE_USER, updateUserSaga);
  yield takeLatest(LOCATION_CHANGE, windowScrollSaga);
  yield takeLatest(SWITCH_ORGANISATION, switchOrganisationSaga);
  yield takeLatest(CONFIRM_EMAIL_CHANGE, confirmEmailChangeSaga);
  yield takeLatest(LOCATION_CHANGE, windowScrollSaga);
}

export function* backGroundLogoutSaga() {
  const requestUrl = `${config.API_URL}/logout`;

  yield call(request, requestUrl, {
    credentials: 'include',
  });
}

export function* loadUserSaga() {
  const requestUrl = `${config.API_URL}/me`;
  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadUserSuccess(response));
    yield put(push(window.location.pathname));
  } catch (err) {
    yield put(loadUserError(err.message));
    yield put(push('/login'));
  }
}

export function* updateUserSaga(action) {
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/me`;
  const body = action.payload.user;
  const { doEmailUpdate } = action.payload;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
    });
    yield put(updateUserSuccess(response, doEmailUpdate));
  } catch (err) {
    const response = yield call([err.response, 'json']);
    yield put(updateUserError(response));
  }
}

export function* switchOrganisationSaga(action) {
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/switchOrganisation`;
  const body = {
    id: action.payload.id,
  };

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
    });
    yield put(loadUserSuccess(response));
    yield put(push('/'));
  } catch (err) {
    yield put(loadUser());
  }
}

export function* confirmEmailChangeSaga(action) {
  const requestUrl = `${config.API_URL}/confirm-email-change`;
  const body = {
    confirmationToken: action.payload.token,
    password: action.payload.password,
  };

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json',
      },
    });
    yield put(confirmEmailChangeSuccess());
  } catch (err) {
    yield put(confirmEmailChangeError());
  }
}

/*
 * Scroll to top on every location change which is dispatched by the redux-route, so that
 * it always shows the top of the page.
 */
export function* windowScrollSaga() {
  yield call(window.scrollTo, 0, 0);
}

import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import React from 'react';
import { injectIntl } from 'react-intl';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import ButtonFooter from '../components/ButtonFooter';
import { CenterWrapper } from '../styledComponents';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/thinking-tom-hanks.mp4';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface TransitionScreenProps {
  nextStep: () => void;
}

function TransitionScreen({ nextStep }: TransitionScreenProps): JSX.Element {
  useOnboardingPageView('transition');
  return (
    <CenterWrapper>
      <BlokkieGifContainer
        source="../thinking-tom-hanks.mp4"
        style={{ margin: `${blokkieTheme.spacing(2)}px 0` }}
      />
      <Title textAlign="center">
        {t('onboarding.2ndApplicantDetails.start.title')}
      </Title>
      <BlokkieTypography
        style={{
          color: blokkieTheme.colors.grey.veryDark,
          textAlign: 'center',
          marginTop: blokkieTheme.spacing(1),
        }}
        variant="pl500"
      >
        {t('onboarding.2ndApplicantDetails.start.description')}
      </BlokkieTypography>
      <ButtonFooter forceAlign="center">
        <BlokkieButton
          variant="primary"
          size="large"
          onClick={nextStep}
          data-test-id="Onboarding-TransitionScreen-GoNextButton"
        >
          {t('next')}
        </BlokkieButton>
      </ButtonFooter>
    </CenterWrapper>
  );
}

export default injectIntl(TransitionScreen);

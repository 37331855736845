import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import moment from 'moment';

import request from '../../../utils/request';
import { CREATE_TIKKIE } from './constants';
import config from '../../../config';
import { createTikkieError, createTikkieSuccess } from './actions';

export default function* watcherSaga() {
  yield takeLatest(CREATE_TIKKIE, createTikkieSaga);
}

export function* createTikkieSaga(action) {
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/paymentrequest`;
  const amount = action.payload.isOpenTikkie ? null : action.payload.amount;

  const body = {
    amountInCents: amount,
    openAmount: action.payload.isOpenTikkie,
    currency: 'EUR',
    channel: 'PORTAL',
    referenceId: action.payload.invoiceNumber,
    descriptionBankStatement: action.payload.description,
    descriptionSms: '',
    descriptionPaymentPage: action.payload.description,
    expiry: moment(action.payload.expiryDate).format('YYYY-MM-DD'),
    data: {},
  };

  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    yield put(createTikkieSuccess(response));
  } catch (err) {
    yield put(createTikkieError(err.response.status, err.message));
  }
}

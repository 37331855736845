import { takeLatest, call, put } from 'redux-saga/effects';
import { LOAD_TRANSACTION } from './constants';
import { loadTransactionSuccess, loadTransactionError } from './actions';
import config from '../../../config';
import request from '../../../utils/request';

export default function* watcherSaga() {
  yield takeLatest(LOAD_TRANSACTION, loadTransactionSaga);
}

export function* loadTransactionSaga(action) {
  const { transactionToken } = action.payload;

  const requestUrl = `${config.API_URL}/transaction/${transactionToken}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadTransactionSuccess(response));
  } catch (err) {
    yield put(loadTransactionError(err.message));
  }
}

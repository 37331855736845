import React from 'react';
import messages from './messages';
import { OnboardingParagraph } from '../styledComponents';
import { Space } from '../../styleguide';
import Screen from './Screen';

const getPostfix = (i: number, totalItems: number): string => {
  const itemInArray = i + 1;
  if (itemInArray === totalItems) {
    return '.';
  }
  if (itemInArray === totalItems - 1) {
    return messages.validationErrorGlue.defaultMessage || '';
  }
  return ', ';
};

interface ReferProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  organisationName: string;
  legalEntity: string;
  becomeCustomer: () => void;
}

function Refer({
  error,
  organisationName,
  legalEntity,
  becomeCustomer,
}: ReferProps): JSX.Element {
  return (
    <Screen
      header={messages.validationErrorHeader.defaultMessage || ''}
      buttonText={messages.becomeCustomerButtonText.defaultMessage}
      buttonClick={becomeCustomer}
    >
      <OnboardingParagraph
        marginTop={Space.s0}
        marginBottom={Space.s8}
        textAlign="center"
      >
        {messages.validationErrorIntro.defaultMessage}
        <strong>{`${organisationName} `}</strong>
        {error.validationErrors &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error.validationErrors.map((vE: any, i: number) => (
            <span key={vE.code}>
              {vE.code === 8 && (
                <span>
                  {messages.validationError8PreFix.defaultMessage}{' '}
                  <strong>{legalEntity.toLowerCase()}</strong>
                </span>
              )}
              {/* @ts-expect-error typescript cannot guarantee that the string will exist in the messages */}
              {`${messages[`validationError${vE.code}`].defaultMessage}`}
              {getPostfix(i, error.validationErrors.length)}
            </span>
          ))}
      </OnboardingParagraph>
      <OnboardingParagraph
        marginTop={Space.s0}
        marginBottom={Space.s24}
        textAlign="center"
      >
        <strong>{messages.validationErrorTipPreFix.defaultMessage}</strong>
        {messages.validationErrorTip.defaultMessage} <br />
      </OnboardingParagraph>
    </Screen>
  );
}

export default Refer;

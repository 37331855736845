import { call, put, takeEvery } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';
import request from '../../utils/request';

import {
  LOGIN_USER,
  RESET_PASSWORD,
  SEND_RESET_LINK,
  VALIDATE_PASSWORD_RESET_TOKEN,
  VALIDATE_REUSED_PASSWORD,
  VALIDATE_TOKEN,
  SET_PASSWORD,
  RESEND_PASSWORD_SET_LINK,
} from './constants';
import {
  loginBlockedUser,
  loginExpiredOtpCode,
  loginGenericError,
  loginInvalidCredentials,
  loginInvalidOtpCode,
  loginSmsSendFailed,
  loginTooFreshOtpCode,
  loginUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  sendResetLinkError,
  sendResetLinkSuccess,
  validatePasswordResetTokenError,
  validatePasswordResetTokenSuccess,
  validateReusedPasswordError,
  validateReusedPasswordSuccess,
  loginUser,
  loginOnboardingKycUserSuccess,
  sendResetLink,
  validateTokenError,
  validateTokenSuccess,
  setPasswordError,
  setPasswordSuccess,
  resendPasswordSetLinkError,
  resendPasswordSetLinkSuccess,
} from './actions';
import config from '../../config';

export default function* watcherSaga() {
  yield takeEvery(LOGIN_USER, loginUserSaga);
  yield takeEvery(SEND_RESET_LINK, sendResetLinkSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(
    VALIDATE_PASSWORD_RESET_TOKEN,
    validatePasswordResetTokenSaga,
  );
  yield takeEvery(VALIDATE_REUSED_PASSWORD, validateReusedPasswordSaga);
  yield takeEvery(VALIDATE_TOKEN, validateTokenSaga);
  yield takeEvery(SET_PASSWORD, setPasswordSaga);
  yield takeEvery(RESEND_PASSWORD_SET_LINK, resendPasswordSetLinkSaga);
}

export function* loginUserSaga(action) {
  const requestUrl = action.payload.url
    ? action.payload.url
    : `${config.API_URL}/login`;
  const { username, password, otp, rememberMe } = action.payload;
  const cookies = new Cookies();
  const onboardingLoginPath = '/onboarding-kyc?loggedIn=true';

  try {
    const formData = {
      username,
      password,
      otp,
    };
    let formBody = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in formData) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(formData[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    formBody = formBody.join('&');

    const res = yield call(request, requestUrl, {
      method: 'POST',
      body: formBody,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });

    if (action.payload.url === `${config.ONBOARDING_SERVICE_URL}/login`) {
      yield put(loginOnboardingKycUserSuccess(res, otp, rememberMe));
      yield put(push(onboardingLoginPath));
    } else {
      yield put(loginUserSuccess(otp, rememberMe));
      window.location.reload();
    }
  } catch (err) {
    // this can happen on iOS 10. 302 redirects throw an exception but return
    // status 200 or 404. See https://discussions.apple.com/thread/5531657
    if (!!err.response && err.response.redirected) {
      yield put(loginUserSuccess(otp, rememberMe));
      window.location.reload();
      return;
    }

    // this can happen EDGE. 302 redirects results in an exception without a
    // response.
    if (!err.response) {
      if (action.payload.url === `${config.ONBOARDING_SERVICE_URL}/login`) {
        yield put(loginOnboardingKycUserSuccess({}, otp, rememberMe));
        yield put(push(onboardingLoginPath));
      } else {
        yield put(loginUserSuccess(otp, rememberMe));
        window.location.reload();
      }
    }

    switch (err.response.status) {
      case 200:
      case 302:
      case 404:
        // this can happen on iOS 10. 302 redirects throw an exception but return
        // status 200 or 404. See https://discussions.apple.com/thread/5531657
        if (action.payload.url === `${config.ONBOARDING_SERVICE_URL}/login`) {
          yield put(
            loginOnboardingKycUserSuccess(
              yield err.response.json(),
              otp,
              rememberMe,
            ),
          );
          yield put(push(onboardingLoginPath));
        } else {
          yield put(loginUserSuccess(otp, rememberMe));
          window.location.reload();
        }
        break;
      case 401:
        yield put(loginInvalidOtpCode());
        break;
      case 403:
        if (action.payload.url === `${config.ONBOARDING_SERVICE_URL}/login`) {
          yield put(loginInvalidCredentials());
        } else {
          yield put(
            loginUser(
              username,
              password,
              otp,
              rememberMe,
              '',
              `${config.ONBOARDING_SERVICE_URL}/login`,
            ),
          );
        }
        break;
      case 406:
        yield put(loginExpiredOtpCode());
        break;
      case 409:
        yield put(loginTooFreshOtpCode());
        break;
      case 423:
        yield put(loginBlockedUser());
        break;
      case 424:
        yield put(loginSmsSendFailed());
        break;
      default:
        yield put(loginGenericError(err));
        break;
    }
  }
}

export function* sendResetLinkSaga(action) {
  const cookies = new Cookies();
  const { email, url } = action.payload;
  const requestUrl = url || `${config.API_URL}/requestResetLink`;
  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    if (url === `${config.ONBOARDING_SERVICE_URL}/sendresetlink`) {
      yield put(sendResetLinkSuccess());
    } else {
      yield put(
        sendResetLink(email, `${config.ONBOARDING_SERVICE_URL}/sendresetlink`),
      );
    }
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      if (url === `${config.ONBOARDING_SERVICE_URL}/sendresetlink`) {
        yield put(sendResetLinkSuccess());
      } else {
        yield put(
          sendResetLink(
            email,
            `${config.ONBOARDING_SERVICE_URL}/sendresetlink`,
          ),
        );
      }
    } else {
      yield put(sendResetLinkError(err));
    }
  }
}

export function* resetPasswordSaga(action) {
  const cookies = new Cookies();
  const url = `${config.API_URL}/resetPassword`;
  const { password, resetToken } = action.payload;

  try {
    yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({
        password,
        resetToken,
      }),
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(resetPasswordSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordError(err));
    }
  }
}

export function* validatePasswordResetTokenSaga(action) {
  const { token } = action.payload;
  // TODO token should be NOT be a request parameter. Should be removed after fix in API.
  const url = `${config.API_URL}/checkToken?token=${token}`;

  try {
    yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({
        token,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    yield put(validatePasswordResetTokenSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(validatePasswordResetTokenSuccess());
    } else {
      yield put(validatePasswordResetTokenError(err.response.status, err));
    }
  }
}

export function* validateReusedPasswordSaga(action) {
  const url = `${config.API_URL}/checkOldPassword`;
  const { password, resetToken } = action.payload;

  try {
    yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({
        password,
        resetToken,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    yield put(validateReusedPasswordSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(validateReusedPasswordSuccess());
    } else {
      yield put(validateReusedPasswordError(err));
    }
  }
}

export function* validateTokenSaga(action) {
  const { token } = action.payload;
  const url = `${config.API_URL}/password-token-validate`;

  try {
    const response = yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({
        passwordToken: token,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    yield put(validateTokenSuccess(response));
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(validateTokenSuccess());
    } else {
      yield put(validateTokenError(err.response.status, err));
    }
  }
}

export function* setPasswordSaga(action) {
  const cookies = new Cookies();
  const url = `${config.API_URL}/password-set`;
  const { password, token } = action.payload;

  try {
    yield call(request, url, {
      method: 'POST',
      body: JSON.stringify({
        password,
        passwordToken: token,
      }),
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(setPasswordSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(setPasswordSuccess());
    } else {
      yield put(setPasswordError(err.response.status, err));
    }
  }
}

export function* resendPasswordSetLinkSaga(action) {
  const cookies = new Cookies();
  const { token } = action.payload;
  const requestUrl = `${config.API_URL}/password-token-resend`;
  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        passwordToken: token,
      }),
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(resendPasswordSetLinkSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(resendPasswordSetLinkSuccess());
    } else {
      yield put(resendPasswordSetLinkError(err.response.status, err));
    }
  }
}

/**
 *
 * BulkDetailView
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import { FormattedMessage, injectIntl } from 'react-intl';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import moment from 'moment';

import '!file-loader?name=[name].[ext]!../../../images/downloadicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/uploadicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/fileok.svg';
import '!file-loader?name=[name].[ext]!../../../images/trash.svg';
import '!file-loader?name=[name].[ext]!../../../images/calendar.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/checkicon.svg';

import PaymentRequestLineItem from '../../PaymentRequest/PaymentRequestLineItem';

import messages from './messages';
import { isEmpty, toCurrency } from '../../../utils';

import animationData from '../../../images/animations/green-dots.json';

import {
  Caption,
  CaptionSmall,
  Color,
  Header1,
  Header2,
  MobileWrapper,
  NonMobileWrapper,
  Panel,
  PrimaryButton,
  SecondaryButton,
} from '../../styleguide';
import csvTypes from '../../../utils/csvTypes';

import { BackArrow, BreadCrumb, GiphyBox } from '../../components';
import animationDataWhite from '../../../images/animations/white-dots.json';
import PreviewList from '../PreviewList';

const BulkDetailWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 16px;
  }
`;

const Header = styled(Header1)`
  margin: 27px 0px 16px 8px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

const SubHeader = styled(Header2)`
  margin: 48px 0px 24px 8px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 24px;
  }
`;

const OverviewWrapper = styled(Panel)`
  padding: 32px 32px 8px 32px;
  margin-bottom: 50px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 24px;
  }
`;

const Row = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const Column = styled.div`
  float: right;
  padding: 0 24px;
  width: auto;
  border-left: 2px solid ${Color.gray05};

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    border-left: none;
    padding: 0px 0px 16px 0px;
    margin-bottom: 16px;
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const BigColumn = styled.div`
  width: auto;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    padding-right: 0px;
  }
`;

const Footer = styled(Row)`
  height: 60px;
  line-height: 60px;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    line-height: 32px;
    padding-top: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
    border-top: 2px solid ${Color.gray05};
  }
`;

const Buttons = styled.div`
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 2px solid ${Color.gray05};

  @media ${(props) => props.theme.device.mobile} {
    border-top: none;
    margin-bottom: 16px;
    text-align: center;
  }
`;

const SendButton = styled(PrimaryButton)`
  margin-right: 16px;
`;

const SendingAnimationWrapper = styled.div`
  text-align: center;
  .react-bodymovin-container {
    height: 18px;
  }
`;

const DownloadButton = styled(SecondaryButton)`
  margin-right: 16px;
  border-radius: 24px;
`;

const Text = styled(Header2)`
  margin-top: 14px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    white-space: normal;
    overflow: auto;
    text-overflow: clip;
  }
`;

const ResponsiveText = styled(Text)`
  @media ${(props) => props.theme.device.mobile} {
    font-size: 18px;
  }
`;

const Status = styled.div`
  padding: 24px;
  line-height: 48px;
  font-family: MuseoSansRounded700;
  font-size: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  background-color: ${Color.white};
  color: ${Color.gray01};

  @media ${(props) => props.theme.device.mobile} {
    line-height: 1.5;
  }
`;

const StatusWrapper = styled.div``;

const ContentWrapper = styled.div``;

const ContentHeader = styled(Header2)`
  margin: 40px 0px 32px 8px;
`;

const GifjeContainer = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 400px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    padding: 0 16px;
  }
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const StatusAnimationWrapper = styled.div`
  float: right;

  .react-bodymovin-container {
    height: 22px;
  }

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`;

const SendButtonWrapper = styled.div`
  float: right;

  img {
    vertical-align: text-bottom;
    margin-right: 8px;
  }

  button {
    margin-right: 0;
  }

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin-top: 24px;
    text-align: center;
  }
`;

const MobileCaption = styled(CaptionSmall)`
  display: block;
  height: 24px;
  line-height: 24px;

  img {
    vertical-align: text-bottom;
    margin-right: 4px;
    width: 18px;
  }
`;

const DesktopCaption = styled(CaptionSmall)`
  display: inline-block;
`;

const Error = styled.div`
  padding: 24px;
  margin-top: 24px;
  line-height: 1.4;
  text-align: center;
  font-family: MuseoSansRounded;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  background-color: ${Color.errorColor};
  color: ${Color.white};
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

const Subtitle = styled(Caption)`
  margin-bottom: 14px;
`;

const ErrorDetailWrapper = styled(Panel)`
  position: relative;
  margin-top: 24px;
  box-shadow: none;
  padding: 32px;
`;

const ErrorDetailTable = styled.table`
  padding-left: 32px;
  width: 100%;
`;

const ErrorDetailTableRow = styled.tr``;

const ErrorDetailTableHeader = styled.thead``;

const ErrorDetailTableBody = styled.tbody``;

const ErrorDetailTableHeaderTitle = styled.th`
  padding: 4px;
`;

const ErrorDetailTableData = styled.td`
  padding: 4px;
`;

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

const bodymovinOptionsWhite = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: animationDataWhite,
};

/* eslint-disable react/prefer-stateless-function */
class BulkDetail extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const oopsTitle = intl.formatMessage({
      id: 'app.components.BulkDetail.oopsTitle',
    });

    const oopsText = intl.formatMessage({
      id: 'app.components.BulkDetail.oopsText',
    });

    this.state = {
      oopsTitle,
      oopsText,
      currentPage: 1,
    };
  }

  UNSAFE_componentWillMount() {
    const { bulkFile, loadBulkFile } = this.props;

    if (!isEmpty(bulkFile)) {
      loadBulkFile(bulkFile.id);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const { bulkFile, loadBulkFile, loadPaymentRequests } = this.props;

    const { currentPage } = this.state;

    if (oldProps.isLoadingBulkFile && !this.props.isLoadingBulkFile) {
      if (this.shouldUsePaymentRequests()) {
        loadPaymentRequests(currentPage, '', 0, 0, [], '', bulkFile.id);
      }
    }

    // if the paging changed, load the next page
    if (oldState.currentPage !== currentPage) {
      loadPaymentRequests(currentPage, '', 0, 0, [], '', bulkFile.id);
    }

    // Reload bulk file if send sms was clicked to update the status
    if (oldProps.isSendingBulkUpload && !this.props.isSendingBulkUpload) {
      loadBulkFile(bulkFile.id);
    }
  }

  shouldUsePaymentRequests = () => !this.shouldShowPreviews();

  shouldShowPreviews = () => this.isReady();

  onChangePaging = (currentPage) => this.setState({ currentPage });

  onSendBulk = () => {
    const { bulkFile, sendBulkUpload } = this.props;
    sendBulkUpload(bulkFile.id);

    // This fixes a timing issue with a flikering interface.
    this.props.bulkFile.status = 'SENDING';
  };

  showPaging = () =>
    !this.shouldShowPreviews() && this.props.paymentRequests.totalElements > 20;

  canNotSend = () => this.hasError() || this.hasValidationError();

  hasError = () => this.props.bulkFile.status === 'ERROR';

  hasValidationError = () =>
    this.props.sendBulkUploadErrorValidationDetails != null;

  isReady = () => this.props.bulkFile.status === 'READY';

  isSending = () => this.props.bulkFile.status === 'SENDING';

  isSent = () => this.props.bulkFile.status === 'SENT';

  hasPayments = () =>
    this.props.bulkFile.paymentRequestBatchPaymentInformation
      .totalTikkiesPaidAtLeastOnce > 0;

  render() {
    const {
      bulkFile,
      bulkFileErrorMessage,
      paymentRequests,
      paymentRequestsErrorMessage,
      paymentRequestsIsLoading,
      showBulkList,
      downloadOption,
      sendBulkUploadErrorValidationDetails,
    } = this.props;
    const { oopsTitle, oopsText } = this.state;

    return (
      <BulkDetailWrapper>
        <MobileWrapper>
          <BackArrow onClick={showBulkList} />
        </MobileWrapper>
        <NonMobileWrapper>
          <FormattedMessage {...messages.back}>
            {(placeholder) => (
              <BreadCrumb
                id="backToBulkList"
                start={placeholder}
                onStart={showBulkList}
                text={bulkFile.fileName}
              />
            )}
          </FormattedMessage>
        </NonMobileWrapper>
        <Header>
          {!this.isSending() && <FormattedMessage {...messages.header} />}
          {this.isSending() && <FormattedMessage {...messages.createdHeader} />}
        </Header>
        {!!bulkFile.paymentRequestBatchPaymentInformation && (
          <OverviewWrapper>
            <Row>
              <Column>
                <CaptionSmall>
                  <FormattedMessage {...messages.labelTotal} />
                </CaptionSmall>
                {!!bulkFile.paymentRequestBatchPaymentInformation
                  .totalPaidAmountInCents && (
                  <ResponsiveText>
                    {toCurrency(
                      bulkFile.paymentRequestBatchPaymentInformation
                        .totalPaidAmountInCents,
                    )}
                  </ResponsiveText>
                )}
                {!bulkFile.paymentRequestBatchPaymentInformation
                  .totalPaidAmountInCents && (
                  <ResponsiveText>{toCurrency(0)}</ResponsiveText>
                )}
              </Column>
              <Column>
                <CaptionSmall>
                  <FormattedMessage {...messages.labelTikkiesPaid} />
                </CaptionSmall>
                <ResponsiveText>
                  {
                    bulkFile.paymentRequestBatchPaymentInformation
                      .totalTikkiesPaidAtLeastOnce
                  }
                  /
                  {
                    bulkFile.paymentRequestBatchPaymentInformation
                      .totalTikkiesInBatch
                  }
                </ResponsiveText>
              </Column>
              <BigColumn>
                <CaptionSmall>
                  <FormattedMessage {...messages.labelTitle} />
                </CaptionSmall>
                <Text>{bulkFile.fileName}</Text>
              </BigColumn>
            </Row>
            <Footer>
              <NonMobileWrapper>
                <DesktopCaption>
                  <FormattedMessage {...messages.labelCreated} />{' '}
                  {moment(bulkFile.uploadedDateTime).format(
                    'D MMM YYYY, HH:mm',
                  )}{' '}
                  uur
                </DesktopCaption>
              </NonMobileWrapper>
              <MobileWrapper>
                <MobileCaption>
                  <FormattedMessage {...messages.labelCreated} />{' '}
                  {moment(bulkFile.uploadedDateTime).format("D MMM 'YY, HH:mm")}{' '}
                  uur
                </MobileCaption>
              </MobileWrapper>
            </Footer>
            {this.isSent() && (
              <NonMobileWrapper>
                <Buttons>
                  {downloadOption && (
                    <>
                      <DownloadButton
                        disabled={!this.hasPayments()}
                        onClick={() => {
                          this.props.downloadMultiplePaymentRequests(
                            '',
                            0,
                            0,
                            [],
                            '',
                            '',
                            [],
                            csvTypes.payment,
                            bulkFile.id,
                          );
                        }}
                      >
                        <img src="/downloadicon.svg" alt="" />
                        <FormattedMessage
                          {...messages.buttonDownloadPayments}
                        />
                      </DownloadButton>
                      <DownloadButton
                        onClick={() => {
                          this.props.downloadMultiplePaymentRequests(
                            '',
                            0,
                            0,
                            [],
                            '',
                            '',
                            [],
                            csvTypes.paymentRequest,
                            bulkFile.id,
                          );
                        }}
                      >
                        <img src="/downloadicon.svg" alt="" />
                        <FormattedMessage
                          {...messages.buttonDownloadPaymentRequests}
                        />
                      </DownloadButton>
                    </>
                  )}
                </Buttons>
              </NonMobileWrapper>
            )}
          </OverviewWrapper>
        )}
        {this.isSending() && (
          <StatusWrapper>
            <SubHeader>
              <FormattedMessage {...messages.status} />
            </SubHeader>
            <Status>
              <FormattedMessage {...messages.sending} />
              <StatusAnimationWrapper>
                <ReactBodymovin options={bodymovinOptions} />
              </StatusAnimationWrapper>
            </Status>
          </StatusWrapper>
        )}
        {(this.isReady() || this.hasError()) && (
          <StatusWrapper>
            <SubHeader>
              <FormattedMessage {...messages.status} />
            </SubHeader>
            <Status>
              <FormattedMessage {...messages.ready} />
              <SendButtonWrapper>
                {!this.props.isSendingBulkUpload && (
                  <SendButton
                    disabled={this.canNotSend()}
                    onClick={this.onSendBulk}
                  >
                    <img alt="" src="/uploadicon-o.svg" />
                    <FormattedMessage {...messages.buttonSend} />
                  </SendButton>
                )}
                {this.props.isSendingBulkUpload && (
                  <SendButton>
                    <SendingAnimationWrapper>
                      <ReactBodymovin options={bodymovinOptionsWhite} />
                    </SendingAnimationWrapper>
                  </SendButton>
                )}
              </SendButtonWrapper>
            </Status>
          </StatusWrapper>
        )}
        {this.hasError() && (
          <Error>
            <FormattedMessage {...messages.sendError} />
          </Error>
        )}
        {this.hasValidationError() && (
          <>
            <Error>
              <FormattedMessage {...messages.validationError} />
            </Error>
            <Header>
              <FormattedMessage {...messages.errorDetailsHeader} />
            </Header>
            <Subtitle>
              <FormattedMessage {...messages.errorDetailsSubtitle} />
            </Subtitle>
            <ErrorDetailWrapper>
              <ErrorDetailTable>
                <ErrorDetailTableHeader>
                  <ErrorDetailTableRow>
                    <ErrorDetailTableHeaderTitle>
                      <FormattedMessage
                        {...messages.errorDetailsTableErrorHeading}
                      />
                    </ErrorDetailTableHeaderTitle>
                    <ErrorDetailTableHeaderTitle>
                      <FormattedMessage
                        {...messages.errorDetailsTableColumnHeading}
                      />
                    </ErrorDetailTableHeaderTitle>
                    <ErrorDetailTableHeaderTitle>
                      <FormattedMessage
                        {...messages.errorDetailsTableRowHeading}
                      />
                    </ErrorDetailTableHeaderTitle>
                  </ErrorDetailTableRow>
                </ErrorDetailTableHeader>
                <ErrorDetailTableBody>
                  {sendBulkUploadErrorValidationDetails.map((bulkErr) => (
                    <ErrorDetailTableRow
                      key={`${bulkErr.errorCode}-${bulkErr.lineNumber}-${bulkErr.columnName}`}
                    >
                      <ErrorDetailTableData>
                        {bulkErr.userMessage}
                      </ErrorDetailTableData>
                      <ErrorDetailTableData>
                        {bulkErr.columnName}
                      </ErrorDetailTableData>
                      <ErrorDetailTableData>
                        {bulkErr.lineNumber}
                      </ErrorDetailTableData>
                    </ErrorDetailTableRow>
                  ))}
                </ErrorDetailTableBody>
              </ErrorDetailTable>
            </ErrorDetailWrapper>
          </>
        )}
        <ContentWrapper>
          {!bulkFileErrorMessage && !this.shouldShowPreviews() && (
            <ContentHeader>
              <FormattedMessage {...messages.contentHeader} /> (
              {paymentRequests.totalElements})
            </ContentHeader>
          )}
          {!!bulkFileErrorMessage && (
            <ContentHeader>
              <FormattedMessage {...messages.contentHeader} /> (-)
            </ContentHeader>
          )}
          {!!paymentRequestsErrorMessage && (
            <GifjeContainer>
              <GifjeWrapper>
                <GiphyBox gif="/oops.gif" title={oopsTitle} text={oopsText} />
              </GifjeWrapper>
            </GifjeContainer>
          )}
          {!this.shouldShowPreviews() &&
            !paymentRequestsIsLoading &&
            !!paymentRequests.paymentEvents &&
            paymentRequests.paymentEvents.map((paymentRequest) => (
              <PaymentRequestLineItem
                key={paymentRequest.id}
                paymentRequest={paymentRequest}
                showTikkieDetail={() =>
                  this.props.showTikkieDetail(paymentRequest)
                }
              />
            ))}
          {this.shouldShowPreviews() &&
            ((!paymentRequestsIsLoading && !!paymentRequests.paymentEvents) ||
              !!bulkFile.paymentRequestPreviews) && (
              <PreviewList
                paymentRequests={paymentRequests}
                bulkFile={bulkFile}
                usePaymentRequests={this.shouldUsePaymentRequests()}
                goToPreview={this.props.showTikkieDetail}
                location={this.props.location}
              />
            )}
          {paymentRequestsIsLoading && (
            <LoaderWrapper>
              <AnimationWrapper>
                <ReactBodymovin options={bodymovinOptions} />
              </AnimationWrapper>
            </LoaderWrapper>
          )}
          {this.showPaging() && (
            <PagingWrapper>
              <Pagination
                onChange={this.onChangePaging}
                defaultCurrent={1}
                current={this.state.currentPage}
                pageSize={20}
                simple={isMobileOnly}
                showLessItems={isTablet}
                total={paymentRequests.totalElements}
                prevIcon={<NavPrev src="/back.svg" />}
                nextIcon={<NavNext src="/back.svg" />}
                showTitle={false}
              />
            </PagingWrapper>
          )}
        </ContentWrapper>
      </BulkDetailWrapper>
    );
  }
}

BulkDetail.propTypes = {
  location: PropTypes.object,
  bulkFile: PropTypes.object.isRequired,
  bulkFileErrorMessage: PropTypes.string,
  loadBulkFile: PropTypes.func.isRequired,
  paymentRequests: PropTypes.object.isRequired,
  paymentRequestsIsLoading: PropTypes.bool.isRequired,
  paymentRequestsErrorMessage: PropTypes.string,
  loadPaymentRequests: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isSendingBulkUpload: PropTypes.bool,
  sendBulkUploadErrorValidationDetails: PropTypes.object,
  sendBulkUpload: PropTypes.func.isRequired,
  showBulkList: PropTypes.func.isRequired,
  showTikkieDetail: PropTypes.func.isRequired,
  downloadMultiplePaymentRequests: PropTypes.func.isRequired,
  downloadOption: PropTypes.bool.isRequired,
  oopsTitle: PropTypes.string,
  oopsText: PropTypes.string,
  isLoadingBulkFile: PropTypes.bool,
};

export default injectIntl(BulkDetail);

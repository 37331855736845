import React from 'react';
import { BlokkieTypography } from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import messages from './messages';
import { Space } from '../../styleguide';
import Screen from './Screen';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';

interface LoginProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  organisationName: string;
  login: () => void;
}

function Login({ error, organisationName, login }: LoginProps): JSX.Element {
  const { t } = useTranslation();

  const displayCorrectErrorMessage = () => {
    if (!error.errorCode) {
      return messages.generalError.defaultMessage;
    }

    if (error.errorCode === 2083) {
      return t('onboarding.searchKvk.alreadyRegistered.body', {
        organisationName: organisationName || '-',
      });
    }
    // @ts-expect-error typescript cannot guarantee that the string will exist in the messages
    return messages[`error${error.errorCode}`].defaultMessage;
  };

  const SendMail = () => {
    window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
  };

  return (
    <Screen
      showGif
      header={t('onboarding.searchKvk.alreadyRegistered.title')}
      buttonText={t('sendMail')}
      buttonClick={SendMail}
      goToLoginScreen={login}
    >
      <BlokkieTypography
        variant="pl500"
        style={{
          textAlign: 'center',
          marginTop: Space.s0,
          marginBottom: Space.s16,
        }}
      >
        {displayCorrectErrorMessage()}
      </BlokkieTypography>
    </Screen>
  );
}

export default Login;

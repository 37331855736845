/*
 *
 * LoginContainer constants
 *
 */

export const LOGIN_USER = 'app/LoginContainer/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'app/LoginContainer/LOGIN_USER_SUCCESS';
export const LOGIN_INVALID_CREDENTIALS =
  'app/LoginContainer/LOGIN_INVALID_CREDENTIALS';
export const LOGIN_INVALID_OTP_CODE =
  'app/LoginContainer/LOGIN_INVALID_OTP_CODE';
export const LOGIN_EXPIRED_OTP_CODE =
  'app/LoginContainer/LOGIN_EXPIRED_OTP_CODE';
export const LOGIN_TOO_FRESH_OTP_CODE =
  'app/LoginContainer/LOGIN_TOO_FRESH_OTP_CODE';
export const LOGIN_BLOCKED_USER = 'app/LoginContainer/LOGIN_BLOCKED_USER';
export const LOGIN_SMS_SEND_FAILED = 'app/LoginContainer/LOGIN_SMS_SEND_FAILED';
export const LOGIN_GENERIC_ERROR = 'app/LoginContainer/LOGIN_GENERIC_ERROR';
export const LOGIN_RESET = 'app/LoginContainer/LOGIN_RESET';
export const SEND_RESET_LINK = 'app/LoginContainer/SEND_RESET_LINK';
export const SEND_RESET_LINK_SUCCESS =
  'app/LoginContainer/SEND_RESET_LINK_SUCCESS';
export const SEND_RESET_LINK_ERROR = 'app/LoginContainer/SEND_RESET_LINK_ERROR';
export const RESET_PASSWORD = 'app/LoginContainer/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS =
  'app/LoginContainer/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/LoginContainer/RESET_PASSWORD_ERROR';
export const VALIDATE_PASSWORD_RESET_TOKEN =
  'app/LoginContainer/VALIDATE_PASSWORD_RESET_TOKEN';
export const VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS =
  'app/LoginContainer/VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS';
export const VALIDATE_PASSWORD_RESET_TOKEN_ERROR =
  'app/LoginContainer/VALIDATE_PASSWORD_RESET_TOKEN_ERROR';
export const VALIDATE_REUSED_PASSWORD =
  'app/LoginContainer/VALIDATE_REUSED_PASSWORD';
export const VALIDATE_REUSED_PASSWORD_SUCCESS =
  'app/LoginContainer/VALIDATE_REUSED_PASSWORD_SUCCESS';
export const VALIDATE_REUSED_PASSWORD_ERROR =
  'app/LoginContainer/VALIDATE_REUSED_PASSWORD_ERROR';
export const OTP = 'app/LoginContainer/OTP';
export const LOGIN_ONBOARDING_KYC_USER_SUCCESS =
  'app/LoginContainer/LOGIN_ONBOARDING_KYC_USER_SUCCESS';
export const VALIDATE_TOKEN = 'app/LoginContainer/VALIDATE_TOKEN';
export const VALIDATE_TOKEN_SUCCESS =
  'app/LoginContainer/VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_ERROR = 'app/LoginContainer/VALIDATE_TOKEN_ERROR';
export const SET_PASSWORD = 'app/LoginContainer/SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'app/LoginContainer/SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'app/LoginContainer/SET_PASSWORD_ERROR';
export const RESEND_PASSWORD_SET_LINK =
  'app/LoginContainer/RESEND_PASSWORD_LINK';
export const RESEND_PASSWORD_SET_LINK_SUCCESS =
  'app/LoginContainer/RESEND_PASSWORD_LINK_SUCCESS';
export const RESEND_PASSWORD_SET_LINK_ERROR =
  'app/LoginContainer/RESEND_PASSWORD_LINK_ERROR';

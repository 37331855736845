import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import config from '../../../config';
import onboardingKYCContainerHOC from '../hoc';
import { entrySteps, entryFlows } from './constants';
import {
  OnboardingEntry,
  SearchBusiness,
} from '../../../components/OnboardingKYC';
import ChooseBank from '../../../components/OnboardingKYC/ChooseBank';
import ProcessExplanation from '../../../components/OnboardingKYC/ProcessExplanation';
import FormWrapperContainer from '../FormWrapperContainer';
import {
  getIssuers,
  resetSearchResult,
  searchBusiness,
  selectBank,
  setCddOnboarding,
  startOrganisationOnboarding,
} from '../actions';
import { ABN_AMRO_BANK_CODE, tikkieCashback, tikkieRecycling } from '../config';
import {
  selectAabOnboarding,
  selectCddOnboarding,
  selectGenericError,
  selectIssuers,
  selectIssuersPending,
  selectKYCSearchResult,
  selectKYCSearchResultError,
  selectKYCSearchResultPending,
  selectOnboardingFlow,
  selectOnboardingKYCData,
  selectOverruleOnlyAbnOption,
  selectProductType,
  selectStartOnboardingPending,
} from '../selectors';
import BankWithAbn from '../../../components/OnboardingKYC/BankWithAbn';
import { OnboardingFlow, OnboardingHash } from '../onboarding-flow';

const determineEntryFlow = (productType, bank) => {
  const bankSet = bank !== '';
  const bankIsAbn = bankSet && bank === ABN_AMRO_BANK_CODE;

  if (
    productType &&
    !(productType === tikkieRecycling || productType === tikkieCashback)
  ) {
    // no product type set, or set and not recycling and not recycling or cashback => Business
    if (bankIsAbn) {
      return entryFlows.abn;
    }
    // Bank not set or not ABN
    return entryFlows.default;
  }
  if (bankIsAbn) {
    return entryFlows.abnWithoutIdeal;
  }
  return entryFlows.defaultWithoutIdeal;
};

function StartOnboardingContainer(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isAabAccountLandingPage = () => {
    const locationSearch = location.search;
    const queryValues = queryString.parse(locationSearch);

    return queryValues.landingPage && queryValues.landingPage === 'aabAccount';
  };

  const onboardingFlow = useSelector(selectOnboardingFlow);
  const [currentStep, setCurrentStep] = useState(entrySteps.agreement);

  const isKvkAlreadyCustomer =
    props.genericError.errorCode === 2087 &&
    props.genericError.validationErrors.some((error) => error.code === '26');

  const isFirstStep = currentStep === entrySteps.agreement;

  const goToLoginScreen = () => history.push('/login');

  if (
    currentStep === entrySteps.chooseBank &&
    isEmpty(props.issuers) &&
    !props.getIssuersPending
  ) {
    props.getIssuers();
  }

  const nextStep = () =>
    setCurrentStep(
      props.getNextStep(
        determineEntryFlow(
          props.onboardingData.productType,
          props.onboardingData.bankCode,
        ),
        currentStep,
      ),
    );

  const previousStep = () =>
    setCurrentStep(
      props.getPreviousStep(
        determineEntryFlow(
          props.onboardingData.productType,
          props.onboardingData.bankCode,
        ),
        currentStep,
      ),
    );

  const canFollowCDD =
    (config.get(config.CDD_FLOW_ENABLED) &&
      location.hash === OnboardingHash.NONE) ||
    (config.get(config.PILOT_ENABLED) &&
      location.hash === OnboardingHash.PILOT);

  const isInvited = [
    OnboardingFlow.RECYCLING,
    OnboardingFlow.CASHBACK,
    OnboardingFlow.INVITE,
  ].includes(onboardingFlow);

  const canContinueWithoutABN =
    canFollowCDD || isInvited || props.aabOnboarding;

  const confirmBank = () => {
    const followCDD =
      canFollowCDD && props.onboardingData.bankCode !== ABN_AMRO_BANK_CODE;
    if (followCDD) {
      dispatch(setCddOnboarding(true));
    } else {
      // case when client goes back after choosing non-ABN, and then chooses ABN
      dispatch(setCddOnboarding(false));
    }
    nextStep();
  };

  return (
    <FormWrapperContainer
      noProgressBar={isFirstStep}
      prevStep={previousStep}
      hasSpecificError={isKvkAlreadyCustomer}
    >
      {currentStep === entrySteps.agreement && (
        <OnboardingEntry
          nextStep={nextStep}
          goToLoginScreen={goToLoginScreen}
          productType={props.productType}
          aabOnboarding={props.aabOnboarding}
          isAabAccountLandingPage={isAabAccountLandingPage()}
        />
      )}
      {currentStep === entrySteps.chooseBank && (
        <ChooseBank
          nextStep={confirmBank}
          previousStep={previousStep}
          selectBank={props.selectBank}
          selectedBank={props.onboardingData.bankCode}
          issuers={props.issuers}
          aabOnboarding={props.aabOnboarding}
          cddEnabled={config.get(config.CDD_FLOW_ENABLED)}
          overruleOnlyAbnOption={canContinueWithoutABN}
        />
      )}
      {currentStep === entrySteps.bankWithAbn && (
        <BankWithAbn
          nextStep={nextStep}
          previousStep={previousStep}
          selectBank={props.selectBank}
        />
      )}
      {currentStep === entrySteps.processExplanation && (
        <ProcessExplanation
          nextStep={nextStep}
          previousStep={previousStep}
          aabOnboarding={props.aabOnboarding}
          productType={props.productType}
        />
      )}
      {currentStep === entrySteps.chooseOrganisation && (
        <SearchBusiness
          searchResultPending={props.searchResultPending}
          searchResult={props.searchResult}
          searchBusiness={props.searchBusiness}
          searchResultError={props.searchResultError}
          resetSearchResult={props.resetSearchResult}
          previousStep={previousStep}
          bankCode={props.onboardingData.bankCode}
          startOrganisationOnboarding={props.startOrganisationOnboarding(
            props.aabOnboarding,
            props.productType,
            props.cddOnboarding,
          )}
          startOnboardingPending={props.startOnboardingPending}
          kvkAlreadyCustomer={isKvkAlreadyCustomer}
          goToLoginScreen={goToLoginScreen}
        />
      )}
    </FormWrapperContainer>
  );
}

StartOnboardingContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  searchResultPending: PropTypes.bool.isRequired,
  searchResultError: PropTypes.object,
  searchResult: PropTypes.object.isRequired,
  searchBusiness: PropTypes.func.isRequired,
  resetSearchResult: PropTypes.func.isRequired,
  startOrganisationOnboarding: PropTypes.func.isRequired,
  startOnboardingPending: PropTypes.bool.isRequired,
  genericError: PropTypes.object.isRequired,
  aabOnboarding: PropTypes.bool.isRequired,
  cddOnboarding: PropTypes.bool.isRequired,
  overruleOnlyAbnOption: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
  onboardingData: PropTypes.object.isRequired,
  getIssuers: PropTypes.func.isRequired,
  getIssuersPending: PropTypes.bool.isRequired,
  issuers: PropTypes.array,
  selectBank: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  searchResultPending: selectKYCSearchResultPending,
  searchResultError: selectKYCSearchResultError,
  searchResult: selectKYCSearchResult,
  onboardingData: selectOnboardingKYCData,
  getIssuersPending: selectIssuersPending,
  issuers: selectIssuers,
  startOnboardingPending: selectStartOnboardingPending,
  genericError: selectGenericError,
  aabOnboarding: selectAabOnboarding,
  cddOnboarding: selectCddOnboarding,
  productType: selectProductType,
  overruleOnlyAbnOption: selectOverruleOnlyAbnOption,
});

const mapDispatchToProps = (dispatch) => ({
  getIssuers: () => dispatch(getIssuers()),
  selectBank: (bank) => dispatch(selectBank(bank)),
  searchBusiness: (keyword) => dispatch(searchBusiness(keyword)),
  resetSearchResult: () => dispatch(resetSearchResult()),
  startOrganisationOnboarding:
    (aabOnboarding, productType, cddOnboarding) => (kvkNumber, bankCode) =>
      dispatch(
        startOrganisationOnboarding({
          kvkNumber,
          bankCode,
          aabOnboarding,
          productType,
          cddOnboarding,
        }),
      ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(StartOnboardingContainer);

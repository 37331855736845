/**
 *
 * CashbackCampaignsComponent
 *
 */

import styled from 'styled-components';
import { Header1 } from '../styleguide';

export const CashbackWrapper = styled.div``;

export const Header = styled(Header1)`
  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';

import { Color } from '../styleguide';
import { isMenuActive } from './shared';
import messages from './messages';
import SwitchOrganisation from './switchOrganisation';
import DropdownMenu from '../Dropdown/DropdownMenu';
import tikkieLogo from '../../images/tikkie-logo.svg';
import emptyLogo from '../../images/empty-logo.png';
import cogwheelOptionMenuIcon from '../../images/menu/cogwheel-option-menu.svg';
import logoutOptionMenuIcon from '../../images/menu/logout-option-menu.svg';
import profileOptionMenuIcom from '../../images/menu/profile-option-menu.svg';
import switchOrganisationOptionMenuIcon from '../../images/menu/switch-organisation-option-menu.svg';

export const MenuWrapper = styled.div`
  background-color: ${Color.brandColor02};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
`;

export const MenuInner = styled.div`
  background-color: ${Color.brandColor02};
  max-width: 1056px;
  height: 80px;
  line-height: 80px;
  margin: 0 auto 24px auto;
  text-align: left;
  user-select: none;
  overflow: visible;
  @media ${(props) => props.theme.device.tablet} {
    width: auto;
    padding: 0px 32px 0px 32px;
  }
  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 64px;
    line-height: 64px;
  }
`;

export const MenuItem = styled(Link)`
  height: 80px;
  display: inline-block;
  margin-right: 50px;
  border-bottom: 4px solid
    ${(props) => (props.active ? '#fafafa' : 'transparent')};
  color: ${(props) =>
    props.active ? Color.white : 'rgba(255, 255, 255, 0.6)'};
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  :hover {
    color: #ffffff;
    border-bottom: 4px solid #fafafa;
  }

  :active,
  :focus {
    outline: none;
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  border: none;
  margin-right: 50px;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 93px;
  object-fit: contain;
`;

const Hamburger = styled.div`
  display: none;
  padding: 0 20px;
  color: #fff;
  text-align: center;
  @media ${(props) => props.theme.device.mobile} {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  float: left;
`;

const HamburgerHeader = styled.span`
  display: inline-block;
  text-align: center;
  user-select: none;
`;

export const AccountWrapper = styled.div`
  float: right;
  font-size: 16px;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  outline: none !important;
  height: 80px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 99px;
    border-bottom: 1px solid rgba(233, 233, 233, 0.3);
    padding: 24px;
  }
`;

export const Account = styled.a`
  display: inline-block;
  border-radius: 24px;
  margin: 15px 0;
  padding-right: 24px;
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  user-select: none;
  white-space: nowrap;
  max-width: 240px;

  i {
    margin-left: 8px;
    position: relative;
    bottom: 18px;

    /* Safari */
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        position: inherit;
      }
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 51px;
    margin: 0;
  }
`;

export const Avatar = styled.img`
  float: left;
  width: 38px;
  height: 38px;
  margin: 6px 15px 6px 6px;
  border: 4px solid #fff;
  border-radius: 19px;
  background-color: #fff;

  @media ${(props) => props.theme.device.tablet} {
    float: none;
    margin: 0;
    cursor: pointer;
  }
`;

export const AccountName = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: 150px;
`;

export const Logout = styled.div`
  cursor: pointer;
  float: right;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.6);
`;

const ItemIconWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  overflow: visible;
  float: right;
  color: ${Color.white};
  cursor: pointer;

  :hover {
    color: ${Color.white};
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const OptionLink = styled(Link)`
  display: block;
  position: relative;
  font-family: MuseoSansRounded;
  line-height: 45px;
  font-size: 16px;
  padding: 0px 24px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 8px;
  text-decoration: none;
  color: ${Color.gray01};

  :focus,
  :hover,
  :visited,
  :link,
  :active {
    text-decoration: none;
  }

  :hover {
    background-color: ${Color.gray06};
  }

  img {
    margin-right: 16px;
  }
`;

const Option = styled.div`
  display: block;
  position: relative;
  font-family: MuseoSansRounded;
  line-height: 45px;
  font-size: 16px;
  padding: 0px 24px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 8px;
  color: ${Color.gray01};

  :hover {
    background-color: ${Color.gray06};
  }

  img {
    margin-right: 16px;
  }
`;

export function Menu({
  turnOffBackgroundScroll,
  turnOnBackgroundScroll,
  loadLinkedOrganisations,
  clearLinkedOrganisations,
  switchOrganisation,
  resetUserUpdate,
  toggleMobileMenu,
  isContactOrAdmin,
  isMobileMenuOpen,
  user,
  logoutUser,
  linkedOrganisations,
  linkedOrganisationsIsLoading,
  linkedOrganisationsLoadError,
}) {
  const [showSwitchOrganisation, setShowSwitchOrganisation] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const hasAtLeastOneOrganisation = !!user?.organisationName;

  useEffect(() => {
    if (showSwitchOrganisation) {
      turnOffBackgroundScroll();
    } else {
      turnOnBackgroundScroll();
      clearLinkedOrganisations();
    }
  }, [showSwitchOrganisation]);

  const toggleShowSwitchOrganisation = () =>
    user.canSwitch ? setShowSwitchOrganisation(!showSwitchOrganisation) : '';

  const getHamburgerHeader = () => {
    if (isMenuActive(['/transactions'])) {
      return <FormattedMessage {...messages.transactions} />;
    } else if (isMenuActive(['/', 'bulk'])) {
      return <FormattedMessage {...messages.paymentRequests} />;
    } else if (isMenuActive(['/settings'])) {
      return <FormattedMessage {...messages.settings} />;
    } else if (isMenuActive(['/settings/organisation'])) {
      return <FormattedMessage {...messages.organisationSettings} />;
    } else if (isMenuActive(['/settings/sms'])) {
      return <FormattedMessage {...messages.smsSettings} />;
    } else if (isMenuActive(['/settings/bulkupload'])) {
      return <FormattedMessage {...messages.bulkSettings} />;
    } else if (isMenuActive(['/settings/api'])) {
      return <FormattedMessage {...messages.apiSettings} />;
    } else if (isMenuActive(['/settings/pages'])) {
      return <FormattedMessage {...messages.pagesSettings} />;
    } else if (isMenuActive(['/tikkieterug'])) {
      return <FormattedMessage {...messages.cashback} />;
    } else if (isMenuActive(['/campaigns'])) {
      return <FormattedMessage {...messages.campaigns} />;
    }

    return '';
  };

  const toggleOptionsMenu = () => {
    setShowOptions(!showOptions);
  };

  return (
    <MenuWrapper id="dashboard">
      <MenuInner>
        <Hamburger>
          <HamburgerIcon onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <i className="fa fa-arrow-left" />
            ) : (
              <i className="fa fa-bars" />
            )}
          </HamburgerIcon>
          <HamburgerHeader>{getHamburgerHeader()}</HamburgerHeader>
          {isMobileOnly && (
            <Logout
              onClick={() => {
                logoutUser();
              }}
            >
              <i className="fa fa-power-off" />
            </Logout>
          )}
        </Hamburger>
        {!isMobileOnly && (
          <LogoLink
            to={
              !!user.cashbackEnabled && hasAtLeastOneOrganisation
                ? '/tikkieterug'
                : '/'
            }
          >
            <Logo src={tikkieLogo} alt="Tikkie" />
          </LogoLink>
        )}
        {!!user.cashbackEnabled && hasAtLeastOneOrganisation && (
          <>
            <MenuItem
              id="cashbackPage"
              to="/tikkieterug"
              active={isMenuActive(['/tikkieterug'])}
            >
              <FormattedMessage {...messages.cashback} />
            </MenuItem>
            <MenuItem
              id="campaignsPage"
              to="/campaigns"
              active={isMenuActive(['/campaigns'])}
            >
              <FormattedMessage {...messages.campaigns} />
            </MenuItem>
          </>
        )}
        {!user.cashbackEnabled && hasAtLeastOneOrganisation && (
          <>
            <MenuItem
              id="transactionPage"
              to="/transactions"
              active={isMenuActive(['/transactions'])}
            >
              <FormattedMessage {...messages.transactions} />
            </MenuItem>
            <MenuItem
              id="tikkiePage"
              to="/"
              active={isMenuActive(['/', '/bulk'])}
            >
              <FormattedMessage {...messages.paymentRequests} />
            </MenuItem>
          </>
        )}
        {!isMobile && (
          <AccountWrapper>
            {hasAtLeastOneOrganisation && (
              <Account id="accountInfo">
                <Avatar src={user.logo || emptyLogo} />
                <AccountName>{user.internalPortalName}</AccountName>
              </Account>
            )}
            <ItemIconWrapper
              id="settingsPage"
              onClick={toggleOptionsMenu}
              data-test-id="menu-options"
            >
              <svg width="22px" height="22px" viewBox="0 0 22 22">
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                  <g
                    transform="translate(-1226.000000, -29.000000)"
                    fillRule="nonzero"
                  >
                    <path
                      d="M1245.40478,41.0779281 C1245.44987,40.7258439 1245.48391,40.373929 1245.48391,39.9999013 C1245.48391,39.6258736 1245.44984,39.2739868 1245.40478,38.9218745 L1247.79093,37.1068768 C1248.00559,36.941877 1248.06239,36.6448492 1247.92649,36.4028777 L1245.6649,32.5968262 C1245.52938,32.3548547 1245.22381,32.2668266 1244.97506,32.3548547 L1242.15918,33.4549661 C1241.57117,33.0148538 1240.93809,32.6518825 1240.24825,32.3769393 L1239.81847,29.4618584 C1239.78439,29.1979715 1239.547,29 1239.26415,29 L1234.74063,29 C1234.45797,29 1234.22058,29.1979715 1234.18651,29.4618584 L1233.75696,32.3769675 C1233.06708,32.6519107 1232.43384,33.0259102 1231.84579,33.4549943 L1229.02992,32.3548829 C1228.76979,32.2559958 1228.4758,32.3548829 1228.34004,32.5968544 L1226.07849,36.4028777 C1225.93139,36.6448492 1225.99918,36.941877 1226.21404,37.1068768 L1228.6002,38.9218745 C1228.55493,39.2739586 1228.52106,39.63693 1228.52106,39.9999013 C1228.52106,40.3628444 1228.55493,40.7258157 1228.6002,41.0779281 L1226.21404,42.8929258 C1225.99918,43.0579538 1225.94275,43.3547842 1226.07849,43.5967556 L1228.34007,47.402779 C1228.4758,47.6447505 1228.78116,47.7327503 1229.02995,47.6447505 L1231.84582,46.5450057 C1232.43384,46.9847795 1233.06711,47.3479201 1233.75699,47.6230325 L1234.18654,50.537775 C1234.22061,50.801831 1234.45803,51 1234.74066,51 L1239.26417,51 C1239.54703,51 1239.78442,50.8018592 1239.81849,50.537775 L1240.24825,47.6230325 C1240.93809,47.3479201 1241.57117,46.9740898 1242.15918,46.5450057 L1244.97506,47.6447505 C1245.23518,47.7438349 1245.52938,47.6447505 1245.6649,47.402779 L1247.92649,43.5967556 C1248.06242,43.3547842 1248.00562,43.0579256 1247.79093,42.8929258 L1245.40478,41.0779281 Z M1236.99999,44 C1234.79424,44 1233,42.2057239 1233,40.0001026 C1233,37.7942761 1234.79424,36 1236.99999,36 C1239.20573,36 1241,37.7942761 1241,40.0001026 C1241,42.2057532 1239.20573,44 1236.99999,44 Z"
                      id="XMLID_9_"
                    />
                  </g>
                </g>
              </svg>
              <DropdownMenu
                top="70px"
                left="-91px"
                pt="24px"
                pb="24px"
                minWidth="225px"
                show={showOptions}
                handleOutsideClick={toggleOptionsMenu}
                dataTestId="menu-options"
              >
                {isContactOrAdmin && hasAtLeastOneOrganisation && (
                  <OptionLink to="/settings">
                    <img src={cogwheelOptionMenuIcon} alt="settings icon" />
                    Instellingen
                  </OptionLink>
                )}
                <OptionLink to="/profile" onClick={resetUserUpdate}>
                  <img src={profileOptionMenuIcom} alt="profile icon" />
                  Mijn profiel
                </OptionLink>
                {user.canSwitch && hasAtLeastOneOrganisation && (
                  <Option onClick={toggleShowSwitchOrganisation}>
                    <img
                      src={switchOrganisationOptionMenuIcon}
                      alt="switch organisation icon"
                    />
                    Organisatiewissel
                  </Option>
                )}
                <Option onClick={logoutUser}>
                  <img src={logoutOptionMenuIcon} alt="logout icon" />
                  Uitloggen
                </Option>
              </DropdownMenu>
            </ItemIconWrapper>
          </AccountWrapper>
        )}
        {isTablet && (
          <AccountWrapper>
            <Avatar
              src={user.logo || emptyLogo}
              onClick={toggleShowSwitchOrganisation}
            />
            <Logout
              data-test-id="Menu-LogOut"
              onClick={() => {
                logoutUser();
              }}
            >
              <i className="fa fa-power-off" />
            </Logout>
          </AccountWrapper>
        )}
        {showSwitchOrganisation && (
          <SwitchOrganisation
            toggleShowSwitchOrganisation={toggleShowSwitchOrganisation}
            showSwitchOrganisation={showSwitchOrganisation}
            linkedOrganisations={linkedOrganisations}
            linkedOrganisationsIsLoading={linkedOrganisationsIsLoading}
            linkedOrganisationsLoadError={linkedOrganisationsLoadError}
            loadLinkedOrganisations={loadLinkedOrganisations}
            currentOrganisation={user.organisationId}
            switchOrganisation={switchOrganisation}
          />
        )}
      </MenuInner>
    </MenuWrapper>
  );
}

Menu.propTypes = {
  toggleMobileMenu: PropTypes.func,
  isContactOrAdmin: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  user: PropTypes.object,
  logoutUser: PropTypes.func,
  turnOffBackgroundScroll: PropTypes.func.isRequired,
  turnOnBackgroundScroll: PropTypes.func.isRequired,
  linkedOrganisations: PropTypes.array,
  linkedOrganisationsIsLoading: PropTypes.bool,
  linkedOrganisationsLoadError: PropTypes.object,
  loadLinkedOrganisations: PropTypes.func.isRequired,
  clearLinkedOrganisations: PropTypes.func.isRequired,
  switchOrganisation: PropTypes.func.isRequired,
  resetUserUpdate: PropTypes.func.isRequired,
};

export default Menu;

export const handleCurrencyKeyDown = (event, existingValue) => {
  const c = event.key || event.code;
  const { keyCode } = event;

  // backspace, enter and tab is okay
  if (keyCode === 8 || keyCode === 9 || keyCode === 13) {
    return;
  }

  // only numbers and commas
  if ('0123456789,.'.indexOf(c) === -1) {
    event.preventDefault();
    return;
  }

  // only one comma
  if (existingValue.indexOf(',') !== -1) {
    if (c === ',' || c === '.') {
      event.preventDefault();
    }
  }
};

export const isMoreThanTwoDecimals = (value) => {
  if (!value) return false;
  const comma = value.indexOf(',');
  if (comma > -1 && comma < value.length - 3) {
    return true;
  }
  return false;
};

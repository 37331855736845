import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import { createUseStyles } from 'react-jss';

import animationData from '../../../images/animations/green-dots.json';

export function CashbackCampaignDetailLoader() {
  const styles = useStyles();

  return (
    <div className={styles.loader}>
      <div className={styles.animation}>
        <ReactBodymovin
          options={{
            loop: true,
            autoplay: true,
            prerender: true,
            animationData,
          }}
        />
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  animation: {
    height: '72px',
    paddingTop: '21px',
    paddingBottom: '21px',
    textAlign: 'center',
    '& .react-bodymovin-container': {
      height: '30px',
    },
  },
  loader: {
    position: 'relative',
    marginBottom: '24px',
  },
});

// Values
import { isEmpty } from 'lodash';
import { linkTypes } from './constants';

export const getInitialValues = (data) => {
  const thankYou = hasPendingThankYouPage(data)
    ? 'pendingThankYou'
    : 'thankYou';
  const url = hasPendingLandingUrl(data) ? 'pendingUrl' : 'url';
  return {
    inputLogo: null,
    pendingLogo: valueOrEmpty(data.pendingImages.logo),
    logo: valueOrEmpty(data.logo),
    inputThankYouImage: null,
    pendingThankYouImage: valueOrEmpty(data.pendingImages.thankYouImage),
    thankYouImage: valueOrEmpty(data.thankYou.image),
    inputExpiryImage: null,
    pendingExpiryImage: valueOrEmpty(data.pendingImages.expiryImage),
    expiryImage: valueOrEmpty(data.expiry.image),
    inputMaxPaymentsImage: null,
    pendingMaxPaymentsImage: valueOrEmpty(data.pendingImages.maxPaymentsImage),
    maxPaymentsImage: valueOrEmpty(data.maxPayments.image),
    url: valueOrEmpty(data[`${url}`]),
    maxPaymentsTitle: valueOrEmpty(data.maxPayments?.title),
    maxPaymentsContent: valueOrEmpty(data.maxPayments?.content),
    expiryTitle: valueOrEmpty(data.expiry?.title),
    expiryContent: valueOrEmpty(data.expiry?.content),
    thankYouAutoRedirect: valueOrEmpty(data[`${thankYou}`]?.autoRedirect),
    thankYouButton: valueOrEmpty(data[`${thankYou}`]?.button),
    thankYouContent: valueOrEmpty(data[`${thankYou}`]?.content),
    thankYouContent2: valueOrEmpty(data[`${thankYou}`]?.content2),
    thankYouTitle: valueOrEmpty(data[`${thankYou}`]?.title),
    thankYouUrl: valueOrEmpty(data[`${thankYou}`]?.url),
    linkType: deriveLinkType(
      data[`${thankYou}`]?.button,
      data[`${thankYou}`]?.url,
      data[`${thankYou}`]?.autoRedirect,
    ),
  };
};

export const hasPendingLandingUrl = (data) => notNullOrUndef(data.pendingUrl);
export const hasPendingThankYouPage = (data) =>
  notNullOrUndef(data.pendingThankYou) &&
  (notNullOrUndef(data.pendingThankYou.title) ||
    notNullOrUndef(data.pendingThankYou.content) ||
    notNullOrUndef(data.pendingThankYou.content2) ||
    notNullOrUndef(data.pendingThankYou.button) ||
    notNullOrUndef(data.pendingThankYou.url) ||
    notNullOrUndef(data.pendingThankYou.autoRedirect));
const notNullOrUndef = (value) => !(value === null || value === undefined);

const deriveLinkType = (thankYouButton, thankYouUrl, thankYouAutoRedirect) => {
  let value = linkTypes.noButton;
  if (!isEmpty(thankYouUrl)) {
    if (thankYouAutoRedirect) {
      value = linkTypes.autoLink;
    } else if (!isEmpty(thankYouButton)) {
      if (thankYouUrl.startsWith(WHATSAPP_BASE_URL)) {
        value = linkTypes.tikLink;
      } else {
        value = linkTypes.buttonLink;
      }
    }
  }
  return value;
};

const valueOrEmpty = (value) => (value == null ? '' : value);

export const valuesUpdated = (values, originalValues) => {
  const thankYou = hasPendingThankYouPage(originalValues)
    ? 'pendingThankYou'
    : 'thankYou';
  const url = hasPendingLandingUrl(originalValues) ? 'pendingUrl' : 'url';
  return (
    values.inputLogo ||
    (originalValues.pendingImages.logo && !values.pendingLogo) ||
    values.inputThankYouImage ||
    (originalValues.pendingImages.thankYouImage &&
      !values.pendingThankYouImage) ||
    values.inputExpiryImage ||
    (originalValues.pendingImages.expiryImage && !values.pendingExpiryImage) ||
    values.inputMaxPaymentsImage ||
    (originalValues.pendingImages.maxPaymentsImage &&
      !values.pendingMaxPaymentsImage) ||
    emptyOrChanged(values.url, originalValues[`${url}`]) ||
    emptyOrChanged(
      values.maxPaymentsTitle,
      originalValues.maxPayments?.title,
    ) ||
    emptyOrChanged(
      values.maxPaymentsContent,
      originalValues.maxPayments?.content,
    ) ||
    emptyOrChanged(values.expiryTitle, originalValues.expiry?.title) ||
    emptyOrChanged(values.expiryContent, originalValues.expiry?.content) ||
    emptyOrChanged(
      values.thankYouTitle,
      originalValues[`${thankYou}`]?.title,
    ) ||
    emptyOrChanged(values.thankYouUrl, originalValues[`${thankYou}`]?.url) ||
    emptyOrChanged(
      values.thankYouAutoRedirect,
      originalValues[`${thankYou}`]?.autoRedirect,
    ) ||
    emptyOrChanged(
      values.thankYouButton,
      originalValues[`${thankYou}`]?.button,
    ) ||
    emptyOrChanged(
      values.thankYouContent,
      originalValues[`${thankYou}`]?.content,
    ) ||
    emptyOrChanged(
      values.thankYouContent2,
      originalValues[`${thankYou}`]?.content2,
    ) ||
    values.linkType !==
      deriveLinkType(
        originalValues[`${thankYou}`]?.button,
        originalValues[`${thankYou}`]?.url,
        originalValues[`${thankYou}`]?.autoRedirect,
      )
  );
};

const emptyOrChanged = (updatedValue, originalValue) =>
  originalValue === null ? updatedValue !== '' : updatedValue !== originalValue;

export const valuesToSave = (
  values,
  { pendingUrl, pendingThankYou, ...data },
) => ({
  ...data,
  url: values.url,
  logo: values.inputLogo,
  maxPayments: {
    ...data.maxPayments,
    image: values.inputMaxPaymentsImage,
    title: values.maxPaymentsTitle,
    content: values.maxPaymentsContent,
  },
  expiry: {
    ...data.expiry,
    image: values.inputExpiryImage,
    title: values.expiryTitle,
    content: values.expiryContent,
  },
  thankYou: {
    ...data.thankYou,
    image: values.inputThankYouImage,
    autoRedirect: values.thankYouAutoRedirect,
    button: values.thankYouButton,
    content: values.thankYouContent,
    content2: values.thankYouContent2,
    title: values.thankYouTitle,
    url: values.thankYouUrl,
  },
  pendingImages: {
    logo: values.pendingLogo,
    thankyou: values.pendingThankYouImage,
    expiry: values.pendingExpiryImage,
    maxpayments: values.pendingMaxPaymentsImage,
  },
});

// Errors
export const getInitialErrors = () => ({
  url: false,
  maxPaymentsTitle: false,
  maxPaymentsContent: false,
  expiryTitle: false,
  expiryContent: false,
  thankYouMinFields: false,
  thankYouUrl: false,
  thankYouTitle: false,
  thankYouContent: false,
  thankYouContent2: false,
  thankYouWhatsAppTikkieUrl: false,
});

// eslint-disable-next-line no-undef
export const hasNoErrors = (errors) => !_.values(errors).some((e) => e);

export const WHATSAPP_BASE_URL = 'https://api.whatsapp.com/send?text=';

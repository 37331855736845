/**
 * Campaign Line Item (summary of campaign in the cashback campaign overview)
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import '!file-loader?name=[name].[ext]!../../../images/icon-cashback-barcode.svg';
import '!file-loader?name=[name].[ext]!../../../images/icon-cashback-receipt.svg';
import '!file-loader?name=[name].[ext]!../../../images/icon-cashback-token.svg';
import { Color, CaptionSmall } from '../../styleguide';
import { toCurrency } from '../../../utils';
import messages from './messages';

const CampaignLineItemWrapper = styled.div`
  position: relative;
  display: block;
  height: 126px;
  padding: 0 24px 0 0;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;

  :hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  width: auto;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 0;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-left: 40px;
  margin-top: 38px;
  margin-right: 16px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin: 0px 0px 16px 0px;
  }
`;

const ItemIcon = styled.img``;

const Header2 = styled.h2`
  margin: 0px;
  padding: 0px;
  font-family: MuseoSansRounded700;
  font-weight: normal;
  font-size: 22px;
  line-height: normal;
  color: ${Color.gray01};
  user-select: none;
  text-align: left;
  ${(props) =>
    props.isCampaignInFuture &&
    `
    color: ${Color.gray02}
`};
`;

const Text = styled(Header2)`
  margin-top: 4px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    white-space: normal;
    overflow: auto;
    text-overflow: clip;
  }
`;

const CampaignName = styled(Header2)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResponsiveText = styled(Text)`
  @media ${(props) => props.theme.device.mobile} {
    font-size: 18px;
  }
`;

const Column = styled.div`
  float: left;
  width: auto;
  padding-top: 42px;
  overflow: hidden;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    border-left: none;
    padding: 0px 0px 16px 0px;
    margin-bottom: 16px;
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const Title = styled(Column)`
  width: 256px;
  margin-right: 16px;
  line-height: 32px;
`;

const Cards = styled(Column)`
  width: 128px;
`;
const Cashbacks = styled(Column)`
  width: 134px;
`;
const Budget = styled(Column)`
  width: 176px;
`;
const Timing = styled(Column)`
  width: 230px;
`;

class CampaignLineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  campaignTypeIcon = () => {
    if (this.props.campaign.type === 'barcode') {
      return { src: '/icon-cashback-barcode.svg' };
    } else if (this.props.campaign.type === 'receipt') {
      return { src: '/icon-cashback-receipt.svg' };
    }
    return { src: '/icon-cashback-token.svg' };
  };

  dateCaption = () => {
    const today = moment().startOf('day');
    const start = moment(this.props.campaign.startDate);
    const end = moment(this.props.campaign.endDate).add(1, 'days');
    if (today < start) {
      const duration = moment.duration(start.diff(today));
      return duration.asDays() > 5 ? messages.startDate : messages.startsIn;
    } else if (today < end) {
      const duration = moment.duration(end.diff(today));
      return duration.asDays() > 5 ? messages.endDate : messages.endsIn;
    }
    return messages.endDate;
  };

  dateContentMessage = () => {
    const today = moment().startOf('day');
    const start = moment(this.props.campaign.startDate);
    const end = moment(this.props.campaign.endDate).add(1, 'days');
    const duration =
      today < start
        ? moment.duration(start.diff(today))
        : moment.duration(end.diff(today));
    if (duration.asDays() === 1) return messages.day;
    if (duration.asDays() <= 5 && duration.asDays() > 0) return messages.days;
    return messages.date;
  };

  dateContentValue = () => {
    const today = moment().startOf('day');
    const start = moment(this.props.campaign.startDate);
    const end = moment(this.props.campaign.endDate).add(1, 'days');
    if (today < start) {
      const duration = moment.duration(start.diff(today));
      return duration.asDays() > 5
        ? start.format('D MMM YYYY')
        : `${duration.asDays()}`;
    } else if (today < end) {
      const duration = moment.duration(end.diff(today));
      return duration.asDays() > 5
        ? moment(this.props.campaign.endDate).format('D MMM YYYY')
        : `${duration.asDays()}`;
    }
    return moment(this.props.campaign.endDate).format('D MMM YYYY');
  };

  render() {
    return (
      <CampaignLineItemWrapper onClick={this.props.showCampaignDetail}>
        <ItemIconWrapper>
          <ItemIcon {...this.campaignTypeIcon()} />
        </ItemIconWrapper>
        <ContentWrapper>
          <Title>
            <CampaignName isCampaignInFuture={this.props.isCampaignInFuture}>
              {this.props.campaign.campaignName}
            </CampaignName>
          </Title>
          <Cards>
            <CaptionSmall>
              <FormattedMessage {...messages.cards} />
            </CaptionSmall>
            <ResponsiveText isCampaignInFuture={this.props.isCampaignInFuture}>
              {this.props.campaign.amountOfCards || '-'}
            </ResponsiveText>
          </Cards>
          <Cashbacks>
            <CaptionSmall>
              <FormattedMessage {...messages.cashbacks} />
            </CaptionSmall>
            <ResponsiveText isCampaignInFuture={this.props.isCampaignInFuture}>
              {this.props.campaign.amountOfPayouts || '-'}
            </ResponsiveText>
          </Cashbacks>
          <Budget>
            <CaptionSmall>
              <FormattedMessage {...messages.budgetRemaining} />
            </CaptionSmall>
            <ResponsiveText isCampaignInFuture={this.props.isCampaignInFuture}>
              {toCurrency(
                this.props.campaign.thresholdAmount -
                  (this.props.campaign.totalPayoutAmount
                    ? this.props.campaign.totalPayoutAmount
                    : 0),
              )}
            </ResponsiveText>
          </Budget>
          <Timing>
            <CaptionSmall>
              <FormattedMessage {...this.dateCaption()} />
            </CaptionSmall>
            <ResponsiveText isCampaignInFuture={this.props.isCampaignInFuture}>
              <FormattedMessage
                {...this.dateContentMessage()}
                values={{ var: this.dateContentValue() }}
              />
            </ResponsiveText>
          </Timing>
        </ContentWrapper>
      </CampaignLineItemWrapper>
    );
  }
}

CampaignLineItem.propTypes = {
  campaign: PropTypes.object.isRequired,
  showCampaignDetail: PropTypes.func.isRequired,
  isCampaignInFuture: PropTypes.bool.isRequired,
};

export default injectIntl(CampaignLineItem);

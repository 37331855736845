/**
 *
 * PaymentRequestShare
 *
 */

import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/plusicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/nexticon.svg';
import '!file-loader?name=[name].[ext]!../../../images/okicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/checkicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/smsfailicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/smsokicon.svg';

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import messages from './messages';
import Loader from '../../OnboardingKYC/Loader';
import {
  Color,
  Header1,
  Header2,
  PrimaryButton,
  SecondaryButton,
  MobileWrapper,
  NonMobileWrapper,
} from '../../styleguide';
import { BackArrow, BreadCrumb } from '../../components';
import QRCode from '../../QRCode/QRCode';
import ShareLink from './ShareLink';
import SuggestionsWrapper from '../../Suggestions/SuggestionsWrapper';

const PaymentRequestShareWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    padding: 24px;
    border-radius: 0px;
    background-color: #f5f6fa;
    overflow: auto;
  }
`;

const Header = styled(Header1)`
  height: 38px;
  margin-top: 32px;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 28px;
    margin-bottom: 24px;
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    font-size: 24px;
  }
`;

const SubHeader = styled(Header2)`
  height: 22px;
  margin-top: 48px;
  margin-bottom: 24px;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 20px;
    margin-bottom: 24px;
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    font-size: 18px;
  }
`;

const Panel = styled.div`
  height: 112px;
  padding: 32px;
  margin-bottom: 34px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: white;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const SmsPanelMobile = styled(Panel)`
  padding-bottom: ${(props) => (props.bigPadding ? '72px' : '')};
`;

const ColumnDescription = styled.div`
  float: left;
  width: 40%;
  border-right: 2px solid ${Color.gray05};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  padding-right: 24px;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    width: 100%;
    border-right: none;
    border-bottom: 2px solid ${Color.gray05};
    padding-bottom: 24px;
    margin-bottom: 24px;
    padding-right: 0px;
  }
`;

const ColumnInvoiceNumber = styled.div`
  float: left;
  width: 40%;
  border-right: 2px solid ${Color.gray05};
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    width: 100%;
    border-right: none;
    border-bottom: 2px solid ${Color.gray05};
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
`;

const ColumnAmount = styled.div`
  float: left;
  width: 20%;
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    width: 100%;
    border-right: none;
    padding-left: 0px;
  }
`;

const Description = styled.div`
  height: 17px;
  margin-bottom: 8px;
  font-family: MuseoSansRounded;
  font-size: 14px;
  color: ${Color.gray03};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
`;

const Value = styled.div`
  height: 27px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.gray01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
`;

const Row = styled.div`
  display: flex;
  min-height: 91px;
  margin-bottom: 16px;
  padding: 30px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: ${(props) =>
    props.focussed
      ? '0 5px 20px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 4px 0 rgba(0, 0, 0, 0.05)'};
`;

const SubRow = styled.div`
  margin-top: 24px;
`;

const PhoneNumbersContainer = styled.div`
  flex: 1;
  margin-left: 32px;
`;

const Grouped = styled.div`
  padding-bottom: 56px;
  margin-bottom: 24px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid ${Color.gray05};

  @media ${(props) => props.theme.device.mobile} {
    margin-left: 0;
    margin-bottom: 0;
    padding-top: 24px;
    padding-bottom: 48px;
  }
`;

const GroupedNoBottom = styled.div`
  height: 30px;
  line-height: 30px;

  @media ${(props) => props.theme.device.mobile} {
    margin-left: 0;
    padding-top: 24px;
    margin-bottom: 52px;
  }
`;

const PreviousNumber = styled.div`
  float: left;
  width: 50%;
  height: 30px;
  line-height: 30px;
  font-family: MuseoSansRounded700;
  font-size: 22px;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    line-height: 26px;
  }
`;

const StatusOK = styled.div`
  color: ${Color.brandColor01};

  img {
    margin-top: 4px;
    width: 18px;
  }

  img,
  span {
    float: right;
    margin-left: 8px;
    font-size: 14px;
  }
`;

const StatusNOK = styled(StatusOK)`
  color: ${Color.errorColor};

  @media ${(props) => props.theme.device.mobile} {
    span {
      width: 60px;
    }
  }
`;

const Label = styled.div`
  float: left;
  width: 158px;
  line-height: 36px;
  font-family: MuseoSansRounded700;
  font-size: 20px;
  color: ${Color.gray01};
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    line-height: 26px;
    font-size: 22px;
    margin-left: 0;
    width: 100%;
  }
`;

const InputPrefix = styled.div`
  float: left;
  width: 66px;
  line-height: 30px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.brandColor01};

  @media ${(props) => props.theme.device.mobile} {
    padding-bottom: 32px;
  }
`;

const Input = styled.input`
  width: 50%;
  height: 30px;
  line-height: 30px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.brandColor01};
  outline: none;
  border: none;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};

  ::placeholder {
    color: ${Color.gray05};
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 40%;
  }
`;

const Button = styled(PrimaryButton)`
  float: right;
  width: 200px;
  margin-top: -9px;

  img {
    margin-right: 8px;
  }
`;

const AnotherNumberButton = styled(SecondaryButton)`
  border-radius: 50px;
  border: solid 2px ${Color.gray05};
  height: 48px;
  line-height: 46px;

  img {
    vertical-align: text-top;
  }

  @media ${(props) => props.theme.device.mobile} {
    margin-left: 0;
    float: right;
    width: 200px;
    margin-top: -9px;
  }
`;

const MobileFooter = styled(MobileWrapper)`
  button {
    float: none;
    width: 100%;
  }
`;

const CopyNotification = styled.div`
  position: fixed;
  bottom: 32px;
  width: 280px;
  height: 38px;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 50px;
  box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);
  background-color: ${Color.brandColor01};
  color: ${Color.white};
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  transition: ${(props) => (props.copied ? 'none' : 'opacity 0.5s ease-in')};
  opacity: ${(props) => (props.copied ? '1' : '0')};

  img {
    margin-right: 8px;
  }

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    bottom: auto;
    left: 0px;
    right: 0px;
    width: auto;
    height: 38px;
    transform: none;
    box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);
    background-color: ${Color.brandColor01};
    border-radius: 0px;
    opacity: 1;
    transition: ${(props) => (props.copied ? 'none' : 'top 0.5s ease-in')};
    top: ${(props) => (props.copied ? '0px' : '-50px')};
`;

function PaymentRequestShare(props) {
  const { t } = useTranslation();
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(true);
  const [smsMessagesSent, setSmsMessagesSent] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumbersSent, setPhoneNumbersSent] = useState(
    props.paymentRequest.notifications.map((notification) =>
      notification.recipientAddress.slice(4),
    ),
  );
  const [phoneNumbersStatus, setPhoneNumbersStatus] = useState(
    props.paymentRequest.notifications.map(
      (notification) => notification.status === 'SENT',
    ),
  );
  const [copied, setCopied] = useState(false);
  const [activeStep, setActiveStep] = useState('');

  const isPhoneNumberCorrect = () => phoneNumber.length === 8;

  const handleKeyPressPhoneNumber = (event) => {
    const c = event.key || event.code;
    const { keyCode } = event;
    const ctrlV = (event.ctrlKey || event.metaKey) && keyCode === 86;

    // Submit on enter
    if (event.key === 'Enter') {
      handleSmsClick();
      return;
    }

    // backspace, tab, enter or crtl-v is okay
    if (keyCode === 8 || keyCode === 9 || keyCode === 13 || ctrlV) {
      return;
    }

    // only numbers and commas
    if ('0123456789'.indexOf(c) === -1) {
      event.preventDefault();
    }

    // Max 8 characters
    if (phoneNumber.length > 7) {
      event.preventDefault();
    }
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePastePhoneNumber = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const value = clipboardData.getData('Text');

    let number = value.replace(/\D/g, ''); // remove non digits
    number =
      number.length > 8 ? number.replace(/^316|^00316|^06|^6/, '') : number;

    setPhoneNumber(number);

    event.preventDefault();
  };

  const handleSmsClick = () => {
    props.sendSms(props.paymentRequest.token, `+316${phoneNumber}`);
  };

  const onCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const formatAmount = (amountInCents) => {
    let amount = amountInCents;
    if (amountInCents.indexOf(',') !== -1) {
      amount = amountInCents.replace(',', '');
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 2,
    });

    return formatter.format(amount ? amount / 100 : 0);
  };

  const isInputFocussed = (step) => activeStep === step;

  const { showDashboard, showTikkieDetail, paymentRequest, isSendingSms } =
    props;
  const { description } = paymentRequest;
  const link = paymentRequest.paymentEventUrl;
  const isOpenTikkie = paymentRequest.openAmount;
  const amount = `${paymentRequest.amountPerPersonInCents}`;
  const invoiceNumber = paymentRequest.referenceId;

  useEffect(
    () => {
      if (phoneNumber && !props.isSendingSms) {
        setSmsMessagesSent(
          props.hasSmsSendError === null
            ? smsMessagesSent + 1
            : smsMessagesSent,
        );
        setPhoneNumbersSent([...phoneNumbersSent, phoneNumber]);
        setPhoneNumbersStatus([
          ...phoneNumbersStatus,
          props.hasSmsSendError === null,
        ]);
        setPhoneNumber('');
        setShowPhoneNumberInput(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.isSendingSms],
  );

  // eslint-disable-next-line
  useEffect(() => () => props.resetQRCode(), []);

  return (
    <PaymentRequestShareWrapper>
      <CopyNotification copied={copied}>
        <img src="/checkicon.svg" alt="" />
        <FormattedMessage {...messages.copied} />
      </CopyNotification>
      <MobileWrapper>
        <BackArrow onClick={showTikkieDetail} />
      </MobileWrapper>
      <NonMobileWrapper>
        <FormattedMessage {...messages.back}>
          {(backPlaceholder) => (
            <FormattedMessage {...messages.shareAgainHeader}>
              {(shareAgainPlaceholder) => (
                <BreadCrumb
                  id="backToOverview"
                  start={backPlaceholder}
                  onStart={showDashboard}
                  previous={description}
                  onPrevious={() => showTikkieDetail(paymentRequest)}
                  text={shareAgainPlaceholder}
                />
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
      </NonMobileWrapper>
      <Header>
        <FormattedMessage {...messages.shareAgainHeader} />
      </Header>
      <Panel>
        <ColumnDescription>
          <Description id="description">
            <FormattedMessage {...messages.description} />
          </Description>
          <Value>{description}</Value>
        </ColumnDescription>
        <ColumnInvoiceNumber>
          <Description id="invoice">
            <FormattedMessage {...messages.invoiceNumber} />
          </Description>
          <Value>{invoiceNumber}</Value>
        </ColumnInvoiceNumber>
        <ColumnAmount>
          <Description id="amount">
            <FormattedMessage {...messages.amount} />
          </Description>
          {!isOpenTikkie && <Value>€ {formatAmount(amount)}</Value>}
          {isOpenTikkie && (
            <Value>
              <FormattedMessage {...messages.open} />
            </Value>
          )}
        </ColumnAmount>
      </Panel>
      <NonMobileWrapper>
        <SubHeader>
          <FormattedMessage {...messages.shareHeader} />
        </SubHeader>
        <ShareLink
          label={messages.shareViaLink}
          link={link}
          onCopy={onCopied}
          dataTestId="payment-request-share-link-input"
        ></ShareLink>
        {props.smsOption && (
          <Row focussed={isInputFocussed('shareViaSms')}>
            <Label>
              <FormattedMessage {...messages.shareViaSms} />
            </Label>
            <PhoneNumbersContainer>
              {phoneNumbersSent.map((ph, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grouped key={`${ph}-${index}`}>
                  {phoneNumbersStatus[index] && (
                    <StatusOK>
                      <PreviousNumber id="sentAndOK">{`+ 31 6 ${ph}`}</PreviousNumber>
                      <img src="/smsokicon.svg" alt="" />
                      <FormattedMessage {...messages.sentStatusOk} />
                    </StatusOK>
                  )}
                  {!phoneNumbersStatus[index] && (
                    <StatusNOK>
                      <PreviousNumber id="sentAndNotOK">{`+ 31 6 ${ph}`}</PreviousNumber>
                      <img src="/smsfailicon.svg" alt="" />
                      <FormattedMessage {...messages.sentStatusNOk} />
                    </StatusNOK>
                  )}
                </Grouped>
              ))}
              {showPhoneNumberInput && (
                <GroupedNoBottom>
                  <InputPrefix>+ 31 6</InputPrefix>
                  <Input
                    id="phoneNumber"
                    placeholder="1234567"
                    onKeyDown={handleKeyPressPhoneNumber}
                    onChange={handleChangePhoneNumber}
                    onPaste={handlePastePhoneNumber}
                    value={phoneNumber}
                    onFocus={() => setActiveStep('shareViaSms')}
                    onBlur={() => setActiveStep('')}
                    data-test-id="payment-request-share-phone-number-input"
                  />
                  {isSendingSms && <Loader />}
                  {!isSendingSms && (
                    <Button
                      id="sendToNumber"
                      disabled={!isPhoneNumberCorrect()}
                      onClick={handleSmsClick}
                    >
                      <img src="/nexticon.svg" alt="" />
                      <FormattedMessage {...messages.buttonSmsLink} />
                    </Button>
                  )}
                </GroupedNoBottom>
              )}
              {!showPhoneNumberInput && (
                <SubRow>
                  <AnotherNumberButton
                    onClick={() => setShowPhoneNumberInput(true)}
                  >
                    <img src="/plusicon.svg" alt="" />
                    <FormattedMessage {...messages.addAnotherNumber} />
                  </AnotherNumberButton>
                </SubRow>
              )}
            </PhoneNumbersContainer>
          </Row>
        )}
        <QRCode
          link={link}
          label={messages.shareViaQRCode}
          fetchQRCode={props.fetchQRCode}
          fetchingQRCode={props.fetchingQRCode}
          fetchQRCodeError={props.fetchQRCodeError}
          qrCode={props.qrCode}
          button={messages.buttonQRCodeDownload}
          buttonQRCodePrint={messages.buttonQRCodePrint}
          onCopy={onCopied}
          info={messages.qrCodeInfo}
          tip={t('qrCode')}
        />
      </NonMobileWrapper>
      <MobileFooter>
        <CopyToClipboard text={link} onCopy={onCopied}>
          <Button>
            <img src="/copyicon-o.svg" alt="" />
            <FormattedMessage {...messages.buttonCopyLink} />
          </Button>
        </CopyToClipboard>
        <br />
        {props.smsOption && (
          <SmsPanelMobile
            focussed={isInputFocussed('shareViaSms')}
            bigPadding={showPhoneNumberInput}
          >
            <Label>
              <FormattedMessage {...messages.shareViaSms} />
            </Label>
            <br />
            {phoneNumbersSent.map((ph, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grouped key={`${ph}-${index}`}>
                {phoneNumbersStatus[index] && (
                  <StatusOK>
                    <PreviousNumber id="sentAndOK">{`+ 31 6 ${ph}`}</PreviousNumber>
                    <img src="/smsokicon.svg" alt="" />
                    <FormattedMessage {...messages.sentStatusOk} />
                  </StatusOK>
                )}
                {!phoneNumbersStatus[index] && (
                  <StatusNOK>
                    <PreviousNumber id="sentAndNotOK">{`+ 31 6 ${ph}`}</PreviousNumber>
                    <img src="/smsfailicon.svg" alt="" />
                    <FormattedMessage {...messages.sentStatusNOk} />
                  </StatusNOK>
                )}
              </Grouped>
            ))}
            {showPhoneNumberInput && (
              <GroupedNoBottom>
                <InputPrefix>+ 31 6</InputPrefix>
                <Input
                  id="phoneNumber"
                  placeholder="1234567"
                  onKeyDown={handleKeyPressPhoneNumber}
                  onChange={handleChangePhoneNumber}
                  onPaste={handlePastePhoneNumber}
                  value={phoneNumber}
                  onFocus={() => setActiveStep('shareViaSms')}
                  onBlur={() => setActiveStep('')}
                />
                {isSendingSms && <Loader />}
                {!isSendingSms && (
                  <Button
                    id="sendToNumber"
                    disabled={!isPhoneNumberCorrect()}
                    onClick={handleSmsClick}
                  >
                    <img src="/nexticon.svg" alt="" />
                    <FormattedMessage {...messages.buttonSmsLink} />
                  </Button>
                )}
              </GroupedNoBottom>
            )}
            {!showPhoneNumberInput && (
              <SubRow>
                <AnotherNumberButton
                  onClick={() => setShowPhoneNumberInput(true)}
                >
                  <img src="/plusicon.svg" alt="" />
                  <FormattedMessage {...messages.addAnotherNumber} />
                </AnotherNumberButton>
              </SubRow>
            )}
          </SmsPanelMobile>
        )}
        <QRCode
          link={link}
          label={messages.shareViaQRCode}
          fetchQRCode={props.fetchQRCode}
          fetchingQRCode={props.fetchingQRCode}
          fetchQRCodeError={props.fetchQRCodeError}
          qrCode={props.qrCode}
          button={messages.buttonQRCodeDownload}
          buttonQRCodePrint={messages.buttonQRCodePrint}
          onCopy={onCopied}
          isMobile
        />
      </MobileFooter>
      {isOpenTikkie && (
        <SuggestionsWrapper
          onCopied={onCopied}
          link={link}
        ></SuggestionsWrapper>
      )}
    </PaymentRequestShareWrapper>
  );
}

PaymentRequestShare.propTypes = {
  showDashboard: PropTypes.func.isRequired,
  showTikkieDetail: PropTypes.func.isRequired,
  paymentRequest: PropTypes.object.isRequired,
  sendSms: PropTypes.func.isRequired,
  isSendingSms: PropTypes.bool.isRequired,
  hasSmsSendError: PropTypes.string,
  fetchingQRCode: PropTypes.bool,
  fetchQRCodeError: PropTypes.bool,
  qrCode: PropTypes.string,
  fetchQRCode: PropTypes.func,
  resetQRCode: PropTypes.func,
  smsOption: PropTypes.any,
};

export default PaymentRequestShare;

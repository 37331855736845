import Cookies from 'universal-cookie';
import moment from 'moment-timezone';

const cookies = new Cookies();

export default function tokenCookie(payload) {
  cookies.set('jwt', payload.token, {
    path: '/',
    expires: moment(payload.expires).toDate(),
  });
}

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CashbackCampaignDetail } from '../../../components/CashbackCampaigns/CashbackCampaignDetail';

import CashbackCampaignsLayout from '../../../components/CashbackCampaigns/CashbackCampaignsLayout';
import { Campaign } from '../../../types/cashback-campaigns';

export default function CashbackCampaignDetailContainer() {
  const { campaignId } = useParams<{ campaignId: string }>();

  const [campaignName, setCampaignName] = useState('');

  const onCampaignLoad = (campaign: Campaign) =>
    setCampaignName(campaign.campaignData.campaignName);

  return (
    <CashbackCampaignsLayout
      title="deals.campaign"
      breadCrumb={{
        previousPage: { text: 'deals.campaigns', link: '/campaigns' },
        currentPage: campaignName,
      }}
    >
      <CashbackCampaignDetail
        campaignId={campaignId}
        onCampaignLoad={onCampaignLoad}
      />
    </CashbackCampaignsLayout>
  );
}

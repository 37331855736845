export const DOWNLOAD_SINGLE_PAYMENT_REQUEST =
  'app/PaymentRequestDetailContainer/DOWNLOAD_SINGLE_PAYMENT_REQUEST';
export const LOAD_PAYMENTS = 'app/PaymentRequestDetailContainer/LOAD_PAYMENTS';
export const LOAD_PAYMENTS_SUCCESS =
  'app/PaymentRequestDetailContainer/LOAD_PAYMENTS_SUCCESS';
export const LOAD_PAYMENTS_ERROR =
  'app/PaymentRequestDetailContainer/LOAD_PAYMENTS_ERROR';
export const CLEAR_DATA = 'app/PaymentRequestDetailContainer/CLEAR_DATA';
export const ARCHIVE_PAYMENT_REQUEST =
  'app/PaymentRequestDetailContainer/ARCHIVE_PAYMENT_REQUEST';
export const ARCHIVE_PAYMENT_REQUEST_SUCCESS =
  'app/PaymentRequestDetailContainer/ARCHIVE_PAYMENT_REQUEST_SUCCESS';
export const ARCHIVE_PAYMENT_REQUEST_ERROR =
  'app/PaymentRequestDetailContainer/ARCHIVE_PAYMENT_REQUEST_ERROR';
export const PATCH_PAYMENT_REQUEST =
  'app/PaymentRequestDetailContainer/PATCH_PAYMENT_REQUEST';
export const PATCH_PAYMENT_REQUEST_ERROR =
  'app/PaymentRequestDetailContainer/PATCH_PAYMENT_REQUEST_ERROR';

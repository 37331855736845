import { isEmptyString } from '../../utils';

export const hasUppercaseLetter = (password) => /[A-Z]/.test(password); // ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŸ
export const hasLowercaseLetter = (password) => /[a-z]/.test(password); // àáâãäåæçèéêëìíîïÞßðñòóôõöøùúûüýþÿ
export const hasNumber = (password) => /[0-9]/.test(password);
export const hasSpecialCharacterNoSpaces = (password) =>
  /[-!@#$%^&+=]/.test(password) && !/\s/.test(password);
export const hasAtLeast8Characters = (password) => password.length > 7;

// checks if all values are ok, so empty check is also needed here
export const hasNoWeirdCharacters = (password) =>
  isEmptyString(password) ||
  password.match(/^[A-Za-z0-9-!@#$%^&+=]+$/) !== null;

import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Overlay } from '../../styleguide';

const DropdownWrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '55px')};
  left: ${(props) => (props.left ? props.left : '-50px')};
  border-radius: 10px;
  box-shadow: 4px 10px 24px 0 rgba(0, 0, 0, 0.15);
  padding-top: ${(props) => (props.pt ? props.pt : '0px')};
  padding-bottom: ${(props) => (props.pb ? props.pb : '0px')};
  border: solid 2px #f2f2f3;
  z-index: 999;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '280px')};
  background-color: #ffffff;
  color: #57565b;

  @media ${(props) => props.theme.device.mobile} {
    left: 0px;
  }

  ${(props) =>
    props.mobileFullScreen &&
    css`
      @media ${props.theme.device.mobile} {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0px;
      }
    `}
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -11px;
  left: 0;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(-45deg);
  background-color: #ffffff;
  color: #57565b;
  border-top: solid 2px #f2f2f3;
  z-index: -1;

  ${(props) =>
    props.mobileFullScreen &&
    css`
      @media ${props.theme.device.mobile} {
        display: none;
      }
    `};
`;

/**
 * @return {null}
 */
function DropdownMenu(props) {
  const { mobileFullScreen = true } = props;
  if (props.show) {
    return (
      <React.Fragment>
        <Overlay onClick={props.handleOutsideClick} />
        <DropdownWrapper
          top={props.top}
          left={props.left}
          pt={props.pt}
          pb={props.pb}
          minWidth={props.minWidth}
          mobileFullScreen={mobileFullScreen}
          data-test-id={props.dataTestId || 'drop-down-menu'}
        >
          <UpArrow />
          {props.children}
        </DropdownWrapper>
      </React.Fragment>
    );
  }
  return null;
}

DropdownMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  handleOutsideClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  pb: PropTypes.string,
  pt: PropTypes.string,
  minWidth: PropTypes.string,
  mobileFullScreen: PropTypes.bool,
};

export default DropdownMenu;

const address = 'ADDRESS';
const selectAddress = 'SELECT_ADDRESS';

export default {
  address,
  selectAddress,
};

export interface Address {
  street?: string;
  houseNumber?: string;
  postalCode?: string;
  addition?: string;
  city?: string;
  countryCode?: string;
}

export interface PartialAddress {
  postalCode?: string;
  houseNumber?: string;
}

/*
 * BulkFile Messages
 *
 * This contains all the text for the BulkFile component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  yesterday: {
    id: 'app.components.BulkLineItem.yesterday',
    defaultMessage: 'gisteren',
  },
  ready: {
    id: 'app.components.BulkLineItem.ready',
    defaultMessage: 'te verzenden',
  },
  error: {
    id: 'app.components.BulkLineItem.error',
    defaultMessage: 'niet verzonden',
  },
  sent: {
    id: 'app.components.BulkLineItem.sent',
    defaultMessage: 'verzonden',
  },
});

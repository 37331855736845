import React, { useState } from 'react';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import { Wrapper } from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import OnboardingDropdown from '../Shared/OnboardingDropdown';
import { UboInfoScreen } from '../UBO/UboInfoScreen';
import ButtonFooter from '../components/ButtonFooter';
import NavigationHeader from '../components/NavigationHeader';
import countryList from './config';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface ChooseCountryProps {
  saveCountry: (country: string | undefined) => void;
  selectedCountry?: string;
  ubo?: boolean;
  onClickBack?: () => void;
  uboFullName?: string;
  clearAddress: () => void;
}

function ChooseCountry({
  saveCountry,
  selectedCountry,
  ubo,
  onClickBack,
  uboFullName,
  clearAddress,
}: ChooseCountryProps): JSX.Element {
  const [info, setInfo] = useState(false);
  const [country, setCountry] = useState(selectedCountry);

  useOnboardingPageView('choose-country');

  const showInfo = (): void => setInfo(!info);
  const disableButton = (): boolean => country === '';
  const netherlandsObject = countryList.find(
    (countryObject) => countryObject.label === 'Nederland',
  );

  const netherlandsObjectArray = netherlandsObject
    ? [netherlandsObject]
    : undefined;
  const saveSelectedCountry = (): void => {
    if (selectedCountry !== '' && country !== selectedCountry) {
      clearAddress();
    }
    saveCountry(country);
  };

  const findCountryLabel = (value?: string): string =>
    countryList.find((singleCountry) => singleCountry.value === value)?.label ??
    '';

  return (
    <>
      <Wrapper>
        {ubo && (
          <>
            <NavigationHeader
              clickLeft={(onClickBack as () => void) ?? undefined}
              clickRight={showInfo}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.grey.dark,
              }}
              variant="pl700"
            >
              {t('onboarding.chooseCountry.ubo.description')}
            </BlokkieTypography>
            <Title>{`${t(
              'onboarding.chooseCountry.ubo.title',
            )} ${uboFullName}?`}</Title>
          </>
        )}
        {!ubo && (
          <>
            <NavigationHeader
              clickLeft={(onClickBack as () => void) ?? undefined}
              clickRight={showInfo}
            />
            <Title>{t('onboarding.chooseCountry.title')}</Title>
          </>
        )}
        <OnboardingDropdown
          priorityOptions={netherlandsObjectArray}
          options={countryList}
          placeholder={t('onboarding.chooseCountry.dropdownPlaceholder') ?? ''}
          handleChange={(event) => setCountry(event.target.value)}
          value={findCountryLabel(country)}
          dataTestId="Onboarding-ChooseCountry-DropDown"
        />
        <ButtonFooter forceAlign="right">
          <BlokkieButton
            disabled={disableButton()}
            onClick={saveSelectedCountry}
            data-test-id="Onboarding-ChooseCountry-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info && !ubo}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={ChooseCountry.name}
      >
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.veryDark,
          }}
          variant="pl500"
        >
          {t('onboarding.chooseCountry.info')}
        </BlokkieTypography>
      </InfoScreen>
      <UboInfoScreen
        show={info && !!ubo}
        showInfo={showInfo}
        fromPage={`${ChooseCountry.name}_ubo`}
      />
    </>
  );
}

export default ChooseCountry;

import React from 'react';
import { injectIntl } from 'react-intl';
import {
  riskQuestionIds,
  riskQuestionSteps,
} from '../../../containers/OnboardingKYCContainer/RiskQuestionsContainer/RiskQuestionsContainerConstants';
import { RiskAnswerOptions } from './RiskAnswerOptions';
import RiskQuestionGeneric from './RiskQuestionGeneric';
import { RiskQuestionComponentProps } from './FluentFlowComponentsConstants';

function FinancialPartOneRiskQuestion({
  setRiskAnswer,
  previousStep,
  nextStep,
  currentAnswer,
}: RiskQuestionComponentProps): JSX.Element {
  const setFinancialPartOneRiskAnswer = (answer: RiskAnswerOptions) => {
    setRiskAnswer({ [riskQuestionIds.financialPartOneRiskId]: answer });
  };

  return (
    <RiskQuestionGeneric
      currentAnswer={currentAnswer}
      setRiskAnswerHandler={setFinancialPartOneRiskAnswer}
      previousStep={previousStep}
      nextStep={nextStep}
      questionType={riskQuestionSteps.financialPartOne}
    />
  );
}

export default injectIntl(FinancialPartOneRiskQuestion);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Color } from '../../styleguide';

const Check = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 16px;
  border-radius: 50%;

  border: solid 2px
    ${(props) => (props.checked ? Color.brandColor01 : '#e9e9e9')};
  background-color: ${(props) =>
    props.checked ? Color.brandColor01 : '#f7f7f9'};

  img {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    margin-top: 3px;
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

const ChoiceTextWrapper = styled.div`
  font-size: 16px;
  color: rgb(87, 86, 91);
`;

function CheckButton(props) {
  const img = props.checked ? '/checkmark2.svg' : '/blank.svg';
  return (
    <CheckWrapper id={props.id}>
      <Check
        checked={props.checked}
        onClick={props.onClick}
        id={props.dataTestId}
      >
        <img src={img} alt="check" />
      </Check>
      <ChoiceTextWrapper>{props.choice}</ChoiceTextWrapper>
    </CheckWrapper>
  );
}

CheckButton.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  choice: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default CheckButton;

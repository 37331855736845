import '!file-loader?name=[name].[ext]!../../images/back.svg';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { Color } from '../styleguide';
import Loader from '../OnboardingKYC/Loader';

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

export function List({
  items,
  pagination,
  pageSize,
  totalItems,
  onPageChange,
  renderItem,
  renderNoItem,
  loading,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const onChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber - 1);
  };
  return (
    <ListContainer>
      {!loading && items.map((item) => renderItem(item))}
      {!loading && items.length === 0 && renderNoItem()}
      {loading && <Loader />}
      {!loading && totalItems !== 0 && pagination && (
        <PagingWrapper>
          <Pagination
            onChange={onChange}
            defaultCurrent={1}
            current={currentPage}
            pageSize={pageSize}
            simple={isMobileOnly}
            showLessItems={isTablet}
            total={totalItems}
            prevIcon={<NavPrev src="/back.svg" />}
            nextIcon={<NavNext src="/back.svg" />}
            showTitle={false}
          />
        </PagingWrapper>
      )}
    </ListContainer>
  );
}

List.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array.isRequired,
  pagination: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderNoItem: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

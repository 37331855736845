import { fromJS } from 'immutable';

import {
  EMPTY_UPLOAD_FILE,
  BULK_UPLOAD,
  BULK_UPLOAD_ERROR,
  BULK_UPLOAD_SUCCESS,
  DELETE_BULK_UPLOAD,
  DELETE_BULK_UPLOAD_ERROR,
  DELETE_BULK_UPLOAD_SUCCESS,
  RESET_BULK_UPLOAD,
  SEND_BULK_UPLOAD,
  SEND_BULK_UPLOAD_ERROR,
  SEND_BULK_UPLOAD_SUCCESS,
} from './constants';

const initialState = fromJS({
  isUploading: false,
  bulkUploadErrorStatus: 0,
  bulkUploadErrorMessage: null,
  bulkUploadFile: {},
  isDeletingBulkUpload: false,
  deleteBulkUploadErrorStatus: 0,
  deleteBulkUploadErrorMessage: null,
  isSendingBulkUpload: false,
  sendBulkUploadErrorStatus: 0,
  sendBulkUploadErrorMessage: null,
});

export default function bulkCreateContainerReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case EMPTY_UPLOAD_FILE:
      return state.set('bulkUploadFile', fromJS({}));
    case BULK_UPLOAD:
      return state
        .set('isUploading', true)
        .set('bulkUploadErrorStatus', 0)
        .set('bulkUploadErrorMessage', null)
        .set('bulkUploadValidationErrors', null)
        .set('bulkUploadFile', fromJS({}));
    case BULK_UPLOAD_SUCCESS:
      return state
        .set('isUploading', false)
        .set('bulkUploadErrorStatus', 0)
        .set('bulkUploadErrorMessage', null)
        .set('bulkUploadValidationErrors', null)
        .set('bulkUploadFile', action.payload.bulkUploadFile);
    case BULK_UPLOAD_ERROR:
      return state
        .set('isUploading', false)
        .set('bulkUploadErrorStatus', action.payload.status)
        .set('bulkUploadErrorMessage', action.payload.error)
        .set('bulkUploadValidationErrors', action.payload.errors)
        .set('bulkUploadFile', fromJS({}));
    case DELETE_BULK_UPLOAD:
      return state
        .set('isDeletingBulkUpload', true)
        .set('deleteBulkUploadErrorStatus', 0)
        .set('deleteBulkUploadErrorMessage', null);
    case DELETE_BULK_UPLOAD_SUCCESS:
      return state
        .set('isDeletingBulkUpload', false)
        .set('deleteBulkUploadErrorStatus', 0)
        .set('deleteBulkUploadErrorMessage', null)
        .set('bulkUploadFile', fromJS({}));
    case DELETE_BULK_UPLOAD_ERROR:
      return state
        .set('isDeletingBulkUpload', false)
        .set('deleteBulkUploadErrorStatus', action.payload.status)
        .set('deleteBulkUploadErrorMessage', action.payload.error);
    case SEND_BULK_UPLOAD:
      return state
        .set('isSendingBulkUpload', true)
        .set('sendBulkUploadErrorStatus', 0)
        .set('sendBulkUploadErrorMessage', null);
    case SEND_BULK_UPLOAD_SUCCESS:
      return state
        .set('isSendingBulkUpload', false)
        .set('sendBulkUploadErrorStatus', 0)
        .set('sendBulkUploadErrorMessage', null)
        .set('bulkUploadFile', fromJS({}));
    case SEND_BULK_UPLOAD_ERROR:
      return state
        .set('isSendingBulkUpload', false)
        .set('sendBulkUploadErrorStatus', action.payload.status)
        .set('sendBulkUploadErrorMessage', action.payload.error);
    case RESET_BULK_UPLOAD:
      return state
        .set('isUploading', false)
        .set('bulkUploadErrorStatus', 0)
        .set('bulkUploadErrorMessage', null)
        .set('bulkUploadValidationErrors', null)
        .set('bulkUploadFile', fromJS({}))
        .set('isDeletingBulkUpload', false)
        .set('deleteBulkUploadErrorStatus', 0)
        .set('deleteBulkUploadErrorMessage', null)
        .set('isSendingBulkUpload', false)
        .set('sendBulkUploadErrorStatus', 0)
        .set('sendBulkUploadErrorMessage', null);
    default:
      return state;
  }
}

export { initialState };

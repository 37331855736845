/**
 *
 * Login
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import {
  Header2,
  Color,
  PrimaryButton,
  Input,
  TertiaryButton,
} from '../../styleguide';

import {
  LightBox,
  LightBoxFieldLabel,
  LightBoxErrorMessageBanner,
} from '../../components';

import Password from '../../Password/Password';

import messages from './messages';
import animationData from '../../../images/animations/white-dots.json';

const LoginWrapper = styled(LightBox)``;

const Header = styled(Header2)`
  margin-left: 8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

const Label = styled(LightBoxFieldLabel)``;

const InputWrapper = styled.div`
  position: relative;
`;

const ErrorMessageBanner = styled(LightBoxErrorMessageBanner)``;

const ErrorMessage = styled(ErrorMessageBanner)`
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    top: 85px;
    left: 0;
    right: 0;
  }
`;

const FooterErrorMessage = styled(ErrorMessageBanner)`
  position: absolute;
  top: -70px;
  left: 0px;
  right: 0px;

  @media ${(props) => props.theme.device.mobile} {
    top: -16px;
    width: 100%;
    max-width: 320px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -2px;
  left: 24px;
  background-color: ${Color.errorColor};
  transform: rotate(-45deg);
  z-index: -1;
`;

const DownArrow = styled(UpArrow)`
  top: auto;
  bottom: -2px;

  @media ${(props) => props.theme.device.mobile} {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Footer = styled.div`
  position: absolute;
  padding: 0px 8px;
  height: 50px;
  line-height: 50px;
  bottom: 40px;
  left: 40px;
  right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    height: 96px;
  }
`;

const ForgotPasswordWrapper = styled.div`
  float: left;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
    margin-top: 16px;
    height: auto;
    line-height: normal;
  }
`;

const ForgotPassword = styled(TertiaryButton)`
  height: 48px;
  line-height: 48px;
`;

const ButtonWrapper = styled.div`
  float: right;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
  }
`;

const Button = styled(PrimaryButton)`
  width: 166px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 231px;
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    min-width: 200px;
  }
`;

const AnimationWrapper = styled.div`
  display: inline-block;
  height: 18px;

  .react-bodymovin-container {
    height: 18px;
  }
`;

const AQUIRE = 'aquire-otp';
const USE_SAVED = 'use-saved-otp';

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

/* eslint-disable react/prefer-stateless-function */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      password: this.props.password,
      isValidUserName: true,
      isValidPassword: true,
      credentialsError: false,
      smsError: false,
      blockedUserError: this.props.blockedUser,
    };

    this.loginRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.invalidCredentials !== this.props.invalidCredentials) {
      this.setState({ credentialsError: this.props.invalidCredentials });
    }

    if (oldProps.loginSmsSendFailed !== this.props.loginSmsSendFailed) {
      this.setState({ smsError: this.props.loginSmsSendFailed });
    }

    if (oldProps.blockedUser !== this.props.blockedUser) {
      this.setState({ blockedUserError: this.props.blockedUser });
    }
  }

  // here field_name is ref name.
  onChangeUsername = (event) => {
    const username = event.target.value.trim();
    this.setState({
      username,
      isValidUserName: true,
      credentialsError: false,
      smsError: false,
      blockedUserError: false,
    });
  };

  onChangePassword = (event) => {
    const password = event.target.value;
    this.setState({
      password,
      credentialsError: false,
      smsError: false,
      blockedUserError: false,
    });
  };

  onLoginUser = () => {
    const { username, password } = this.state;
    const { otp, rememberMe } = this.props;
    const isValidUserName = true; // username.match(/@/g) !== null;
    const action = otp === '' || otp == null ? AQUIRE : USE_SAVED;

    this.setState({ isValidUserName });

    if (isValidUserName) {
      this.props.onLoginUser(username, password, otp, rememberMe, action);
    }
  };

  onHandleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onLoginUser();
    }
  };

  disableLoginButton = () =>
    this.state.username === '' ||
    this.state.password === '' ||
    !this.state.isValidUserName ||
    !this.state.isValidPassword;

  hasUsernameFocus = () => this.props.username === '';

  hasPasswordFocus = () =>
    this.props.isResetLinkSent || this.props.username !== '';

  render() {
    const { isLoading, onShowForgotPassword } = this.props;

    const {
      username,
      password,
      credentialsError,
      smsError,
      blockedUserError,
      isValidUserName,
      isValidPassword,
    } = this.state;

    return (
      <LoginWrapper>
        <Header>
          <FormattedMessage {...messages.header} />
        </Header>
        {credentialsError && (
          <ErrorMessageBanner id="incorrectCredentials">
            <FormattedMessage {...messages.loginFailedError} />
          </ErrorMessageBanner>
        )}
        {smsError && (
          <ErrorMessageBanner id="smsSendFailed">
            <FormattedMessage {...messages.smsSendError} />
          </ErrorMessageBanner>
        )}
        <InputWrapper>
          <Label>
            <FormattedMessage {...messages.email} />
          </Label>
          <FormattedMessage {...messages.emailPlaceholder}>
            {(placeholder) => (
              <Input
                onChange={this.onChangeUsername}
                onKeyDown={this.onHandleKeyDown}
                id="email"
                placeholder={placeholder}
                autoFocus={this.hasUsernameFocus()}
                autoComplete="off"
                value={username}
              />
            )}
          </FormattedMessage>
          {!isValidUserName && (
            <ErrorMessage id="incompleteEmail">
              <UpArrow />
              <FormattedMessage {...messages.emailError} />
            </ErrorMessage>
          )}
        </InputWrapper>
        <InputWrapper>
          <Label>
            <FormattedMessage {...messages.password} />
          </Label>
          <Password
            onChange={this.onChangePassword}
            onKeyDown={this.onHandleKeyDown}
            id="password"
            placeholder={messages.passwordPlaceholder.defaultMessage}
            autoFocus={this.hasPasswordFocus()}
            value={password}
          />
          {!isValidPassword && (
            <ErrorMessage>
              <UpArrow />
              <FormattedMessage {...messages.passwordError} />
            </ErrorMessage>
          )}
        </InputWrapper>
        <Footer>
          {blockedUserError && (
            <FooterErrorMessage id="blocked">
              <DownArrow />
              <FormattedMessage {...messages.blockedError} />
            </FooterErrorMessage>
          )}
          <ButtonWrapper>
            <Button
              onClick={this.onLoginUser}
              disabled={this.disableLoginButton()}
              id="login"
              ref={this.loginRef}
            >
              {isLoading && (
                <AnimationWrapper>
                  <ReactBodymovin options={bodymovinOptions} />
                </AnimationWrapper>
              )}
              {!isLoading && <FormattedMessage {...messages.button} />}
            </Button>
          </ButtonWrapper>
          <ForgotPasswordWrapper>
            <ForgotPassword id="forgotPassword" onClick={onShowForgotPassword}>
              <FormattedMessage {...messages.forgotPasswordLink} />
            </ForgotPassword>
          </ForgotPasswordWrapper>
        </Footer>
      </LoginWrapper>
    );
  }
}

Login.propTypes = {
  rememberMe: PropTypes.bool,
  loginSmsSendFailed: PropTypes.bool,
  username: PropTypes.string,
  password: PropTypes.string,
  otp: PropTypes.string,
  isLoading: PropTypes.bool,
  isResetLinkSent: PropTypes.bool,
  invalidCredentials: PropTypes.bool,
  showSmsSendError: PropTypes.func,
  blockedUser: PropTypes.bool,
  onLoginUser: PropTypes.func.isRequired,
  onLoginReset: PropTypes.func.isRequired,
  onShowForgotPassword: PropTypes.func.isRequired,
};

export default Login;

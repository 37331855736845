import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import { isEmpty } from '../../../utils';
import ApiOverview from '../../../components/Settings/ApiOverview';
import ApiNewToken from '../../../components/Settings/ApiNewToken';
import ApiModifyToken from '../../../components/Settings/ApiModifyToken';
import {
  loadSettings,
  updateSettings,
  loadApiTokens,
  createApiToken,
  modifyApiToken,
} from '../actions';
import {
  selectSettingsData,
  selectApiTokensLoading,
  selectApiTokensLoadingError,
  selectApiTokensLoadingSuccess,
  selectApiTokens,
  selectCreatingApiToken,
  selectCreateApiTokenError,
  selectCreateApiTokenSuccess,
  selectModifyingApiToken,
  selectModifyApiTokenError,
  selectModifyApiTokenSuccess,
  selectModifiedApiToken,
} from '../selectors';
import { selectUser } from '../../App/selectors';

export function ApiSettingsContainer(props) {
  const [apiOverview, setApiOverview] = useState(true);
  const [apiNewToken, setApiNewToken] = useState(false);
  const [apiModifyToken, setApiModifyToken] = useState(false);
  const [apiToken, setApiToken] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(props.settingsData)) {
      props.loadSettings();
      props.loadApiTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  const goToCreateApiToken = () => {
    setApiNewToken(true);
    setApiOverview(false);
  };

  const goToModifyToken = (token) => {
    setApiModifyToken(true);
    setApiOverview(false);
    setApiToken(token);
  };

  const goToApiOverview = () => {
    props.loadApiTokens();

    setApiNewToken(false);
    setApiOverview(true);
    setApiModifyToken(false);
  };

  const isUnlimited = () =>
    props.settingsData.userSetting.maxYieldPerIbanInCentsPer24Hours === -1;

  const isCashback = props.user.cashbackEnabled;

  const updateApiTokenState = () => {
    setApiToken(props.modifiedApiToken);
  };

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer highMargin>
          {apiOverview && (
            <ApiOverview
              gotoSettingsDashboard={gotoSettingsDashboard}
              settingsData={props.settingsData}
              apiTokensLoading={props.apiTokensLoading}
              apiTokensLoadingSuccess={props.apiTokensLoadingSuccess}
              apiTokensLoadingError={props.apiTokensLoadingError}
              apiTokens={props.apiTokens}
              loadApiTokens={props.loadApiTokens}
              goToCreateApiToken={goToCreateApiToken}
              goToModifyToken={goToModifyToken}
            />
          )}
          {apiNewToken && (
            <ApiNewToken
              settingsData={props.settingsData}
              apiTokensLoading={props.apiTokensLoading}
              apiTokensLoadingSuccess={props.apiTokensLoadingSuccess}
              apiTokensLoadingError={props.apiTokensLoadingError}
              apiTokens={props.apiTokens}
              loadApiTokens={props.loadApiTokens}
              createApiToken={props.createApiToken}
              creatingApiToken={props.creatingApiToken}
              createApiTokenSuccess={props.createApiTokenSuccess}
              createApiTokenError={props.createApiTokenError}
              goToApiOverview={goToApiOverview}
              cashbackAllowed={isCashback}
            />
          )}
          {apiModifyToken && (
            <ApiModifyToken
              settingsData={props.settingsData}
              apiTokensLoading={props.apiTokensLoading}
              apiTokensLoadingSuccess={props.apiTokensLoadingSuccess}
              apiTokensLoadingError={props.apiTokensLoadingError}
              apiTokens={props.apiTokens}
              loadApiTokens={props.loadApiTokens}
              apiToken={apiToken}
              goToApiOverview={goToApiOverview}
              modifyApiToken={props.modifyApiToken}
              modifyApiTokenError={props.modifyApiTokenError}
              modifyingApiToken={props.modifyingApiToken}
              modifyApiTokenSuccess={props.modifyApiTokenSuccess}
              updateApiTokenState={updateApiTokenState}
              cashbackAllowed={isCashback}
            />
          )}
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

ApiSettingsContainer.propTypes = {
  settingsData: PropTypes.object,
  loadSettings: PropTypes.func,
  loadApiTokens: PropTypes.func,
  apiTokensLoading: PropTypes.bool,
  apiTokensLoadingError: PropTypes.bool,
  apiTokensLoadingSuccess: PropTypes.bool,
  apiTokens: PropTypes.array,
  createApiToken: PropTypes.func,
  creatingApiToken: PropTypes.bool,
  createApiTokenSuccess: PropTypes.bool,
  createApiTokenError: PropTypes.object,
  modifyApiToken: PropTypes.func,
  modifyApiTokenError: PropTypes.object,
  modifyingApiToken: PropTypes.bool,
  modifyApiTokenSuccess: PropTypes.bool,
  modifiedApiToken: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  loadSettings,
  settingsData: selectSettingsData,
  apiTokensLoading: selectApiTokensLoading,
  apiTokensLoadingError: selectApiTokensLoadingError,
  apiTokensLoadingSuccess: selectApiTokensLoadingSuccess,
  apiTokens: selectApiTokens,
  creatingApiToken: selectCreatingApiToken,
  createApiTokenError: selectCreateApiTokenError,
  createApiTokenSuccess: selectCreateApiTokenSuccess,
  modifyingApiToken: selectModifyingApiToken,
  modifyApiTokenError: selectModifyApiTokenError,
  modifyApiTokenSuccess: selectModifyApiTokenSuccess,
  modifiedApiToken: selectModifiedApiToken,
  user: selectUser,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
  loadApiTokens: () => dispatch(loadApiTokens()),
  createApiToken: (name, rights) => dispatch(createApiToken(name, rights)),
  modifyApiToken: (enabled, name, rights, appToken) =>
    dispatch(modifyApiToken(enabled, name, rights, appToken)),
  updateSettings: (settingsData) => dispatch(updateSettings(settingsData)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'ApiSettingsContainer', saga });

export default compose(withSaga, withConnect)(ApiSettingsContainer);

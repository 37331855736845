import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'amber';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { GiphyBox } from '../../components/components';
import { BUSINESS_EMAIL_ADDRESS } from '../../global-constants';

const GiphyWrapper = styled.div`
  width: 414px;
  margin: 0 auto;
`;

/* eslint-disable react/prefer-stateless-function */
class UserWithoutOrganisationsPage extends React.PureComponent {
  render() {
    return (
      <Container
        mt={isMobile ? 94 : 250}
        data-test-id="users-without-organisations-page"
      >
        <GiphyWrapper>
          <GiphyBox
            gif="link-not-working.gif"
            title={this.props.intl.formatMessage({
              id: 'app.components.UsersWithoutOrganisation.title',
            })}
            text={this.props.intl.formatMessage({
              id: 'app.components.UsersWithoutOrganisation.text',
            })}
            button={this.props.intl.formatMessage({
              id: 'app.components.UsersWithoutOrganisation.button',
            })}
            onClick={() => {
              window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
            }}
          />
        </GiphyWrapper>
      </Container>
    );
  }
}

UserWithoutOrganisationsPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(UserWithoutOrganisationsPage);

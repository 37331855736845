import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';

import reducer from './reducer';
import saga from './saga';
import injectSaga from '../../../utils/injectSaga';
import injectReducer from '../../../utils/injectReducer';
import PaymentRequestCreate from '../../../components/PaymentRequest/PaymentRequestCreate';
import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';
import {
  selectAmount,
  selectCreateTikkieErrorMessage,
  selectCreateTikkieErrorStatus,
  selectDescription,
  selectExpiry,
  selectIsCreating,
  selectTikkie,
} from './selectors';
import { createTikkie } from './actions';
import { selectUser } from '../../App/selectors';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import { saveTikkie } from '../actions';

function PaymentRequestCreateContainer(props) {
  const history = useHistory();

  const showDashboard = () => {
    history.push('/');
  };

  const showShareTikkie = (
    description,
    invoiceNumber,
    amount,
    isOpenTikkie,
    link,
    token,
    notifications,
    hasBackOption,
    paymentRequest,
  ) => {
    props.saveTikkie({
      description,
      invoiceNumber,
      amount,
      isOpenTikkie,
      link,
      token,
      notifications,
      hasBackOption,
      paymentRequest,
    });
    history.push(`/paymentrequest/${token}/share`);
  };

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }

  return (
    <DashboardWrapper id="dashboard">
      <Container>
        <InnerContainer>
          <PaymentRequestCreate
            isCreating={props.isCreating}
            createTikkieErrorStatus={props.createTikkieErrorStatus}
            createTikkieErrorMessage={props.createTikkieErrorMessage}
            showDashboard={showDashboard}
            showShareTikkie={showShareTikkie}
            defaultExpiryDays={props.user.defaultExpiryDays}
            openTikkieOption={props.user.openAmountOption}
            createTikkie={props.createTikkie}
            tikkie={props.tikkie}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

PaymentRequestCreateContainer.propTypes = {
  user: PropTypes.object,
  isCreating: PropTypes.bool,
  createTikkieErrorStatus: PropTypes.number,
  createTikkieErrorMessage: PropTypes.string,
  tikkie: PropTypes.object,
  createTikkie: PropTypes.func,
  saveTikkie: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  isCreating: selectIsCreating,
  createTikkieErrorStatus: selectCreateTikkieErrorStatus,
  createTikkieErrorMessage: selectCreateTikkieErrorMessage,
  tikkie: selectTikkie,
  amount: selectAmount,
  description: selectDescription,
  expiry: selectExpiry,
});

const mapDispatchToProps = (dispatch) => ({
  createTikkie: (
    amount,
    description,
    invoiceNumber,
    expiryDate,
    isOpenTikkie,
  ) =>
    dispatch(
      createTikkie(
        amount,
        description,
        invoiceNumber,
        expiryDate,
        isOpenTikkie,
      ),
    ),
  saveTikkie: (tikkie) => dispatch(saveTikkie(tikkie)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'paymentRequest/createContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'paymentRequest/createContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PaymentRequestCreateContainer);

import {
  LOAD_SETTINGS,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_ERROR,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  LOAD_API_TOKENS,
  LOAD_API_TOKENS_SUCCESS,
  LOAD_API_TOKENS_ERROR,
  CREATE_API_TOKEN,
  CREATE_API_TOKEN_SUCCESS,
  CREATE_API_TOKEN_ERROR,
  MODIFY_API_TOKEN,
  MODIFY_API_TOKEN_SUCCESS,
  MODIFY_API_TOKEN_ERROR,
  CLEAR_SETTINGS,
  FETCH_AUDIT_LOGS,
  FETCH_AUDIT_LOGS_SUCCESS,
  FETCH_AUDIT_LOGS_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  ADD_USER_TO_ACTIVE_ORGANISATION,
  ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
  ADD_USER_TO_ACTIVE_ORGANISATION_ERROR,
  RESET_ADD_USER_TO_ACTIVE_ORGANISATION,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
  RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  LOAD_PERMITTED_ORGANISATIONS,
  LOAD_PERMITTED_ORGANISATIONS_SUCCESS,
  LOAD_PERMITTED_ORGANISATIONS_ERROR,
  FETCH_PERMITTED_ORGANISATIONS_USERS,
  FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS,
  FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  REQUEST_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_ERROR,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  RESET_UPDATE_USER,
  RESET_DELETE_USER,
  RESET_UPDATE_SETTINGS,
  DEACTIVATE_ORGANISATION,
  DEACTIVATE_ORGANISATION_SUCCESS,
  DEACTIVATE_ORGANISATION_ERROR,
  RESET_DEACTIVATE_ORGANISATION,
} from './constants';

export function loadSettings() {
  return {
    type: LOAD_SETTINGS,
  };
}

export function loadSettingsSuccess(settings) {
  return {
    type: LOAD_SETTINGS_SUCCESS,
    payload: {
      settings,
    },
  };
}

export function loadSettingsError(error) {
  return {
    type: LOAD_SETTINGS_ERROR,
    payload: {
      ...error,
    },
  };
}

export function updateSettings(settings) {
  return {
    type: UPDATE_SETTINGS,
    payload: settings,
  };
}

export function updateSettingsSuccess(updatedSettings) {
  return {
    type: UPDATE_SETTINGS_SUCCESS,
    payload: { updatedSettings },
  };
}

export function updateSettingsError(status, error) {
  return {
    type: UPDATE_SETTINGS_ERROR,
    payload: { status, error },
  };
}

export function resetUpdateSettings() {
  return {
    type: RESET_UPDATE_SETTINGS,
  };
}

export function loadApiTokens() {
  return {
    type: LOAD_API_TOKENS,
  };
}

export function loadApiTokensSuccess(apiTokens) {
  return {
    type: LOAD_API_TOKENS_SUCCESS,
    payload: apiTokens,
  };
}

export function loadApiTokensError(status, error) {
  return {
    type: LOAD_API_TOKENS_ERROR,
    payload: { status, error },
  };
}

export function createApiToken(name, rights) {
  return {
    type: CREATE_API_TOKEN,
    payload: {
      name,
      rights,
    },
  };
}

export function createApiTokenSuccess(apiTokens) {
  return {
    type: CREATE_API_TOKEN_SUCCESS,
    payload: apiTokens,
  };
}

export function createApiTokenError(status, error) {
  return {
    type: CREATE_API_TOKEN_ERROR,
    payload: { status, error },
  };
}

export function modifyApiToken(enabled, name, rights, appToken) {
  return {
    type: MODIFY_API_TOKEN,
    payload: {
      enabled,
      name,
      rights,
      appToken,
    },
  };
}

export function modifyApiTokenSuccess(apiTokens) {
  return {
    type: MODIFY_API_TOKEN_SUCCESS,
    payload: apiTokens,
  };
}

export function modifyApiTokenError(status, error) {
  return {
    type: MODIFY_API_TOKEN_ERROR,
    payload: { status, error },
  };
}

export function clearSettings() {
  return {
    type: CLEAR_SETTINGS,
  };
}

export function fetchAuditLogs(params) {
  return {
    type: FETCH_AUDIT_LOGS,
    payload: {
      params,
    },
  };
}

export function fetchAuditLogsSuccess(auditLogs) {
  return {
    type: FETCH_AUDIT_LOGS_SUCCESS,
    payload: auditLogs,
  };
}

export function fetchAuditLogsError(error) {
  return {
    type: FETCH_AUDIT_LOGS_ERROR,
    payload: {
      ...error,
    },
  };
}

export function fetchUsers(params) {
  return {
    type: FETCH_USERS,
    payload: {
      params,
    },
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  };
}

export function fetchUsersError(error) {
  return {
    type: FETCH_USERS_ERROR,
    payload: {
      ...error,
    },
  };
}

export function addUserToActiveOrganisation(user) {
  return {
    type: ADD_USER_TO_ACTIVE_ORGANISATION,
    payload: {
      user,
    },
  };
}

export function addUserToActiveOrganisationSuccess(user) {
  return {
    type: ADD_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
    payload: user,
  };
}

export function addUserToActiveOrganisationError(error) {
  return {
    type: ADD_USER_TO_ACTIVE_ORGANISATION_ERROR,
    payload: {
      ...error,
    },
  };
}

export function resetAddUserToActiveOrganisation() {
  return {
    type: RESET_ADD_USER_TO_ACTIVE_ORGANISATION,
  };
}

export function addExistingUserToActiveOrganisation(user) {
  return {
    type: ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
    payload: {
      user,
    },
  };
}

export function addExistingUserToActiveOrganisationSuccess(user) {
  return {
    type: ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_SUCCESS,
    payload: user,
  };
}

export function addExistingUserToActiveOrganisationError(error) {
  return {
    type: ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION_ERROR,
    payload: {
      ...error,
    },
  };
}

export function resetAddExistingUserToActiveOrganisation() {
  return {
    type: RESET_ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  };
}

export function loadPermittedOrganisations() {
  return {
    type: LOAD_PERMITTED_ORGANISATIONS,
  };
}

export function loadPermittedOrganisationsSuccess(organisations) {
  return {
    type: LOAD_PERMITTED_ORGANISATIONS_SUCCESS,
    payload: organisations,
  };
}

export function loadPermittedOrganisationsError(error) {
  return {
    type: LOAD_PERMITTED_ORGANISATIONS_ERROR,
    payload: {
      ...error,
    },
  };
}

export function fetchPermittedOrganisationsUsers(params) {
  return {
    type: FETCH_PERMITTED_ORGANISATIONS_USERS,
    payload: { params },
  };
}

export function fetchPermittedOrganisationsUsersSuccess(users) {
  return {
    type: FETCH_PERMITTED_ORGANISATIONS_USERS_SUCCESS,
    payload: users,
  };
}

export function fetchPermittedOrganisationsUsersError(error) {
  return {
    type: FETCH_PERMITTED_ORGANISATIONS_USERS_ERROR,
    payload: {
      ...error,
    },
  };
}

export function fetchUser(id) {
  return {
    type: FETCH_USER,
    payload: { id },
  };
}

export function fetchUserSuccess(user) {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    payload: {
      ...error,
    },
  };
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload,
  };
}

export function resetUpdateUser() {
  return {
    type: RESET_UPDATE_USER,
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  };
}

export function updateUserError(error) {
  return {
    type: UPDATE_USER_ERROR,
    payload: { ...error },
  };
}

export function requestPasswordResetMail(email) {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: { email },
  };
}

export function requestPasswordResetMailSuccess() {
  return {
    type: REQUEST_PASSWORD_RESET_SUCCESS,
  };
}

export function requestPasswordResetMailError(error) {
  return {
    type: REQUEST_PASSWORD_RESET_ERROR,
    payload: { ...error },
  };
}

export function deleteUser(id, allOrganisations) {
  return {
    type: DELETE_USER,
    payload: {
      id,
      allOrganisations,
    },
  };
}

export function deleteUserSuccess() {
  return {
    type: DELETE_USER_SUCCESS,
  };
}

export function deleteUserError(error) {
  return {
    type: DELETE_USER_ERROR,
    payload: { ...error },
  };
}

export function resetDeleteUser() {
  return {
    type: RESET_DELETE_USER,
  };
}

export function deactivateOrganisation(motivation) {
  return {
    type: DEACTIVATE_ORGANISATION,
    payload: motivation,
  };
}

export function deactivationSuccess(deactivationSuccessPayload) {
  return {
    type: DEACTIVATE_ORGANISATION_SUCCESS,
    payload: deactivationSuccessPayload,
  };
}

export function deactivationError(error) {
  return {
    type: DEACTIVATE_ORGANISATION_ERROR,
    payload: {
      ...error,
    },
  };
}

export function resetDeactivation() {
  return {
    type: RESET_DEACTIVATE_ORGANISATION,
  };
}

export const selectBulkUploadErrorStatus = (state) =>
  state.get('bulkCreateContainer').get('bulkUploadErrorStatus');
export const selectBulkUploadErrorMessage = (state) =>
  state.get('bulkCreateContainer').get('bulkUploadErrorMessage');
export const selectBulkUploadValidationErrors = (state) =>
  state.get('bulkCreateContainer').get('bulkUploadValidationErrors');
export const selectBulkUploadFile = (state) =>
  state.get('bulkCreateContainer').get('bulkUploadFile');
export const selectIsUploading = (state) =>
  state.get('bulkCreateContainer').get('isUploading');

export const selectIsDeletingBulkUpload = (state) =>
  state.get('bulkCreateContainer').get('isDeletingBulkUpload');
export const selectDeleteBulkUploadErrorStatus = (state) =>
  state.get('bulkCreateContainer').get('deleteBulkUploadErrorStatus');
export const selectDeleteBulkUploadErrorMessage = (state) =>
  state.get('bulkCreateContainer').get('deleteBulkUploadErrorMessage');

export const selectIsSendingBulkUpload = (state) =>
  state.get('bulkCreateContainer').get('isSendingBulkUpload');
export const selectSendBulkUploadErrorStatus = (state) =>
  state.get('bulkCreateContainer').get('sendBulkUploadErrorStatus');
export const selectSendBulkUploadErrorMessage = (state) =>
  state.get('bulkCreateContainer').get('sendBulkUploadErrorMessage');

/*
 * PaymentRequest shared actions
 */

import {
  SAVE_TIKKIE,
  LOAD_PAYMENT_REQUEST,
  LOAD_PAYMENT_REQUEST_ERROR,
  LOAD_PAYMENT_REQUEST_SUCCESS,
  CLEAR_DATA,
} from './constants';

export function saveTikkie(tikkie) {
  return {
    type: SAVE_TIKKIE,
    payload: tikkie,
  };
}

export function loadPaymentRequest(paymentRequestToken) {
  return {
    type: LOAD_PAYMENT_REQUEST,
    payload: {
      paymentRequestToken,
    },
  };
}

export function loadPaymentRequestSuccess(paymentRequest) {
  return {
    type: LOAD_PAYMENT_REQUEST_SUCCESS,
    payload: {
      paymentRequest,
    },
  };
}

export function loadPaymentRequestError(error) {
  return {
    type: LOAD_PAYMENT_REQUEST_ERROR,
    payload: {
      error,
    },
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

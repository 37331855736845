import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { CaptionSmall, Panel, Space, Color } from '../../styleguide';

const ImagesWrapper = styled(Panel)`
  padding: 32px 32px 8px 32px;
  margin-bottom: 40px;
  display: flex;
  margin-bottom: ${Space.s24};

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
    padding: 24px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding-right: ${Space.s32};

  @media ${(props) => props.theme.device.mobile} {
    border-left: none;
    padding: 0px 0px ${Space.s16} 0px;
    margin-bottom: ${Space.s16};
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const ImageWrapper = styled.a`
  display: block;
  width: 225px;
  height: 155px;
  line-height: 155px;
  *font-size: 155px;
  margin-top: 16px;
  margin-bottom: 46px;
  background: ${Color.gray05};
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
`;

const Image = styled.img`
  max-width: 209px;
  max-height: 140px;
  border-radius: 8px;
`;

const CampaignImage = (props) => (
  <Item>
    <CaptionSmall>{props.label}</CaptionSmall>
    <ImageWrapper href={props.imageUrl} target="_blank">
      <Image src={props.thumbnailUrl} />
    </ImageWrapper>
  </Item>
);

CampaignImage.propTypes = {
  label: PropTypes.object,
  thumbnailUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

const CampaignImages = (props) => (
  <ImagesWrapper>
    {!!props.campaign.imageUrl && (
      <CampaignImage
        label={<FormattedMessage {...messages.imagePage} />}
        thumbnailUrl={props.campaign.imageUrl.small}
        imageUrl={props.campaign.imageUrl.large}
      />
    )}
    {!!props.campaign.cardImageUrl && (
      <CampaignImage
        label={<FormattedMessage {...messages.imageCard} />}
        thumbnailUrl={props.campaign.cardImageUrl.small}
        imageUrl={props.campaign.cardImageUrl.large}
      />
    )}
  </ImagesWrapper>
);

CampaignImages.propTypes = {
  campaign: PropTypes.object.isRequired,
};

export default CampaignImages;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container, PageTitle } from 'amber';
import * as messages from './messages';
import {
  AddButton,
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
  SelectWrapper,
} from '../../styledComponents';
import UserManagementFilters from '../UserManagementFilters';
import { List } from '../../../List';
import { UserListItem } from '../../../User/UserListItem';
import { isEmpty } from '../../../../utils';
import { FooterContent, FooterWrapper } from '../../../Footer';
import { Color } from '../../../styleguide';
import { ROLE_ADMIN, ROLE_USER } from '../../../../global-constants';
import { uppercaseFirstLetterAndLowercaseTheRest } from '../../../../utils/strings';
import { GifjeContainer, GifjeWrapper } from '../../../Gif';
import { GiphyBox } from '../../../components';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsPageview,
} from '../../../../ga4';

const FooterUsername = styled.div`
  color: #57565b;
  font-size: 16px;
  font-family: MuseoSansRounded;
  align-self: flex-start;
  margin-right: 130px;
  margin-left: 15px;

  @media ${(props) => props.theme.device.mobile} {
    margin: 0px;
    width: 100%;
    text-align: center;
  }
`;

const FooterAddButtonWrapper = styled.div`
  flex: 1;
  justify-self: flex-end;
`;

function AddExistingUser({
  gotoPortalOverview,
  goBack,
  users,
  usersLoading,
  usersError,
  fetchUsers,
  permittedOrganisations,
  permittedOrganisationsError,
  addExistingUserToActiveOrganisationSuccess,
  addExistingUserToActiveOrganisationError,
  addExistingUserToActiveOrganisation,
  resetAddExistingUserToActiveOrganisation,
}) {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [selectedRoleToCreateUser, setSelectedRoleToCreateUser] =
    useState(null);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [userSelected, setUserSelected] = useState(undefined);

  const availableRoles = [ROLE_ADMIN, ROLE_USER];
  const availableRolesToCreateUser = availableRoles.map((role) => ({
    id: role,
    label: uppercaseFirstLetterAndLowercaseTheRest(role),
  }));

  useEffect(() => {
    analyticsPageview();
  }, []);

  useEffect(
    () => () => resetAddExistingUserToActiveOrganisation(),
    [resetAddExistingUserToActiveOrganisation],
  );

  const hasErrors =
    !isEmpty(usersError) ||
    !isEmpty(addExistingUserToActiveOrganisationError) ||
    !isEmpty(permittedOrganisationsError);
  const loading = usersLoading && !hasErrors;

  const pageSize = 20;

  useEffect(() => {
    if (
      !userSelected ||
      !availableRolesToCreateUser
        .map((role) => role.label)
        .includes(selectedRoleToCreateUser)
    ) {
      setCreateButtonDisabled(true);
    } else {
      setCreateButtonDisabled(false);
    }
  }, [userSelected, selectedRoleToCreateUser, availableRolesToCreateUser]);

  useEffect(() => {
    fetchUsers({
      size: pageSize,
      page: 0,
      orgId: selectedOrg,
      search: searchText,
    });
  }, [fetchUsers, pageSize, selectedOrg, searchText]);

  useEffect(() => {
    if (!isEmpty(addExistingUserToActiveOrganisationSuccess)) {
      setUserSelected(null);
      resetAddExistingUserToActiveOrganisation();
      fetchUsers({
        size: pageSize,
        page: 0,
        orgId: selectedOrg,
        search: searchText,
      });
    }
  }, [
    addExistingUserToActiveOrganisationSuccess,
    pageSize,
    searchText,
    resetAddExistingUserToActiveOrganisation,
    fetchUsers,
  ]);

  if (hasErrors) {
    if (
      usersError.statusCode === 403 ||
      addExistingUserToActiveOrganisationError.statusCode === 403 ||
      permittedOrganisationsError.statusCode === 403
    ) {
      return ErrorPageNoContact({
        pageTitle: messages.title,
        showUnauthorizedDescription: true,
        onClickBackOverview: gotoPortalOverview,
      });
    }
    return ErrorPageWithContact({
      pageTitle: messages.title,
      errorType: 'ContactSupport',
      onClickBackOverview: gotoPortalOverview,
    });
  }

  const renderUserItem = (user) => {
    const selected = user.id === userSelected?.id;
    return (
      <UserListItem
        selected={selected}
        key={user.id}
        user={user}
        dataTestId="user-management-add-existing-user-user-list-item"
        onClick={() => selectUserInTheList(user)}
      />
    );
  };

  const renderUserNoItem = () => (
    <GifjeContainer>
      <GifjeWrapper>
        <GiphyBox
          gif="no-results.gif"
          title={messages.noResultsTitle}
          text={messages.noResultsText}
        />
      </GifjeWrapper>
    </GifjeContainer>
  );

  const onPageChange = (page) => {
    fetchUsers({ size: pageSize, page });
  };

  const permittedOrgs = permittedOrganisations.map((org) => ({
    id: org.id.toString(),
    label: org.name,
  }));

  const selectUserInTheList = (user) => {
    if (userSelected?.id === user.id) {
      setUserSelected(null);
    } else {
      setUserSelected(user);
    }
  };

  const addExistingUser = () => {
    if (userSelected) {
      const newUser = {
        ...userSelected,
        functionalRole: selectedRoleToCreateUser.toUpperCase(),
      };
      analyticsEvent(
        AnalyticCategories.SETTINGS,
        'business-portal-AddExistingUser-add-button',
      );
      addExistingUserToActiveOrganisation(newUser);
    }
  };

  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton
        goBack={goBack}
        text={messages.backButtonText}
        dataTestId="user-management-add-existing-user-back-button"
      />
      <PageTitle>{messages.title}</PageTitle>
      <div style={{ flex: 1 }}>
        <UserManagementFilters
          selectedFilter={selectedOrg}
          filterOptions={permittedOrgs}
          searchText={searchText}
          onFilterSubmit={setSelectedOrg}
          filterTitle="Locatie"
          onSearch={setSearchText}
        />
        <List
          pagination
          loading={loading}
          pageSize={pageSize}
          totalItems={users.totalElements}
          items={users.users}
          onPageChange={onPageChange}
          renderItem={renderUserItem}
          renderNoItem={renderUserNoItem}
        />
      </div>
      {userSelected && (
        <FooterWrapper slideIn={userSelected}>
          <FooterContent>
            <FooterUsername data-test-id="footer-username">{`${userSelected.firstName} ${userSelected.lastName}`}</FooterUsername>
            <SelectWrapper
              rounded
              textColor={Color.gray01}
              color={Color.gray07}
              data-test-id="select-drop-down"
            >
              <select
                value={
                  selectedRoleToCreateUser || messages.roleToCreateUserText
                }
                onChange={(e) => setSelectedRoleToCreateUser(e.target.value)}
              >
                <option value={null} key={messages.roleToCreateUserText}>
                  {messages.roleToCreateUserText}
                </option>
                {availableRolesToCreateUser.map((role) => (
                  <option value={role.label} key={role.label}>
                    {role.label}
                  </option>
                ))}
              </select>
            </SelectWrapper>
            <FooterAddButtonWrapper>
              <AddButton
                dataTestId="user-management-add-existing-user-add-user-button"
                type="secondary"
                text={messages.addUserButtonText}
                alt={messages.addUserButtonText}
                relative
                disabled={createButtonDisabled}
                onClick={addExistingUser}
              />
            </FooterAddButtonWrapper>
          </FooterContent>
        </FooterWrapper>
      )}
    </Container>
  );
}

AddExistingUser.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  users: PropTypes.shape({
    users: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  usersLoading: PropTypes.bool.isRequired,
  usersError: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  permittedOrganisations: PropTypes.array.isRequired,
  permittedOrganisationsError: PropTypes.object.isRequired,
  addExistingUserToActiveOrganisation: PropTypes.func.isRequired,
  addExistingUserToActiveOrganisationSuccess: PropTypes.object.isRequired,
  addExistingUserToActiveOrganisationError: PropTypes.object.isRequired,
  resetAddExistingUserToActiveOrganisation: PropTypes.func.isRequired,
};

export default AddExistingUser;

export const SEND_BULK_UPLOAD = 'app/BulkDetailContainer/SEND_BULK_UPLOAD';
export const SEND_BULK_UPLOAD_SUCCESS =
  'app/BulkDetailContainer/SEND_BULK_UPLOAD_SUCCESS';
export const SEND_BULK_UPLOAD_ERROR =
  'app/BulkDetailContainer/SEND_BULK_UPLOAD_ERROR';

export const LOAD_BULK_FILE = 'app/BulkDetailContainer/LOAD_BULK_FILE';
export const LOAD_BULK_FILE_SUCCESS =
  'app/BulkDetailContainer/LOAD_BULK_FILE_SUCCESS';
export const LOAD_BULK_FILE_ERROR =
  'app/BulkDetailContainer/LOAD_BULK_FILE_ERROR';

export const LOAD_PAYMENT_REQUESTS =
  'app/BulkDetailContainer/LOAD_PAYMENT_REQUESTS';
export const LOAD_PAYMENT_REQUESTS_SUCCESS =
  'app/BulkDetailContainer/LOAD_PAYMENT_REQUESTS_SUCCESS';
export const LOAD_PAYMENT_REQUESTS_ERROR =
  'app/BulkDetailContainer/LOAD_PAYMENT_REQUESTS_ERROR';

export const DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS =
  'app/BulkDetailContainer/DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS';
export const CLEAR_DATA = 'app/BulkDetailContainer/CLEAR_DATA';

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import { fetchAuditLogs } from '../actions';
import {
  selectAuditLogs,
  selectAuditLogsError,
  selectAuditLogsLoading,
} from '../selectors';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../components/components';
import EmptyLoader from '../../../components/Util/Loader';
import AuditLog from '../../../components/Settings/AuditLog';

function AuditLogContainer({
  settingsLoading,
  auditLogs,
  auditLogsLoading,
  auditLogsError,
  getAuditLogs,
}) {
  const history = useHistory();

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  return (
    <>
      {settingsLoading && <EmptyLoader />}
      {!settingsLoading && (
        <DashboardWrapper>
          <Container>
            <InnerContainer highMargin>
              <AuditLog
                gotoPortalOverview={gotoPortalOverview}
                gotoSettingsDashboard={gotoSettingsDashboard}
                auditLogs={auditLogs}
                auditLogsLoading={auditLogsLoading}
                auditLogsError={auditLogsError}
                fetchAuditLogs={getAuditLogs}
              />
            </InnerContainer>
          </Container>
        </DashboardWrapper>
      )}
    </>
  );
}

AuditLogContainer.propTypes = {
  settingsLoading: PropTypes.bool,
  auditLogs: PropTypes.shape({
    auditLogs: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  auditLogsLoading: PropTypes.bool.isRequired,
  auditLogsError: PropTypes.object.isRequired,
  getAuditLogs: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  auditLogs: selectAuditLogs,
  auditLogsLoading: selectAuditLogsLoading,
  auditLogsError: selectAuditLogsError,
});

const mapDispatchToProps = (dispatch) => ({
  getAuditLogs: (pagination) => dispatch(fetchAuditLogs(pagination)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'AuditLogContainer', saga });

export default compose(withSaga, withConnect)(AuditLogContainer);

/**
 *
 * Otp
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import ReactBodymovin from 'react-bodymovin';

import '!file-loader?name=[name].[ext]!../../../images/checkmark.svg';

import greenAnimationData from '../../../images/animations/green-dots.json';
import whiteAnimationData from '../../../images/animations/white-dots.json';
import messages from './messages';

import {
  Header2,
  Color,
  PrimaryButton,
  Input,
  TertiaryButton,
  Paragraph,
} from '../../styleguide';

import {
  LightBox,
  LightBoxBackArrow,
  LightBoxErrorMessageBanner,
} from '../../components';

const OtpWrapper = styled(LightBox)``;

const BackArrow = styled(LightBoxBackArrow)``;

const Header = styled(Header2)`
  padding-bottom: 16px;
`;

const Info = styled(Paragraph)``;

const Pin = styled.div`
  position: relative;
  margin: 26px 0px;
  height: 62px;

  @media ${(props) => props.theme.device.mobileSmall} {
    margin: 20px 0px;
    height: 50px;
  }

  @media ${(props) => props.theme.device.mobileMedium} {
    height: 58px;
  }

  @media ${(props) => props.theme.device.mobileLarge} {
    height: 62px;
  }
`;

const PinCircle = styled.div`
  float: left;
  width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 13px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #ffffff;
  border: 2px solid ${Color.gray04};
  font-size: 24px;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 20px;
    width: 48px;
    height: 56px;
    line-height: 44px;
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    font-size: 18px;
    margin-right: 8px;
    width: 40px;
    height: 48px;
    line-height: 37px;
  }

  @media ${(props) => props.theme.device.mobileMedium} {
    font-size: 20px;
    margin-right: 10px;
    width: 48px;
    height: 56px;
    line-height: 46px;
  }

  @media ${(props) => props.theme.device.mobileLarge} {
    font-size: 22px;
    margin-right: 13px;
    width: 50px;
    height: 60px;
    line-height: 50px;
  }
`;

const FilledPinCircle = styled(PinCircle)`
  background-color: ${Color.brandColor01};
  border: 2px solid transparent;
  color: ${Color.white};
`;

const FocusPinCircle = styled(PinCircle)`
  border: solid 2px ${Color.brandColor01};
  color: ${Color.brandColor01};
`;

const Remember = styled.div`
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-family: MuseoSansRounded;
  font-size: 16px;
  font-weight: normal;
  color: #bebebf;
  cursor: pointer;
  user-select: none;

  span {
    vertical-align: top;
  }
`;

const Checkbox = styled.img`
  display: inline-block;
  padding: 4px;
  width: 24px;
  height: 24px;
  margin-right: 9px;
  border-radius: 4px;
  border: 2px solid ${Color.brandColor01};
  background-color: ${Color.brandColor01};
`;

const EmptyCheckbox = styled.div`
  display: inline-block;
  padding: 6px;
  width: 24px;
  height: 24px;
  margin-right: 9px;
  border-radius: 4px;
  border: solid 2px #e9e9e9;
  background-color: #f7f7f9;
`;

const Footer = styled.div`
  position: absolute;
  height: 50px;
  line-height: 50px;
  padding: 0 8px;
  bottom: 40px;
  left: 40px;
  right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    height: 96px;
    line-height: auto;
  }
`;

const ResendOtpWrapper = styled.div`
  float: left;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
    margin-top: 16px;
    height: auto;
    line-height: normal;
  }
`;

const ResendOtp = styled(TertiaryButton)`
  height: 48px;
  line-height: 48px;
`;

const ResendOtpStatic = styled(ResendOtp)`
  cursor: default;
`;

const ButtonWrapper = styled.div`
  float: right;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
  }
`;

const Button = styled(PrimaryButton)`
  width: 166px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 231px;
  }

  @media ${(props) => props.theme.device.mobileSmall} {
    min-width: 200px;
  }
`;

const ErrorMessage = styled(LightBoxErrorMessageBanner)`
  position: absolute;
  top: 250px;
  left: 40px;
  right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    top: 255px;
    left: 16px;
    right: 16px;
  }

  @media ${(props) => props.theme.device.mobileLarge} {
    top: 260px;
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  width: 200%;
  border: none;
  outline: none;
  top: 0px;
  bottom: 0px;
  opacity: 0;
  margin-left: -100%;
`;

const AnimationWrapper = styled.div`
  .react-bodymovin-container {
    height: 22px;
  }

  @media ${(props) => props.theme.device.mobile} {
    height: 18px;
  }
`;

const ButtonAnimationWrapper = styled(AnimationWrapper)`
  height: 18px;

  .react-bodymovin-container {
    height: 18px;
  }
`;

const PinInput = styled(Input)`
  letter-spacing: 6px;
`;

const KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const PIN_SIZE = 5;
const AQUIRE = 'aquire-otp';
const USE_OTP = 'use-otp';
const RESEND = 'resend-otp';

const greenOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: greenAnimationData,
};

const whiteOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: whiteAnimationData,
};

let mounted = false;

class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: [],
      otpError: false,
      otpSendError: false,
      otpExpired: false,
      otpTooFreshError: false,
      loggingIn: false,
      resending: false,
      resent: false,
      resend: true,
    };
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.resent !== this.state.resent) {
      if (this.state.resent) {
        setTimeout(() => {
          if (mounted) {
            this.setState({ resent: false, resend: true });
          }
        }, 30000);
      }
    }

    if (oldProps.isLoading !== this.props.isLoading) {
      if (this.props.blockedUser) {
        this.props.onShowLogin();
        return;
      }

      const otp =
        this.props.invalidOtpCode || this.props.expiredOtpCode
          ? []
          : oldState.otp;
      const otpError =
        this.props.invalidOtpCode && this.props.action !== AQUIRE;
      const otpSendError = this.props.loginSmsSendFailed;
      const otpTooFreshError = this.props.tooFreshOtpCode;
      const loggingIn = this.props.isLoading && this.props.action === USE_OTP;
      const resending = this.props.isLoading && this.props.action === RESEND;
      const resent = this.props.expiredOtpCode && this.props.action === RESEND;
      const resend = !resending && !resent;
      const otpExpired =
        this.props.expiredOtpCode && this.props.action === USE_OTP;

      this.setState({
        otp,
        otpError,
        otpSendError,
        otpTooFreshError,
        loggingIn,
        resending,
        resent,
        resend,
        otpExpired,
      });
    }
  }

  componentDidMount() {
    if (!isMobile) {
      document.addEventListener('keydown', this.onKeyDown);
    }
    mounted = true;
  }

  componentWillUnmount() {
    if (!isMobile) {
      document.removeEventListener('keydown', this.onKeyDown);
    }
    mounted = false;
  }

  onKeyDown = (event) => {
    const { otp } = this.state;
    const code = event.keyCode || event.charCode;

    switch (code) {
      case 8:
        this.clearErrors();
        if (otp.length > 0) {
          otp.pop();
        }
        break;
      case 27:
        this.clearErrors();
        otp.length = 0;
        break;
      default:
        this.clearErrors();
        if (otp.length < PIN_SIZE && KEYS.indexOf(event.key) !== -1) {
          otp.push(event.key);
        }
        break;
    }

    this.setState({ otp });

    if (otp.length === PIN_SIZE && event.key === 'Enter') {
      this.onLoginWithOtp();
    }
  };

  onLoginWithOtp = () => {
    const { username, password, rememberMe } = this.props;
    this.props.onLoginUser(
      username,
      password,
      this.getOtp(),
      rememberMe,
      USE_OTP,
    );
  };

  onResend = () => {
    this.setState({ otp: [] });
    this.props.onResendOtp();
  };

  disableLoginButton = () => this.state.otp.length < PIN_SIZE;

  getOtp = () => this.state.otp.join('');

  setOtp = (event) => {
    const otp = event.target.value.split('');
    this.setState({ otp });
  };

  showOtpError = () => this.state.otpError;

  showSmsSendError = () => this.state.otpSendError;

  showTooFreshOtpError = () => this.state.otpTooFreshError;

  showLoggingIn = () => this.state.loggingIn;

  showResendingOtp = () => this.state.resending;

  showOtpResent = () => this.state.resent;

  showOtpResend = () => this.state.resend;

  showOtpExpiredError = () => this.state.otpExpired;

  clearErrors = () => {
    this.setState({
      otpError: false,
      otpSendError: false,
      otpTooFreshError: false,
      otpExpired: false,
    });
  };

  render() {
    const { onToggleRememberMe, rememberMe } = this.props;

    const { otp } = this.state;
    const { length } = otp;

    return (
      <OtpWrapper onClick={this.clearErrors}>
        <BackArrow src="/arrow-back.svg" onClick={this.props.onShowLogin} />
        <Header>
          <FormattedMessage {...messages.header} />
        </Header>
        <Info>
          <FormattedMessage {...messages.info} />
        </Info>
        {isMobile && (
          <Pin>
            <PinInput
              onChange={this.setOtp}
              id="pinInput"
              placeholder={12345}
              autoFocus
              autoComplete="off"
              maxLength={5}
              value={this.getOtp()}
            />
          </Pin>
        )}
        {!isMobile && length === 0 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <PinCircle id="circle-1" />
            <PinCircle id="circle-2" />
            <PinCircle id="circle-3" />
            <PinCircle id="circle-4" />
            <PinCircle id="circle-5" />
          </Pin>
        )}
        {!isMobile && length === 1 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <FilledPinCircle id="circle-1">{otp[0]}</FilledPinCircle>
            <FocusPinCircle id="circle-2" />
            <PinCircle id="circle-3" />
            <PinCircle id="circle-4" />
            <PinCircle id="circle-5" />
          </Pin>
        )}
        {!isMobile && length === 2 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <FilledPinCircle id="circle-1">{otp[0]}</FilledPinCircle>
            <FilledPinCircle id="circle-2">{otp[1]}</FilledPinCircle>
            <FocusPinCircle id="circle-3" />
            <PinCircle id="circle-4" />
            <PinCircle id="circle-5" />
          </Pin>
        )}
        {!isMobile && length === 3 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <FilledPinCircle id="circle-1">{otp[0]}</FilledPinCircle>
            <FilledPinCircle id="circle-2">{otp[1]}</FilledPinCircle>
            <FilledPinCircle id="circle-3">{otp[2]}</FilledPinCircle>
            <FocusPinCircle id="circle-4" />
            <PinCircle id="circle-5" />
          </Pin>
        )}
        {!isMobile && length === 4 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <FilledPinCircle id="circle-1">{otp[0]}</FilledPinCircle>
            <FilledPinCircle id="circle-2">{otp[1]}</FilledPinCircle>
            <FilledPinCircle id="circle-3">{otp[2]}</FilledPinCircle>
            <FilledPinCircle id="circle-4">{otp[3]}</FilledPinCircle>
            <FocusPinCircle id="circle-5" />
          </Pin>
        )}
        {!isMobile && length === 5 && (
          <Pin>
            <HiddenInput
              autoFocus
              id="pinInput"
              type="number"
              pattern="[0-9]*"
            />
            <FilledPinCircle id="circle-1">{otp[0]}</FilledPinCircle>
            <FilledPinCircle id="circle-2">{otp[1]}</FilledPinCircle>
            <FilledPinCircle id="circle-3">{otp[2]}</FilledPinCircle>
            <FilledPinCircle id="circle-4">{otp[3]}</FilledPinCircle>
            <FilledPinCircle id="circle-5">{otp[4]}</FilledPinCircle>
          </Pin>
        )}
        {this.showOtpError() && (
          <ErrorMessage>
            <FormattedMessage {...messages.incorrectOtpError} />
          </ErrorMessage>
        )}
        {this.showTooFreshOtpError() && (
          <ErrorMessage>
            <FormattedMessage {...messages.otpTooFreshError} />
          </ErrorMessage>
        )}
        {this.showSmsSendError() && (
          <ErrorMessage>
            <FormattedMessage {...messages.smsSendError} />
          </ErrorMessage>
        )}
        {this.showOtpExpiredError() && (
          <ErrorMessage>
            <FormattedMessage {...messages.otpExpiredError} />
          </ErrorMessage>
        )}
        <Remember onClick={onToggleRememberMe}>
          {rememberMe ? <Checkbox src="/checkmark.svg" /> : <EmptyCheckbox />}
          <FormattedMessage {...messages.remember} />
        </Remember>
        <Footer>
          <ButtonWrapper id="confirmOtp">
            <Button
              onClick={this.onLoginWithOtp}
              disabled={this.disableLoginButton()}
            >
              {this.showLoggingIn() && (
                <ButtonAnimationWrapper>
                  <ReactBodymovin options={whiteOptions} />
                </ButtonAnimationWrapper>
              )}
              {!this.showLoggingIn() && (
                <FormattedMessage {...messages.button} />
              )}
            </Button>
          </ButtonWrapper>
          <ResendOtpWrapper>
            {this.showResendingOtp() && (
              <AnimationWrapper>
                <ReactBodymovin options={greenOptions} />
              </AnimationWrapper>
            )}
            {this.showOtpResent() && (
              <ResendOtpStatic id="resendOtpCode">
                <FormattedMessage {...messages.otpLinkHasBeenResent} />
              </ResendOtpStatic>
            )}
            {this.showOtpResend() && (
              <ResendOtp id="resendOtpCode" onClick={this.onResend}>
                <FormattedMessage {...messages.resendOtpLink} />
              </ResendOtp>
            )}
          </ResendOtpWrapper>
        </Footer>
      </OtpWrapper>
    );
  }
}

Otp.propTypes = {
  tooFreshOtpCode: PropTypes.bool,
  invalidOtpCode: PropTypes.bool,
  blockedUser: PropTypes.bool,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccessful: PropTypes.bool.isRequired,
  expiredOtpCode: PropTypes.bool.isRequired,
  loginSmsSendFailed: PropTypes.bool.isRequired,
  onShowLogin: PropTypes.func.isRequired,
  onLoginUser: PropTypes.func.isRequired,
  onResendOtp: PropTypes.func.isRequired,
  onToggleRememberMe: PropTypes.func.isRequired,
};

export default Otp;

/**
 *
 * BulkList
 *
 */

import '!file-loader?name=[name].[ext]!../../../images/gifs/oops.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/no-results.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/happy-dance.gif';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import Pagination from 'rc-pagination';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

import BulkLineItem from '../BulkLineItem';

import messages from './messages';
import {
  Color,
  Header1,
  Panel,
  Tabs,
  Content,
  TabItem,
} from '../../styleguide';
import { GiphyBox } from '../../components';

import animationData from '../../../images/animations/green-dots.json';

const BulkListWrapper = styled.div``;

const Header = styled(Header1)`
  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 116px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 276px;
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const options = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

/* eslint-disable react/prefer-stateless-function */
class BulkList extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const oopsTitle = intl.formatMessage({
      id: 'app.components.BulkList.oopsTitle',
    });

    const oopsText = intl.formatMessage({
      id: 'app.components.BulkList.oopsText',
    });

    const oopsButton = intl.formatMessage({
      id: 'app.components.BulkList.oopsButton',
    });

    const firstUseTitle = intl.formatMessage({
      id: 'app.components.BulkList.firstUseTitle',
    });

    const firstUseText = intl.formatMessage({
      id: 'app.components.BulkList.firstUseText',
    });

    this.state = {
      oopsTitle,
      oopsText,
      oopsButton,
      firstUseTitle,
      firstUseText,
    };

    this.loadBulkFiles();
  }

  loadBulkFiles = (page = this.props.currentPage) =>
    this.props.loadBulkFiles(page);

  showPaging = () => this.props.totalBulkFiles > 20;

  onChangePaging = (currentPage) => {
    this.loadBulkFiles(currentPage);
  };

  hasNoBulkFiles = () =>
    !this.props.bulkFilesIsLoading && this.props.bulkFiles.length === 0;

  showNoBulkFilesFirstUse = () => this.hasNoBulkFiles();

  render() {
    return (
      <BulkListWrapper>
        <Header>
          <FormattedMessage {...messages.header} />
        </Header>
        <Tabs>
          <TabItem to="/" active={0}>
            <FormattedMessage {...messages.tabAll} />
          </TabItem>
          <TabItem to="/bulk" active={1}>
            <FormattedMessage {...messages.tabBulkUploads} />
          </TabItem>
        </Tabs>
        {this.props.bulkFiles && (
          <Content>
            {!!this.props.bulkFilesErrorMessage && (
              <GifjeContainer>
                <GifjeWrapper>
                  <GiphyBox
                    gif="oops.gif"
                    onClick={() => {
                      this.props.loadBulkFiles(1, '', 0, 0, [], '');
                    }}
                    title={this.state.oopsTitle}
                    text={this.state.oopsText}
                    button={this.state.oopsButton}
                  />
                </GifjeWrapper>
              </GifjeContainer>
            )}
            {this.showNoBulkFilesFirstUse() && (
              <GifjeContainer>
                <GifjeWrapper>
                  <GiphyBox
                    gif="no-results.gif"
                    title={this.state.firstUseTitle}
                    text={this.state.firstUseText}
                  />
                </GifjeWrapper>
              </GifjeContainer>
            )}
            {this.props.bulkFilesIsLoading && (
              <LoaderWrapper>
                <AnimationWrapper>
                  <ReactBodymovin options={options} />
                </AnimationWrapper>
              </LoaderWrapper>
            )}
            {this.props.bulkFiles.map((bulkFile) => (
              <BulkLineItem
                key={bulkFile.id}
                uploadedDateTime={bulkFile.uploadedDateTime}
                status={bulkFile.status}
                fileName={bulkFile.fileName}
                totalTikkiesPaidAtLeastOnce={
                  bulkFile.paymentRequestBatchPaymentInformation
                    .totalTikkiesPaidAtLeastOnce
                }
                totalTikkiesInBatch={
                  bulkFile.paymentRequestBatchPaymentInformation
                    .totalTikkiesInBatch
                }
                showBulkDetail={() => this.props.showBulkDetail(bulkFile)}
              />
            ))}
            {this.showPaging() && (
              <PagingWrapper>
                <Pagination
                  onChange={this.onChangePaging}
                  defaultCurrent={1}
                  current={this.props.currentPage}
                  pageSize={20}
                  simple={isMobileOnly}
                  showLessItems={isTablet}
                  total={this.props.totalBulkFiles}
                  prevIcon={<NavPrev src="back.svg" />}
                  nextIcon={<NavNext src="back.svg" />}
                  showTitle={false}
                />
              </PagingWrapper>
            )}
          </Content>
        )}
      </BulkListWrapper>
    );
  }
}

BulkList.propTypes = {
  bulkFiles: PropTypes.array.isRequired,
  totalBulkFiles: PropTypes.number.isRequired,
  bulkFilesIsLoading: PropTypes.bool.isRequired,
  bulkFilesErrorMessage: PropTypes.string,
  intl: PropTypes.object.isRequired,
  loadBulkFiles: PropTypes.func.isRequired,
  showBulkDetail: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default injectIntl(BulkList);

/**
 *
 * BulkFile
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '!file-loader?name=[name].[ext]!../../../images/bulkfileicon.svg';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Color } from '../../styleguide';
import { formatDate } from '../../../utils';
import messages from './messages';

import animationData from '../../../images/animations/green-dots.json';

const BulkLineItemWrapper = styled.div`
  position: relative;
  display: block;
  height: 72px;
  line-height: 72px;
  padding: 0 24px 0 0;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-left: 8px solid ${(props) => props.color};
  vertical-align: middle;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;

  :hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
  }

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  width: auto;
  margin-right: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    margin-right: 0;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 20px 16px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin: 0px 0px 16px 0px;
  }
`;

const ItemIcon = styled.img``;

const Created = styled.div`
  float: left;
  margin-right: 32px;
  width: 80px;
  height: 72px;
  line-height: 72px;
  color: ${Color.gray03};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
`;

const Status = styled.div`
  float: right;
  height: 36px;
  min-width: 104px;
  line-height: 36px;
  margin: 18px 0px;
  padding: 0px 17px;
  border-radius: 40px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
    right: 16px;
    top: 0px;
  }
`;

const FileName = styled.div`
  float: left;
  width: 500px;
  padding-right: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.tablet} {
    width: 260px;
  }
`;

const Progress = styled.div`
  font-family: MuseoSansRounded;
`;

const AnimationWrapper = styled.div`
  .react-bodymovin-container {
    height: 20px;
  }
`;

const options = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

const BulkLineItem = injectIntl((props) => {
  const determineStatusColor = (status) => {
    switch (status) {
      case 'SENT':
        return Color.brandColor01;
      case 'SENDING':
        return Color.secondaryColor02;
      case 'READY':
        return Color.secondaryColor02;
      case 'ERROR':
        return Color.errorColor;
      default:
        return 'red';
    }
  };

  const determineBackgroundColor = (status) => {
    switch (status) {
      case 'SENT':
        return '#EEF9F8';
      case 'SENDING':
        return 'transparent';
      case 'READY':
        return '#FCF9ED';
      case 'ERROR':
        return '#FDF6F7';
      default:
        return 'red';
    }
  };

  const determineStatusText = (status) => {
    const ready = props.intl.formatMessage({
      id: 'app.components.BulkLineItem.ready',
    });
    const error = props.intl.formatMessage({
      id: 'app.components.BulkLineItem.error',
    });
    const sent = props.intl.formatMessage({
      id: 'app.components.BulkLineItem.sent',
    });

    switch (status) {
      case 'SENT':
        return sent;
      case 'SENDING':
        return '';
      case 'READY':
        return ready;
      case 'ERROR':
        return error;
      default:
        return '** error **';
    }
  };

  return (
    <BulkLineItemWrapper
      color={determineStatusColor(props.status)}
      onClick={props.showBulkDetail}
    >
      <ItemIconWrapper>
        <ItemIcon src="bulkfileicon.svg" />
      </ItemIconWrapper>
      <Status
        id={props.status}
        name="tikkieBulkFileStatus"
        color={determineStatusColor(props.status)}
        background={determineBackgroundColor(props.status)}
      >
        {props.status !== 'SENDING' && (
          <span>{determineStatusText(props.status)}</span>
        )}
        {props.status === 'SENDING' && (
          <AnimationWrapper id="sendingAnim">
            <ReactBodymovin options={options} />
          </AnimationWrapper>
        )}
      </Status>
      <ContentWrapper>
        <Created id="tikkieBulkFileCreationDate">
          <FormattedMessage {...messages.yesterday}>
            {(yesterdayText) => (
              <span>{formatDate(yesterdayText, props.uploadedDateTime)}</span>
            )}
          </FormattedMessage>
        </Created>
        <FileName id="tikkieBulkFileName">{props.fileName}</FileName>
        <Progress id="tikkieBulkProgress">
          {props.totalTikkiesPaidAtLeastOnce}/{props.totalTikkiesInBatch}
        </Progress>
      </ContentWrapper>
    </BulkLineItemWrapper>
  );
});

BulkLineItem.propTypes = {
  uploadedDateTime: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  totalTikkiesPaidAtLeastOnce: PropTypes.number.isRequired,
  totalTikkiesInBatch: PropTypes.number.isRequired,
  showBulkDetail: PropTypes.func.isRequired,
};

export default BulkLineItem;

import {
  DOWNLOAD_SINGLE_PAYMENT_REQUEST,
  LOAD_PAYMENTS,
  LOAD_PAYMENTS_ERROR,
  LOAD_PAYMENTS_SUCCESS,
  CLEAR_DATA,
  ARCHIVE_PAYMENT_REQUEST,
  ARCHIVE_PAYMENT_REQUEST_SUCCESS,
  ARCHIVE_PAYMENT_REQUEST_ERROR,
  PATCH_PAYMENT_REQUEST,
  PATCH_PAYMENT_REQUEST_ERROR,
} from './constants';

export function downloadSinglePaymentRequest(token) {
  return {
    type: DOWNLOAD_SINGLE_PAYMENT_REQUEST,
    payload: {
      token,
    },
  };
}

export function loadPayments(page, paymentRequestToken) {
  return {
    type: LOAD_PAYMENTS,
    payload: {
      page,
      paymentRequestToken,
    },
  };
}

export function loadPaymentsSuccess(payments) {
  return {
    type: LOAD_PAYMENTS_SUCCESS,
    payload: {
      payments,
    },
  };
}

export function loadPaymentsError(error) {
  return {
    type: LOAD_PAYMENTS_ERROR,
    payload: {
      error,
    },
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export function archivePaymentRequest(token) {
  return {
    type: ARCHIVE_PAYMENT_REQUEST,
    payload: {
      token,
    },
  };
}

export function archivePaymentRequestError(error) {
  return {
    type: ARCHIVE_PAYMENT_REQUEST_ERROR,
    payload: {
      error,
    },
  };
}

export function archivePaymentRequestSuccess() {
  return {
    type: ARCHIVE_PAYMENT_REQUEST_SUCCESS,
  };
}

export function editPaymentRequest(token, expireDate) {
  return {
    type: PATCH_PAYMENT_REQUEST,
    payload: {
      token,
      expireDate,
    },
  };
}

export function editPaymentRequestError(error) {
  return {
    type: PATCH_PAYMENT_REQUEST_ERROR,
    payload: {
      error,
    },
  };
}

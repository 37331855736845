import moment from 'moment';

const getDatesByLabel = (option) => {
  const selectedDate = {};
  selectedDate.toDate = moment().valueOf();
  if (option === 'today') {
    selectedDate.fromDate = moment().startOf('day').valueOf();
  } else if (option === 'thisWeek') {
    selectedDate.fromDate = moment()
      .subtract(1, 'week')
      .endOf('week')
      .add(1, 'second')
      .valueOf();
  } else if (option === 'thisMonth') {
    selectedDate.fromDate = moment()
      .subtract(1, 'month')
      .endOf('month')
      .add(1, 'second')
      .valueOf();
  } else if (option === 'thisYear') {
    selectedDate.fromDate = moment()
      .subtract(1, 'year')
      .endOf('year')
      .add(1, 'second')
      .valueOf();
  } else if (option === 'customDate') {
    this.toggleCalendar();
  }
  return selectedDate;
};

const getLabelByDate = (startDate, endDate) => {
  const today = moment().format('YYYY-MM-DD');
  const thisWeek = moment()
    .subtract(1, 'week')
    .endOf('week')
    .add(1, 'second')
    .format('YYYY-MM-DD');
  const thisMonth = moment()
    .subtract(1, 'month')
    .endOf('month')
    .add(1, 'second')
    .format('YYYY-MM-DD');
  const thisYear = moment()
    .subtract(1, 'year')
    .endOf('year')
    .add(1, 'second')
    .format('YYYY-MM-DD');
  const start = moment(startDate).format('YYYY-MM-DD');
  const end = moment(endDate).format('YYYY-MM-DD');
  if (end !== today) {
    return 'customDate';
  } else if (end === today) {
    if (start === today) {
      return 'today';
    }
    if (start === thisWeek) {
      return 'thisWeek';
    }
    if (start === thisMonth) {
      return 'thisMonth';
    }
    if (start === thisYear) {
      return 'thisYear';
    }
  }
  return 'customDate';
};

const customDateText = (startDate, endDate) => {
  let label = '';

  if (startDate === undefined || endDate === undefined) {
    return '';
  }

  if (startDate === null || endDate === null) {
    return '';
  }

  if (startDate === endDate) {
    label = `${startDate.format("D MMM 'YY")}`;
    return label.toLowerCase();
  }

  const isSameMonth = startDate.month() === endDate.month();
  const isSameYear = startDate.year() === endDate.year();
  if (isSameMonth && isSameYear) {
    label = `${startDate.format('D')} - ${endDate.format('D MMM')}`;
  } else if (isSameYear) {
    label = `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`;
  } else {
    label = `${startDate.format("D MMM 'YY")} - ${endDate.format("D MMM 'YY")}`;
  }
  return label.toLowerCase();
};

export { getDatesByLabel, getLabelByDate, customDateText };

import React from 'react';
import PropTypes from 'prop-types';
import { Header2, Header3 } from '../../styleguide';
import {
  OnboardingParagraph,
  OnboardingParagraphHeader,
} from '../styledComponents';

function MarkDownParser(props) {
  const convertMdToHtml = () => {
    const arr = props.input.split('\n');

    return arr.reduce((HTML, item, index) => {
      if (item.trim().charAt(0) === '#') {
        return [...HTML, header(item, index)];
      }
      return [...HTML, paragraph(item, index)];
    }, []);
  };

  const paragraph = (item, index) => {
    if (item.trim().length === 0) {
      return <OnboardingParagraph key={index}>&nbsp;</OnboardingParagraph>;
    }
    return <OnboardingParagraph key={index}>{item.trim()}</OnboardingParagraph>;
  };

  const header = (item, index) => {
    const arr = item.split('# ');
    const headerType = arr[0].trim().length + 1;
    const headerContent = arr[1].trim();

    if (headerType === 2) {
      return <Header2 key={index}>{headerContent}</Header2>;
    }

    if (headerType === 3) {
      return <Header3 key={index}>{headerContent}</Header3>;
    }

    if (headerType === 4) {
      return (
        <OnboardingParagraphHeader key={index}>
          {headerContent}
        </OnboardingParagraphHeader>
      );
    }

    return undefined;
  };

  return convertMdToHtml();
}

MarkDownParser.propTypes = {
  input: PropTypes.string,
};

export default MarkDownParser;

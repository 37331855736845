/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/abn-app.svg';
import '!file-loader?name=[name].[ext]!../../../images/app-store-download.svg';
import '!file-loader?name=[name].[ext]!../../../images/google-play-download.svg';
import {
  blokkieTheme,
  BlokkieTypography,
  BlokkieTextLink,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { CenterWrapper } from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import { appStoreABN, googlePlayABN } from './constants';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IDVInstallAppProps {
  prevStep: () => void;
  nextStep: () => void;
}

function IDVInstallApp({
  prevStep,
  nextStep,
}: IDVInstallAppProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const [info, setInfo] = useState(false);
  const showInfo = (): void => setInfo(!info);

  useOnboardingPageView('idv-install-app');

  const next = (): void => {
    nextStep();
  };

  return (
    <>
      <NavigationHeader
        clickLeft={prevStep as () => void}
        clickRight={showInfo}
        noMarginBottom
      />
      <CenterWrapper>
        <div>
          <img src="/abn-app.svg" alt="" />
        </div>

        <div className={classes.appIcons}>
          <a href={googlePlayABN} target="_blank" rel="noreferrer">
            <img src="/google-play-download.svg" alt="" />
          </a>
          <a href={appStoreABN} target="_blank" rel="noreferrer">
            <img src="/app-store-download.svg" alt="" />
          </a>
        </div>

        <Title textAlign="center">{t(`onboarding.idv.installApp.title`)}</Title>

        <BlokkieTextLink
          variant="pl700"
          underline
          onClick={next}
          data-test-id="Onboarding-IDV-Intall-App-NextButton"
          style={{ color: blokkieTheme.colors.primary.green }}
        >
          {t(`onboarding.idv.installApp.next`)}
        </BlokkieTextLink>
      </CenterWrapper>
      <InfoScreen
        show={info}
        title={t(`onboarding.idv.installApp.infoscreen.title`)}
        onClick={showInfo}
        fromPage="Install app"
      >
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.veryDark,
          }}
          variant="pl500"
        >
          {t(`onboarding.idv.installApp.infoscreen.description`)}
        </BlokkieTypography>
      </InfoScreen>
    </>
  );
}

const useStyles = createUseStyles({
  appIcons: {
    marginTop: -blokkieTheme.spacing(1),
  },
});

export default injectIntl(IDVInstallApp);

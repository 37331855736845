import {
  CREATE_TIKKIE,
  CREATE_TIKKIE_ERROR,
  CREATE_TIKKIE_SUCCESS,
} from './constants';

export function createTikkie(
  amount,
  description,
  invoiceNumber,
  expiryDate,
  isOpenTikkie,
) {
  return {
    type: CREATE_TIKKIE,
    payload: {
      amount,
      description,
      invoiceNumber,
      expiryDate,
      isOpenTikkie,
    },
  };
}

export function createTikkieSuccess(tikkie) {
  return {
    type: CREATE_TIKKIE_SUCCESS,
    payload: {
      tikkie,
    },
  };
}

export function createTikkieError(status, error) {
  return {
    type: CREATE_TIKKIE_ERROR,
    payload: {
      status,
      error,
    },
  };
}

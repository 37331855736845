/*
 * PaymentRequest Messages
 *
 * This contains all the text for the PaymentRequest component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  waiting: {
    id: 'app.components.PaymentRequestLineItem.waiting',
    defaultMessage: 'nog niet betaald',
  },
  preview: {
    id: 'app.components.PaymentRequestLineItem.preview',
    defaultMessage: 'preview',
  },
  yesterday: {
    id: 'app.components.PaymentRequestLineItem.yesterday',
    defaultMessage: 'gisteren',
  },
  openTikkie: {
    id: 'app.components.PaymentRequestLineItem.openTikkie',
    defaultMessage: 'open Tikkie',
  },
});

/* eslint-disable import/no-unresolved */
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieTypography } from '@tikkie/blokkie';
import '!file-loader?name=[name].[ext]!../../images/warning.svg';
import '!file-loader?name=[name].[ext]!../../images/close-warning.svg';

import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { Color } from '../styleguide';

const DEACTIVATION_LOCAL_STORAGE_KEY = 'terminationBanner';

interface DeactivationBannerProps {
  loggedIn: boolean;
  status: string;
}

function daysLeftInCurrentMonth(): number {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return lastDayOfMonth.getDate() - today.getDate() + 1;
}

export function clearLocalStorage(): void {
  localStorage.removeItem(DEACTIVATION_LOCAL_STORAGE_KEY);
}

export function DeactivationBanner({
  loggedIn,
  status,
}: DeactivationBannerProps): React.ReactElement | null {
  const styles = useStyles();
  const { t } = useTranslation();
  const [bannerClosed, setBannerClosed] = useLocalStorage(
    DEACTIVATION_LOCAL_STORAGE_KEY,
    false,
  );
  const days = daysLeftInCurrentMonth();

  const showBanner = useMemo(
    () => loggedIn && status === 'ALMOST_INACTIVE' && !bannerClosed,
    [loggedIn, status, bannerClosed],
  );
  return !showBanner ? null : (
    <div className={styles.banner}>
      <img src="/warning.svg" alt="warning" />
      <BlokkieTypography variant="pl500">
        {t('portal.components.deactivation', { days })}
      </BlokkieTypography>
      <button
        className={styles.close}
        type="button"
        onClick={() => setBannerClosed(true)}
      >
        <img src="/close-warning.svg" alt="close" />
      </button>
    </div>
  );
}

const useStyles = createUseStyles({
  banner: {
    maxWidth: '100%',
    color: Color.gray01,
    alignItems: 'center',
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '16px',
    width: '1056px',
    margin: '0 auto 24px auto',
    backgroundColor: `#fefbf6`, // Color.secondaryColor02,
    border: `4px solid #fdf3e4`,
    radius: '8px',
    padding: '24px',
    gridTemplateColumns: 'auto auto 1fr',
  },

  close: {
    cursor: 'pointer',
    padding: '0',
    border: 'none',
    background: 'none',
    justifySelf: 'end',
  },
});

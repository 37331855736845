/*
 * BulkTikkie Messages
 *
 * This contains all the text for the BulkTikkie component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'app.components.BulkList.back',
    defaultMessage: 'Terug',
  },
  header: {
    id: 'app.components.BulkList.header',
    defaultMessage: 'Tikkies in een keer uploaden',
  },
  caption1: {
    id: 'app.components.BulkList.caption1',
    defaultMessage: 'Nog niet bekend met CSV-bestanden? Bekijk onze ',
  },
  caption2: {
    id: 'app.components.BulkList.caption2',
    defaultMessage: 'handleiding',
  },
  caption3: {
    id: 'app.components.BulkList.caption3',
    defaultMessage: ' en download de template.',
  },
  downloadManual: {
    id: 'app.components.BulkList.downloadManual',
    defaultMessage: 'Download handleiding',
  },
  downloadExample: {
    id: 'app.components.BulkList.downloadExample',
    defaultMessage: 'Download voorbeeld Excel-bestand',
  },
  uploadFileHeader: {
    id: 'app.components.BulkList.uploadFileHeader',
    defaultMessage: 'Upload CSV-bestand',
  },
  uploadFileCaption: {
    id: 'app.components.BulkList.uploadFileCaption',
    defaultMessage:
      'Sleep je bestanden hierheen of klik op "selecteer bestand"',
  },
  uploadFileButton: {
    id: 'app.components.BulkList.uploadFileButton',
    defaultMessage: 'Selecteer bestand',
  },
  dropFile: {
    id: 'app.components.BulkList.dropFile',
    defaultMessage: 'Drop de file hier!',
  },
  titleLabel: {
    id: 'app.components.BulkList.titleLabel',
    defaultMessage: 'Kies een titel',
  },
  titleCaption: {
    id: 'app.components.BulkList.titleCaption',
    defaultMessage: 'Voor je eigen administratie',
  },
  titlePlaceholder: {
    id: 'app.components.BulkList.titlePlaceholder',
    defaultMessage: 'Bijv. "Schoolgeld december"',
  },
  sendTikkiesButton: {
    id: 'app.components.BulkList.sendTikkiesButton',
    defaultMessage: 'Verstuur alle Tikkies per SMS',
  },
  saveTikkiesButton: {
    id: 'app.components.BulkList.saveTikkiesButton',
    defaultMessage: 'Opslaan en later verzenden',
  },
  preview: {
    id: 'app.components.BulkList.preview',
    defaultMessage: 'Preview bekijken',
  },
  bulkUploadExcelErrorMessage: {
    id: 'app.components.BulkList.bulkUploadExcelErrorMessage',
    defaultMessage:
      'Het is niet mogelijk om direct een Excel bestand te uploaden, exporteer het bestand naar een CSV bestand en probeer het opnieuw.',
  },
  bulkUploadNonCsvErrorMessage: {
    id: 'app.components.BulkList.bulkUploadNonCsvErrorMessage',
    defaultMessage: 'Je kan alleen CSV bestanden uploaden.',
  },
  rejectBulkUploadErrorMessage: {
    id: 'app.components.BulkList.rejectBulkUploadErrorMessage',
    defaultMessage:
      'Oeps, je kunt alleen een CSV bestand van maximaal 10 MB uploaden',
  },
  errorDetailsHeader: {
    id: 'app.components.BulkList.errorDetailsHeader',
    defaultMessage: 'Toelichting gevonden fouten',
  },
  errorDetailsSubtitle: {
    id: 'app.components.BulkList.errorDetailsSubtitle',
    defaultMessage:
      'Deze meldingen geven aan welke fouten er in het CSV-bestand zijn gevonden. Pas het bestand aan en upload het opnieuw.',
  },
  errorDetailsTableErrorHeading: {
    id: 'app.components.BulkList.errorDetailsTableErrorHeading',
    defaultMessage: 'Foutmelding',
  },
  errorDetailsTableColumnHeading: {
    id: 'app.components.BulkList.errorDetailsTableColumnHeading',
    defaultMessage: 'Kolom',
  },
  errorDetailsTableRowHeading: {
    id: 'app.components.BulkList.errorDetailsTableRowHeading',
    defaultMessage: 'Regel',
  },
});

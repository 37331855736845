import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';

import FormWrapperContainer from '../FormWrapperContainer';
import { selectApplicant2Data } from '../selectors';
import secondApplicantDetailsByFirstApplicantSteps from './constants';
import { setSecondApplicantDetails, setApplicant2Name } from '../actions';
import ContactName from '../../../components/OnboardingKYC/ContactName';
import GetEmail from '../../../components/OnboardingKYC/GetEmail';
import TransitionScreen from '../../../components/OnboardingKYC/TransitionScreen';

const secondApplicantDetailsByFirstApplicantStepsArr = [
  secondApplicantDetailsByFirstApplicantSteps.applicant2Transition,
  secondApplicantDetailsByFirstApplicantSteps.applicant2Name,
  secondApplicantDetailsByFirstApplicantSteps.applicant2Email,
];

function SecondApplicantDetailsByFirstApplicantContainer(props) {
  const [currentStep, setCurrentStep] = useState(
    secondApplicantDetailsByFirstApplicantSteps.applicant2Transition,
  );

  const nextStep = () => {
    setCurrentStep(
      props.getNextStep(
        secondApplicantDetailsByFirstApplicantStepsArr,
        currentStep,
      ),
    );
  };

  const prevStep = () => {
    setCurrentStep(
      props.getPreviousStep(
        secondApplicantDetailsByFirstApplicantStepsArr,
        currentStep,
      ),
    );
  };

  const setNameAndGoNext = (name) => {
    props.setApplicant2Name(name);
    nextStep();
  };

  const saveSecondApplicantDetailsByFirstApplicant = (email) => {
    props.setApplicant2Details(props.applicant2Data.name, email);
  };

  const noProgressBar =
    currentStep ===
    secondApplicantDetailsByFirstApplicantSteps.applicant2Transition;

  const { name } = props.applicant2Data;
  return (
    <FormWrapperContainer portalBacking noProgressBar={noProgressBar}>
      {currentStep ===
        secondApplicantDetailsByFirstApplicantSteps.applicant2Transition && (
        <TransitionScreen nextStep={nextStep} />
      )}

      {currentStep ===
        secondApplicantDetailsByFirstApplicantSteps.applicant2Name && (
        <ContactName
          setContactName={setNameAndGoNext}
          forApplicant2
          onClickBack={prevStep}
          pendingNames={
            name.firstName
              ? {
                  firstName: name.firstName,
                  lastNamePrefix: name.lastNamePrefix,
                  lastName: name.lastName,
                }
              : undefined
          }
        />
      )}
      {currentStep ===
        secondApplicantDetailsByFirstApplicantSteps.applicant2Email && (
        <GetEmail
          sendEmail={saveSecondApplicantDetailsByFirstApplicant}
          prevStep={prevStep}
          onboardingData={{ email: '' }}
          simplifiedHeader
          forApplicant2
        />
      )}
    </FormWrapperContainer>
  );
}

SecondApplicantDetailsByFirstApplicantContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  setApplicant2Name: PropTypes.func.isRequired,
  applicant2Data: PropTypes.object.isRequired,
  setApplicant2Details: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  applicant2Data: selectApplicant2Data,
});

const mapDispatchToProps = (dispatch) => ({
  setApplicant2Name: (name) => dispatch(setApplicant2Name(name)),
  setApplicant2Details: (name, email) =>
    dispatch(setSecondApplicantDetails(name, email)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(SecondApplicantDetailsByFirstApplicantContainer);

import {
  LOGOUT_USER,
  LOAD_LINKED_ORGANISATIONS,
  LOAD_LINKED_ORGANISATIONS_SUCCESS,
  LOAD_LINKED_ORGANISATIONS_ERROR,
  CLEAR_LINKED_ORGANISATIONS,
} from './constants';

export function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}

export function loadLinkedOrganisations() {
  return {
    type: LOAD_LINKED_ORGANISATIONS,
  };
}

export function loadLinkedOrganisationsSuccess(response) {
  return {
    type: LOAD_LINKED_ORGANISATIONS_SUCCESS,
    payload: response,
  };
}

export function loadLinkedOrganisationsError(error) {
  return {
    type: LOAD_LINKED_ORGANISATIONS_ERROR,
    payload: error,
  };
}

export function clearLinkedOrganisations() {
  return {
    type: CLEAR_LINKED_ORGANISATIONS,
  };
}

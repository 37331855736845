const countryList = [
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Åland',
    value: 'AX',
  },
  {
    label: 'Albanië',
    value: 'AL',
  },
  {
    label: 'Algerije',
    value: 'DZ',
  },
  {
    label: 'Amerikaanse Maagdeneilanden',
    value: 'VI',
  },
  {
    label: 'Amerikaans-Samoa',
    value: 'AS',
  },
  {
    label: 'Andorra',
    value: 'AD',
  },
  {
    label: 'Angola',
    value: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    label: 'Antigua en Barbuda',
    value: 'AG',
  },
  {
    label: 'Argentinië',
    value: 'AR',
  },
  {
    label: 'Armenië',
    value: 'AM',
  },
  {
    label: 'Aruba',
    value: 'AW',
  },
  {
    label: 'Australië',
    value: 'AU',
  },
  {
    label: 'Azerbeidzjan',
    value: 'AZ',
  },
  {
    label: "Bahama's",
    value: 'BS',
  },
  {
    label: 'Bahrein',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'België',
    value: 'BE',
  },
  {
    label: 'Belize',
    value: 'BZ',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'BT',
  },
  {
    label: 'Bolivia',
    value: 'BO',
  },
  {
    label: 'Bosnië en Herzegovina',
    value: 'BA',
  },
  {
    label: 'Botswana',
    value: 'BW',
  },
  {
    label: 'Bouveteiland',
    value: 'BV',
  },
  {
    label: 'Brazilië',
    value: 'BR',
  },
  {
    label: 'Britse Maagdeneilanden',
    value: 'VG',
  },
  {
    label: 'Brits Indische Oceaanterritorium',
    value: 'IO',
  },
  {
    label: 'Brunei',
    value: 'BN',
  },
  {
    label: 'Bulgarije',
    value: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    label: 'Burundi',
    value: 'BI',
  },
  {
    label: 'Cambodja',
    value: 'KH',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Caribisch Nederland',
    value: 'BQ',
  },
  {
    label: 'Centraal-Afrikaanse Republiek',
    value: 'CF',
  },
  {
    label: 'Chili',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Christmaseiland',
    value: 'CX',
  },
  {
    label: 'Cocoseilanden',
    value: 'CC',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Comoren',
    value: 'KM',
  },
  {
    label: 'Congo-Brazzaville',
    value: 'CG',
  },
  {
    label: 'Congo-Kinshasa',
    value: 'CD',
  },
  {
    label: 'Cookeilanden',
    value: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'Curaçao',
    value: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'CY',
  },
  {
    label: 'Denemarken',
    value: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'DM',
  },
  {
    label: 'Dominicaanse Republiek',
    value: 'DO',
  },
  {
    label: 'Duitsland',
    value: 'DE',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypte',
    value: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'SV',
  },
  {
    label: 'Equatoriaal-Guinea',
    value: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'ER',
  },
  {
    label: 'Estland',
    value: 'EE',
  },
  {
    label: 'Ethiopië',
    value: 'ET',
  },
  {
    label: 'Faeröer',
    value: 'FO',
  },
  {
    label: 'Falklandeilanden',
    value: 'FK',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Filipijnen',
    value: 'PH',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'Frankrijk',
    value: 'FR',
  },
  {
    label: 'Franse Zuidelijke en Antarctische Gebieden',
    value: 'TF',
  },
  {
    label: 'Frans-Guyana',
    value: 'GF',
  },
  {
    label: 'Frans-Polynesië',
    value: 'PF',
  },
  {
    label: 'Gabon',
    value: 'GA',
  },
  {
    label: 'Gambia',
    value: 'GM',
  },
  {
    label: 'Georgië',
    value: 'GE',
  },
  {
    label: 'Ghana',
    value: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    label: 'Grenada',
    value: 'GD',
  },
  {
    label: 'Griekenland',
    value: 'GR',
  },
  {
    label: 'Groenland',
    value: 'GL',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'GG',
  },
  {
    label: 'Guinee',
    value: 'GN',
  },
  {
    label: 'Guinee-Bissau',
    value: 'GW',
  },
  {
    label: 'Guyana',
    value: 'GY',
  },
  {
    label: 'Haïti',
    value: 'HT',
  },
  {
    label: 'Heard en McDonaldeilanden',
    value: 'HM',
  },
  {
    label: 'Honduras',
    value: 'HN',
  },
  {
    label: 'Hongarije',
    value: 'HU',
  },
  {
    label: 'Hongkong',
    value: 'HK',
  },
  {
    label: 'Ierland',
    value: 'IE',
  },
  {
    label: 'IJsland',
    value: 'IS',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Indonesië',
    value: 'ID',
  },
  {
    label: 'Irak',
    value: 'IQ',
  },
  {
    label: 'Iran',
    value: 'IR',
  },
  {
    label: 'Israël',
    value: 'IL',
  },
  {
    label: 'Italië',
    value: 'IT',
  },
  {
    label: 'Ivoorkust',
    value: 'CI',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Jemen',
    value: 'YE',
  },
  {
    label: 'Jersey',
    value: 'JE',
  },
  {
    label: 'Jordanië',
    value: 'JO',
  },
  {
    label: 'Kaaimaneilanden',
    value: 'KY',
  },
  {
    label: 'Kaapverdië',
    value: 'CV',
  },
  {
    label: 'Kameroen',
    value: 'CM',
  },
  {
    label: 'Kazachstan',
    value: 'KZ',
  },
  {
    label: 'Kenia',
    value: 'KE',
  },
  {
    label: 'Kirgizië',
    value: 'KG',
  },
  {
    label: 'Kiribati',
    value: 'KI',
  },
  {
    label: 'Kleine afgelegen eilanden van de Verenigde Staten',
    value: 'UM',
  },
  {
    label: 'Koeweit',
    value: 'KW',
  },
  {
    label: 'Kroatië',
    value: 'HR',
  },
  {
    label: 'Laos',
    value: 'LA',
  },
  {
    label: 'Lesotho',
    value: 'LS',
  },
  {
    label: 'Letland',
    value: 'LV',
  },
  {
    label: 'Libanon',
    value: 'LB',
  },
  {
    label: 'Liberia',
    value: 'LR',
  },
  {
    label: 'Libië',
    value: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'Litouwen',
    value: 'LT',
  },
  {
    label: 'Luxemburg',
    value: 'LU',
  },
  {
    label: 'Macau',
    value: 'MO',
  },
  {
    label: 'Madagaskar',
    value: 'MG',
  },
  {
    label: 'Malawi',
    value: 'MW',
  },
  {
    label: 'Maldiven',
    value: 'MV',
  },
  {
    label: 'Maleisië',
    value: 'MY',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Malta',
    value: 'MT',
  },
  {
    label: 'Man',
    value: 'IM',
  },
  {
    label: 'Marokko',
    value: 'MA',
  },
  {
    label: 'Marshalleilanden',
    value: 'MH',
  },
  {
    label: 'Martinique',
    value: 'MQ',
  },
  {
    label: 'Mauritanië',
    value: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'YT',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Micronesia',
    value: 'FM',
  },
  {
    label: 'Moldavië',
    value: 'MD',
  },
  {
    label: 'Monaco',
    value: 'MC',
  },
  {
    label: 'Mongolië',
    value: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'MS',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'MM',
  },
  {
    label: 'Namibië',
    value: 'NA',
  },
  {
    label: 'Nauru',
    value: 'NR',
  },
  {
    label: 'Nederland',
    value: 'NL',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    label: 'Nieuw-Caledonië',
    value: 'NC',
  },
  {
    label: 'Nieuw-Zeeland',
    value: 'NZ',
  },
  {
    label: 'Niger',
    value: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'NG',
  },
  {
    label: 'Niue',
    value: 'NU',
  },
  {
    label: 'Noordelijke Marianen',
    value: 'MP',
  },
  {
    label: 'Noord-Korea',
    value: 'KP',
  },
  {
    label: 'Noord-Macedonië',
    value: 'MK',
  },
  {
    label: 'Noorwegen',
    value: 'NO',
  },
  {
    label: 'Norfolk',
    value: 'NF',
  },
  {
    label: 'Oeganda',
    value: 'UG',
  },
  {
    label: 'Oekraïne',
    value: 'UA',
  },
  {
    label: 'Oezbekistan',
    value: 'UZ',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Oostenrijk',
    value: 'AT',
  },
  {
    label: 'Oost-Timor',
    value: 'TL',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Palestina',
    value: 'PS',
  },
  {
    label: 'Panama',
    value: 'PA',
  },
  {
    label: 'Papoea-Nieuw-Guinea',
    value: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'PY',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Pitcairneilanden',
    value: 'PN',
  },
  {
    label: 'Polen',
    value: 'PL',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'Réunion',
    value: 'RE',
  },
  {
    label: 'Roemenië',
    value: 'RO',
  },
  {
    label: 'Rusland',
    value: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'RW',
  },
  {
    label: 'Saint-Barthélemy',
    value: 'BL',
  },
  {
    label: 'Saint Kitts en Nevis',
    value: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    label: 'Saint-Pierre en Miquelon',
    value: 'PM',
  },
  {
    label: 'Saint Vincent en de Grenadines',
    value: 'VC',
  },
  {
    label: 'Salomonseilanden',
    value: 'SB',
  },
  {
    label: 'Samoa',
    value: 'WS',
  },
  {
    label: 'San Marino',
    value: 'SM',
  },
  {
    label: 'Saoedi-Arabië',
    value: 'SA',
  },
  {
    label: 'Sao Tomé en Principe',
    value: 'ST',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Servië',
    value: 'RS',
  },
  {
    label: 'Seychellen',
    value: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Verenigd Koninkrijk Sint-Helena, Ascension en Tristan da Cunha',
    value: 'SH',
  },
  {
    label: 'Sint-Maarten',
    value: 'MF',
  },
  {
    label: 'Sint Maarten',
    value: 'SX',
  },
  {
    label: 'Slovenië',
    value: 'SI',
  },
  {
    label: 'Slowakije',
    value: 'SK',
  },
  {
    label: 'Soedan',
    value: 'SD',
  },
  {
    label: 'Somalië',
    value: 'SO',
  },
  {
    label: 'Spanje',
    value: 'ES',
  },
  {
    label: 'Spitsbergen en Jan Mayen',
    value: 'SJ',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'Suriname',
    value: 'SR',
  },
  {
    label: 'Swaziland',
    value: 'SZ',
  },
  {
    label: 'Syrië',
    value: 'SY',
  },
  {
    label: 'Tadzjikistan',
    value: 'TJ',
  },
  {
    label: 'Taiwan',
    value: 'TW',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Togo',
    value: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'TK',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Trinidad en Tobago',
    value: 'TT',
  },
  {
    label: 'Tsjaad',
    value: 'TD',
  },
  {
    label: 'Tsjechië',
    value: 'CZ',
  },
  {
    label: 'Tunesië',
    value: 'TN',
  },
  {
    label: 'Turkije',
    value: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    label: 'Turks- en Caicoseilanden',
    value: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    label: 'Vaticaanstad',
    value: 'VA',
  },
  {
    label: 'Venezuela',
    value: 'VE',
  },
  {
    label: 'Verenigde Arabische Emiraten',
    value: 'AE',
  },
  {
    label: 'Verenigde Staten',
    value: 'US',
  },
  {
    label: 'Verenigd Koninkrijk',
    value: 'GB',
  },
  {
    label: 'Vietnam',
    value: 'VN',
  },
  {
    label: 'Wallis en Futuna',
    value: 'WF',
  },
  {
    label: 'Westelijke Sahara',
    value: 'EH',
  },
  {
    label: 'Wit-Rusland',
    value: 'BY',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
  },
  {
    label: 'Zuid-Afrika',
    value: 'ZA',
  },
  {
    label: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    value: 'GS',
  },
  {
    label: 'Zuid-Korea',
    value: 'KR',
  },
  {
    label: 'Zuid-Soedan',
    value: 'SS',
  },
  {
    label: 'Zweden',
    value: 'SE',
  },
  {
    label: 'Zwitserland',
    value: 'CH',
  },
];

export default countryList;

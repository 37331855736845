/**
 *
 * CreateTikkie
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';
import '!file-loader?name=[name].[ext]!../../../images/calendar.svg';
import '!file-loader?name=[name].[ext]!../../../images/active.svg';
import '!file-loader?name=[name].[ext]!../../../images/inactive.svg';
import '!file-loader?name=[name].[ext]!../../../images/okicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/gifs/oops2.gif';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import messages from './messages';
import animationData from '../../../images/animations/white-dots.json';
import {
  Color,
  Dropdown,
  Header1,
  NonMobileWrapper,
  PrimaryButton,
  SecondaryButton,
} from '../../styleguide';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';

import { ErrorBubble, GiphyBox, RadioButton } from '../../components';

const PaymentRequestCreateWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;

  .DateInput {
    position: fixed;
    top: -200px;
    width: 0px;
    height: 0px;
  }

  .SingleDatePicker_picker {
    position: relative;
    top: -30px !important;
    padding: 0px !important;
    margin-left: -20px;
  }

  .DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
  }

  .DayPickerNavigation {
    .DayPickerNavigation_button + .DayPickerNavigation_button {
      left: unset;
      right: 52px;
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 14px;
    left: 24px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 8px 0px;
  }

  .CalendarMonth {
    padding: 0px !important;
  }

  .CalendarMonth_caption {
    color: ${Color.brandColor01};
    font-size: 16px;
    margin-bottom: 16px;
  }

  .DayPicker_weekHeader {
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0px !important;
    text-transform: lowercase;
  }

  .CalendarDay__default {
    font-size: 14px;
    border: none;
    outline: none;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    color: ${Color.brandColor01};
    background-color: #e0f2f1 !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    color: ${Color.white};
    background-color: ${Color.brandColor01} !important;
  }

  @media ${(props) => props.theme.device.mobile} {
    .DateRangePicker_picker {
      position: relative;
      top: 0px !important;
      margin-left: -20px;
    }

    .DayPickerNavigation_button {
      position: absolute;
      top: 14px;
      left: 22px;
      line-height: 0.78;
      border-radius: 3px;
      padding: 8px;
    }
  }
`;

const Glass = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const BackButton = styled(SecondaryButton)`
  border-radius: 8px;
  border: solid 2px ${Color.gray05};
`;

const BackArrowWrapper = styled.div`
  margin-bottom: 24px;
  display: none;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
  }
`;

const BackArrow = styled.img`
  cursor: pointer;
  width: 24px;
  margin: 2px;
`;

const CalendarBackArrow = styled(BackArrow)`
  margin-left: 22px;
`;

const NextWrapper = styled.div`
  display: none;
  position: absolute;
  top: 272px;
  right: 24px;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
  }
`;

const ExpiryButtonWrapper = styled.div`
  float: right;
`;

const CalendarIcon = styled.img`
  margin-right: 8px;
  vertical-align: text-bottom;
`;

const Header = styled(Header1)`
  height: 38px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const CreateTikkieInputs = styled.div``;

const CreateTikkieInput = styled.div`
  width: 1056px;
  height: 88px;
  padding: 26px 24px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: none;
  background-color: ${Color.white};
  box-shadow: ${(props) =>
    props.focussed
      ? '0 5px 20px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 4px 0 rgba(0, 0, 0, 0.05)'};

  @media ${(props) => props.theme.device.mobile} {
    display: ${(props) => (props.visible ? 'block' : 'none')};
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 100%;
    padding: 24px;
    border-radius: 0px;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: auto;
  }
`;

const CreateTikkieContentWrapper = styled.div`
  position: relative;
`;

const CreateTikkieInputWrapper = styled.div`
  position: relative;
`;

const LabelWrapper = styled.div`
  position: relative;
`;

const Label = styled.div`
  float: left;
  width: 240px;
  height: 36px;
  line-height: 36px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.gray01};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    width: 100%;
    margin-bottom: 8px;
    font-size: 30px;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 180px;
  }
`;

const LabelWithDescription = styled(Label)`
  margin-top: -10px;

  @media ${(props) => props.theme.device.tablet} {
    margin-top: 0px;
  }
`;

const LabelShort = styled(Label)`
  width: 120px;

  @media ${(props) => props.theme.device.tablet} {
    width: 120px;
  }
`;

const CaptionSmall = styled.div`
  position: absolute;
  height: 14px;
  line-height: 14px;
  font-family: MuseoSansRounded;
  font-size: 14px;
  color: ${Color.gray03};

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }

  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

const EuroWrapper = styled.div`
  display: inline-block;
`;

const Euro = styled.div`
  position: absolute;
  color: ${Color.brandColor01};
  height: 36px;
  line-height: 36px;
  font-size: 22px;
  font-family: MuseoSansRounded700;
  left: 266px;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    left: 24px;
    font-size: 30px;
    height: auto;
    line-height: normal;
  }

  @media ${(props) => props.theme.device.tablet} {
    font-size: 22px;
    left: 214px;
  }
`;

const InputField = styled.input`
  float: left;
  border: none
  width: 500px;
  height: 36px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.brandColor01};
  outline: none;

  ::placeholder {
    color: ${Color.gray04};
  }

  :disabled {
    background-color: transparent;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    font-size: 28px;
    height: auto;
    line-height: normal;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media ${(props) => props.theme.device.tablet} {
    font-size: 22px;
    width: 430px;
  }
`;

const Currency = styled(InputField)`
  width: 300px;
  padding-left: 28px;
  font-size: 22px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    font-size: 30px;
    margin-bottom: -3px;
    padding-left: 24px;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 150px;
    font-size: 22px;
  }
`;

const Toggle = styled.div`
  float: right;
  height: 38px;
  line-height: 38px;
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: ${(props) => (props.active ? Color.brandColor01 : Color.gray04)};

  span {
    vertical-align: top;
  }

  img {
    float: right;
    margin-left: 16px;
    cursor: pointer;
  }

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    margin-top: 36px;
    font-size: 18px;

    span {
      display: block;
      width: auto;
      line-height: 24px;
      padding-top: 5px;
    }

    img {
      float: right;
    }
  }
`;

const CharacterCounter = styled.div`
  float: right;
  height: 36px;
  line-height: 36px;
  font-family: MuseoSansRounded700;
  font-size: 22px;
  color: ${Color.gray01};

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const CreateTikkieFooter = styled.div`
  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const CreateTikkieButton = styled(PrimaryButton)`
  width: 240px;
  float: right;
`;

const NextButton = styled(PrimaryButton)`
  width: 210px;
  float: right;
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -11px;
  left: 0;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ed5565;
  transform: rotate(-45deg);
  background-color: #ffffff;
  color: #57565b;
  border: solid 2px #f2f2f3;
  z-index: -1;

  @media ${(props) => props.theme.device.tablet} {
    left: 130px;
  }
`;

const UpArrowRight = styled(UpArrow)`
  left: unset
  right: 80px;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 55px;
  right: 0px;
  border-radius: 10px;
  box-shadow: 4px 10px 24px 0 rgba(0, 0, 0, 0.15);
  border: solid 2px #f2f2f3;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: none;
    border-radius: 8px;
  }
`;

const Options = styled.div`
  width: 223px;
  padding: 12px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #57565b;
  z-index: 999;

  @media ${(props) => props.theme.device.mobile} {
    overflow: auto;
    padding-bottom: 100px;
    padding-top: 72px;
    border-radius: 10px;
    height: 100%;
  }
`;

const Divider = styled.div`
  border-top: 2px solid #f2f2f3;
  margin-bottom: 8px;
`;

const NavPrev = styled.img``;

const NavNext = styled.img`
  transform: rotate(180deg);
`;

const CalendarHeader = styled.div`
  height: 18px;
`;

const Calendar = styled.div`
  position: relative;
  height: 500px;
  width: 338px;
  padding: 24px 0 12px 0;
  background-color: #ffffff;
  border-radius: 8px;
  color: #57565b;
  z-index: 99;
  overflow: hidden;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    padding: 0px 24px;
  }
`;

const CalendarButton = styled(PrimaryButton)`
  position: absolute;
  width: 238px;
  bottom: 26px;
  left: 50px;
  z-index: 99;
`;

const AnimationWrapper = styled.div`
  display: inline-block;
  height: 18px;

  .react-bodymovin-container {
    height: 18px;
  }
`;

const ErrorWrapper = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 40px;

  @media ${(props) => props.theme.device.mobile} {
    left: 36px;
    right: 36px;
    padding-top: 0px;
  }
`;

const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 60px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    height: auto;
    box-shadow: none;
    border-radius: 0px;
    background-color: white;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 320px;
`;

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

// Make sure the dates are in Dutch
moment.locale('nl');

/* eslint-disable react/prefer-stateless-function */
class PaymentRequestCreate extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const errorTitle = intl.formatMessage({
      id: 'app.components.PaymentRequestCreate.errorTitle',
    });
    const errorText = intl.formatMessage({
      id: 'app.components.PaymentRequestCreate.errorText',
    });
    const errorButton = intl.formatMessage({
      id: 'app.components.PaymentRequestCreate.errorButton',
    });
    const errorSecondaryButton = intl.formatMessage({
      id: 'app.components.PaymentRequestCreate.errorSecondaryButton',
    });

    this.state = {
      showMenu: false,
      showCalendar: false,
      showHardError: false,
      isOpenTikkie: false,
      expiryDate: moment().add(this.props.defaultExpiryDays, 'days').endOf(),
      expiryDateOption: '',
      activeStep: 'amount',
      descriptionLength: 35,
      invoiceNumberLength: 35,
      amount: '',
      description: '',
      invoiceNumber: '',
      errorTitle,
      errorText,
      errorButton,
      errorSecondaryButton,
    };

    this.handleBlurAmount = this.handleBlurAmount.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleKeyPressAmount = this.handleKeyPressAmount.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeInvoiceNumber = this.handleChangeInvoiceNumber.bind(this);
  }

  componentDidUpdate(oldProps, oldState) {
    const { amount } = this.state;
    const { isCreating, createTikkieErrorMessage } = this.props;

    const tikkieCreated =
      !isCreating && oldProps.isCreating && createTikkieErrorMessage == null;

    const showHardError =
      oldProps.createTikkieErrorStatus === 0 &&
      this.props.createTikkieErrorStatus > 399 &&
      this.props.createTikkieErrorStatus !== 422;

    if (oldState.showHardError !== showHardError) {
      // eslint-disable-next-line
      this.setState({ showHardError });
    }

    if (tikkieCreated) {
      const { description, invoiceNumber, isOpenTikkie } = this.state;
      const notifications = [];
      const hasBackOption = false;
      const paymentRequest = {};
      const { tikkie, showShareTikkie } = this.props;

      showShareTikkie(
        description,
        invoiceNumber,
        amount || '0',
        isOpenTikkie,
        tikkie.paymentEventUrl,
        tikkie.paymentEventToken,
        notifications,
        hasBackOption,
        paymentRequest,
      );
    }
  }

  // Input related
  /* eslint-disable */
  isInputCompleted = (step) => {
    const { amount, description, invoiceNumber, isOpenTikkie } = this.state;

    if (step === 'amount') {
      const value = this.parseAmount(amount);
      return (
        (!Number.isNaN(value) && value > 0) ||
        isOpenTikkie ||
        description !== ''
      );
    }

    if (step === 'description') {
      return description !== '';
    }

    if (step === 'invoiceNumber') {
      return invoiceNumber !== '';
    }
  };
  /* eslint-enable */

  isInputFocussed = (step) => this.state.activeStep === step;

  isInputVisible = (step) => isMobileOnly && this.state.activeStep === step;

  isInputRowVisible = (step) =>
    !isMobileOnly || (isMobileOnly && this.state.activeStep === step);

  setActiveStep = (activeStep) => this.setState({ activeStep });

  goBack = () => {
    const { activeStep } = this.state;

    if (activeStep === 'amount') {
      this.props.showDashboard();
    } else if (activeStep === 'description') {
      this.setActiveStep('amount');
    } else if (activeStep === 'invoiceNumber') {
      this.setActiveStep('description');
    }
  };

  goNext = () => {
    const { activeStep, invoiceNumber, amount } = this.state;

    if (activeStep === 'amount') {
      if (parseInt(amount, 10) > 50000) {
        this.setState({ showAmountError: true });
      } else {
        this.setActiveStep('description');
      }
    } else if (activeStep === 'description') {
      this.setActiveStep('invoiceNumber');
    } else if (activeStep === 'invoiceNumber') {
      if (invoiceNumber.match(/^[A-Za-z0-9-!&'()+-./:?_`, ]+$/)) {
        this.onCreateTikkie();
      } else {
        this.setState({ showCharError: true });
      }
    }
  };

  handleKeyPressAmount = (event) => {
    const { amount } = this.state;
    const c = event.key || event.code;
    const { keyCode } = event;

    // backspace, enter and tab is okay
    if (keyCode === 8 || keyCode === 9 || keyCode === 13) {
      return;
    }

    // only numbers and commas
    if ('0123456789,.'.indexOf(c) === -1) {
      event.preventDefault();
      return;
    }

    // only one comma
    if (amount.indexOf(',') !== -1) {
      if (c === ',' || c === '.') {
        event.preventDefault();
      }
    }
  };

  handleChangeAmount = (event) => {
    const { value } = event.target;
    const hasPastedDots = value.indexOf('.') !== -1;
    const comma = value.indexOf(',');

    if (hasPastedDots) {
      this.formatPastedValue(value);
      return;
    }

    // only two digits after the comma
    if (comma > -1 && comma < value.length - 3) {
      return;
    }

    this.setState({ amount: value, showAmountError: false });
  };

  formatPastedValue = (value) => {
    let amount = value.trim();
    const dot = amount.lastIndexOf('.');
    const c = amount.indexOf(',');
    if (this.isDecimal(dot, amount)) {
      amount = `${amount.substring(0, dot)},${amount.substring(dot + 1)}`;
      amount = `${amount.substring(0, c)}${amount.substring(c + 1)}`;
    }
    amount = this.removeComma(amount);
    this.setState({ amount: amount.replace(/\./g, '') });
  };

  removeComma(amount) {
    const comma = amount.lastIndexOf(',');
    return comma === amount.length - 4
      ? `${amount.substring(0, comma)}${amount.substring(comma + 1)}`
      : amount;
  }

  isDecimal(dot, amount) {
    return (
      dot === amount.length - 1 ||
      dot === amount.length - 2 ||
      dot === amount.length - 3
    );
  }

  handleBlurAmount = () => {
    let { amount } = this.state;
    const comma = amount.indexOf(',');
    const amountLength = amount.length;

    if (parseInt(amount, 10) > 50000) {
      this.setState({ showAmountError: true });
    } else {
      this.setState({ showAmountError: false });
    }

    // No input? Make a pretty 0,00
    if (amount === '') {
      amount = '';
      this.setState({ amount });
      return;
    }

    // No comma? Add one, it is neater
    if (comma === -1) {
      amount += ',00';
      this.setState({ amount });
      return;
    }

    // No decimal after the comma? Add some, it is neater
    if (comma > -1 && comma === amountLength - 1) {
      amount += '00';
      this.setState({ amount });
      return;
    }

    if (comma === 0) {
      if (amountLength === 2) {
        amount = `0${amount}0`;
      } else {
        amount = `0${amount}`;
      }
      this.setState({ amount });
      return;
    }

    // Only one decimal after the comma? Add another, it is neater
    if (comma > -1 && comma > amountLength - 3) {
      amount += '0';
      this.setState({ amount });
    }
  };

  handleChangeDescription = (event) => {
    const description = event.target.value;
    const descriptionLength = 35 - (description ? description.length : 0);
    this.setState({ description, descriptionLength });
  };

  handleChangeInvoiceNumber = (event) => {
    const invoiceNumber = event.target.value;
    const invoiceNumberLength = 35 - (invoiceNumber ? invoiceNumber.length : 0);
    this.setState({ invoiceNumber, invoiceNumberLength, showCharError: false });
  };

  isCreateButtonEnabled = () => {
    const {
      amount,
      description,
      isOpenTikkie,
      invoiceNumber,
      showAmountError,
    } = this.state;
    const value = this.parseAmount(amount);
    const hasAmount = !Number.isNaN(value) && value > 0;
    const hasDescription = description !== '';
    const hasInvoiceNumber = invoiceNumber !== '';

    return (
      (hasAmount || isOpenTikkie) &&
      hasDescription &&
      hasInvoiceNumber &&
      !showAmountError
    );
  };

  parseAmount = (amount) =>
    amount ? parseFloat(amount.replace(/,/, '.')).toFixed(2) : 0;

  // Menu toggles

  toggleMenu = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
      showCalendar: prevState.showMenu ? prevState.showCalendar : false,
    }));
  };

  toggleOpenTikkie = () => {
    this.setState((prevState) => ({
      isOpenTikkie: !prevState.isOpenTikkie,
    }));
  };

  setSelectionDateOption = (expiryDateOption) => {
    if (expiryDateOption === '1 week') {
      this.setState({
        expiryDateOption,
        showMenu: false,
        expiryDate: moment().add(1, 'week').endOf(),
      });
    } else if (expiryDateOption === '2 weeks') {
      this.setState({
        expiryDateOption,
        showMenu: false,
        expiryDate: moment().add(2, 'weeks').endOf(),
      });
    } else if (expiryDateOption === '1 month') {
      this.setState({
        expiryDateOption,
        showMenu: false,
        expiryDate: moment().add(1, 'month').endOf(),
      });
    } else if (expiryDateOption === '6 months') {
      this.setState({
        expiryDateOption,
        showMenu: false,
        expiryDate: moment().add(6, 'months').endOf(),
      });
    } else if (expiryDateOption === '1 year') {
      this.setState({
        expiryDateOption,
        showMenu: false,
        expiryDate: moment().add(1, 'year').endOf(),
      });
    } else if (expiryDateOption === 'other') {
      this.setState({ expiryDateOption, showCalendar: true });
    }
  };

  hasSelectionDateOption = (expiryDateOption) =>
    this.state.expiryDateOption === expiryDateOption;

  hasCustomExpiryDate = () => this.state.selectedExpiryDate !== null;

  applyCustomDate = () =>
    this.setState((prevState) => ({
      expiryDate: prevState.selectedExpiryDate,
      selectedExpiryDate: null,
      showMenu: false,
      showCalendar: false,
    }));

  getExpiryLabel = () => {
    const expiry = !this.state.expiryDate
      ? moment().add(this.props.defaultExpiryDays, 'days').endOf()
      : this.state.expiryDate;
    const now = moment();
    const isSameYear = now.year() === expiry.year();

    return isSameYear ? expiry.format(' D MMM') : expiry.format(" D MMM 'YY");
  };

  hideCalendar = () => {
    const showCalendar = false;
    this.setState({ showCalendar });
  };

  onCreateTikkie = () => {
    const { amount, description, invoiceNumber, expiryDate, isOpenTikkie } =
      this.state;

    const amountInCents = parseInt(amount.replace(',', ''), 10);

    if (invoiceNumber.match(/^[A-Za-z0-9-!&'()+-./:?_`, ]+$/)) {
      this.props.createTikkie(
        amountInCents,
        description,
        invoiceNumber,
        expiryDate.valueOf(),
        isOpenTikkie,
      );
    } else {
      this.setState({ showCharError: true });
    }
  };

  // Error setActiveStep
  hasHardThresholdError = () => this.state.showHardError;

  render() {
    const {
      showMenu,
      showCalendar,
      isOpenTikkie,
      showAmountError,
      showCharError,
    } = this.state;

    const { showDashboard, openTikkieOption, isCreating } = this.props;

    return (
      <PaymentRequestCreateWrapper>
        {showMenu && <Glass onClick={this.toggleMenu} />}
        <BackButton id="backToOverview" onClick={() => showDashboard()}>
          <BackArrow src="back.svg" />
          <FormattedMessage {...messages.back} />
        </BackButton>
        {!this.hasHardThresholdError() && (
          <CreateTikkieContentWrapper>
            <CreateTikkieInputWrapper>
              <NonMobileWrapper>
                <ExpiryButtonWrapper>
                  <Dropdown onClick={this.toggleMenu}>
                    <CalendarIcon src="calendar.svg" />
                    <FormattedMessage {...messages.validUntil} />
                    {this.getExpiryLabel()}
                    <i className="fa fa-caret-down" />
                  </Dropdown>

                  {showMenu && !showCalendar && (
                    <OptionsWrapper>
                      <UpArrowRight />
                      <Options>
                        <RadioButton
                          id="filterOneWeek"
                          onClick={() => this.setSelectionDateOption('1 week')}
                          checked={this.hasSelectionDateOption('1 week')}
                        >
                          <FormattedMessage {...messages.dateOneWeek} />
                        </RadioButton>
                        <RadioButton
                          id="filterTwoWeeks"
                          onClick={() => this.setSelectionDateOption('2 weeks')}
                          checked={this.hasSelectionDateOption('2 weeks')}
                        >
                          <FormattedMessage {...messages.dateTwoWeeks} />
                        </RadioButton>
                        <RadioButton
                          id="filterOneMonth"
                          onClick={() => this.setSelectionDateOption('1 month')}
                          checked={this.hasSelectionDateOption('1 month')}
                        >
                          <FormattedMessage {...messages.dateOneMonth} />
                        </RadioButton>
                        <RadioButton
                          id="filterSixMonths"
                          onClick={() =>
                            this.setSelectionDateOption('6 months')
                          }
                          checked={this.hasSelectionDateOption('6 months')}
                        >
                          <FormattedMessage {...messages.dateSixMonths} />
                        </RadioButton>
                        <RadioButton
                          id="filterOneYear"
                          onClick={() => this.setSelectionDateOption('1 year')}
                          checked={this.hasSelectionDateOption('1 year')}
                        >
                          <FormattedMessage {...messages.dateOneYear} />
                        </RadioButton>
                        <Divider />
                        <RadioButton
                          id="filterOther"
                          onClick={() => this.setSelectionDateOption('other')}
                          checked={this.hasSelectionDateOption('other')}
                        >
                          <FormattedMessage {...messages.dateOther} />
                        </RadioButton>
                      </Options>
                    </OptionsWrapper>
                  )}

                  {showMenu && showCalendar && (
                    <OptionsWrapper>
                      <UpArrowRight />
                      <Calendar>
                        <CalendarBackArrow
                          src="/arrow-back.svg"
                          onClick={() => this.hideCalendar()}
                        />
                        <CalendarHeader />
                        <DayPickerSingleDateController
                          date={this.state.selectedExpiryDate}
                          onDateChange={(selectedExpiryDate) =>
                            this.setState({ selectedExpiryDate })
                          }
                          focused={this.state.focused}
                          onFocusChange={({ focused }) =>
                            this.setState({ focused })
                          }
                          enableOutsideDays={false}
                          isOutsideRange={(day) =>
                            day.isBefore(moment().startOf('day'))
                          }
                          daySize={44}
                          hideKeyboardShortcutsPanel
                          keepOpenOnDateSelect
                          navPrev={<NavPrev src="chevron.svg" />}
                          navNext={<NavNext src="chevron.svg" />}
                          numberOfMonths={1}
                        />
                      </Calendar>
                      <CalendarButton
                        disabled={!this.hasCustomExpiryDate()}
                        onClick={this.applyCustomDate}
                      >
                        <FormattedMessage {...messages.applyDate} />
                      </CalendarButton>
                    </OptionsWrapper>
                  )}
                </ExpiryButtonWrapper>
                <Header>
                  <FormattedMessage {...messages.header} />
                </Header>
              </NonMobileWrapper>
              <CreateTikkieInputs>
                {this.isInputRowVisible('amount') && (
                  <CreateTikkieInput
                    visible={this.isInputVisible('amount')}
                    completed={this.isInputCompleted('amount')}
                    focussed={this.isInputFocussed('amount')}
                  >
                    <BackArrowWrapper>
                      <BackArrow src="/arrow-back.svg" onClick={this.goBack} />
                    </BackArrowWrapper>
                    <LabelWrapper>
                      <Label>
                        <FormattedMessage {...messages.amount} />
                      </Label>
                    </LabelWrapper>
                    {isOpenTikkie && (
                      <LabelWrapper>
                        <LabelShort>
                          <FormattedMessage {...messages.open} />
                        </LabelShort>
                      </LabelWrapper>
                    )}
                    {!isOpenTikkie && (
                      <EuroWrapper lang="nl-NL">
                        <Euro>&euro;</Euro>
                        <FormattedMessage {...messages.amountPlaceholder}>
                          {(placeholder) => (
                            <Currency
                              id="tikkieAmount"
                              placeholder={placeholder}
                              value={this.state.amount}
                              onFocus={() => this.setActiveStep('amount')}
                              onChange={this.handleChangeAmount}
                              onBlur={this.handleBlurAmount}
                              onKeyPress={this.handleKeyPressAmount}
                              autoFocus={this.isInputFocussed('amount')}
                              maxLength="9"
                              type="text"
                              pattern="[0-9],."
                              inputmode="numeric"
                              autoComplete="off"
                              data-test-id="payment-request-create-amount-input"
                            />
                          )}
                        </FormattedMessage>
                        {showAmountError && (
                          <ErrorWrapper id="idealMax">
                            <ErrorBubble text="Sorry, € 50.000 is echt de max" />
                          </ErrorWrapper>
                        )}
                      </EuroWrapper>
                    )}
                    {openTikkieOption && (
                      <Toggle
                        id="openTikkie"
                        onClick={this.toggleOpenTikkie}
                        active={this.state.isOpenTikkie}
                      >
                        {this.state.isOpenTikkie && (
                          <img src="active.svg" alt="" />
                        )}
                        {!this.state.isOpenTikkie && (
                          <img src="inactive.svg" alt="" />
                        )}
                        <FormattedMessage {...messages.openTikkieText} />
                      </Toggle>
                    )}
                    <NextWrapper>
                      <NextButton
                        disabled={!this.isInputCompleted('amount')}
                        onClick={this.goNext}
                      >
                        <FormattedMessage {...messages.next} />
                      </NextButton>
                    </NextWrapper>
                  </CreateTikkieInput>
                )}
                {this.isInputRowVisible('description') && (
                  <CreateTikkieInput
                    visible={this.isInputVisible('description')}
                    completed={this.isInputCompleted('description')}
                    focussed={this.isInputFocussed('description')}
                  >
                    <BackArrowWrapper>
                      <BackArrow src="/arrow-back.svg" onClick={this.goBack} />
                    </BackArrowWrapper>
                    <CharacterCounter>
                      {this.state.descriptionLength}
                    </CharacterCounter>
                    <LabelWithDescription>
                      <Label>
                        <FormattedMessage {...messages.description} />
                        <CaptionSmall>
                          <FormattedMessage {...messages.descriptionCaption} />
                        </CaptionSmall>
                      </Label>
                    </LabelWithDescription>
                    {/* <NonMobileWrapper> */}
                    <FormattedMessage {...messages.descriptionPlaceholder}>
                      {(placeholder) => (
                        <InputField
                          id="description"
                          placeholder={placeholder}
                          onFocus={() => this.setActiveStep('description')}
                          onChange={this.handleChangeDescription}
                          disabled={!this.isInputCompleted('amount')}
                          autoFocus={this.isInputFocussed('description')}
                          maxLength={35}
                          autoComplete="off"
                          data-test-id="payment-request-create-description-input"
                        />
                      )}
                    </FormattedMessage>
                    <NextWrapper>
                      {isCreating && (
                        <NextButton>
                          <AnimationWrapper>
                            <ReactBodymovin options={bodymovinOptions} />
                          </AnimationWrapper>
                        </NextButton>
                      )}
                      {!isCreating && (
                        <NextButton
                          disabled={!this.isInputCompleted('description')}
                          onClick={this.goNext}
                        >
                          <FormattedMessage {...messages.next} />
                        </NextButton>
                      )}
                    </NextWrapper>
                  </CreateTikkieInput>
                )}
                {this.isInputRowVisible('invoiceNumber') && (
                  <CreateTikkieInput
                    visible={this.isInputVisible('invoiceNumber')}
                    completed={this.isInputCompleted('invoiceNumber')}
                    focussed={this.isInputFocussed('invoiceNumber')}
                  >
                    <BackArrowWrapper>
                      <BackArrow src="/arrow-back.svg" onClick={this.goBack} />
                    </BackArrowWrapper>
                    <CharacterCounter>
                      {this.state.invoiceNumberLength}
                    </CharacterCounter>
                    <LabelWrapper>
                      <LabelWithDescription>
                        <FormattedMessage {...messages.invoice} />
                        <CaptionSmall>
                          <FormattedMessage {...messages.invoiceCaption} />
                        </CaptionSmall>
                      </LabelWithDescription>
                    </LabelWrapper>
                    <EuroWrapper>
                      <FormattedMessage {...messages.invoicePlaceholder}>
                        {(placeholder) => (
                          <InputField
                            id="invoiceNumber"
                            placeholder={placeholder}
                            onFocus={() => this.setActiveStep('invoiceNumber')}
                            onChange={this.handleChangeInvoiceNumber}
                            disabled={!this.isInputCompleted('description')}
                            autoFocus={this.isInputFocussed('invoiceNumber')}
                            maxLength={35}
                            autoComplete="off"
                          />
                        )}
                      </FormattedMessage>
                      {showCharError && (
                        <ErrorWrapper id="refIdChars">
                          <ErrorBubble text="Je kunt alleen letters, cijfers, spaties en deze tekens gebruiken: !&'()+-./:?_`," />
                        </ErrorWrapper>
                      )}
                    </EuroWrapper>
                    <NextWrapper>
                      {isCreating && (
                        <NextButton>
                          <AnimationWrapper>
                            <ReactBodymovin options={bodymovinOptions} />
                          </AnimationWrapper>
                        </NextButton>
                      )}
                      {!isCreating && (
                        <NextButton
                          disabled={
                            !this.isInputCompleted('description') ||
                            !this.isInputCompleted('invoiceNumber')
                          }
                          onClick={this.goNext}
                        >
                          <FormattedMessage {...messages.createTikkie} />
                        </NextButton>
                      )}
                    </NextWrapper>
                  </CreateTikkieInput>
                )}
              </CreateTikkieInputs>
            </CreateTikkieInputWrapper>
            <CreateTikkieFooter>
              {isCreating && (
                <CreateTikkieButton>
                  <AnimationWrapper>
                    <ReactBodymovin options={bodymovinOptions} />
                  </AnimationWrapper>
                </CreateTikkieButton>
              )}
              {!isCreating && (
                <CreateTikkieButton
                  id="createTikkie"
                  disabled={!this.isCreateButtonEnabled()}
                  onClick={this.onCreateTikkie}
                >
                  <img src="okicon-o.svg" alt="" />
                  <FormattedMessage {...messages.createTikkie} />
                </CreateTikkieButton>
              )}
            </CreateTikkieFooter>
          </CreateTikkieContentWrapper>
        )}
        {this.hasHardThresholdError() && (
          <CreateTikkieContentWrapper>
            <CreateTikkieInputWrapper>
              <Header>
                <FormattedMessage {...messages.header} />
              </Header>
              <CreateTikkieInputs>
                <GifjeContainer>
                  <GifjeWrapper id="createTikkieFail">
                    <GiphyBox
                      gif="oops2.gif"
                      onClick={() => {
                        window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
                      }}
                      onSecondaryClick={() => {
                        showDashboard();
                      }}
                      title={this.state.errorTitle}
                      text={this.state.errorText}
                      button={this.state.errorButton}
                      secondaryButton={this.state.errorSecondaryButton}
                    />
                  </GifjeWrapper>
                </GifjeContainer>
              </CreateTikkieInputs>
            </CreateTikkieInputWrapper>
          </CreateTikkieContentWrapper>
        )}
      </PaymentRequestCreateWrapper>
    );
  }
}

PaymentRequestCreate.propTypes = {
  intl: PropTypes.object.isRequired,
  isCreating: PropTypes.bool.isRequired,
  createTikkieErrorStatus: PropTypes.number.isRequired,
  createTikkieErrorMessage: PropTypes.string,
  showDashboard: PropTypes.func.isRequired,
  showShareTikkie: PropTypes.func.isRequired,
  defaultExpiryDays: PropTypes.number.isRequired,
  openTikkieOption: PropTypes.bool.isRequired,
  createTikkie: PropTypes.func.isRequired,
  tikkie: PropTypes.object,
};

export default injectIntl(PaymentRequestCreate);

/*
 * LinkSent Messages
 *
 * This contains all the text for the LinkSent component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  checkMail: {
    id: 'app.components.LinkSent.checkMail',
    defaultMessage: 'Koekeloer in jou mailbox',
  },
  checkMailInfo: {
    id: 'app.components.LinkSent.checkMailInfo',
    defaultMessage:
      'Indien ons jou email adres herken dan is die e-pos met jou reset link al oppad na jou mailbox toe.',
  },
  checkMailInfoFirstPw: {
    id: 'app.components.LinkSent.checkMailInfoFirstPw',
    defaultMessage: 'We hebben je een mailtje gestuurd met een nieuwe link.',
  },
  checkMailButton: {
    id: 'app.components.LinkSent.checkMailButton',
    defaultMessage: 'Regso, baie dankie!',
  },
});

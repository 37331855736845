import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GetMobile } from '../../../components/OnboardingKYC';
import {
  getCurrentItem,
  resendSms,
  savePhoneNumber,
  sendSMS,
  verifyCode,
} from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectGenericError,
  selectIsSmsResent,
  selectOnboardingKYCData,
  selectResendingSms,
} from '../selectors';
import { enterPhone, transitionScreen, verifyPhone } from './constants';
import VerificationCode from '../../../components/OnboardingKYC/VerificationCode';
import FormWrapperContainer from '../FormWrapperContainer';
import SignUpOrganisation from '../../../components/OnboardingKYC/SignUpOrganisation';

const steps = [enterPhone, verifyPhone, transitionScreen];

function VerifyPhoneContainer(props) {
  const [currentStep, setCurrentStep] = useState(enterPhone);
  const prevStep = () =>
    setCurrentStep(props.getPreviousStep(steps, currentStep));
  const nextStep = () => setCurrentStep(props.getNextStep(steps, currentStep));
  const { genericError } = props;

  const hasError = () =>
    genericError.errorCode === 2082 || genericError.errorCode === 2078;

  const isAbnOrAabFlow =
    props.onboardingData.hasAbnBank || props.onboardingData.aabOnboarding;

  return (
    <>
      {currentStep !== transitionScreen && (
        <FormWrapperContainer
          hasSpecificError={hasError()}
          prevStep={prevStep}
          nextStep={nextStep}
        >
          {currentStep === enterPhone && (
            <GetMobile
              nextStep={nextStep}
              sendSMS={props.sendSMS}
              savePhoneNumber={props.savePhoneNumber}
              phoneNumber={props.onboardingData.phoneNumber}
            />
          )}
          {currentStep === verifyPhone && (
            <VerificationCode
              prevStep={prevStep}
              nextStep={nextStep}
              phoneNumber={props.onboardingData.phoneNumber}
              resendSms={props.resendSms}
              resendingSms={props.resendingSms}
              isSmsResent={props.isSmsResent}
              verifyCode={props.verifyCode}
              hasError={hasError()}
              error={genericError}
            />
          )}
        </FormWrapperContainer>
      )}
      {currentStep === transitionScreen && (
        <FormWrapperContainer portalBacking noProgressBar>
          <SignUpOrganisation
            nextStep={props.getCurrentItem}
            isAbnOrAabFlow={isAbnOrAabFlow}
          />
        </FormWrapperContainer>
      )}
    </>
  );
}

VerifyPhoneContainer.propTypes = {
  getCurrentItem: PropTypes.func.isRequired,
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  sendSMS: PropTypes.func.isRequired,
  savePhoneNumber: PropTypes.func.isRequired,
  onboardingData: PropTypes.object,
  resendSms: PropTypes.func.isRequired,
  resendingSms: PropTypes.bool.isRequired,
  isSmsResent: PropTypes.bool.isRequired,
  verifyCode: PropTypes.func.isRequired,
  genericError: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  sendSMS: (mobile) => dispatch(sendSMS(mobile)),
  savePhoneNumber: (mobile) => dispatch(savePhoneNumber(mobile)),
  resendSms: (mobile) => dispatch(resendSms(mobile)),
  verifyCode: (code) => dispatch(verifyCode(code)),
  getCurrentItem: () => dispatch(getCurrentItem()),
});

const mapStateToProps = createStructuredSelector({
  onboardingData: selectOnboardingKYCData,
  resendingSms: selectResendingSms,
  isSmsResent: selectIsSmsResent,
  genericError: selectGenericError,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(VerifyPhoneContainer);

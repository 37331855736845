import React from 'react';
import messages from './messages';
import { OnboardingParagraph } from '../styledComponents';
import { Space } from '../../styleguide';
import Screen from './Screen';

interface RejectProps {
  organisationName: string;
  mailSupport: () => void;
}

function Reject({ organisationName, mailSupport }: RejectProps): JSX.Element {
  return (
    <Screen
      header={messages.generalErrorHeader.defaultMessage || ''}
      buttonText={messages.askSupportButtonText.defaultMessage}
      buttonClick={mailSupport}
    >
      <OnboardingParagraph
        marginTop={Space.s0}
        marginBottom={Space.s8}
        textAlign="center"
      >
        {messages.validationErrorIntro.defaultMessage}
        <strong>{`${organisationName} `}</strong>
        {messages.rejectErrorPart1.defaultMessage}
      </OnboardingParagraph>
      <OnboardingParagraph
        marginTop={Space.s0}
        marginBottom={Space.s24}
        textAlign="center"
      >
        {messages.rejectErrorPart2.defaultMessage}
      </OnboardingParagraph>
    </Screen>
  );
}

export default Reject;

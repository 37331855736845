import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';
import FormWrapperContainer from '../FormWrapperContainer';

import { selectOrganisationName, selectBadSector } from '../selectors';
import BadSector from '../../../components/OnboardingKYC/BadSector';
import { setBadSector, submitBadSector } from '../actions';

function ContactNameContainer(props) {
  return (
    <FormWrapperContainer portalBacking>
      <BadSector
        badSector={props.badSector}
        setBadSector={props.setBadSector}
        nextStep={() => props.submitBadSector(props.badSector)}
        organisationName={props.organisationName}
      />
    </FormWrapperContainer>
  );
}

ContactNameContainer.propTypes = {
  organisationName: PropTypes.string.isRequired,
  badSector: PropTypes.bool,
  setBadSector: PropTypes.func.isRequired,
  submitBadSector: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  badSector: selectBadSector,
});

const mapDispatchToProps = (dispatch) => ({
  setBadSector: (badSector) => dispatch(setBadSector(badSector)),
  submitBadSector: (badSector) => dispatch(submitBadSector(badSector)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(ContactNameContainer);

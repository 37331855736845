/**
 *
 * Organisation Settings
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/money.svg';
import {
  PageTitle,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardTitle,
  CardBody,
  CardActions,
  Caption,
  CaptionSmall,
  Paragraph,
  Button,
  InputTile,
  HR,
} from 'amber';
import { withTranslation } from 'react-i18next';
import {
  ErrorPageWithContact,
  ErrorPageNoContact,
  FooterContent,
  FooterWrapper,
  BackButton,
  InputErrorBox,
} from '../styledComponents';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import { analyticsPageview } from '../../../ga4';

/* eslint-disable react/prefer-stateless-function */
export class OrganisationSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organisationName: props.settingsData.organisationName,
      displayName: props.settingsData.displayName,
      internalPortalName: props.settingsData.internalPortalName,
      internalPortalNameError: false,
      shortName: props.settingsData.shortName,
      stateChanged: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  componentDidMount() {
    analyticsPageview();
  }

  componentDidUpdate(oldProps) {
    const { isUpdating, settingsLoadingError, settingsUpdatingError } =
      this.props;

    const updatedSettings =
      !isUpdating &&
      oldProps.isUpdating &&
      !settingsLoadingError &&
      !settingsUpdatingError;

    if (updatedSettings) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        organisationName: this.props.settingsData.organisationName,
        displayName: this.props.settingsData.displayName,
        internalPortalName: this.props.settingsData.internalPortalName,
        shortName: this.props.settingsData.shortName,
        stateChanged: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetUpdateSettings();
  }

  cancel = () => {
    this.setState({
      internalPortalName: this.props.settingsData.internalPortalName,
      stateChanged: false,
    });
  };

  saveChanges = () => {
    const { internalPortalName } = this.state;
    const userSetting = {
      ...this.props.settingsData.userSetting,
    };
    this.props.updateSettings({
      ...this.props.settingsData,
      userSetting,
      internalPortalName,
    });
    this.setState({ stateChanged: false });
  };

  handleFieldChange = (fieldId, value) => {
    this.setState({ [fieldId]: value });
    this.setState({ stateChanged: true });
  };

  handleInternalPortalNameField = (fieldId, value) => {
    if (isEmpty(value) || value.length > 50) {
      this.setState({ internalPortalNameError: true });
    } else {
      this.setState({ internalPortalNameError: false });
    }
    this.handleFieldChange(fieldId, value);
  };

  sendMail = () => {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}?subject=${t(
      'portal.settings.organisation.email.subject',
    )}&body=${t('portal.settings.organisation.email.body')}`;
  };

  hasErrors = () => this.state.internalPortalNameError;

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      gotoPortalOverview,
      gotoSettingsDashboard,
      settingsLoadingError,
      settingsLoadingErrorStatusCode,
      settingsUpdatingError,
    } = this.props;
    const {
      organisationName,
      displayName,
      internalPortalName,
      internalPortalNameError,
      shortName,
      stateChanged,
    } = this.state;

    if (settingsLoadingError) {
      if (settingsLoadingErrorStatusCode === 403) {
        return ErrorPageNoContact({
          pageTitle: t('portal.settings.general.header'),
          showUnauthorizedDescription: true,
          onClickBackOverview: gotoPortalOverview,
        });
      }
      return ErrorPageWithContact({
        pageTitle: t('portal.settings.general.header'),
        onClickBackOverview: gotoPortalOverview,
      });
    }

    if (settingsUpdatingError) {
      return ErrorPageNoContact({
        pageTitle: t('portal.settings.general.header'),
        onClickBackOverview: gotoSettingsDashboard,
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={t('portal.settings.general.backButton')}
        />
        <PageTitle>
          {t('portal.settings.general.header')}
          {internalPortalName}
        </PageTitle>
        <Container row mb={24}>
          <Card>
            <CardMedia src="/settings.svg" rounded={false} />
            <CardContent media>
              <CardTitle>{t('portal.settings.organisation.header')}</CardTitle>
            </CardContent>
            <Container row pt={24}>
              <HR />
              <Container row pt={32} pb={16}>
                <CaptionSmall>
                  {t('portal.settings.general.description')}
                </CaptionSmall>
              </Container>
              <Container row>
                <Paragraph>
                  {t('portal.settings.organisation.description.text')}
                </Paragraph>
              </Container>
            </Container>
          </Card>
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.organisation.internalName.title')}
            desc={t('portal.settings.organisation.internalName.description')}
            value={internalPortalName}
            infoDesc={t('portal.settings.organisation.internalName.helpText')}
            id="internalPortalName"
            dataTestId="Organisation-InternalPortalName"
            onChange={this.handleInternalPortalNameField}
          >
            {internalPortalNameError && (
              <InputErrorBox
                errorText={t('portal.settings.organisation.internalName.error')}
                withInfo
              />
            )}
          </InputTile>
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.organisation.officialName.title')}
            desc={t('portal.settings.organisation.officialName.description')}
            value={organisationName}
            infoDesc={t('portal.settings.organisation.officialName.helpText')}
            disabled
          />
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.organisation.shortName.title')}
            desc={t('portal.settings.organisation.shortName.description')}
            value={shortName}
            infoDesc={t('portal.settings.organisation.shortName.helpText')}
            disabled
          />
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.organisation.externalName.title')}
            desc={t('portal.settings.organisation.externalName.description')}
            value={displayName}
            infoDesc={t('portal.settings.organisation.externalName.helpText')}
            disabled
          />
        </Container>
        <Container row mb={24}>
          <Card>
            <CardContent center>
              <CardTitle>
                {t('portal.settings.organisation.changeName.title')}
              </CardTitle>
              <CardBody>
                <Caption>
                  {t('portal.settings.organisation.changeName.description')}
                </Caption>
              </CardBody>
              <CardActions>
                <Button onClick={this.sendMail}>
                  {t('portal.settings.general.mailButton')}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Container>

        <FooterWrapper slideIn={stateChanged}>
          {stateChanged && (
            <FooterContent>
              <Button type="secondary" onClick={this.cancel}>
                {t('portal.settings.general.update.cancelButton')}
              </Button>
              <Button
                type="primary"
                dataTestId="Organisation-SaveButton"
                onClick={this.saveChanges}
                disabled={this.hasErrors()}
              >
                {t('portal.settings.general.update.saveButton')}
              </Button>
            </FooterContent>
          )}
        </FooterWrapper>
      </Container>
    );
  }
}

OrganisationSettings.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  settingsUpdatingSuccess: PropTypes.bool.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  settingsUpdatingError: PropTypes.bool.isRequired,
  resetUpdateSettings: PropTypes.func.isRequired,
};

export default withTranslation()(OrganisationSettings);

/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CLEAR_LINKED_ORGANISATIONS,
  LOAD_LINKED_ORGANISATIONS,
  LOAD_LINKED_ORGANISATIONS_ERROR,
  LOAD_LINKED_ORGANISATIONS_SUCCESS,
} from './constants';

const initialState = fromJS({
  organisations: [],
  organisationsIsLoading: false,
  organisationsLoadError: {},
});

function menuReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LINKED_ORGANISATIONS:
      return state
        .set('organisationsIsLoading', true)
        .set('organisationsLoadError', fromJS({}));
    case LOAD_LINKED_ORGANISATIONS_SUCCESS:
      return state
        .set('organisationsIsLoading', false)
        .set('organisations', fromJS(action.payload));
    case LOAD_LINKED_ORGANISATIONS_ERROR:
      return state
        .set('organisationsIsLoading', false)
        .set('organisationsLoadError', fromJS(action.payload));
    case CLEAR_LINKED_ORGANISATIONS:
      return state
        .set('organisations', fromJS(initialState.get('organisations')))
        .set(
          'organisationsIsLoading',
          initialState.get('organisationsIsLoading'),
        )
        .set(
          'organisationsLoadError',
          fromJS(initialState.get('organisationsLoadError')),
        );
    default:
      return state;
  }
}

export default menuReducer;

import '!file-loader?name=[name].[ext]!../../../images/icons/cogwheel.svg';
import '!file-loader?name=[name].[ext]!../../../images/transactionicon.svg';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color } from '../../styleguide';
import { formatDate } from '../../../utils';
import * as messages from './messages';

const AuditListItemWrapper = styled.div`
  position: relative;
  display: block;
  line-height: 72px;
  padding: 0 24px 0 0;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-left: 8px solid ${(props) => props.color};
  vertical-align: middle;
  font-family: MuseoSansRounded700;
  font-size: 16px;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    height: auto;
    padding: 16px;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: auto;
  margin-right: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    display: grid;
    margin-right: 0;
  }
`;

const ItemIconWrapper = styled.div`
  float: left;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 20px 16px;
  border-radius: 6.4px;
  background-color: rgba(104, 104, 193, 0.1);
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    width: 32px;
    margin: 0px;
    float: none;
  }
`;

const Created = styled.div`
  display: flex;
  justify-content: flex-start;
  float: left;
  min-width: 100px;
  height: 72px;
  line-height: 72px;
  color: ${Color.gray03};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    justify-content: flex-start;
    width: 280px;
    height: 24px;
    line-height: 24px;
  }
`;

const User = styled.div`
  white-space: initial;
  line-height: 1em;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  float: left;
  height: 72px;
  min-width: 150px;
  margin-right: 16px;
  font-family: MuseoSansRounded;
  color: ${(props) => props.color};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  @media ${(props) => props.theme.device.mobile} {
    justify-content: flex-start;
    width: 280px;
    height: 24px;
    line-height: 24px;
  }
`;

const Description = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1.2em;
  margin-right: 16px;
  font-family: MuseoSansRounded;
  color: ${(props) => props.color};
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${(props) => props.theme.device.mobile} {
    width: 280px;
    line-height: 24px;
  }
`;

export function AuditListItem(props) {
  const { auditLog } = props;
  const getIcon = () => {
    switch (auditLog.logType) {
      case 'USER':
        return <>🧑‍💼</>;
      case 'SUBSCRIPTION':
        return <>🧾</>;
      default:
        // SETTING
        return <>⚙️</>;
    }
  };

  return (
    <AuditListItemWrapper
      color="#9D9DD7"
      data-test-id="audit-list-item-wrapper"
    >
      <ItemIconWrapper>{getIcon()}</ItemIconWrapper>
      <ContentWrapper>
        <Created>{formatDate(messages.yesterday, auditLog.created)}</Created>
        <User color="#57565B">{auditLog.userName}</User>
        <Description color="#57565B">{auditLog.description}</Description>
      </ContentWrapper>
    </AuditListItemWrapper>
  );
}

AuditListItem.propTypes = {
  auditLog: PropTypes.object.isRequired,
};

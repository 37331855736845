import { fromJS } from 'immutable';
import { clearLocalStorage } from '../../components/Deactivation/deactivation-banner';

import {
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  SAVE_PAYMENT_REQUEST_DRAFT,
  SET_CURRENT_PAGE_NAME,
  SWITCH_ORGANISATION,
  CONFIRM_EMAIL_CHANGE_SUCCESS,
  CONFIRM_EMAIL_CHANGE_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  RESET_USER_UPDATE,
} from './constants';

const initialState = fromJS({
  user: {},
  tempUser: {},
  userIsLoading: false,
  userErrorMessage: null,
  userUpdateIsLoading: false,
  userUpdateError: null,
  paymentRequestDraft: {},
  currentPageName: '',
  checkEmail: false,
  emailChangeSuccess: false,
  emailChangeError: false,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_ORGANISATION:
      clearLocalStorage();
      return state
        .set('userIsLoading', true)
        .set('userErrorMessage', null)
        .set('user', fromJS({}));
    case LOAD_USER:
      return state
        .set('userIsLoading', true)
        .set('userErrorMessage', null)
        .set('user', fromJS({}));
    case LOAD_USER_SUCCESS:
      return state
        .set('userIsLoading', false)
        .set('userErrorMessage', null)
        .set('user', fromJS(action.payload.user));
    case LOAD_USER_ERROR:
      return state
        .set('userIsLoading', false)
        .set('userErrorMessage', action.payload.error)
        .set('user', fromJS({}));
    case UPDATE_USER:
      return state
        .set('userUpdateIsLoading', true)
        .set('userUpdateError', null)
        .set(
          'tempUser',
          fromJS({ ...state.get('user'), ...action.payload.user }),
        );
    case UPDATE_USER_SUCCESS:
      return state
        .set('userUpdateIsLoading', false)
        .set('userUpdateError', null)
        .set('tempUser', fromJS({}))
        .set('checkEmail', action.payload.checkEmail)
        .set('user', fromJS(action.payload.user));
    case UPDATE_USER_ERROR:
      return state
        .set('userUpdateIsLoading', false)
        .set('userUpdateError', action.payload)
        .set(
          'tempUser',
          action.payload.errorCode === 2050 || action.payload.errorCode === 2040
            ? state.get('tempUser')
            : fromJS({}),
        );
    case RESET_USER_UPDATE:
      return state
        .set('tempUser', fromJS({}))
        .set('checkEmail', false)
        .set('userUpdateError', null);
    case SAVE_PAYMENT_REQUEST_DRAFT:
      return state.set('paymentRequestDraft', action.payload);
    case SET_CURRENT_PAGE_NAME:
      return state.set('currentPageName', action.payload);
    case CONFIRM_EMAIL_CHANGE_SUCCESS:
      return state
        .set('emailChangeSuccess', true)
        .set('emailChangeError', false);
    case CONFIRM_EMAIL_CHANGE_ERROR:
      return state
        .set('emailChangeSuccess', false)
        .set('emailChangeError', true);
    default:
      return state;
  }
}

export default appReducer;

export { initialState };

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import { isEmpty } from '../../../utils';
import { loadSettings, updateSettings } from '../actions';
import {
  selectSettingsData,
  selectSettingsLoading,
  selectSettingsLoadingError,
  selectSettingsLoadingErrorStatusCode,
  selectSettingsUpdating,
  selectSettingsUpdatingError,
} from '../selectors';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../components/components';
import Pages from '../../../components/Settings/Pages';
import EmptyLoader from '../../../components/Util/Loader';

function PagesSettingsContainer({
  settingsData,
  settingsLoading,
  settingsLoadingError,
  settingsLoadingErrorStatusCode,
  loadAllSettings,
  settingsUpdatingError,
  updateOrgSettings,
  isUpdating,
}) {
  const history = useHistory();

  useEffect(
    () => {
      if (isEmpty(settingsData)) {
        loadAllSettings();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  return (
    <>
      {settingsLoading && <EmptyLoader />}
      {!settingsLoading && (
        <DashboardWrapper>
          <Container>
            <InnerContainer highMargin>
              <Pages
                gotoPortalOverview={gotoPortalOverview}
                gotoSettingsDashboard={gotoSettingsDashboard}
                settingsData={settingsData}
                settingsLoading={settingsLoading}
                settingsLoadingError={settingsLoadingError}
                settingsLoadingErrorStatusCode={settingsLoadingErrorStatusCode}
                loadSettings={loadAllSettings}
                updateSettings={updateOrgSettings}
                settingsUpdatingError={settingsUpdatingError}
                isUpdating={isUpdating}
              />
            </InnerContainer>
          </Container>
        </DashboardWrapper>
      )}
    </>
  );
}

PagesSettingsContainer.propTypes = {
  settingsData: PropTypes.object,
  settingsLoading: PropTypes.bool,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  loadAllSettings: PropTypes.func,
  updateOrgSettings: PropTypes.func,
  settingsUpdatingError: PropTypes.bool,
  isUpdating: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  settingsLoading: selectSettingsLoading,
  settingsLoadingError: selectSettingsLoadingError,
  settingsLoadingErrorStatusCode: selectSettingsLoadingErrorStatusCode,
  settingsData: selectSettingsData,
  settingsUpdatingError: selectSettingsUpdatingError,
  isUpdating: selectSettingsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllSettings: () => dispatch(loadSettings()),
  updateOrgSettings: (settingsData) => dispatch(updateSettings(settingsData)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'PagesSettingsContainer', saga });

export default compose(withSaga, withConnect)(PagesSettingsContainer);

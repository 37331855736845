/**
 *
 * ResetPassword
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';
import '!file-loader?name=[name].[ext]!../../../images/checkmark.svg';
import '!file-loader?name=[name].[ext]!../../../images/gifs/oops.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/succes.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/link-not-working.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/link-not-valid.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/oh-no-cat.gif';
import '!file-loader?name=[name].[ext]!../../../images/gifs/champagne.gif';

import { FormattedMessage } from 'react-intl';
import { Header2, Header4, PrimaryButton, Paragraph } from '../../styleguide';

import { LightBox, LightBoxBackArrow } from '../../components';
import Password from '../../Password/Password';

import messages from './messages';
import animationData from '../../../images/animations/white-dots.json';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';

const ResetPasswordWrapper = styled(LightBox)``;

const BackArrow = styled(LightBoxBackArrow)``;

const StepWrapper = styled.div`
  border: none;
`;

const GifWrapper = styled.div`
  margin: 0px auto 5px auto;
  width: 176px;
  height: 176px;
  border: 12px solid ${(props) => props.theme.palette.lightPurple};
  border-radius: 88px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 0px;
  }

  @media ${(props) => props.theme.device.tablet} {
    margin-top: 0px;
  }
`;

const Gifje = styled.img`
  width: 100%;
  max-height: 100%;
  border: 8px solid ${(props) => props.theme.palette.purple};
  border-radius: 88px;
`;

const MessageWrapper = styled.div`
  margin: 20px 50px 20px 50px;
  color: #57565b;
`;

const MessageTitle = styled(Header2)`
  margin-bottom: 5px;
  min-height: 26px;
  text-align: center;
`;

const MessageText = styled(Paragraph)`
  text-align: center;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled(Header4)`
  margin: 0px 40px 10px 8px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 0px;
    margin-bottom: 16px;
  }
`;

const ErrorMessageBanner = styled.div`
  position: ${(props) => (props.positionBelow ? '' : 'absolute')};
  z-index: 2;
  top: 88px;
  width: 412px;
  height: 47px;
  line-height: 47px;
  border-radius: 10px;
  background-color: #ed5565;
  font-family: MuseoSans300;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    width: auto;
    left: 16px;
    right: 16px;
  }
`;

const ErrorMessage = styled(ErrorMessageBanner)`
  position: absolute;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    left: 0;
    right: 0;
    height: auto;
    min-height: 47px;
    line-height: 17px;
    padding: 15px;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -6px;
  left: 24px;
  background-color: #ed5565;
  transform: rotate(-45deg);
  z-index: -1;
`;

const Footer = styled.div`
  height: 50px;
  line-height: 50px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  float: right;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
  }
`;

const CenteredButtonWrapper = styled(ButtonWrapper)`
  float: none;
  text-align: center;
`;

const Button = styled(PrimaryButton)`
  min-width: 166px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 231px;
  }
`;

const VerificationWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: -10px;
  margin-left: 10px;
  min-height: 180px;
`;

const VerifyOK = styled.div`
  color: ${(props) => props.theme.palette.gray};
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const VerifyIconWrapperOK = styled.img`
  margin-right: 9px;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.green};
`;

const VerifyNOK = styled(VerifyOK)`
  color: ${(props) => props.theme.palette.lightGray};
`;

const VerifyIconWrapperNOK = styled.img`
  margin-right: 9px;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.lightGray};
`;

const AnimationWrapper = styled.div`
  display: inline-block;
  height: 18px;

  .react-bodymovin-container {
    height: 18px;
  }
`;

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

/* eslint-disable react/prefer-stateless-function */
class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      hasUppercaseLetter: false,
      hasLowercaseLetter: false,
      hasNumber: false,
      hasSpecialCharacterNoSpaces: false,
      hasAtLeast8Characters: false,
      passwordMatches: false,
      isValidPassword: false,
      showPasswordMatchScreen: false,
      passwordHasWeirdCharactersError: false,
    };

    this.resetRef = React.createRef();
    this.confirmRef = React.createRef();
  }

  passwordVariablePostFix = this.props.resetPassword ? '' : 'FirstPw';

  onChangePassword = (event) => {
    const password = event.target.value;
    const hasUppercaseLetter = /[A-Z]/.test(password); // ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŸ
    const hasLowercaseLetter = /[a-z]/.test(password); // àáâãäåæçèéêëìíîïÞßðñòóôõöøùúûüýþÿ
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialCharacterNoSpaces =
      /\W|_/.test(password) && !/\s/.test(password);
    const hasAtLeast8Characters = password.length > 7;
    const isValidPassword =
      hasUppercaseLetter &&
      hasLowercaseLetter &&
      hasNumber &&
      hasSpecialCharacterNoSpaces &&
      hasAtLeast8Characters;

    const passwordHasWeirdCharactersError = false;
    const showPasswordMatchScreen = false;

    this.setState({
      password,
      hasUppercaseLetter,
      hasLowercaseLetter,
      hasNumber,
      hasSpecialCharacterNoSpaces,
      hasAtLeast8Characters,
      isValidPassword,
      passwordHasWeirdCharactersError,
      showPasswordMatchScreen,
    });
  };

  onVerifyPassword = (event) => {
    const password = event.target.value;
    this.setState((prevState) => ({
      passwordMatches: prevState.password === password,
    }));
  };

  disableVerifyButton = () => !this.state.passwordMatches;

  disableResetButton = () => !this.state.isValidPassword;

  passwordHasBeenReset = () => this.props.passwordSetSuccess;

  passwordResetFail = () =>
    this.props.hasPasswordSetError && this.props.passwordSetError !== 422;

  showExpiredLinkScreen = () =>
    this.props.validateTokenError === 422 ||
    this.props.passwordSetError === 422;

  showBadLinkScreen = () =>
    this.props.validateTokenError === 400 ||
    this.props.validateTokenError === 401 ||
    this.props.validateTokenError === 404;

  showChoosePasswordScreen = () =>
    (!this.state.showPasswordMatchScreen &&
      !this.props.isValidatingToken &&
      !this.props.validateTokenError &&
      !this.props.isSettingPassword &&
      !this.props.passwordSetSuccess &&
      !this.props.hasPasswordSetError) ||
    (this.state.showPasswordMatchScreen &&
      (this.props.validatingReusedPasswordError ||
        this.props.isValidatingReusedPassword) &&
      !this.props.isValidatingToken &&
      !this.props.validateTokenError &&
      !this.props.isSettingPassword &&
      !this.props.passwordSetSuccess &&
      !this.props.hasPasswordSetError);

  showPasswordMatchScreen = () =>
    this.state.showPasswordMatchScreen &&
    this.props.validatingReusedPasswordSuccess &&
    !this.props.isValidatingToken &&
    !this.props.validateTokenError &&
    //! this.props.isSettingPassword &&
    !this.props.passwordSetSuccess &&
    !this.props.hasPasswordSetError &&
    !this.props.isValidatingReusedPassword &&
    !this.props.validatingReusedPasswordError;

  showPasswordHasWeirdCharactersError = () =>
    this.state.passwordHasWeirdCharactersError;

  showPreviouslyUsedPasswordError = () =>
    this.state.showPasswordMatchScreen &&
    this.props.validatingReusedPasswordError;

  onHandleKeyDownConfirm = (event) => {
    if (event.key === 'Enter') {
      this.confirmRef.current.props.onClick();
    }
  };

  onHandleKeyDownReset = (event) => {
    if (event.key === 'Enter') {
      this.resetRef.current.props.onClick();
    }
  };

  onCheckPasswordAndVerify = () => {
    const { password } = this.state;
    const specialCharCountMatches = password.match(/[-!@#$%^&+=]/g);
    const weirdCharCountMatches = password.match(/\W|_/g);
    const specialCharCount =
      specialCharCountMatches !== null ? specialCharCountMatches.length : 0;
    const weirdCharCount =
      weirdCharCountMatches !== null ? weirdCharCountMatches.length : 0;
    const passwordHasWeirdCharactersError = weirdCharCount > specialCharCount;

    this.setState({
      showPasswordMatchScreen: !passwordHasWeirdCharactersError,
      passwordHasWeirdCharactersError,
    });

    // Only validate on old passwords in reset password flow
    if (this.props.resetPassword && !passwordHasWeirdCharactersError) {
      this.props.onValidateReusedPassword(
        this.state.password,
        this.props.token,
      );
    }
  };

  onSetPassword = () => {
    this.props.onSetPassword(this.state.password, this.props.token);
  };

  onGoBackToChoosePasswordScreen = () =>
    this.setState({ showPasswordMatchScreen: false });

  onShowLogin = () => this.props.onShowLogin();

  render() {
    return (
      <ResetPasswordWrapper>
        {this.showBadLinkScreen() && (
          <StepWrapper>
            <GifWrapper id="badLinkImage">
              <Gifje src="/link-not-valid.gif" />
            </GifWrapper>
            <MessageWrapper>
              <MessageTitle id="badLinkTitle" data-test-id="badLinkTitle">
                <FormattedMessage {...messages.badLinkErrorTitle} />
              </MessageTitle>
              <MessageText id="badLinkMessage" data-test-id="badLinkMessage">
                <FormattedMessage
                  {...messages[
                    `badLinkErrorText${this.passwordVariablePostFix}`
                  ]}
                />
              </MessageText>
            </MessageWrapper>
            <Footer>
              {this.props.resetPassword && (
                <CenteredButtonWrapper>
                  <Button
                    onClick={() => this.props.onSendNewLink()}
                    id="getNewLinkButton"
                    data-test-id="getNewLinkButton"
                    autoFocus="true"
                  >
                    <FormattedMessage {...messages.badLinkErrorButton} />
                  </Button>
                </CenteredButtonWrapper>
              )}
            </Footer>
          </StepWrapper>
        )}
        {this.showExpiredLinkScreen() && (
          <StepWrapper>
            <GifWrapper id="expiredLinkImage" data-test-id="expiredLinkImage">
              <Gifje
                src={
                  this.props.resetPassword
                    ? '/link-not-working.gif'
                    : 'oh-no-cat.gif'
                }
              />
            </GifWrapper>
            <MessageWrapper>
              <MessageTitle
                id="expiredLinkTitle"
                data-test-id="expiredLinkTitle"
              >
                <FormattedMessage
                  {...messages[
                    `expiryErrorTitle${this.passwordVariablePostFix}`
                  ]}
                />
              </MessageTitle>
              <MessageText
                id="expiredLinkMessage"
                data-test-id="expiredLinkMessage"
              >
                <FormattedMessage
                  {...messages[
                    `expiryErrorText${this.passwordVariablePostFix}`
                  ]}
                />
              </MessageText>
            </MessageWrapper>
            <Footer>
              <CenteredButtonWrapper>
                <Button
                  onClick={this.props.onSendNewLink}
                  id="getNewLinkButton"
                  data-test-id="getNewLinkButton"
                  autoFocus="true"
                >
                  {this.props.isResendingLink && (
                    <AnimationWrapper>
                      <ReactBodymovin options={bodymovinOptions} />
                    </AnimationWrapper>
                  )}
                  {!this.props.isResendingLink && (
                    <FormattedMessage
                      {...messages[
                        `expiryErrorButton${this.passwordVariablePostFix}`
                      ]}
                    />
                  )}
                </Button>
                {this.props.hasResendLinkError && (
                  <ErrorMessageBanner
                    id="resendLinkGenericError"
                    data-test-id="resendLinkGenericError"
                    positionBelow={!this.props.resetPassword}
                  >
                    <FormattedMessage {...messages.resendLinkGenericError} />
                  </ErrorMessageBanner>
                )}
              </CenteredButtonWrapper>
            </Footer>
          </StepWrapper>
        )}
        {this.showChoosePasswordScreen() && (
          <StepWrapper>
            <BackArrow src="/arrow-back.svg" className="hidden" />
            <InputWrapper>
              <Label>
                {this.props.resetPassword ? '' : `${this.props.firstName}, `}
                <FormattedMessage
                  {...messages[
                    `enterNewPassword${this.passwordVariablePostFix}`
                  ]}
                />
              </Label>
              <Password
                onChange={this.onChangePassword}
                onKeyDown={this.onHandleKeyDownConfirm}
                id="resetPassword"
                data-test-id="resetPassword"
                placeholder={
                  messages.enterNewPasswordPlaceholder.defaultMessage
                }
                autoFocus="true"
                autoComplete="off"
                value={this.state.password}
              />
              {this.showPasswordHasWeirdCharactersError() && (
                <ErrorMessage id="invalidPassword">
                  <UpArrow />
                  <FormattedMessage
                    {...messages.passwordHasWeirdCharactersError}
                  />
                </ErrorMessage>
              )}
              {this.showPreviouslyUsedPasswordError() && (
                <ErrorMessage id="oldPassword">
                  <UpArrow />
                  <FormattedMessage {...messages.passwordHasBeenUsedError} />
                </ErrorMessage>
              )}
            </InputWrapper>
            <VerificationWrapper>
              {this.state.hasUppercaseLetter && (
                <VerifyOK id="upperCaseCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordUppercase} />
                </VerifyOK>
              )}
              {!this.state.hasUppercaseLetter && (
                <VerifyNOK id="upperCaseCheckMark" name="unChecked">
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordUppercase} />
                </VerifyNOK>
              )}
              {this.state.hasLowercaseLetter && (
                <VerifyOK id="lowerCaseCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordLowercase} />
                </VerifyOK>
              )}
              {!this.state.hasLowercaseLetter && (
                <VerifyNOK id="lowerCaseCheckMark" name="unChecked">
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordLowercase} />
                </VerifyNOK>
              )}
              {this.state.hasSpecialCharacterNoSpaces && (
                <VerifyOK id="specialCharactersNoSpaceCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordSpecial} />
                </VerifyOK>
              )}
              {!this.state.hasSpecialCharacterNoSpaces && (
                <VerifyNOK
                  id="specialCharactersNoSpaceCheckMark"
                  name="unChecked"
                >
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordSpecial} />
                </VerifyNOK>
              )}
              {this.state.hasNumber && (
                <VerifyOK id="digitCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordNumber} />
                </VerifyOK>
              )}
              {!this.state.hasNumber && (
                <VerifyNOK id="digitCheckMark" name="unChecked">
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordNumber} />
                </VerifyNOK>
              )}
              {this.state.hasAtLeast8Characters && (
                <VerifyOK id="lengthCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordLength} />
                </VerifyOK>
              )}
              {!this.state.hasAtLeast8Characters && (
                <VerifyNOK id="lengthCheckMark" name="unChecked">
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordLength} />
                </VerifyNOK>
              )}
            </VerificationWrapper>
            <Footer>
              <ButtonWrapper>
                <Button
                  onClick={this.onCheckPasswordAndVerify}
                  disabled={this.disableResetButton()}
                  id="confirm"
                  data-test-id="confirm"
                  ref={this.confirmRef}
                >
                  {this.props.isValidatingReusedPassword && (
                    <AnimationWrapper>
                      <ReactBodymovin options={bodymovinOptions} />
                    </AnimationWrapper>
                  )}
                  {!this.props.isValidatingReusedPassword && (
                    <FormattedMessage {...messages.resetPasswordNextButton} />
                  )}
                </Button>
              </ButtonWrapper>
            </Footer>
          </StepWrapper>
        )}
        {this.showPasswordMatchScreen() && (
          <StepWrapper>
            <BackArrow
              src="/arrow-back.svg"
              onClick={this.onGoBackToChoosePasswordScreen}
            />
            <InputWrapper>
              <Label>
                <FormattedMessage {...messages.verifyNewPassword} />
              </Label>
              <FormattedMessage {...messages.enterNewPasswordPlaceholder}>
                {(placeholder) => (
                  <Password
                    onChange={this.onVerifyPassword}
                    onKeyDown={this.onHandleKeyDownReset}
                    id="verifyPassword"
                    placeholder={placeholder}
                    autoFocus="true"
                    autoComplete="off"
                    type="password"
                  />
                )}
              </FormattedMessage>
            </InputWrapper>
            <VerificationWrapper>
              {this.state.passwordMatches && (
                <VerifyOK id="matchingCheckMark" name="checked">
                  <VerifyIconWrapperOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordMatches} />
                </VerifyOK>
              )}
              {!this.state.passwordMatches && (
                <VerifyNOK id="matchingCheckMark" name="unChecked">
                  <VerifyIconWrapperNOK src="/checkmark.svg" />
                  <FormattedMessage {...messages.passwordMatches} />
                </VerifyNOK>
              )}
            </VerificationWrapper>
            <Footer>
              <ButtonWrapper>
                <Button
                  onClick={this.onSetPassword}
                  disabled={this.disableVerifyButton()}
                  id="reset"
                  ref={this.resetRef}
                >
                  {this.props.isSettingPassword && (
                    <AnimationWrapper>
                      <ReactBodymovin options={bodymovinOptions} />
                    </AnimationWrapper>
                  )}
                  {!this.props.isSettingPassword && (
                    <FormattedMessage {...messages.resetPasswordNextButton} />
                  )}
                </Button>
              </ButtonWrapper>
            </Footer>
          </StepWrapper>
        )}
        {this.passwordHasBeenReset() && (
          <StepWrapper>
            <GifWrapper>
              <Gifje
                id="resetSuccess"
                src={this.props.resetPassword ? '/succes.gif' : 'champagne.gif'}
              />
            </GifWrapper>
            <MessageWrapper>
              <MessageTitle id="successTitle">
                <FormattedMessage
                  {...messages[
                    `resetPasswordSuccessTitle${this.passwordVariablePostFix}`
                  ]}
                />
              </MessageTitle>
              <MessageText id="successMessage">
                <FormattedMessage
                  {...messages[
                    `resetPasswordSuccessText${this.passwordVariablePostFix}`
                  ]}
                />
              </MessageText>
            </MessageWrapper>
            <Footer>
              <CenteredButtonWrapper>
                <Button
                  onClick={this.onShowLogin}
                  id="gotoLoginButton"
                  autoFocus="true"
                >
                  <FormattedMessage
                    {...messages[
                      `resetPasswordSuccessButton${this.passwordVariablePostFix}`
                    ]}
                  />
                </Button>
              </CenteredButtonWrapper>
            </Footer>
          </StepWrapper>
        )}
        {this.passwordResetFail() && (
          <StepWrapper>
            <GifWrapper>
              <Gifje id="resetFailure" src="/oops.gif" />
            </GifWrapper>
            <MessageWrapper>
              <MessageTitle id="resetFailureTitle">
                <FormattedMessage {...messages.resetPasswordErrorTitle} />
              </MessageTitle>
              <MessageText id="resetFailureMessage">
                <FormattedMessage {...messages.resetPasswordErrorText} />
              </MessageText>
            </MessageWrapper>
            <Footer>
              <CenteredButtonWrapper>
                <Button
                  onClick={() => {
                    window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
                  }}
                  id="gotoResetPassword"
                  autoFocus="true"
                >
                  {this.props.isSettingPassword && (
                    <AnimationWrapper>
                      <ReactBodymovin options={bodymovinOptions} />
                    </AnimationWrapper>
                  )}
                  {!this.props.isSettingPassword && (
                    <FormattedMessage {...messages.resetPasswordErrorButton} />
                  )}
                </Button>
              </CenteredButtonWrapper>
            </Footer>
          </StepWrapper>
        )}
      </ResetPasswordWrapper>
    );
  }
}

ResetPassword.propTypes = {
  isSettingPassword: PropTypes.bool.isRequired,
  passwordSetSuccess: PropTypes.bool.isRequired,
  hasPasswordSetError: PropTypes.bool.isRequired,
  passwordSetError: PropTypes.number,
  isValidatingToken: PropTypes.bool.isRequired,
  validateTokenSuccess: PropTypes.bool.isRequired,
  validateTokenError: PropTypes.number.isRequired,
  onSetPassword: PropTypes.func.isRequired,
  onShowLogin: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  onSendNewLink: PropTypes.func.isRequired,
  resetPassword: PropTypes.bool,
  onValidateReusedPassword: PropTypes.func,
  isValidatingReusedPassword: PropTypes.bool,
  validatingReusedPasswordError: PropTypes.bool,
  validatingReusedPasswordSuccess: PropTypes.bool,
  firstName: PropTypes.string,
  isResendingLink: PropTypes.bool,
  hasResendLinkError: PropTypes.bool,
};

export default ResetPassword;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { createUseStyles } from 'react-jss';
import { isMobileOnly } from 'react-device-detect';
import '!file-loader?name=[name].[ext]!../../../images/ideal.svg';
import '!file-loader?name=[name].[ext]!../../../images/gifs/oh-no-cat.gif';
import {
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import ShortDisturbanceMessage from '../../SDM';
import {
  Wrapper,
  CenterWrapper,
  Header,
  Label,
  OnboardingParagraph,
  OnboardingLink,
  OnboardingPrimaryButton,
  OnboardingImage,
  HorizontalRightWrapper,
  ArrowBack,
} from '../styledComponents';
import messages from './messages';
import { Color, FontSize, FontWeight, Radii, Space } from '../../styleguide';
import OnboardingSelectButton from '../Shared/OnboardingSelectButton';
import Loader from '../Loader';
import { paymentStatuses } from '../../../containers/OnboardingKYCContainer/config';
import OnboardingTextBalloon from '../Shared/OnboardingTextBalloon';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import { InfoScreen } from '../InfoScreen';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from '../../../ga4';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from '../../../containers/OnboardingKYCContainer/selectors';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

type PaymentStatusses = 'NOT_PAID' | 'PENDING' | 'PAID';
interface Issuer {
  code: string;
  up: boolean;
  name: string;
}

interface Payment {
  token: string;
  idealPaymentUrl: string;
}

interface PaymentProps {
  issuers: Issuer[];
  getIssuers: () => void;
  business?: boolean;
  initiatePayment: (issuerCode: string) => void;
  pending: boolean;
  payment: Payment;
  checkPayment: (paymentToken: string) => void;
  paymentStatus: PaymentStatusses;
  goToIdeal: (paymentUrl: string) => void;
  organisationName?: string;
  skipPayment?: () => void;
  showOnlyAbnWhenBusinessPayment?: boolean;
  bankCode?: string;
  next: () => void;
}

function Payment({
  issuers,
  getIssuers,
  business,
  initiatePayment,
  pending,
  payment,
  checkPayment,
  paymentStatus,
  goToIdeal,
  organisationName,
  skipPayment,
  showOnlyAbnWhenBusinessPayment,
  bankCode,
  next,
}: PaymentProps): JSX.Element {
  const classes = useStyles();
  const businessStr = business ? 'Business' : '';
  const paymentToken = payment.token;
  const paymentUrl = payment.idealPaymentUrl;
  const [info, setInfo] = useState(false);
  const [issuerDown, setIssuerDown] = useState(false);
  const [issuerDownInfo, setIssuerDownInfo] = useState(false);
  const [showSkipPaymentConfirmation, setShowSkipPaymentConfirmation] =
    useState(false);
  const [isSkippingPayment, setIsSkippingPayment] = useState(false);
  const productType = useSelector(selectProductType);
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const hasAbnBank = useSelector(selectHasAbnBank);
  useOnboardingPageView('payment');

  useEffect(() => {
    if (paymentStatus === paymentStatuses.paid) {
      next();
    }
  }, [next, paymentStatus]);

  const skipPaymentStep = useCallback((): void => {
    if (showOnlyAbnWhenBusinessPayment && !isSkippingPayment) {
      setShowSkipPaymentConfirmation(true);
    } else {
      analyticsEvent(
        'skip_step',
        AnalyticCategories.ONBOARDING,
        'onboarding-event',
        {
          step: 'business_payment',
          flow: onboardingFlow,
          product: analyticsProductTypeMapper(productType),
          bank: bankCode,
          hasAbnBank,
        },
      );
      if (skipPayment) {
        skipPayment();
      }
    }
  }, [isSkippingPayment, showOnlyAbnWhenBusinessPayment, skipPayment]);

  useEffect(() => {
    if (isSkippingPayment) {
      skipPaymentStep();
    }
  }, [isSkippingPayment, skipPaymentStep]);

  if (pending) return <Loader />;

  if (!isEmpty(paymentToken) && isEmpty(paymentStatus)) {
    checkPayment(paymentToken);
  } else if (isEmpty(issuers)) {
    getIssuers();
  }

  const showInfo = (): void => setInfo(!info);

  const showIssuerDownInfo = (): void => setIssuerDownInfo(!issuerDownInfo);

  const onClickPay = (event: ChangeEvent<HTMLSelectElement>): void => {
    const issuerCode = event.target.value;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const issuerCodeInList = issuers.find(
      (issuer) => issuer.code === issuerCode,
    )!;
    if (!issuerCodeInList.up) {
      return setIssuerDown(true);
    }
    return initiatePayment(issuerCode);
  };

  const confirmSkipPayment = (): void => {
    setShowSkipPaymentConfirmation(false);
    setIsSkippingPayment(true);
  };

  const cancelSkipPayment = (): void => {
    setShowSkipPaymentConfirmation(false);
    setIsSkippingPayment(false);
  };

  const showOnlySelectedIssuer = issuers.filter(
    (issuer) => issuer.code === bankCode,
  );

  return (
    <>
      {showSkipPaymentConfirmation && (
        <Wrapper>
          <Header>
            <ArrowBack
              data-test-id="Onboarding-Payment-BackButton"
              onClick={cancelSkipPayment}
            />
          </Header>
          <Label>{messages.confirmSkipPayment.defaultMessage}</Label>
          <OnboardingParagraph>
            {messages.confirmSkipPaymentExplanation.defaultMessage}
          </OnboardingParagraph>
          <HorizontalRightWrapper marginTop="auto">
            <OnboardingPrimaryButton
              onClick={confirmSkipPayment}
              marginRight={Space.s8}
            >
              {messages.yes.defaultMessage}
            </OnboardingPrimaryButton>
            <OnboardingPrimaryButton
              onClick={cancelSkipPayment}
              marginLeft={Space.s8}
            >
              {messages.no.defaultMessage}
            </OnboardingPrimaryButton>
          </HorizontalRightWrapper>
        </Wrapper>
      )}
      {!showSkipPaymentConfirmation &&
        !isSkippingPayment &&
        paymentStatus !== paymentStatuses.pending && (
          <>
            <Wrapper>
              <NavigationHeader clickRight={showInfo} />
              {business && (
                <ShortDisturbanceMessage
                  context="business-onboarding-payment"
                  marginTop={isMobileOnly ? '0px' : '32px'}
                  isInner
                />
              )}
              <Title>
                {business
                  ? `${t(
                      'onboarding.payment.business.title',
                    )} ${organisationName}`
                  : t('onboarding.payment.personal.title')}
              </Title>
              <div className={classes.buttonContainer}>
                <OnboardingSelectButton
                  placeholder={
                    business
                      ? t('onboarding.payment.business.payButton')
                      : t('onboarding.payment.personal.payButton')
                  }
                  options={showOnlySelectedIssuer}
                  data-test-id={`Onboarding-Payment${businessStr}-PayButton`}
                  handleChange={onClickPay}
                />
              </div>
              <ButtonFooter
                customStyle={{
                  textAlign: 'center',
                }}
              >
                <div>
                  <BlokkieTypography
                    style={{
                      color: blokkieTheme.colors.grey.veryDark,
                    }}
                    variant="pl500"
                  >
                    {business
                      ? t('onboarding.payment.business.description')
                      : t('onboarding.payment.personal.description')}
                  </BlokkieTypography>

                  {business && (
                    <div>
                      <BlokkieTextLink
                        color={blokkieTheme.colors.grey.veryDark}
                        underline
                        variant="pl700"
                        onClick={skipPaymentStep}
                      >
                        {t('onboarding.payment.skipPayment')}
                      </BlokkieTextLink>
                    </div>
                  )}
                </div>
              </ButtonFooter>
              <OnboardingTextBalloon
                show={paymentStatus === paymentStatuses.notPaid}
                center
              >
                <OnboardingParagraph
                  className={classes.colored}
                  small
                  marginBottom={Space.s0}
                  paddingLeft={Space.s8}
                >
                  {messages.notPaid1.defaultMessage}
                </OnboardingParagraph>
                <OnboardingParagraph
                  className={classes.colored}
                  small
                  marginBottom="2px"
                  paddingLeft={Space.s8}
                >
                  {messages.notPaid2.defaultMessage}
                  <OnboardingLink onClick={showInfo}>
                    {messages.notPaid3.defaultMessage}
                  </OnboardingLink>
                  {messages.notPaid4.defaultMessage}
                </OnboardingParagraph>
                <OnboardingParagraph
                  className={classes.colored}
                  small
                  marginBottom={Space.s0}
                  paddingLeft={Space.s8}
                >
                  {messages.notPaid5.defaultMessage}
                  <OnboardingLink
                    onClick={() => {
                      window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
                    }}
                  >
                    {messages.notPaid6.defaultMessage}
                  </OnboardingLink>
                  {messages.notPaid7.defaultMessage}
                </OnboardingParagraph>
              </OnboardingTextBalloon>
              <OnboardingTextBalloon
                show={!!issuerDown}
                error
                marginTop={`-${Space.s16}`}
                width={360}
                horizontalBoxCenter
              >
                <OnboardingParagraph
                  small
                  marginBottom={Space.s0}
                  paddingLeft={Space.s8}
                >
                  {messages.issuerDown.defaultMessage}
                  {issuerDown}.<br />
                </OnboardingParagraph>
                <OnboardingLink
                  className={classes.link}
                  marginBottom={Space.s0}
                  marginTop={Space.s8}
                  paddingLeft={Space.s8}
                  onClick={showIssuerDownInfo}
                >
                  <b>{messages.issuerDownInfo.defaultMessage}</b>
                </OnboardingLink>
              </OnboardingTextBalloon>
            </Wrapper>
            <InfoScreen
              show={info}
              title={messages.infoScreenTitle.defaultMessage}
              onClick={showInfo}
              fromPage={Payment.name}
            >
              {!business && (
                <>
                  <OnboardingParagraph>
                    {messages.infoScreenBody1.defaultMessage}
                  </OnboardingParagraph>
                  <OnboardingParagraph>
                    {messages.infoScreenBody2.defaultMessage}
                  </OnboardingParagraph>
                </>
              )}
              {business && (
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.grey.veryDark,
                  }}
                  variant="pl500"
                >
                  {t('onboarding.payment.business.info', {
                    organisationName: organisationName || '-',
                  })}
                </BlokkieTypography>
              )}
            </InfoScreen>
            <InfoScreen
              show={issuerDownInfo}
              title={`${messages.issuerDownInfoTitle.defaultMessage} ${issuerDown}`}
              onClick={showIssuerDownInfo}
              fromPage={`${Payment.name}_issuerDown`}
            >
              <OnboardingParagraph>
                {messages.issuerDownInfoP1.defaultMessage}
                {issuerDown}
                {messages.issuerDownInfoP2.defaultMessage}
              </OnboardingParagraph>
              <OnboardingParagraph>
                {messages.issuerDownInfoP3.defaultMessage}
                <OnboardingLink>
                  <BlokkieTextLink
                    href="https://beschikbaarheid.ideal.nl/"
                    target="_blank"
                    variant="pl700"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    {messages.issuerDownInfoP4Link.defaultMessage!}
                  </BlokkieTextLink>
                </OnboardingLink>{' '}
                <br />
                {messages.issuerDownInfoP5.defaultMessage}
              </OnboardingParagraph>
            </InfoScreen>
          </>
        )}
      {!showSkipPaymentConfirmation &&
        !isSkippingPayment &&
        paymentStatus === paymentStatuses.pending && (
          <CenterWrapper>
            <Header />
            <OnboardingImage src="/oh-no-cat.gif" alt="" />
            <Label>{messages.pendingTitle.defaultMessage}</Label>
            <OnboardingParagraph
              narrow
              textAlign="center"
              marginBottom={Space.s24}
            >
              {messages.pendingParagraph.defaultMessage}
            </OnboardingParagraph>
            <OnboardingPrimaryButton
              onClick={() => goToIdeal(paymentUrl)}
              data-test-id={`Onboarding-Payment${businessStr}-PendingButton`}
            >
              {messages.pendingButton.defaultMessage}
            </OnboardingPrimaryButton>
          </CenterWrapper>
        )}
    </>
  );
}

const useStyles = createUseStyles({
  buttonContainer: {
    backgroundColor: Color.backgroundColor,
    height: '124px',
    minHeight: Space.s72,
    marginTop: blokkieTheme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Radii.s8,
    '& option': {
      color: Color.gray01,
      background: Color.gray05,
      margin: Space.s8,
    },
  },
  colored: {
    color: `${Color.brandColor04} !important`,
  },
  link: {
    color: Color.white,
    borderBottom: '0px',
    fontWeight: FontWeight.s500,
    fontSize: FontSize.s14,
  },
});

export default Payment;

import styled, { css } from 'styled-components';
import { blokkieTheme } from '@tikkie/blokkie';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import '!file-loader?name=[name].[ext]!../../images/arrow-back.svg';
import { Color, FontSize, FontWeight, Space, Radii } from '../styleguide';

/**
 * To whichever component incorporates space the padding and margins can be passed as an attribute. An
 * example of this would be <OnboardingParagraph marginBottom={Space.s8} />.
 */

export const SpaceAttributes = (props) => ({
  margin: props.margin ? `${props.margin}` : '',
  marginBottom: props.marginBottom ? `${props.marginBottom}` : '',
  marginLeft: props.marginLeft ? `${props.marginLeft}` : '',
  marginRight: props.marginRight ? `${props.marginRight}` : '',
  marginTop: props.marginTop ? `${props.marginTop}` : '',
  padding: props.padding ? `${props.padding}` : '',
  paddingBottom: props.paddingBottom ? `${props.paddingBottom}` : '',
  paddingLeft: props.paddingLeft ? `${props.paddingLeft}` : '',
  paddingRight: props.paddingRight ? `${props.paddingRight}` : '',
  paddingTop: props.paddingTop ? `${props.paddingTop}` : '',
  justifySelf: props.justifySelf ? `${props.justifySelf}` : '',
  alignSelf: props.alignSelf ? `${props.alignSelf}` : '',
  textAlign: props.textAlign ? `${props.textAlign}` : '',
});

export const OnboardingButtonCSS = css`
  height: 48px;
  width: auto;
  padding: 0px ${Space.s24};
  text-align: center;
  border-radius: ${Radii.s50};
  border: 0px;
  cursor: pointer;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.white};
  background-color: ${blokkieTheme.colors.primary.green};
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  box-shadow: 2px 2px 10px 0 rgba(79, 195, 184, 0.5);

  :disabled {
    opacity: 0.2;
    border: 2px solid transparent;
    box-shadow: none;
    cursor: default;
  }

  :hover:enabled {
    box-shadow: 2px 2px 10px 0 rgba(79, 195, 184, 0.8);
  }

  img {
    margin-right: ${Space.s8};
    width: 20px;
    height: 20px;
  }
`;

export const OnboardingParagraph = styled.p.attrs((props) => ({
  ...props,
}))`
  font-family: MuseoSansRounded;
  font-size: ${(props) => (props.small ? FontSize.s14 : FontSize.s16)};
  font-weight: ${FontWeight.s500};
  line-height: ${(props) => (props.small ? 'normal' : '1.75')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => (props.gray ? Color.gray03 : Color.gray01)};
  margin-bottom: ${Space.s8};
  margin-top: ${Space.s0};
  max-width: ${(props) => (props.narrow ? '360px' : '')};

  @media ${(props) => props.theme.device.mobile} {
    max-width: initial;
  }

  ${SpaceAttributes};
`;

OnboardingParagraph.propTypes = {
  small: PropTypes.bool,
  gray: PropTypes.bool,
  narrow: PropTypes.bool,
  italic: PropTypes.bool,
};

export const OnboardingParagraphHeader = styled(OnboardingParagraph).attrs({
  as: 'h3',
})`
  font-weight: ${FontWeight.s700};
  margin: 0;
  ${SpaceAttributes};
`;

export const OnboardingParagraphDescription = styled(OnboardingParagraph)`
  font-size: ${FontSize.s14};
  font-weight: ${FontWeight.s700};
  line-height: 1.79;
  color: ${Color.gray03};
`;

export const UboHeader = styled(OnboardingParagraph)`
  font-weight: ${FontWeight.s700};
  color: ${Color.gray02};
  margin-top: ${Space.s32};
  margin-bottom: ${Space.s0};
`;

export const List = styled.ul.attrs((props) => ({
  ...props,
}))`
  font-family: MuseoSansRounded;
  font-size: ${FontSize.s16};
  font-weight: ${FontWeight.s500};
  line-height: 1.75;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${Color.gray01};
  padding: ${Space.s0};
  padding-left: 18px;
`;

export const Wrapper = styled.div.attrs((props) => ({
  ...props,
}))`
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 0;
  ${SpaceAttributes};
`;

export const CenterWrapper = styled(Wrapper).attrs((props) => ({
  ...props,
}))`
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.device.mobile} {
    justify-content: flex-start;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  justify-content: space-between;
  ${SpaceAttributes};
`;

export const HorizontalRightWrapper = styled(Wrapper).attrs((props) => ({
  ...props,
}))`
  height: auto;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-end;
`;

export const Header = styled.div.attrs((props) => ({
  ...props,
}))`
  display: flex;
  position: relative;
  height: 32px;
  width: 100%;
  margin-bottom: 8px;
  ${SpaceAttributes};
`;

export const ArrowBack = styled.div.attrs((props) => ({
  ...props,
}))`
  position: absolute;
  left: 0;
  background: url('/arrow-back.svg') no-repeat;
  background-size: 27px 20px;
  width: 27px;
  height: 20px;
  cursor: pointer;
  align-self: center;
  ${SpaceAttributes};
`;

export const ToolTip = styled.div.attrs((props) => ({
  ...props,
}))`
  position: absolute;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: ${Radii.s16};
  background-color: rgba(77, 182, 172, 0.2);
  font-family: MuseoSansRounded700;
  font-size: ${FontSize.s22};
  color: ${Color.brandColor01};
  cursor: pointer;
  ${SpaceAttributes};
`;

export const Label = styled.h2.attrs((props) => ({
  ...props,
}))`
  font-family: MuseoSansRounded700;
  font-size: ${FontSize.s22};
  margin: ${(props) =>
    props.low
      ? `${Space.s48} ${Space.s0} ${Space.s8}`
      : `${Space.s0} ${Space.s0}`};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${blokkieTheme.colors.grey.veryDark};
  ${SpaceAttributes};
  line-height: 1.5;

  @media ${(props) => props.theme.device.mobile} {
    margin: ${Space.s8} ${Space.s0};
  }
`;

export const Input = styled.input.attrs((props) => ({
  ...props,
}))`
  font-family: MuseoSansRounded700;
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: 50px;
  display: inline-block;
  line-height: 32px;
  padding: ${Space.s0};
  object-fit: contain;
  background-color: ${blokkieTheme.colors.grey.white};
  outline: none;
  border: none;
  font-size: ${FontSize.s22};
  color: ${blokkieTheme.colors.primary.green};
  caret-color: ${blokkieTheme.colors.primary.green};
  ${SpaceAttributes};

  ::placeholder {
    font-family: MuseoSansRounded700;
    font-size: ${FontSize.s22};
    color: ${Color.gray04};
  }

  @media ${(props) => props.theme.device.mobile} {
    min-width: ${Space.s64};
  }
`;

export const Shade = styled.div.attrs((props) => ({
  ...props,
}))`
  position: relative;
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : '32px')};
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : '-24px'};
  margin-bottom: ${Space.s8};
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 87%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const OnboardingImage = styled.img`
  width: 156px;
  height: 156px;
  border-radius: 88px;
  border-style: none;
  background: ${Color.backgroundColor};
  margin-bottom: ${Space.s24};
  border: ${Radii.s8} solid ${Color.gray05};
`;

export const OnboardingPrimaryButton = styled.button`
  align-self: ${(props) => (props.right ? 'flex-end' : '')};
  flex-shrink: ${(props) => (props.flexShrink !== 1 ? props.flexShrink : 1)};
  ${OnboardingButtonCSS};
  ${SpaceAttributes};
`;

export const OnboardingSecondaryButton = styled.button`
  display: inline-block;
  height: 48px;
  padding: 0px 24px;
  width: initial;
  cursor: pointer;
  text-align: center;
  font-family: MuseoSansRounded700;
  font-size: 16px;
  color: ${Color.gray01};
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  background-color: ${Color.white};
  border: 2px solid ${Color.gray05};
  border-radius: ${(props) => (props.rounded ? '50px' : '0px')};

  :disabled {
    opacity: 0.4;
  }

  img {
    margin-right: ${Space.s8};
    height: 20px;
    width: 20px;
  }

  ${SpaceAttributes};
`;

export const OnboardingLink = styled.span`
  user-select: none;
  color: ${blokkieTheme.colors.grey.veryDark};
  cursor: pointer;
  ${SpaceAttributes};
  text-decoration: underline;
  font-weight: 600;
  font-size: ${(props) => (props.small ? '14px' : 'inherit')};
`;

export const SmallLink = styled.span`
  font-family: MuseoSansRounded700;
  font-size: ${FontSize.s14};
  line-height: 1.5;
  text-align: center;
  @media ${(props) => props.theme.device.mobile} {
    line-height: 1;
  }
  color: ${Color.brandColor01};
  cursor: pointer;
  border-bottom: 1px solid ${Color.brandColor01};
  margin-left: 4px;
`;

export const Word = styled.span`
  display: inline-block;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  float: left;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  button {
    height: 24px;
    line-height: 24px;
  }
`;

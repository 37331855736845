import { takeLatest, call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import request from '../../../utils/request';
import { BULK_UPLOAD, DELETE_BULK_UPLOAD, SEND_BULK_UPLOAD } from './constants';
import {
  bulkUploadSuccess,
  bulkUploadError,
  deleteBulkUploadError,
  deleteBulkUploadSuccess,
  sendBulkUploadError,
  sendBulkUploadSuccess,
} from './actions';
import config from '../../../config';

export default function* watcherSaga() {
  yield takeLatest(BULK_UPLOAD, bulkUploadSaga);
  yield takeLatest(DELETE_BULK_UPLOAD, deleteBulkUploadSaga);
  yield takeLatest(SEND_BULK_UPLOAD, sendBulkUploadSaga);
}

export function* bulkUploadSaga(action) {
  const cookies = new Cookies();
  const data = new FormData();
  const requestUrl = `${config.API_URL}/batch/upload`;

  try {
    data.append('file', action.payload.file);
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
      body: data,
    });
    if (response.errorCode) {
      yield put(bulkUploadError(response.status, response.userMessage));
      return;
    }
    yield put(bulkUploadSuccess(response));
  } catch (err) {
    const errResponse = yield err.response.json().catch(() => ({}));
    const message = errResponse.userMessage || 'Oeps, uploaden niet gelukt';
    yield put(
      bulkUploadError(err.response.status, message, errResponse.errorDetails),
    );
  }
}

export function* deleteBulkUploadSaga(action) {
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/batch/${action.payload.id}`;

  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(deleteBulkUploadSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(deleteBulkUploadSuccess());
    } else {
      const message =
        err.response.userMessage || 'Oeps, verwijderen niet gelukt';
      yield put(deleteBulkUploadError(err.response.status, message));
    }
  }
}

export function* sendBulkUploadSaga(action) {
  const cookies = new Cookies();
  const paymentRequestBatchId = action.payload.id;

  const requestUrl = `${config.API_URL}/batch/send?paymentRequestBatchId=${paymentRequestBatchId}`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(sendBulkUploadSuccess(action.payload.id));
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(sendBulkUploadSuccess());
    } else {
      const message = err.response.userMessage || 'Oeps, verzenden niet gelukt';
      yield put(sendBulkUploadError(err.response.status, message));
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  VerificationWrapper,
  VerifyIconWrapperNOK,
  VerifyIconWrapperOK,
  VerifyNOK,
  VerifyOK,
} from './components';

export const PasswordVerification = ({ validations }) => {
  const {
    hasUppercaseLetter,
    hasLowercaseLetter,
    hasNumber,
    hasSpecialCharacterNoSpaces,
    hasAtLeast8Characters,
  } = validations;

  return (
    <VerificationWrapper>
      {hasUppercaseLetter && (
        <VerifyOK id="upperCaseCheckMark" name="checked">
          <VerifyIconWrapperOK src="/checkmark.svg" />1 hoofdletter
        </VerifyOK>
      )}
      {!hasUppercaseLetter && (
        <VerifyNOK id="upperCaseCheckMark" name="unChecked">
          <VerifyIconWrapperNOK src="/checkmark.svg" />1 hoofdletter
        </VerifyNOK>
      )}
      {hasLowercaseLetter && (
        <VerifyOK id="lowerCaseCheckMark" name="checked">
          <VerifyIconWrapperOK src="/checkmark.svg" />1 kleine letter
        </VerifyOK>
      )}
      {!hasLowercaseLetter && (
        <VerifyNOK id="lowerCaseCheckMark" name="unChecked">
          <VerifyIconWrapperNOK src="/checkmark.svg" />1 kleine letter
        </VerifyNOK>
      )}
      {hasSpecialCharacterNoSpaces && (
        <VerifyOK id="specialCharactersNoSpaceCheckMark" name="checked">
          <VerifyIconWrapperOK src="/checkmark.svg" />1 speciaal teken (geen
          spatie)
        </VerifyOK>
      )}
      {!hasSpecialCharacterNoSpaces && (
        <VerifyNOK id="specialCharactersNoSpaceCheckMark" name="unChecked">
          <VerifyIconWrapperNOK src="/checkmark.svg" />1 speciaal teken (geen
          spatie)
        </VerifyNOK>
      )}
      {hasNumber && (
        <VerifyOK id="digitCheckMark" name="checked">
          <VerifyIconWrapperOK src="/checkmark.svg" />1 getal
        </VerifyOK>
      )}
      {!hasNumber && (
        <VerifyNOK id="digitCheckMark" name="unChecked">
          <VerifyIconWrapperNOK src="/checkmark.svg" />1 getal
        </VerifyNOK>
      )}
      {hasAtLeast8Characters && (
        <VerifyOK id="lengthCheckMark" name="checked">
          <VerifyIconWrapperOK src="/checkmark.svg" />8 tekens
        </VerifyOK>
      )}
      {!hasAtLeast8Characters && (
        <VerifyNOK id="lengthCheckMark" name="unChecked">
          <VerifyIconWrapperNOK src="/checkmark.svg" />8 tekens
        </VerifyNOK>
      )}
    </VerificationWrapper>
  );
};

PasswordVerification.propTypes = {
  validations: PropTypes.object.isRequired,
};

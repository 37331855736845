import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/hardworking-cat.mp4';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { t } from 'i18next';
import {
  OnboardingParagraph,
  CenterWrapper,
  Wrapper,
  OnboardingLink,
} from '../styledComponents';
import Loader from '../Loader';
import messages from './messages';
import { InfoScreen } from '../InfoScreen';
import { ns } from '../../../i18n/i18n';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface CheckYourEmailProps {
  nextStep: () => void;
  previousStep: () => void;
  email: string;
  sendEmailResult: {
    success: boolean;
  };
}

function CheckYourEmail(props: CheckYourEmailProps): JSX.Element {
  const { previousStep, email, sendEmailResult } = props;
  const [info, setInfo] = useState(false);
  useOnboardingPageView('check-your-mail');

  const showInfo = (): void => setInfo(!info);
  if (!sendEmailResult.success) {
    return <Loader />;
  }
  return (
    <>
      <Wrapper>
        <NavigationHeader clickLeft={previousStep} noMarginBottom />
        <CenterWrapper {...props}>
          <BlokkieGifContainer
            source="../hardworking-cat.mp4"
            style={{ margin: `${blokkieTheme.spacing()}px 0` }}
          />
          <Title textAlign="center">
            {t(`onboarding.email.checkEmail.title`, { ns: ns.common })}
          </Title>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
            }}
            variant="pl500"
          >
            {t(`onboarding.email.checkEmail.descriptionA`, { ns: ns.common })}
          </BlokkieTypography>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
              marginBottom: blokkieTheme.spacing(3),
            }}
            variant="pl700"
          >
            {email}
          </BlokkieTypography>
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              textAlign: 'center',
            }}
            variant="pl500"
          >
            {t(`onboarding.email.checkEmail.descriptionB`, { ns: ns.common })}
          </BlokkieTypography>
        </CenterWrapper>
      </Wrapper>
      <InfoScreen
        show={info}
        title={messages.infoScreenTitle.defaultMessage}
        onClick={showInfo}
        fromPage={CheckYourEmail.name}
      >
        <OnboardingParagraph>
          {messages.infoScreenBody1.defaultMessage}
          <OnboardingLink onClick={previousStep}>
            {messages.infoScreenBody2.defaultMessage}
          </OnboardingLink>
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

export default injectIntl(CheckYourEmail);

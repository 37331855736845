import '!file-loader?name=[name].[ext]!../../images/gifs/high-5.gif';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { loadUser, updateUser } from '../App/actions';
import {
  selectUser,
  selectUserIsLoading,
  selectUserUpdateIsLoading,
  selectTempUser,
  selectUserUpdateError,
  selectCheckEmail,
} from '../App/selectors';
import { isEmpty } from '../../utils';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../components/components';
import Loader from '../../components/OnboardingKYC/Loader';
import CurrentUser from '../../components/CurrentUser';
import {
  ErrorPage,
  ErrorPageWithContact,
} from '../../components/Settings/styledComponents';

function CurrentUserContainer({
  user,
  tempUser,
  userLoading,
  getUser,
  dispatchUpdateUser,
  updateUserLoading,
  updateUserError,
  checkEmail,
}) {
  const history = useHistory();
  const loading = userLoading || updateUserLoading;
  const showGenericError =
    !loading &&
    updateUserError &&
    updateUserError.errorCode !== 2050 &&
    updateUserError.errorCode !== 2040;
  const showCheckMail = !loading && !showGenericError && checkEmail;
  const showUser = !loading && !showGenericError && !checkEmail;
  useEffect(() => {
    if (isEmpty(user) && !loading) {
      getUser();
    }
  }, [user, loading]);

  const goToPortalOverview = () => history.push('/');

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer highMargin>
          {loading && <Loader />}
          {showUser && (
            <CurrentUser
              user={user}
              tempUser={tempUser}
              updateUser={dispatchUpdateUser}
              updateUserError={updateUserError}
            />
          )}
          {showGenericError && (
            <ErrorPageWithContact
              pageTitle="Jouw profiel"
              onClickBackOverview={goToPortalOverview}
              errorType="Update"
            />
          )}
          {showCheckMail && (
            <ErrorPage
              pageTitle="Jouw profiel"
              image="/high-5.gif"
              errorTitle="Bedankt"
              errorDescription="We hebben je wijzigingen binnen, check je mail"
              mailButton={false}
              onClickSecondaryButton={goToPortalOverview}
              onClickBackButton={goToPortalOverview}
            />
          )}
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

CurrentUserContainer.propTypes = {
  user: PropTypes.object,
  tempUser: PropTypes.object,
  userLoading: PropTypes.bool,
  getUser: PropTypes.func,
  dispatchUpdateUser: PropTypes.func,
  updateUserLoading: PropTypes.bool,
  updateUserError: PropTypes.object,
  checkEmail: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  userLoading: selectUserIsLoading,
  updateUserLoading: selectUserUpdateIsLoading,
  tempUser: selectTempUser,
  updateUserError: selectUserUpdateError,
  checkEmail: selectCheckEmail,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(loadUser()),
  dispatchUpdateUser: (user, doEmailUpdate) =>
    dispatch(updateUser(user, doEmailUpdate)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CurrentUserContainer);

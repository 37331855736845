import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import reducer from './reducer';
import paymentRequestReducer from '../reducer';
import saga from './saga';
import injectSaga from '../../../utils/injectSaga';
import injectReducer from '../../../utils/injectReducer';
import {
  InnerContainer,
  DashboardWrapper,
  Container,
} from '../../../components/components';
import {
  selectPayments,
  selectPaymentsErrorMessage,
  selectPaymentsIsLoading,
  selectarchivingPaymentRequest,
} from './selectors';
import {
  selectPaymentRequest,
  selectPaymentRequestErrorMessage,
  selectPaymentRequestIsLoading,
} from '../selectors';
import { selectUser, selectPaymentRequestDraft } from '../../App/selectors';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import PaymentRequestDetail from '../../../components/PaymentRequest/PaymentRequestDetail';
import {
  downloadSinglePaymentRequest,
  loadPayments,
  clearData,
  archivePaymentRequest,
  editPaymentRequest,
} from './actions';
import {
  saveTikkie,
  loadPaymentRequest,
  clearData as clearPaymentRequest,
} from '../actions';

function PaymentRequestDetailContainer(props) {
  const {
    user,
    paymentRequest,
    paymentRequestDraft,
    paymentRequestIsLoading,
    paymentRequestErrorMessage,
    payments,
    paymentsIsLoading,
    paymentsErrorMessage,
  } = props;

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(
    () => () => {
      props.clearData();
      props.clearPaymentRequest();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showDashboard = () => {
    history.push('/');
  };

  const showShareTikkie = (
    description,
    invoiceNumber,
    amount,
    isOpenTikkie,
    link,
    token,
    notifications,
    hasBackOption,
    pr,
  ) => {
    props.saveTikkie({
      description,
      invoiceNumber,
      amount,
      isOpenTikkie,
      link,
      token,
      notifications,
      hasBackOption,
      pr,
    });
    history.push(`/paymentrequest/${token}/share`);
  };

  const showTransactionDetail = (viewTransaction) => {
    history.push(`/transaction/${viewTransaction.token}`);
  };

  if (isEmpty(user)) {
    return <EmptyLoader />;
  }
  const { paymentRequestToken } = match.params;

  const isPreview = paymentRequestToken == null;

  // If there is no payment request token, then it is a draft tikkie coming from BulkDetailComponent
  const localPaymentRequest = isPreview
    ? paymentRequestDraft
    : { token: paymentRequestToken, ...paymentRequest };

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <PaymentRequestDetail
            paymentRequest={localPaymentRequest}
            paymentRequestIsLoading={paymentRequestIsLoading}
            paymentRequestErrorMessage={paymentRequestErrorMessage}
            loadPaymentRequest={props.loadPaymentRequest}
            payments={payments}
            paymentsIsLoading={paymentsIsLoading}
            paymentsErrorMessage={paymentsErrorMessage}
            loadPayments={props.loadPayments}
            editPaymentRequest={props.editPaymentRequest}
            showDashboard={showDashboard}
            showShareTikkie={showShareTikkie}
            showTransactionDetail={showTransactionDetail}
            downloadSinglePaymentRequest={props.downloadSinglePaymentRequest}
            downloadOption={user.downloadOption}
            showPayerDetails={user.showPayerDetailsPortal}
            archivePaymentRequest={props.archivePaymentRequest}
            archivingPaymentRequest={props.archivingPaymentRequest}
            isPreview={isPreview}
            match={match}
            history={history}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

PaymentRequestDetailContainer.propTypes = {
  user: PropTypes.object,
  paymentRequest: PropTypes.object,
  paymentRequestDraft: PropTypes.object,
  paymentRequestIsLoading: PropTypes.bool,
  paymentRequestErrorMessage: PropTypes.string,
  loadPaymentRequest: PropTypes.func,
  payments: PropTypes.object,
  paymentsIsLoading: PropTypes.bool,
  paymentsErrorMessage: PropTypes.string,
  loadPayments: PropTypes.func,
  downloadSinglePaymentRequest: PropTypes.func,
  saveTikkie: PropTypes.func,
  clearData: PropTypes.func,
  clearPaymentRequest: PropTypes.func,
  archivePaymentRequest: PropTypes.func,
  archivingPaymentRequest: PropTypes.bool,
  editPaymentRequest: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  paymentRequest: selectPaymentRequest,
  paymentRequestDraft: selectPaymentRequestDraft,
  paymentRequestIsLoading: selectPaymentRequestIsLoading,
  paymentRequestErrorMessage: selectPaymentRequestErrorMessage,
  payments: selectPayments,
  paymentsIsLoading: selectPaymentsIsLoading,
  paymentsErrorMessage: selectPaymentsErrorMessage,
  archivingPaymentRequest: selectarchivingPaymentRequest,
});

const mapDispatchToProps = (dispatch) => ({
  loadPaymentRequest: (paymentRequestToken) =>
    dispatch(loadPaymentRequest(paymentRequestToken)),
  downloadSinglePaymentRequest: (token) =>
    dispatch(downloadSinglePaymentRequest(token)),
  loadPayments: (currentPage, paymentRequestToken) =>
    dispatch(loadPayments(currentPage, paymentRequestToken)),
  saveTikkie: (tikkie) => dispatch(saveTikkie(tikkie)),
  clearData: () => dispatch(clearData()),
  clearPaymentRequest: () => dispatch(clearPaymentRequest()),
  archivePaymentRequest: (token) => dispatch(archivePaymentRequest(token)),
  editPaymentRequest: (token, expireDate) =>
    dispatch(editPaymentRequest(token, expireDate)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer(
  {
    key: 'paymentRequest/detailContainer',
    reducer,
  },
  {
    key: 'paymentRequest',
    paymentRequestReducer,
  },
);
const withSaga = injectSaga({ key: 'paymentRequest/detailContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PaymentRequestDetailContainer);

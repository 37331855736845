import React from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieIcon, BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';

const priorityOptionKey = (key: string): string => `top-${key}`;

interface DropdownOption {
  value: string;
  label: string;
}

interface OnboardingDropdownProps {
  priorityOptions?: Array<DropdownOption>;
  options: Array<DropdownOption>;
  placeholder: string;
  handleChange: React.ChangeEventHandler<HTMLSelectElement>;
  value?: string;
  dataTestId?: string;
}

function OnboardingDropdown({
  priorityOptions,
  options,
  placeholder,
  handleChange,
  value,
  dataTestId,
}: OnboardingDropdownProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.selectBox}>
        <BlokkieTypography
          variant="pl700"
          style={{
            color: value
              ? blokkieTheme.colors.primary.green
              : blokkieTheme.colors.grey.default,
          }}
        >
          {value || placeholder}
        </BlokkieTypography>
        <BlokkieIcon
          variant="chevronDown"
          color={blokkieTheme.colors.primary.green}
        />
      </div>
      <select
        onChange={handleChange}
        value={value}
        data-test-id={dataTestId}
        className={classes.select}
      >
        <option hidden>{placeholder}</option>
        {priorityOptions &&
          priorityOptions.map((priorityOption) => (
            <>
              <option
                key={priorityOptionKey(priorityOption.value)}
                value={priorityOption.value}
              >
                {priorityOption.label}
              </option>
              <option disabled>
                ----------------------------------------------------------------
              </option>
            </>
          ))}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    flex: 1,
    alignItems: 'center',
  },
  selectBox: {
    width: 343,
    height: 48,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 6,
    border: `2px solid ${blokkieTheme.colors.grey.light}`,
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'MuseoSansRounded',
    paddingLeft: blokkieTheme.spacing(2),
    paddingRight: blokkieTheme.spacing(2),
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
    },
  },
  select: {
    opacity: 0,
    width: 343,
    height: 48,
    position: 'absolute',
    '-webkit-appearance': 'menulist-button',
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    width: '100%',
  },
});

export default OnboardingDropdown;

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  try {
    const res = response.json();
    return res;
  } catch (e) {
    return response;
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response, redirectToOnboarding) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (
    response.status &&
    response.url &&
    response.status === 401 &&
    !response.url.includes('/me') &&
    !response.url.includes('/login')
  ) {
    if (redirectToOnboarding) {
      window.location.replace('/onboarding-kyc');
    } else {
      window.location.replace('/login');
    }
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, redirectOnLoginExpiry = false) {
  return fetch(url, options)
    .then((res) => checkStatus(res, redirectOnLoginExpiry))
    .then(parseJSON);
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  legalEntityP1: {
    id: 'app.components.ErrorScreen.legalEntityP1',
    defaultMessage: 'Jouw organisatie is een ',
  },
  legalEntityPBold: {
    id: 'app.components.ErrorScreen.legalEntityPBold',
    defaultMessage: 'Commanditaire vennootschap',
  },
  legalEntityP2: {
    id: 'app.components.ErrorScreen.legalEntityP2',
    defaultMessage:
      '. Maar geen zorgen! Als klant van ABN AMRO kun je Tikkie Zakelijk wel gebruiken.',
  },
  becomeCustomerButtonText: {
    id: 'app.components.ErrorScreen.becomeCustomerButtonText',
    defaultMessage: 'Wordt klant bij ABN AMRO',
  },
  askSupportButtonText: {
    id: 'app.components.ErrorScreen.askSupportButtonText',
    defaultMessage: 'Neem contact met ons op',
  },
  generalErrorHeader: {
    id: 'app.components.ErrorScreen.generalErrorHeader',
    defaultMessage: 'Helaas... 🥺',
  },
  generalError: {
    id: 'app.components.ErrorScreen.generalError',
    defaultMessage:
      'Er lijkt iets niet helemaal goed te gaan. Hulp nodig? Stuur ons gerust een berichtje.',
  },
  loginErrorHeader: {
    id: 'app.components.ErrorScreen.generalErrorHeader',
    defaultMessage: 'Kennen wij elkaar niet?',
  },
  loginButtonText: {
    id: 'app.components.ErrorScreen.askSupportButtonText',
    defaultMessage: 'Inloggen',
  },
  rejectErrorPart1: {
    id: 'app.components.ErrorScreen.rejectErrorPart1',
    defaultMessage:
      'niet voldoet aan de strenge veiligheidscriteria van ABN AMRO.',
  },
  rejectErrorPart2: {
    id: 'app.components.ErrorScreen.rejectErrorPart2',
    defaultMessage:
      'Wil je hier meer over weten of denk je dat er sprake is van een misverstand?',
  },
  validationErrorHeader: {
    id: 'app.components.ErrorScreen.validationErrorHeader',
    defaultMessage: 'Sorry... 🥺',
  },
  validationErrorIntro: {
    id: 'app.components.ErrorScreen.validationErrorIntro',
    defaultMessage: 'We kunnen je aanvraag niet verwerken, omdat ',
  },
  validationErrorGlue: {
    id: 'app.components.ErrorScreen.validationErrorGlue',
    defaultMessage: ' en ',
  },
  validationError2: {
    id: 'app.components.ErrorScreen.validationError2',
    defaultMessage: 'USER_ALREADY_CONFIRMED_CHECK',
  },
  validationError3: {
    id: 'app.components.ErrorScreen.validationError5',
    defaultMessage: 'REVIEW_KEYWORD_IN_NAME_CHECK',
  },
  validationError4: {
    id: 'app.components.ErrorScreen.validationError6',
    defaultMessage: 'COMPLETE_KVK_EXTRACT_CHECK',
  },
  validationError5: {
    id: 'app.components.ErrorScreen.validationError7',
    defaultMessage: 'ACTIVE_ORGANISATION_CHECK',
  },
  validationError6: {
    id: 'app.components.ErrorScreen.validationError8',
    defaultMessage: 'meer dan 99 handelsnamen heeft',
  },
  validationError7: {
    id: 'app.components.ErrorScreen.validationError9',
    defaultMessage:
      'geen natuurlijk persoon als uiteindelijke belanghebbende heeft',
  },
  validationError8PreFix: {
    id: 'app.components.ErrorScreen.validationError10PreFix',
    defaultMessage: 'een ',
  },
  validationError8: {
    id: 'app.components.ErrorScreen.validationError10',
    defaultMessage: " is en we alleen bv's en eenmanszaken accepteren",
  },
  validationError9: {
    id: 'app.components.ErrorScreen.validationError11',
    defaultMessage: 'niet in Nederland is gevestigd',
  },
  validationError10: {
    id: 'app.components.ErrorScreen.validationError12',
    defaultMessage: 'SBI_REVIEW_CODES_CHECK',
  },
  validationError11: {
    id: 'app.components.ErrorScreen.validationError13',
    defaultMessage: 'een eigenaar heeft die niet in Nederland is gevestigd',
  },
  validationError12: {
    id: 'app.components.ErrorScreen.validationError15',
    defaultMessage: 'IS_ADULT_CHECK',
  },
  validationError13: {
    id: 'app.components.ErrorScreen.validationError16',
    defaultMessage: 'IS_VALID_SECTOR_CHECK',
  },
  validationError19: {
    id: 'app.components.ErrorScreen.validationError26',
    defaultMessage: 'KVK_ALREADY_CUSTOMER',
  },
  validationErrorTipPreFix: {
    id: 'app.components.ErrorScreen.validationErrorTip',
    defaultMessage: 'Tip: ',
  },
  validationErrorTip: {
    id: 'app.components.ErrorScreen.validationErrorTip',
    defaultMessage:
      'je kunt alsnog zakelijk gebruik maken van Tikkie wanneer je een zakelijke rekening opent bij ABN AMRO.',
  },
  error2071: {
    id: 'app.components.ErrorScreen.error2071',
    defaultMessage: 'VALIDATION_ERROR',
  },
  error2072: {
    id: 'app.components.ErrorScreen.error2072',
    defaultMessage: 'GENERAL_ERROR',
  },
  error2078: {
    id: 'app.components.ErrorScreen.error2078',
    defaultMessage: 'INVALID_EXPIRED_CODE',
  },
  error2079: {
    id: 'app.components.ErrorScreen.error2079',
    defaultMessage: 'Je hebt te vaak een nieuwe verificatiecode opgevraagd.',
  },
  error2080: {
    id: 'app.components.ErrorScreen.error2080',
    defaultMessage: 'NO_VERIFICATION_CODE',
  },
  error2081: {
    id: 'app.components.ErrorScreen.error2081',
    defaultMessage: 'ALREADY_VERIFIED',
  },
  error2082: {
    id: 'app.components.ErrorScreen.error2082',
    defaultMessage: 'SMS_SENT_TOO_FREQUENTLY',
  },
  error2083: {
    id: 'app.components.ErrorScreen.error2083',
    defaultMessage:
      'Volgens onze gegevens heeft jouw bedrijf al een aanmelding lopen. Log in, en ga verder!',
  },
  error2086: {
    id: 'app.components.ErrorScreen.error2086',
    defaultMessage: 'ORGANISATION_ALREADY_EXISTS_DIFFERENT_USER',
  },
  error2087: {
    id: 'app.components.ErrorScreen.error2087',
    defaultMessage: 'ONBOARDING_CHECKS_FAILED',
  },
  error2097: {
    id: 'app.components.ErrorScreen.error2097',
    defaultMessage: 'EMAIL_NOT_VERIFIED',
  },
  error2098: {
    id: 'app.components.ErrorScreen.error2098',
    defaultMessage: 'KVK profiel niet gevonden',
  },
  error2099: {
    id: 'app.components.ErrorScreen.error2099',
    defaultMessage: 'TERMS_NOT_FOUND',
  },
  error2100: {
    id: 'app.components.ErrorScreen.error2100',
    defaultMessage:
      'Er is iets mis gegaan met het ophalen van het KVK uittreksel.',
  },
  error2101: {
    id: 'app.components.ErrorScreen.error2101',
    defaultMessage: 'IMAGE_NOT_SAVED',
  },
  error2102: {
    id: 'app.components.ErrorScreen.error2102',
    defaultMessage: 'INCORRECT_NR_OF_FILES',
  },
  error2103: {
    id: 'app.components.ErrorScreen.error2103',
    defaultMessage: 'INVALID_FILE_TYPE',
  },
  error2104: {
    id: 'app.components.ErrorScreen.error2104',
    defaultMessage: 'FILE_TOO_LARGE',
  },
  error9998: {
    id: 'app.components.ErrorScreen.error9998',
    defaultMessage: 'INVALID_COUNTRY',
  },
});

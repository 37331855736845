import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import OKIcon from '!file-loader?name=[name].[ext]!../../../images/checkmark2-o.svg';
import styled from 'styled-components';
import { BlokkieTextLink } from '@tikkie/blokkie';
import {
  PageTitle,
  Container,
  Paragraph,
  Button,
  InputTile,
  HR,
  Toggle,
  CaptionGreen,
  Popup,
} from 'amber';
import messages from './messages';
import smsScreenshot1 from '../../../images/smsscreenshot1.png';
import smsScreenshot2 from '../../../images/smsscreenshot2.png';
import {
  ContainerBox,
  ContainerRow,
  ToggleBox,
  ImageWrapper,
  Image,
  ExplanationText,
  Header2Card,
  ImageWrapperToggle,
  ParagraphToggle,
  PageDescription,
  CaptionSmallPading,
  Header2Manual,
  ContainerRowExplanation,
  ImageWrapperExplanation,
  ImageExplanation,
  FooterContent,
  FooterWrapper,
  ParagraphSmallWidth,
  ErrorPageNoContact,
  ErrorPageWithContact,
  BackButton,
} from '../styledComponents';
import { analyticsPageview } from '../../../ga4';

const CheckMarkImage = styled.img`
  width: 18px;
  margin-right: 12px;
`;

const ParagraphInline = styled(Paragraph)`
  display: inline;
`;

const PopupWrapper = styled.div`
  margin-top: 28px;
  transition: opacity 0.5s ease-in;
  opacity: ${(props) => (props.animate ? 1 : 0)};
`;

class SmsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultText: props.settingsData.sms?.defaultText,
      smsOption: props.settingsData.sms?.enabled,
      smsOptionChanged: false,
      stateChanged: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.toggleSmsOption = this.toggleSmsOption.bind(this);
  }

  componentDidMount() {
    analyticsPageview();
  }

  componentDidUpdate(oldProps) {
    const { isUpdating, settingsLoadingError, settingsUpdatingError } =
      this.props;

    const updatedSettings =
      !isUpdating &&
      oldProps.isUpdating &&
      !settingsUpdatingError &&
      !settingsLoadingError;

    if (updatedSettings) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        defaultText: this.props.settingsData.sms.defaultText,
        smsOption: this.props.settingsData.sms.enabled,
        stateChanged: false,
      });
    }
  }

  toggleSmsOption = () => {
    this.setState((prevState) => {
      const sms = {
        enabled: !prevState.smsOption,
        defaultText: prevState.defaultText,
      };

      this.props.updateSettings({ ...this.props.settingsData, sms });

      return {
        smsOption: sms.enabled,
        smsOptionChanged: true,
      };
    });
  };

  handleFieldChange = (fieldId, value) => {
    this.setState({ [fieldId]: value });
    this.setState({ stateChanged: true });
  };

  cancel = () => {
    this.setState({
      defaultText: this.props.settingsData.sms.defaultText,
      smsOption: this.props.settingsData.sms.enabled,
      stateChanged: false,
    });
  };

  saveDefaultSmsText = () => {
    const sms = {
      enabled: this.state.smsOption,
      defaultText: this.state.defaultText,
    };
    this.props.updateSettings({ ...this.props.settingsData, sms });
    this.setState({ stateChanged: false, smsOptionChanged: false });
  };

  render() {
    const {
      gotoPortalOverview,
      gotoSettingsDashboard,
      settingsUpdatingSuccess,
      settingsUpdatingError,
      settingsLoadingError,
      settingsLoadingErrorStatusCode,
    } = this.props;
    const { defaultText, smsOption, smsOptionChanged, stateChanged } =
      this.state;
    const showTogglePopup =
      smsOption && smsOptionChanged && settingsUpdatingSuccess;

    if (settingsLoadingError) {
      if (settingsLoadingErrorStatusCode === 403) {
        return ErrorPageNoContact({
          pageTitle: messages.header.defaultMessage,
          showUnauthorizedDescription: true,
          onClickBackOverview: gotoPortalOverview,
        });
      }
      return ErrorPageWithContact({
        pageTitle: messages.header.defaultMessage,
        onClickBackOverview: gotoPortalOverview,
      });
    }

    if (settingsUpdatingError) {
      return ErrorPageNoContact({
        pageTitle: messages.header.defaultMessage,
        onClickBackOverview: gotoSettingsDashboard,
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={messages.terug.defaultMessage}
        />
        <PageTitle>
          <FormattedMessage {...messages.header} />
        </PageTitle>

        <ContainerBox>
          <ContainerRow>
            <ImageWrapper>
              <Image>
                <img src="/sms.svg" alt="" />
              </Image>
            </ImageWrapper>
            <ExplanationText center>
              <Header2Card>
                <FormattedMessage {...messages.smsSettings} />
              </Header2Card>
              <CaptionGreen>
                <FormattedMessage {...messages.mostUsed} />
                aan
              </CaptionGreen>
            </ExplanationText>
            <ToggleBox>
              <ImageWrapperToggle>
                <ParagraphToggle>
                  <FormattedMessage {...messages.status} />
                </ParagraphToggle>
                <Toggle
                  on={smsOption}
                  dataTestId="Sms-ModuleToggle"
                  onClick={this.toggleSmsOption}
                />
              </ImageWrapperToggle>
              <PopupWrapper animate={showTogglePopup}>
                <Popup show small>
                  <CheckMarkImage src={OKIcon} alt="" />
                  <ParagraphInline>
                    <FormattedMessage {...messages.popupText} />
                  </ParagraphInline>
                </Popup>
              </PopupWrapper>
            </ToggleBox>
          </ContainerRow>
          <ContainerRow>
            <HR />
          </ContainerRow>
          <ContainerRow>
            <PageDescription>
              <CaptionSmallPading>
                <FormattedMessage {...messages.descriptionTitle} />
              </CaptionSmallPading>
              <ParagraphSmallWidth>
                <FormattedMessage {...messages.descriptionText} />
                <BlokkieTextLink
                  href="https://www.tikkie.me/zakelijk#kosten"
                  target="_blank"
                  variant="pl500"
                  underline
                >
                  {/* TODO: add different link or add sms costs to page */}
                  <FormattedMessage {...messages.smsCost} />
                </BlokkieTextLink>
              </ParagraphSmallWidth>
            </PageDescription>
          </ContainerRow>
        </ContainerBox>

        <Container row>
          <InputTile
            title="Sms-tekst"
            desc="Maximaal 200 tekens (incl. spaties)"
            value={defaultText}
            infoDesc="Bepaal zelf welke tekst er in de sms komt. De Tikkie-link en verloopdatum komen er automatisch achter."
            id="defaultText"
            maxlength="200"
            onChange={this.handleFieldChange}
          />
        </Container>

        <Header2Manual>
          <FormattedMessage {...messages.manual} />
        </Header2Manual>

        <ContainerBox>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle1} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText1a} />
                <BlokkieTextLink
                  href="./bulkupload"
                  target="_self"
                  variant="pl500"
                  underline
                >
                  <FormattedMessage {...messages.manualLink1} />
                </BlokkieTextLink>
                <FormattedMessage {...messages.manualText1b} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src={smsScreenshot1} alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src={smsScreenshot2} alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle2} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText2} />
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
        </ContainerBox>

        <FooterWrapper slideIn={stateChanged}>
          {stateChanged && (
            <FooterContent>
              <Button type="secondary" onClick={this.cancel}>
                <FormattedMessage {...messages.cancelButton} />
              </Button>
              <Button
                type="primary"
                dataTestId="Sms-SaveButton"
                onClick={this.saveDefaultSmsText}
              >
                <FormattedMessage {...messages.saveButton} />
              </Button>
            </FooterContent>
          )}
        </FooterWrapper>
      </Container>
    );
  }
}

SmsSettings.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  settingsUpdatingSuccess: PropTypes.bool.isRequired,
  settingsUpdatingError: PropTypes.bool.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
};

export default injectIntl(SmsSettings);

import { takeLatest, call, put, all } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import request from '../../../utils/request';
import { FETCH_QR_CODE, SEND_SMS } from './constants';
import config from '../../../config';
import {
  sendSmsSuccess,
  sendSmsError,
  fetchQRCodeSuccess,
  fetchQRCodeError,
} from './actions';
import paymentRequestSagas from '../saga';

export default function* watcherSaga() {
  yield all([
    ...paymentRequestSagas,
    takeLatest(SEND_SMS, sendSmsSaga),
    takeLatest(FETCH_QR_CODE, fetchQRCodeSaga),
  ]);
}

export function* sendSmsSaga(action) {
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/sms/send?token=${action.payload.token}`;
  const body = {
    mobilePhone: action.payload.phoneNumber,
  };

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    yield put(sendSmsSuccess());
  } catch (err) {
    // This condition is needed because this POST request does not return a JSON response:
    if (!err.response) {
      yield put(sendSmsSuccess());
    } else {
      yield put(sendSmsError(err.message));
    }
  }
}

export function* fetchQRCodeSaga(action) {
  const requestUrl = `${config.QR_SERVICE_URL}/qrcode`;
  const body = {
    url: action.payload.link,
    size: 10,
    style: 'round',
  };
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    yield put(fetchQRCodeSuccess(response.qrCodeImage));
  } catch (err) {
    yield put(fetchQRCodeError(err.message));
  }
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import injectSaga from '../../../../utils/injectSaga';
import saga from '../../saga';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../../components/components';
import UserDetail from '../../../../components/Settings/UserManagement/UserDetail';
import {
  selectFetchUserError,
  selectFetchUserLoading,
  selectUpdateUserError,
  selectRequestPasswordResetMailSuccess,
  selectRequestPasswordResetMailError,
  selectUser,
  selectDeleteUserError,
  selectDeleteUserLoading,
  selectUpdateUserLoading,
} from '../../selectors';
import Loader from '../../../../components/OnboardingKYC/Loader';
import {
  deleteUser,
  fetchUser,
  requestPasswordResetMail,
  resetDeleteUser,
  resetUpdateUser,
  updateUser,
} from '../../actions';
import { selectOrganisationHasOtpEnabled } from '../../../App/selectors';

function UserDetailContainer({
  user,
  userError,
  userLoading,
  userUpdate,
  userUpdateLoading,
  userUpdateError,
  dispatchResetUpdateUser,
  resetPassword,
  passwordResetSuccess,
  passwordResetError,
  loadUser,
  dispatchDeleteUser,
  deleteUserLoading,
  deleteUserError,
  dispatchResetDeleteUser,
  organisationHasOtpEnabled,
}) {
  const match = useRouteMatch();
  const { id } = match.params;

  useEffect(() => {
    loadUser(id);
  }, [loadUser, id]);

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer highMargin>
          {!!userLoading || !!deleteUserLoading || !!userUpdateLoading ? (
            <Loader />
          ) : (
            <UserDetail
              user={user}
              userId={id}
              userError={userError}
              updateUser={userUpdate}
              userUpdateError={userUpdateError}
              resetUpdateUser={dispatchResetUpdateUser}
              resetPassword={resetPassword}
              passwordResetSuccess={passwordResetSuccess}
              passwordResetError={passwordResetError}
              deleteUser={dispatchDeleteUser}
              deleteUserError={deleteUserError}
              resetDeleteUser={dispatchResetDeleteUser}
              otpEnabled={organisationHasOtpEnabled}
            />
          )}
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

UserDetailContainer.propTypes = {
  user: PropTypes.object,
  userError: PropTypes.object,
  userLoading: PropTypes.bool,
  loadUser: PropTypes.func,
  userUpdate: PropTypes.func,
  userUpdateLoading: PropTypes.bool.isRequired,
  userUpdateError: PropTypes.object,
  dispatchResetUpdateUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func,
  passwordResetError: PropTypes.object,
  passwordResetSuccess: PropTypes.object,
  dispatchDeleteUser: PropTypes.func.isRequired,
  deleteUserLoading: PropTypes.bool.isRequired,
  deleteUserError: PropTypes.object.isRequired,
  dispatchResetDeleteUser: PropTypes.func.isRequired,
  organisationHasOtpEnabled: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  userLoading: selectFetchUserLoading,
  userError: selectFetchUserError,
  user: selectUser,
  userUpdateLoading: selectUpdateUserLoading,
  userUpdateError: selectUpdateUserError,
  passwordResetSuccess: selectRequestPasswordResetMailSuccess,
  passwordResetError: selectRequestPasswordResetMailError,
  deleteUserError: selectDeleteUserError,
  deleteUserLoading: selectDeleteUserLoading,
  organisationHasOtpEnabled: selectOrganisationHasOtpEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => dispatch(fetchUser(id)),
  userUpdate: (id, user) => dispatch(updateUser(id, user)),
  dispatchResetUpdateUser: () => dispatch(resetUpdateUser()),
  resetPassword: (email) => dispatch(requestPasswordResetMail(email)),
  dispatchDeleteUser: (id, allOrganisations) =>
    dispatch(deleteUser(id, allOrganisations)),
  dispatchResetDeleteUser: () => dispatch(resetDeleteUser()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'UserDetailContainer', saga });

export default compose(withSaga, withConnect)(UserDetailContainer);

export const getInitialValues = (data) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  phoneNumber: data.phoneNumber,
  functionalRole: data.functionalRole,
  linkedOrganisations: data.linkedOrganisations,
});

export const valuesUpdated = (values, originalValues) =>
  values.firstName !== originalValues.firstName ||
  values.lastName !== originalValues.lastName ||
  values.phoneNumber !== originalValues.phoneNumber ||
  values.functionalRole !== originalValues.functionalRole;

export const valuesToSave = (values, data) => ({
  ...data,
  firstName: values.firstName,
  lastName: values.lastName,
  phoneNumber: values.phoneNumber,
  functionalRole: values.functionalRole,
});

export const getInitialErrors = () => ({
  firstName: false,
  lastName: false,
  phoneNumber: false,
  email: false,
});

// eslint-disable-next-line no-undef
export const hasNoErrors = (errors) => !Object.values(errors).some((e) => e);

export const hasNoNulls = (values) => Object.values(values).some((v) => !!v);

import React from 'react';

import { CashbackCampaignsList } from '../../../components/CashbackCampaigns/CashbackCampaignsList';

import CashbackCampaignsLayout from '../../../components/CashbackCampaigns/CashbackCampaignsLayout';

export default function CashbackCampaignsContainer() {
  return (
    <CashbackCampaignsLayout title="deals.campaigns">
      <CashbackCampaignsList />
    </CashbackCampaignsLayout>
  );
}

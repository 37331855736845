/**
 *
 * LinkSent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';

import { FormattedMessage } from 'react-intl';

import { Header2, PrimaryButton, Paragraph } from '../../styleguide';

import { LightBox, LightBoxBackArrow } from '../../components';

import messages from './messages';

const LinkSentWrapper = styled(LightBox)``;

const BackArrow = styled(LightBoxBackArrow)``;

const StepWrapper = styled.div`
  border: none;
`;

const Header = styled(Header2)`
  padding-bottom: 16px;
`;

const Info = styled(Paragraph)`
  margin-bottom: 26px;
`;

const InfoLarge = styled(Info)`
  min-height: 150px;
`;

const Footer = styled.div`
  position: absolute;
  height: 50px;
  line-height: 50px;
  bottom: 40px;
  left: 40px;
  right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    line-height: auto;
  }
`;

const ButtonWrapper = styled.div`
  float: right;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 200px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 231px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class LinkSent extends React.PureComponent {
  passwordVariablePostFix = this.props.resetPassword ? '' : 'FirstPw';

  render() {
    const { resetPassword, onShowLogin } = this.props;

    return (
      <LinkSentWrapper>
        <StepWrapper>
          {resetPassword && (
            <BackArrow src="/arrow-back.svg" onClick={onShowLogin} />
          )}
          <Header id="checkMailHeader">
            <FormattedMessage {...messages.checkMail} />
          </Header>
          <InfoLarge id="checkMailInfo">
            <FormattedMessage
              {...messages[`checkMailInfo${this.passwordVariablePostFix}`]}
            />
          </InfoLarge>
          <Footer>
            <ButtonWrapper>
              <Button onClick={onShowLogin} id="resetComplete" autoFocus="true">
                <FormattedMessage {...messages.checkMailButton} />
              </Button>
            </ButtonWrapper>
          </Footer>
        </StepWrapper>
      </LinkSentWrapper>
    );
  }
}

LinkSent.propTypes = {
  resetPassword: PropTypes.bool,
  onShowLogin: PropTypes.func.isRequired,
};

export default LinkSent;

import React, { useState, useEffect, KeyboardEvent } from 'react';
import { injectIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { Wrapper, Box } from '../styledComponents';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import { InfoScreen } from '../InfoScreen';
import {
  capitalizeDuringTyping,
  capitalizeAndRemoveSpaces,
} from '../../../utils';
import { validNameChars } from '../../../utils/validNameChars';
import { UboInfoScreen } from '../UBO/UboInfoScreen';
import { Applicant2InfoScreen } from '../AuthorizedToSign/Applicant2InfoScreen';
import { InputLabel } from '../InputLabel';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

export const SET_CONTACT_NAME_SCREEN_BACK_BUTTON_TEST_ID =
  'Onboarding-ContactName-BackButton';

export const SET_CONTACT_NAME_SCREEN_INPUT_GENDER_TEST_ID =
  'Onboarding-ContactName-Input-Gender';

export const SET_CONTACT_NAME_SCREEN_INPUT_LABEL_FIRST_NAME_TEST_ID =
  'Onboarding-ContactName-Input-FirstName';

export const SET_CONTACT_NAME_SCREEN_INPUT_LABEL_PREFIX_TEST_ID =
  'Onboarding-ContactName-Input-Prefix';

export const SET_CONTACT_NAME_SCREEN_INPUT_LABEL_LAST_NAME_TEST_ID =
  'Onboarding-ContactName-Input-LastName';

export const SET_CONTACT_NAME_SCREEN_NEXT_BUTTON_TEST_ID =
  'Onboarding-ContactName-NextButton';

interface ContactNameProps {
  setContactName: (param: {
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    gender?: string;
  }) => void;
  ubo?: boolean;
  onClickBack: () => void;
  pendingNames: {
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
  };
  forApplicant2?: boolean;
}

function ContactName({
  setContactName,
  ubo,
  onClickBack,
  pendingNames,
  forApplicant2,
}: ContactNameProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedGender, setSelectedGender] = useState('');
  const [values, setValues] = useState({
    firstName: '',
    lastNamePrefix: '',
    lastName: '',
    gender: '',
    info: false,
  });
  useOnboardingPageView('contact-name');

  useEffect(
    () => {
      if ((ubo || forApplicant2) && pendingNames) {
        if (pendingNames.firstName)
          setValues({
            ...values,
            firstName: pendingNames.firstName,
            lastNamePrefix: pendingNames.lastNamePrefix,
            lastName: pendingNames.lastName,
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (event: any): void => {
    const { name, value } = event.target;
    if (name === 'firstName' || name === 'lastName') {
      setValues({ ...values, [name]: capitalizeDuringTyping(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const submitContactName = (): void => {
    const capitalizedFirstName = capitalizeAndRemoveSpaces(values.firstName);
    const capitalizedLastName = capitalizeAndRemoveSpaces(values.lastName);
    const nameData = {
      firstName: capitalizedFirstName,
      lastNamePrefix: values.lastNamePrefix,
      lastName: capitalizedLastName,
    };
    setContactName(
      ubo || forApplicant2 ? nameData : { gender: selectedGender, ...nameData },
    );
  };

  const invalidInput = (): boolean =>
    values.firstName.length < 2 ||
    values.lastName.length < 2 ||
    (!ubo && !forApplicant2 && isEmpty(selectedGender));

  const onHandleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const c = e.key;
    const ctrlV = (e.ctrlKey || e.metaKey) && e.key === 'v';
    const ctrlC = (e.ctrlKey || e.metaKey) && e.key === 'c';

    if (
      e.key === 'ArrowLeft' ||
      e.key === 'RightArrow' ||
      e.key === 'Backspace' ||
      e.key === 'Tab' ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    if (validNameChars.indexOf(c) === -1) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && !invalidInput()) {
      submitContactName();
    }
  };

  const showInfo = (): void =>
    setValues({
      ...values,
      info: !values.info,
    });

  return (
    <>
      <Wrapper>
        {ubo && (
          <>
            <NavigationHeader
              clickLeft={onClickBack as () => void}
              clickRight={showInfo}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.grey.dark,
                marginTop: blokkieTheme.spacing(1),
              }}
              variant="ps700"
            >
              {t('onboarding.setContactNameScreen.uboHeader')}
            </BlokkieTypography>
            <Title>{t('onboarding.setContactNameScreen.titleUbo')}</Title>
          </>
        )}
        {forApplicant2 && (
          <>
            <NavigationHeader
              clickLeft={onClickBack as () => void}
              clickRight={showInfo}
              noMarginBottom
            />
            <Title>
              {t('onboarding.setContactNameScreen.titleApplicant2')}
            </Title>
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.grey.dark,
                marginBottom: blokkieTheme.spacing(1),
              }}
              variant="ps700"
            >
              {t('onboarding.setContactNameScreen.descriptionApplicant2')}
            </BlokkieTypography>
          </>
        )}
        {!ubo && !forApplicant2 && (
          <>
            <NavigationHeader clickRight={showInfo} noMarginBottom />
            <Title>{t('onboarding.setContactNameScreen.title')}</Title>
          </>
        )}
        {!ubo && !forApplicant2 && (
          <div className={classes.radioButtonContainer}>
            <OnboardingRadioButton
              key="MALE"
              id="MALE"
              onClick={() => setSelectedGender('MALE')}
              checked={isEqual(selectedGender, 'MALE')}
              text={t('onboarding.setContactNameScreen.genderMale')}
              data-test-id={SET_CONTACT_NAME_SCREEN_INPUT_GENDER_TEST_ID}
            />
            <OnboardingRadioButton
              key="FEMALE"
              id="FEMALE"
              onClick={() => setSelectedGender('FEMALE')}
              checked={isEqual(selectedGender, 'FEMALE')}
              text={t('onboarding.setContactNameScreen.genderFemale')}
              data-test-id={SET_CONTACT_NAME_SCREEN_INPUT_GENDER_TEST_ID}
            />
            <OnboardingRadioButton
              key="NEUTRAL"
              id="NEUTRAL"
              onClick={() => setSelectedGender('NEUTRAL')}
              checked={isEqual(selectedGender, 'NEUTRAL')}
              text={t('onboarding.setContactNameScreen.genderNeutral')}
              data-test-id={SET_CONTACT_NAME_SCREEN_INPUT_GENDER_TEST_ID}
            />
          </div>
        )}
        <Box marginTop={`${isMobileOnly ? blokkieTheme.spacing(3) : 0}px`}>
          <InputLabel
            dataTestId={SET_CONTACT_NAME_SCREEN_INPUT_LABEL_FIRST_NAME_TEST_ID}
            label={t('onboarding.setContactNameScreen.firstNameInputLabel')}
            name="firstName"
            text={values.firstName}
            onChange={handleInputChange}
            onKeyDown={onHandleKeyDown}
            maxLength={45}
          />
        </Box>
        <Box flexDirection={isMobileOnly ? 'column' : 'row'}>
          <InputLabel
            style={{ flex: 1, marginTop: blokkieTheme.spacing(2) }}
            dataTestId={SET_CONTACT_NAME_SCREEN_INPUT_LABEL_PREFIX_TEST_ID}
            label={t('onboarding.setContactNameScreen.prefixInputLabel')}
            name="lastNamePrefix"
            text={values.lastNamePrefix}
            onChange={handleInputChange}
            onKeyDown={onHandleKeyDown}
            maxLength={45}
          />
          <InputLabel
            style={{
              flex: 3,
              marginTop: blokkieTheme.spacing(2),
              marginLeft: isMobileOnly ? 0 : blokkieTheme.spacing(2),
            }}
            dataTestId={SET_CONTACT_NAME_SCREEN_INPUT_LABEL_LAST_NAME_TEST_ID}
            label={t('onboarding.setContactNameScreen.lastNameInputLabel')}
            name="lastName"
            text={values.lastName}
            onChange={handleInputChange}
            onKeyDown={onHandleKeyDown}
            maxLength={45}
          />
        </Box>
        <ButtonFooter>
          <BlokkieButton
            disabled={invalidInput()}
            onClick={submitContactName}
            data-test-id={SET_CONTACT_NAME_SCREEN_NEXT_BUTTON_TEST_ID}
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={values.info && !ubo && !forApplicant2}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={ContactName.name}
      >
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            display: 'block',
          }}
          variant="pl500"
        >
          {t('onboarding.setContactNameScreen.infoParagraph1')}
        </BlokkieTypography>
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            display: 'block',
            marginTop: blokkieTheme.spacing(2),
          }}
          variant="pl500"
        >
          {t('onboarding.setContactNameScreen.infoParagraph2')}
        </BlokkieTypography>
      </InfoScreen>
      <Applicant2InfoScreen
        show={values.info && !!forApplicant2}
        showInfo={showInfo}
        fromPage={`${ContactName.name}_applicant2`}
      />
      <UboInfoScreen
        show={values.info && !!ubo}
        showInfo={showInfo}
        fromPage={`${ContactName.name}_ubo`}
      />
    </>
  );
}

const useStyles = createUseStyles({
  radioButtonContainer: {
    display: 'flex',
    gap: blokkieTheme.spacing(2),
    marginTop: blokkieTheme.spacing(3),
    marginBottom: blokkieTheme.spacing(3),
    flexWrap: 'wrap',
    [blokkieTheme.mediaQueries.allPhone]: {
      marginBottom: blokkieTheme.spacing(),
    },
    [blokkieTheme.mediaQueries.smallPhone]: {
      flexDirection: 'column',
      gap: blokkieTheme.spacing(2),
    },
  },
});

export default injectIntl(ContactName);

import {
  LOAD_BULK_FILES,
  LOAD_BULK_FILES_ERROR,
  LOAD_BULK_FILES_SUCCESS,
} from './constants';

export function loadBulkFiles(page) {
  return {
    type: LOAD_BULK_FILES,
    payload: {
      page,
    },
  };
}

export function loadBulkFilesSuccess(bulkFiles) {
  return {
    type: LOAD_BULK_FILES_SUCCESS,
    payload: {
      bulkFiles,
    },
  };
}

export function loadBulkFilesError(error) {
  return {
    type: LOAD_BULK_FILES_ERROR,
    payload: {
      error,
    },
  };
}

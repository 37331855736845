import '!file-loader?name=[name].[ext]!../../../images/maxpayments-page-example.png';

import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { Container, InputTile } from 'amber';
import { useTranslation } from 'react-i18next';
import { ContainerProps, InputErrorBox } from '../styledComponents';
import config from '../../../config';
import { ImageVerifyUploader } from './ImageVerifyUploader';
import { PENDING_MAY_PAYMENT_IMG } from './constants';
import { PreviewPage } from './PreviewPage';
import { isGifFileExtension } from '../../../utils';

function MaxPayments({ values, onChange, validate, errors }) {
  const { t } = useTranslation();
  const validateText = (value, max, valueStr) =>
    validate(value.length > max, valueStr);
  const validateTitle = () =>
    validateText(values.maxPaymentsTitle, 40, 'maxPaymentsTitle');
  const validateContent = () =>
    validateText(values.maxPaymentsContent, 50, 'maxPaymentsContent');

  return (
    <>
      <Container col={9}>
        <ContainerProps row mb={24} maxHeight="100%">
          <Container row>
            {config.get(config.LOGO_UPLOAD_ENABLED) && (
              <ImageVerifyUploader
                desc={t('portal.settings.pages.general.gif.description')}
                original={values.maxPaymentsImage}
                pending={values.pendingMaxPaymentsImage}
                entityName="gifje"
                pendingVariable={PENDING_MAY_PAYMENT_IMG}
                maxFileSize={2048}
                requiredExtensionType="gif"
                verifyFileExtension={isGifFileExtension}
                onLoad={(file) => {
                  onChange('inputMaxPaymentsImage', file);
                }}
                onChange={onChange}
              />
            )}
            <Container row mb={24}>
              <InputTile
                title={t('portal.settings.pages.maxPaymentsTitle.title')}
                desc={t('portal.settings.pages.maxPaymentsTitle.description')}
                value={values.maxPaymentsTitle}
                type="text"
                id="maxPaymentsTitle"
                dataTestId="Organisation-MaxPaymentsTitleInput"
                onChange={(fieldId, value) => {
                  validateTitle();
                  onChange(fieldId, value);
                }}
                onBlur={validateTitle}
                placeholder={t(
                  'portal.settings.pages.maxPaymentsTitle.placeholder',
                )}
              >
                {errors.maxPaymentsTitle && (
                  <InputErrorBox
                    errorText={t(
                      'portal.settings.pages.maxPaymentsTitle.error',
                    )}
                  />
                )}
              </InputTile>
            </Container>
            <Container row mb={24}>
              <InputTile
                title={t('portal.settings.pages.maxPaymentsContent.title')}
                desc={t('portal.settings.pages.maxPaymentsContent.description')}
                value={values.maxPaymentsContent}
                type="text"
                id="maxPaymentsContent"
                dataTestId="Organisation-MaxPaymentsContentInput"
                onChange={(fieldId, value) => {
                  validateContent();
                  onChange(fieldId, value);
                }}
                onBlur={validateContent}
                placeholder={t(
                  'portal.settings.pages.maxPaymentsContent.placeholder',
                )}
              >
                {errors.maxPaymentsContent && (
                  <InputErrorBox
                    errorText={t(
                      'portal.settings.pages.maxPaymentsContent.error',
                    )}
                  />
                )}
              </InputTile>
            </Container>
          </Container>
        </ContainerProps>
      </Container>
      <Container col={3} center={isMobile}>
        <PreviewPage>
          <img
            src="/maxpayments-page-example.png"
            alt="maxpayments-page-example"
          />
        </PreviewPage>
      </Container>
    </>
  );
}

MaxPayments.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default MaxPayments;

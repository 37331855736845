/**
 *
 * PaymentRequestDetail
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import ReactBodymovin from 'react-bodymovin';
import { injectIntl, FormattedMessage } from 'react-intl';
import Pagination from 'rc-pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';
import 'rc-pagination/assets/index.css';
import PropTypes from 'prop-types';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import { HR } from 'amber';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { selectUser } from '../../../containers/App/selectors';
import '!file-loader?name=[name].[ext]!../../../images/downloadicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/uploadicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/fileok.svg';
import '!file-loader?name=[name].[ext]!../../../images/trash.svg';
import '!file-loader?name=[name].[ext]!../../../images/calendar.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/checkicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/icon-pencil.svg';

import TransactionLineItem from '../../Transactions/TransactionLineItem';
import { isEmpty, toCurrency } from '../../../utils';
import messages from './messages';
import animationData from '../../../images/animations/green-dots.json';
import {
  Color,
  Header1,
  Header2,
  CaptionSmall,
  PrimaryButton,
  SecondaryButton,
  Panel,
  NonMobileWrapper,
  MobileWrapper,
  Space,
} from '../../styleguide';

import { GiphyBox, BackArrow, BreadCrumb } from '../../components';
import PaymentRequestDetailItem from './PaymentRequestDetailItem';
import Loader from '../../OnboardingKYC/Loader';
import PopupWithButtons from './PopupWithButtons';
import { usePrevious } from '../../../hooks';
import { DatePicker } from '../../DatePicker/single-date-picker';

const PaymentRequestDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;
  @media ${(props) => props.theme.device.mobile} {
    padding: 16px;
  }
`;

const Header = styled(Header1)`
  margin: ${Space.s16} 0;
`;

const OverviewWrapper = styled(Panel)`
  display: flex;
  flex-direction: column;
  padding: ${Space.s32};

  @media ${(props) => props.theme.device.mobile} {
    padding: ${Space.s24};
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${Space.s16};
  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column-reverse;
  }
`;

const HRMargin = styled(HR)`
  margin-bottom: ${Space.s16};
`;

const Footer = styled(Row)`
  align-items: center;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;

  @media ${(props) => props.theme.device.mobile} {
    display: grid;
    gap: 1em;
    height: auto;
    line-height: 32px;
    border-bottom: none;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Buttons = styled.div`
  padding-top: 24px;
  border-top: 2px solid ${Color.gray05};
`;

const SendButton = styled(PrimaryButton)`
  margin-right: 16px;
`;

const MobileSendButton = styled(SendButton)`
  width: 100%;
  margin-bottom: 16px;
`;

const DownloadButton = styled(SecondaryButton)`
  margin-right: 16px;
  border-radius: 24px;
`;

const CopyButton = styled(SecondaryButton)`
  margin-right: 16px;
  border-radius: 24px;
`;

const GreenCopyButton = styled(CopyButton)`
  width: 100%;
`;

const ContentWrapper = styled.div``;

const ContentHeader = styled(Header2)`
  margin-top: 40px;
  margin-bottom: 16px;
  margin-left: 8px;
`;

const GifjeContainer = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 400px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    padding: 0 16px;
  }
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const Right = styled.div`
  float: right;
  margin-left: ${Space.s8};

  img {
    vertical-align: text-bottom;
    margin-right: 8px;
  }

  button {
    margin-right: 0;
  }
`;

const NavPrev = styled.img`
  margin-top: -6px;
`;

const NavNext = styled.img`
  margin-top: -6px;
  transform: rotate(180deg);
`;

const PagingWrapper = styled.div`
  margin: 24px auto;
  text-align: center;

  ul {
    display: inline-block;
    margin: 0px auto;
  }

  li,
  li.rc-pagination-prev,
  li.rc-pagination-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    border: solid 2px ${Color.gray05};
    background-color: ${Color.white};
    outline: none !important;
    margin-bottom: 8px;
  }

  li:focus,
  li > a:focus {
    outline: none !important;
  }

  li > a {
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
    outline: none !important;
  }

  li.rc-pagination-item-active {
    color: ${Color.white} !important;
    background-color: ${Color.brandColor01};
    border: 2px solid ${Color.brandColor01} !important;
    outline: none;
  }

  li.rc-pagination-item-active > a {
    color: ${Color.white};
  }

  .rc-pagination-simple-pager {
    width: auto;
    line-height: 48px;
    margin: 0px 24px 0px 0px;
    background-color: transparent;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-simple-pager > input {
    width: auto;
    text-align: right;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
    font-family: MuseoSansRounded700;
    font-size: 16px;
    color: ${Color.gray01};
  }

  .rc-pagination-slash {
    margin: 0px !important;
  }

  .rc-pagination-item.rc-pagination-item-active:hover a {
    border: none !important;
    cursor: default;
    color: ${Color.white} !important;
  }

  .rc-pagination-item:hover {
    border: solid 2px ${Color.brandColor01} !important;
  }

  .rc-pagination-item:hover a {
    color: ${Color.brandColor01} !important;
  }
`;

const CopyNotification = styled.div`
  position: fixed;
  bottom: 32px;
  width: 280px;
  height: 38px;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 50px;
  box-shadow: 2px 2px 14px 0 rgba(79, 195, 184, 0.5);
  background-color: ${Color.brandColor01};
  color: ${Color.white};
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  transition: ${(props) => (props.copied ? 'none' : 'opacity 0.5s ease-in')};
  opacity: ${(props) => (props.copied ? '1' : '0')};

  img {
    margin-right: 8px;
  }

  @media ${(props) => props.theme.device.mobile} {
    bottom: auto;
    left: 0px;
    right: 0px;
    width: auto;
    transform: none;
    border-radius: 0px;
    opacity: 1;
    transition: ${(props) => (props.copied ? 'none' : 'top 0.5s ease-in')};
    top: ${(props) => (props.copied ? '0px' : '-50px')};
`;

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

const CaptionSmallWithMaxWidth = styled(CaptionSmall)`
  max-width: 50%;
  display: flex;
  gap: 5px;

  &, & span {
    white-space: nowrap;
  }

  @media ${(props) => props.theme.device.mobile} {
  max-width: 100%;
  flex-direction: column;
`;

const useStyles = createUseStyles({
  button: {
    height: '48px',
    cursor: 'pointer',
    color: Color.gray01,
    borderRadius: '50px',
    background: Color.white,
    padding: '0 12px',
    display: 'grid',
    columnGap: '5px',
    fontSize: '16px',
    gridAutoFlow: 'column',
    width: 'fit-content',
    alignItems: 'center',
    border: '2px solid #F4F4F4',

    '& span': {
      whiteSpace: 'nowrap !important',
    },

    '& > div': {
      cursor: 'default',
    },

    '&:disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  },

  validity: {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',

    '& h2': {
      marginTop: '4px',
      width: '275px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },

  moveUp: {
    '& h2': {
      marginTop: -blokkieTheme.spacing(),
    },
  },

  datepicker: {
    left: '-15px',
    transform: 'translate(0, 30px)',

    '& > i': {
      left: '70px',
    },

    [blokkieTheme.mediaQueries.allPhone]: {
      left: '15px',
    },
  },
});

function PaymentRequestDetail(props) {
  const {
    paymentRequest,
    paymentsIsLoading,
    paymentRequestIsLoading,
    paymentsErrorMessage,
    loadPaymentRequest,
    payments,
    showTransactionDetail,
    downloadOption,
    archivePaymentRequest,
    archivingPaymentRequest,
    showShareTikkie,
    showDashboard,
    downloadSinglePaymentRequest,
    showPayerDetails,
    editPaymentRequest,
    isPreview,
    user: { multiPay },
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPayments, setTotalNumberOfPayments] = useState(0);
  const [copied, setCopied] = useState(false);
  const [showArchivePrompt, setShowArchivePrompt] = useState(false);
  const [focus, setFocus] = useState(false);
  const [expireDate, setExpireDate] = useState(moment(0));
  const [status, setStatus] = useState(paymentRequest.status || 'CLOSED');

  useEffect(() => {
    // eslint-disable-next-line no-console
    setStatus(paymentRequest.status);
    if (expireDate.isSame(moment(0)) && paymentRequest.expirationDate) {
      setExpireDate(moment.unix(paymentRequest.expirationDate / 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest]);

  const datePickerEnabled = useMemo(
    () =>
      status !== 'CLOSED' && (multiPay || paymentRequest.totalTimesPaid === 0),
    [multiPay, paymentRequest.totalTimesPaid, status],
  );

  const disabled = useMemo(
    () =>
      expireDate.isSame(moment(0)) ||
      !datePickerEnabled ||
      moment().valueOf() > expireDate.endOf('day'),
    [datePickerEnabled, expireDate],
  );

  const fullDate = useMemo(
    () =>
      expireDate.toDate().toLocaleString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    [expireDate],
  );

  const popup = React.useRef(null);
  useOnClickOutside(popup, () => setFocus(false));

  const saveExpireDate = (newExpireDate) => {
    setExpireDate(newExpireDate);
    setStatus('OPEN');
    editPaymentRequest(paymentRequest.token, newExpireDate.toDate());
    setFocus(false);
  };

  const match = useRouteMatch();
  const history = useHistory();
  const { paymentRequestToken } = match.params;

  const prevCurrentPage = usePrevious(currentPage);

  useEffect(() => {
    loadPaymentRequest(paymentRequestToken);
    loadPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevCurrentPage !== currentPage) {
      loadPayments();
    }

    if (payments && !isEmpty(payments)) {
      if (payments.totalNumberOfPayments !== totalNumberOfPayments) {
        setTotalNumberOfPayments(payments.totalNumberOfPayments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, totalNumberOfPayments, currentPage]);

  const onChangePaging = (page) => setCurrentPage(page);

  const onShare = () => {
    const hasBackOption = true;
    const notifications = [];

    paymentRequest.notifications.map((notification) =>
      notifications.push({
        number: notification.recipientAddress.slice(4),
        status: notification.status === 'SENT',
      }),
    );

    showShareTikkie(
      paymentRequest.description,
      paymentRequest.referenceId,
      `${paymentRequest.amountPerPersonInCents}`,
      paymentRequest.openAmount,
      paymentRequest.paymentEventUrl,
      paymentRequest.token,
      notifications,
      hasBackOption,
      paymentRequest,
    );
  };

  const showPaging = () => payments.totalNumberOfPayments > 20;

  const lastPage = () => currentPage === 100;

  const loadPayments = () => {
    props.loadPayments(currentPage, paymentRequestToken);
  };

  const hasToken = () =>
    !!paymentRequest && paymentRequest.token && paymentRequest.token !== '';

  const showPreviewInfo = () =>
    !hasToken() &&
    (paymentRequest.payerPhoneNumber ||
      paymentRequest.descriptionSms ||
      paymentRequest.descriptionBankStatement);

  const hasPayments = () => totalNumberOfPayments !== 0;

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const goBackToBulkFromDraft = () => history.push(paymentRequest.backPath);

  const goBack = () => (hasToken() ? showDashboard() : goBackToBulkFromDraft());

  const backToBulkStr = hasToken() ? '' : 'ToBulk';

  if (paymentRequestIsLoading || paymentsIsLoading) {
    return <Loader />;
  }

  return (
    <PaymentRequestDetailWrapper>
      <CopyNotification copied={copied}>
        <img src="/checkicon.svg" alt="" />
        <FormattedMessage {...messages.copied} />
      </CopyNotification>
      <MobileWrapper>
        <BackArrow onClick={goBack} />
      </MobileWrapper>
      <NonMobileWrapper>
        <FormattedMessage {...messages[`back${backToBulkStr}`]}>
          {(placeholder) => (
            <BreadCrumb
              id="backToOverview"
              start={placeholder}
              onStart={goBack}
              text={paymentRequest.description}
            />
          )}
        </FormattedMessage>
      </NonMobileWrapper>
      <Header>
        {hasToken() && <FormattedMessage {...messages.header} />}
        {!hasToken() && <FormattedMessage {...messages.previewHeader} />}
      </Header>
      <OverviewWrapper>
        <Row>
          <PaymentRequestDetailItem
            dataTestId="paymentRequestDetail-description"
            expand
            label={<FormattedMessage {...messages.labelTitle} />}
            value={paymentRequest.description}
            big
          />
          <PaymentRequestDetailItem
            dataTestId="paymentRequestDetail-amountRequested"
            label={<FormattedMessage {...messages.labelAmount} />}
            value={
              paymentRequest.openAmount ? (
                <FormattedMessage {...messages.openTikkie} />
              ) : (
                toCurrency(paymentRequest.amountPerPersonInCents)
              )
            }
            padding
            border
            big
          />
          <PaymentRequestDetailItem
            dataTestId="paymentRequestDetail-amountPaid"
            label={<FormattedMessage {...messages.labelTotal} />}
            value={toCurrency(paymentRequest.totalAmountPaidInCents)}
            padding
            border
            big
          />
        </Row>
        <Row>
          <PaymentRequestDetailItem
            dataTestId="paymentRequestDetail-invoiceNumber"
            label={<FormattedMessage {...messages.labelInvoiceNumber} />}
            value={paymentRequest.referenceId}
          />
        </Row>
        <Row className={[classes.validity, isPreview ? null : classes.moveUp]}>
          <PaymentRequestDetailItem
            dataTestId="paymentRequestDetail-expire"
            label={
              <FormattedMessage
                id="paymentRequestDetail-expire-date"
                defaultMessage={t('portal.page.payment-request.validity')}
              />
            }
          >
            <span data-test-id="paymentRequestDetail-expire-date">
              {fullDate}
            </span>
            {!isPreview && (
              <button
                ref={popup}
                disabled={!datePickerEnabled}
                className={classes.button}
                type="button"
                onClick={() => setFocus(!focus)}
                dataTestId="paymentRequestDetail-expire-adjust"
              >
                <img src="/icon-pencil.svg" alt="" />
                <BlokkieTypography variant="pl700">
                  {t('portal.page.payment-request.adjust')}
                </BlokkieTypography>
                <DatePicker
                  className={classes.datepicker}
                  date={expireDate}
                  focus={focus}
                  buttonText="portal.components.datepicker.adjust"
                  setDate={saveExpireDate}
                  checkRange={(day) =>
                    day.isSameOrAfter(moment().startOf('day'))
                  }
                />
              </button>
            )}
          </PaymentRequestDetailItem>
        </Row>
        {showPreviewInfo() && (
          <>
            <HRMargin />
            {paymentRequest.payerPhoneNumber && (
              <Row>
                <PaymentRequestDetailItem
                  dataTestId="paymentRequestDetail-phone"
                  label={<FormattedMessage {...messages.labelPhone} />}
                  value={paymentRequest.payerPhoneNumber}
                />
              </Row>
            )}
            {paymentRequest.descriptionSms && (
              <Row>
                <PaymentRequestDetailItem
                  dataTestId="paymentRequestDetail-sms"
                  label={<FormattedMessage {...messages.labelSms} />}
                  value={paymentRequest.descriptionSms}
                />
              </Row>
            )}
            {paymentRequest.descriptionBankStatement && (
              <Row>
                <PaymentRequestDetailItem
                  dataTestId="paymentRequestDetail-descriptionBankStatement"
                  label={
                    <FormattedMessage
                      {...messages.labelDescriptionBankStatement}
                    />
                  }
                  value={paymentRequest.descriptionBankStatement}
                />
              </Row>
            )}
          </>
        )}
        {!!paymentRequest.notifications && (
          <Footer>
            <CaptionSmallWithMaxWidth data-test-id="paymentRequestDetail_created">
              {!paymentRequest.creatorFirstName && (
                <FormattedMessage {...messages.labelCreated} />
              )}
              {paymentRequest.creatorFirstName && (
                <>
                  <FormattedMessage {...messages.labelCreatedBy} />
                  {paymentRequest.creatorFirstName}{' '}
                  {paymentRequest.creatorLastName}
                </>
              )}
              <FormattedMessage {...messages.labelCreatedOn} />
              <span>
                {moment(paymentRequest.created).format('D MMM YYYY, HH:mm')} uur
              </span>
            </CaptionSmallWithMaxWidth>
            <CaptionSmall>
              {paymentRequest.notifications.length > 0 && (
                <FormattedMessage {...messages.labelSent}>
                  {(placeholder) => (
                    <span id="numberOfSentTextMessages">
                      🚀 {placeholder} {paymentRequest.notifications.length}
                    </span>
                  )}
                </FormattedMessage>
              )}
            </CaptionSmall>
          </Footer>
        )}
        {hasToken() && (
          <Buttons>
            <NonMobileWrapper>
              <SendButton id="shareAgain" disabled={disabled} onClick={onShare}>
                <img src="/uploadicon-o.svg" alt="" />
                <FormattedMessage {...messages.buttonSend} />
              </SendButton>
              <CopyToClipboard
                text={paymentRequest.paymentEventUrl}
                onCopy={onCopy}
              >
                <CopyButton disabled={disabled}>
                  <img src="/copyicon.svg" alt="" />
                  <FormattedMessage {...messages.buttonCopy} />
                </CopyButton>
              </CopyToClipboard>
              <Right>
                <CopyButton
                  data-test-id="tikkie-detail-archive-button"
                  onClick={() => setShowArchivePrompt(true)}
                  disabled={
                    showArchivePrompt ||
                    archivingPaymentRequest ||
                    status === 'CLOSED'
                  }
                >
                  <img src="/trash.svg" alt="" />
                  <FormattedMessage {...messages.archive} />
                </CopyButton>
                {(showArchivePrompt || archivingPaymentRequest) && (
                  <PopupWithButtons
                    onYes={() => {
                      archivePaymentRequest(paymentRequest.token);
                      setShowArchivePrompt(false);
                    }}
                    onNo={() => setShowArchivePrompt(false)}
                    loading={archivingPaymentRequest}
                  />
                )}
              </Right>
              {downloadOption && (
                <Right>
                  <DownloadButton
                    disabled={!hasPayments()}
                    onClick={() => {
                      downloadSinglePaymentRequest(paymentRequest.token);
                    }}
                  >
                    <img src="/downloadicon.svg" alt="" />
                    <FormattedMessage {...messages.buttonDownload} />
                  </DownloadButton>
                </Right>
              )}
            </NonMobileWrapper>
            <MobileWrapper>
              <MobileSendButton
                id="shareAgain"
                disabled={disabled}
                onClick={onShare}
              >
                <img src="/uploadicon-o.svg" alt="" />
                <FormattedMessage {...messages.buttonSend} />
              </MobileSendButton>
              <CopyToClipboard
                text={paymentRequest.paymentEventUrl}
                onCopy={copied}
              >
                <GreenCopyButton disabled={disabled}>
                  <img src="/copyicon-o.svg" alt="" />
                  <FormattedMessage {...messages.buttonCopy} />
                </GreenCopyButton>
              </CopyToClipboard>
            </MobileWrapper>
          </Buttons>
        )}
      </OverviewWrapper>
      {hasToken() && (
        <ContentWrapper>
          {!paymentsErrorMessage && (
            <ContentHeader>
              <FormattedMessage {...messages.contentHeader} /> (
              {paymentRequest.totalTimesPaid})
            </ContentHeader>
          )}
          {!!paymentsErrorMessage && (
            <ContentHeader>
              <FormattedMessage {...messages.contentHeader} /> (-)
            </ContentHeader>
          )}
          {!!paymentsErrorMessage && (
            <GifjeContainer>
              <GifjeWrapper>
                <GiphyBox
                  gif="oops.gif"
                  title={messages.oopsTitle.defaultMessage}
                  text={messages.oopsText.defaultMessage}
                />
              </GifjeWrapper>
            </GifjeContainer>
          )}
          {!lastPage() &&
            !!payments.payments &&
            !paymentsIsLoading &&
            payments.payments.map((payment) => (
              <TransactionLineItem
                key={payment.id}
                payment={payment}
                invoiceNumber={paymentRequest.referenceId}
                description={paymentRequest.description}
                showPayerDetails={showPayerDetails}
                showTransactionDetail={() => showTransactionDetail(payment)}
              />
            ))}
          {!lastPage() && paymentsIsLoading && (
            <LoaderWrapper>
              <AnimationWrapper>
                <ReactBodymovin options={bodymovinOptions} />
              </AnimationWrapper>
            </LoaderWrapper>
          )}
          {lastPage() && (
            <GifjeContainer>
              <GifjeWrapper>
                <GiphyBox
                  gif="oops.gif"
                  title={messages.endTitle.defaultMessage}
                  text={messages.endText.defaultMessage}
                />
              </GifjeWrapper>
            </GifjeContainer>
          )}
          {showPaging() && (
            <PagingWrapper>
              <Pagination
                onChange={onChangePaging}
                defaultCurrent={1}
                current={currentPage}
                pageSize={20}
                simple={isMobileOnly}
                showLessItems={isTablet}
                total={totalNumberOfPayments}
                prevIcon={<NavPrev src="/back.svg" />}
                nextIcon={<NavNext src="/back.svg" />}
                showTitle={false}
              />
            </PagingWrapper>
          )}
        </ContentWrapper>
      )}
    </PaymentRequestDetailWrapper>
  );
}

PaymentRequestDetail.propTypes = {
  history: PropTypes.object,
  paymentRequest: PropTypes.object.isRequired,
  paymentRequestIsLoading: PropTypes.bool.isRequired,
  paymentRequestErrorMessage: PropTypes.string,
  payments: PropTypes.object.isRequired,
  paymentsIsLoading: PropTypes.bool.isRequired,
  paymentsErrorMessage: PropTypes.string,
  showDashboard: PropTypes.func.isRequired,
  showShareTikkie: PropTypes.func.isRequired,
  showTransactionDetail: PropTypes.func.isRequired,
  loadPaymentRequest: PropTypes.func.isRequired,
  loadPayments: PropTypes.func.isRequired,
  downloadOption: PropTypes.bool.isRequired,
  downloadSinglePaymentRequest: PropTypes.func,
  archivePaymentRequest: PropTypes.func.isRequired,
  showPayerDetails: PropTypes.bool,
  archivingPaymentRequest: PropTypes.bool,
  match: PropTypes.object,
  isDraft: PropTypes.bool,
  editPaymentRequest: PropTypes.func,
  user: PropTypes.object,
  isPreview: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect, injectIntl)(PaymentRequestDetail);

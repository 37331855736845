import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useRouteMatch } from 'react-router-dom';
import { backgroundLogout, confirmEmailChange } from '../App/actions';
import {
  selectEmailChangeError,
  selectEmailChangeSuccess,
} from '../App/selectors';
import { EmailChangeConfirmed } from '../../components/ConfirmEmailChange/EmailChangeConfirmed';
import { EnterPasswordForConfirmation } from '../../components/ConfirmEmailChange/EnterPasswordForConfirmation';
import { EmailChangeError } from '../../components/ConfirmEmailChange/EmailChangeError';

function ConfirmEmailChangeContainer({
  logout,
  confirmEmailChangeRequest,
  emailChangeSuccess,
  emailChangeError,
}) {
  const [emailChangeConfirmed, setEmailChangeConfirmed] = useState(false);
  const match = useRouteMatch();
  const { token } = match.params;

  useEffect(() => {
    if (emailChangeSuccess) {
      logout();
      setEmailChangeConfirmed(true);
    }
  }, [emailChangeSuccess]);

  const confirmWithPassword = (password) => {
    confirmEmailChangeRequest(token, password);
  };

  return (
    <div>
      {emailChangeError && <EmailChangeError />}
      {!emailChangeError && !emailChangeConfirmed && (
        <EnterPasswordForConfirmation enterPassword={confirmWithPassword} />
      )}
      {!emailChangeError && emailChangeConfirmed && <EmailChangeConfirmed />}
    </div>
  );
}

ConfirmEmailChangeContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  confirmEmailChangeRequest: PropTypes.func.isRequired,
  emailChangeSuccess: PropTypes.bool.isRequired,
  emailChangeError: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  emailChangeSuccess: selectEmailChangeSuccess,
  emailChangeError: selectEmailChangeError,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(backgroundLogout()),
  confirmEmailChangeRequest: (token, password) =>
    dispatch(confirmEmailChange(token, password)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfirmEmailChangeContainer);

import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/request';

import {
  loadLinkedOrganisationsError,
  loadLinkedOrganisationsSuccess,
} from './actions';
import config from '../../config';
import { LOAD_LINKED_ORGANISATIONS, LOGOUT_USER } from './constants';

export default function* watcherSaga() {
  yield takeLatest(LOGOUT_USER, logoutUserSaga);
  yield takeLatest(LOAD_LINKED_ORGANISATIONS, loadLinkedOrganisationsSaga);
}

export function* logoutUserSaga() {
  localStorage.clear();
  const requestUrl = `${config.API_URL}/logout`;

  try {
    yield call(request, requestUrl, {
      credentials: 'include',
    });
    window.location.reload();
  } catch (err) {
    window.location.reload();
  }
}

export function* loadLinkedOrganisationsSaga() {
  const requestUrl = `${config.API_URL}/organisations`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadLinkedOrganisationsSuccess(response));
  } catch (err) {
    yield put(loadLinkedOrganisationsError(err));
  }
}

/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import settingsReducer from './containers/SettingsContainer/reducer';
import paymentRequestReducer from './containers/PaymentRequest/reducer';
import onboardingKYCContainerReducer from './containers/OnboardingKYCContainer/reducer';
import history from './utils/history';

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    language: languageProviderReducer,
    paymentRequest: paymentRequestReducer,
    onboardingKYCContainer: onboardingKYCContainerReducer,
    settings: settingsReducer,
    ...injectedReducers,
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import injectSaga from '../../../../utils/injectSaga';
import saga from '../../saga';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../../components/components';
import AddUser from '../../../../components/Settings/UserManagement/AddUser';
import {
  selectAddUserToActiveOrganisationError,
  selectAddUserToActiveOrganisationLoading,
  selectAddUserToActiveOrganisationSuccess,
} from '../../selectors';
import {
  addUserToActiveOrganisation,
  resetAddUserToActiveOrganisation,
} from '../../actions';
import Loader from '../../../../components/OnboardingKYC/Loader';
import { selectOrganisationHasOtpEnabled } from '../../../App/selectors';

function AddUserContainer({
  dispatchAddUserToActiveOrganisation,
  dispatchResetUserToActiveOrganisation,
  addUserToActiveOrganisationLoading,
  addUserToActiveOrganisationSuccess,
  addUserToActiveOrganisationError,
  organisationHasOtpEnabled,
}) {
  const history = useHistory();
  const goBackToListUsers = () => {
    history.push('/settings/user-management/users');
  };
  const goToUserDetails = (id) =>
    history.push(`/settings/user-management/user/${id}`);
  const goToExistingUsers = () =>
    history.push('/settings/user-management/add-existing-user');

  return (
    <>
      {addUserToActiveOrganisationLoading && <Loader />}
      {!addUserToActiveOrganisationLoading && (
        <DashboardWrapper>
          <Container>
            <InnerContainer highMargin>
              <AddUser
                goBackToListUsers={goBackToListUsers}
                goToUserDetails={goToUserDetails}
                goToExistingUsers={goToExistingUsers}
                addUserToActiveOrganisation={
                  dispatchAddUserToActiveOrganisation
                }
                addUserToActiveOrganisationSuccess={
                  addUserToActiveOrganisationSuccess
                }
                addUserToActiveOrganisationError={
                  addUserToActiveOrganisationError
                }
                resetAddUserToActiveOrganisation={
                  dispatchResetUserToActiveOrganisation
                }
                otpEnabled={organisationHasOtpEnabled}
              />
            </InnerContainer>
          </Container>
        </DashboardWrapper>
      )}
    </>
  );
}

AddUserContainer.propTypes = {
  dispatchAddUserToActiveOrganisation: PropTypes.func.isRequired,
  dispatchResetUserToActiveOrganisation: PropTypes.func.isRequired,
  addUserToActiveOrganisationLoading: PropTypes.bool.isRequired,
  addUserToActiveOrganisationSuccess: PropTypes.object.isRequired,
  addUserToActiveOrganisationError: PropTypes.object.isRequired,
  organisationHasOtpEnabled: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  addUserToActiveOrganisationLoading: selectAddUserToActiveOrganisationLoading,
  addUserToActiveOrganisationSuccess: selectAddUserToActiveOrganisationSuccess,
  addUserToActiveOrganisationError: selectAddUserToActiveOrganisationError,
  organisationHasOtpEnabled: selectOrganisationHasOtpEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddUserToActiveOrganisation: (user) =>
    dispatch(addUserToActiveOrganisation(user)),
  dispatchResetUserToActiveOrganisation: () =>
    dispatch(resetAddUserToActiveOrganisation()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'AddUserContainer', saga });

export default compose(withSaga, withConnect)(AddUserContainer);

/*
 * LoginContainer Messages
 *
 * This contains all the text for the LoginContainer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  slogan1: {
    id: 'app.containers.LoginContainer.slogan1',
    defaultMessage: 'Kyk na die nuutste status van jou besigheids Tikkies',
  },
});

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import onboardingKYCContainerHOC from '../hoc';
import ChooseCountry from '../../../components/OnboardingKYC/ChooseCountry';
import { selectOrganisationName, selectCountry } from '../selectors';
import { setCountry } from '../actions';
import FormWrapperContainer from '../FormWrapperContainer';

function CountryContainer(props) {
  const { country, saveCountry } = props;

  return (
    <FormWrapperContainer portalBacking>
      <ChooseCountry saveCountry={saveCountry} selectedCountry={country} />
    </FormWrapperContainer>
  );
}

CountryContainer.propTypes = {
  saveCountry: PropTypes.func.isRequired,
  country: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  country: selectCountry,
});

const mapDispatchToProps = (dispatch) => ({
  saveCountry: (countryCode) => dispatch(setCountry(countryCode)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(CountryContainer);

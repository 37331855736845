export const SEND_SMS = 'app/paymentRequestShareContainer/SEND_SMS';
export const SEND_SMS_SUCCESS =
  'app/paymentRequestShareContainer/SEND_SMS_SUCCESS';
export const SEND_SMS_ERROR = 'app/paymentRequestShareContainer/SEND_SMS_ERROR';
export const FETCH_QR_CODE = 'app/paymentRequestShareContainer/FETCH_QR_CODE';
export const FETCH_QR_CODE_SUCCESS =
  'app/paymentRequestShareContainer/FETCH_QR_CODE_SUCCESS';
export const FETCH_QR_CODE_ERROR =
  'app/paymentRequestShareContainer/FETCH_QR_CODE_ERROR';
export const RESET_QR_CODE = 'app/paymentRequestShareContainer/RESET_QR_CODE';

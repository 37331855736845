import React from 'react';
import { useTranslation } from 'react-i18next';

import { GifContainer } from '../GifContainer';

interface Props {
  onClick: () => void | Promise<void>;
}

export function CashbackCampaignDetailEmpty(props: Props) {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <GifContainer
      gif="no-results.gif"
      title={t('deals.response.empty.title')}
      text={t('deals.response.empty.text')}
      buttonText={t('deals.response.empty.buttonText')}
      onClick={onClick}
    />
  );
}

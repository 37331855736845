import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.LinkNotFound.title',
    defaultMessage: '✉️ Oeps...',
  },
  subTitle: {
    id: 'app.components.LinkNotFound.subTitle',
    defaultMessage: 'Deze link werkt niet meer.',
  },
  contact: {
    id: 'app.components.LinkNotFound.contact',
    defaultMessage: 'Neem contact met ons op',
  },
});

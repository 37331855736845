import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingParagraph } from '../styledComponents';
import { InfoScreen } from '../InfoScreen';

interface UboInfoScreenProps {
  show: boolean;
  showInfo: () => void;
  fromPage: string;
  children?: React.ReactNode;
}

function UboInfoScreen({
  show,
  showInfo,
  fromPage,
  children,
}: UboInfoScreenProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <InfoScreen
      show={show}
      title={t('onboarding.uboInfoScreen.title')}
      onClick={showInfo}
      fromPage={fromPage}
    >
      <OnboardingParagraph>
        {t('onboarding.uboInfoScreen.body')}
      </OnboardingParagraph>
      {children}
    </InfoScreen>
  );
}

export { UboInfoScreen };

import { createGlobalStyle } from 'styled-components';
import MuseoSansRounded700 from './fonts/MuseoSansRounded-700-web.otf';
import MuseoSansRounded from './fonts/MuseoSansRounded-500-web.otf';
import MuseoSansRounded900 from './fonts/MuseoSansRounded-900-web.otf';
import OpenSansRegular from './fonts/OpenSans-Regular.ttf';
import OpenSansBold from './fonts/OpenSans-Bold.ttf';
import MuseoSans100 from './fonts/MuseoSans-100.otf';
import MuseoSans300 from './fonts/MuseoSans-300.otf';

/* eslint no-unused-expressions: 0 */
export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'OpenSansRegular';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'OpenSansBold';
    src: url(${OpenSansBold});
  }

  @font-face {
    font-family: 'MuseoSansRounded';
    src: url(${MuseoSansRounded});
  }

  @font-face {
    font-family: 'MuseoSans100';
    src: url(${MuseoSans100});
  }

  @font-face {
    font-family: 'MuseoSans300';
    src: url(${MuseoSans300});
  }

  @font-face {
    font-family: 'MuseoSansRounded700';
    src: url(${MuseoSansRounded700});
  }

  @font-face {
    font-family: 'MuseoSansRounded900';
    src: url(${MuseoSansRounded900});
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1;
    background-color: #f5f6fa;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    letter-spacing: normal;
    font-weight: normal;
    font-family: MuseoSansRounded700, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:invalid {
    box-shadow: none;
  }

  #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  video::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

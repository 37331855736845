/**
 *
 * TransactionDetail
 *
 */

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'rc-pagination/assets/index.css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import ReactBodymovin from 'react-bodymovin';

import '!file-loader?name=[name].[ext]!../../../images/nexticon-o.svg';

import greenDots from '../../../images/animations/green-dots.json';

import { isEmpty, toCurrency } from '../../../utils';
import messages from './messages';

import {
  Color,
  Header1,
  Header2,
  Header4,
  CaptionSmall,
  SecondaryButton,
  Panel,
  NonMobileWrapper,
  MobileWrapper,
} from '../../styleguide';

import { BackArrow, BreadCrumb } from '../../components';

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const TransactionDetailWrapper = styled.div`
  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 16px;
  }
`;

const Header = styled(Header1)`
  margin: 27px 0px 16px 8px;

  @media ${(props) => props.theme.device.mobile} {
    margin-top: 16px;
  }
`;

const OverviewWrapper = styled(Panel)`
  padding: 32px 32px 8px 32px;
  margin-bottom: 50px;

  @media ${(props) => props.theme.device.mobile} {
    padding: 24px;
  }
`;

const BreadcrumbWrapper = styled.div``;

const Row = styled.div`
  position: relative;
  padding-bottom: 12px;

  &.divider {
    border-top: 2px solid ${Color.gray05};
    margin-top: -12px;
    padding-top: 24px;
  }

  @media ${(props) => props.theme.device.mobile} {
    padding-bottom: 0px;
  }
`;

const Column = styled.div`
  float: right;
  padding: 0 24px;
  width: auto;
  border-left: 2px solid ${Color.gray05};

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    border-left: none;
    padding: 0px 0px 16px 0px;
    margin-bottom: 16px;
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const BigColumn = styled.div`
  width: auto;
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  margin-bottom: 8px;

  @media ${(props) => props.theme.device.mobile} {
    padding-right: 0px;
  }
`;

const Text = styled(Header2)`
  margin-top: 14px;
  width: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;

  @media ${(props) => props.theme.device.mobile} {
    white-space: normal;
    overflow: auto;
    text-overflow: clip;
  }
`;

const Subtext = styled(Header4)`
  margin-top: 14px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  line-height: 1.5;

  @media ${(props) => props.theme.device.mobile} {
    margin: 10px 0px 10px 0px;
  }
`;

const Additionaltext = styled(Header4)`
  margin: 10px 0px 0px 0px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  line-height: 1.5;
`;

const ResponsiveText = styled(Text)`
  @media ${(props) => props.theme.device.mobile} {
    font-size: 18px;
  }
`;

const MobileCaption = styled(CaptionSmall)`
  display: block;
  height: 24px;
  line-height: 24px;

  img {
    vertical-align: text-bottom;
    margin-right: 4px;
    width: 18px;
  }
`;

const DesktopCaption = styled(CaptionSmall)`
  display: inline-block;
`;

const Footer = styled(Row)`
  height: 60px;
  line-height: 60px;
  margin-top: -16px;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: none;
    border-bottom: 2px solid ${Color.gray05};
  }
`;

const Buttons = styled.div`
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 2px solid ${Color.gray05};
  text-align: right;

  @media ${(props) => props.theme.device.mobile} {
    border-top: none;
    margin-bottom: 16px;
    text-align: center;
  }
`;

const GotoButton = styled(SecondaryButton)`
  white-space: normal;
  height: auto;
  min-height: 48px;

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 1.5;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class TransactionDetail extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const transactionListText = intl.formatMessage({
      id: 'app.components.TransactionDetail.transactionList',
    });

    const transactionDetailText = intl.formatMessage({
      id: 'app.components.TransactionDetail.transactionDetail',
    });

    this.state = {
      transactionListText,
      transactionDetailText,
    };
  }

  isPaymentRequestPayment = (type) => type === 'PAYMENT_REQUEST';

  isRefund = (type) => type === 'REFUND';

  refundPostFix = (type) => (this.isRefund(type) ? 'Refund' : '');

  render() {
    const {
      transaction,
      showTransactionList,
      showTikkieDetail,
      showTransactionDetail,
      showPayerDetails,
      transactionIsLoading,
    } = this.props;

    const { transactionListText, transactionDetailText } = this.state;

    return (
      <TransactionDetailWrapper>
        <BreadcrumbWrapper>
          <MobileWrapper>
            <BackArrow onClick={showTransactionList} />
          </MobileWrapper>
          <NonMobileWrapper>
            <BreadCrumb
              id="backToTransactionList"
              start={transactionListText}
              onStart={showTransactionList}
              text={transactionDetailText}
            />
          </NonMobileWrapper>
        </BreadcrumbWrapper>
        <Header>
          {this.isPaymentRequestPayment(transaction.type) && (
            <FormattedMessage {...messages.headerPaymentRequestPayment} />
          )}
          {this.isRefund(transaction.type) && (
            <FormattedMessage {...messages.headerRefundPayment} />
          )}
        </Header>
        {transactionIsLoading ? (
          <AnimationWrapper>
            <ReactBodymovin
              options={{
                loop: true,
                autoplay: true,
                prerender: true,
                animationData: greenDots,
              }}
            />
          </AnimationWrapper>
        ) : (
          <OverviewWrapper>
            <NonMobileWrapper>
              <Row>
                <Column>
                  <CaptionSmall>
                    <FormattedMessage {...messages.amount} />
                  </CaptionSmall>
                  <ResponsiveText id="amount">
                    {this.isRefund(transaction.type)
                      ? `- ${toCurrency(transaction.amountInCents)}`
                      : `${toCurrency(transaction.amountInCents)}`}
                  </ResponsiveText>
                </Column>
                <Column>
                  <CaptionSmall>
                    <FormattedMessage {...messages.tikkieId} />
                  </CaptionSmall>
                  <ResponsiveText id="tikkieId">
                    {transaction.id}
                  </ResponsiveText>
                </Column>
                {showPayerDetails && (
                  <BigColumn>
                    <CaptionSmall>
                      <FormattedMessage {...messages.paidBy} />
                    </CaptionSmall>
                    <Text id="payer">{transaction.counterPartyName}</Text>
                    <Subtext id="iban">{transaction.counterPartyIban}</Subtext>
                  </BigColumn>
                )}
                {(this.isPaymentRequestPayment(transaction.type) ||
                  this.isRefund(transaction.type)) && (
                  <BigColumn>
                    <CaptionSmall>
                      <FormattedMessage {...messages.description} />
                    </CaptionSmall>
                    <Subtext id="description">
                      {transaction.description}
                    </Subtext>
                  </BigColumn>
                )}
                <BigColumn>
                  <CaptionSmall>
                    <FormattedMessage {...messages.idNumber} />
                  </CaptionSmall>
                  <Subtext id="invoice">{transaction.referenceId}</Subtext>
                </BigColumn>
              </Row>
            </NonMobileWrapper>
            <MobileWrapper>
              <Row>
                {showPayerDetails && (
                  <Column>
                    <CaptionSmall>
                      <FormattedMessage {...messages.paidBy} />
                    </CaptionSmall>
                    <Text id="payer">{transaction.counterPartyName}</Text>
                    <Additionaltext id="iban">
                      {transaction.counterPartyIban}
                    </Additionaltext>
                  </Column>
                )}
                <Column>
                  <CaptionSmall>
                    <FormattedMessage {...messages.tikkieId} />
                  </CaptionSmall>
                  <ResponsiveText id="tikkieId">
                    {transaction.id}
                  </ResponsiveText>
                </Column>
                <Column>
                  <CaptionSmall>
                    <FormattedMessage {...messages.amount} />
                  </CaptionSmall>
                  <ResponsiveText id="amount">
                    {toCurrency(transaction.amountInCents)}
                  </ResponsiveText>
                </Column>
                {this.isPaymentRequestPayment(transaction.type) && (
                  <BigColumn>
                    <CaptionSmall>
                      <FormattedMessage {...messages.description} />
                    </CaptionSmall>
                    <Subtext id="description">
                      {transaction.description}
                    </Subtext>
                  </BigColumn>
                )}
                <BigColumn>
                  <CaptionSmall>
                    <FormattedMessage {...messages.idNumber} />
                  </CaptionSmall>
                  <Subtext id="invoice">{transaction.referenceId}</Subtext>
                </BigColumn>
              </Row>
            </MobileWrapper>
            <Footer>
              <NonMobileWrapper>
                <DesktopCaption>
                  <FormattedMessage
                    {...messages[
                      `paidOn${this.refundPostFix(transaction.type)}`
                    ]}
                  />{' '}
                  {moment(transaction.created).format("D MMM 'YY, HH:mm")}{' '}
                  <FormattedMessage {...messages.hour} />
                </DesktopCaption>
              </NonMobileWrapper>
              <MobileWrapper>
                <MobileCaption>
                  <FormattedMessage
                    {...messages[
                      `paidOn${this.refundPostFix(transaction.type)}`
                    ]}
                  />{' '}
                  {moment(transaction.created).format("D MMM 'YY, HH:mm")}{' '}
                  <FormattedMessage {...messages.hour} />
                </MobileCaption>
              </MobileWrapper>
            </Footer>
            <NonMobileWrapper>
              <Buttons>
                {this.isPaymentRequestPayment(transaction.type) && (
                  <GotoButton
                    id="relatedTikkie"
                    rounded
                    onClick={() =>
                      showTikkieDetail({ token: transaction.originatingToken })
                    }
                  >
                    <img src="/nexticon-o.svg" alt="" />
                    <FormattedMessage {...messages.gotoOriginPaymentRequest} />
                  </GotoButton>
                )}
                {this.isRefund(transaction.type) && (
                  <GotoButton
                    id="relatedPayment"
                    rounded
                    onClick={() =>
                      showTransactionDetail({
                        token: transaction.originatingToken,
                      })
                    }
                  >
                    <img src="/nexticon-o.svg" alt="" />
                    <FormattedMessage {...messages.gotoOriginTikkiePayment} />
                  </GotoButton>
                )}
              </Buttons>
            </NonMobileWrapper>
            <MobileWrapper>
              <Buttons>
                {this.isPaymentRequestPayment(transaction.type) && (
                  <GotoButton
                    rounded
                    onClick={() =>
                      showTikkieDetail({ token: transaction.originatingToken })
                    }
                  >
                    <div>
                      <img src="/nexticon-o.svg" alt="" />
                      <FormattedMessage
                        {...messages.gotoOriginPaymentRequest}
                      />
                    </div>
                  </GotoButton>
                )}
                {this.isRefund(transaction.type) && (
                  <GotoButton
                    rounded
                    onClick={() =>
                      showTransactionDetail({
                        token: transaction.originatingToken,
                      })
                    }
                  >
                    <div>
                      <img src="/nexticon-o.svg" alt="" />
                      <FormattedMessage {...messages.gotoOriginTikkiePayment} />
                    </div>
                  </GotoButton>
                )}
              </Buttons>
            </MobileWrapper>
          </OverviewWrapper>
        )}
      </TransactionDetailWrapper>
    );
  }
}

TransactionDetail.propTypes = {
  intl: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  showPayerDetails: PropTypes.bool.isRequired,
  loadTransaction: PropTypes.func.isRequired,
  transactionIsLoading: PropTypes.bool.isRequired,
  transactionErrorMessage: PropTypes.string,
  showTransactionList: PropTypes.func.isRequired,
  showTikkieDetail: PropTypes.func.isRequired,
  showTransactionDetail: PropTypes.func.isRequired,
};

export default injectIntl(TransactionDetail);

import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import resetPasswordSteps from './constants';
import onboardingKYCContainerHOC from '../hoc';
import Password from '../../../components/OnboardingKYC/Password';
import { resetPassword, resetResetPassword } from '../actions';
import {
  selectResetPasswordtoken,
  selectResetPasswordSuccess,
  selectResetPasswordError,
} from '../selectors';
import FormWrapperContainer from '../FormWrapperContainer';

function ResetPasswordContainer(props) {
  const [currentStep] = useState(resetPasswordSteps.resetPassword);

  return (
    <FormWrapperContainer>
      {currentStep === resetPasswordSteps.resetPassword && (
        <Password
          sendPassword={props.resetPassword}
          resetPasswordSuccess={props.resetPasswordSuccess}
          resetPasswordError={props.resetPasswordError}
          previousStep={props.resetResetPassword}
          resetToken={props.token}
        />
      )}
    </FormWrapperContainer>
  );
}

ResetPasswordContainer.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  resetPasswordSuccess: PropTypes.bool,
  resetPasswordError: PropTypes.object,
  resetResetPassword: PropTypes.func,
  token: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  token: selectResetPasswordtoken,
  resetPasswordSuccess: selectResetPasswordSuccess,
  resetPasswordError: selectResetPasswordError,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (password, token) => dispatch(resetPassword(password, token)),
  resetResetPassword: () => dispatch(resetResetPassword()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(ResetPasswordContainer);

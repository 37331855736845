/*
 *
 * App reducer
 *
 */

import { fromJS } from 'immutable';

import {
  SAVE_TIKKIE,
  LOAD_PAYMENT_REQUEST,
  LOAD_PAYMENT_REQUEST_ERROR,
  LOAD_PAYMENT_REQUEST_SUCCESS,
  CLEAR_DATA,
} from './constants';

const initialState = fromJS({
  tikkie: {},
  paymentRequest: {},
  paymentRequestIsLoading: false,
  paymentRequestErrorMessage: null,
});

function paymentRequestReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TIKKIE:
      return state.set('tikkie', action.payload);
    case LOAD_PAYMENT_REQUEST:
      return state
        .set('paymentRequestIsLoading', true)
        .set('paymentRequestErrorMessage', null)
        .set('paymentRequest', fromJS({}));
    case LOAD_PAYMENT_REQUEST_SUCCESS:
      return state
        .set('paymentRequestIsLoading', false)
        .set('paymentRequestErrorMessage', null)
        .set('paymentRequest', fromJS(action.payload.paymentRequest));
    case LOAD_PAYMENT_REQUEST_ERROR:
      return state
        .set('paymentRequestIsLoading', false)
        .set('paymentRequestErrorMessage', action.payload.error)
        .set('paymentRequest', fromJS({}));
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

export default paymentRequestReducer;

export { initialState };

import { defineMessages } from 'react-intl';

export default defineMessages({
  terug: {
    id: 'app.components.terug',
    defaultMessage: 'Terug',
  },
  errorButton: {
    id: 'app.components.errorButton',
    defaultMessage: 'Mailtje sturen',
  },
  errorSecondaryButton: {
    id: 'app.components.errorSecondaryButton',
    defaultMessage: 'Terug naar overzicht',
  },
  errorTitle: {
    id: 'app.components.errorTitle',
    defaultMessage: 'Oeps, er gaat iets mis',
  },
  errorTextLoad: {
    id: 'app.components.errorTextLoad',
    defaultMessage:
      'Je instellingen kunnen niet getoond worden. Ons supportteam helpt je graag verder.',
  },
  errorTextNewToken: {
    id: 'app.components.errorTextNewToken',
    defaultMessage:
      'Op dit moment kun je helaas geen token aanmaken. Ons supportteam helpt je graag verder.',
  },
  errorTextModifyToken: {
    id: 'app.components.errorTextModifyToken',
    defaultMessage:
      'Op dit moment kun je helaas je token niet wijzigen. Ons supportteam helpt je graag verder.',
  },
  errorTextUnauthorized: {
    id: 'app.components.errorTextUnauthorized',
    defaultMessage:
      'Je hebt geen toegang tot de instellingen. Vraag de contactpersoon van je bedrijf voor Tikkie ons een mailtje te sturen.',
  },
  errorTextUpdate: {
    id: 'app.components.errorTextUpdate',
    defaultMessage:
      'Je wijzigingen zijn niet opgeslagen. Probeer het later nog een keer.',
  },
  errorTextContactSupport: {
    id: 'app.components.errorTextLoad',
    defaultMessage: 'Ons supportteam helpt je graag verder.',
  },
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { DayPickerRangeController, SingleDatePicker } from 'react-dates';
import { Color, PrimaryButton } from '../styleguide';

const MobileFilterHeader = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  right: 16px;
  z-index: 9999;

  height: 68px;
  line-height: 68px;
  font-size: 22px;
  padding-left: 25px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${Color.white};
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);
`;

const MobileFilterFooter = styled.div`
  position: fixed;
  height: 80px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  padding: 16px;
  z-index: 9999;

  background-color: ${Color.white};
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);

  button {
    width: 100%;
    height: 48px;
    margin: 0px;
    padding: 0px;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -11px;
  left: 0;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ed5565;
  transform: rotate(-45deg);
  background-color: #ffffff;
  color: #57565b;
  border: solid 2px #f2f2f3;
  z-index: -1;
`;

const FilterOptionsCalendarUpArrowLeft = styled(UpArrow)`
  left: -480px;

  @media ${(props) => props.theme.device.tablet} {
    left: -400px;
  }
`;

const BackArrow = styled.img`
  margin-left: 32px;
  font-size: 24px;
  height: 20px;
  width: 27px;
  color: ${Color.brandColor01};
  cursor: pointer;

  @media ${(props) => props.theme.device.mobile} {
    margin: 0;
  }
`;

const FilterClose = styled.div`
  float: right;
  font-size: 16px;
  margin-top: 2px;
  margin-right: 25px;
  color: ${Color.brandColor01};
  cursor: pointer;
`;

const FilterOptionsWrapper = styled.div`
  position: absolute;
  top: 55px;
  left: -50px;
  border-radius: 10px;
  box-shadow: 4px 10px 24px 0 rgba(0, 0, 0, 0.15);
  border: solid 2px #f2f2f3;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: none;
    border-radius: 8px;
  }
`;

const FilterOptionsCalendarWrapper = styled(FilterOptionsWrapper)`
  @media ${(props) => props.theme.device.tablet} {
    margin-left: -34px;
  }
`;

const FilterOptions = styled.div`
  position: relative;
  padding: 0px 0px 12px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #57565b;
  z-index: 999;

  @media ${(props) => props.theme.device.mobile} {
    overflow: auto;
    padding-bottom: 100px;
    padding-top: 72px;
    border-radius: 10px;
    height: 100%;
  }
`;

const CalenderStyle = styled.div`
  .CalendarMonth {
  }

  .CalendarMonth_caption {
    color: ${Color.brandColor01};
    font-size: 16px;
    margin-bottom: 16px;
  }

  .CalendarDay__default {
    font-size: 14px;
    border: none;
    outline: none;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    color: ${Color.brandColor01};
    background-color: #e0f2f1 !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    color: ${Color.white};
    background-color: ${Color.brandColor01} !important;
  }

  .DayPicker_weekHeader {
    font-size: 14px;
    margin-bottom: 8px;
    text-transform: lowercase;
  }

  .DateInput,
  .DateRangePickerInput_arrow {
    position: fixed;
    top: -200px;
  }

  .DateRangePicker_picker {
    position: relative;
    top: 16px !important;
    margin-left: -25px;
    margin-right: -55px;
  }

  .DateRangePickerInput__withBorder,
  .DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
  }

  .DayPickerNavigation {
    .DayPickerNavigation_button + .DayPickerNavigation_button {
      left: unset;
      right: 22px;
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 14px;
    left: 22px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 8px 16px;
  }

  ${(props) =>
    props.top &&
    css`
      .SingleDatePicker_picker__directionLeft {
        top: ${props.top} !important;
      }
    `}

  @media ${(props) => props.theme.device.mobile} {
    .DateRangePicker_picker {
      position: relative;
      top: 0px !important;
      margin-left: -20px;
    }

    ${(props) =>
      props.left &&
      css`
        .SingleDatePicker_picker__directionLeft {
          left: ${props.left} !important;
        }
      `}

    .DayPickerNavigation_button {
      position: absolute;
      top: 14px;
      left: 22px;
      line-height: 0.78;
      border-radius: 3px;
      padding: 8px;
    }
  }

  @media ${(props) => props.theme.device.tablet} {
    height: 128px;
  }
`;

const FilterCalendar = styled.div`
  position: relative;
  height: 450px;
  padding: 32px 0;
  background-color: #ffffff;
  border-radius: 8px;
  color: #57565b;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    padding: 0;
  }
`;

const FilterOption = styled.div`
  height: 56px;
  line-height: 56px;
  padding: 0px 24px;
  cursor: pointer;
  z-index: 9999;

  button {
    margin: 8px 0px 0px 0px;
    height: 40px;
    line-height: 30px;
  }

  @media ${(props) => props.theme.device.mobile} {
    font-size: 18px;
    margin-bottom: -8px;
  }
`;

const FilterHeader = styled(FilterOption)`
  height: 56px;
  line-height: 56px;
  font-family: MuseoSansRounded700;
  font-size: 18px;

  div {
    float: right;
    height: 56px;
    line-height: 56px;
  }
`;

const CalendarButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 32px;
  right: 32px;
  min-width: 128px;
  z-index: 99;
`;

const NavPrev = styled.img``;

const NavNext = styled.img`
  transform: rotate(180deg);
`;

export function DatePicker({
  toggle,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  date,
  setDate,
  focusedInput,
  setFocusedInput,
  disabled,
  apply,
  reset,
  isMobile,
  top,
  left,
  noMinimumNights,
}) {
  const [focused, setFocused] = useState(true);
  const isRange = !!setStartDate && !!setEndDate;
  const renderRangePicker = (numberOfMonths, orientation) => (
    <DayPickerRangeController
      startDate={startDate}
      endDate={endDate}
      /* eslint-disable no-shadow */
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(v) => setFocusedInput(v || 'startDate')}
      hideKeyboardShortcutsPanel
      numberOfMonths={numberOfMonths}
      navPrev={<NavPrev src="chevron.svg" />}
      navNext={<NavNext src="chevron.svg" />}
      isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
      initialVisibleMonth={() =>
        isMobile ? moment() : moment().subtract(1, 'month')
      }
      orientation={orientation}
      minimumNights={noMinimumNights ? 0 : 1}
    />
  );

  if (!isRange)
    return (
      <CalenderStyle top={top} left={left}>
        <SingleDatePicker
          date={date}
          onDateChange={(date) => setDate(date)}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
          numberOfMonths={1}
          initialVisibleMonth={moment}
          hideKeyboardShortcutsPanel
        />
      </CalenderStyle>
    );

  return isMobile ? (
    <FilterOptionsWrapper>
      <FilterOptions>
        <MobileFilterHeader>
          <BackArrow src="/arrow-back.svg" onClick={toggle} />
          <FilterClose onClick={reset}>Annuleren</FilterClose>
        </MobileFilterHeader>
        <MobileFilterFooter>
          <PrimaryButton
            id="applyFiltersFromCalendarButton"
            disabled={disabled}
            onClick={apply}
          >
            Toepassen
          </PrimaryButton>
        </MobileFilterFooter>
        <FilterHeader>Kies datum</FilterHeader>
        <FilterCalendar>
          <CalenderStyle>{renderRangePicker(1, 'vertical')}</CalenderStyle>
        </FilterCalendar>
      </FilterOptions>
    </FilterOptionsWrapper>
  ) : (
    <FilterOptionsCalendarWrapper>
      <FilterOptionsCalendarUpArrowLeft />
      <FilterOptions>
        <FilterCalendar>
          <CalenderStyle>
            <BackArrow src="/arrow-back.svg" onClick={() => toggle()} />
            {renderRangePicker(2, 'horizontal')}
          </CalenderStyle>
        </FilterCalendar>
        <CalendarButton disabled={disabled} onClick={apply}>
          Toepassen
        </CalendarButton>
      </FilterOptions>
    </FilterOptionsCalendarWrapper>
  );
}

DatePicker.propTypes = {
  toggle: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func,
  date: PropTypes.object,
  setDate: PropTypes.func,
  focusedInput: PropTypes.string,
  setFocusedInput: PropTypes.func,
  disabled: PropTypes.bool,
  apply: PropTypes.func,
  reset: PropTypes.func, // Mobile only
  isMobile: PropTypes.bool,
  top: PropTypes.string,
  left: PropTypes.string,
  noMinimumNights: PropTypes.bool,
};

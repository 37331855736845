import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import PaymentSettings from '../../../components/Settings/Payment';
import { loadSettings, resetUpdateSettings, updateSettings } from '../actions';
import { selectUser } from '../../App/selectors';
import {
  selectSettingsData,
  selectSettingsLoading,
  selectSettingsLoadingError,
  selectSettingsLoadingErrorStatusCode,
  selectSettingsUpdating,
  selectSettingsUpdatingError,
  selectSettingsUpdatingSuccess,
} from '../selectors';

function PaymentSettingsContainer(props) {
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(props.settingsData)) {
      props.loadSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  if (props.settingsLoading) {
    return <EmptyLoader />;
  }

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer highMargin>
          <PaymentSettings
            gotoPortalOverview={gotoPortalOverview}
            gotoSettingsDashboard={gotoSettingsDashboard}
            settingsData={props.settingsData}
            settingsLoading={props.settingsLoading}
            settingsLoadingError={props.settingsLoadingError}
            settingsLoadingErrorStatusCode={
              props.settingsLoadingErrorStatusCode
            }
            loadSettings={props.loadSettings}
            updateSettings={props.updateSettings}
            settingsUpdatingSuccess={props.settingsUpdatingSuccess}
            settingsUpdatingError={props.settingsUpdatingError}
            resetUpdateSettings={props.resetUpdateSettings}
            isUpdating={props.isUpdating}
            user={props.user}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

PaymentSettingsContainer.propTypes = {
  settingsData: PropTypes.object,
  user: PropTypes.object,
  settingsLoading: PropTypes.bool,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  loadSettings: PropTypes.func,
  updateSettings: PropTypes.func,
  settingsUpdatingSuccess: PropTypes.bool,
  settingsUpdatingError: PropTypes.bool,
  resetUpdateSettings: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  loadSettings,
  settingsLoading: selectSettingsLoading,
  settingsLoadingError: selectSettingsLoadingError,
  settingsLoadingErrorStatusCode: selectSettingsLoadingErrorStatusCode,
  settingsData: selectSettingsData,
  settingsUpdatingSuccess: selectSettingsUpdatingSuccess,
  settingsUpdatingError: selectSettingsUpdatingError,
  isUpdating: selectSettingsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
  updateSettings: (settingsData) => dispatch(updateSettings(settingsData)),
  resetUpdateSettings: () => dispatch(resetUpdateSettings()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'PaymentSettingsContainer', saga });

export default compose(withSaga, withConnect)(PaymentSettingsContainer);

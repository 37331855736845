import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/close-icon.svg';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  OnboardingParagraph,
  RadioButtonContainer,
} from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

export const AUTHORIZED_SCREEN_BACK_BUTTON_TEST_ID =
  'authorized-screen-back-button-test-id';
export const AUTHORIZED_SCREEN_INFO_BUTTON_TEST_ID =
  'authorized-screen-info-button-test-id';

interface AuthorizedToSignSDDProps {
  setAuthorizedData(state: {
    authorizedToSign: boolean;
    jointlyAuthorized?: boolean;
  }): void;
  previousStep(): void;
  organisationName: string;
  authorizedData?: {
    authorizedToSign: boolean;
  };
}

function AuthorizedToSignSDD({
  setAuthorizedData,
  previousStep,
  organisationName,
  authorizedData,
}: AuthorizedToSignSDDProps): JSX.Element {
  const { t } = useTranslation();
  const [info, setInfo] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [authorizedToSign, setAuthorizedToSign] = useState(
    authorizedData ? authorizedData.authorizedToSign : false,
  );
  useOnboardingPageView('authorized-to-sign-sdd');

  const showInfo = (): void => setInfo(!info);
  const goNext = (): void => {
    setAuthorizedData({ authorizedToSign });
  };
  const enableSoleAuthorized = (): void => {
    setAuthorizedToSign(true);
    setUntouched(false);
  };

  const enableNotAuthorized = (): void => {
    setAuthorizedToSign(false);
    setUntouched(false);
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={previousStep as () => void}
          clickRight={showInfo}
        />
        <Title>{`${t(
          'onboarding.authorizedToSignSDDScreen.title1',
        )} ${organisationName} ${t(
          'onboarding.authorizedToSignSDDScreen.title2',
        )}`}</Title>
        <RadioButtonContainer>
          <OnboardingRadioButton
            key="authorizedToSign"
            id="authorizedToSign"
            onClick={enableSoleAuthorized}
            checked={authorizedToSign}
            text={t('onboarding.authorizedToSignSDDScreen.allowed')}
            data-test-id="Onboarding-AuthorizedToSign-Yes"
          />
          <OnboardingRadioButton
            key="notAuthorizedToSign"
            id="notAuthorizedToSign"
            onClick={enableNotAuthorized}
            checked={!authorizedToSign && !untouched}
            text={t('onboarding.authorizedToSignSDDScreen.notAllowed')}
            data-test-id="Onboarding-AuthorizedToSign-No"
          />
        </RadioButtonContainer>
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            disabled={!authorizedToSign}
            onClick={goNext}
            data-test-id="Onboarding-AuthorizedToSign-NextButton"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={t('onboarding.authorizedToSignSDDScreen.infoTitle')}
        onClick={showInfo}
        fromPage={AuthorizedToSignSDD.name}
      >
        <OnboardingParagraph>
          {t('onboarding.authorizedToSignSDDScreen.infoBody1')}
        </OnboardingParagraph>
        <OnboardingParagraph>
          {t('onboarding.authorizedToSignSDDScreen.infoBody2')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

export default injectIntl(AuthorizedToSignSDD);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, FormTile, Header1, Info } from 'amber';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  BackButton,
  ErrorPageNoContact,
  SelectWrapper,
} from '../../styledComponents';
import {
  ROLE_ADMIN,
  ROLE_CONTACT_PERSON,
  ROLE_USER,
} from '../../../../global-constants';
import { uppercaseFirstLetterAndLowercaseTheRest } from '../../../../utils/strings';
import {
  getInitialErrors,
  getInitialValues,
  hasNoErrors,
  valuesUpdated,
} from './updateState';
import { isEmpty } from '../../../../utils';
import Loader from '../../../OnboardingKYC/Loader';
import { FiltersWrapper, Paragraph, statusMapper } from '../../../styleguide';
import { Delete } from './Delete';
import { Email, FirstName, LastName, Phone } from '../UserProfile';
import { UpdateFooter } from '../../UpdateFooter';
import { usePrevious } from '../../../../hooks';
import { Status } from '../../../../global-components';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsPageview,
} from '../../../../ga4';

const Header = styled(Header1)`
  padding-bottom: 24px !important;
`;

const Row = styled(FiltersWrapper)`
  @media ${(props) => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

const TitleAndStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

function UserDetail({
  user,
  userError,
  updateUser,
  userUpdateError,
  resetUpdateUser,
  userId,
  deleteUser,
  deleteUserError,
  resetPassword,
  passwordResetError,
  passwordResetSuccess,
  resetDeleteUser,
  otpEnabled,
}) {
  const history = useHistory();

  const functionalRoles = [ROLE_CONTACT_PERSON, ROLE_ADMIN, ROLE_USER];

  useEffect(() => {
    analyticsPageview();
  }, []);

  useEffect(() => () => resetUpdateUser(), [resetUpdateUser]);
  useEffect(() => () => resetDeleteUser(), [resetDeleteUser]);

  const [values, setValues] = useState(getInitialValues(user));
  const previousValues = usePrevious(values);
  const [errors, setErrors] = useState(getInitialErrors());
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const valuesChanged = valuesUpdated(values, getInitialValues(user));
  const { firstName, lastName, email, phoneNumber, functionalRole } = values;
  const upperCaseFunctionalRole = functionalRole?.toUpperCase();

  const isContactPerson = upperCaseFunctionalRole === ROLE_CONTACT_PERSON;

  useEffect(() => {
    if (previousValues && values && valuesUpdated(previousValues, values)) {
      setValues(getInitialValues(user));
    }
  }, [user]);

  const onChange = (fieldId, value) => {
    setValues((prevValues) => ({ ...prevValues, [fieldId]: value }));
  };

  const cancel = () => {
    setValues(getInitialValues(user));
    setErrors(getInitialErrors());
  };

  const renderPasswordReset = () => {
    if (isPasswordReset) {
      if (isEmpty(passwordResetError) && !isEmpty(passwordResetSuccess)) {
        return (
          <Paragraph>
            We hebben de medewerker een e-mail gestuurd om het wachtwoord te
            resetten.
          </Paragraph>
        );
      }

      if (!isEmpty(passwordResetError) && isEmpty(passwordResetSuccess)) {
        return (
          <Paragraph>Er ging iets mis. Probeer het later opnieuw.</Paragraph>
        );
      }

      return <Loader />;
    }

    return (
      <Button
        type="secondary"
        dataTestId="user-management-password-reset-button"
        onClick={() => {
          resetPassword(email);
          setIsPasswordReset(true);
        }}
      >
        Reset wachtwoord
      </Button>
    );
  };

  const goBackToListUsers = () =>
    history.push('/settings/user-management/users');

  if (
    !isEmpty(userError) ||
    !isEmpty(userUpdateError) ||
    !isEmpty(deleteUserError)
  ) {
    return ErrorPageNoContact({
      pageTitle: 'Profiel',
      showUnauthorizedDescription: false,
      onClickBackOverview: goBackToListUsers,
    });
  }

  const status = statusMapper[user.status];

  if (!values) return null;
  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton
        goBack={goBackToListUsers}
        text="Terug"
        dataTestId="user-management-user-detail-back-button"
      />
      <Row width="100%" justifyContent="space-between" marginBottom={0}>
        <TitleAndStatusWrapper>
          <Header data-test-id="header">{`Profiel ${user.firstName} ${user.lastName}`}</Header>
          {status && (
            <Status
              ml="10px"
              color={status.color}
              backgroundColor={status.backgroundColor}
            >
              {status.displayName}
            </Status>
          )}
        </TitleAndStatusWrapper>

        {!isContactPerson && (
          <Delete
            user={user}
            deleteUserFromAllOrganisations={() => {
              analyticsEvent(
                AnalyticCategories.SETTINGS,
                'business-portal-UserDetail-delete-all-button',
              );
              deleteUser(user.id, true);
            }}
            deleteUserFromOneOrganisation={() => {
              analyticsEvent(
                AnalyticCategories.SETTINGS,
                'business-portal-UserDetail-delete-current-button',
              );
              deleteUser(user.id);
            }}
          />
        )}
      </Row>
      <FirstName
        onChange={onChange}
        value={firstName}
        error={errors.firstName}
        setErrors={setErrors}
        disabled={isContactPerson}
      />
      <LastName
        onChange={onChange}
        value={lastName}
        error={errors.lastName}
        setErrors={setErrors}
        disabled={isContactPerson}
      />
      <Email value={email} disabled />
      {otpEnabled && (
        <Phone
          onChange={onChange}
          value={phoneNumber}
          error={errors.phoneNumber}
          setErrors={setErrors}
          disabled={isContactPerson}
        />
      )}
      <Container row mb={24}>
        <FormTile
          title="Rol"
          desc="Bepaal de rechten"
          id="functionalRole"
          dataTestId="user-management-switch-role"
          disabled={isContactPerson}
        >
          <Container col={11}>
            <SelectWrapper data-test-id="select-drop-down">
              <select
                value={uppercaseFirstLetterAndLowercaseTheRest(functionalRole)}
                onChange={(e) => {
                  if (!isContactPerson) {
                    onChange('functionalRole', e.target.value);
                  }
                }}
              >
                {functionalRoles
                  .filter((role) => {
                    if (isContactPerson) {
                      return role === ROLE_CONTACT_PERSON;
                    }
                    return role !== ROLE_CONTACT_PERSON;
                  })
                  .map((role) => (
                    <option
                      value={uppercaseFirstLetterAndLowercaseTheRest(role)}
                      key={role}
                    >
                      {uppercaseFirstLetterAndLowercaseTheRest(role)}
                    </option>
                  ))}
              </select>
            </SelectWrapper>
          </Container>
          <Container col={1} center>
            <Info
              dataTestId="user-management-add-user-role-info"
              desc="Een gebruiker kan alleen Tikkies aanmaken en inzien. Beslissingsbevoegden en contactpersonen kunnen ook instellingen aanpassen in de portal."
            />
          </Container>
        </FormTile>
      </Container>
      <Container row mb={24}>
        <FormTile
          title="Wachtwoord reset"
          desc="Stuur reset link naar deze medewerker"
          id="password-reset"
          dataTestId="user-management-password-reset-tile"
        >
          <Container col={11}>{renderPasswordReset()}</Container>
          <Container col={1} center>
            <Info
              dataTestId="user-management-password-reset-info"
              desc="De medewerker krijgt via e-mail een reset link, waarmee het wachtwoord gewijzigd kan worden."
            />
          </Container>
        </FormTile>
      </Container>

      <UpdateFooter
        show={valuesChanged}
        onCancel={cancel}
        onUpdate={() => {
          analyticsEvent(
            AnalyticCategories.SETTINGS,
            'business-portal-UserDetail-save-button',
          );
          updateUser({ id: userId, user: values });
        }}
        updateDisabled={!hasNoErrors(errors)}
      />
    </Container>
  );
}

UserDetail.propTypes = {
  user: PropTypes.object,
  userError: PropTypes.object,
  userUpdateError: PropTypes.object.isRequired,
  resetUpdateUser: PropTypes.func.isRequired,
  userId: PropTypes.string,
  updateUser: PropTypes.func,
  resetPassword: PropTypes.func,
  passwordResetError: PropTypes.object,
  passwordResetSuccess: PropTypes.object,
  deleteUser: PropTypes.func.isRequired,
  deleteUserError: PropTypes.object.isRequired,
  resetDeleteUser: PropTypes.func.isRequired,
  otpEnabled: PropTypes.bool,
};

export default UserDetail;

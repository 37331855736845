import { fromJS } from 'immutable';

import {
  LOAD_PAYMENTS,
  LOAD_PAYMENTS_SUCCESS,
  DOWNLOAD_SINGLE_PAYMENT_REQUEST,
  CLEAR_DATA,
  ARCHIVE_PAYMENT_REQUEST,
  LOAD_PAYMENTS_ERROR,
  PATCH_PAYMENT_REQUEST_ERROR,
  ARCHIVE_PAYMENT_REQUEST_ERROR,
} from './constants';

const initialState = fromJS({
  payments: {},
  paymentsIsLoading: false,
  paymentsErrorMessage: null,
  archivingPaymentRequest: false,
});

export default function paymentRequestDetailReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_PAYMENTS:
      return state
        .set('paymentsIsLoading', true)
        .set('paymentsErrorMessage', null)
        .set('payments', fromJS({}));
    case LOAD_PAYMENTS_SUCCESS:
      return state
        .set('paymentsIsLoading', false)
        .set('paymentsErrorMessage', null)
        .set('payments', fromJS(action.payload.payments));
    case LOAD_PAYMENTS_ERROR:
      return state
        .set('paymentsIsLoading', false)
        .set('paymentsErrorMessage', action.payload.error)
        .set('payments', fromJS({}));
    case PATCH_PAYMENT_REQUEST_ERROR:
      return state
        .set('paymentsIsLoading', false)
        .set('paymentsErrorMessage', action.payload.error)
        .set('payments', fromJS({}));
    case CLEAR_DATA:
      return initialState;
    case ARCHIVE_PAYMENT_REQUEST:
      return state.set('archivingPaymentRequest', true);
    case ARCHIVE_PAYMENT_REQUEST_ERROR:
      return state
        .set('payments', fromJS({}))
        .set('paymentsIsLoading', false)
        .set('archivingPaymentRequest', false)
        .set('paymentsErrorMessage', action.payload.error);
    case DOWNLOAD_SINGLE_PAYMENT_REQUEST:
    default:
      return state;
  }
}

export { initialState };

import { fromJS } from 'immutable';

import {
  LOAD_BULK_FILES,
  LOAD_BULK_FILES_SUCCESS,
  LOAD_BULK_FILES_ERROR,
} from './constants';

const initialState = fromJS({
  bulkFiles: [],
  totalBulkFiles: 0,
  bulkFilesIsLoading: false,
  bulkFilesErrorMessage: null,
  page: 1,
});

export default function bulkListContainerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_BULK_FILES:
      return state
        .set('bulkFilesIsLoading', true)
        .set('bulkFilesErrorMessage', null)
        .set('bulkFiles', fromJS([]))
        .set('totalBulkFiles', 0)
        .set('page', action.payload.page);
    case LOAD_BULK_FILES_SUCCESS:
      return state
        .set('bulkFilesIsLoading', false)
        .set('bulkFilesErrorMessage', null)
        .set('bulkFiles', fromJS(action.payload.bulkFiles.batches))
        .set('totalBulkFiles', fromJS(action.payload.bulkFiles.totalElements));
    case LOAD_BULK_FILES_ERROR:
      return state
        .set('bulkFilesIsLoading', false)
        .set('bulkFilesErrorMessage', action.payload.error)
        .set('bulkFiles', fromJS([]))
        .set('totalBulkFiles', 0);
    default:
      return state;
  }
}

export { initialState };

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Switch } from 'react-router';
import { useLocation, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import Loader from '../../components/Util/Loader';
import {
  ApiSettingsContainer,
  BulkCreateContainer,
  BulkDetailContainer,
  BulkListContainer,
  BulkUploadSettingsContainer,
  OldCashbackCampaignDetailContainer,
  OldCashbackCampaignsContainer,
  CashbackCampaignDetailContainer,
  CashbackCampaignsContainer,
  LoginContainer,
  MenuContainer,
  OnboardingKYCContainer,
  OrganisationSettingsContainer,
  PaymentSettingsContainer,
  PagesSettingsContainer,
  PaymentRequestCreateContainer,
  PaymentRequestDetailContainer,
  PaymentRequestListContainer,
  PaymentRequestShareContainer,
  SettingsContainer,
  SmsSettingsContainer,
  TransactionDetailContainer,
  TransactionsListContainer,
} from '..';
import { isEmpty } from '../../utils';
import config from '../../config';
import {
  selectUser,
  selectUserErrorMessage,
  selectUserIsLoading,
} from './selectors';
import { selectLoginIsSuccessful } from '../LoginContainer/selectors';
import { loadUser } from './actions';
import reducer from './reducer';
import saga from './saga';
import UserWithoutOrganisationsPage from '../UserWithoutOrganisationsPage';
import AuditLogContainer from '../SettingsContainer/AuditLogContainer';
import ListUsersContainer from '../SettingsContainer/UserManagementContainer/ListUsersContainer';
import AddUserContainer from '../SettingsContainer/UserManagementContainer/AddUserContainer';
import AddExistingUserContainer from '../SettingsContainer/UserManagementContainer/AddExistingUserContainer';
import UserDetailContainer from '../SettingsContainer/UserManagementContainer/UserDetailContainer';
import ConfirmEmailChangeContainer from '../ConfirmEmailChangeContainer';
import CurrentUserContainer from '../CurrentUserContainer';
import { usePrevious } from '../../hooks';
import DeactivationSettingsContainer from '../SettingsContainer/DeactivationSettingsContainer';
import { DeactivationBanner } from '../../components/Deactivation/deactivation-banner';

const AppWrapper = styled.div`
  height: 100%;
  overflow: ${(props) => (props.noScroll ? 'hidden' : 'inherit')};
`;

function AppContainer(props) {
  const { userIsLoading, isLoginSuccessful } = props;
  const [noScroll, setNoScroll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const excludedFromLoadUserAndLogin =
      path.substring(1, 11) === 'onboarding' ||
      path.startsWith('/verify-email');
    if (!excludedFromLoadUserAndLogin) {
      props.loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevIsLoginSuccessful = usePrevious(isLoginSuccessful);

  useEffect(() => {
    const newLoginDetected =
      isLoginSuccessful && prevIsLoginSuccessful !== isLoginSuccessful;
    if (newLoginDetected) {
      props.loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccessful]);

  const turnOffScroll = () => setNoScroll(true);
  const turnOnScroll = () => setNoScroll(false);

  const path = location.pathname;
  const isOnboarding = path.substring(1, 11) === 'onboarding';
  if (!isOnboarding && userIsLoading) {
    return <Loader />;
  }

  if (!isEmpty(props.user) && isEmpty(props.user.organisationName)) {
    return (
      <AppWrapper noScroll={noScroll} id="outer-container">
        <MenuContainer
          turnOffScroll={turnOffScroll}
          turnOnScroll={turnOnScroll}
        />
        <Switch>
          <Route exact path="/">
            <UserWithoutOrganisationsPage />
          </Route>
          {config.get(config.USER_MANAGEMENT_ENABLED) && (
            <Route exact path="/profile">
              <CurrentUserContainer />
            </Route>
          )}
          <Route exact path="/verify-email/:token">
            <ConfirmEmailChangeContainer />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </AppWrapper>
    );
  }

  return (
    <AppWrapper noScroll={noScroll} id="outer-container">
      <MenuContainer
        turnOffScroll={turnOffScroll}
        turnOnScroll={turnOnScroll}
      />
      <DeactivationBanner
        loggedIn={!isEmpty(props.user)}
        status={props.user.organisationStatus}
      />
      <Switch>
        <Route exact path="/verify-email/:token">
          <ConfirmEmailChangeContainer />
        </Route>
        <Route exact path="/login">
          <LoginContainer />
        </Route>
        <Route exact path="/reset/:token">
          <LoginContainer />
        </Route>
        <Route exact path="/password/:token">
          <LoginContainer />
        </Route>
        <Route exact path="/otp">
          <LoginContainer />
        </Route>
        <Route exact path="/">
          <PaymentRequestListContainer />
        </Route>

        <Route exact path="/create">
          <PaymentRequestCreateContainer />
        </Route>
        <Route exact path="/upload">
          <BulkCreateContainer />
        </Route>
        <Route exact path="/bulk">
          <BulkListContainer />
        </Route>
        <Route exact path="/bulk/:bulkId">
          <BulkDetailContainer />
        </Route>
        <Route exact path="/transactions">
          <TransactionsListContainer />
        </Route>
        <Route exact path="/transaction/:transactionId">
          <TransactionDetailContainer />
        </Route>
        <Route exact path="/paymentrequest/draft">
          <PaymentRequestDetailContainer />
        </Route>
        <Route exact path="/paymentrequest/:paymentRequestToken">
          <PaymentRequestDetailContainer />
        </Route>
        <Route exact path="/paymentrequest/:paymentRequestToken/share">
          <PaymentRequestShareContainer />
        </Route>
        <Route exact path="/campaigns">
          <CashbackCampaignsContainer />
        </Route>
        <Route exact path="/campaigns/:campaignId">
          <CashbackCampaignDetailContainer />
        </Route>
        <Route exact path="/tikkieterug">
          <OldCashbackCampaignsContainer />
        </Route>
        <Route exact path="/tikkieterug/:campaignId">
          <OldCashbackCampaignDetailContainer />
        </Route>
        <Route exact path="/onboarding-kyc">
          <OnboardingKYCContainer.StartOnboardingContainer />
        </Route>
        <Route exact path="/onboarding-kyc/authorized-to-sign">
          <OnboardingKYCContainer.AuthorizedToSignContainer />
        </Route>
        <Route exact path="/onboarding-kyc/authorized-to-sign-simplified">
          <OnboardingKYCContainer.AuthorizedToSignContainer simplified />
        </Route>
        <Route exact path="/onboarding-kyc/second-applicant-details">
          <OnboardingKYCContainer.SecondApplicantDetailsByFirstApplicantContainer />
        </Route>

        <Route exact path="/onboarding-kyc/nickname">
          <OnboardingKYCContainer.NickNameContainer />
        </Route>

        <Route exact path="/onboarding-kyc/email">
          <OnboardingKYCContainer.EmailContainer />
        </Route>

        <Route exact path="/onboarding-kyc/confirm-email/:token">
          <OnboardingKYCContainer.ChoosePasswordContainer />
        </Route>

        <Route exact path="/onboarding-kyc/sector">
          <OnboardingKYCContainer.BadSectorContainer />
        </Route>

        <Route exact path="/onboarding-kyc/risk-questions">
          <OnboardingKYCContainer.RiskQuestionsContainer />
        </Route>

        <Route exact path="/onboarding-kyc/ubo">
          <OnboardingKYCContainer.UboContainer />
        </Route>

        <Route exact path="/onboarding-kyc/set-contact-name">
          <OnboardingKYCContainer.ContactNameContainer />
        </Route>

        <Route exact path="/onboarding-kyc/verify-phone">
          <OnboardingKYCContainer.VerifyPhoneContainer />
        </Route>

        <Route exact path="/onboarding-kyc/birth-date">
          <OnboardingKYCContainer.BirthDateContainer />
        </Route>

        <Route exact path="/onboarding-kyc/country">
          <OnboardingKYCContainer.CountryContainer />
        </Route>

        <Route exact path="/onboarding-kyc/address">
          <OnboardingKYCContainer.AddressContainer />
        </Route>

        <Route exact path="/onboarding-kyc/contact-payment">
          <OnboardingKYCContainer.ContactPaymentContainer />
        </Route>

        <Route exact path="/onboarding-kyc/contact-payment/:paymentToken">
          <OnboardingKYCContainer.ContactPaymentContainer />
        </Route>

        <Route exact path="/onboarding-kyc/business-payment">
          <OnboardingKYCContainer.BusinessPaymentContainer />
        </Route>

        <Route exact path="/onboarding-kyc/business-payment/:paymentToken">
          <OnboardingKYCContainer.BusinessPaymentContainer />
        </Route>

        <Route exact path="/onboarding-kyc/idv">
          <OnboardingKYCContainer.IDVContainer />
        </Route>

        <Route exact path="/onboarding-kyc/idv-return/:transactionId">
          <OnboardingKYCContainer.IDVContainer />
        </Route>

        <Route exact path="/onboarding-kyc/sign-up-pending">
          <OnboardingKYCContainer.SignUpPendingContainer />
        </Route>

        <Route exact path="/onboarding-kyc/terms">
          <OnboardingKYCContainer.TermsContainer />
        </Route>

        <Route exact path="/onboarding-kyc/reset/:token">
          <OnboardingKYCContainer.ResetPasswordContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/start">
          <OnboardingKYCContainer.StartSecondApplicantContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/start/:token">
          <OnboardingKYCContainer.StartSecondApplicantContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/address">
          <OnboardingKYCContainer.SecondApplicantAddressContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/idv">
          <OnboardingKYCContainer.IDVContainer />
        </Route>

        <Route
          exact
          path="/onboarding-kyc/second-applicant/idv-return/:transactionId"
        >
          <OnboardingKYCContainer.IDVContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/terms">
          <OnboardingKYCContainer.TermsContainer />
        </Route>

        <Route exact path="/onboarding-kyc/second-applicant/end">
          <OnboardingKYCContainer.EndSecondApplicantContainer />
        </Route>

        <Route exact path="/settings/organisation">
          <OrganisationSettingsContainer />
        </Route>

        <Route exact path="/settings/payment">
          <PaymentSettingsContainer />
        </Route>

        <Route exact path="/settings/deactivate">
          <DeactivationSettingsContainer />
        </Route>

        {config.get(config.PAGES_SETTINGS_ENABLED) && (
          <Route exact path="/settings/pages">
            <PagesSettingsContainer />
          </Route>
        )}

        {config.get(config.AUDIT_LOG_ENABLED) && (
          <Route exact path="/settings/auditlog" e>
            <AuditLogContainer />
          </Route>
        )}

        {config.get(config.USER_MANAGEMENT_ENABLED) && (
          <Route exact path="/settings/user-management/users" e>
            <ListUsersContainer />
          </Route>
        )}

        {config.get(config.USER_MANAGEMENT_ENABLED) && (
          <Route exact path="/settings/user-management/add-user">
            <AddUserContainer />
          </Route>
        )}

        {config.get(config.USER_MANAGEMENT_ENABLED) && (
          <Route exact path="/settings/user-management/add-existing-user">
            <AddExistingUserContainer />
          </Route>
        )}

        {config.get(config.USER_MANAGEMENT_ENABLED) && (
          <Route exact path="/settings/user-management/user/:id">
            <UserDetailContainer />
          </Route>
        )}

        {config.get(config.USER_MANAGEMENT_ENABLED) && (
          <>
            <Route exact path="/profile">
              <CurrentUserContainer />
            </Route>
            <Route exact path="/settings/api">
              <ApiSettingsContainer />
            </Route>
            <Route exact path="/settings">
              <SettingsContainer />
            </Route>
            <Route exact path="/settings/sms">
              <SmsSettingsContainer />
            </Route>
            <Route exact path="/settings/bulkupload">
              <BulkUploadSettingsContainer />
            </Route>
          </>
        )}

        <Redirect from="*" to="/" />
      </Switch>
    </AppWrapper>
  );
}

AppContainer.propTypes = {
  user: PropTypes.object,
  userIsLoading: PropTypes.bool,
  loadUser: PropTypes.func,
  isLoginSuccessful: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  userIsLoading: selectUserIsLoading,
  userErrorMessage: selectUserErrorMessage,
  isLoginSuccessful: selectLoginIsSuccessful,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(loadUser()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'app', reducer });
const withSaga = injectSaga({ key: 'app', saga });

export default compose(withReducer, withSaga, withConnect)(AppContainer);

import {
  LOAD_TRANSACTION,
  LOAD_TRANSACTION_ERROR,
  LOAD_TRANSACTION_SUCCESS,
} from './constants';

export function loadTransaction(transactionToken) {
  return {
    type: LOAD_TRANSACTION,
    payload: {
      transactionToken,
    },
  };
}

export function loadTransactionSuccess(transaction) {
  return {
    type: LOAD_TRANSACTION_SUCCESS,
    payload: {
      transaction,
    },
  };
}

export function loadTransactionError(error) {
  return {
    type: LOAD_TRANSACTION_ERROR,
    payload: {
      error,
    },
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, PageTitle } from 'amber';
import * as messages from './messages';
import {
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
} from '../../styledComponents';
import {
  ROLE_ADMIN,
  ROLE_CONTACT_PERSON,
  ROLE_USER,
} from '../../../../global-constants';
import UserManagementFilters from '../UserManagementFilters';
import { uppercaseFirstLetterAndLowercaseTheRest } from '../../../../utils/strings';
import { List } from '../../../List';
import { UserListItem } from '../../../User/UserListItem';
import { isEmpty } from '../../../../utils';
import { GifjeContainer, GifjeWrapper } from '../../../Gif';
import { GiphyBox } from '../../../components';
import { statusMapper } from '../../../styleguide';
import { analyticsPageview } from '../../../../ga4';

function ListUsers({
  gotoPortalOverview,
  gotoSettingsDashboard,
  gotoAddUser,
  gotoAddExistingUser,
  user,
  users,
  usersLoading,
  usersError,
  fetchUsers,
  hasOtherPermittedOrganisations,
  goToUserDetails,
  permittedOrganisationsError,
}) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchText, setSearchText] = useState('');

  const hasErrors =
    !isEmpty(usersError) || !isEmpty(permittedOrganisationsError);
  const loading = usersLoading && !hasErrors;

  const pageSize = 20;

  const availableRoles = [ROLE_ADMIN, ROLE_USER, ROLE_CONTACT_PERSON];
  const roles = availableRoles.map((role) => ({
    id: role,
    label: uppercaseFirstLetterAndLowercaseTheRest(role),
  }));

  useEffect(() => {
    analyticsPageview();
  }, []);

  useEffect(() => {
    fetchUsers({
      size: pageSize,
      page: 0,
      role: selectedRole,
      search: searchText,
    });
  }, [fetchUsers, pageSize, selectedRole, searchText]);

  if (hasErrors) {
    if (
      usersError.statusCode === 403 ||
      permittedOrganisationsError.statusCode === 403
    ) {
      return ErrorPageNoContact({
        pageTitle: messages.title,
        showUnauthorizedDescription: true,
        onClickBackOverview: gotoPortalOverview,
      });
    }
    return ErrorPageWithContact({
      pageTitle: messages.title,
      errorType: 'ContactSupport',
      onClickBackOverview: gotoPortalOverview,
    });
  }

  const renderUserItem = (itemUser) => {
    const status = statusMapper[itemUser.status];
    return (
      <UserListItem
        key={itemUser.id}
        user={itemUser}
        disabled={itemUser.id === user.id}
        onClick={() => goToUserDetails(itemUser.id)}
        dataTestId="user-management-list-users-user-list-item"
        color={status.color}
      />
    );
  };

  const renderUserNoItem = () => (
    <GifjeContainer>
      <GifjeWrapper>
        <GiphyBox
          gif="no-results.gif"
          title={messages.noResultsTitle}
          text={messages.noResultsText}
        />
      </GifjeWrapper>
    </GifjeContainer>
  );

  const onPageChange = (page) => {
    fetchUsers({
      size: pageSize,
      page,
      role: selectedRole,
      search: searchText,
    });
  };

  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton
        goBack={gotoSettingsDashboard}
        text={messages.backButtonText}
        dataTestId="user-management-list-users-back-button"
      />
      <PageTitle>{messages.title}</PageTitle>
      <div style={{ flex: 1 }}>
        <UserManagementFilters
          selectedFilter={selectedRole}
          filterOptions={roles}
          searchText={searchText}
          onFilterSubmit={setSelectedRole}
          filterTitle="Rol"
          onSearch={setSearchText}
          gotoAddUser={gotoAddUser}
          gotoAddExistingUser={gotoAddExistingUser}
          hasOtherPermittedOrganisations={hasOtherPermittedOrganisations}
        />
        <List
          pagination
          loading={loading}
          pageSize={pageSize}
          totalItems={users.totalElements}
          items={users.users}
          onPageChange={onPageChange}
          renderItem={renderUserItem}
          renderNoItem={renderUserNoItem}
        />
      </div>
    </Container>
  );
}

ListUsers.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  gotoAddUser: PropTypes.func.isRequired,
  gotoAddExistingUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.shape({
    users: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  usersLoading: PropTypes.bool.isRequired,
  usersError: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  hasOtherPermittedOrganisations: PropTypes.bool,
  goToUserDetails: PropTypes.func.isRequired,
  permittedOrganisationsError: PropTypes.object.isRequired,
};

export default ListUsers;

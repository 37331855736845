import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import onboardingKYCContainerHOC from '../hoc';
import FormWrapperContainer from '../FormWrapperContainer';
import {
  selectAddressDetails,
  selectOrganisationName,
  selectPossibleAddresses,
  selectSubmitPartialAddressError,
  selectCountry,
  selectContactData,
} from '../selectors';
import { submitPartialAddress, submitAddress } from '../actions';
import addressSteps from '../../../components/OnboardingKYC/AddressInput/constants';
import AddressInput from '../../../components/OnboardingKYC/AddressInput';

const addressStepsArr = [addressSteps.address, addressSteps.selectAddress];

function AddressContainer(props) {
  const { getNextStep, getPreviousStep, country, contactData } = props;
  const dutchAddress = country
    ? country === 'NL' // from state
    : contactData.address?.countryCode === 'NL'; // from currentItem

  const [currentDutchAddressStep, setCurrentDutchAddressStep] = useState(
    addressSteps.address,
  );
  const nextDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getNextStep(addressStepsArr, currentDutchAddressStep),
    );

  const prevDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getPreviousStep(addressStepsArr, currentDutchAddressStep),
    );

  return (
    <FormWrapperContainer portalBacking>
      <AddressInput
        submitPartialAddress={props.submitPartialAddress}
        addressDetails={props.addressDetails}
        possibleAddresses={props.possibleAddresses}
        submitAddress={props.submitAddress}
        notFoundError={props.submitPartialAddressError}
        dutchAddress={dutchAddress}
        nextDutchAddressStep={nextDutchAddressStep}
        prevDutchAddressStep={prevDutchAddressStep}
        currentDutchAddressStep={currentDutchAddressStep}
      />
    </FormWrapperContainer>
  );
}

AddressContainer.propTypes = {
  submitPartialAddress: PropTypes.func.isRequired,
  addressDetails: PropTypes.object.isRequired,
  possibleAddresses: PropTypes.array.isRequired,
  submitPartialAddressError: PropTypes.bool.isRequired,
  submitAddress: PropTypes.func.isRequired,
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  country: PropTypes.string,
  contactData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  addressDetails: selectAddressDetails,
  possibleAddresses: selectPossibleAddresses,
  submitPartialAddressError: selectSubmitPartialAddressError,
  country: selectCountry,
  contactData: selectContactData,
});

const mapDispatchToProps = (dispatch) => ({
  submitPartialAddress: (address) => dispatch(submitPartialAddress(address)),
  submitAddress: (selectedAddress) => dispatch(submitAddress(selectedAddress)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(AddressContainer);

import React, { useState } from 'react';

import '!file-loader?name=[name].[ext]!../../../images/delete.svg';
import '!file-loader?name=[name].[ext]!../../../images/edit.svg';
import '!file-loader?name=[name].[ext]!../../../images/plusicon.svg';

import {
  BlokkieButton,
  BlokkieIcon,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import { formatDateOrder } from '../../../utils';
import { Color, FontSize, Radii, Space } from '../../styleguide';
import { Address } from '../AddressInput/constants';
import ButtonFooter from '../components/ButtonFooter';
import NavigationHeader from '../components/NavigationHeader';
import {
  OnboardingParagraph,
  OnboardingParagraphDescription,
  Shade,
  ToolTip,
  Wrapper,
} from '../styledComponents';
import { involvementTypes } from './Involvement/constants';
import { UboInfoScreen } from './UboInfoScreen';
import { InvolvementType } from './types';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface UboListItem {
  id: number;
  contactPerson?: boolean;
  firstName: string;
  lastNamePrefix: string;
  birthDate: string;
  address: Address;
  involvement: InvolvementType;
}

interface UboOverviewProps {
  contactPersonNickName?: string;
  uboDetailsList: Array<UboListItem>;
  stopUboFlow: () => void;
  onStartNewUbo: () => void;
  getFullName: (item: UboListItem) => string;
  onStartEdit: (item: UboListItem) => void;
  deleteUbo: (item: UboListItem) => void;
}

function UboOverview({
  contactPersonNickName,
  uboDetailsList,
  stopUboFlow,
  onStartNewUbo,
  getFullName,
  onStartEdit,
  deleteUbo,
}: UboOverviewProps): JSX.Element {
  const classes = useStyles();
  const [info, setInfo] = useState(false);

  useOnboardingPageView('ubo-overview');

  const showInfo = (): void => setInfo(!info);
  const involvementString = (involvement: InvolvementType): string =>
    involvement.types.includes(involvementTypes.owner)
      ? `${Number(involvement.ownerPercentage)}% eigenaar`
      : 'Zeggenschap';

  return (
    <>
      <Wrapper>
        <NavigationHeader clickRight={showInfo} noMarginBottom />
        <Title>{t('onboarding.ubo.overview.header')}</Title>
        <div className={classes.scrollBox}>
          {uboDetailsList
            .sort((ubo) => (ubo.contactPerson ? -1 : 1))
            .map((ubo) => (
              <div className={classes.uboInfoContainer} key={ubo.id}>
                <div className={classes.uboInfoBox}>
                  {!ubo.contactPerson && (
                    <>
                      <OnboardingParagraph className={classes.uboName}>
                        {getFullName(ubo)}
                      </OnboardingParagraph>
                      <OnboardingParagraphDescription
                        className={classes.uboInfo}
                      >
                        {involvementString(ubo.involvement)} •{' '}
                        {formatDateOrder(ubo.birthDate)}
                      </OnboardingParagraphDescription>
                    </>
                  )}
                  {ubo.contactPerson && (
                    <>
                      <OnboardingParagraph className={classes.uboName}>
                        {contactPersonNickName}
                      </OnboardingParagraph>
                      <OnboardingParagraphDescription
                        className={classes.uboInfo}
                      >
                        {involvementString(ubo.involvement)}
                      </OnboardingParagraphDescription>
                    </>
                  )}
                </div>
                <div className={classes.iconBox}>
                  <ToolTip
                    className={classes.smallToolTip}
                    onClick={() => deleteUbo(ubo)}
                  >
                    <img src="/delete.svg" alt="Delete" />
                  </ToolTip>
                  {!ubo.contactPerson && (
                    <ToolTip
                      className={classes.smallToolTip}
                      onClick={() => onStartEdit(ubo)}
                    >
                      <img src="/edit.svg" alt="Edit" />
                    </ToolTip>
                  )}
                </div>
              </div>
            ))}
        </div>
        <Shade />
        <ButtonFooter
          customClass={classes.buttonFooter}
          forceAlign="space-between"
        >
          <BlokkieButton
            onClick={() => onStartNewUbo()}
            data-test-id="Onboarding-UboOverview-AddButton"
            id="button"
            variant="secondary"
            size="large"
          >
            <React.Fragment key=".0">
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                }}
              >
                <BlokkieIcon variant="plus" />
                <BlokkieTypography
                  style={{
                    color: blokkieTheme.colors.primary.green,
                  }}
                  variant="pl700"
                >
                  {t('add')}
                </BlokkieTypography>
              </div>
            </React.Fragment>
          </BlokkieButton>

          <BlokkieButton
            onClick={() => stopUboFlow()}
            data-test-id="Onboarding-UboOverview-NextButton"
            id="button"
            variant="primary"
            size="large"
          >
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.grey.white,
              }}
              variant="pl700"
            >
              {t('noNext')}
            </BlokkieTypography>
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <UboInfoScreen
        show={info}
        showInfo={showInfo}
        fromPage={UboOverview.name}
      />
    </>
  );
}

const useStyles = createUseStyles({
  scrollBox: {
    height: '217px',
    padding: '0px 2px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: Radii.s4,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&::after': {
      display: 'block',
      height: '20px',
      content: '""',
    },
  },
  uboInfoContainer: {
    width: '100%',
    height: '77px',
    objectFit: 'contain',
    borderRadius: Radii.s8,
    boxShadow: '0 2px 4px 3px rgba(0, 0, 0, 0.05)',
    flexDirection: 'row',
    display: 'flex',
    marginTop: Space.s8,
    marginBottom: Space.s16,
  },
  uboInfoBox: {
    width: '85%',
    paddingLeft: Space.s16,
    paddingTop: Space.s8,
  },
  uboName: {
    color: blokkieTheme.colors.primary.green,
    fontWeight: 'bold',
    fontSize: FontSize.s18,
    marginBottom: Space.s0,
  },
  uboInfo: {
    color: Color.gray02,
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '15%',
  },
  smallToolTip: {
    width: '22px',
    height: '22px',
    position: 'relative',
  },
  buttonFooter: {
    '& #button': {},
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    buttonFooter: {
      '& #button': {
        padding: '12px 14px',
      },
    },
  },
});

export default UboOverview;

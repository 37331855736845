import React, { useEffect, useState } from 'react';
import { Container, Card } from 'amber';
import {
  BlokkieTypography,
  BlokkieButton,
  blokkieTheme,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BackButton, ErrorPageNoContact } from '../styledComponents';
import { CheckButton } from '../../components';
import DeactivationConfirmation from './DeactivationConfirmation';
import { isEmpty } from '../../../utils';
import { resetDeactivation } from '../../../containers/SettingsContainer/actions';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsPageview,
} from '../../../ga4';

// eslint-disable-next-line no-shadow
enum PredefinedReasons {
  UNUSED = 'UNUSED',
  MISSING_FUNCTIONALITIES = 'MISSING_FUNCTIONALITIES',
  OTHER_PAYMENT_SOLUTION = 'OTHER_PAYMENT_SOLUTION',
  EXPENSIVE = 'EXPENSIVE',
  NOT_AWARE_OF_COSTS = 'NOT_AWARE_OF_COSTS',
}

interface DeactivationProps {
  gotoSettingsDashboard(): void;
  deactivateOrganisation(motivation: string[]): void;
  settingsData: { status: string; id: number };
  deactivationError: object;
  deactivationSuccess: object;
}

function Deactivation({
  gotoSettingsDashboard,
  settingsData,
  deactivateOrganisation,
  deactivationError,
  deactivationSuccess,
}: DeactivationProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deactivationReasons, setDeactivationReasons] = useState<string[]>([]);
  const [operationKey, setOperationKey] = useState<boolean>(false);
  const [expensiveKey, setExpensiveKey] = useState<boolean>(false);
  const [costsKey, setCostsKey] = useState<boolean>(false);
  const [competitionKey, setCompetitionKey] = useState<boolean>(false);
  const [unusedKey, setUnusedKey] = useState<boolean>(false);
  const [otherKey, setOtherKey] = useState<boolean>(false);
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
  const [otherReasonValue, setOtherReasonValue] = useState<string>('');

  useEffect(() => {
    analyticsPageview();
  }, []);

  useEffect(() => {
    if (settingsData.status !== 'ACTIVE') {
      setShowConfirmation(true);
    }
  }, [settingsData]);

  const goToSettingsOverview = () => {
    gotoSettingsDashboard();
  };

  const enableOrDisableOperationKey = () => {
    setOperationKey(!operationKey);
    regulateDeactivationReasonsList(PredefinedReasons.MISSING_FUNCTIONALITIES);
  };

  const enableOrDisableExpensiveKey = () => {
    setExpensiveKey(!expensiveKey);
    regulateDeactivationReasonsList(PredefinedReasons.EXPENSIVE);
  };

  const enableOrDisableCostAwarenessKey = () => {
    setCostsKey(!costsKey);
    regulateDeactivationReasonsList(PredefinedReasons.NOT_AWARE_OF_COSTS);
  };

  const enableOrDisableCompetitionKey = () => {
    setCompetitionKey(!competitionKey);
    regulateDeactivationReasonsList(PredefinedReasons.OTHER_PAYMENT_SOLUTION);
  };

  const enableOrDisableUnusedKey = () => {
    setUnusedKey(!unusedKey);
    regulateDeactivationReasonsList(PredefinedReasons.UNUSED);
  };

  const enableOrDisableOtherKey = () => {
    setOtherKey(!otherKey);
  };

  const enableOrDisableAgreeTerms = () => {
    setAgreeTerms(!agreeTerms);
  };

  const isCheckboxChecked = (key: boolean) => (key ? 'yes' : 'no');

  const removeKeyFromDeactivationReasonsList = (keyToRemove: string) => {
    const newArray = deactivationReasons;
    const indexToRemove = newArray.indexOf(keyToRemove);
    if (indexToRemove > -1) {
      newArray.splice(indexToRemove, 1);
    }
    setDeactivationReasons(newArray);
  };

  const addKeyToDeactivationReasonsList = (keyToAdd: string) => {
    const newArray = deactivationReasons;
    newArray.push(keyToAdd);
    setDeactivationReasons(newArray);
  };

  const regulateDeactivationReasonsList = (reason: string) => {
    if (!deactivationReasons.includes(reason)) {
      addKeyToDeactivationReasonsList(reason);
    } else {
      removeKeyFromDeactivationReasonsList(reason);
    }
  };

  const isAllowedToDeactivate = () => {
    if (
      agreeTerms &&
      (deactivationReasons.length > 0 || otherReasonValue.length > 0)
    ) {
      return true;
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeReason = (event: any) => {
    const reason = event.target.value;
    setOtherReasonValue(reason);
  };

  const sendDeactivationRequest = () => {
    if (otherReasonValue.length > 0) {
      addKeyToDeactivationReasonsList(otherReasonValue);
    }

    deactivateOrganisation(deactivationReasons);
  };

  useEffect(() => {
    if (!isEmpty(deactivationSuccess)) {
      const predefinedValueList: string[] = Object.values(PredefinedReasons);

      deactivationReasons.forEach((key) => {
        analyticsEvent(
          'business-cancel-subscription',
          AnalyticCategories.SETTINGS,
          'settings-event',
          {
            predefined: predefinedValueList.includes(key),
            reason: key,
          },
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivationSuccess]);

  if (!isEmpty(deactivationError)) {
    return (
      <ErrorPageNoContact
        onClickBackOverview={() => {
          dispatch(resetDeactivation());
          goToSettingsOverview();
        }}
      />
    );
  }

  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <BackButton goBack={goToSettingsOverview} text={t('back')} />
      <BlokkieTypography
        variant="h1"
        style={{ color: blokkieTheme.colors.grey.veryDark }}
      >
        {t('portal.deactivation.request.title')}
      </BlokkieTypography>
      {showConfirmation && <DeactivationConfirmation />}
      {!showConfirmation && (
        <div>
          <Container row mt={28}>
            <Card>
              <Container row mb={14}>
                <BlokkieTypography
                  variant="ps500"
                  style={{ color: blokkieTheme.colors.grey.default }}
                >
                  {t('portal.deactivation.request.descriptionTitle')}
                </BlokkieTypography>
              </Container>

              <Container row mb={24}>
                <BlokkieTypography
                  variant="pl500"
                  style={{ color: blokkieTheme.colors.grey.veryDark }}
                >
                  {t('portal.deactivation.request.description')}
                </BlokkieTypography>
              </Container>

              <Container row mb={14}>
                <BlokkieTypography
                  variant="pl500"
                  style={{ color: blokkieTheme.colors.grey.veryDark }}
                >
                  {t('portal.deactivation.request.description1')}
                </BlokkieTypography>
              </Container>
            </Card>
          </Container>
          <Container mt={15}>
            <Card>
              <Container row mb={10}>
                <BlokkieTypography
                  variant="pl700"
                  style={{ color: blokkieTheme.colors.grey.veryDark }}
                >
                  {t('portal.deactivation.request.reasonTitle')}
                </BlokkieTypography>
              </Container>

              <Container row>
                <div className={classes.descriptionContainer}>
                  <BlokkieTypography
                    variant="ps500"
                    style={{
                      width: '192px',
                      marginRight: '55px',
                      color: blokkieTheme.colors.grey.default,
                    }}
                  >
                    {t('portal.deactivation.request.reasonDescription')}
                  </BlokkieTypography>

                  <div className={classes.checkboxes}>
                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(unusedKey)}
                        onClick={enableOrDisableUnusedKey}
                      />
                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t('portal.deactivation.request.reasonUnused')}
                      </BlokkieTypography>
                    </div>

                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(operationKey)}
                        onClick={enableOrDisableOperationKey}
                      />

                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t('portal.deactivation.request.reasonNotFunctioning')}
                      </BlokkieTypography>
                    </div>

                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(competitionKey)}
                        onClick={enableOrDisableCompetitionKey}
                      />
                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t(
                          'portal.deactivation.request.reasonUsingOtherBankApp',
                        )}
                      </BlokkieTypography>
                    </div>

                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(expensiveKey)}
                        onClick={enableOrDisableExpensiveKey}
                        dataTestId="expensive-checkbox"
                      />

                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t('portal.deactivation.request.reasonExpensive')}
                      </BlokkieTypography>
                    </div>

                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(costsKey)}
                        onClick={enableOrDisableCostAwarenessKey}
                      />
                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t(
                          'portal.deactivation.request.reasonNotAwareOfCostsKey',
                        )}
                      </BlokkieTypography>
                    </div>

                    <div className={classes.checkboxWithInputWrapper}>
                      <div className={classes.checkBoxWrapper}>
                        <CheckButton
                          checked={isCheckboxChecked(otherKey)}
                          onClick={enableOrDisableOtherKey}
                        />

                        <BlokkieTypography
                          variant="pl500"
                          style={{ color: blokkieTheme.colors.grey.veryDark }}
                        >
                          {t('portal.deactivation.request.reasonOther')}
                        </BlokkieTypography>
                      </div>

                      <input
                        className={classes.checkboxInput}
                        maxLength={200}
                        disabled={!otherKey}
                        placeholder={t(
                          'portal.deactivation.request.reasonOtherInputPlaceholder',
                        )}
                        onChange={onChangeReason}
                      />
                    </div>
                  </div>
                </div>
              </Container>

              <Container mt={21}>
                <div className={classes.divider} />
              </Container>

              <Container row mt={32}>
                <div className={classes.agreementContainer}>
                  <div className={classes.checkboxes}>
                    <div className={classes.checkBoxWrapper}>
                      <CheckButton
                        checked={isCheckboxChecked(agreeTerms)}
                        onClick={enableOrDisableAgreeTerms}
                        dataTestId="agreement-checkbox"
                      />

                      <BlokkieTypography
                        variant="pl500"
                        style={{ color: blokkieTheme.colors.grey.veryDark }}
                      >
                        {t('portal.deactivation.request.agreement')}
                      </BlokkieTypography>
                    </div>
                    <div className={classes.buttonsWrapper}>
                      <BlokkieButton
                        variant="secondary"
                        onClick={goToSettingsOverview}
                      >
                        {t('portal.deactivation.request.cancel')}
                      </BlokkieButton>
                      <BlokkieButton
                        variant="primary"
                        disabled={!isAllowedToDeactivate()}
                        onClick={sendDeactivationRequest}
                        data-test-id="deactivate-button"
                      >
                        {t('portal.deactivation.request.unsubscribeButton')}
                      </BlokkieButton>
                    </div>
                  </div>
                </div>
              </Container>
            </Card>
          </Container>
        </div>
      )}
    </Container>
  );
}

const useStyles = createUseStyles({
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',

    [blokkieTheme.mediaQueries.allPhone]: {
      flexDirection: 'column',
      gap: blokkieTheme.spacing(3),
    },
  },
  agreementContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: blokkieTheme.spacing(31),
    marginRight: blokkieTheme.spacing(12.5),

    [blokkieTheme.mediaQueries.allPhone]: {
      flexDirection: 'column',
      gap: blokkieTheme.spacing(3),
      marginLeft: '0',
      marginRight: '0',
    },
  },
  description: {
    width: blokkieTheme.spacing(24),
    marginRight: blokkieTheme.spacing(7),
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    gap: blokkieTheme.spacing(2),
  },
  checkboxWithInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: blokkieTheme.spacing(2),
  },
  checkBoxWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: blokkieTheme.spacing(4),
  },
  checkboxInput: {
    display: 'flex',
    padding: '12px 16px',
    alignSelf: 'stretch',
    borderRadius: '6px',
    border: `2px solid ${blokkieTheme.colors.grey.light}`,
    background: blokkieTheme.colors.grey.white,
    width: '100%',
  },
  divider: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: blokkieTheme.colors.grey.veryLight,
    height: '2px',
    width: '100%',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: blokkieTheme.spacing(7.5),
    marginTop: blokkieTheme.spacing(4.25),

    [blokkieTheme.mediaQueries.allPhone]: {
      marginTop: '0',
      flexDirection: 'column',
      gap: blokkieTheme.spacing(2.75),
    },
  },
  checkbox: {
    cursor: 'pointer',
    width: blokkieTheme.spacing(3),
    height: blokkieTheme.spacing(3),
    lineHeight: blokkieTheme.spacing(2.25),
    borderRadius: blokkieTheme.spacing(0.5),
  },
  unchecked: {
    border: `solid 2px #e9e9e9`,
    backgroundColor: `#f7f7f9`,
  },
  checked: {
    border: `solid 2px ${blokkieTheme.colors.primary.green}`,
    backgroundColor: `${blokkieTheme.colors.primary.green}`,
  },
  checkboxImage: {
    width: blokkieTheme.spacing(2),
    height: blokkieTheme.spacing(2),
    marginLeft: blokkieTheme.spacing(0.5),
    marginTop: blokkieTheme.spacing(0.5),
  },
});

export default Deactivation;

export const selectIsSendingSms = (state) =>
  state.get('paymentRequest/shareContainer').get('isSendingSms');
export const selectSmsSendError = (state) =>
  state.get('paymentRequest/shareContainer').get('smsSendError');
export const selectFetchingQRCode = (state) =>
  state.get('paymentRequest/shareContainer').get('fetchingQRCode');
export const selectFetchQRCodeError = (state) =>
  state.get('paymentRequest/shareContainer').get('fetchQRCodeError');
export const selectQRCode = (state) =>
  state.get('paymentRequest/shareContainer').get('qrCode');

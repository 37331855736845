import { defineMessages } from 'react-intl';

export default defineMessages({
  checkEmail: {
    id: 'app.components.CheckYourEmail.checkEmail',
    defaultMessage: '✉️ Check je mail!',
  },
  zoWeten: {
    id: 'app.components.CheckYourEmail.zoWeten',
    defaultMessage: 'Zo weten we zeker dat jij toegang hebt tot',
  },
  emailNotReceived: {
    id: 'app.components.CheckYourEmail.emailNotReceived',
    defaultMessage: 'Niets ontvangen? Check je spamfolder.',
  },
  infoScreenTitle: {
    id: 'app.components.CheckYourEmail.infoScreenTitle',
    defaultMessage: 'Geen e-mail ontvangen?',
  },
  infoScreenBody1: {
    id: 'app.components.CheckYourEmail.infoScreenBody1',
    defaultMessage:
      'Check je spamfolder. Als het nog steeds niet lukt, dan kun je ook ',
  },
  infoScreenBody2: {
    id: 'app.components.CheckYourEmail.infoScreenBody2',
    defaultMessage: 'een ander e-mailadres invullen.',
  },
});

export const branchListKeys = {
  sectorDefense: 'sectorDefense',
  sectorOverboeking: 'sectorOverboeking',
  sectorBeleggingsmaatschappijen: 'sectorBeleggingsmaatschappijen',
  sectorCommercieelVastgoed: 'sectorCommercieelVastgoed',
  sectorJewelry: 'sectorJewelry',
  sectorGaming: 'sectorGaming',
  sectorUsedProducts: 'sectorUsedProducts',
  sectorSports: 'sectorSports',
  sectorVirtualMoney: 'sectorVirtualMoney',
  sectorAntique: 'sectorAntique',
  sectorOil: 'sectorOil',
  sectorNPO: 'sectorNPO',
  sectorRetail: 'sectorRetail',
  sectorCoffeeshops: 'sectorCoffeeshops',
  sectorAgents: 'sectorAgents',
  sectorTrust: 'sectorTrust',
};

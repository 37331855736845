import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popup } from 'amber';

import {
  PrimaryButton,
  Radii,
  SecondaryButton,
  Header3,
  Space,
} from '../../styleguide';
import messages from './messages';
import Loader from '../../OnboardingKYC/Loader';

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s ease-in;
  opacity: ${(props) => (props.animate ? 1 : 0)};
  position: relative;
  left: -200px;
  top: 24px;
`;

const ConfirmText = styled(Header3)`
  margin-bottom: ${Space.s16};
`;

const NoButton = styled(SecondaryButton)`
  border-radius: ${Radii.s24};
  margin-left: ${Space.s8};
`;

const PopupWithButtons = (props) => (
  <PopupWrapper animate>
    <Popup small show>
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <ConfirmText>{messages.confirm.defaultMessage}</ConfirmText>
          <PrimaryButton onClick={props.onYes}>Ja</PrimaryButton>
          <NoButton onClick={props.onNo}>Nee</NoButton>
        </>
      )}
    </Popup>
  </PopupWrapper>
);

PopupWithButtons.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PopupWithButtons;

/*
 * ApiSettings Messages
 *
 * This contains all the text for the ApiSettings component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ApiSettings.header',
    defaultMessage: 'API tokens voor ',
  },
  headerNew: {
    id: 'app.components.ApiSettings.headerNew',
    defaultMessage: 'Nieuw token aanmaken',
  },
  terug: {
    id: 'app.components.SettingsDashboard.terug',
    defaultMessage: 'API overzicht',
  },
  apiSettings: {
    id: 'app.components.ApiSettings.apiSettings',
    defaultMessage: 'API tokens beheren ',
  },
  createApiToken: {
    id: 'app.components.ApiSettings.changedOn',
    defaultMessage: 'API token toevoegen',
  },
  descriptionTitle: {
    id: 'app.components.ApiSettings.descriptionTitle',
    defaultMessage: 'Omschrijving',
  },
  descriptionText: {
    id: 'app.components.ApiSettings.descriptionText',
    defaultMessage:
      'Wat kan ik hier doen? Een API (Application Programming Interfaces) is een handige manier om lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis risus at mauris molestie maximus. Suspendisse interdum dolor ullamcorper enim mollis, quis.\n',
  },
  descriptionTextApiToken: {
    id: 'app.components.ApiSettings.descriptionTextApiToken',
    defaultMessage: 'Zodat je ‘m later kunt herkennen',
  },
  placeholderApiToken: {
    id: 'app.components.ApiSettings.placeholderApiToken',
    defaultMessage: 'Bijv. ”Voor onze mobile app”',
  },
  addTokensTitle: {
    id: 'app.components.ApiSettings.changeNameTitle',
    defaultMessage: 'heeft (nog) geen API tokens',
  },
  addTokensText: {
    id: 'app.components.ApiSettings.changeNameTitle',
    defaultMessage: 'Creeër je eerste token met een druk op de groene knop.',
  },
  has: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'heeft',
  },
  apiTokens: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'API tokens',
  },
  manualTitle1: {
    id: 'app.components.ApiSettings.manualTitle1',
    defaultMessage: 'Aanmaken betaalverzoekjes',
  },
  manualText1: {
    id: 'app.components.ApiSettings.manualText1',
    defaultMessage:
      'Als deze mogelijkheid aan staat kunnen betaalverzoekjes aangemaakt worden namens jouw bedrijf. Het is mogelijk om gegevens over deze betaalverzoekjes en de daarop ontvangen betalingen op te halen.',
  },
  manualTitle2: {
    id: 'app.components.ApiSettings.manualTitle2',
    defaultMessage: 'Terugbetaling betaalde verzoekjes',
  },
  manualText2: {
    id: 'app.components.ApiSettings.manualText2',
    defaultMessage:
      'Als deze mogelijkheid aan staat is het mogelijk om een terugbetaling aan te maken op een betaald verzoekje. Deze zal uitbetaald worden uit de binnengekomen inkomsten van deze dag. Het is hiervoor wel nodig om gebundelde uitbetaling aan te hebben staan voor jouw organisatie.',
  },
  manualTitle3: {
    id: 'app.components.ApiSettings.manualTitle3',
    defaultMessage: 'Notificatie van gebundelde uitbetalingen',
  },
  manualText3: {
    id: 'app.components.ApiSettings.manualText3',
    defaultMessage:
      'Als deze mogelijkheid aan staat kun je notificaties ontvangen wanneer een gebundelde uitbetaling heeft plaatsgevonden. Daarnaast is het mogelijk om het CAMT.053 bestand van de gebundelde uitbetalingen op te halen.',
  },
  manualTitle5: {
    id: 'app.components.ApiSettings.manualTitle5',
    defaultMessage: 'Tikkie Terug (geldterugacties)',
  },
  manualText5: {
    id: 'app.components.ApiSettings.manualText5',
    defaultMessage:
      'Je klanten snel hun geld terug, jij altijd direct inzicht. Je ziet in één overzicht hoe je campagne het doet en hoeveel geld er nog over is. Ook kun je een token aanmaken voor individuele uitbetalingen. Ook van deze token haal je eenvoudig de gegevens op en kun je een berichtje ontvangen als de betaling is gedaan.',
  },
  manual: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'Handleiding',
  },
});

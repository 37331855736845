import { takeLatest, call, put, all } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { push } from 'connected-react-router/immutable';

import request from '../../../utils/request';
import config from '../../../config';
import {
  ARCHIVE_PAYMENT_REQUEST,
  DOWNLOAD_SINGLE_PAYMENT_REQUEST,
  LOAD_PAYMENTS,
  PATCH_PAYMENT_REQUEST,
} from './constants';

import {
  loadPaymentsError,
  loadPaymentsSuccess,
  editPaymentRequestError,
  archivePaymentRequestError,
} from './actions';
import { loadPaymentRequestError, loadPaymentRequestSuccess } from '../actions';
import { resetPaymentRequests } from '../PaymentRequestListContainer/actions';

import paymentRequestSagas from '../saga';
import { LOAD_PAYMENT_REQUEST } from '../constants';

export default function* watcherSaga() {
  yield all([
    ...paymentRequestSagas,
    takeLatest(LOAD_PAYMENT_REQUEST, loadPaymentRequestSaga),
    takeLatest(LOAD_PAYMENTS, loadPaymentsSaga),
    takeLatest(
      DOWNLOAD_SINGLE_PAYMENT_REQUEST,
      downloadSinglePaymentRequestSaga,
    ),
    takeLatest(ARCHIVE_PAYMENT_REQUEST, archivePaymentRequestSaga),
    takeLatest(PATCH_PAYMENT_REQUEST, updateSinglePaymentExpiryDateSaga),
  ]);
}

export function* loadPaymentRequestSaga(action) {
  const { paymentRequestToken } = action.payload;

  const requestUrl = `${config.API_URL}/paymentinformation/${paymentRequestToken}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadPaymentRequestSuccess(response));
  } catch (err) {
    yield put(loadPaymentRequestError(err.message));
  }
}

export function* loadPaymentsSaga(action) {
  const { page, paymentRequestToken } = action.payload;

  const currentPage = page - 1;

  const requestUrl = `${config.API_URL}/paymentinformation/${paymentRequestToken}/payments?page=${currentPage}&size=20`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadPaymentsSuccess(response));
  } catch (err) {
    yield put(loadPaymentsError(err.message));
  }
}

export function* updateSinglePaymentExpiryDateSaga(action) {
  const { token, expireDate } = action.payload;
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/paymentrequest/${token}`;
  try {
    yield call(request, requestUrl, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify({
        expiry: expireDate.toISOString().substring(0, 10),
      }),
    });
    yield put(resetPaymentRequests());
  } catch (err) {
    yield put(editPaymentRequestError(err.message));
  }
}

export function* downloadSinglePaymentRequestSaga(action) {
  const { token } = action.payload;
  const requestUrl = `${config.API_URL}/csv/${token}`;
  window.location = requestUrl;
}

export function* archivePaymentRequestSaga(action) {
  const { token } = action.payload;
  const cookies = new Cookies();
  const requestUrl = `${config.API_URL}/paymentrequest/${token}`;
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  try {
    yield call(request, requestUrl, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify({ status: 'CLOSED' }),
    });
    yield put(resetPaymentRequests());
    yield delay(1000);
    yield put(push('/'));
  } catch (err) {
    yield put(archivePaymentRequestError(err.message));
  }
}

/*
 * CreateTikkie Messages
 *
 * This contains all the text for the CreateTikkie component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.PaymentRequestCreate.header',
    defaultMessage: 'Nieuwe Tikkie',
  },
  back: {
    id: 'app.components.PaymentRequestCreate.back',
    defaultMessage: 'Terug',
  },
  validUntil: {
    id: 'app.components.PaymentRequestCreate.validUntil',
    defaultMessage: 'Geldig t/m',
  },
  open: {
    id: 'app.components.PaymentRequestCreate.open',
    defaultMessage: 'Open',
  },
  amount: {
    id: 'app.components.PaymentRequestCreate.amount',
    defaultMessage: 'Bedrag',
  },
  amountPlaceholder: {
    id: 'app.components.PaymentRequestCreate.amountPlaceholder',
    defaultMessage: '0,00',
  },
  description: {
    id: 'app.components.PaymentRequestCreate.description',
    defaultMessage: 'Omschrijving',
  },
  descriptionCaption: {
    id: 'app.components.PaymentRequestCreate.descriptionCaption',
    defaultMessage: 'Voor de ontvanger',
  },
  descriptionPlaceholder: {
    id: 'app.components.PaymentRequestCreate.descriptionPlaceholder',
    defaultMessage: 'Bijv. "Donatie"',
  },
  invoice: {
    id: 'app.components.PaymentRequestCreate.invoice',
    defaultMessage: 'Kenmerk',
  },
  invoiceCaption: {
    id: 'app.components.PaymentRequestCreate.invoiceCaption',
    defaultMessage: 'Voor je eigen administratie',
  },
  invoicePlaceholder: {
    id: 'app.components.PaymentRequestCreate.invoicePlaceholder',
    defaultMessage: 'Bijv. "Factuur-1234"',
  },
  openTikkieText: {
    id: 'app.components.PaymentRequestCreate.openTikkieText',
    defaultMessage: 'Laat de ontvanger dit zelf bepalen',
  },
  createTikkie: {
    id: 'app.components.PaymentRequestCreate.createTikkie',
    defaultMessage: 'Verzoekje aanmaken',
  },
  calendarHeader: {
    id: 'app.components.PaymentRequestCreate.calendarHeader',
    defaultMessage: 'Selecteer datum',
  },
  dateOneWeek: {
    id: 'app.components.PaymentRequestCreate.dateOneWeek',
    defaultMessage: '1 week',
  },
  dateTwoWeeks: {
    id: 'app.components.PaymentRequestCreate.dateTwoWeeks',
    defaultMessage: '2 weken',
  },
  dateOneMonth: {
    id: 'app.components.PaymentRequestCreate.dateOneMonth',
    defaultMessage: '1 maand',
  },
  dateSixMonths: {
    id: 'app.components.PaymentRequestCreate.dateSixMonths',
    defaultMessage: '6 maanden',
  },
  dateOneYear: {
    id: 'app.components.PaymentRequestCreate.dateOneYear',
    defaultMessage: '1 jaar',
  },
  dateOther: {
    id: 'app.components.PaymentRequestCreate.dateOther',
    defaultMessage: 'Anders...',
  },
  applyDate: {
    id: 'app.components.PaymentRequestCreate.applyDate',
    defaultMessage: 'Klaar',
  },
  next: {
    id: 'app.components.PaymentRequestCreate.next',
    defaultMessage: 'Volgende',
  },
  addInvoiceNumber: {
    id: 'app.components.PaymentRequestCreate.addInvoiceNumber',
    defaultMessage: 'Kenmerk toevoegen',
  },
  errorTitle: {
    id: 'app.components.PaymentRequestCreate.errorTitle',
    defaultMessage: 'Oeps, aanmaken niet gelukt',
  },
  errorText: {
    id: 'app.components.PaymentRequestCreate.errorText',
    defaultMessage:
      'Op dit moment kun je helaas geen Tikkie aanmaken. Gelukkig staat ons support team klaar om het op te lossen.',
  },
  errorButton: {
    id: 'app.components.PaymentRequestCreate.errorButton',
    defaultMessage: 'Stuur ons een mailtje',
  },
  errorSecondaryButton: {
    id: 'app.components.PaymentRequestCreate.errorSecondaryButton',
    defaultMessage: 'Terug naar overzicht',
  },
});

import React from 'react';
import { injectIntl } from 'react-intl';

import '!file-loader?name=[name].[ext]!../../../images/videoGifs/handshake.mp4';

import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import ButtonFooter from '../components/ButtonFooter';
import { CenterWrapper, Wrapper } from '../styledComponents';
import Title from '../Title';

interface FFStartExplanationProps {
  nextStep: () => void;
}

function FFStartExplanation(props: FFStartExplanationProps): JSX.Element {
  const { nextStep } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Wrapper>
      <CenterWrapper {...props}>
        <div className={classes.gifContainer}>
          <BlokkieGifContainer source="../handshake.mp4" />
        </div>
        <Title textAlign="center">
          {t('onboarding.fluentflow.startExplanation.header')}
        </Title>
        <BlokkieTypography
          variant="pl500"
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            marginBottom: blokkieTheme.spacing(5),
            textAlign: 'center',
          }}
        >
          {t('onboarding.fluentflow.startExplanation.body')}
        </BlokkieTypography>
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            onClick={nextStep}
            data-test-id="Onboarding-FluentFlow-StartExplanationButton"
          >
            {t('onboarding.fluentflow.startExplanation.button')}
          </BlokkieButton>
        </ButtonFooter>
      </CenterWrapper>
    </Wrapper>
  );
}

const useStyles = createUseStyles({
  gifContainer: {
    display: 'flex',
    marginBottom: blokkieTheme.spacing(3),
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    gifContainer: {
      marginBottom: blokkieTheme.spacing(3),
      marginTop: blokkieTheme.spacing(3),
    },
  },
});

export default injectIntl(FFStartExplanation);

import StartOnboardingContainer from './StartOnboardingContainer';
import StartSecondApplicantContainer from './StartSecondApplicantContainer';
import EmailContainer from './EmailContainer';
import ChoosePasswordContainer from './ChoosePasswordContainer';
import BadSectorContainer from './BadSectorContainer';
import ContactNameContainer from './ContactNameContainer';
import ContactPaymentContainer from './ContactPaymentContainer';
import BusinessPaymentContainer from './BusinessPaymentContainer';
import VerifyPhoneContainer from './VerifyPhoneContainer';
import BirthDateContainer from './BirthDateContainer';
import CountryContainer from './CountryContainer';
import SignUpPendingContainer from './SignUpPendingContainer';
import TermsContainer from './TermsContainer';
import ResetPasswordContainer from './ResetPasswordContainer';
import AddressContainer from './AddressContainer';
import NickNameContainer from './NickNameContainer';
import AuthorizedToSignContainer from './AuthorizedToSignContainer';
import UboContainer from './UboContainer';
import IDVContainer from './IDVContainer';
import SecondApplicantAddressContainer from './SecondApplicantAddressContainer';
import EndSecondApplicantContainer from './EndSecondApplicantContainer';
import SecondApplicantDetailsByFirstApplicantContainer from './SecondApplicantDetailsByFirstApplicantContainer';
import RiskQuestionsContainer from './RiskQuestionsContainer/RiskQuestionsContainer';

export default {
  StartOnboardingContainer,
  StartSecondApplicantContainer,
  AuthorizedToSignContainer,
  EmailContainer,
  ChoosePasswordContainer,
  VerifyPhoneContainer,
  BadSectorContainer,
  ContactNameContainer,
  BirthDateContainer,
  CountryContainer,
  ContactPaymentContainer,
  BusinessPaymentContainer,
  SignUpPendingContainer,
  TermsContainer,
  ResetPasswordContainer,
  AddressContainer,
  NickNameContainer,
  UboContainer,
  IDVContainer,
  SecondApplicantAddressContainer,
  EndSecondApplicantContainer,
  SecondApplicantDetailsByFirstApplicantContainer,
  RiskQuestionsContainer,
};

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/money.svg';
import '!file-loader?name=[name].[ext]!../../../images/settings/settings-payments.png';
import styled from 'styled-components';
import {
  PageTitle,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardTitle,
  CardBody,
  CardActions,
  Caption,
  CaptionSmall,
  Paragraph,
  Button,
  InputTile,
  HR,
  Info,
} from 'amber';
import { withTranslation } from 'react-i18next';
import CheckButton from './checkButton';
import {
  FooterContent,
  FooterWrapper,
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
  InputErrorBox,
} from '../styledComponents';
import { formatIban, toCurrency } from '../../../utils';
import theme from '../../../theme';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import { analyticsPageview } from '../../../ga4';
const moment = require('moment-timezone');

const CheckBoxTileWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: inherit;
  text-align: inherit;
  flex-wrap: wrap;
  flex: 0 0 100%;
  border-radius: 8px;
  padding: 24px !important;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  align-items: flex-start;
  font-family: MuseoSansRounded, sans-serif;
  color: #bebebf;
  font-size: 14px;

  @media ${theme.device.mobile} {
    flex-direction: column;
    gap: 20px;
  }
`;

const CheckBoxWrapper = styled.div`
  width: 72%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${theme.device.mobile} {
    width: unset;
    height: unset;
    gap: 20px;
  }
`;

const TextContainerWrapper = styled.div`
  width: 25%;
  height: 100%;

  @media ${theme.device.mobile} {
    width: unset;
    height: unset;
  }
`;

const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  flex-direction: column;
  box-sizing: border-box;
  align-items: inherit;
  text-align: inherit;
  height: 100%;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  color: #57565b;
  font-size: 16px;
  font-family: MuseoSansRounded700, sans-serif;
`;

const InfoWrapper = styled.div`
  width: 3%;
  align-self: center;
  display: flex;
  text-align: center;
  justify-content: end;

  @media ${theme.device.mobile} {
    position: absolute;
    left: 87%;
  }
`;

const MultipayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e9;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #515161;
`;

// eslint-disable-next-line react/prefer-stateless-function
export class PaymentSettings extends React.Component {
  constructor(props) {
    super(props);
    const { userSetting } = this.props.settingsData;
    const iban = props.settingsData.iban
      ? formatIban(props.settingsData.iban)
      : null;
    const isUnlimited = userSetting ? this.isUnlimited() : null;
    const maxAmountPerTikkie =
      userSetting?.maxAmountPerPersonPerPaymentEventInCents
        ? toCurrency(userSetting.maxAmountPerPersonPerPaymentEventInCents, 0)
        : null;
    const maxYieldPerDay = userSetting?.maxYieldPerIbanInCentsPer24Hours
      ? toCurrency(userSetting.maxYieldPerIbanInCentsPer24Hours, 0)
      : null;

    const email = this.props.user ? this.props.user.email : '';
    this.state = {
      bundledPayoutOption: props.settingsData.bundledPayoutOption,
      maxAmountPerTikkie,
      maxYieldPerDay,
      multiPay: userSetting?.multiPay,
      isUnlimited,
      stateChanged: false,
      iban,
      email,
      defaultExpiryDays: props.settingsData.defaultExpiryDays,
      internalPortalName: props.settingsData.internalPortalName,
      hasDifferentPayoutMethodTomorrow:
        props.settingsData.hasDifferentPayoutMethodTomorrow,
      defaultExpiryDaysNameError: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  componentDidMount() {
    analyticsPageview();
  }

  componentDidUpdate(oldProps) {
    const { isUpdating, settingsLoadingError, settingsUpdatingError } =
      this.props;

    const updatedSettings =
      !isUpdating &&
      oldProps.isUpdating &&
      !settingsLoadingError &&
      !settingsUpdatingError;

    if (updatedSettings) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        iban: formatIban(this.props.settingsData.iban),
        bundledPayoutOption: this.props.settingsData.bundledPayoutOption,
        defaultExpiryDays: this.props.settingsData.defaultExpiryDays,
        multiPay: this.props.settingsData.userSetting.multiPay,
        hasDifferentPayoutMethodTomorrow:
          this.props.settingsData.hasDifferentPayoutMethodTomorrow,
        stateChanged: false,
      });
    }
  }

  handleFieldChange = (fieldId, value) => {
    this.setState({ [fieldId]: value });
    this.setState({ stateChanged: true });
  };

  handleKeyDownExpiryDays = (event) => {
    const c = event.key || event.code;
    const { keyCode } = event;
    const ctrlV = (event.ctrlKey || event.metaKey) && keyCode === 86;
    const ctrlC = (event.ctrlKey || event.metaKey) && keyCode === 67;

    // left-arrow, right-arrow, backspace, tab, enter, crtl-v or ctrl-c is okay
    if (
      keyCode === 37 ||
      keyCode === 39 ||
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    // only numbers
    if ('0123456789'.indexOf(c) === -1) {
      event.preventDefault();
    }

    // Max 5 characters
    if (this.state.defaultExpiryDays.length > 5) {
      event.preventDefault();
    }
  };

  handleBlurExpiryDays = () => {
    const { defaultExpiryDays } = this.state;
    if (isEmpty(defaultExpiryDays) || defaultExpiryDays === '0') {
      this.setState({ defaultExpiryDays: 14 });
    }
  };

  cancel = () => {
    this.setState({
      defaultExpiryDays: this.props.settingsData.defaultExpiryDays,
      multiPay: this.props.settingsData.userSetting.multiPay,
      bundledPayoutOption: this.props.settingsData.bundledPayoutOption,
      stateChanged: false,
      defaultExpiryDaysNameError: false,
    });
  };

  saveChanges = () => {
    const { defaultExpiryDays, multiPay, bundledPayoutOption } = this.state;
    const userSetting = {
      ...this.props.settingsData.userSetting,
      multiPay,
    };
    this.props.updateSettings({
      ...this.props.settingsData,
      defaultExpiryDays,
      userSetting,
      bundledPayoutOption,
    });
    this.setState({ stateChanged: false });
  };

  enableMultiPay = () => {
    this.setState(() => ({
      multiPay: true,
      stateChanged: !this.props.settingsData?.userSetting?.multiPay,
    }));
  };

  enableSinglePay = () => {
    this.setState(() => ({
      multiPay: false,
      stateChanged: this.props.settingsData?.userSetting?.multiPay,
    }));
  };

  enableBundledPayout = () => {
    this.setState(() => ({
      bundledPayoutOption: true,
      stateChanged: !this.props.settingsData?.bundledPayoutOption,
    }));
  };

  enableDirectPayout = () => {
    this.setState(() => ({
      bundledPayoutOption: false,
      stateChanged: this.props.settingsData?.bundledPayoutOption,
    }));
  };

  isUnlimited = () => {
    const maxYield =
      this.props.settingsData.userSetting.maxYieldPerIbanInCentsPer24Hours;
    // when the yield is -1, it is unlimited
    return maxYield === -1;
  };

  componentWillUnmount() {
    this.props.resetUpdateSettings();
  }

  getTomorrowDayAndMonth() {
    return moment().add(1, 'day').format('DD-MM');
  }

  showWarningForDifferentPayoutMethod = () => {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const hasPendingChange =
      this.props.settingsData?.bundledPayoutOption !== undefined &&
      this.state?.bundledPayoutOption !==
        this.props.settingsData?.bundledPayoutOption;
    if (
      (!this.state.hasDifferentPayoutMethodTomorrow && hasPendingChange) ||
      (this.state.hasDifferentPayoutMethodTomorrow && !hasPendingChange)
    ) {
      if (this.state.bundledPayoutOption) {
        return (
          <MultipayWrapper id="Payments-bundledPayoutInfoMessage">
            {t('portal.settings.payment.payout.bundled.warning1') +
              this.getTomorrowDayAndMonth() +
              t('portal.settings.payment.payout.bundled.warning2')}
          </MultipayWrapper>
        );
      } else {
        return (
          <MultipayWrapper id="Payments-directPayoutInfoMessage">
            {t('portal.settings.payment.payout.direct.warning1') +
              this.getTomorrowDayAndMonth() +
              t('portal.settings.payment.payout.direct.warning2')}
          </MultipayWrapper>
        );
      }
    }

    return '';
  };

  sendMail = () => {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}
    ?subject=${t('portal.settings.payment.changeLimit.email.subject')}
    &body=${t('portal.settings.payment.changeLimit.email.body.text')}${t(
      'portal.settings.payment.changeLimit.email.body.organisationName',
    )}${this.state.internalPortalName}${t(
      'portal.settings.payment.changeLimit.email.body.userEmail',
    )}${this.state.email}`;
  };

  handleExpiryfield = (fieldId, value) => {
    if (isEmpty(value) || Number(value) <= 0 || value.startsWith('0')) {
      this.setState({ defaultExpiryDaysNameError: true });
    } else {
      this.setState({ defaultExpiryDaysNameError: false });
    }
    this.handleFieldChange(fieldId, value);
  };

  hasErrors = () => this.state.defaultExpiryDaysNameError;

  render() {
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    const {
      gotoSettingsDashboard,
      settingsLoadingErrorStatusCode,
      settingsLoadingError,
      gotoPortalOverview,
      settingsUpdatingError,
    } = this.props;
    const {
      internalPortalName,
      iban,
      defaultExpiryDays,
      stateChanged,
      multiPay,
      bundledPayoutOption,
      isUnlimited,
      maxAmountPerTikkie,
      maxYieldPerDay,
      defaultExpiryDaysNameError,
    } = this.state;

    if (settingsLoadingError) {
      if (settingsLoadingErrorStatusCode === 403) {
        return ErrorPageNoContact({
          pageTitle: t('portal.settings.general.header'),
          showUnauthorizedDescription: true,
          onClickBackOverview: gotoPortalOverview,
        });
      }
      return ErrorPageWithContact({
        pageTitle: t('portal.settings.general.header'),
        onClickBackOverview: gotoPortalOverview,
      });
    }

    if (settingsUpdatingError) {
      return ErrorPageNoContact({
        pageTitle: t('portal.settings.general.header'),
        onClickBackOverview: gotoSettingsDashboard,
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={t('portal.settings.general.backButton')}
        />

        <PageTitle>
          {t('portal.settings.general.header')}
          {internalPortalName}
        </PageTitle>

        <Container row mb={24}>
          <Card>
            <CardMedia src="/settings-payments.png" rounded={false} />
            <CardContent media>
              <CardTitle>{t('portal.settings.payment.header')}</CardTitle>
              <CardBody></CardBody>
            </CardContent>
            <Container row pt={24}>
              <HR />
              <Container row pt={32} pb={16}>
                <CaptionSmall>
                  {t('portal.settings.general.description')}
                </CaptionSmall>
              </Container>
              <Container row>
                <Paragraph>
                  {t('portal.settings.payment.description')}
                </Paragraph>
              </Container>
            </Container>
          </Card>
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.payment.bankAccount.title')}
            desc={t('portal.settings.payment.bankAccount.description')}
            value={iban}
            infoDesc={t('portal.settings.payment.bankAccount.helpText')}
            disabled
          />
        </Container>
        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.payment.expiry.title')}
            desc={t('portal.settings.payment.expiry.description')}
            value={defaultExpiryDays}
            type="number"
            infoDesc={t('portal.settings.payment.expiry.helpText')}
            id="defaultExpiryDays"
            dataTestId="Payments-ExpiryDaysInput"
            onChange={this.handleExpiryfield}
            onKeyDown={this.handleKeyDownExpiryDays}
            onBlur={this.handleBlurExpiryDays}
          />

          {defaultExpiryDaysNameError && (
            <InputErrorBox
              errorText={t('portal.settings.payment.expiry.error')}
              withInfo
            />
          )}
        </Container>

        <Container row mb={24}>
          <CheckBoxTileWrapper>
            <TextContainerWrapper>
              <TextWrapper>
                <TitleWrapper>
                  {t('portal.settings.payment.multiPay.title')}
                </TitleWrapper>
                <div>{t('portal.settings.payment.multiPay.description')}</div>
              </TextWrapper>
            </TextContainerWrapper>

            <CheckBoxWrapper>
              <CheckButton
                id="multiPay"
                checked={multiPay}
                onClick={this.enableMultiPay}
                choice={t('portal.settings.payment.multiPay.option1')}
                dataTestId="Payments-multiPayChecked"
              />
              <CheckButton
                id="singlePay"
                checked={!multiPay}
                onClick={this.enableSinglePay}
                choice={t('portal.settings.payment.multiPay.option2')}
                dataTestId="Payments-singlePayChecked"
              />
            </CheckBoxWrapper>

            <InfoWrapper>
              <Info
                title=""
                desc={t('portal.settings.payment.multiPay.helpText')}
                dataTestId="multiPayInfo"
              >
                ?
              </Info>
            </InfoWrapper>
          </CheckBoxTileWrapper>
        </Container>

        {this.showWarningForDifferentPayoutMethod()}

        <Container row mb={24}>
          <CheckBoxTileWrapper>
            <TextContainerWrapper>
              <TextWrapper>
                <TitleWrapper>
                  {t('portal.settings.payment.payout.title')}
                </TitleWrapper>
                <div>{t('portal.settings.payment.payout.description')}</div>
              </TextWrapper>
            </TextContainerWrapper>

            <CheckBoxWrapper>
              <CheckButton
                id="directPayout"
                checked={!bundledPayoutOption}
                onClick={this.enableDirectPayout}
                choice={t('portal.settings.payment.payout.option1')}
                dataTestId="Payments-directPayoutChecked"
              />
              <CheckButton
                id="bundledPayout"
                checked={bundledPayoutOption}
                onClick={this.enableBundledPayout}
                choice={t('portal.settings.payment.payout.option2')}
                dataTestId="Payments-bundledPayoutChecked"
              />
            </CheckBoxWrapper>

            <InfoWrapper>
              <Info
                title=""
                desc={t('portal.settings.payment.payout.helpText')}
                dataTestId="payoutInfo"
              >
                ?
              </Info>
            </InfoWrapper>
          </CheckBoxTileWrapper>
        </Container>

        <Container row mb={24}>
          <InputTile
            title={t('portal.settings.payment.limitPerTikkie.title')}
            desc={t('portal.settings.payment.limitPerTikkie.description')}
            value={maxAmountPerTikkie}
            infoDesc={t('portal.settings.payment.limitPerTikkie.helpText')}
            disabled
          />
        </Container>
        <Container row mb={24} style={{ whiteSpace: 'pre-line' }}>
          <InputTile
            title={t('portal.settings.payment.limitPerDay.title')}
            desc={t('portal.settings.payment.limitPerDay.description')}
            value={
              isUnlimited
                ? t('portal.settings.payment.limitPerDay.unlimited')
                : maxYieldPerDay
            }
            infoDesc={t('portal.settings.payment.limitPerDay.helpText')}
            disabled
          />
        </Container>

        <Container row>
          <Card>
            <CardMedia src="/money.svg" center />
            <CardContent media>
              <CardTitle>
                {t('portal.settings.payment.changeLimit.title')}
              </CardTitle>

              <CardBody>
                <div>
                  <Caption>
                    {t('portal.settings.payment.changeLimit.text')}
                  </Caption>
                  <Caption>
                    {t('portal.settings.payment.changeLimit.text1')}
                  </Caption>
                  <Caption>
                    {t('portal.settings.payment.changeLimit.text2')}
                  </Caption>
                  <Caption>
                    {t('portal.settings.payment.changeLimit.text3')}
                  </Caption>
                </div>
              </CardBody>
              <CardActions>
                <Button onClick={this.sendMail}>
                  {t('portal.settings.general.mailButton')}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Container>
        <FooterWrapper slideIn={stateChanged}>
          {stateChanged && (
            <FooterContent>
              <Button
                dataTestId="Payments-CancelButton"
                type="secondary"
                onClick={this.cancel}
              >
                {t('portal.settings.general.update.cancelButton')}
              </Button>
              <Button
                type="primary"
                dataTestId="Payments-SaveButton"
                onClick={this.saveChanges}
                disabled={this.hasErrors()}
              >
                {t('portal.settings.general.update.saveButton')}
              </Button>
            </FooterContent>
          )}
        </FooterWrapper>
      </Container>
    );
  }
}

PaymentSettings.propTypes = {
  user: PropTypes.object,
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  settingsUpdatingSuccess: PropTypes.bool.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  settingsUpdatingError: PropTypes.bool.isRequired,
  resetUpdateSettings: PropTypes.func.isRequired,
};

export default withTranslation()(PaymentSettings);

import { takeLatest, call, put } from 'redux-saga/effects';

import request from '../../utils/request';
import config from '../../config';
import { LOAD_PAYMENT_REQUEST } from './constants';
import { loadPaymentRequestError, loadPaymentRequestSuccess } from './actions';

const sagas = [takeLatest(LOAD_PAYMENT_REQUEST, loadPaymentRequestSaga)];

export function* loadPaymentRequestSaga(action) {
  const { paymentRequestToken } = action.payload;

  const requestUrl = `${config.API_URL}/paymentinformation/${paymentRequestToken}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadPaymentRequestSuccess(response));
  } catch (err) {
    yield put(loadPaymentRequestError(err.message));
  }
}

export default sagas;

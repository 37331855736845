import styled from 'styled-components';

export const VerificationWrapper = styled.div`
  margin-left: 10px;
`;

export const VerifyOK = styled.div`
  color: ${(props) => props.theme.palette.gray};
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VerifyIconWrapperOK = styled.img`
  margin-right: 9px;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.green};
`;

export const VerifyNOK = styled(VerifyOK)`
  color: ${(props) => props.theme.palette.lightGray};
`;

export const VerifyIconWrapperNOK = styled.img`
  margin-right: 9px;
  width: 24px;
  height: 24px;
  padding: 6px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.lightGray};
`;

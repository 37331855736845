import { fromJS } from 'immutable/dist/immutable';
import {
  LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_TRANSACTIONS_SUCCESS,
  DOWNLOAD_CAMT,
} from './constants';

const initialState = fromJS({
  // Transactions
  transactions: {},
  transactionsIsLoading: false,
  transactionsErrorMessage: null,
  // Filters Transactions
  transactionFilterPage: 1,
  transactionFilterFromDate: 0,
  transactionFilterToDate: 0,
  transactionFilterType: '',
  transactionFilterSearchText: '',
});

export default function transactionsListContainerReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_TRANSACTIONS:
      return state
        .set('transactionFilterPage', action.payload.page)
        .set('transactionFilterFromDate', action.payload.from)
        .set('transactionFilterToDate', action.payload.to)
        .set('transactionFilterType', action.payload.transactionType)
        .set('transactionFilterSearchText', action.payload.searchString)
        .set('transactionsIsLoading', true)
        .set('transactionsErrorMessage', null)
        .set('transactions', fromJS({}));
    case LOAD_TRANSACTIONS_SUCCESS:
      return state
        .set('transactionsIsLoading', false)
        .set('transactionsErrorMessage', null)
        .set('transactions', fromJS(action.payload.transactions));
    case LOAD_TRANSACTIONS_ERROR:
      return state
        .set('transactionsIsLoading', false)
        .set('transactionsErrorMessage', action.payload.error)
        .set('transactions', fromJS({}));
    case DOWNLOAD_CAMT:
    default:
      return state;
  }
}

export { initialState };

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Space } from '../../styleguide';
import {
  Box,
  OnboardingParagraph,
  OnboardingParagraphDescription,
  Wrapper,
} from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import Loader from '../Loader';
import { formatDateToPattern, getFullName } from '../../../utils';
import { InputLabel } from '../InputLabel';
import { TextAreaLabel } from '../TextareaLabel';
import { ns } from '../../../i18n/i18n';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IAddress {
  street: string;
  hidden: boolean;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  city: string;
  country: string;
}

interface IContactPersonDetails {
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
}

interface IProps {
  nextStep: () => void;
  onboardingData: {
    address: IAddress;
    kvkNumber: string;
    organisationName: string;
    legalEntity: string;
    contactPersonDetails: IContactPersonDetails;
    created: string;
  };
  forSecondApplicant: boolean;
}

const stringsToStringWithNewLines = (strings: string[]): string =>
  strings.reduce(
    (result, currentValue) => `${result}${result && '\n'}${currentValue}`,
    '',
  );

function VerifyOrganisation(props: IProps): JSX.Element {
  const [showNotRight, setShowNotRight] = useState(false);
  const { t } = useTranslation();

  useOnboardingPageView('verify-organisation');

  const toggleShowNotRight = (): void => setShowNotRight(!showNotRight);

  const { onboardingData, nextStep, forSecondApplicant } = props;
  const { address } = onboardingData;

  const addressLine1 = address.hidden
    ? '<afgeschermd>'
    : `${address.street} ${address.houseNumber ? address.houseNumber : ''} ${
        address.houseNumberAddition ? address.houseNumberAddition : ''
      }`;
  const addressLine2 = address.hidden
    ? ''
    : `${address.postalCode} ${address.city}, ${address.country}`;

  // eslint-disable-next-line react/destructuring-assignment
  if (!props.onboardingData.kvkNumber) {
    return <Loader />;
  }

  const title = forSecondApplicant
    ? `${t('onboarding.verifyOrganisationScreen.headerSecondApplicant', {
        ns: ns.common,
      })} ${onboardingData.organisationName}`
    : `${onboardingData.organisationName}`;

  const getSubtitleForSecondApplicant = (): string => {
    let subtitleTemplate = t(
      'onboarding.verifyOrganisationScreen.subTitleSecondApplicant',
      { ns: ns.common },
    );

    subtitleTemplate = subtitleTemplate.replace(
      '{organisationName}',
      onboardingData.organisationName,
    );
    subtitleTemplate = subtitleTemplate.replace(
      '{firstApplicantName}',
      getFullName(onboardingData.contactPersonDetails),
    );
    subtitleTemplate = subtitleTemplate.replace(
      '{startingDate}',
      formatDateToPattern(onboardingData.created, 'DD-MM-YYYY'),
    );

    return subtitleTemplate;
  };

  return (
    <>
      <Wrapper>
        <Title data-test-id="Onboarding-VerifyOrganisation-Name">{title}</Title>
        {forSecondApplicant && (
          <OnboardingParagraphDescription marginBottom={Space.s24}>
            <BlokkieTypography variant="pl700" style={{ fontSize: '14px' }}>
              {getSubtitleForSecondApplicant()}
            </BlokkieTypography>
          </OnboardingParagraphDescription>
        )}
        <Box
          flexDirection={isMobileOnly ? 'column' : 'row'}
          style={{ marginTop: blokkieTheme.spacing() }}
        >
          <InputLabel
            dataTestId="Onboarding-VerifyOrganisation-KvkNumber"
            style={{ flex: 1 }}
            label={t('onboarding.verifyOrganisationScreen.kvkNumberInputLabel')}
            text={onboardingData.kvkNumber}
            disabled
          />
          <InputLabel
            dataTestId="Onboarding-VerifyOrganisation-LegalEntity"
            style={{
              flex: 2,
              marginTop: isMobileOnly ? blokkieTheme.spacing(2) : 0,
              marginLeft: !isMobileOnly ? blokkieTheme.spacing(2) : 0,
            }}
            label={t('onboarding.verifyOrganisationScreen.legalFormInputLabel')}
            text={onboardingData.legalEntity}
            disabled
          />
        </Box>
        <Box>
          <TextAreaLabel
            dataTestId="Onboarding-VerifyOrganisation-Address"
            style={{ marginTop: blokkieTheme.spacing(2) }}
            label={t('onboarding.verifyOrganisationScreen.addressInputLabel')}
            text={stringsToStringWithNewLines([addressLine1, addressLine2])}
            rows={2}
            disabled
          />
        </Box>
        {!forSecondApplicant && (
          <BlokkieTextLink
            variant="pl700"
            onClick={toggleShowNotRight}
            underline
            color={blokkieTheme.colors.grey.veryDark}
            data-test-id="Onboarding-VerifyOrganisation-SomethingWrong"
            style={{
              marginTop: blokkieTheme.spacing(2),
            }}
          >
            {t('onboarding.verifyOrganisationScreen.secondaryButtonText')}
          </BlokkieTextLink>
        )}
        <ButtonFooter forceAlign="right">
          <BlokkieButton
            onClick={nextStep}
            data-test-id="Onboarding-VerifyOrganisation-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>

      {showNotRight && (
        <InfoScreen
          title={t('onboarding.verifyOrganisationScreen.infoScreen.title')}
          onClick={toggleShowNotRight}
          fromPage={VerifyOrganisation.name}
          show={showNotRight}
        >
          <OnboardingParagraph>
            {t('onboarding.verifyOrganisationScreen.infoScreen.text')}
          </OnboardingParagraph>
        </InfoScreen>
      )}
    </>
  );
}

export default injectIntl(VerifyOrganisation);

import { fromJS } from 'immutable';

import {
  DOWNLOAD_PAYMENTS,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_ERROR,
  LOAD_CAMPAIGN_SUCCESS,
  FETCH_QR_CODE,
  FETCH_QR_CODE_ERROR,
  FETCH_QR_CODE_SUCCESS,
  RESET_QR_CODE,
} from './constants';

const initialState = fromJS({
  campaign: {},
  campaignIsLoading: false,
  campaignErrorMessage: null,
  fetchingQRCode: false,
  qrCode: '',
  fetchQRCodeError: false,
});

export default function cashbackCampaignDetailReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_CAMPAIGN:
      return state
        .set('campaignIsLoading', true)
        .set('campaignErrorMessage', null)
        .set('campaign', fromJS({}));
    case LOAD_CAMPAIGN_SUCCESS:
      return state
        .set('campaignIsLoading', false)
        .set('campaignErrorMessage', null)
        .set('campaign', fromJS(action.payload.campaign));
    case LOAD_CAMPAIGN_ERROR:
      return state
        .set('campaignIsLoading', false)
        .set('campaignErrorMessage', action.payload.error)
        .set('campaign', fromJS({}));
    case FETCH_QR_CODE:
      return state.set('fetchingQRCode', true);
    case FETCH_QR_CODE_SUCCESS:
      return state
        .set('fetchingQRCode', false)
        .set('qrCode', action.payload.data);
    case FETCH_QR_CODE_ERROR:
      return state.set('fetchingQRCode', false).set('fetchQRCodeError', true);
    case RESET_QR_CODE:
      return state.set('qrCode', '');
    case DOWNLOAD_PAYMENTS:
    default:
      return state;
  }
}

export { initialState };

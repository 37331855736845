import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  riskQuestionIds,
  riskQuestionSteps,
  riskQuestionStepsSequence,
} from './RiskQuestionsContainerConstants';
import { selectOnboardingKYCData } from '../selectors';
import { sendRiskAnswers } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import FormWrapperContainer from '../FormWrapperContainer';
import StartExplanationRiskQuestion from '../../../components/OnboardingKYC/FluentFlow/StartExplanationRiskQuestion';
import NationRiskQuestion from '../../../components/OnboardingKYC/FluentFlow/NationRiskQuestion';
import DefenseRiskQuestion from '../../../components/OnboardingKYC/FluentFlow/DefenseRiskQuestion';
import FinancialPartOneRiskQuestion from '../../../components/OnboardingKYC/FluentFlow/FinancialPartOneRiskQuestion';
import FinancialPartTwoRiskQuestion from '../../../components/OnboardingKYC/FluentFlow/FinancialPartTwoRiskQuestion';
import SectorRiskQuestions from '../../../components/OnboardingKYC/FluentFlow/SectorRiskQuestions';
import { RiskAnswerOptions } from '../../../components/OnboardingKYC/FluentFlow/RiskAnswerOptions';

function RiskQuestionsContainer(props) {
  const [currentStep, setCurrentStep] = useState(riskQuestionSteps.explanation);

  const [riskPayloadState, setRiskPayloadState] = useState({
    [riskQuestionIds.sectorRiskId]: RiskAnswerOptions.UNANSWERED,
    [riskQuestionIds.nationRiskId]: RiskAnswerOptions.UNANSWERED,
    [riskQuestionIds.defenseRiskId]: RiskAnswerOptions.UNANSWERED,
    [riskQuestionIds.financialPartOneRiskId]: RiskAnswerOptions.UNANSWERED,
    [riskQuestionIds.financialPartTwoRiskId]: RiskAnswerOptions.UNANSWERED,
  });

  const nextStep = () => {
    setCurrentStep(props.getNextStep(riskQuestionStepsSequence, currentStep));
  };

  const prevStep = () => {
    setCurrentStep(
      props.getPreviousStep(riskQuestionStepsSequence, currentStep),
    );
  };

  const setRiskAnswer = (riskAnswer) => {
    setRiskPayloadState({
      ...riskPayloadState,
      ...riskAnswer,
    });
  };

  const sendRiskAnswerHandler = () => {
    props.sendRiskAnswers(riskPayloadState);
  };

  return (
    <FormWrapperContainer portalBacking>
      {currentStep === riskQuestionSteps.explanation && (
        <StartExplanationRiskQuestion nextStep={nextStep} />
      )}
      {currentStep === riskQuestionSteps.sector && (
        <SectorRiskQuestions
          currentAnswer={riskPayloadState[riskQuestionIds.sectorRiskId]}
          nextStep={nextStep}
          setRiskAnswer={setRiskAnswer}
          organisationName={props.onboardingData.organisationName}
        />
      )}
      {currentStep === riskQuestionSteps.nation && (
        <NationRiskQuestion
          nextStep={nextStep}
          previousStep={prevStep}
          setRiskAnswer={setRiskAnswer}
          currentAnswer={riskPayloadState[riskQuestionIds.nationRiskId]}
        />
      )}
      {currentStep === riskQuestionSteps.defence && (
        <DefenseRiskQuestion
          nextStep={nextStep}
          previousStep={prevStep}
          setRiskAnswer={setRiskAnswer}
          currentAnswer={riskPayloadState[riskQuestionIds.defenseRiskId]}
        />
      )}
      {currentStep === riskQuestionSteps.financialPartOne && (
        <FinancialPartOneRiskQuestion
          nextStep={nextStep}
          previousStep={prevStep}
          setRiskAnswer={setRiskAnswer}
          currentAnswer={
            riskPayloadState[riskQuestionIds.financialPartOneRiskId]
          }
        />
      )}
      {currentStep === riskQuestionSteps.financialPartTwo && (
        <FinancialPartTwoRiskQuestion
          nextStep={sendRiskAnswerHandler}
          previousStep={prevStep}
          setRiskAnswer={setRiskAnswer}
          currentAnswer={
            riskPayloadState[riskQuestionIds.financialPartTwoRiskId]
          }
        />
      )}
    </FormWrapperContainer>
  );
}

RiskQuestionsContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
  sendRiskAnswers: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  onboardingData: selectOnboardingKYCData,
});

const mapDispatchToProps = (dispatch) => ({
  sendRiskAnswers: (riskAnswers) => dispatch(sendRiskAnswers(riskAnswers)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(RiskQuestionsContainer);

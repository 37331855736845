import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, PageTitle } from 'amber';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  isExpiryTab,
  isLandingTab,
  isMaxPaymentsTab,
  isThankyouTab,
  navigationTabs,
} from './constants';
import { Navigation } from './Navigation';
import {
  BackButton,
  ErrorPageNoContact,
  ErrorPageWithContact,
  FooterContent,
  FooterWrapper,
} from '../styledComponents';
import ThankYou from './ThankYou';
import Landing from './Landing';
import {
  getInitialErrors,
  getInitialValues,
  hasNoErrors,
  hasPendingLandingUrl,
  hasPendingThankYouPage,
  valuesToSave,
  valuesUpdated,
} from './updateState';
import Loader from '../../OnboardingKYC/Loader';
import ExpiryPage from './Expiry';
import MaxPayments from './MaxPayments';
import { AnalyticCategories, analyticsEvent } from '../../../ga4';

function Pages({
  gotoPortalOverview,
  gotoSettingsDashboard,
  settingsData,
  settingsLoading,
  settingsLoadingError,
  settingsLoadingErrorStatusCode,
  updateSettings,
  isUpdating,
  settingsUpdatingError,
}) {
  const { t } = useTranslation();
  const [tab, setTab] = useState(navigationTabs.landing);
  const noErrors = !settingsLoadingError && !settingsUpdatingError;
  const loading = (settingsLoading || isUpdating) && noErrors;

  const initialValues =
    !loading && noErrors ? getInitialValues(settingsData) : {};
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(getInitialErrors());
  const valuesChanged =
    !loading && noErrors ? valuesUpdated(values, settingsData) : false;

  const displayStatusBoxOnPage = () =>
    (isLandingTab(tab) && hasPendingLandingUrl(settingsData)) ||
    (isThankyouTab(tab) && hasPendingThankYouPage(settingsData));

  const cancel = () => {
    setValues(initialValues);
    setErrors(getInitialErrors());
  };

  useEffect(() => {
    // On save erase inputValues for images
    if (!loading && valuesChanged && settingsData) {
      setValues(getInitialValues(settingsData));
    }
  }, [loading]);

  const onChange = (fieldId, value) => {
    setValues((prevValues) => ({ ...prevValues, [fieldId]: value }));
  };

  const validate = (shouldDisplayError, errorString) => {
    if (shouldDisplayError) {
      if (!errors[errorString]) {
        setErrors({ ...errors, [errorString]: true });
      }
    } else if (errors[errorString]) {
      setErrors({ ...errors, [errorString]: false });
    }
  };

  const saveChanges = () => {
    analyticsEvent(AnalyticCategories.SETTINGS, 'save');
    updateSettings(valuesToSave(values, settingsData));
  };

  if (settingsLoadingError) {
    if (settingsLoadingErrorStatusCode === 403) {
      return ErrorPageNoContact({
        pageTitle: t('portal.settings.pages.general.header'),
        showUnauthorizedDescription: true,
        onClickBackOverview: gotoPortalOverview,
      });
    }
    return ErrorPageWithContact({
      pageTitle: t('portal.settings.pages.general.header'),
      onClickBackOverview: gotoPortalOverview,
    });
  }

  if (settingsUpdatingError) {
    return ErrorPageNoContact({
      pageTitle: t('portal.settings.pages.general.header'),
      onClickBackOverview: gotoSettingsDashboard,
    });
  }

  return (
    <>
      {isMobile && <Navigation tab={tab} setTab={setTab} />}
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={t('portal.settings.general.backButton')}
        />
        {!isMobile && (
          <>
            <PageTitle>{t('portal.settings.pages.general.header')}</PageTitle>
            <Navigation
              tab={tab}
              setTab={setTab}
              noMarginBottom={displayStatusBoxOnPage()}
            />
          </>
        )}
        {!loading && (
          <>
            {isLandingTab(tab) && (
              <Landing
                values={values}
                onChange={onChange}
                validate={validate}
                errors={errors}
                displayPendingStatus={hasPendingLandingUrl(settingsData)}
              />
            )}
            {isThankyouTab(tab) && (
              <ThankYou
                values={values}
                onChange={onChange}
                validate={validate}
                errors={errors}
                displayPendingStatus={hasPendingThankYouPage(settingsData)}
              />
            )}
            {isExpiryTab(tab) && (
              <ExpiryPage
                values={values}
                onChange={onChange}
                validate={validate}
                errors={errors}
              />
            )}
            {isMaxPaymentsTab(tab) && (
              <MaxPayments
                values={values}
                onChange={onChange}
                validate={validate}
                errors={errors}
              />
            )}
            <FooterWrapper slideIn={valuesChanged}>
              {valuesChanged && (
                <FooterContent>
                  <Button type="secondary" onClick={cancel}>
                    {t('portal.settings.general.update.cancelButton')}
                  </Button>
                  <Button
                    type="primary"
                    dataTestId="Pages-SaveButton"
                    onClick={saveChanges}
                    disabled={!hasNoErrors(errors)}
                  >
                    {t('portal.settings.general.update.saveButton')}
                  </Button>
                </FooterContent>
              )}
            </FooterWrapper>
          </>
        )}
        {loading && <Loader />}
      </Container>
    </>
  );
}

Pages.propTypes = {
  settingsLoading: PropTypes.bool,
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  updateSettings: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  settingsUpdatingError: PropTypes.bool.isRequired,
};

export default Pages;

/* eslint-disable jsx-a11y/anchor-is-valid */
import '!file-loader?name=[name].[ext]!../../../images/videoGifs/dont-go.mp4';
import {
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { Card, Container } from 'amber';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import { Color } from '../../styleguide';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import { analyticsPageview } from '../../../ga4';

function DeactivationConfirmation(): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    analyticsPageview();
  }, []);

  return (
    <Container row mt={28}>
      <Card>
        <div className={classes.columnContainer}>
          <div className={classes.column1}>
            <BlokkieTypography
              variant="h3"
              style={{
                color: Color.gray01,
                marginBottom: blokkieTheme.spacing(2),
              }}
            >
              {t('portal.deactivation.confirmation.title')}
            </BlokkieTypography>
            <BlokkieTypography
              variant="pl500"
              style={{
                color: blokkieTheme.colors.grey.veryDark,
              }}
            >
              {t('portal.deactivation.confirmation.text1')}
            </BlokkieTypography>
            <br />
            <div>
              <BlokkieTypography
                variant="pl500"
                style={{ color: blokkieTheme.colors.grey.veryDark }}
              >
                {t('portal.deactivation.confirmation.text2')}
              </BlokkieTypography>
            </div>
            <div>
              <BlokkieTypography
                variant="pl500"
                style={{ color: blokkieTheme.colors.grey.veryDark }}
              >
                {t('portal.deactivation.confirmation.text3').concat(' ')}
              </BlokkieTypography>
              <BlokkieTextLink
                href={`mailto:${BUSINESS_EMAIL_ADDRESS}`}
                variant="pl500"
                underline
              >
                {`${BUSINESS_EMAIL_ADDRESS}`}
              </BlokkieTextLink>
            </div>
          </div>

          <div className={classes.column2}>
            <BlokkieGifContainer
              source="/dont-go.mp4"
              style={{
                marginBottom: `${blokkieTheme.spacing(2)}px`,
                width: '246px',
                height: '246px',
              }}
            />
          </div>
        </div>
      </Card>
    </Container>
  );
}

const useStyles = createUseStyles({
  columnContainer: {
    display: 'flex',
    gap: blokkieTheme.spacing(2),
    width: '100%',
    [blokkieTheme.mediaQueries.allPhone]: {
      flexDirection: 'column',
    },
  },
  column1: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '70%',
  },
  column2: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '30%',
    [blokkieTheme.mediaQueries.allPhone]: {
      marginTop: blokkieTheme.spacing(3),
    },
  },
});

export default DeactivationConfirmation;

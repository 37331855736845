/**
 *
 * Transaction Filter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';
import SearchFilter from '../../SearchFilter';
import Dropdown from '../../Dropdown/Dropdown';
import DropdownMobile from '../../Dropdown/DropdownMobile';
import TransactionDateFilter from './TransactionDateFilter';
import messages from './messages';
import {
  FilterItemFilledWrapper,
  FilterItemWrapper,
  FiltersWrapper,
} from '../../styleguide';

class TransactionsFilters extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    const labelPaymentRequests = intl.formatMessage({
      id: 'app.components.TransactionFilter.paymentRequests',
    });

    const labelRefund = intl.formatMessage({
      id: 'app.components.TransactionFilter.refund',
    });

    this.state = {
      transactionTypeOptions: [
        {
          id: 'PAYMENT_REQUEST',
          label: labelPaymentRequests,
        },
        {
          id: 'REFUND',
          label: labelRefund,
        },
      ],
    };
  }

  updateDateFilterOptionState = (selectedDate) => {
    this.props.transactionListCallback(selectedDate);
  };

  loadTransactions = (
    page = this.props.currentPage,
    fromDate = this.props.filterFromDate,
    toDate = this.props.filterToDate,
    type = this.props.filterType,
    search = this.props.filterSearch,
  ) => {
    this.props.loadTransactions(page, fromDate, toDate, type, search);
  };

  filterByTransactionType = (transactionType) => {
    const _ = undefined;
    this.loadTransactions(1, _, _, transactionType);
  };

  searchTransactions = (searchString) => {
    const _ = undefined;
    this.loadTransactions(1, _, _, _, searchString);
  };

  filterByTransactionDate = (fromDate, toDate) => {
    const _ = undefined;
    this.loadTransactions(1, fromDate, toDate, _, _);
  };

  filterTransaction = (fromDate, toDate, type) => {
    const _ = undefined;
    this.loadTransactions(1, fromDate, toDate, type, _);
  };

  render() {
    return (
      <FiltersWrapper>
        {!isMobileOnly && (
          <React.Fragment>
            <FilterItemWrapper>
              <TransactionDateFilter
                startDate={this.props.filterFromDate}
                endDate={this.props.filterToDate}
                transactionsFiltersCallback={this.updateDateFilterOptionState}
                onSubmit={this.filterByTransactionDate}
              />
            </FilterItemWrapper>
            <FilterItemWrapper id="transactionFilter">
              <Dropdown
                selected={this.props.filterType}
                options={this.state.transactionTypeOptions}
                onSubmit={this.filterByTransactionType}
              >
                <FormattedMessage {...messages.allTransactions} />
              </Dropdown>
            </FilterItemWrapper>
          </React.Fragment>
        )}
        {isMobileOnly && (
          <FilterItemWrapper>
            <DropdownMobile
              selected={{
                transactionType: this.props.filterType,
                date: {
                  fromDate: this.props.filterFromDate,
                  toDate: this.props.filterToDate,
                },
              }}
              onSubmit={this.filterTransaction}
            >
              <FormattedMessage {...messages.filter} />
            </DropdownMobile>
          </FilterItemWrapper>
        )}
        <FilterItemFilledWrapper>
          <SearchFilter
            onSearch={this.searchTransactions}
            searchedText={this.props.filterSearch}
          />
        </FilterItemFilledWrapper>
      </FiltersWrapper>
    );
  }
}

TransactionsFilters.propTypes = {
  intl: PropTypes.object.isRequired,
  loadTransactions: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  filterFromDate: PropTypes.number,
  filterToDate: PropTypes.number,
  filterType: PropTypes.string.isRequired,
  filterSearch: PropTypes.string.isRequired,
  transactionListCallback: PropTypes.func,
};

export default injectIntl(TransactionsFilters);

const agreement = 'AGREEMENT';
const processExplanation = 'PROCESS_EXPLANATION';
const kvkOverview = 'KVK_OVERVIEW';
const country = 'COUNTRY';

export default {
  agreement,
  processExplanation,
  kvkOverview,
  country,
};

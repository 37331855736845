import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../components/components';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import EmptyLoader from '../../components/Util/Loader';
import {
  selectSettingsData,
  selectSettingsLoading,
  selectSettingsLoadingError,
  selectSettingsLoadingErrorStatusCode,
} from './selectors';
import SettingsDashboard from '../../components/Settings/Dashboard';
import { isEmpty } from '../../utils';
import * as constants from '../../global-constants';
import { selectUser } from '../App/selectors';
import { loadSettings } from './actions';

export function SettingsContainer(props) {
  const {
    user,
    settingsLoading,
    settingsLoadingErrorStatusCode,
    settingsData,
    settingsLoadingError,
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(settingsData)) {
      props.loadSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoOrganisationSettings = () => {
    history.push('/settings/organisation');
  };

  const goToPaymentSettings = () => {
    history.push('/settings/payment');
  };

  const gotoPagesSettings = () => {
    history.push('/settings/pages');
  };

  const gotoApiSettings = () => {
    history.push('/settings/api');
  };

  const gotoSmsSettings = () => {
    history.push('/settings/sms');
  };

  const gotoBulkUploadSettings = () => {
    history.push('/settings/bulkupload');
  };

  const goToDeactivationSettings = () => {
    history.push('/settings/deactivate');
  };

  const gotoAuditLog = () => {
    history.push('/settings/auditlog');
  };

  const gotoUserManagementUsersList = () => {
    history.push('/settings/user-management/users');
  };

  if (settingsLoading) {
    return <EmptyLoader />;
  }

  const isContactPersonOrAdmin =
    user.functionalRole === constants.ROLE_CONTACT_PERSON ||
    user.functionalRole === constants.ROLE_ADMIN;

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <SettingsDashboard
            isContactPersonOrAdmin={isContactPersonOrAdmin}
            gotoPortalOverview={gotoPortalOverview}
            gotoOrganisationSettings={gotoOrganisationSettings}
            goToPaymentSettings={goToPaymentSettings}
            gotoPagesSettings={gotoPagesSettings}
            gotoApiSettings={gotoApiSettings}
            gotoSmsSettings={gotoSmsSettings}
            gotoBulkUploadSettings={gotoBulkUploadSettings}
            goToDeactivationSettings={goToDeactivationSettings}
            gotoAuditLog={gotoAuditLog}
            gotoUserManagementUsersList={gotoUserManagementUsersList}
            settingsData={settingsData}
            settingsLoadingError={settingsLoadingError}
            settingsLoadingErrorStatusCode={settingsLoadingErrorStatusCode}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

SettingsContainer.propTypes = {
  user: PropTypes.object.isRequired,
  settingsLoading: PropTypes.bool.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  settingsData: PropTypes.object,
  loadSettings: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
});

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  settingsLoading: selectSettingsLoading,
  settingsLoadingError: selectSettingsLoadingError,
  settingsLoadingErrorStatusCode: selectSettingsLoadingErrorStatusCode,
  settingsData: selectSettingsData,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'SettingsContainer', saga });

export default compose(withSaga, withConnect)(SettingsContainer);

/*
 * Transaction Date Filter Messages
 *
 *
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  filterName: {
    id: 'app.components.TransactionDateFilter.filterName',
    defaultMessage: 'Datum',
  },
  reset: {
    id: 'app.components.TransactionDateFilter.reset',
    defaultMessage: 'Reset',
  },
  today: {
    id: 'app.components.TransactionDateFilter.today',
    defaultMessage: 'Vandaag',
  },
  thisWeek: {
    id: 'app.components.TransactionDateFilter.thisWeek',
    defaultMessage: 'Deze week',
  },
  thisMonth: {
    id: 'app.components.TransactionDateFilter.thisMonth',
    defaultMessage: 'Deze maand',
  },
  thisYear: {
    id: 'app.components.TransactionDateFilter.thisYear',
    defaultMessage: 'Dit jaar',
  },
  customDate: {
    id: 'app.components.TransactionDateFilter.customDate',
    defaultMessage: 'Kies datum...',
  },
  filter: {
    id: 'app.components.TransactionDateFilter.filter',
    defaultMessage: 'Filter toepassen',
  },
});

/*
 *
 * Menu constants
 *
 */

export const LOGOUT_USER = 'app/MenuContainer/LOGOUT_USER';

export const LOAD_LINKED_ORGANISATIONS = 'app/LOAD_LINKED_ORGANISATIONS';
export const LOAD_LINKED_ORGANISATIONS_SUCCESS =
  'app/LOAD_LINKED_ORGANISATIONS_SUCCESS';
export const LOAD_LINKED_ORGANISATIONS_ERROR =
  'app/LOAD_LINKED_ORGANISATIONS_ERROR';
export const CLEAR_LINKED_ORGANISATIONS = 'app/CLEAR_LINKED_ORGANISATIONS';

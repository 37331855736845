import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.SmsSettings.header',
    defaultMessage: 'Module Delen via sms',
  },
  terug: {
    id: 'app.components.SmsSettings.terug',
    defaultMessage: 'Terug',
  },
  smsSettings: {
    id: 'app.components.SmsSettings.smsSettings',
    defaultMessage: 'Tikkies sturen via sms',
  },
  changedOn: {
    id: 'app.components.SmsSettings.changedOn',
    defaultMessage: 'Gewijzigd op ',
  },
  changedBy: {
    id: 'app.components.SmsSettings.changedBy',
    defaultMessage: 'door ',
  },
  mostUsed: {
    id: 'app.components.SmsSettings.mostUsed',
    defaultMessage: 'Meest gebruikte setting: ',
  },
  status: {
    id: 'app.components.SmsSettings.status',
    defaultMessage: 'Status setting:',
  },
  popupText: {
    id: 'app.components.SmsSettings.popupText',
    defaultMessage: 'Module is aangezet',
  },
  descriptionTitle: {
    id: 'app.components.SmsSettings.descriptionTitle',
    defaultMessage: 'Omschrijving',
  },
  descriptionText: {
    id: 'app.components.SmsSettings.descriptionText',
    defaultMessage:
      'Een betaalverzoek kun je op verschillende manieren delen. Bijvoorbeeld met een QR-code die je klanten scannen of een link die je deelt via WhatsApp of mail. Je kunt ook kiezen voor Delen via sms. Op onze website vind je meer ',
  },
  smsCost: {
    id: 'app.components.SmsSettings.smsCost',
    defaultMessage: 'informatie over de kosten.',
  },
  manual: {
    id: 'app.components.SmsSettings.manual',
    defaultMessage: 'Handleiding',
  },
  manualTitle1: {
    id: 'app.components.SmsSettings.manualTitle1',
    defaultMessage: 'Waarom Tikkies versturen via sms?',
  },
  manualText1a: {
    id: 'app.components.SmsSettings.manualText1',
    defaultMessage:
      'Als je geen WhatsApp of Messenger voor je bedrijf gebruikt, kan Tikkies Delen via sms een handige oplossing zijn. Deze module is vooral geschikt voor als je per keer 1 of een paar Tikkies stuurt. Wil je veel Tikkies tegelijk sturen? Kies dan voor ',
  },
  manualLink1: {
    id: 'app.components.SmsSettings.manualLink1',
    defaultMessage: 'Tikkies via upload',
  },
  manualText1b: {
    id: 'app.components.SmsSettings.manualText1b',
    defaultMessage: ' sturen.',
  },
  manualTitle2: {
    id: 'app.components.SmsSettings.manualTitle2',
    defaultMessage: 'Zo werkt Tikkies sturen via sms',
  },
  manualText2: {
    id: 'app.components.SmsSettings.manualText2',
    defaultMessage:
      "Als je deze plug-in aan hebt staan, zie je na het aanmaken van een Tikkie de optie 'Deel via sms'. Daar vul je het 06-nummer in en klaar! Je ziet in de portal of het versturen gelukt is. Ook kun je nog een 06-nummer toevoegen om dezelfde Tikkie te sturen.",
  },
  cancelButton: {
    id: 'app.components.SmsSettings.cancelButton',
    defaultMessage: 'Annuleren',
  },
  saveButton: {
    id: 'app.components.SmsSettings.saveButton',
    defaultMessage: 'Wijzigingen opslaan',
  },
});

import React from 'react';
import { injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/gifs/cat-computer.gif';
import {
  CenterWrapper,
  Header,
  Label,
  OnboardingImage,
  OnboardingParagraph,
  OnboardingPrimaryButton,
  Wrapper,
} from '../styledComponents';
import messages from './messages';
import { Space } from '../../styleguide';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';

function LinkNotFound(): JSX.Element {
  const mailSupport = (): void => {
    window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
  };

  return (
    <Wrapper>
      <Header marginBottom={Space.s32} />
      <CenterWrapper>
        <OnboardingImage src="/cat-computer.gif" alt="" />
        <Label>{messages.title.defaultMessage}</Label>
        <OnboardingParagraph narrow textAlign="center">
          {messages.subTitle.defaultMessage}
          <br />
        </OnboardingParagraph>
        <OnboardingPrimaryButton onClick={mailSupport} marginTop="auto">
          {messages.contact.defaultMessage}
        </OnboardingPrimaryButton>
      </CenterWrapper>
    </Wrapper>
  );
}

export default injectIntl(LinkNotFound);

import { fromJS } from 'immutable/dist/immutable';
import {
  LOAD_TRANSACTION,
  LOAD_TRANSACTION_ERROR,
  LOAD_TRANSACTION_SUCCESS,
} from './constants';

const initialState = fromJS({
  // Transaction
  transaction: {},
  transactionIsLoading: false,
  transactionErrorMessage: null,
});

export default function transactionDetailContainerReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_TRANSACTION:
      return state
        .set('transactionIsLoading', true)
        .set('transactionErrorMessage', null)
        .set('transaction', fromJS({}));
    case LOAD_TRANSACTION_SUCCESS:
      return state
        .set('transactionIsLoading', false)
        .set('transactionErrorMessage', null)
        .set('transaction', fromJS(action.payload.transaction));
    case LOAD_TRANSACTION_ERROR:
      return state
        .set('transactionIsLoading', false)
        .set('transactionErrorMessage', action.payload.error)
        .set('transaction', fromJS({}));
    default:
      return state;
  }
}

export { initialState };

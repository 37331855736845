import { takeLatest, call, put } from 'redux-saga/effects';

import request from '../../../utils/request';
import { LOAD_CAMPAIGNS } from './constants';
import { loadCampaignsSuccess, loadCampaignsError } from './actions';
import config from '../../../config';

export default function* watcherSaga() {
  yield takeLatest(LOAD_CAMPAIGNS, loadCampaignsSaga);
}

export function* loadCampaignsSaga() {
  const requestUrl = `${config.API_URL}/ms/cashback/campaigns`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(
      loadCampaignsSuccess(
        response.sort((a, b) =>
          `${b.startDate}`.localeCompare(`${a.startDate}`),
        ),
      ),
    );
  } catch (err) {
    yield put(loadCampaignsError(err.message));
  }
}

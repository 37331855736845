import {
  LOAD_CAMPAIGNS,
  LOAD_CAMPAIGNS_ERROR,
  LOAD_CAMPAIGNS_SUCCESS,
} from './constants';

export function loadCampaigns() {
  return {
    type: LOAD_CAMPAIGNS,
    payload: {},
  };
}

export function loadCampaignsSuccess(campaigns) {
  return {
    type: LOAD_CAMPAIGNS_SUCCESS,
    payload: {
      campaigns,
    },
  };
}

export function loadCampaignsError(error) {
  return {
    type: LOAD_CAMPAIGNS_ERROR,
    payload: {
      error,
    },
  };
}

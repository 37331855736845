const selectUser = (state) => state.get('app').get('user').toJS();
const selectTempUser = (state) => state.get('app').get('tempUser').toJS();
const selectOrganisationHasOtpEnabled = (state) =>
  state.get('app').get('user').get('otpEnabled');
const selectUserIsLoading = (state) => state.get('app').get('userIsLoading');
const selectUserErrorMessage = (state) =>
  state.get('app').get('userErrorMessage');
const selectUserUpdateIsLoading = (state) =>
  state.get('app').get('userUpdateIsLoading');
const selectUserUpdateError = (state) =>
  state.get('app').get('userUpdateError');
const selectCheckEmail = (state) => state.get('app').get('checkEmail');

const selectPaymentRequestDraft = (state) =>
  state.get('app').get('paymentRequestDraft');
const selectCurrentPageName = (state) =>
  state.get('app')?.get('currentPageName');
const selectEmailChangeSuccess = (state) =>
  state.get('app').get('emailChangeSuccess');
const selectEmailChangeError = (state) =>
  state.get('app').get('emailChangeError');

export {
  selectUser,
  selectTempUser,
  selectOrganisationHasOtpEnabled,
  selectUserIsLoading,
  selectUserErrorMessage,
  selectUserUpdateIsLoading,
  selectUserUpdateError,
  selectCheckEmail,
  selectPaymentRequestDraft,
  selectCurrentPageName,
  selectEmailChangeSuccess,
  selectEmailChangeError,
};

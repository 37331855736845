import React from 'react';
import PropTypes from 'prop-types';
import { Container, InputTile } from 'amber';
import { InputErrorBox } from '../../styledComponents';
import {
  emailVerificationError,
  firstNameValidationError,
  lastNameValidationError,
  phoneNumberVerificationError,
} from '../AddUser/messages';
import { validator } from './utils';
import { isValidEmail, isValidPhone } from '../../../Util';

export const FirstName = ({ value, error, setErrors, onChange }) => (
  <Container row mb={24}>
    <InputTile
      title="Voornaam"
      desc="Alleen zichtbaar voor jezelf"
      value={value}
      type="text"
      id="firstName"
      dataTestId="user-first-name"
      onChange={(fieldId, val) => {
        onChange(fieldId, val);
        validator(setErrors, fieldId, val, 45);
      }}
    >
      {error && <InputErrorBox errorText={firstNameValidationError} />}
    </InputTile>
  </Container>
);

FirstName.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  setErrors: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export const LastName = ({ value, error, setErrors, onChange }) => (
  <Container row mb={24}>
    <InputTile
      title="Achternaam"
      desc="Alleen zichtbaar voor jezelf"
      value={value}
      type="text"
      id="lastName"
      dataTestId="user-last-name"
      onChange={(fieldId, val) => {
        onChange(fieldId, val);
        validator(setErrors, fieldId, val, 45);
      }}
    >
      {error && <InputErrorBox errorText={lastNameValidationError} />}
    </InputTile>
  </Container>
);

LastName.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  setErrors: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export const Email = ({ value, error, setErrors, onChange, disabled }) => (
  <Container row mb={24}>
    <InputTile
      title="E-mailadres"
      desc="Voor notificaties en updates"
      value={value}
      type="text"
      id="email"
      dataTestId="user-email"
      onChange={(fieldId, val) => {
        onChange(fieldId, val);
        validator(setErrors, 'email', val, 45, !isValidEmail(val));
      }}
      disabled={disabled}
    >
      {error && <InputErrorBox errorText={emailVerificationError} />}
    </InputTile>
  </Container>
);

Email.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  setErrors: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export const Phone = ({ value, error, setErrors, onChange }) => {
  const phoneHasFormat = (startString, expectedLength) =>
    value.startsWith(startString) && value.length === expectedLength;

  const convertToInternationalPhone = (firstNumbers) =>
    onChange('phoneNumber', value.replace(firstNumbers, '+316'));

  const onBlurPhone = () => {
    if (phoneHasFormat('00316', 13)) {
      convertToInternationalPhone('00316');
    }
    if (phoneHasFormat('316', 11)) {
      convertToInternationalPhone('316');
    }
    if (phoneHasFormat('06', 10)) {
      convertToInternationalPhone('06');
    }
    if (phoneHasFormat('6', 9)) {
      convertToInternationalPhone('6');
    }
  };
  return (
    <Container row mb={24}>
      <InputTile
        title="Telefoonnummer"
        desc="Two factor verificatie"
        value={value}
        type="text"
        id="phoneNumber"
        dataTestId="user-phone-number"
        onBlur={onBlurPhone}
        onChange={(fieldId, val) => {
          onChange(fieldId, val);
          validator(setErrors, fieldId, value, 15, !isValidPhone(val));
        }}
      >
        {error && <InputErrorBox errorText={phoneNumberVerificationError} />}
      </InputTile>
    </Container>
  );
};

Phone.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  setErrors: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

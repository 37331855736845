import { BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';
import { t } from 'i18next';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { List } from '../../styledComponents';
import { UboInfoScreen } from '../UboInfoScreen';

interface UboInvolvementInfoScreenProps {
  show: boolean;
  showInfo: () => void;
  fromPage: string;
}

function UboInvolvementInfoScreen({
  show,
  showInfo,
  fromPage,
}: UboInvolvementInfoScreenProps): JSX.Element {
  const classes = useStyles();
  return (
    <UboInfoScreen show={show} showInfo={showInfo} fromPage={`${fromPage}`}>
      <div className={classes.contentContainer}>
        <BlokkieTypography variant="pl700">
          {t('onboarding.ubo.involvement.header2')}
        </BlokkieTypography>
        <BlokkieTypography
          style={{
            marginBottom: blokkieTheme.spacing(1),
          }}
          variant="pl500"
        >
          {t('onboarding.ubo.involvement.paragraph2')}
        </BlokkieTypography>
        <BlokkieTypography variant="pl700">
          {t('onboarding.ubo.involvement.header3')}
        </BlokkieTypography>
        <BlokkieTypography variant="pl500">
          {t('onboarding.ubo.involvement.paragraph3')}
        </BlokkieTypography>
        <List>
          <li>
            <BlokkieTypography variant="pl500">
              {t('onboarding.ubo.involvement.list1')}
            </BlokkieTypography>
          </li>
          <li>
            <BlokkieTypography variant="pl500">
              {t('onboarding.ubo.involvement.list2')}
            </BlokkieTypography>
          </li>
          <li>
            <BlokkieTypography variant="pl500">
              {t('onboarding.ubo.involvement.list3')}
            </BlokkieTypography>
          </li>
          <li>
            <BlokkieTypography variant="pl500">
              {t('onboarding.ubo.involvement.list4')}
            </BlokkieTypography>
          </li>
        </List>
      </div>
    </UboInfoScreen>
  );
}

const useStyles = createUseStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: blokkieTheme.colors.grey.veryDark,
  },
});

export { UboInvolvementInfoScreen };

/**
 *
 * TransactionsListContainer
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  selectTransactions,
  selectTransactionsIsLoading,
  selectTransactionsErrorMessage,
  selectFilterSearch,
  selectTxFilterFromDate,
  selectTxFilterPage,
  selectTxFilterSearch,
  selectTxFilterToDate,
  selectTxFilterType,
} from './selectors';
import TransactionList from '../../../components/Transactions/TransactionList';
import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../components/components';
import { loadTransactions, downloadCamt } from './actions';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import { selectUser } from '../../App/selectors';

function TransactionsListContainer(props) {
  const history = useHistory();

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }
  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <TransactionList
            transactions={props.transactions}
            transactionsIsLoading={props.transactionsIsLoading}
            transactionsErrorMessage={props.transactionsErrorMessage}
            loadTransactions={props.loadTransactions}
            downloadCamt={props.downloadCamt}
            showTransactionDetail={(transaction) =>
              history.push(`/transaction/${transaction.token}`)
            }
            user={props.user}
            filterFromDate={props.transactionFilterFromDate}
            filterToDate={props.transactionFilterToDate}
            currentPage={props.transactionFilterPage}
            filterType={props.transactionFilterType}
            filterSearch={props.transactionFilterSearchText}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

TransactionsListContainer.propTypes = {
  transactions: PropTypes.object,
  user: PropTypes.object,
  transactionsIsLoading: PropTypes.bool,
  transactionsErrorMessage: PropTypes.string,
  loadTransactions: PropTypes.func,
  downloadCamt: PropTypes.func,
  transactionFilterPage: PropTypes.number,
  transactionFilterFromDate: PropTypes.number,
  transactionFilterToDate: PropTypes.number,
  transactionFilterType: PropTypes.string,
  transactionFilterSearchText: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  // User
  user: selectUser,
  // Load Transactions
  transactions: selectTransactions,
  transactionsIsLoading: selectTransactionsIsLoading,
  transactionsErrorMessage: selectTransactionsErrorMessage,
  // Filters
  filterSearch: selectFilterSearch,
  // Filters Transactions
  transactionFilterPage: selectTxFilterPage,
  transactionFilterFromDate: selectTxFilterFromDate,
  transactionFilterToDate: selectTxFilterToDate,
  transactionFilterType: selectTxFilterType,
  transactionFilterSearchText: selectTxFilterSearch,
});

const mapDispatchToProps = (dispatch) => ({
  loadTransactions: (currentPage, from, to, transactionType, searchString) =>
    dispatch(
      loadTransactions(currentPage, from, to, transactionType, searchString),
    ),
  downloadCamt: (date) => dispatch(downloadCamt(date)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'transactionsListContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'transactionsListContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(TransactionsListContainer);

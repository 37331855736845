import React from 'react';
import { useTranslation } from 'react-i18next';

import { GifContainer } from '../GifContainer';

interface Props {
  onClick: () => void | Promise<void>;
}

export function CashbackCampaignsListError(props: Props) {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <GifContainer
      gif="no-results.gif"
      title={t('deals.response.error.title')}
      text={t('deals.response.error.text')}
      buttonText={t('deals.response.error.buttonText')}
      onClick={onClick}
    />
  );
}

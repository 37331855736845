/*
 * PaymentRequest Messages
 *
 * This contains all the text for the PaymentRequest component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  cards: {
    id: 'app.components.CampaignLineItem.cards',
    defaultMessage: 'Geactiveerd',
  },
  cashbacks: {
    id: 'app.components.CampaignLineItem.cashbacks',
    defaultMessage: 'Uitbetaald',
  },
  budgetRemaining: {
    id: 'app.components.CampaignLineItem.budgetRemaing',
    defaultMessage: 'Resterend budget',
  },
  endsIn: {
    id: 'app.components.CampaignLineItem.endsIn',
    defaultMessage: 'Eindigt over',
  },
  startsIn: {
    id: 'app.components.CampaignLineItem.startsIn',
    defaultMessage: 'Start over',
  },
  endDate: {
    id: 'app.components.CampaignLineItem.endDate',
    defaultMessage: 'Einddatum',
  },
  startDate: {
    id: 'app.components.CampaignLineItem.startDate',
    defaultMessage: 'Startdatum',
  },
  day: {
    id: 'app.components.CampaignLineItem.day',
    defaultMessage: '{var} dag',
  },
  days: {
    id: 'app.components.CampaignLineItem.days',
    defaultMessage: '{var} dagen',
  },
  date: {
    id: 'app.components.CampaignLineItem.datum',
    defaultMessage: '{var}',
  },
});

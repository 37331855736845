import { fromJS } from 'immutable/dist/immutable';
import {
  LOAD_PAYMENT_REQUESTS,
  LOAD_PAYMENT_REQUESTS_ERROR,
  LOAD_PAYMENT_REQUESTS_SUCCESS,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
  RESET_PAYMENT_REQUESTS,
} from './constants';

const initialState = fromJS({
  // Payment Requests
  paymentRequests: {},
  paymentRequestsIsLoading: false,
  paymentRequestsErrorMessage: null,
  // Filters
  filterDateFrom: 0,
  filterDateTo: 0,
  filterDateLabel: '',
  filterStatus: [],
  filterSearch: '',
});

export default function transactionsListContainerReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case LOAD_PAYMENT_REQUESTS:
      return state
        .set('filterDateFrom', action.payload.from)
        .set('filterDateTo', action.payload.to)
        .set('filterDateLabel', action.payload.label)
        .set('filterStatus', fromJS(action.payload.status))
        .set('filterSearch', action.payload.search)
        .set('paymentRequestsIsLoading', true)
        .set('paymentRequestsErrorMessage', null)
        .set('paymentRequests', fromJS({}));
    case LOAD_PAYMENT_REQUESTS_SUCCESS:
      return state
        .set('paymentRequestsIsLoading', false)
        .set('paymentRequestsErrorMessage', null)
        .set('paymentRequests', fromJS(action.payload.paymentRequests));
    case LOAD_PAYMENT_REQUESTS_ERROR:
      return state
        .set('paymentRequestsIsLoading', false)
        .set('paymentRequestsErrorMessage', action.payload.error)
        .set('paymentRequests', fromJS({}));
    case RESET_PAYMENT_REQUESTS:
      return state.set('paymentRequests', fromJS({}));
    case DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS:
    default:
      return state;
  }
}

export { initialState };

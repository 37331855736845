import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PaymentRequestList from '../../../components/PaymentRequest/PaymentRequestList';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import { selectUser } from '../../App/selectors';
import {
  selectPaymentRequests,
  selectPaymentRequestsIsLoading,
  selectPaymentRequestsErrorMessage,
  selectFilterDateFrom,
  selectFilterDateTo,
  selectFilterDateLabel,
  selectFilterStatus,
  selectFilterSearch,
} from './selectors';
import {
  loadPaymentRequests,
  downloadMultiplePaymentRequests,
} from './actions';

function PaymentRequestListContainer(props) {
  const history = useHistory();

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }

  if (props.user.cashbackEnabled) {
    history.push('/tikkieterug');
  }

  const showDashboard = () => {
    history.push('/');
  };

  const showBulkList = () => {
    history.push('/bulk');
  };

  const showCreateTikkie = () => {
    history.push('/create');
  };

  const showBulkTikkie = () => {
    history.push('/upload');
  };

  const showTikkieDetail = (paymentRequest) => {
    history.push(`/paymentrequest/${paymentRequest.token}`);
  };

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <PaymentRequestList
            user={props.user}
            filterDateFrom={props.filterDateFrom}
            filterDateTo={props.filterDateTo}
            filterDateLabel={props.filterDateLabel}
            filterStatus={props.filterStatus}
            filterSearch={props.filterSearch}
            paymentRequests={props.paymentRequests}
            paymentRequestsIsLoading={props.paymentRequestsIsLoading}
            paymentRequestsErrorMessage={props.paymentRequestsErrorMessage}
            loadPaymentRequests={props.loadPaymentRequests}
            downloadMultiplePaymentRequests={
              props.downloadMultiplePaymentRequests
            }
            showDashboard={showDashboard}
            showBulkList={showBulkList}
            showCreateTikkie={showCreateTikkie}
            showBulkTikkie={showBulkTikkie}
            showTikkieDetail={showTikkieDetail}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

PaymentRequestListContainer.propTypes = {
  user: PropTypes.object,
  // Load payment requests
  paymentRequests: PropTypes.object,
  paymentRequestsIsLoading: PropTypes.bool,
  paymentRequestsErrorMessage: PropTypes.string,
  loadPaymentRequests: PropTypes.func,
  // Filtering
  filterDateFrom: PropTypes.number,
  filterDateTo: PropTypes.number,
  filterDateLabel: PropTypes.string,
  filterStatus: PropTypes.array,
  filterSearch: PropTypes.string,
  downloadMultiplePaymentRequests: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  paymentRequests: selectPaymentRequests,
  paymentRequestsIsLoading: selectPaymentRequestsIsLoading,
  paymentRequestsErrorMessage: selectPaymentRequestsErrorMessage,
  // Filters
  filterDateFrom: selectFilterDateFrom,
  filterDateTo: selectFilterDateTo,
  filterDateLabel: selectFilterDateLabel,
  filterStatus: selectFilterStatus,
  filterSearch: selectFilterSearch,
});

const mapDispatchToProps = (dispatch) => ({
  loadPaymentRequests: (
    currentPage,
    label,
    from,
    to,
    status,
    search,
    paymentRequestBatchId,
  ) =>
    dispatch(
      loadPaymentRequests(
        currentPage,
        label,
        from,
        to,
        status,
        search,
        paymentRequestBatchId,
      ),
    ),
  downloadMultiplePaymentRequests: (
    label,
    from,
    to,
    status,
    search,
    allSelected,
    selectedPaymentRequests,
    csvType,
    paymentRequestBatchId,
  ) =>
    dispatch(
      downloadMultiplePaymentRequests(
        label,
        from,
        to,
        status,
        search,
        allSelected,
        selectedPaymentRequests,
        csvType,
        paymentRequestBatchId,
      ),
    ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'paymentRequest/listContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'paymentRequest/listContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PaymentRequestListContainer);

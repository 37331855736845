import React from 'react';
import { createUseStyles } from 'react-jss';

import ReactBodymovin from 'react-bodymovin';

import '!file-loader?name=[name].[ext]!../../../images/tikkie-start.png';

import greenAnimationData from '../../../images/animations/green-dots.json';
import { CenterWrapper } from '../styledComponents';

const greenOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: greenAnimationData,
};

function Loader(): JSX.Element {
  const classes = useStyles();

  return (
    <CenterWrapper>
      <div className={classes.loaderWrapper} data-test-id="loader">
        <ReactBodymovin options={greenOptions} />
      </div>
    </CenterWrapper>
  );
}
const useStyles = createUseStyles({
  loaderWrapper: {
    width: '80px',
    height: '100%',
    display: 'flex',
  },
});

export default Loader;

const startScreen = 'START';
const contactPersonUbo = 'CONTACT_UBO';
const otherUbo = 'OTHER_UBO';
const birthDate = 'BIRTH_DATE';
const name = 'NAME';
const country = 'COUNTRY';
const address = 'ADDRESS';
const involvement = 'INVOLVEMENT';
const addUbo = 'ADD_UBO';
const editUbo = 'EDIT_UBO';
const deleteUbo = 'DELETE_UBO';
const stopUboFlow = 'STOP_FLOW';

export const overallSteps = {
  startScreen,
  contactPersonUbo,
  otherUbo,
};

export const otherUboSteps = {
  name,
  birthDate,
  country,
  address,
  involvement,
};

export const actions = {
  addUbo,
  editUbo,
  deleteUbo,
  stopUboFlow,
};

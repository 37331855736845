import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import React from 'react';
import { createUseStyles } from 'react-jss';

import { Color } from '../styleguide';

interface InputSuggestionProps {
  label: string;
  name: string;
  value: string | undefined;
  dataTestId?: string;
  onChange: (name: string, value: string) => void;
  onKeyHandler: (
    name: string,
    event: React.FormEvent<HTMLInputElement>,
  ) => void;
}

function InputSuggestion({
  label,
  onChange,
  onKeyHandler,
  name,
  value,
  dataTestId,
}: InputSuggestionProps): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.inputRow}>
      <div className={classes.labelHolder}>
        <BlokkieTypography
          variant="pl700"
          style={{
            color: Color.gray01,
            fontSize: '18px',
          }}
        >
          {label}
        </BlokkieTypography>
      </div>
      <div className={classes.currencyHolder}>€</div>
      <div>
        <input
          data-test-id={dataTestId}
          className={classes.input}
          name={name}
          placeholder="0,00"
          type="text"
          value={value}
          autoComplete="off"
          onChange={(e) => onChange(name, e.target.value)}
          onKeyDown={(e) => onKeyHandler(name, e)}
        />
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  inputRow: {
    display: 'flex',
    width: '120px',
    alignItems: 'center',
    gap: blokkieTheme.spacing(0.5),
  },
  labelHolder: {
    display: 'flex',
    flex: 1,
    color: blokkieTheme.colors.grey.default,
  },
  currencyHolder: {
    display: 'flex',
    color: Color.brandColor01,
  },
  input: {
    fontSize: '18px',
    fontFamily: 'MuseoSansRounded700',
    fontWeight: '500',
    lineHeight: '24px',
    outline: 'none',
    border: 'none',
    color: Color.brandColor01,
    '&::placeholder': {
      color: blokkieTheme.colors.grey.light,
    },
    width: '80px',
  },
});

export default InputSuggestion;

export const entrySteps = {
  agreement: 'AGREEMENT',
  processExplanation: 'PROCESS_EXPLANATION',
  chooseBank: 'CHOOSE_BANK',
  bankWithAbn: 'BANK_WITH_ABN',
  chooseOrganisation: 'CHOOSE_ORGANISATION',
  country: 'COUNTRY',
  kvkOverview: 'KVK_OVERVIEW',
};

export const entryFlows = {
  default: [
    entrySteps.agreement,
    entrySteps.chooseBank,
    entrySteps.processExplanation,
    entrySteps.chooseOrganisation,
  ],
  abn: [
    entrySteps.agreement,
    entrySteps.chooseBank,
    entrySteps.chooseOrganisation,
  ],
  defaultWithoutIdeal: [
    entrySteps.agreement,
    entrySteps.bankWithAbn,
    entrySteps.processExplanation,
    entrySteps.chooseOrganisation,
  ],
  abnWithoutIdeal: [
    entrySteps.agreement,
    entrySteps.bankWithAbn,
    entrySteps.chooseOrganisation,
  ],
  secondApplicant: [
    entrySteps.agreement,
    entrySteps.processExplanation,
    entrySteps.kvkOverview,
    entrySteps.country,
  ],
};

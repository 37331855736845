import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlokkieTextLink } from '@tikkie/blokkie';
import {
  PageTitle,
  Container,
  Paragraph,
  HR,
  Toggle,
  CaptionGreen,
  CaptionSmall,
  Popup,
} from 'amber';
import styled from 'styled-components';
import messages from './messages';
import settingsBulkUpload1 from '../../../images/settings-bulkupload-1.png';
import settingsBulkUpload2 from '../../../images/settings-bulkupload-2.png';
import {
  ContainerBox,
  ContainerRow,
  ImageWrapper,
  Image,
  ExplanationText,
  Header2Card,
  ImageWrapperToggle,
  ParagraphToggle,
  PageDescription,
  CaptionSmallPading,
  Header2Manual,
  ContainerRowExplanation,
  ImageWrapperExplanation,
  ImageExplanation,
  ToggleBox,
  ParagraphSmallWidth,
  ErrorPageNoContact,
  ErrorPageWithContact,
  BackButton,
} from '../styledComponents';
import OKIcon from '../../../images/checkmark2-o.svg';

const CheckMarkImage = styled.img`
  width: 18px;
  margin-right: 12px;
`;

export const ParagraphInline = styled(Paragraph)`
  display: inline;
`;

export const PopupWrapper = styled.div`
  margin-top: 28px;
  transition: opacity 0.5s ease-in;
  opacity: ${(props) => (props.animate ? 1 : 0)};
`;

const SubDescription = styled(CaptionSmall)`
  padding-top: 14px !important;
`;

class BulkUploadSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkOption: props.settingsData.bulkEnabled,
      bulkOptionChanged: false,
    };

    this.toggleBulkOption = this.toggleBulkOption.bind(this);
  }

  componentDidUpdate(oldProps) {
    const { isUpdating, settingsLoadingError, settingsUpdatingError } =
      this.props;

    const updatedSettings =
      !isUpdating &&
      oldProps.isUpdating &&
      !settingsUpdatingError &&
      !settingsLoadingError;

    if (updatedSettings) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        bulkOption: this.props.settingsData.bulkEnabled,
        bulkOptionChanged: true,
      });
    }
  }

  toggleBulkOption = () => {
    this.setState((prevState) => {
      const bulkEnabled = !prevState.bulkOption;
      this.props.updateSettings({ ...this.props.settingsData, bulkEnabled });
      return {
        bulkOption: bulkEnabled,
        bulkOptionChanged: true,
      };
    });
  };

  render() {
    const {
      gotoPortalOverview,
      gotoSettingsDashboard,
      settingsUpdatingSuccess,
      settingsUpdatingError,
      settingsLoadingErrorStatusCode,
      settingsLoadingError,
    } = this.props;
    const { bulkOption, bulkOptionChanged } = this.state;
    const showTogglePopup =
      bulkOption && bulkOptionChanged && settingsUpdatingSuccess;

    if (settingsLoadingError) {
      if (settingsLoadingErrorStatusCode === 403) {
        return ErrorPageNoContact({
          pageTitle: messages.header.defaultMessage,
          showUnauthorizedDescription: true,
          onClickBackOverview: gotoPortalOverview,
        });
      }
      return ErrorPageWithContact({
        pageTitle: messages.header.defaultMessage,
        onClickBackOverview: gotoPortalOverview,
      });
    }

    if (settingsUpdatingError) {
      return ErrorPageNoContact({
        pageTitle: messages.header.defaultMessage,
        onClickBackOverview: gotoSettingsDashboard,
      });
    }

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={messages.terug.defaultMessage}
        />
        <PageTitle>
          <FormattedMessage {...messages.header} />
        </PageTitle>
        <ContainerBox>
          <ContainerRow>
            <ImageWrapper>
              <Image>
                <img src="/upload.svg" alt="sms" />
              </Image>
            </ImageWrapper>
            <ExplanationText center>
              <Header2Card>
                <FormattedMessage {...messages.bulkSettings} />
              </Header2Card>
              <CaptionGreen>
                <FormattedMessage {...messages.mostUsed} />
                aan
              </CaptionGreen>
            </ExplanationText>
            <ToggleBox>
              <ImageWrapperToggle>
                <ParagraphToggle>
                  <FormattedMessage {...messages.status} />
                </ParagraphToggle>
                <Toggle
                  dataTestId="Bulk-ModuleToggle"
                  on={bulkOption}
                  onClick={this.toggleBulkOption}
                />
              </ImageWrapperToggle>
              <PopupWrapper animate={showTogglePopup}>
                <Popup show small>
                  <CheckMarkImage src={OKIcon} alt="" />
                  <ParagraphInline>
                    <FormattedMessage {...messages.popupText} />
                  </ParagraphInline>
                </Popup>
              </PopupWrapper>
            </ToggleBox>
          </ContainerRow>
          <ContainerRow>
            <HR />
          </ContainerRow>
          <ContainerRow>
            <PageDescription>
              <CaptionSmallPading>
                <FormattedMessage {...messages.descriptionTitle} />
              </CaptionSmallPading>
              <ParagraphSmallWidth>
                <FormattedMessage {...messages.descriptionText} />
                <BlokkieTextLink
                  href="https://www.tikkie.me/faq/zakelijk"
                  target="_blank"
                  variant="p500"
                  underline
                >
                  {/* TODO: add different link or add sms costs to page */}
                  <FormattedMessage {...messages.smsCost} />
                </BlokkieTextLink>
              </ParagraphSmallWidth>
              <SubDescription>
                <FormattedMessage {...messages.descriptionCaption1} />
                <BlokkieTextLink
                  href="/settings/sms"
                  target="_self"
                  variant="ps500"
                  underline
                >
                  <FormattedMessage {...messages.descriptionCaptionLink} />
                </BlokkieTextLink>
                <FormattedMessage {...messages.descriptionCaption2} />
              </SubDescription>
            </PageDescription>
          </ContainerRow>
        </ContainerBox>

        <Header2Manual>
          <FormattedMessage {...messages.manual} />
        </Header2Manual>

        <ContainerBox>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle1} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText1} />
                <BlokkieTextLink
                  href="https://assets.ctfassets.net/sgaxi93g9pib/5VxLGYQNAkq2AeigoeKcqu/b4871b6591ea972d3abbcfcac0412298/Voorbeeld_CSV_upload_excel_-_alles.xlsx"
                  target="_self"
                  variant="p500"
                  underline
                >
                  <FormattedMessage {...messages.manualLink1} />
                </BlokkieTextLink>
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src={settingsBulkUpload1} alt="voorbeeld" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src={settingsBulkUpload2} alt="voorbeeld" />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle2} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText2} />
              </Paragraph>
              <Paragraph>
                <FormattedMessage {...messages.manualText3} />
              </Paragraph>
              <Paragraph>
                <FormattedMessage {...messages.manualText4} />
              </Paragraph>
              <Paragraph>
                <BlokkieTextLink
                  href="https://www.tikkie.me/zakelijk/handleiding-portal#bulk"
                  target="_blank"
                  variant="p500"
                  underline
                >
                  <FormattedMessage {...messages.manualLink2} />
                </BlokkieTextLink>
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
        </ContainerBox>
      </Container>
    );
  }
}

BulkUploadSettings.propTypes = {
  gotoPortalOverview: PropTypes.func.isRequired,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  settingsUpdatingSuccess: PropTypes.bool.isRequired,
  settingsUpdatingError: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
};

export default injectIntl(BulkUploadSettings);

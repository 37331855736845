import { blokkieTheme } from '@tikkie/blokkie';
import React, { useCallback, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { Color, FontSize } from '../../styleguide';
import { SpaceAttributes } from '../styledComponents';

interface IProps extends React.ComponentPropsWithoutRef<'input'> {
  relatedButtonId: string;
  labelInput?: boolean;
}

function InputKeyboard(props: IProps): JSX.Element {
  const classes = useStyles(props);
  const { labelInput, relatedButtonId, style, onBlur, onFocus, ...rest } =
    props;

  const updateWindowHeight = useCallback(() => {
    const buttonFooterElement = document.getElementById(relatedButtonId);

    if (window.visualViewport?.height && buttonFooterElement) {
      const newMarginBottom = `${
        window.innerHeight - window.visualViewport.height
      }px`;
      buttonFooterElement.style.marginBottom = newMarginBottom;
      buttonFooterElement.style.transition = 'margin-bottom 0.4s ease';
    }
  }, []);

  useEffect(() => {
    // when keyboard is open when moving to the next screen
    if (
      isMobileOnly &&
      window.visualViewport &&
      window.visualViewport.height !== window.innerHeight
    ) {
      updateWindowHeight();
    }
  }, []);

  const prepareButtonForKeyboard = () => {
    if (window.visualViewport && isMobileOnly) {
      window.visualViewport.addEventListener('resize', updateWindowHeight);
    }
  };

  const unprepareButtonForKeyboard = () => {
    const buttonFooterElement = document.getElementById(relatedButtonId);

    if (buttonFooterElement && isMobileOnly) {
      buttonFooterElement.style.marginBottom = '0';
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', updateWindowHeight);
      }
    }
  };

  return (
    <input
      {...rest}
      className={labelInput ? classes.labelContainer : classes.container}
      style={{ ...style, fontSize: FontSize.s22 }}
      onFocus={(event) => {
        prepareButtonForKeyboard();

        if (onFocus) {
          onFocus(event);
        }
      }}
      onBlur={(event) => {
        unprepareButtonForKeyboard();

        if (onBlur) {
          onBlur(event);
        }
      }}
    />
  );
}

const useStyles = createUseStyles({
  container: {
    fontFamily: 'MuseoSansRounded700',
    width: (props) => (props.width ? `${props.width}%` : '100%'),
    height: '50px',
    display: 'inline-block',
    lineHeight: '32px',
    padding: 0,
    objectFit: 'contain',
    backgroundColor: blokkieTheme.colors.grey.white,
    outline: 'none',
    border: 'none',
    fontSize: FontSize.s22,
    color: blokkieTheme.colors.primary.green,
    SpaceAttributes,
    '&::placeholder': {
      fontFamily: 'MuseoSansRounded700',
      fontSize: FontSize.s22,
      color: Color.gray04,
    },
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    container: {
      minWidth: blokkieTheme.spacing(8),
    },
  },
  labelContainer: {
    fontSize: FontSize.s18,
    lineHeight: FontSize.s24,
    fontFamily: 'MuseoSansRounded700',
    padding: '12px 16px',
    textAlign: 'left',
    width: '100%',
    color: blokkieTheme.colors.primary.green,
    backgroundColor: blokkieTheme.colors.grey.white,
    borderRadius: '6px',
    border: `2px solid ${blokkieTheme.colors.grey.light}`,
    outline: 'none',
    '&:focus': {
      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.18)',
    },
    '&:disabled': {
      color: blokkieTheme.colors.grey.dark,
      WebkitTextFillColor: blokkieTheme.colors.grey.dark,
      opacity: 1,
      backgroundColor: blokkieTheme.colors.grey.veryLight,
    },
    '&::placeholder': {
      color: blokkieTheme.colors.grey.default,
    },
  },
  [blokkieTheme.mediaQueries.smallPhone]: {
    labelContainer: {
      fontSize: FontSize.s14,
      lineHeight: FontSize.s18,
    },
  },
});

export default InputKeyboard;

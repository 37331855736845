import React from 'react';
import { injectIntl } from 'react-intl';
import {
  blokkieTheme,
  BlokkieButton,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { List } from '../styledComponents';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import {
  tikkieCashback,
  tikkieRecycling,
} from '../../../containers/OnboardingKYCContainer/config';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface ProcessExplanationProps {
  nextStep: () => void;
  previousStep: () => void;
  forSecondApplicant: boolean;
  aabOnboarding: boolean;
  productType: string;
}

function ProcessExplanation({
  nextStep,
  previousStep,
  forSecondApplicant,
  aabOnboarding,
  productType,
}: ProcessExplanationProps): JSX.Element {
  const { t } = useTranslation();

  useOnboardingPageView('process-explanation');

  const oneCentRequired =
    aabOnboarding ||
    !(
      productType === tikkieCashback ||
      productType === tikkieRecycling ||
      forSecondApplicant
    );
  return (
    <>
      <NavigationHeader clickLeft={previousStep as () => void} />
      <Title>{t('onboarding.processExplanation.title')}</Title>

      <List>
        {!aabOnboarding && (
          <li>{t('onboarding.processExplanation.listItem1')}</li>
        )}
        {!aabOnboarding && (
          <li>{t('onboarding.processExplanation.listItem2')}</li>
        )}
        {oneCentRequired && (
          <li>{t('onboarding.processExplanation.listItem3')}</li>
        )}
      </List>

      <ButtonFooter>
        <BlokkieButton
          variant="primary"
          size="large"
          onClick={nextStep}
          data-test-id="Onboarding-ProcessExplanation-NextButton"
        >
          {t('next')}
        </BlokkieButton>
      </ButtonFooter>
    </>
  );
}

export default injectIntl<ProcessExplanationProps>(ProcessExplanation);

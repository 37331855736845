import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { TabItem, Tabs } from '../../styleguide';
import {
  isExpiryTab,
  isLandingTab,
  isMaxPaymentsTab,
  isThankyouTab,
  navigationTabs,
} from './constants';

export function Navigation({ tab, setTab, noMarginBottom }) {
  return (
    <div>
      <Tabs marginTop="0px" marginBottom={noMarginBottom ? '' : '30px'}>
        <TabItem
          onClick={() => setTab(navigationTabs.landing)}
          active={isLandingTab(tab)}
        >
          {i18n.t('portal.settings.pages.tab.landing')}
        </TabItem>
        <TabItem
          onClick={() => setTab(navigationTabs.thankyou)}
          active={isThankyouTab(tab)}
        >
          {i18n.t('portal.settings.pages.tab.thankyou')}
        </TabItem>
        <TabItem
          onClick={() => setTab(navigationTabs.expiry)}
          active={isExpiryTab(tab)}
        >
          {i18n.t('portal.settings.pages.tab.expiry')}
        </TabItem>
        <TabItem
          onClick={() => setTab(navigationTabs.maxpayments)}
          active={isMaxPaymentsTab(tab)}
        >
          {i18n.t('portal.settings.pages.tab.maxPayments')}
        </TabItem>
      </Tabs>
    </div>
  );
}

Navigation.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  noMarginBottom: PropTypes.bool,
};

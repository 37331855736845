import { blokkieTheme } from '@tikkie/blokkie';
import React, { Children } from 'react';
import { createUseStyles } from 'react-jss';

interface IProps {
  children: React.ReactNode;
  insideWrapper?: boolean;
  align?: 'left' | 'right';
  forceAlign?: 'left' | 'right' | 'space-between';
  customStyle?: React.CSSProperties;
}

function ButtonHeader(props: IProps): JSX.Element {
  const classes = useStyles();
  const { children, align, forceAlign, customStyle, insideWrapper } = props;
  const childrenCount = Children.count(children);
  const singleAlignmentClass =
    align === 'left' ? classes.leftAlign : classes.rightAlign;

  return (
    <div
      className={[
        insideWrapper ? classes.containerInsideWrapper : classes.container,
        childrenCount > 1 && !forceAlign
          ? classes.spaceBetween
          : singleAlignmentClass,
      ].join(' ')}
      style={{
        justifyContent: forceAlign ?? '',
        ...customStyle,
      }}
    >
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    marginLeft: -blokkieTheme.spacing(),
    marginRight: -blokkieTheme.spacing(),
  },
  containerInsideWrapper: {
    display: 'flex',
    marginTop: -blokkieTheme.spacing(1),
    marginLeft: -blokkieTheme.spacing(2),
    marginRight: -blokkieTheme.spacing(2),
  },
  leftAlign: {
    justifyContent: 'left',
  },
  rightAlign: {
    justifyContent: 'right',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
});

export default ButtonHeader;

export const title = 'Medewerker toevoegen';

export const backButtonText = 'Terug';

export const firstName = 'Voornaam';
export const firstNameDescription = 'Alleen zichtbaar voor jezelf';

export const lastName = 'Achternaam';
export const lastNameDescription = 'Alleen zichtbaar voor jezelf';

export const email = 'E-mailadres';
export const emailDescription = 'Voor notificaties';

export const phoneNumber = 'Telefoonnummer';
export const phoneNumberDescription = 'Two factor verificatie';

export const rolePlaceholder = '...';
export const role = `Rol van ${rolePlaceholder}`;

export const roleDescription = 'De rechten van de medewerker';

export const addUserButtonText = 'Medewerker toevoegen';

export const roleToCreateUserText = 'Kies een rol';

export const firstNameValidationError =
  'Oeps, de voornaam moet uit 1 tot 45 tekens bestaan.';
export const lastNameValidationError =
  'Oeps, de achternaam moet uit 1 tot 45 tekens bestaan.';
export const emailVerificationError =
  'Oeps, het e-mailadres lijkt niet te kloppen.';
export const phoneNumberVerificationError =
  'Oeps, het telefoonnummer lijkt niet te kloppen.';

export const roleExplanation =
  'Een gebruiker kan alleen Tikkies aanmaken en inzien. Een beslissingsbevoegde kan ook instellingen aanpassen in de portal.';

export const errorUserAlreadyInOrg = {
  errorTitle: 'Hé, dit is al een bekende',
  description:
    'Deze medewerker is al bekend bij een van jouw andere organisaties. Zoek de medewerker op en voeg deze vervolgens toe aan de gewenste organisatie.',
  buttonLabel: 'Medewerker opzoeken',
};

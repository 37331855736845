import React, { ChangeEventHandler } from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieIcon, blokkieTheme } from '@tikkie/blokkie';
import { Color, Radii, Space } from '../../styleguide';

interface SelectOption {
  code: string;
  name: string;
}

interface OnboardingSelectButtonProps {
  options: Array<SelectOption>;
  placeholder: string;
  handleChange?: ChangeEventHandler<HTMLSelectElement>;
  'data-test-id'?: string;
}

function OnboardingSelectButton({
  options,
  placeholder,
  handleChange,
  'data-test-id': dataTestId,
}: OnboardingSelectButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.selectButtonWrapper}>
      <div className={classes.primaryButtonSelectIconWrapper}>
        <BlokkieIcon variant="logoIdeal" color="white" />
      </div>
      <select
        className={classes.primaryButtonSelect}
        placeholder={placeholder}
        onChange={handleChange}
        data-test-id={dataTestId}
      >
        <option hidden>{placeholder}</option>
        {options.map((option) => (
          <option key={option.code} value={option.code}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

const useStyles = createUseStyles({
  selectButtonWrapper: {
    position: 'relative',
  },
  primaryButtonSelectIconWrapper: {
    position: 'absolute',
    paddingLeft: '24px',
    marginTop: '12px',
    '@media only screen and (max-width: 374px)': {
      display: 'none',
    },
  },
  // TODO: replace with BlokkieButton
  primaryButtonSelect: {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    width: '290px',
    height: '48px',
    padding: `0px 24px`,
    textAlign: 'center',
    borderRadius: Radii.s50,
    border: '0px',
    cursor: 'pointer',
    fontFamily: 'MuseoSansRounded700',
    fontSize: '16px',
    color: Color.white,
    backgroundColor: blokkieTheme.colors.primary.green,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    outline: 'none',
    userSelect: 'none',
    boxShadow: '2px 2px 10px 0 rgba(79, 195, 184, 0.5)',
    '&:disabled': {
      opacity: 0.2,
      border: '2px solid transparent',
      boxShadow: 'none',
      cursor: 'default',
    },
    '&:hover:enabled': {
      boxShadow: '2px 2px 10px 0 rgba(79, 195, 184, 0.8)',
    },
    '& img': {
      marginRight: Space.s8,
      width: '20px',
      height: '20px',
    },
    '@media only screen and (max-width: 374px)': {
      textAlign: 'center',
    },
    '@media only screen and (min-width: 374px)': {
      paddingLeft: '57px',
    },
  },
});

export default OnboardingSelectButton;

import React, { useState } from 'react';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import { Color, FontSize, Space } from '../../styleguide';
import { Wrapper, Shade, OnboardingParagraph } from '../styledComponents';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import { branchListKeys } from './config';
import { InfoScreen } from '../InfoScreen';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface BadSectorProps {
  nextStep(): void;
  setBadSector(value: boolean): void;
  badSector?: boolean | null;
  organisationName: string;
  isCddFlow?: boolean;
}

function BadSector({
  nextStep,
  setBadSector,
  badSector,
  organisationName,
  isCddFlow,
}: BadSectorProps): JSX.Element {
  const { t } = useTranslation();
  const [info, setInfo] = useState(false);
  const classes = useStyles();
  const choiceMade = badSector !== null;
  const showInfo = (): void => setInfo(!info);

  useOnboardingPageView('bad-sector');
  return (
    <>
      <Wrapper>
        <NavigationHeader clickRight={showInfo} noMarginBottom />
        <Title>{t('onboarding.badSector.title', { organisationName })}</Title>
        <ul className={classes.list}>
          {Object.keys(branchListKeys).map((key) => (
            <li key={key}>
              <BlokkieTypography
                variant="pl500"
                style={{ color: blokkieTheme.colors.grey.default }}
              >
                {t(`onboarding.badSector.${key}`)}
              </BlokkieTypography>
            </li>
          ))}
        </ul>
        <Shade />
        <ButtonFooter
          customStyle={{
            alignItems: 'center',
            marginTop: blokkieTheme.spacing(2),
          }}
        >
          <div className={classes.radioContainer}>
            <OnboardingRadioButton
              key={t('yes')}
              id={t('yes')}
              onClick={() => setBadSector(true)}
              checked={choiceMade && badSector}
              text={t('yes')}
              data-test-id="Onboarding-BadSector-Yes"
            />
            <OnboardingRadioButton
              key={t('no')}
              id={t('no')}
              onClick={() => setBadSector(false)}
              checked={choiceMade && !badSector}
              text={t('no')}
              data-test-id="Onboarding-BadSector-No"
            />
          </div>
          <BlokkieButton
            variant="primary"
            size="large"
            onClick={nextStep}
            data-test-id="Onboarding-BadSector-NextButton"
            disabled={!choiceMade}
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={
          isCddFlow
            ? t('onboarding.fluentflow.riskQuestion.infoHeader')
            : t('onboarding.infoscreenTitle')
        }
        onClick={showInfo}
        fromPage={BadSector.name}
      >
        <OnboardingParagraph>
          {isCddFlow
            ? t('onboarding.fluentflow.riskQuestion.sector.explanation')
            : t('onboarding.badSector.infoBody')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

const useStyles = createUseStyles({
  list: {
    position: 'relative',
    margin: Space.s0,
    fontFamily: 'MuseoSansRounded700',
    fontSize: FontSize.s16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: Color.gray02,
    listStylePosition: 'outside',
    paddingInlineStart: '20px',
    maxHeight: '245px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&::after': {
      display: 'block',
      height: '20px',
      content: '""',
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      marginTop: '19px',
      fontSize: '14px',
      maxHeight: '100%',
    },
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: blokkieTheme.spacing(2),
  },
});

export default BadSector;

import { BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface IProps {
  children: string | string[];
  textAlign?: string;
}

function Title(props: IProps): JSX.Element {
  const { children, textAlign = 'left' } = props;
  const classes = useStyles({ textAlign });

  return (
    <div className={classes.container} {...props}>
      <BlokkieTypography variant="h3">{children}</BlokkieTypography>
    </div>
  );
}

const useStyles = createUseStyles({
  container: ({ textAlign }: { textAlign: string }) => ({
    marginTop: blokkieTheme.spacing(2),
    marginBottom: blokkieTheme.spacing(),
    textAlign,
  }),
  [blokkieTheme.mediaQueries.allPhone]: {
    container: {
      marginTop: blokkieTheme.spacing(),
    },
  },
});

export default Title;

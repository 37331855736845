/*
 * Dropdown Messages
 *
 *
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  reset: {
    id: 'app.components.Dropdown.reset',
    defaultMessage: 'Reset',
  },
  filter: {
    id: 'app.components.Dropdown.filter',
    defaultMessage: 'Filter toepassen',
  },
});

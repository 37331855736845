/*
 * Login Messages
 *
 * This contains all the text for the Login component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Login.header',
    defaultMessage: 'Log in',
  },
  email: {
    id: 'app.components.Login.email',
    defaultMessage: 'E-pos',
  },
  emailPlaceholder: {
    id: 'app.components.Login.emailPlaceholder',
    defaultMessage: 'bv. hoff@gmail.com',
  },
  emailError: {
    id: 'app.components.Login.emailError',
    defaultMessage: "Voer 'n geldige e-pos adres in.",
  },
  password: {
    id: 'app.components.Login.password',
    defaultMessage: 'Wachtwoord',
  },
  passwordPlaceholder: {
    id: 'app.components.Login.passwordPlaceholder',
    defaultMessage: 'Vul hier jouw wachtwoord in',
  },
  passwordError: {
    id: 'app.components.Login.passwordError',
    defaultMessage: 'Voer een wachtwoord in.',
  },
  blockedError: {
    id: 'app.components.Login.blockedError',
    defaultMessage:
      'Oops, you entered the wrong credentials 4x. Request a new password.',
  },
  button: {
    id: 'app.components.Login.button',
    defaultMessage: 'Log in',
  },
  forgotPasswordLink: {
    id: 'app.components.Login.forgotPasswordLink',
    defaultMessage: 'Wachtwoord vergeten?',
  },
  loginFailedError: {
    id: 'app.components.Login.loginFailedError',
    defaultMessage:
      "Jouw email of wachtwoord is nie reg nie, probeer maar nog 'n keer.",
  },
  smsSendError: {
    id: 'app.components.Login.smsSendError',
    defaultMessage: 'A problem occured while sending your OTP code.',
  },
  signup: {
    id: 'app.components.Login.signup',
    defaultMessage: 'Aanvragen',
  },
});

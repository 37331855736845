import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.ContactPayment.title',
    defaultMessage: 'Betaling gelukt! ⚡️',
  },
  paragraph: {
    id: 'app.components.ContactPayment.paragraph',
    defaultMessage: 'Nog twee stappen en je aanvraag is klaar!',
  },
  button: {
    id: 'app.components.ContactPayment.button',
    defaultMessage: 'Verder met aanvragen',
  },
});

import styled from 'styled-components';

export const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 116px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

export const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 276px;
`;

import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormattedMessage } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/arrow-back-white.svg';
import { blokkieTheme } from '@tikkie/blokkie';

import messages from './messages';
import { Color } from '../../styleguide';

function HeaderComponent(): JSX.Element {
  const classes = useStyles();

  // TODO: add BlokkieTextLink component, refactor styles
  return (
    <div className={classes.header}>
      <div className={classes.headerInner}>
        <a
          className={classes.headerLink}
          href="/"
          data-test-id="Onboarding-Form-BackToWebsite"
        >
          <FormattedMessage {...messages.back} />
        </a>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  header: {
    height: '72px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'none',
    },
  },
  headerInner: {
    width: '1031px',
    display: 'flex',
    alignItems: 'center',
    [blokkieTheme.mediaQueries.tablet]: {
      width: '95%',
    },
  },
  headerLink: {
    textDecoration: 'none',
    fontFamily: 'MuseoSansRounded700',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.05)',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: Color.white,
    '&::before': {
      content: 'url("/arrow-back-white.svg")',
      marginRight: '10px',
    },
  },
});

export default HeaderComponent;

import React, { useState } from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import signUpPendingSteps from './constants';
import onboardingKYCContainerHOC from '../hoc';
import SignUpPending from '../../../components/OnboardingKYC/SignUpPending';
import FormWrapperContainer from '../FormWrapperContainer';
import { selectNickName } from '../selectors';

function SignUpPendingContainer(props) {
  const [currentStep] = useState(signUpPendingSteps.signUpPending);

  return (
    <FormWrapperContainer portalBacking noProgressBar>
      {currentStep === signUpPendingSteps.signUpPending && (
        <SignUpPending name={props.nickName} />
      )}
    </FormWrapperContainer>
  );
}

SignUpPendingContainer.propTypes = {
  nickName: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  nickName: selectNickName,
});

const withConnect = connect(mapStateToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(SignUpPendingContainer);

import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import contactPaymentSteps from './constants';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectIssuers,
  selectIssuersPending,
  selectOrganisationName,
  selectInitiatingPersonalPayment,
  selectInitiatePersonalPaymentSuccess,
  selectPersonalPayment,
  selectCheckingPersonalPayment,
  selectPersonalPaymentStatus,
} from '../selectors';
import {
  getIssuers,
  initiatePersonalPayment,
  checkPersonalPayment,
  handlePendingPayment,
  getCurrentItem,
} from '../actions';
import Payment from '../../../components/OnboardingKYC/Payment';
import PaymentDone from '../../../components/OnboardingKYC/PaymentDone';
import FormWrapperContainer from '../FormWrapperContainer';

function ContactPaymentContainer(props) {
  const contactPaymentStepsArr = [
    contactPaymentSteps.contactPaymentDone,
    contactPaymentSteps.businessPayment,
  ];

  const [currentStep, setCurrentStep] = useState(
    contactPaymentSteps.contactPayment,
  );

  const nextStep = () => {
    setCurrentStep(props.getNextStep(contactPaymentStepsArr, currentStep));
  };

  const { pending } = props;
  return (
    <FormWrapperContainer portalBacking>
      {currentStep === contactPaymentSteps.contactPayment && (
        <Payment
          getIssuers={props.getIssuers}
          issuers={props.issuers}
          pending={pending}
          initiatePayment={props.initiatePayment}
          payment={props.payment}
          checkPayment={props.checkPayment}
          paymentStatus={props.paymentStatus}
          goToIdeal={props.goToIdeal}
          next={nextStep}
        />
      )}
      {currentStep === contactPaymentSteps.contactPaymentDone && (
        <PaymentDone next={props.getCurrentItem} />
      )}
    </FormWrapperContainer>
  );
}

ContactPaymentContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getIssuers: PropTypes.func.isRequired,
  issuers: PropTypes.array.isRequired,
  initiatePayment: PropTypes.func.isRequired,
  payment: PropTypes.object,
  checkPayment: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string,
  goToIdeal: PropTypes.func.isRequired,
  pending: PropTypes.bool,
  getCurrentItem: PropTypes.func.isRequired,
};

const pending = createSelector(
  [
    selectIssuersPending,
    selectInitiatingPersonalPayment,
    selectInitiatePersonalPaymentSuccess,
    selectCheckingPersonalPayment,
  ],
  (
    issuersPending,
    initiatePaymentPending,
    initiatePaymentSuccess,
    checkingPayment,
  ) =>
    issuersPending ||
    initiatePaymentPending ||
    initiatePaymentSuccess ||
    checkingPayment,
);

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  issuers: selectIssuers,
  payment: selectPersonalPayment,
  paymentStatus: selectPersonalPaymentStatus,
  pending,
});

const mapDispatchToProps = (dispatch) => ({
  getIssuers: () => dispatch(getIssuers()),
  initiatePayment: (issuerCode) =>
    dispatch(initiatePersonalPayment(issuerCode)),
  checkPayment: (paymentToken) => dispatch(checkPersonalPayment(paymentToken)),
  goToIdeal: (idealPaymentUrl) =>
    dispatch(handlePendingPayment(idealPaymentUrl)),
  getCurrentItem: () => dispatch(getCurrentItem()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(ContactPaymentContainer);

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import onboardingKYCContainerHOC from '../hoc';
import { setNickName } from '../actions';
import FormWrapperContainer from '../FormWrapperContainer';
import NickName from '../../../components/OnboardingKYC/NickName';

function NickNameContainer(props) {
  return (
    <FormWrapperContainer>
      <NickName saveNickName={props.setNickName} />
    </FormWrapperContainer>
  );
}

NickNameContainer.propTypes = {
  setNickName: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setNickName: (nickName) => dispatch(setNickName(nickName)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(NickNameContainer);

/* eslint-disable no-shadow */
export enum OnboardingFlow {
  BASIC = 'basic',
  INVITE = 'invite',
  CASHBACK = 'invite-cashback',
  RECYCLING = 'invite-recycling',
  AAB_ONBOARDING = 'aabonboarding',
  SECOND_APPLICANT = 'secondapplicant-invite',
}

export enum OnboardingHash {
  NONE = '',
  INVITE = '#invite',
  CASHBACK = '#invite-cashback',
  RECYCLING = '#invite-recycling',
  AAB_ONBOARDING = '#aabonboarding',
  PILOT = '#invite-pilot',
}

export const hashToOnboardingFlowMapping = new Map([
  [OnboardingHash.INVITE, OnboardingFlow.INVITE],
  [OnboardingHash.CASHBACK, OnboardingFlow.CASHBACK],
  [OnboardingHash.RECYCLING, OnboardingFlow.RECYCLING],
  [OnboardingHash.AAB_ONBOARDING, OnboardingFlow.AAB_ONBOARDING],
]);

export const onboardingFlowToHashMapping = new Map([
  [OnboardingFlow.BASIC, OnboardingHash.NONE],
  [OnboardingFlow.INVITE, OnboardingHash.INVITE],
  [OnboardingFlow.CASHBACK, OnboardingHash.CASHBACK],
  [OnboardingFlow.RECYCLING, OnboardingHash.RECYCLING],
  [OnboardingFlow.AAB_ONBOARDING, OnboardingHash.AAB_ONBOARDING],
]);

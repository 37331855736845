/*
 * BulkDetailView Messages
 *
 * This contains all the text for the BulkDetailView component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  back: {
    id: 'app.components.CampaignDetail.back',
    defaultMessage: 'Alle campagnes',
  },
  header: {
    id: 'app.components.CampaignDetail.header',
    defaultMessage: 'Details campagne',
  },
  oopsTitle: {
    id: 'app.components.CampaignDetail.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.CampaignDetail.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  oopsButton: {
    id: 'app.components.CampaignDetail.oopsButton',
    defaultMessage: 'Probeer opnieuw',
  },
  campaignName: {
    id: 'app.components.CampaignDetail.campaignName',
    defaultMessage: 'Naam campagne',
  },
  campaignId: {
    id: 'app.components.CampaignDetail.campaignId',
    defaultMessage: 'Campagne-id',
  },
  campaignPeriod: {
    id: 'app.components.CampaignDetail.campaignPeriod',
    defaultMessage: 'Looptijd: {start} - {end}',
  },
  campaignTotalBudget: {
    id: 'app.components.CampaignDetail.campaignTotalBudget',
    defaultMessage: 'Budget',
  },
  campaignTotalPayout: {
    id: 'app.components.CampaignDetail.campaignTotalPayout',
    defaultMessage: 'Uitbetaald',
  },
  campaignRemainingBudget: {
    id: 'app.components.CampaignDetail.campaignRemainingBudget',
    defaultMessage: 'Resterend budget',
  },
  performanceHeader: {
    id: 'app.components.CampaignDetail.performanceHeader',
    defaultMessage: 'Resultaat (tot nu toe)',
  },
  imagesHeader: {
    id: 'app.components.CampaignDetail.imagesHeader',
    defaultMessage: 'Afbeeldingen',
  },
  cards: {
    id: 'app.components.CampaignDetail.cards',
    defaultMessage: 'Geactiveerd',
  },
  cashbacks: {
    id: 'app.components.CampaignDetail.cashbacks',
    defaultMessage: 'Uitbetaald',
  },
  conversion: {
    id: 'app.components.CampaignDetail.conversion',
    defaultMessage: 'Conversie',
  },
  cashbackAmount: {
    id: 'app.components.CampaignDetail.cashbackAmount',
    defaultMessage: 'Cashbackbedrag',
  },
  imagePage: {
    id: 'app.components.CampaignDetail.imagesPage',
    defaultMessage: 'Campagnepagina',
  },
  imageCard: {
    id: 'app.components.CampaignDetail.imagesCard',
    defaultMessage: 'Campagnetegel',
  },
  downloadReport: {
    id: 'app.components.CampaignDetail.downloadPaymentsButton',
    defaultMessage: 'Download rapportage',
  },
  scansHeader: {
    id: 'app.components.CampaignDetail.scansHeader',
    defaultMessage: 'Gescande kassabonnen',
  },
  shareHeader: {
    id: 'app.components.CampaignDetail.shareHeader',
    defaultMessage: 'Links naar campagne',
  },
  shareUrl: {
    id: 'app.components.CampaignDetail.shareUrl',
    defaultMessage: 'Link',
  },
  buttonCopyLink: {
    id: 'app.components.CampaignDetail.buttonCopyLink',
    defaultMessage: 'Link kopiëren',
  },
  shareUrlCopied: {
    id: 'app.components.CampaignDetail.shareUrlCopied',
    defaultMessage: 'Link gekopieerd op klembord!',
  },
  qrCode: {
    id: 'app.components.CampaignDetail.qrCode',
    defaultMessage: 'QR-code',
  },
  buttonQRCodeDownload: {
    id: 'app.components.CampaignDetail.buttonQRCodeDownload',
    defaultMessage: 'QR downloaden',
  },
  scanRejectReason: {
    id: 'app.components.CampaignDetail.scanRejectReason',
    defaultMessage: 'Reden van afkeuring',
  },
  scanOk: {
    id: 'app.components.CampaignDetail.scanOk',
    defaultMessage: 'Goedgekeurd',
  },
  scanNotOk: {
    id: 'app.components.CampaignDetail.scanNotOk',
    defaultMessage: 'Afgekeurd',
  },
  scanReceiptDuplicate: {
    id: 'app.components.CampaignDetail.scanReceiptDuplicate',
    defaultMessage: 'Bon al gebruikt',
  },
  scanReceiptDateMismatch: {
    id: 'app.components.CampaignDetail.scanReceiptDateMismatch',
    defaultMessage: 'Verkeerde aankoopdatum',
  },
  scanReceiptDateMissing: {
    id: 'app.components.CampaignDetail.scanReceiptDateMissing',
    defaultMessage: 'Datum niet op bon',
  },
  scanProductNotFound: {
    id: 'app.components.CampaignDetail.scanProductNotFound',
    defaultMessage: 'Product niet op bon',
  },
  scanPriceMismatch: {
    id: 'app.components.CampaignDetail.scanPriceMismatch',
    defaultMessage: 'Verkeerde productprijs',
  },
  scanOcrConfidence: {
    id: 'app.components.CampaignDetail.scanOcrConfidence',
    defaultMessage: 'Foto te onduidelijk',
  },
  scanOther: {
    id: 'app.components.CampaignDetail.scanOther',
    defaultMessage: 'Anders',
  },
});

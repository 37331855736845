export const selectCampaign = (state) =>
  state.get('campaign/detailContainer').get('campaign').toJS();
export const selectCampaignIsLoading = (state) =>
  state.get('campaign/detailContainer').get('campaignIsLoading');
export const selectCampaignErrorMessage = (state) =>
  state.get('campaign/detailContainer').get('campaignErrorMessage');
export const selectFetchingQRCode = (state) =>
  state.get('campaign/detailContainer').get('fetchingQRCode');
export const selectFetchQRCodeError = (state) =>
  state.get('campaign/detailContainer').get('fetchQRCodeError');
export const selectQRCode = (state) =>
  state.get('campaign/detailContainer').get('qrCode');

/**
 *
 * DropDown Component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { DropdownButton, RadioButton } from '../../components';
import { PrimaryButton, TertiaryButton } from '../../styleguide';
import messages from './messages';
import DropdownMenu from '../DropdownMenu';
import DropdownHeader from '../DropdownHeader';
import DropdownFooter from '../DropdownFooter';

export const DropdownWrapper = styled.div`
  display: flex;
`;

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownMenu: false,
      selectedOption: '',
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      selectedOption: this.props.selected,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.state.selectedOption) {
      this.setState({ selectedOption: nextProps.selected });
    }
  }

  toggleDropdownMenu = () => {
    this.setState((prevState) => ({
      showDropdownMenu: !prevState.showDropdownMenu,
    }));
  };

  resetDropdownMenu = (event) => {
    event.stopPropagation();
    this.setState({
      selectedOption: '',
    });
    if (isMobileOnly) {
      this.setState({ showDropdownMenu: false });
    }
  };

  clearDropdownMenu = (event) => {
    this.resetDropdownMenu(event);
    this.props.onSubmit('');
  };

  onOptionClick = (type) => {
    this.setDropdownOption(type);
    const optionFn = this.props.options.filter(
      (option) => option.id === type,
    )[0].fn;
    if (optionFn) {
      optionFn(type);
    }
  };

  setDropdownOption = (type) => this.setState({ selectedOption: type });

  hasDropdownOption = (type) => this.state.selectedOption === type;

  onSubmit = () => {
    this.toggleDropdownMenu();
    this.props.onSubmit(this.state.selectedOption);
  };

  getDropdownTitle = (type) => {
    if (!type || type === '') {
      return '';
    }
    return this.props.options?.filter((option) => option.id === type)[0]?.label;
  };

  handleOutsideClick = () => {
    const selectedOption = this.props.selected;
    this.setState({ selectedOption });
    this.toggleDropdownMenu();
  };

  render() {
    const { showDropdownMenu } = this.state;

    return (
      <DropdownWrapper data-test-id={this.props.dataTestId || 'drop-down'}>
        <DropdownButton
          active={showDropdownMenu}
          selected={this.getDropdownTitle(this.state.selectedOption)}
          onClick={this.toggleDropdownMenu}
          onReset={this.clearDropdownMenu}
        >
          {this.props.children}
        </DropdownButton>
        <DropdownMenu
          show={showDropdownMenu}
          handleOutsideClick={this.handleOutsideClick}
        >
          <DropdownHeader>
            {this.props.children}
            <TertiaryButton id="clearFilter" onClick={this.resetDropdownMenu}>
              <FormattedMessage {...messages.reset} />
            </TertiaryButton>
          </DropdownHeader>
          {this.props.options.map((option) => (
            <RadioButton
              key={option.id}
              id={option.id}
              onClick={() => this.onOptionClick(option.id)}
              checked={this.hasDropdownOption(option.id)}
            >
              {option.label}
            </RadioButton>
          ))}
          <DropdownFooter>
            <PrimaryButton
              onClick={() => this.onSubmit()}
              data-test-id={
                `${this.props.dataTestId}-apply-button` ||
                'drop-down-apply-button'
              }
            >
              <FormattedMessage {...messages.filter} />
            </PrimaryButton>
          </DropdownFooter>
        </DropdownMenu>
      </DropdownWrapper>
    );
  }
}

Dropdown.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
};

export default Dropdown;

import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';

import FormWrapperContainer from '../FormWrapperContainer';
import {
  selectApplicant2Data,
  selectFilledInAuthorizedToSign,
  selectOnboardingKYCData,
  selectOrganisationName,
} from '../selectors';
import authorizedToSignSteps from './constants';
import AuthorizedToSign from '../../../components/OnboardingKYC/AuthorizedToSign';
import AuthorizedToSignSDD from '../../../components/OnboardingKYC/AuthorizedToSignSDD';
import VerifyOrganisation from '../../../components/OnboardingKYC/VerifyOrganisation';
import { setApplicant2Name, setAuthorizedToSign } from '../actions';

const authorizedToSignStepsArr = [
  authorizedToSignSteps.verifyOrganisation,
  authorizedToSignSteps.authorizedToSign,
];

function AuthorizedToSignContainer(props) {
  const [currentStep, setCurrentStep] = useState(
    authorizedToSignSteps.verifyOrganisation,
  );

  const nextStep = () => {
    setCurrentStep(props.getNextStep(authorizedToSignStepsArr, currentStep));
  };

  const prevStep = () => {
    setCurrentStep(
      props.getPreviousStep(authorizedToSignStepsArr, currentStep),
    );
  };

  const { organisationName, onboardingData } = props;
  return (
    <FormWrapperContainer>
      {currentStep === authorizedToSignSteps.verifyOrganisation && (
        <VerifyOrganisation
          nextStep={nextStep}
          onboardingData={onboardingData}
        />
      )}
      {currentStep === authorizedToSignSteps.authorizedToSign &&
        (props.simplified ? (
          <AuthorizedToSignSDD
            organisationName={organisationName}
            previousStep={prevStep}
            setAuthorizedData={props.setAuthorizedToSign}
            authorizedData={
              props.filledInAuthorizedToSign
                ? {
                    authorizedToSign:
                      props.onboardingData.contactPersonDetails
                        .authorizedToSign,
                  }
                : undefined
            }
          />
        ) : (
          <AuthorizedToSign
            organisationName={organisationName}
            previousStep={prevStep}
            setAuthorizedData={props.setAuthorizedToSign}
            authorizedData={
              props.filledInAuthorizedToSign
                ? {
                    authorizedToSign:
                      props.onboardingData.contactPersonDetails
                        .authorizedToSign,
                    jointlyAuthorized:
                      props.onboardingData.contactPersonDetails
                        .jointlyAuthorized,
                  }
                : undefined
            }
            isAbnOnboarding={props.onboardingData.hasAbnBank}
            isAabOnboarding={props.onboardingData.aabOnboarding}
            onboardingFlow={props.onboardingData.onboardingFlow}
            isCddOnboarding={props.onboardingData.cddOnboarding}
          />
        ))}
    </FormWrapperContainer>
  );
}

AuthorizedToSignContainer.propTypes = {
  organisationName: PropTypes.string.isRequired,
  onboardingData: PropTypes.object.isRequired,
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  setAuthorizedToSign: PropTypes.func.isRequired,
  filledInAuthorizedToSign: PropTypes.bool.isRequired,
  simplified: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  organisationName: selectOrganisationName,
  onboardingData: selectOnboardingKYCData,
  applicant2Data: selectApplicant2Data,
  filledInAuthorizedToSign: selectFilledInAuthorizedToSign,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthorizedToSign: (authorizedData) =>
    dispatch(setAuthorizedToSign(authorizedData)),
  setApplicant2Name: (name) => dispatch(setApplicant2Name(name)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(AuthorizedToSignContainer);

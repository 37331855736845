import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider } from '../../styleguide';

const Header = styled.div`
  height: 56px;
  line-height: 56px;
  padding: 0px 24px;
  background-color: #ffffff;
  font-family: MuseoSansRounded700;
  font-size: 18px;
  border-radius: 8px;

  div {
    float: right;
    height: 56px;
    line-height: 56px;
  }

  @media ${(props) => props.theme.device.mobile} {
    height: 68px;
    line-height: 68px;
    font-size: 22px;
    padding-left: 25px;

    div {
      height: 68px;
      line-height: 68px;
    }
  }
`;

function DropdownHeader(props) {
  return (
    <React.Fragment>
      <Header>{props.children}</Header>
      <Divider />
    </React.Fragment>
  );
}

DropdownHeader.propTypes = {
  children: PropTypes.node,
};

export default DropdownHeader;

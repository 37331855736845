import React from 'react';
import { useTranslation } from 'react-i18next';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { InfoScreen } from '../InfoScreen';

interface Applicant2InfoScreenProps {
  show: boolean;
  showInfo(): void;
  fromPage: string;
}

function Applicant2InfoScreen({
  show,
  showInfo,
  fromPage,
}: Applicant2InfoScreenProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <InfoScreen
      show={show}
      title={t('onboarding.infoscreenTitle')}
      onClick={showInfo}
      fromPage={fromPage}
    >
      <BlokkieTypography
        style={{
          color: blokkieTheme.colors.grey.veryDark,
          display: 'block',
        }}
        variant="pl500"
      >
        {t('onboarding.authorizedToSignScreen.applicant2InfoParagraph1')}
      </BlokkieTypography>
      <BlokkieTypography
        style={{
          color: blokkieTheme.colors.grey.veryDark,
          display: 'block',
          marginTop: blokkieTheme.spacing(2),
        }}
        variant="pl500"
      >
        {t('onboarding.authorizedToSignScreen.applicant2InfoParagraph2')}
      </BlokkieTypography>
    </InfoScreen>
  );
}

export { Applicant2InfoScreen };

import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import onboardingKYCContainerHOC from '../hoc';
import FormWrapperContainer from '../FormWrapperContainer';
import {
  selectAddressDetails,
  selectCountry,
  selectPossibleAddresses,
  selectSecondApplicantData,
  selectSubmitPartialAddressError,
} from '../selectors';
import { submitAddress, submitPartialAddress } from '../actions';
import addressSteps from '../../../components/OnboardingKYC/AddressInput/constants';
import AddressInput from '../../../components/OnboardingKYC/AddressInput';

const addressStepsArr = [addressSteps.address, addressSteps.selectAddress];

function SecondApplicantAddress(props) {
  const { getNextStep, getPreviousStep, country, secondApplicantData } = props;
  const dutchAddressInState = country ? country === 'NL' : false;
  const dutchAddressInCurrentItem = secondApplicantData.address
    ? secondApplicantData.address.countryCode === 'NL'
    : false;
  const dutchAddress = country
    ? dutchAddressInState
    : dutchAddressInCurrentItem;

  const [currentDutchAddressStep, setCurrentDutchAddressStep] = useState(
    addressSteps.address,
  );
  const nextDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getNextStep(addressStepsArr, currentDutchAddressStep),
    );

  const prevDutchAddressStep = () =>
    setCurrentDutchAddressStep(
      getPreviousStep(addressStepsArr, currentDutchAddressStep),
    );

  return (
    <FormWrapperContainer>
      <AddressInput
        submitPartialAddress={props.submitPartialAddress}
        addressDetails={props.addressDetails}
        possibleAddresses={props.possibleAddresses}
        submitAddress={props.submitAddress}
        notFoundError={props.submitPartialAddressError}
        dutchAddress={dutchAddress}
        nextDutchAddressStep={nextDutchAddressStep}
        prevDutchAddressStep={prevDutchAddressStep}
        currentDutchAddressStep={currentDutchAddressStep}
      />
    </FormWrapperContainer>
  );
}

SecondApplicantAddress.propTypes = {
  submitPartialAddress: PropTypes.func.isRequired,
  addressDetails: PropTypes.object.isRequired,
  possibleAddresses: PropTypes.array.isRequired,
  submitPartialAddressError: PropTypes.bool.isRequired,
  submitAddress: PropTypes.func.isRequired,
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  country: PropTypes.string,
  secondApplicantData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  addressDetails: selectAddressDetails,
  possibleAddresses: selectPossibleAddresses,
  submitPartialAddressError: selectSubmitPartialAddressError,
  country: selectCountry,
  secondApplicantData: selectSecondApplicantData,
});

const mapDispatchToProps = (dispatch) => ({
  submitPartialAddress: (address) => dispatch(submitPartialAddress(address)),
  submitAddress: (selectedAddress) =>
    dispatch(submitAddress(selectedAddress, true)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(SecondApplicantAddress);

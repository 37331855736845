import {
  SEND_SMS,
  SEND_SMS_ERROR,
  SEND_SMS_SUCCESS,
  FETCH_QR_CODE,
  FETCH_QR_CODE_SUCCESS,
  FETCH_QR_CODE_ERROR,
  RESET_QR_CODE,
} from './constants';

export function sendSms(token, phoneNumber) {
  return {
    type: SEND_SMS,
    payload: {
      token,
      phoneNumber,
    },
  };
}

export function sendSmsSuccess() {
  return {
    type: SEND_SMS_SUCCESS,
  };
}

export function sendSmsError(error) {
  return {
    type: SEND_SMS_ERROR,
    payload: {
      error,
    },
  };
}

export function fetchQRCode(link) {
  return {
    type: FETCH_QR_CODE,
    payload: {
      link,
    },
  };
}

export function fetchQRCodeSuccess(response) {
  return {
    type: FETCH_QR_CODE_SUCCESS,
    payload: {
      data: response,
    },
  };
}

export function fetchQRCodeError(error) {
  return {
    type: FETCH_QR_CODE_ERROR,
    payload: {
      error,
    },
  };
}

export function resetQRCode() {
  return {
    type: RESET_QR_CODE,
  };
}

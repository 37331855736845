/*
 * Payment Messages
 *
 * This contains all the text for the Payment component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  waiting: {
    id: 'app.components.PaymentLineItem.waiting',
    defaultMessage: 'wachten',
  },
  yesterday: {
    id: 'app.components.PaymentLineItem.yesterday',
    defaultMessage: 'gisteren',
  },
});

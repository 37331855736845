/**
 *
 * Filter
 *
 */

import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';
import '!file-loader?name=[name].[ext]!../../../images/plus.svg';
import '!file-loader?name=[name].[ext]!../../../images/bigplus.svg';
import '!file-loader?name=[name].[ext]!../../../images/chevron.svg';
import '!file-loader?name=[name].[ext]!../../../images/plusicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/plusicon-o.svg';
import '!file-loader?name=[name].[ext]!../../../images/uploadicon.svg';

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { isMobileOnly, isTablet } from 'react-device-detect';

import messages from './messages';
import {
  Color,
  MenuItemWrapper,
  InputField,
  PrimaryButton,
  TertiaryButton,
  SecondaryButton,
} from '../../styleguide';
import { customDateText } from '../../Transactions/TransactionsFilters/TransactionDateFilter/calendarUtils';
import { DropdownButton, RadioButton, CheckButton } from '../../components';
import { DatePicker } from '../../DatePicker/DatePicker';

const PaymentRequestFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;

  font-family: MuseoSansRounded;
  font-weight: normal;
  font-size: 16px;
`;

const FilterItemWrapper = styled.div`
  position: relative;
  height: 48px;
  margin: 0px 16px 16px 0px;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
    margin-bottom: 8px;
  }
`;

const CheckButtonWrapper = styled(MenuItemWrapper)`
  padding: 10px 20px 0px 20px;
  border-radius: 8px;
  border: solid 2px ${Color.gray05};
`;

const MobileFilterItemWrapper = styled(FilterItemWrapper)`
  display: none;
  z-index: 100;

  @media ${(props) => props.theme.device.mobile} {
    display: block;
  }
`;

const MobileFilterHeader = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  right: 16px;
  z-index: 9999;

  height: 68px;
  line-height: 68px;
  font-size: 22px;
  padding-left: 25px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${Color.white};
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);
`;

const MobileFilterFooter = styled.div`
  position: fixed;
  height: 80px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  padding: 16px;
  z-index: 9999;

  background-color: ${Color.white};
  box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);

  button {
    width: 100%;
    height: 48px;
    margin: 0px;
    padding: 0px;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -11px;
  left: 0;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ed5565;
  transform: rotate(-45deg);
  background-color: #ffffff;
  color: #57565b;
  border: solid 2px #f2f2f3;
  z-index: -1;
`;

const FilterClose = styled.div`
  float: right;
  font-size: 16px;
  margin-top: 2px;
  margin-right: 25px;
  color: ${Color.brandColor01};
  cursor: pointer;
`;

const FilterOptionsWrapper = styled.div`
  position: absolute;
  top: 55px;
  left: -50px;
  border-radius: 10px;
  box-shadow: 4px 10px 24px 0 rgba(0, 0, 0, 0.15);
  border: solid 2px #f2f2f3;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    border: none;
    border-radius: 8px;
  }
`;

const FilterOptions = styled.div`
  position: relative;
  padding: 0px 0px 12px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #57565b;
  z-index: 999;

  @media ${(props) => props.theme.device.mobile} {
    overflow: auto;
    padding-bottom: 100px;
    padding-top: 72px;
    border-radius: 10px;
    height: 100%;
  }
`;

const FilterOption = styled.div`
  height: 56px;
  line-height: 56px;
  padding: 0px 24px;
  cursor: pointer;
  z-index: 9999;

  button {
    margin: 8px 0px 0px 0px;
    height: 40px;
    line-height: 30px;
  }

  @media ${(props) => props.theme.device.mobile} {
    font-size: 18px;
    margin-bottom: -8px;
  }
`;

const FilterHeader = styled(FilterOption)`
  height: 56px;
  line-height: 56px;
  font-family: MuseoSansRounded700;
  font-size: 18px;

  div {
    float: right;
    height: 56px;
    line-height: 56px;
  }
`;

const Divider = styled.div`
  border-top: 2px solid #f2f2f3;
  margin-bottom: 8px;
`;

const Seperator = styled.div`
  border-top: 2px solid #f2f2f3;
  margin: 8px 16px;
`;

const FilterSpacer = styled.div`
  height: 16px;
`;

const FilterItemFilledWrapper = styled(FilterItemWrapper)`
  flex: 1;
  z-index: 1;

  @media ${(props) => props.theme.device.tablet} {
    position: absolute;
    margin: 64px 0px 0px 0px;
    left: 32px;
    right: 32px;
    z-index: 0;
  }

  @media ${(props) => props.theme.device.mobile} {
    display: block;
    margin-left: 16px;
    margin-right: 8px;
  }
`;

const CreateButton = styled(PrimaryButton)`
  @media ${(props) => props.theme.device.tablet} {
  }

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const MobileCreateButton = styled(PrimaryButton)`
  display: none;
  position: fixed;
  bottom: 24px;
  right: 16px;
  z-index: 90;
  width: 56px;
  height: 56px;
  line-height: 56px;
  padding: 0px;
  text-align: center;
  border: none;

  img {
    border: none;
    outline: none;
    width: 45%;
    height: 45%;
    margin-right: 0px;
  }

  @media ${(props) => props.theme.device.mobile} {
    display: inline-block;
  }
`;

const Glass = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const SearchGlass = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: ${Color.backgroundColor};
  padding: 80px 16px;
  z-index: 99999;

  a {
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: ${Color.brandColor01};
  }

  img {
    width: 25px;
  }
`;

const SearchInput = styled(InputField)`
  z-index: 2;
`;

const CreateButtonWrapper = styled(FilterItemWrapper)`
  margin-right: 0px;

  @media ${(props) => props.theme.device.tablet} {
    position: absolute;
    right: 32px;
  }
`;

const CreateOptionsWrapper = styled(FilterOptionsWrapper)`
  width: 280px;
  left: -97px;
  right: 0px;
`;

const CreateOptionsButtonWrapper = styled.div`
  padding: 24px 24px 0px 24px;
  margin-bottom: -12px;
`;

const CreateOptionsButton = styled(SecondaryButton)`
  width: 100%;
  border-radius: 50px;
  border: 2px solid ${Color.gray05};
`;

const Info = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-family: MuseoSansRounded;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: ${Color.gray03};
`;

// Make sure the dates are in Dutch
moment.locale('nl');

/* eslint-disable react/prefer-stateless-function */
class PaymentRequestFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: 'startDate',
      showDateMenu: false,
      showCalendar: false,
      showStatusMenu: false,
      showMobileMenu: false,
      showCreateMenu: false,
      mobileSearchHasFocus: false,
      uncommittedSearch: '',
      filterDateFrom: this.props.filterDateFrom,
      filterDateTo: this.props.filterDateTo,
      filterDateLabel: this.props.filterDateLabel,
      filterStatus: this.props.filterStatus,
      filterSearch: this.props.filterSearch,
      filtersText: '',
      statusText: '',
      dateText: '',
      startDate: null,
      endDate: moment(),
      prevStartDate: null,
      prevEndDate: null,
    };
  }

  componentDidUpdate(oldProps, oldState) {
    const previousStatusText = this.getStatusText(oldProps.filterStatus);

    const previousDateText = this.getDateText(oldProps.filterDateLabel);

    const currentStatusText = this.getStatusText(this.props.filterStatus);

    const displayStatusText = this.getStatusText(oldState.filterStatus);

    const displayDateText = this.getDateText(oldState.filterDateLabel);

    const statusText = this.getStatusText(this.state.filterStatus);

    const dateText = this.getDateText(this.state.filterDateLabel);

    const isMenusClosed =
      !this.state.showDateMenu && !this.state.showStatusMenu;

    const isEqual =
      currentStatusText === statusText &&
      this.props.filterDateFrom === this.state.filterDateFrom &&
      this.props.filterDateTo === this.state.filterDateTo &&
      this.props.filterSearch === this.state.filterSearch;

    const isDisplayEqual =
      displayStatusText === statusText && displayDateText === dateText;

    const hasSearch =
      oldProps.filterSearch !== '' &&
      this.state.filterSearch !== '' &&
      this.state.uncommittedSearch !== '';

    const isReset =
      this.props.filterDateLabel === '' &&
      this.props.filterDateFrom === 0 &&
      this.props.filterDateTo === 0 &&
      this.props.filterSearch === '' &&
      this.props.filterStatus.length === 0 &&
      (previousStatusText !== '' || previousDateText !== '' || hasSearch);

    const hasPageChange = oldProps.currentPage !== this.props.currentPage;

    if (!isDisplayEqual) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((prevState) => ({
        statusText,
        dateText,
        filtersText: this.getFiltersText(prevState),
      }));
    }

    if (hasPageChange) {
      // Fetch the new page based on the existing search criteria
      this.fetchPaymentRequests(this.props.currentPage);
    } else if (isMenusClosed && !isEqual) {
      // Criteria changed, so reset page back to the first page and fetch
      const page = 1;
      this.props.resetSelection();
      this.fetchPaymentRequests(page);
    }

    if (isReset) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filterDateLabel: '',
        filterDateFrom: 0,
        filterDateTo: 0,
        filterSearch: '',
        filterStatus: [],
        uncommittedSearch: '',
        startDate: null,
        endDate: null,
      });
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState((prevState) => ({
      filtersText: this.getFiltersText(prevState),
      statusText: this.getStatusText(this.props.filterStatus),
      dateText: this.getDateText(this.props.filterDateLabel),
      uncommittedSearch: this.props.filterSearch,
    }));

    this.fetchPaymentRequests(this.props.currentPage);
  }

  showRemoveSearch() {
    return (
      this.props.filterSearch !== '' &&
      (this.state.filterSearch === this.state.uncommittedSearch || isMobileOnly)
    );
  }

  showDoSearch() {
    return (
      (this.props.filterSearch !== '' || this.state.uncommittedSearch !== '') &&
      this.state.filterSearch !== this.state.uncommittedSearch &&
      (this.state.uncommittedSearch.length > 2 ||
        (this.state.uncommittedSearch.length === 0 &&
          this.state.filterSearch !== this.state.uncommittedSearch))
    );
  }

  hasSearchText() {
    return (
      this.state.filterSearch !== this.state.uncommittedSearch &&
      (this.state.uncommittedSearch.length > 2 ||
        (this.state.uncommittedSearch.length === 0 &&
          this.state.filterSearch !== this.state.uncommittedSearch))
    );
  }

  getStatusText(status) {
    const { intl } = this.props;

    const paid = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_paid',
    });
    const pending = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_unpaid',
    });
    const expired = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_expired',
    });
    const notExpired = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_not_expired',
    });
    const archived = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_archived',
    });
    const notArchived = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.status_not_archived',
    });
    const filters = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.filters',
    });
    const translated = [];

    if (status.indexOf('paid') !== -1) translated.push(paid);
    if (status.indexOf('pending') !== -1) translated.push(pending);
    if (status.indexOf('expired') !== -1) translated.push(expired);
    if (status.indexOf('notexpired') !== -1) translated.push(notExpired);
    if (status.indexOf('closed') !== -1) translated.push(archived);
    if (status.indexOf('open') !== -1) translated.push(notArchived);

    return window.length > 2
      ? `${status.length} ${filters}`
      : translated.join(', ');
  }

  getDateText(dateText) {
    const { intl } = this.props;

    const dateToday = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.datum_today',
    });
    const dateWeek = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.date_week',
    });
    const dateMonth = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.date_month',
    });
    const dateYear = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.date_year',
    });

    let text = dateText;
    if (dateText === 'today') {
      text = dateToday;
    } else if (dateText === 'last week') {
      text = dateWeek;
    } else if (dateText === 'last month') {
      text = dateMonth;
    } else if (dateText === 'this year') {
      text = dateYear;
    } else if (dateText === 'custom date') {
      text = this.isCustomDateSet() ? this.getCustomDateText() : dateText;
    }

    return text;
  }

  getFiltersText = (prevState) => {
    const { intl } = this.props;
    const filter = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.filter',
    });
    const filters = intl.formatMessage({
      id: 'app.components.PaymentRequestFilter.filters',
    });

    const status = prevState.filterStatus;
    let { length } = status;

    if (prevState.filterDateLabel !== '') {
      length += 1;
    }

    if (length === 0) {
      return '';
    } else if (length === 1) {
      return `1 ${filter}`;
    }
    return `${length} ${filters}`;
  };

  getCustomDateText = () =>
    customDateText(this.state.startDate, this.state.endDate);

  removeDateSelection = (event) => {
    event.stopPropagation();
    this.setState({
      filterDateLabel: '',
      filterDateFrom: 0,
      filterDateTo: 0,
    });
  };

  removeStatusSelection = (event) => {
    event.stopPropagation();
    this.setState({
      filterStatus: [],
    });
  };

  removeSearch = () => {
    this.setState({
      filterSearch: '',
      uncommittedSearch: '',
      mobileSearchHasFocus: false,
    });
  };

  removeMobileSelection = (event) => {
    event.stopPropagation();
    this.setState({
      filterDateLabel: '',
      filterDateFrom: 0,
      filterDateTo: 0,
      filterStatus: [],
      showDateMenu: false,
      showStatusMenu: false,
      showMobileMenu: false,
      showCalendar: false,
      showCreateMenu: false,
    });
  };

  hasSelectionDateOption = (option) => this.state.filterDateLabel === option;

  hasSelectionStatusOption = (option) =>
    this.state.filterStatus.indexOf(option) !== -1;

  setSelectionStatusOption = (option) => {
    this.setState((prevState) => {
      const filterStatus = prevState.filterStatus.slice(0); // clone it
      let index = false;
      if (option === 'paid') {
        index = filterStatus.indexOf('pending');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      } else if (option === 'pending') {
        index = filterStatus.indexOf('paid');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      } else if (option === 'expired') {
        index = filterStatus.indexOf('notexpired');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      } else if (option === 'notexpired') {
        index = filterStatus.indexOf('expired');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      } else if (option === 'open') {
        index = filterStatus.indexOf('closed');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      } else if (option === 'closed') {
        index = filterStatus.indexOf('open');
        if (index > -1) {
          filterStatus.splice(index, 1);
        }
      }
      filterStatus.push(option);
      return { filterStatus };
    });
  };

  setSelectionDateOption = (option) => {
    const filterDateLabel = option;
    let filterDateFrom = 0;
    let filterDateTo = 0;

    if (option === 'today') {
      filterDateFrom = moment().startOf('day').valueOf();
      filterDateTo = moment().valueOf();
    } else if (option === 'last week') {
      filterDateFrom = moment()
        .subtract(1, 'week')
        .endOf('week')
        .add(1, 'second')
        .valueOf();
      filterDateTo = moment().valueOf();
    } else if (option === 'last month') {
      filterDateFrom = moment()
        .subtract(1, 'month')
        .endOf('month')
        .add(1, 'second')
        .valueOf();
      filterDateTo = moment().valueOf();
    } else if (option === 'this year') {
      filterDateFrom = moment()
        .subtract(1, 'year')
        .endOf('year')
        .add(1, 'second')
        .valueOf();
      filterDateTo = moment().valueOf();
    } else if (option === 'custom date') {
      this.setState((prevState) => ({
        filterDateLabel,
        showCalendar: true,
        prevStartDate: prevState.startDate,
        prevEndDate: prevState.endDate,
      }));
      return;
    }

    this.setState({
      filterDateLabel,
      filterDateFrom,
      filterDateTo,
    });
  };

  setSearchText = (event) =>
    this.setState({ uncommittedSearch: event.target.value });

  doSearch = () =>
    this.setState((prevState) => ({
      filterSearch: prevState.uncommittedSearch,
      mobileSearchHasFocus: false,
    }));

  onSearchKeyDown = (event) => {
    const code = event.keyCode || event.charCode;
    if (event.key === 'Enter') {
      if (
        this.state.uncommittedSearch.length === 0 ||
        this.state.uncommittedSearch.length > 2
      ) {
        this.doSearch();
      }
    } else if (code === 27) {
      this.removeSearch();
    }
  };

  onMobileSearchFocus = () => {
    this.setState({ mobileSearchHasFocus: true });
  };

  cancelMenus = () => {
    this.setState({
      filterDateLabel: this.props.filterDateLabel,
      filterDateFrom: this.props.filterDateFrom,
      filterDateTo: this.props.filterDateTo,
      filterStatus: this.props.filterStatus,
      showDateMenu: false,
      showCalendar: false,
      showStatusMenu: false,
      showMobileMenu: false,
      showCreateMenu: false,
    });
  };

  closeMenus = () => {
    this.setState({
      showDateMenu: false,
      showStatusMenu: false,
      showMobileMenu: false,
      showCalendar: false,
      showCreateMenu: false,
    });
  };

  toggleDateSelection = () => {
    this.setState((prevState) => ({
      showDateMenu: !prevState.showDateMenu,
      showStatusMenu: false,
    }));
  };

  toggleStatusSelection = () => {
    this.setState((prevState) => ({
      showStatusMenu: !prevState.showStatusMenu,
      showDateMenu: false,
    }));
  };

  toggleMobileSelection = () => {
    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }));
  };

  toggleCreateSelection = () => {
    this.setState((prevState) => ({
      showCreateMenu: !prevState.showCreateMenu,
    }));
  };

  toggleCreate = () => {
    const { bulkOption, showCreateTikkie } = this.props;
    if (bulkOption && !isTablet) {
      this.toggleCreateSelection();
    } else {
      showCreateTikkie();
    }
  };

  getDateDropDownLabel = () =>
    this.state.dateText !== 'custom date' ? this.state.dateText : '';

  isCustomDateSet = () =>
    this.state.filterDateLabel === 'custom date' &&
    this.state.startDate !== null &&
    this.state.endDate !== null;

  applyCustomDateAndCloseMenus = () => {
    this.setState((prevState) => {
      const filterDateFrom = prevState.startDate
        .subtract(1, 'day')
        .endOf('day')
        .add(1, 'second')
        .valueOf();
      const filterDateTo = prevState.endDate.endOf('day').valueOf();
      const filterDateLabel = 'custom date';

      const dateText = this.getCustomDateText();
      return {
        filterDateLabel,
        filterDateFrom,
        filterDateTo,
        dateText,
        showDateMenu: false,
        showStatusMenu: false,
        showMobileMenu: false,
        showCalendar: false,
      };
    });
  };

  hideCalendar = () => {
    this.setState((prevState) => ({
      showCalendar: false,
      startDate: prevState.prevStartDate,
      endDate: prevState.prevEndDate,
    }));
  };

  fetchPaymentRequests = (currentPage) => {
    const {
      filterDateLabel,
      filterDateFrom,
      filterDateTo,
      filterStatus,
      filterSearch,
    } = this.state;

    this.props.loadPaymentRequests(
      currentPage,
      filterDateLabel,
      filterDateFrom,
      filterDateTo,
      filterStatus,
      filterSearch,
    );
  };

  renderDateRangePicker = (isMobile) => (
    <DatePicker
      toggle={() => this.hideCalendar()}
      startDate={this.state.startDate}
      setStartDate={(date) => this.setState({ startDate: date })}
      endDate={this.state.endDate}
      setEndDate={(date) => this.setState({ endDate: date })}
      focusedInput={this.state.focusedInput}
      setFocusedInput={(input) =>
        this.setState({
          focusedInput: input || 'startDate',
        })
      }
      disabled={!this.isCustomDateSet()}
      apply={this.applyCustomDateAndCloseMenus}
      reset={this.removeMobileSelection}
      isMobile={isMobile}
      noMinimumNights
    />
  );

  render() {
    const {
      showDateMenu,
      showStatusMenu,
      showMobileMenu,
      showCreateMenu,
      showCalendar,
    } = this.state;

    const { showCreateTikkie, showBulkTikkie, bulkOption } = this.props;

    return (
      <PaymentRequestFiltersWrapper>
        {(showDateMenu ||
          showStatusMenu ||
          showMobileMenu ||
          showCreateMenu) && <Glass onClick={this.cancelMenus} />}
        <FilterItemWrapper>
          <CheckButtonWrapper>
            <CheckButton
              disabled={!this.props.hasResults}
              checked={this.props.allSelected}
              onClick={this.props.toggleAllSelected}
            />
          </CheckButtonWrapper>
        </FilterItemWrapper>
        <FilterItemWrapper id="dateFilter">
          <DropdownButton
            active={showDateMenu}
            selected={this.getDateDropDownLabel()}
            onClick={this.toggleDateSelection}
            onReset={this.removeDateSelection}
          >
            <FormattedMessage {...messages.date} />
          </DropdownButton>
          {showDateMenu && !showCalendar && (
            <FilterOptionsWrapper>
              <UpArrow />
              <FilterOptions>
                <FilterHeader>
                  <FormattedMessage {...messages.date} />
                  <TertiaryButton
                    id="clearDateFilter"
                    onClick={this.removeDateSelection}
                  >
                    <FormattedMessage {...messages.reset} />
                  </TertiaryButton>
                </FilterHeader>
                <Divider />
                <RadioButton
                  id="filterToday"
                  onClick={() => this.setSelectionDateOption('today')}
                  checked={this.hasSelectionDateOption('today')}
                >
                  <FormattedMessage {...messages.date_today} />
                </RadioButton>
                <RadioButton
                  id="filterWeek"
                  onClick={() => this.setSelectionDateOption('last week')}
                  checked={this.hasSelectionDateOption('last week')}
                >
                  <FormattedMessage {...messages.date_week} />
                </RadioButton>
                <RadioButton
                  id="filterMonth"
                  onClick={() => this.setSelectionDateOption('last month')}
                  checked={this.hasSelectionDateOption('last month')}
                >
                  <FormattedMessage {...messages.date_month} />
                </RadioButton>
                <RadioButton
                  id="filterYear"
                  onClick={() => this.setSelectionDateOption('this year')}
                  checked={this.hasSelectionDateOption('this year')}
                >
                  <FormattedMessage {...messages.date_year} />
                </RadioButton>
                <RadioButton
                  id="filterCustomDate"
                  onClick={() => this.setSelectionDateOption('custom date')}
                  checked={this.hasSelectionDateOption('custom date')}
                >
                  {this.isCustomDateSet() && (
                    <span>{this.getCustomDateText()}</span>
                  )}
                  {!this.isCustomDateSet() && (
                    <FormattedMessage {...messages.select_date} />
                  )}
                </RadioButton>
                <FilterOption>
                  <PrimaryButton
                    id="applyDateFilterButton"
                    onClick={this.closeMenus}
                  >
                    <FormattedMessage {...messages.apply_filters} />
                  </PrimaryButton>
                </FilterOption>
              </FilterOptions>
            </FilterOptionsWrapper>
          )}
          {showDateMenu && showCalendar && this.renderDateRangePicker(false)}
        </FilterItemWrapper>

        <FilterItemWrapper id="statusFilter">
          <DropdownButton
            active={showStatusMenu}
            selected={this.state.statusText}
            onClick={this.toggleStatusSelection}
            onReset={this.removeStatusSelection}
          >
            <FormattedMessage {...messages.status} />
          </DropdownButton>
          {showStatusMenu && (
            <FilterOptionsWrapper>
              <UpArrow />
              <FilterOptions>
                <FilterHeader>
                  <FormattedMessage {...messages.status} />
                  <TertiaryButton
                    id="clearStatusFilter"
                    onClick={this.removeStatusSelection}
                  >
                    <FormattedMessage {...messages.reset} />
                  </TertiaryButton>
                </FilterHeader>
                <Divider />
                <RadioButton
                  id="filterPaid"
                  onClick={() => this.setSelectionStatusOption('paid')}
                  checked={this.hasSelectionStatusOption('paid')}
                >
                  <FormattedMessage {...messages.status_paid} />
                </RadioButton>
                <RadioButton
                  id="filterPending"
                  onClick={() => this.setSelectionStatusOption('pending')}
                  checked={this.hasSelectionStatusOption('pending')}
                >
                  <FormattedMessage {...messages.status_unpaid} />
                </RadioButton>
                <Seperator />
                <RadioButton
                  id="filterExpired"
                  onClick={() => this.setSelectionStatusOption('expired')}
                  checked={this.hasSelectionStatusOption('expired')}
                >
                  <FormattedMessage {...messages.status_expired} />
                </RadioButton>
                <RadioButton
                  id="filterNotExpired"
                  onClick={() => this.setSelectionStatusOption('notexpired')}
                  checked={this.hasSelectionStatusOption('notexpired')}
                >
                  <FormattedMessage {...messages.status_not_expired} />
                </RadioButton>
                <Seperator />
                <RadioButton
                  id="filterArchived"
                  onClick={() => this.setSelectionStatusOption('closed')}
                  checked={this.hasSelectionStatusOption('closed')}
                >
                  <FormattedMessage {...messages.status_archived} />
                </RadioButton>
                <RadioButton
                  id="filterNotArchived"
                  onClick={() => this.setSelectionStatusOption('open')}
                  checked={this.hasSelectionStatusOption('open')}
                >
                  <FormattedMessage {...messages.status_not_archived} />
                </RadioButton>
                <FilterOption>
                  <PrimaryButton
                    id="applyStatusFilterButton"
                    onClick={this.closeMenus}
                  >
                    <FormattedMessage {...messages.apply_filters} />
                  </PrimaryButton>
                </FilterOption>
              </FilterOptions>
            </FilterOptionsWrapper>
          )}
        </FilterItemWrapper>
        <FilterItemFilledWrapper>
          <SearchInput>
            <i className="fa fa-search" />
            {isMobileOnly && (
              <FormattedMessage {...messages.search_mobile_placeholder}>
                {(placeholder) => (
                  <input
                    type="text"
                    placeholder={placeholder}
                    onFocus={this.onMobileSearchFocus}
                    value={this.state.uncommittedSearch}
                  />
                )}
              </FormattedMessage>
            )}
            {!isMobileOnly && (
              <FormattedMessage {...messages.search_placeholder}>
                {(placeholder) => (
                  <input
                    type="text"
                    placeholder={placeholder}
                    onChange={this.setSearchText}
                    onKeyDown={this.onSearchKeyDown}
                    value={this.state.uncommittedSearch}
                  />
                )}
              </FormattedMessage>
            )}
            {this.showRemoveSearch() && (
              <a onClick={this.removeSearch}>
                <i className="fa fa-remove" />
              </a>
            )}
            {this.showDoSearch() && (
              <PrimaryButton id="search" onClick={this.doSearch}>
                <FormattedMessage {...messages.search} />
              </PrimaryButton>
            )}
          </SearchInput>
        </FilterItemFilledWrapper>

        {isMobileOnly && this.state.mobileSearchHasFocus && (
          <SearchGlass>
            <a onClick={this.removeSearch}>
              <img src="arrow-back.svg" alt="" />
            </a>
            <SearchInput>
              <i className="fa fa-search" />
              <FormattedMessage {...messages.search_mobile_placeholder}>
                {(placeholder) => (
                  <input
                    type="text"
                    placeholder={placeholder}
                    autoFocus="true"
                    onChange={this.setSearchText}
                    onKeyDown={this.onSearchKeyDown}
                    value={this.state.uncommittedSearch}
                  />
                )}
              </FormattedMessage>
              <PrimaryButton
                onClick={this.doSearch}
                disabled={!this.hasSearchText()}
              >
                <FormattedMessage {...messages.search} />
              </PrimaryButton>
            </SearchInput>
          </SearchGlass>
        )}

        <MobileFilterItemWrapper>
          <DropdownButton
            active={showMobileMenu}
            selected={this.state.filtersText}
            onClick={this.toggleMobileSelection}
            onReset={this.removeMobileSelection}
          >
            <FormattedMessage {...messages.filter_button} />
          </DropdownButton>

          {showMobileMenu && !showCalendar && (
            <FilterOptionsWrapper id="statusFilter">
              <FilterOptions>
                <MobileFilterHeader>
                  <FormattedMessage {...messages.filter_button} />
                  <FilterClose onClick={this.removeMobileSelection}>
                    <FormattedMessage {...messages.cancel} />
                  </FilterClose>
                </MobileFilterHeader>
                <MobileFilterFooter>
                  <PrimaryButton
                    id="applyFiltersButton"
                    onClick={this.closeMenus}
                  >
                    <FormattedMessage {...messages.apply_filters} />
                  </PrimaryButton>
                </MobileFilterFooter>
                <FilterHeader>
                  <FormattedMessage {...messages.status} />
                </FilterHeader>
                <RadioButton
                  id="filterPaid"
                  onClick={() => this.setSelectionStatusOption('paid')}
                  checked={this.hasSelectionStatusOption('paid')}
                >
                  <FormattedMessage {...messages.status_paid} />
                </RadioButton>
                <RadioButton
                  id="filterPending"
                  onClick={() => this.setSelectionStatusOption('pending')}
                  checked={this.hasSelectionStatusOption('pending')}
                >
                  <FormattedMessage {...messages.status_unpaid} />
                </RadioButton>
                <FilterSpacer />
                <RadioButton
                  id="filterExpired"
                  onClick={() => this.setSelectionStatusOption('expired')}
                  checked={this.hasSelectionStatusOption('expired')}
                >
                  <FormattedMessage {...messages.status_expired} />
                </RadioButton>
                <RadioButton
                  id="filterNotExpired"
                  onClick={() => this.setSelectionStatusOption('notexpired')}
                  checked={this.hasSelectionStatusOption('notexpired')}
                >
                  <FormattedMessage {...messages.status_not_expired} />
                </RadioButton>
                <FilterSpacer />
                <RadioButton
                  id="filterArchived"
                  onClick={() => this.setSelectionStatusOption('closed')}
                  checked={this.hasSelectionStatusOption('closed')}
                >
                  <FormattedMessage {...messages.status_archived} />
                </RadioButton>
                <RadioButton
                  id="filterNotArchived"
                  onClick={() => this.setSelectionStatusOption('open')}
                  checked={this.hasSelectionStatusOption('open')}
                >
                  <FormattedMessage {...messages.status_not_archived} />
                </RadioButton>
                <FilterHeader>
                  <FormattedMessage {...messages.date} />
                </FilterHeader>
                <RadioButton
                  id="filterToday"
                  onClick={() => this.setSelectionDateOption('today')}
                  checked={this.hasSelectionDateOption('today')}
                >
                  <FormattedMessage {...messages.date_today} />
                </RadioButton>
                <RadioButton
                  id="filterWeek"
                  onClick={() => this.setSelectionDateOption('last week')}
                  checked={this.hasSelectionDateOption('last week')}
                >
                  <FormattedMessage {...messages.date_week} />
                </RadioButton>
                <RadioButton
                  id="filterMonth"
                  onClick={() => this.setSelectionDateOption('last month')}
                  checked={this.hasSelectionDateOption('last month')}
                >
                  <FormattedMessage {...messages.date_month} />
                </RadioButton>
                <RadioButton
                  id="filterYear"
                  onClick={() => this.setSelectionDateOption('this year')}
                  checked={this.hasSelectionDateOption('this year')}
                >
                  <FormattedMessage {...messages.date_year} />
                </RadioButton>
                <RadioButton
                  id="filterCustomDate"
                  onClick={() => this.setSelectionDateOption('custom date')}
                  checked={this.hasSelectionDateOption('custom date')}
                >
                  {this.isCustomDateSet() && (
                    <span>{this.getCustomDateText()}</span>
                  )}
                  {!this.isCustomDateSet() && (
                    <FormattedMessage {...messages.select_date} />
                  )}
                </RadioButton>
              </FilterOptions>
            </FilterOptionsWrapper>
          )}
          {showMobileMenu && showCalendar && this.renderDateRangePicker(true)}
        </MobileFilterItemWrapper>

        <CreateButtonWrapper>
          <CreateButton id="createTikkie" onClick={() => this.toggleCreate()}>
            <img src="plusicon-o.svg" alt="" />
            <FormattedMessage {...messages.create} />
          </CreateButton>
          {showCreateMenu && (
            <CreateOptionsWrapper>
              <UpArrow />
              <FilterOptions>
                <CreateOptionsButtonWrapper>
                  <CreateOptionsButton
                    id="singleTikkie"
                    onClick={() => showCreateTikkie()}
                  >
                    <img src="plusicon.svg" alt="" />
                    <FormattedMessage {...messages.createSingleTikkie} />
                  </CreateOptionsButton>
                  <Info>
                    <FormattedMessage {...messages.createSingleTikkieInfo} />
                  </Info>
                  {bulkOption && !isTablet && (
                    <div>
                      <CreateOptionsButton
                        id="batchOfTikkies"
                        onClick={() => showBulkTikkie()}
                      >
                        <img src="uploadicon.svg" alt="" />
                        <FormattedMessage {...messages.bulkUpload} />
                      </CreateOptionsButton>
                      <Info>
                        <FormattedMessage {...messages.bulkUploadInfo} />
                      </Info>
                    </div>
                  )}
                </CreateOptionsButtonWrapper>
              </FilterOptions>
            </CreateOptionsWrapper>
          )}
        </CreateButtonWrapper>

        <MobileCreateButton onClick={() => showCreateTikkie()}>
          <img src="bigplus.svg" alt="" />
        </MobileCreateButton>
      </PaymentRequestFiltersWrapper>
    );
  }
}

PaymentRequestFilters.propTypes = {
  loadPaymentRequests: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  filterDateFrom: PropTypes.number.isRequired,
  filterDateTo: PropTypes.number.isRequired,
  filterDateLabel: PropTypes.string.isRequired,
  filterStatus: PropTypes.array.isRequired,
  filterSearch: PropTypes.string.isRequired,
  showCreateTikkie: PropTypes.func.isRequired,
  showBulkTikkie: PropTypes.func.isRequired,
  allSelected: PropTypes.string.isRequired,
  toggleAllSelected: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  bulkOption: PropTypes.bool.isRequired,
  hasResults: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PaymentRequestFilters);

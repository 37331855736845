/**
 *
 * Api Overview
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/back.svg';
import '!file-loader?name=[name].[ext]!../../../images/money.svg';
import '!file-loader?name=[name].[ext]!../../../images/copyicon.svg';
import '!file-loader?name=[name].[ext]!../../../images/api-options.png';
import '!file-loader?name=[name].[ext]!../../../images/token-example.png';
import '!file-loader?name=[name].[ext]!../../../images/dev-portal.png';
import styled from 'styled-components';
import ReactBodymovin from 'react-bodymovin';
import { BlokkieTextLink } from '@tikkie/blokkie';
import {
  PageTitle,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardTitle,
  CardBody,
  CardActions,
  Caption,
  CaptionSmall,
  Paragraph,
  Button,
  HR,
} from 'amber';
import greenAnimationData from '../../../images/animations/green-dots.json';
import messages from './messages';
import {
  ContainerBox,
  ContainerRowExplanation,
  ImageWrapperExplanation,
  ImageExplanation,
  ExplanationText,
  Header2Card,
  Header2Manual,
  BackButton,
  AddButton,
} from '../styledComponents';
import { Color, FontSize, Radii, Space } from '../../styleguide';
import { Loader } from '../../components';

const Tile = styled.div`
  width: 100%;
  margin: 0 0 ${Space.s8} 0;
  display: flex;
  box-sizing: border-box;
  -webkit-box-pack: inherit;
  justify-content: inherit;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  background-color: ${Color.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  flex: 0 0 100%;
  border-radius: ${Radii.s8};
  padding: ${Space.s24};
  color: ${Color.gray01};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded;

  &:hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

const Descr = styled.div`
  width: 33%;
`;

const Token = styled.div`
  color: ${Color.brandColor01};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded700;
  width: 55%;
`;

const Label = styled.div`
  width: 10%;
`;

const EnabledLabel = styled.div`
  width: 73px;
  height: 100%;
  border-radius: ${Radii.s40};
  background-color: #dbf0ee;
  color: ${Color.brandColor01};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded700;
  padding: ${Space.s8};
  text-align: center;
  float: right;
`;

const DisabledLabel = styled.div`
  width: 105px;
  height: 100%;
  border-radius: ${Radii.s40};
  background-color: #fdf9ec;
  color: ${Color.secondaryColor02};
  font-size: ${FontSize.s16};
  font-family: MuseoSansRounded700;
  padding: ${Space.s8};
  text-align: center;
`;

/* eslint-disable react/prefer-stateless-function */
export class ApiOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationName: props.settingsData.internalPortalName,
    };
    this.props.loadApiTokens();
  }

  createApiToken = () => {
    this.props.goToCreateApiToken();
  };

  render() {
    const {
      gotoSettingsDashboard,
      apiTokens,
      apiTokensLoading,
      apiTokensLoadingSuccess,
    } = this.props;
    const { organisationName } = this.state;
    const greenOptions = {
      loop: true,
      autoplay: true,
      prerender: true,
      animationData: greenAnimationData,
    };

    return (
      <Container row ptM={32} prM={16} plM={16} pbM={16}>
        <BackButton
          goBack={gotoSettingsDashboard}
          text={messages.terug.defaultMessage}
        />

        <PageTitle>
          <FormattedMessage {...messages.header} />
          {organisationName}
        </PageTitle>
        <Container row mb={24}>
          <Card>
            <CardMedia src="/settings-api.svg" rounded={false} />
            <CardContent media>
              <CardTitle>
                <FormattedMessage {...messages.apiSettings} />
              </CardTitle>
            </CardContent>
            <Container row pt={24}>
              <HR />
              <Container row pt={32} pb={16}>
                <CaptionSmall>
                  <FormattedMessage {...messages.descriptionTitle} />
                </CaptionSmall>
              </Container>
              <Container row>
                <Paragraph>
                  <FormattedMessage {...messages.descriptionText} />
                </Paragraph>
              </Container>
            </Container>
          </Card>
        </Container>

        {apiTokensLoading && (
          <Container row mb={24}>
            <Tile>
              <Loader>
                <ReactBodymovin options={greenOptions} />
              </Loader>
            </Tile>
          </Container>
        )}

        {isEmpty(apiTokens) && apiTokensLoadingSuccess && (
          <Container row mb={24}>
            <Card>
              <Container pb={12}>
                <CardContent center>
                  <CardTitle>
                    {organisationName}{' '}
                    <FormattedMessage {...messages.addTokensTitle} />
                  </CardTitle>
                  <CardBody>
                    <Caption>
                      <FormattedMessage {...messages.addTokensText} />
                    </Caption>
                  </CardBody>
                  <CardActions>
                    <Button onClick={this.createApiToken}>
                      <FormattedMessage {...messages.createApiToken} />
                    </Button>
                  </CardActions>
                </CardContent>
              </Container>
            </Card>
          </Container>
        )}

        {apiTokensLoadingSuccess && (
          <Container row mb={24}>
            {apiTokens.length === 1 && (
              <Header2Manual>
                {organisationName} <FormattedMessage {...messages.has} />{' '}
                {apiTokens.length} <FormattedMessage {...messages.apiToken} />
              </Header2Manual>
            )}
            {apiTokens.length > 1 && (
              <Header2Manual>
                {organisationName} <FormattedMessage {...messages.has} />{' '}
                {apiTokens.length} <FormattedMessage {...messages.apiTokens} />
              </Header2Manual>
            )}
            {apiTokens.length >= 1 && (
              <AddButton
                text="Token aanmaken"
                alt="Token aanmaken"
                onClick={this.createApiToken}
                disabled={false}
              />
            )}
            {apiTokens.map((apiToken) => (
              <Tile onClick={() => this.props.goToModifyToken(apiToken)}>
                <Descr>{apiToken.name}</Descr>
                <Token>{apiToken.appToken}</Token>
                {apiToken.enabled && (
                  <Label>
                    <EnabledLabel>actief</EnabledLabel>
                  </Label>
                )}
                {!apiToken.enabled && (
                  <Label>
                    <DisabledLabel>niet actief</DisabledLabel>
                  </Label>
                )}
              </Tile>
            ))}
          </Container>
        )}

        <Header2Manual>
          <FormattedMessage {...messages.manual} />
        </Header2Manual>
        <ContainerBox>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle1} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText1} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/token-example.png" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
          <ContainerRowExplanation reverse>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/api-options.png" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle2} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText2} />
              </Paragraph>
            </ExplanationText>
          </ContainerRowExplanation>
          <ContainerRowExplanation>
            <ExplanationText>
              <Header2Card>
                <FormattedMessage {...messages.manualTitle3} />
              </Header2Card>
              <Paragraph>
                <FormattedMessage {...messages.manualText3A} />
                <BlokkieTextLink
                  href="https://developer.abnamro.com/api-products/tikkie"
                  target="_blank"
                  variant="pl500"
                  underline
                >
                  developer portal van ABN AMRO
                </BlokkieTextLink>
                <FormattedMessage {...messages.manualText3B} />
              </Paragraph>
            </ExplanationText>
            <ImageWrapperExplanation>
              <ImageExplanation>
                <img src="/dev-portal.png" alt="" />
              </ImageExplanation>
            </ImageWrapperExplanation>
          </ContainerRowExplanation>
        </ContainerBox>
      </Container>
    );
  }
}

ApiOverview.propTypes = {
  gotoPortalOverview: PropTypes.func,
  gotoSettingsDashboard: PropTypes.func.isRequired,
  settingsData: PropTypes.object.isRequired,
  apiTokens: PropTypes.array.isRequired,
  loadApiTokens: PropTypes.func.isRequired,
  apiTokensLoading: PropTypes.bool.isRequired,
  apiTokensLoadingSuccess: PropTypes.bool.isRequired,
  goToCreateApiToken: PropTypes.func,
  goToModifyToken: PropTypes.func,
};

export default injectIntl(ApiOverview);

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { blokkieTheme, BlokkieButton, BlokkieTextLink } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import '!file-loader?name=[name].[ext]!../../../images/abn-app.svg';
import '!file-loader?name=[name].[ext]!../../../images/idv-app-expired.svg';
import { CenterWrapper } from '../styledComponents';
import {
  clearTransactionProps,
  createTransaction,
  getIDVStatus,
  getQRCode,
} from '../../../containers/OnboardingKYCContainer/actions';
import {
  selectGenericPending,
  selectIdvStartUrl,
  selectIdvStatus,
  selectIsSecondApplicant,
  selectQRCode,
} from '../../../containers/OnboardingKYCContainer/selectors';
import Loader from '../Loader';
import {
  COMPLETED,
  DESKTOP,
  EXPIRED,
  IDENTIFICATION_COMPLETED,
} from './constants';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import NavigationHeader from '../components/NavigationHeader';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IProps {
  getQRCode: (param: string) => void;
  getIDVStatus: (param: boolean) => void;
  createTransaction: (param: boolean) => void;
  qrCode: string;
  idvStartUrl: string;
  idvStatus: string;
  prevStep: () => void;
  nextStep: () => void;
  genericPending: number;
  resetTransactionProps: () => void;
  forSecondApplicant: boolean;
}

function IDVOpenAppDesktop(props: IProps): JSX.Element {
  const {
    prevStep,
    nextStep,
    qrCode,
    idvStartUrl,
    idvStatus,
    genericPending,
    resetTransactionProps,
    forSecondApplicant,
  } = props;
  const classes = useStyles();
  const [doPoll, setDoPoll] = useState(false);
  const [isReset, setReset] = useState(false);
  const { t } = useTranslation();

  const getIdvStatusForApplicant = (): void =>
    props.getIDVStatus(forSecondApplicant);
  const createTransactionForApplicant = (): void =>
    props.createTransaction(forSecondApplicant);
  const next = (): void => {
    nextStep();
  };
  const resetTransaction = (): void => {
    setReset(true);
    resetTransactionProps();
    createTransactionForApplicant();
  };

  const isPending = (): boolean => idvStatus === IDENTIFICATION_COMPLETED;
  const isExpired = (): boolean => idvStatus === EXPIRED;
  const isLoading = (): boolean =>
    isEmpty(idvStartUrl) || isEmpty(qrCode) || genericPending === 1 || isReset;

  useOnboardingPageView('idv-open-app-desktop');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (doPoll) {
      getIdvStatusForApplicant();
      const poll = setInterval(() => {
        getIdvStatusForApplicant();
      }, 2 * 1000);
      return () => doPoll && clearInterval(poll);
    }
  }, [doPoll]);

  useEffect(() => {
    if (isEmpty(idvStartUrl)) {
      createTransactionForApplicant();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(idvStartUrl) && isEmpty(qrCode)) {
      props.getQRCode(idvStartUrl);
    }
  }, [idvStartUrl]);

  useEffect(() => {
    if (idvStatus === COMPLETED || idvStatus === EXPIRED) {
      setDoPoll(false);
    } else {
      setReset(false);
    }
  }, [idvStatus]);

  useEffect(() => {
    if (!isEmpty(qrCode)) {
      setDoPoll(true);
    }
  }, [qrCode]);

  useEffect(() => () => setDoPoll(false), []);

  return (
    <>
      <NavigationHeader
        clickLeft={prevStep as () => void}
        clickRight={next}
        noMarginBottom
      />
      <CenterWrapper>
        {isLoading() && <Loader />}
        {!isLoading() && (
          <>
            {!isPending() && !isExpired() && (
              <>
                <img className={classes.qrCode} src={qrCode} alt="" />
                <Title textAlign="center">
                  {t(`onboarding.idv.openApp.titleDesktop`)}
                </Title>
                <BlokkieTextLink
                  variant="pl700"
                  underline
                  onClick={next}
                  style={{
                    color: blokkieTheme.colors.primary.green,
                    marginTop: blokkieTheme.spacing(2),
                  }}
                >
                  {t(`onboarding.idv.openApp.explainer`)}
                </BlokkieTextLink>
              </>
            )}
            {isPending() && (
              <>
                <img src="/abn-app.svg" alt="" />
                <Title textAlign="center">
                  {t(`onboarding.idv.openApp.pending`)}
                </Title>
                <BlokkieTextLink
                  variant="pl700"
                  underline
                  href={`mailto:${BUSINESS_EMAIL_ADDRESS}`}
                  style={{
                    color: blokkieTheme.colors.primary.green,
                    marginTop: blokkieTheme.spacing(2),
                  }}
                >
                  {t(`onboarding.idv.openApp.getHelp`)}
                </BlokkieTextLink>
              </>
            )}
            {isExpired() && (
              <>
                <img src="/idv-app-expired.svg" alt="" />
                <Title textAlign="center">
                  {t(`onboarding.idv.openApp.expired`)}
                </Title>
                <BlokkieButton
                  variant="primary"
                  size="large"
                  onClick={resetTransaction}
                  data-test-id="Onboarding-IDV-QRCode-NewQRButton"
                  style={{
                    marginTop: blokkieTheme.spacing(2),
                  }}
                >
                  {t(`onboarding.idv.openApp.expiredButton`)}
                </BlokkieButton>
              </>
            )}
          </>
        )}
      </CenterWrapper>
    </>
  );
}

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload?:
      | { idvStartUrl: any }
      | { type: any; forSecondApplicant: any }
      | { forSecondApplicant: any };
  }) => any,
) => ({
  getQRCode: (idvStartUrl: any) => dispatch(getQRCode(idvStartUrl)),
  createTransaction: (forSecondApplicant: any) =>
    dispatch(createTransaction(DESKTOP, forSecondApplicant)),
  getIDVStatus: (forSecondApplicant: any) =>
    dispatch(getIDVStatus(forSecondApplicant)),
  resetTransactionProps: () => dispatch(clearTransactionProps()),
});

const mapStateToProps = createStructuredSelector({
  qrCode: selectQRCode,
  idvStartUrl: selectIdvStartUrl,
  idvStatus: selectIdvStatus,
  genericPending: selectGenericPending,
  forSecondApplicant: selectIsSecondApplicant,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const useStyles = createUseStyles({
  qrCode: {
    height: '200px',
    width: '200px',
    padding: '15px',
    border: '2px solid rgba(233, 233, 233, 0.7)',
    borderRadius: '5%',
  },
});

export default compose(injectIntl, withConnect)(IDVOpenAppDesktop);

import React from 'react';
import { createUseStyles } from 'react-jss';
import { blokkieTheme } from '@tikkie/blokkie';
import { isMobile, isTablet } from 'react-device-detect';
import '!file-loader?name=[name].[ext]!../../../images/arrow-back-white.svg';

import {
  Account,
  AccountWrapper,
  Avatar,
  Logo,
  LogoLink,
  Logout,
  MenuInner,
} from '../../Menu/menu';

import { Color, FontSize } from '../../styleguide';
import messages from './messages';

interface PortalBackingProps {
  children: React.ReactNode;
  organisationName?: string;
  logout: () => void;
}

function PortalBacking({
  children,
  organisationName,
  logout,
}: PortalBackingProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.portalBackingWrapper}>
      <div className={classes.menu}>
        <MenuInner>
          <LogoLink className={classes.logoLink} to="/">
            <Logo className={classes.logo} src="/tikkie-logo.svg" alt="" />
          </LogoLink>
          {!isMobile && (
            <AccountWrapper>
              <Account className={classes.disabledAccount} id="accountInfo">
                <Avatar src="/businesslogo.png" />
                {organisationName}
              </Account>
              <Logout onClick={() => logout()}>
                {messages.logOut.defaultMessage}
              </Logout>
            </AccountWrapper>
          )}
          {isTablet && (
            <AccountWrapper>
              <Avatar src="/tikkie-logo.svg" />
              <Logout>
                <i className="fa fa-power-off" />
              </Logout>
            </AccountWrapper>
          )}
        </MenuInner>
      </div>
      {children}
    </div>
  );
}

const useStyles = createUseStyles({
  portalBackingWrapper: {
    backgroundColor: Color.backgroundColor,
    fontSize: FontSize.s18,
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundSize: 'cover',
    '&:after': {
      backgroundColor: '#000000',
      width: '100%',
      height: '100%',
      content: '" "',
      position: 'fixed',
      top: '80px',
      opacity: '0.5',
    },
  },
  logoLink: {
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'block',
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  logo: {
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '84px',
    },
  },
  menu: {
    backgroundColor: Color.brandColor02,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
    width: '100%',
    [blokkieTheme.mediaQueries.allPhone]: {
      display: 'none',
    },
  },
  disabledAccount: {
    cursor: 'initial',
    overflow: 'hidden',
  },
});

export default PortalBacking;

/*
 * Filter Messages
 *
 * This contains all the text for the Filter component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  reset: {
    id: 'app.components.PaymentRequestFilters.reset',
    defaultMessage: 'Reset',
  },
  date: {
    id: 'app.components.PaymentRequestFilters.date',
    defaultMessage: 'Datum',
  },
  date_all_tikkies: {
    id: 'app.components.PaymentRequestFilters.date_all_tikkies',
    defaultMessage: 'Alle Tikkies',
  },
  date_today: {
    id: 'app.components.PaymentRequestFilters.datum_today',
    defaultMessage: 'Vandaag',
  },
  date_week: {
    id: 'app.components.PaymentRequestFilters.date_week',
    defaultMessage: 'Deze week',
  },
  date_month: {
    id: 'app.components.PaymentRequestFilters.date_month',
    defaultMessage: 'Deze maand',
  },
  date_year: {
    id: 'app.components.PaymentRequestFilters.date_year',
    defaultMessage: 'Dit jaar',
  },
  select_date: {
    id: 'app.components.PaymentRequestFilters.select_date',
    defaultMessage: 'Kies datum...',
  },
  apply_date: {
    id: 'app.components.PaymentRequestFilters.apply_date',
    defaultMessage: 'Toepassen',
  },
  mobile_calendar_header: {
    id: 'app.components.PaymentRequestFilters.mobile_calendar_header',
    defaultMessage: 'Kies datum',
  },

  status: {
    id: 'app.components.PaymentRequestFilters.status',
    defaultMessage: 'Status',
  },
  status_all: {
    id: 'app.components.PaymentRequestFilters.status_all',
    defaultMessage: 'Alle statussen',
  },
  status_paid: {
    id: 'app.components.PaymentRequestFilters.status_paid',
    defaultMessage: 'Betaald',
  },
  status_unpaid: {
    id: 'app.components.PaymentRequestFilters.status_unpaid',
    defaultMessage: 'Niet betaald',
  },
  status_expired: {
    id: 'app.components.PaymentRequestFilters.status_expired',
    defaultMessage: 'Verlopen',
  },
  status_not_expired: {
    id: 'app.components.PaymentRequestFilters.status_not_expired',
    defaultMessage: 'Niet verlopen',
  },
  status_archived: {
    id: 'app.components.PaymentRequestFilters.status_archived',
    defaultMessage: 'Verwijderd',
  },
  status_not_archived: {
    id: 'app.components.PaymentRequestFilters.status_not_archived',
    defaultMessage: 'Niet verwijderd',
  },
  create: {
    id: 'app.components.PaymentRequestFilters.create',
    defaultMessage: 'Tikkie sturen',
  },
  search_placeholder: {
    id: 'app.components.PaymentRequestFilters.search_placeholder',
    defaultMessage: 'Omschrijving of kenmerk...',
  },
  search_mobile_placeholder: {
    id: 'app.components.PaymentRequestFilters.search_mobile_placeholder',
    defaultMessage: 'Zoeken...',
  },
  search: {
    id: 'app.components.PaymentRequestFilters.search',
    defaultMessage: 'Zoeken',
  },
  filter: {
    id: 'app.components.PaymentRequestFilters.filter',
    defaultMessage: 'Filter',
  },
  filters: {
    id: 'app.components.PaymentRequestFilters.filters',
    defaultMessage: 'filters',
  },
  filter_button: {
    id: 'app.components.PaymentRequestFilters.filter_button',
    defaultMessage: 'Filter',
  },
  apply_filters: {
    id: 'app.components.PaymentRequestFilters.apply_filters',
    defaultMessage: 'Filters toepassen',
  },
  cancel: {
    id: 'app.components.PaymentRequestFilters.cancel',
    defaultMessage: 'Annuleer',
  },
  createSingleTikkie: {
    id: 'app.components.PaymentRequestFilters.createSingleTikkie',
    defaultMessage: 'Eén Tikkie',
  },
  createSingleTikkieInfo: {
    id: 'app.components.PaymentRequestFilters.createSingleTikkieInfo',
    defaultMessage: 'Stuur een Tikkie naar één of meerdere klanten.',
  },
  bulkUpload: {
    id: 'app.components.PaymentRequestFilters.bulkUpload',
    defaultMessage: 'Tikkies via upload',
  },
  bulkUploadInfo: {
    id: 'app.components.PaymentRequestFilters.bulkUploadInfo',
    defaultMessage:
      'Stuur meerdere Tikkies tegelijk door een bestand te uploaden.',
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import messages from './messages';
import PaymentRequestLineItem from '../../PaymentRequest/PaymentRequestLineItem';
import { Caption, Header2 } from '../../styleguide';

const PreviewContent = styled.div``;

const PreviewHeader = styled(Header2)`
  margin-top: 40px;
  margin-bottom: 32px;
`;

const PreviewNotice = styled(Caption)`
  display: block;
  margin-top: 24px;
  text-align: center;
`;

export default function PreviewList(props) {
  const { bulkFile, paymentRequests, usePaymentRequests } = props;

  const bulkFileTotal = bulkFile.paymentRequestBatchPaymentInformation
    ? bulkFile.paymentRequestBatchPaymentInformation.totalTikkiesInBatch
    : 0;
  const paymentRequestTotal =
    paymentRequests && paymentRequests.totalElements
      ? paymentRequests.totalElements
      : 0;
  const previewNumber = usePaymentRequests
    ? paymentRequestTotal
    : bulkFileTotal;
  const getPreviewNumberText = () => {
    if (previewNumber > 0) {
      return previewNumber > 6
        ? ` (6/${previewNumber})`
        : ` (${previewNumber}/${previewNumber})`;
    }
    return '';
  };
  const getPreviews = () => {
    let previews = [];
    if (usePaymentRequests) {
      if (paymentRequests && paymentRequests.totalElements) {
        previews = paymentRequests.paymentEvents.slice(0, 6);
      }
    } else if (bulkFile.paymentRequestPreviews) {
      previews = bulkFile.paymentRequestPreviews.map((paymentRequest) => ({
        ...paymentRequest,
        description: paymentRequest.descriptionPaymentPage,
        amountPerPersonInCents: paymentRequest.amountInCents,
        openAmount: paymentRequest.amountInCents === null,
      }));
    }
    return previews.map((paymentRequest) => ({
      ...paymentRequest,
      preview: true,
    }));
  };
  // const goToPreviewTikkie = (paymentRequest) =>
  //   goToPreview({ ...paymentRequest, backPath: props.location.pathname });

  return (
    <PreviewContent>
      <PreviewHeader>
        <FormattedMessage {...messages.tikkiePreviewHeader} />
        {getPreviewNumberText()}
      </PreviewHeader>
      {!isEmpty(getPreviews()) &&
        getPreviews().map((paymentRequest) => (
          <PaymentRequestLineItem
            key={paymentRequest.id}
            paymentRequest={paymentRequest}
            preview
            // showTikkieDetail={() => goToPreviewTikkie(paymentRequest)}
          />
        ))}
      {previewNumber > 6 && (
        <PreviewNotice>
          <FormattedMessage {...messages.tikkiePreviewNotice} />
        </PreviewNotice>
      )}
    </PreviewContent>
  );
}

PreviewList.propTypes = {
  location: PropTypes.object,
  bulkFile: PropTypes.object.isRequired,
  paymentRequests: PropTypes.object,
  usePaymentRequests: PropTypes.bool,
  goToPreview: PropTypes.func.isRequired,
};

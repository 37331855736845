import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import onboardingKYCContainerHOC from '../hoc';
import { EXPLAINER, INSTALL_APP, OPEN_APP, QR_CODE_HELP } from './constants';
import FormWrapperContainer from '../FormWrapperContainer';
import IDVExplainer from '../../../components/OnboardingKYC/IDVExplainer';
import IDVInstallApp from '../../../components/OnboardingKYC/IDVInstallApp';
import {
  IDVOpenAppDesktop,
  IDVOpenAppMobile,
} from '../../../components/OnboardingKYC/IDVOpenApp';
import IDVQRCodeHelp from '../../../components/OnboardingKYC/IDVQRCodeHelp';
import { selectIsSecondApplicant } from '../selectors';
import ShortDisturbanceMessage from '../../../components/SDM';

const steps = [EXPLAINER, INSTALL_APP, OPEN_APP, QR_CODE_HELP];

function IDVContainer(props) {
  const [currentStep, setCurrentStep] = useState(EXPLAINER);
  const prevStep = () =>
    setCurrentStep(props.getPreviousStep(steps, currentStep));
  const nextStep = () => setCurrentStep(props.getNextStep(steps, currentStep));

  const hasError = () => false;

  return (
    <FormWrapperContainer
      portalBacking={!props.isSecondApplicant}
      hasSpecificError={hasError()}
      prevStep={prevStep}
      nextStep={nextStep}
    >
      <ShortDisturbanceMessage
        context="business-onboarding-idv"
        isInner
        marginTop="0px"
      />
      {currentStep === EXPLAINER && <IDVExplainer nextStep={nextStep} />}
      {currentStep === INSTALL_APP && (
        <IDVInstallApp prevStep={prevStep} nextStep={nextStep} />
      )}
      {currentStep === OPEN_APP &&
        (isMobile ? (
          <IDVOpenAppMobile prevStep={prevStep} />
        ) : (
          <IDVOpenAppDesktop prevStep={prevStep} nextStep={nextStep} />
        ))}
      {currentStep === QR_CODE_HELP && <IDVQRCodeHelp prevStep={prevStep} />}
    </FormWrapperContainer>
  );
}

IDVContainer.propTypes = {
  getNextStep: PropTypes.func.isRequired,
  getPreviousStep: PropTypes.func.isRequired,
  isSecondApplicant: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isSecondApplicant: selectIsSecondApplicant,
});

const withConnect = connect(mapStateToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(IDVContainer);

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.BulkUpload.header',
    defaultMessage: 'Module Tikkies via upload',
  },
  terug: {
    id: 'app.components.BulkUpload.terug',
    defaultMessage: 'Terug',
  },
  bulkSettings: {
    id: 'app.components.BulkUpload.bulkSettings',
    defaultMessage: 'Tikkies sturen via upload',
  },
  changedOn: {
    id: 'app.components.BulkUpload.changedOn',
    defaultMessage: 'Gewijzigd op ',
  },
  changedBy: {
    id: 'app.components.BulkUpload.changedBy',
    defaultMessage: 'door ',
  },
  mostUsed: {
    id: 'app.components.BulkUpload.mostUsed',
    defaultMessage: 'Meest gebruikte setting: ',
  },
  status: {
    id: 'app.components.BulkUpload.status',
    defaultMessage: 'Status setting:',
  },
  popupText: {
    id: 'app.components.BulkUpload.popupText',
    defaultMessage: 'Module is aangezet',
  },
  descriptionTitle: {
    id: 'app.components.BulkUpload.descriptionTitle',
    defaultMessage: 'Omschrijving',
  },
  descriptionText: {
    id: 'app.components.BulkUpload.descriptionText',
    defaultMessage:
      'Veel Tikkies in één keer versturen doe je eenvoudig via een CSV-bestand en direct via sms. Je betaalt per verstuurde sms. Check onze website voor ',
  },
  descriptionCaption1: {
    id: 'app.components.BulkUpload.descriptionCaption1',
    defaultMessage:
      'Goed om te weten: voor het versturen van Bulk Tikkies via sms hoef je de module ',
  },
  descriptionCaptionLink: {
    id: 'app.components.BulkUpload.descriptionCaptionLink',
    defaultMessage: 'Delen via sms',
  },
  descriptionCaption2: {
    id: 'app.components.BulkUpload.descriptionCaption2',
    defaultMessage: ' niet aan te zetten. ✌️',
  },
  smsCost: {
    id: 'app.components.BulkUpload.smsCost',
    defaultMessage: 'meer informatie.',
  },
  manual: {
    id: 'app.components.BulkUpload.manual',
    defaultMessage: 'Handleiding',
  },
  manualTitle1: {
    id: 'app.components.BulkUpload.manualTitle1',
    defaultMessage: 'Zo maak je een CSV-bestand',
  },
  manualText1: {
    id: 'app.components.BulkUpload.manualText1',
    defaultMessage:
      'Download het voorbeeld CSV-bestand en vul de kolommen met de gegevens van je klanten. Daarna upload je het bestand in de portal en bekijk je de preview. Alles goed? Kies voor meteen versturen of opslaan voor later. ',
  },
  manualLink1: {
    id: 'app.components.BulkUpload.manualLink1',
    defaultMessage: 'Bekijk het voorbeeld CSV-bestand.',
  },
  manualTitle2: {
    id: 'app.components.BulkUpload.manualTitle2',
    defaultMessage: 'Tips voor het invullen van het CSV-bestand',
  },
  manualText2: {
    id: 'app.components.BulkUpload.manualText2',
    defaultMessage: '- Gebruik één rij per Tikkie',
  },
  manualText3: {
    id: 'app.components.BulkUpload.manualText3',
    defaultMessage: '- Wijzig de kolomnamen niet',
  },
  manualText4: {
    id: 'app.components.BulkUpload.manualText4',
    defaultMessage: '- Vul in elk geval de eerste 7 kolommen met gegevens',
  },
  manualLink2: {
    id: 'app.components.BulkUpload.manualLink2',
    defaultMessage: 'Meer tips? Bekijk de online handleiding.',
  },
});

import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  BlokkieButton,
  BlokkieIcon,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import '!file-loader?name=[name].[ext]!../../../images/gifs/the-office-sorry.gif';
import Screen from './Screen';

function RejectWithReferErrorScreen(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const goToABN = () =>
    window.open(
      'https://www.abnamro.nl/nl/zakelijk/producten/zakelijke-rekening/index.html',
      '_blank',
      'noreferrer',
    );
  return (
    <Screen
      showGif
      header={t('onboarding.fluentflow.riskQuestion.error.header')}
      sadGifVersion
    >
      <BlokkieTypography
        className={classes.errorScreenBody}
        variant="pl500"
        data-test-id="Onboarding-Rejection-Body"
      >
        {t('onboarding.fluentflow.riskQuestion.error.body')}
      </BlokkieTypography>
      <BlokkieButton
        data-test-id="Onboarding-Rejection-GoToAbnButton"
        onClick={goToABN}
        size="large"
        variant="primary"
      >
        <div
          style={{
            display: 'flex',
            gap: 8,
          }}
        >
          <BlokkieIcon color="white" variant="arrowOutOblique" />
          <BlokkieTypography variant="buttonLarge">
            {t('onboarding.fluentflow.riskQuestion.error.button')}
          </BlokkieTypography>
        </div>
      </BlokkieButton>
    </Screen>
  );
}

const useStyles = createUseStyles({
  errorScreenBody: {
    marginTop: blokkieTheme.spacing(),
    marginBottom: blokkieTheme.spacing(),
    textAlign: 'center',
  },
});

export default RejectWithReferErrorScreen;

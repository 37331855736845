import { defineMessages } from 'react-intl';

export default defineMessages({
  tikkiePreviewHeader: {
    id: 'app.components.PreviewList.tikkiePreviewHeader',
    defaultMessage: 'Preview van de Tikkies',
  },
  tikkiePreviewNotice: {
    id: 'app.components.PreviewList.tikkiePreviewNotice',
    defaultMessage:
      'We previewen alleen de eerste 6 Tikkies, de rest zal op dezelfde wijze opgemaakt worden.',
  },
});

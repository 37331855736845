import {
  LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_TRANSACTIONS_SUCCESS,
  DOWNLOAD_CAMT,
} from './constants';

export function loadTransactions(
  page,
  from,
  to,
  transactionType,
  searchString,
) {
  return {
    type: LOAD_TRANSACTIONS,
    payload: {
      page,
      from,
      to,
      transactionType,
      searchString,
    },
  };
}

export function loadTransactionsSuccess(transactions) {
  return {
    type: LOAD_TRANSACTIONS_SUCCESS,
    payload: {
      transactions,
    },
  };
}

export function loadTransactionsError(error) {
  return {
    type: LOAD_TRANSACTIONS_ERROR,
    payload: {
      error,
    },
  };
}

export function downloadCamt(date) {
  return {
    type: DOWNLOAD_CAMT,
    payload: {
      date,
    },
  };
}

export const selectAmount = (state) =>
  state.get('paymentRequest/createContainer').get('amount');
export const selectDescription = (state) =>
  state.get('paymentRequest/createContainer').get('description');
export const selectExpiry = (state) =>
  state.get('paymentRequest/createContainer').get('expiry');
export const selectIsCreating = (state) =>
  state.get('paymentRequest/createContainer').get('isCreating');
export const selectCreateTikkieErrorStatus = (state) =>
  state.get('paymentRequest/createContainer').get('createTikkieErrorStatus');
export const selectCreateTikkieErrorMessage = (state) =>
  state.get('paymentRequest/createContainer').get('createTikkieErrorMessage');
export const selectTikkie = (state) =>
  state.get('paymentRequest/createContainer').get('tikkie');

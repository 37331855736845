import React from 'react';
import { injectIntl } from 'react-intl';

import '!file-loader?name=[name].[ext]!../../../images/videoGifs/goed-bezig.mp4';

import { useTranslation } from 'react-i18next';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { CenterWrapper, Wrapper } from '../styledComponents';
import ButtonFooter from '../components/ButtonFooter';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface SignUpOrganisationProps {
  nextStep: () => void;
  isAbnOrAabFlow: boolean;
}

function SignUpOrganisation(props: SignUpOrganisationProps): JSX.Element {
  const { nextStep, isAbnOrAabFlow } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  useOnboardingPageView('sign-up-organisation');

  return (
    <Wrapper>
      <CenterWrapper {...props}>
        <div className={classes.gifContainer}>
          <BlokkieGifContainer source="../goed-bezig.mp4" gifOffset="-20%" />
        </div>
        <Title textAlign="center">
          {t('onboarding.signUpOrganisation.header')}
        </Title>
        <BlokkieTypography
          variant="pl500"
          style={{
            color: blokkieTheme.colors.grey.veryDark,
            marginBottom: blokkieTheme.spacing(5),
            textAlign: 'center',
          }}
        >
          {isAbnOrAabFlow
            ? t('onboarding.signUpOrganisation.body')
            : t('onboarding.signUpOrganisation.inviteFlowBody')}
        </BlokkieTypography>
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            onClick={nextStep}
            data-test-id="Onboarding-SignUpOrganisation-SignUpButton"
          >
            {isAbnOrAabFlow
              ? t('onboarding.signUpOrganisation.button')
              : t('onboarding.signUpOrganisation.inviteFlowButton')}
          </BlokkieButton>
        </ButtonFooter>
      </CenterWrapper>
    </Wrapper>
  );
}

const useStyles = createUseStyles({
  gifContainer: {
    display: 'flex',
    marginBottom: blokkieTheme.spacing(3),
  },
  [blokkieTheme.mediaQueries.allPhone]: {
    gifContainer: {
      marginBottom: blokkieTheme.spacing(3),
      marginTop: blokkieTheme.spacing(3),
    },
  },
});

export default injectIntl(SignUpOrganisation);

import '!file-loader?name=[name].[ext]!../../images/empty-logo.png';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { toLower, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Header2, Paragraph } from 'amber';
import { Color, Overlay } from '../styleguide';
import messages from './messages';
import { Shade } from '../OnboardingKYC/styledComponents';
import Loader from '../OnboardingKYC/Loader';
import oops2gif from '../../images/gifs/oops2.gif';

const SwitchBox = styled.div`
  width: 600px;
  height: 650px;
  background: ${Color.white};
  box-shadow: 0px 2px 110px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-top: -325px;
  margin-left: -300px;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;
`;

const SwitchBoxMobile = styled.div`
  width: 100%;
  height: 100%;
  background: ${Color.white};
  z-index: 999;
  display: block;
  position: fixed;
  padding-bottom: 40px;
`;

const SwitchHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.right ? 'right' : 'space-between')};
  width: 100%;
  height: 100px;
  padding: 32px;
`;

const SwitchTitle = styled.h2`
  display: flex;
  font-family: MuseoSansRounded;
  font-size: 30px;
  line-height: 35px;
  color: ${Color.gray01};
  justify-content: center;
  width: 95%;

  @media ${(props) => props.theme.device.mobile} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SwitchClose = styled.img`
  cursor: pointer;
`;

const SwitchInputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SwitchInput = styled.div`
  background: ${Color.white};
  height: 48px;
  width: 90%;
  display: flex;

  i.fa-search {
    position: absolute;
    margin-left: 16px;
    line-height: 44px;
    color: ${Color.gray04};
    margin-right: 8px;
  }

  i + input {
    padding-left: 40px;
  }

  input {
    flex: 1;
    height: 48px;
    outline: none;
    color: ${Color.gray01};
    border-radius: 8px;
    border: solid 2px ${Color.gray04};
    font-family: MuseoSansRounded;

    ::placeholder {
      font-size: 16px;
      color: ${Color.gray03};
    }

    :hover,
    :focus {
      border: solid 2px ${Color.gray04};
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

const SwitchListBox = styled.div`
  padding: 0px 32px;
  margin: 32px 0px 0px 0px;
  height: 390px;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media ${(props) => props.theme.device.mobile} {
    height: 450px;
  }
`;

const SwitchLineItem = styled.div`
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: ${Color.gray01};
  line-height: 19px;
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const SwitchOrganisationName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SwitchAvatar = styled.img`
  border: ${(props) =>
    props.current ? '3px solid #6868C1' : '2px solid #f2f2f3'};
  float: right;
  width: 38px;
  height: 38px;
  margin: 6px 15px 6px 0px;
  border-radius: 19px;
  background-color: #fff;
`;

const DarkOverlay = styled(Overlay)`
  background-color: rgba(0, 0, 0, 0.3);
`;

const ContainerBoxError = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ErrorWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

const GifWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  width: 176px;
  height: 176px;
  border: 12px solid rgba(104, 104, 193, 0.1);
  border-radius: 88px;
`;

const GifWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  border: 8px solid ${Color.brandColor02};
  border-radius: 88px;
`;

const Gifje = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 88px;
`;

const ParagraphMargin = styled(Paragraph)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

export default function SwitchOrganisation(props) {
  const {
    toggleShowSwitchOrganisation,
    showSwitchOrganisation,
    linkedOrganisations,
    linkedOrganisationsIsLoading,
    linkedOrganisationsLoadError,
    loadLinkedOrganisations,
    currentOrganisation,
    switchOrganisation,
  } = props;
  const SwitchOrganisationBox = isMobileOnly ? SwitchBoxMobile : SwitchBox;
  const [switchSearch, setSwitchSearch] = useState('');

  useEffect(() => {
    if (showSwitchOrganisation) {
      loadLinkedOrganisations();
    }
  }, [showSwitchOrganisation]);

  const organisationItems = () =>
    switchSearch === ''
      ? linkedOrganisations
      : linkedOrganisations.filter((organisation) =>
          toLower(organisation.internalPortalName).includes(
            toLower(switchSearch),
          ),
        );

  const organisationsLoaded =
    !isEmpty(linkedOrganisations) && !linkedOrganisationsIsLoading;
  const organisationsLoading =
    (isEmpty(linkedOrganisations) && isEmpty(linkedOrganisationsLoadError)) ||
    linkedOrganisationsIsLoading;
  const organisationsError =
    !isEmpty(linkedOrganisationsLoadError) && !linkedOrganisationsIsLoading;

  return (
    <>
      <DarkOverlay onClick={toggleShowSwitchOrganisation} />
      <SwitchOrganisationBox>
        {organisationsLoaded && (
          <>
            <SwitchHeader>
              <SwitchTitle>
                <FormattedMessage {...messages.allOrganisations} />
                {`(${linkedOrganisations.length})`}
              </SwitchTitle>
              <SwitchClose
                src="/close-icon.svg"
                onClick={toggleShowSwitchOrganisation}
              />
            </SwitchHeader>
            <SwitchInputBox>
              <SwitchInput>
                <i className="fa fa-search" />
                <FormattedMessage {...messages.search_placeholder}>
                  {(placeholder) => (
                    <input
                      type="text"
                      placeholder={placeholder}
                      value={switchSearch}
                      onChange={(event) => setSwitchSearch(event.target.value)}
                    />
                  )}
                </FormattedMessage>
              </SwitchInput>
            </SwitchInputBox>
            <SwitchListBox>
              {organisationItems().map((organisation) => (
                <SwitchLineItem
                  onClick={
                    organisation.id === currentOrganisation
                      ? () => toggleShowSwitchOrganisation()
                      : () => switchOrganisation(organisation.id)
                  }
                  key={organisation.id}
                >
                  <SwitchAvatar
                    current={organisation.id === currentOrganisation}
                    src={organisation.logo || '/empty-logo.png'}
                  />
                  <SwitchOrganisationName>
                    {organisation.internalPortalName}
                  </SwitchOrganisationName>
                </SwitchLineItem>
              ))}
            </SwitchListBox>
            <Shade />
          </>
        )}
        {organisationsLoading && <Loader />}
        {organisationsError && (
          <>
            <SwitchHeader right>
              <SwitchClose
                src="/close-icon.svg"
                onClick={toggleShowSwitchOrganisation}
              />
            </SwitchHeader>
            <ContainerBoxError data-test-id="container-box-error">
              <ErrorWrapper>
                <GifWrapper>
                  <GifWrapperInner>
                    <Gifje src={oops2gif} alt="" />
                  </GifWrapperInner>
                </GifWrapper>
                <Header2>
                  <FormattedMessage {...messages.errorTitle} />
                </Header2>
                <ParagraphMargin>
                  <FormattedMessage {...messages.errorDescription} />
                </ParagraphMargin>
              </ErrorWrapper>
            </ContainerBoxError>
          </>
        )}
      </SwitchOrganisationBox>
    </>
  );
}

SwitchOrganisation.propTypes = {
  toggleShowSwitchOrganisation: PropTypes.func.isRequired,
  showSwitchOrganisation: PropTypes.bool.isRequired,
  linkedOrganisations: PropTypes.array,
  linkedOrganisationsIsLoading: PropTypes.bool,
  linkedOrganisationsLoadError: PropTypes.object,
  loadLinkedOrganisations: PropTypes.func.isRequired,
  currentOrganisation: PropTypes.number.isRequired,
  switchOrganisation: PropTypes.func.isRequired,
};

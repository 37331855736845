import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, PageTitle } from 'amber';
import {
  Email,
  FirstName,
  LastName,
  Phone,
} from '../Settings/UserManagement/UserProfile';
import {
  getInitialErrors,
  getInitialValues,
  hasNoErrors,
  valuesToSave,
  valuesUpdated,
} from './updateState';
import { UpdateFooter } from '../Settings/UpdateFooter';
import { PasswordUpdate } from './PasswordUpdate';
import { isEmpty } from '../../utils';
import { AnalyticCategories, analyticsEvent } from '../../ga4';
import { useOnboardingPageView } from '../../hooks';

function CurrentUser({ user, tempUser, updateUser, updateUserError }) {
  let initialValues = getInitialValues(!isEmpty(tempUser) ? tempUser : user);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(getInitialErrors());
  const [cancelled, setCancelled] = useState(false);
  const [readyToUpdatePassword, setReadyToUpdatePassword] = useState(true);
  const valuesChanged = valuesUpdated(values, getInitialValues(user));
  const { firstName, lastName, email, phoneNumber } = values;

  useOnboardingPageView('authorized-to-sign');

  useEffect(() => {
    initialValues = getInitialValues(!isEmpty(tempUser) ? tempUser : user);
    setValues(initialValues);
  }, [user, tempUser]);

  useEffect(() => {
    if (cancelled) setCancelled(false);
  }, [cancelled]);

  const onChange = (fieldId, value) => {
    setValues((prevValues) => ({ ...prevValues, [fieldId]: value }));
  };

  const cancel = () => {
    setValues(getInitialValues(user));
    setErrors(getInitialErrors());
    setCancelled(true);
  };

  const cancelPassword = () => {
    setValues((oldValues) => ({
      ...oldValues,
      password: '',
      currentPassword: '',
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      currentPassword: false,
      password: false,
    }));
    setCancelled(true);
  };

  const emailUpdated = values.email !== user.email;

  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <PageTitle>Jouw profiel</PageTitle>
      <FirstName
        onChange={onChange}
        value={firstName}
        error={errors.firstName}
        setErrors={setErrors}
      />
      <LastName
        onChange={onChange}
        value={lastName}
        error={errors.lastName}
        setErrors={setErrors}
      />
      <Email
        onChange={onChange}
        value={email}
        error={errors.email}
        setErrors={setErrors}
      />
      {user.otpEnabled && (
        <Phone
          onChange={onChange}
          value={phoneNumber}
          error={errors.phoneNumber}
          setErrors={setErrors}
        />
      )}
      <PasswordUpdate
        values={values}
        onChange={onChange}
        cancelled={cancelled}
        onCancelPassword={cancelPassword}
        readyToUpdate={readyToUpdatePassword}
        setReadyToUpdate={setReadyToUpdatePassword}
        updateUserError={updateUserError}
        showPasswordUpdateInitial={
          !isEmpty(tempUser) &&
          !isEmpty(tempUser.password) &&
          !isEmpty(tempUser.currentPassword)
        }
      />

      <UpdateFooter
        show={valuesChanged}
        onCancel={cancel}
        onUpdate={() => {
          analyticsEvent(
            AnalyticCategories.SETTINGS,
            'business-portal-CurrentUser-save-button',
          );
          updateUser(valuesToSave(values), emailUpdated);
        }}
        updateDisabled={!hasNoErrors(errors) || !readyToUpdatePassword}
      />
    </Container>
  );
}

CurrentUser.propTypes = {
  user: PropTypes.object.isRequired,
  tempUser: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateUserError: PropTypes.object.isRequired,
};

export default CurrentUser;

/*
 * ApiSettings Messages
 *
 * This contains all the text for the ApiSettings component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ApiSettings.header',
    defaultMessage: 'API instellingen voor ',
  },
  headerNew: {
    id: 'app.components.ApiSettings.headerNew',
    defaultMessage: 'Nieuw token aanmaken',
  },
  terug: {
    id: 'app.components.SettingsDashboard.terug',
    defaultMessage: 'Terug',
  },
  apiSettings: {
    id: 'app.components.ApiSettings.apiSettings',
    defaultMessage: 'API instellingen beheren ',
  },
  createApiToken: {
    id: 'app.components.ApiSettings.changedOn',
    defaultMessage: 'Token aanmaken',
  },
  descriptionTitle: {
    id: 'app.components.ApiSettings.descriptionTitle',
    defaultMessage: 'Omschrijving',
  },
  descriptionText: {
    id: 'app.components.ApiSettings.descriptionText',
    defaultMessage:
      'Met een API (Application Programming Interface) verbind je eenvoudig je eigen systeem met het systeem van Tikkie. Zo kun je vanuit je eigen systeem betaalverzoeken sturen, terugbetalingen regelen en transactiegegevens koppelen aan een softwareprogramma.',
  },
  descriptionTextApiToken: {
    id: 'app.components.ApiSettings.descriptionTextApiToken',
    defaultMessage: 'Zodat je ‘m later kunt herkennen',
  },
  placeholderApiToken: {
    id: 'app.components.ApiSettings.placeholderApiToken',
    defaultMessage: 'Bijv. ”Voor onze mobile app”',
  },
  addTokensTitle: {
    id: 'app.components.ApiSettings.changeNameTitle',
    defaultMessage: 'heeft (nog) geen tokens',
  },
  addTokensText: {
    id: 'app.components.ApiSettings.changeNameTitle',
    defaultMessage: 'Creeër je eerste token met een druk op de groene knop.',
  },
  has: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'heeft',
  },
  apiTokens: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'tokens',
  },
  apiToken: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'token',
  },
  manualTitle1: {
    id: 'app.components.ApiSettings.manualTitle1',
    defaultMessage: 'Wat is een token?',
  },
  manualText1: {
    id: 'app.components.ApiSettings.manualText1',
    defaultMessage:
      'Een token is de koppeling tussen jouw Tikkie account en je eigen systeem. Je kunt deze tokens zelf aanmaken en er bepaalde mogelijkheden aan toekennen. Je kunt ook op elk moment deze mogelijkheden weer aanpassen of je token deactiveren.',
  },
  manualTitle2: {
    id: 'app.components.ApiSettings.manualTitle2',
    defaultMessage: 'Welke mogelijkheden zijn er?',
  },
  manualText2: {
    id: 'app.components.ApiSettings.manualText2',
    defaultMessage:
      'Tikkie heeft op dit moment 3 mogelijkheden die je afzonderlijk van elkaar kunt gebruiken. Je kunt betaalverzoeken sturen uit naam van jouw bedrijf. Ook kun je terugbetalingen doen op ontvangen betalingen. Daarnaast kun je op basis van de transactiegegevens notificaties over ontvangen betalingen krijgen, een bestand ophalen van gebundelde uitbetalingen of informatie ophalen van losse betalingen. Tip: maak per koppeling een token aan, zodat de mogelijkheden die jij kiest alleen voor die koppeling beschikbaar zijn.',
  },
  manualTitle3: {
    id: 'app.components.ApiSettings.manualTitle3',
    defaultMessage: 'Hoe ga ik te werk?',
  },
  manualText3A: {
    id: 'app.components.ApiSettings.manualText3A',
    defaultMessage: 'De API’s staan beschreven op de ',
  },
  manualText3B: {
    id: 'app.components.ApiSettings.manualText3B',
    defaultMessage:
      '. Je meldt je aan als ontwikkelaar en start de integratie met de API. Daarna kun je met de API key uit de developer portal en het token uit de Tikkie portal aan de slag. Als je integreert met een bestaand programma, lever jij jouw token uit aan dat programma. Let op: vanaf dat moment kan er dus vanuit de naam van jouw bedrijf gehandeld worden. Na het eerste gebruik is de token gekoppeld aan de API key en kan de token dus niet meer door een andere API key gebruikt worden.',
  },
  manual: {
    id: 'app.components.ApiSettings.manual',
    defaultMessage: 'Handleiding',
  },
});

import React from 'react';
import { injectIntl } from 'react-intl';
import {
  riskQuestionIds,
  riskQuestionSteps,
} from '../../../containers/OnboardingKYCContainer/RiskQuestionsContainer/RiskQuestionsContainerConstants';
import { RiskAnswerOptions } from './RiskAnswerOptions';
import BadSector from '../BadSector';

interface SectorRiskQuestionProps {
  setRiskAnswer(state: { [riskId: string]: RiskAnswerOptions }): void;
  nextStep(): void;
  currentAnswer: RiskAnswerOptions;
  organisationName: string;
}

const currentBadSectorAnswer = (currentAnswer: RiskAnswerOptions) => {
  if (currentAnswer === RiskAnswerOptions.UNANSWERED) {
    return null;
  }
  return currentAnswer === RiskAnswerOptions.YES;
};

function SectorRiskQuestions({
  setRiskAnswer,
  nextStep,
  currentAnswer,
  organisationName,
}: SectorRiskQuestionProps): JSX.Element {
  const setSectorRiskAnswer = (answer: boolean) => {
    setRiskAnswer({
      [riskQuestionIds.sectorRiskId]: answer
        ? RiskAnswerOptions.YES
        : RiskAnswerOptions.NO,
    });
  };

  return (
    <BadSector
      nextStep={nextStep}
      setBadSector={setSectorRiskAnswer}
      organisationName={organisationName}
      badSector={currentBadSectorAnswer(currentAnswer)}
      isCddFlow
    />
  );
}

export default injectIntl(SectorRiskQuestions);

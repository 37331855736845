import '!file-loader?name=[name].[ext]!../../../images/expiry-page-example.png';
import '!file-loader?name=[name].[ext]!../../../images/ice-cream.png';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Container, InputTile } from 'amber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerProps, InputErrorBox } from '../styledComponents';
import { ImageVerifyUploader } from './ImageVerifyUploader';
import config from '../../../config';
import { PENDING_EXPIRY_IMG } from './constants';
import { PreviewPage } from './PreviewPage';
import { isGifFileExtension } from '../../../utils';

function ExpiryPage({ values, onChange, validate, errors }) {
  const { t } = useTranslation();
  const validateText = (value, max, valueStr) =>
    validate(value.length > max, valueStr);
  const validateTitle = () =>
    validateText(values.expiryTitle, 40, 'expiryTitle');
  const validateContent = () =>
    validateText(values.expiryContent, 50, 'expiryContent');

  return (
    <>
      <Container col={9}>
        <ContainerProps row mb={24} maxHeight="100%">
          <Container row>
            {config.get(config.LOGO_UPLOAD_ENABLED) && (
              <ImageVerifyUploader
                desc={t('portal.settings.pages.general.gif.description')}
                input={values.inputExpiryImage}
                original={values.expiryImage}
                pending={values.pendingExpiryImage}
                entityName="gifje"
                pendingVariable={PENDING_EXPIRY_IMG}
                maxFileSize={2048}
                requiredExtensionType="gif"
                verifyFileExtension={isGifFileExtension}
                onLoad={(file) => {
                  onChange('inputExpiryImage', file);
                }}
                onChange={onChange}
              />
            )}
            <Container row mb={24}>
              <InputTile
                title={t('portal.settings.pages.expiryTitle.title')}
                desc={t('portal.settings.pages.expiryTitle.description')}
                value={values.expiryTitle}
                type="text"
                id="expiryTitle"
                dataTestId="Organisation-ExpiryTitleInput"
                onBlur={validateTitle}
                onChange={(fieldId, value) => {
                  validateTitle();
                  onChange(fieldId, value);
                }}
                placeholder={t('portal.settings.pages.expiryTitle.placeholder')}
              >
                {errors.expiryTitle && (
                  <InputErrorBox
                    errorText={t('portal.settings.pages.expiryTitle.error')}
                  />
                )}
              </InputTile>
            </Container>
            <Container row mb={24}>
              <InputTile
                title={t('portal.settings.pages.expiryContent.title')}
                desc={t('portal.settings.pages.expiryContent.description')}
                value={values.expiryContent}
                type="text"
                id="expiryContent"
                dataTestId="Organisation-ExpiryContentInput"
                onBlur={validateContent}
                onChange={(fieldId, value) => {
                  validateContent();
                  onChange(fieldId, value);
                }}
                placeholder={t(
                  'portal.settings.pages.expiryContent.placeholder',
                )}
              >
                {errors.expiryContent && (
                  <InputErrorBox
                    errorText={t('portal.settings.pages.expiryContent.error')}
                  />
                )}
              </InputTile>
            </Container>
          </Container>
        </ContainerProps>
      </Container>
      <Container col={3} center={isMobile}>
        <PreviewPage>
          <img src="/expiry-page-example.png" alt="expiry-page-example" />
        </PreviewPage>
      </Container>
    </>
  );
}

ExpiryPage.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ExpiryPage;

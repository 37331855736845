import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.searchBusiness.title',
    defaultMessage: 'Wat is het KVK-nummer van jouw organisatie?',
  },
  placeholder: {
    id: 'app.components.searchBusiness.placeholder',
    defaultMessage: 'Bijv. “57043381”',
  },
  continue: {
    id: 'app.components.searchBusiness.continue',
    defaultMessage: 'Volgende',
  },
  noSearchResults: {
    id: 'app.components.searchBusiness.noSearchResults',
    defaultMessage: '🤔 Voor dit KVK-nummer kunnen we geen organisaties vinden',
  },
  kvkExtractPendingTitle: {
    id: 'app.components.searchBusiness.kvkExtractPendingTitle',
    defaultMessage: 'Een tikkie geduld ⏳',
  },
  kvkExtractPendingP1: {
    id: 'app.components.searchBusiness.kvkExtractPendingP1',
    defaultMessage: 'We halen de gegevens van ',
  },
  kvkExtractPendingP2: {
    id: 'app.components.searchBusiness.kvkExtractPendingP2',
    defaultMessage:
      ' op bij de Kamer van Koophandel. Dit kan een paar tellen duren…',
  },
  kvkAlreadyCustomer1: {
    id: 'app.components.searchBusiness.kvkAlreadyCustomer1',
    defaultMessage: 'Volgens onze gegevens heeft ',
  },
  kvkAlreadyCustomer2: {
    id: 'app.components.searchBusiness.kvkAlreadyCustomer2',
    defaultMessage: ' zich al aangemeld. Log in, en ga direct aan de slag!',
  },
  loginButton: {
    id: 'app.components.searchBusiness.loginButton',
    defaultMessage: 'Inloggen',
  },
  kvkInfoScreenTitle: {
    id: 'app.components.searchBusiness.kvkInfoScreenTitle',
    defaultMessage: 'Waarom moet ik dit invullen?',
  },
  kvkInfoScreenBody: {
    id: 'app.components.searchBusiness.kvkInfoScreenBody',
    defaultMessage:
      'We gebruiken het KVK-nummer van jouw organisatie om je bedrijfsgegevens op te halen. Handig, want dan hoef je zelf minder in te vullen.',
  },
});

import React, { useState, useEffect, KeyboardEvent } from 'react';
import moment from 'moment';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { createUseStyles } from 'react-jss';
import { blokkieTheme } from '@tikkie/blokkie';
import { Input } from '../styledComponents';
import { formatDateOrder } from '../../../utils';

interface OnboardingDatePickerProps {
  date: string;
  setDate: (value: string) => void;
  readyToSend: boolean;
  submit: (value: string) => void;
  'data-test-id'?: string;
  placeholder?: string;
}

function OnboardingDatePicker({
  date,
  setDate,
  readyToSend,
  submit,
  'data-test-id': dataTestId,
  placeholder,
}: OnboardingDatePickerProps): JSX.Element {
  const [internalDate, setInternalDate] = useState('');

  const classes = useStyles();

  useEffect(
    () => {
      if (moment(date).isValid()) setInternalDate(formatDateOrder(date));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeHandler = (e: React.FormEvent<HTMLInputElement>): void => {
    let d = e.currentTarget.value;
    // Max length = 10
    if (d.length > 10) return;

    // Use dashingPos to add/remove a dash automatically
    const dashingPos = d.length === 2 || d.length === 5;
    if (d.length < internalDate.length) {
      if (dashingPos) d = internalDate.slice(0, -2);
    } else if (dashingPos) d += '-';

    setInternalDate(d);

    if (d.length === 10) {
      const formattedDate = formatDateOrder(d, true);
      if (moment(formattedDate).isValid()) {
        setDate(formattedDate);
      }
    } else {
      setDate('');
    }
  };

  const todaysDateFormatted = (): string => {
    const format = 'YYYY-MM-DD';
    const todaysDate = new Date();
    return moment(todaysDate).format(format);
  };

  const validateInput = (e: KeyboardEvent<HTMLInputElement>): void => {
    const c = e.key;

    if (
      e.key === 'ArrowLeft' ||
      e.key === 'RightArrow' ||
      e.key === 'Backspace' ||
      e.key === 'Tab'
    ) {
      return;
    }

    if ('0123456789'.indexOf(c) === -1) {
      e.preventDefault();
    }

    if (e.key === 'Enter' && readyToSend) {
      submit(date);
    }
  };

  return (
    <>
      {!isMobileOnly && !isTablet && (
        <Input
          type="tel"
          placeholder={placeholder ?? '17-07-1952'}
          value={internalDate}
          onKeyDown={validateInput}
          onChange={changeHandler}
          data-test-id={dataTestId}
        />
      )}
      {(isMobileOnly || isTablet) && (
        <Input
          className={classes.dateInput}
          required
          type="date"
          value={date}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            const selectedDate = e.currentTarget.value;
            if (selectedDate !== todaysDateFormatted())
              setDate(e.currentTarget.value);
          }}
          data-test-id={dataTestId}
        />
      )}
    </>
  );
}

const useStyles = createUseStyles({
  dateInput: {
    display: 'flex',
    border: `3px solid ${blokkieTheme.colors.grey.light}`,
  },
});

export default OnboardingDatePicker;

/**
 *
 * ForgotPassword
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';

import { FormattedMessage } from 'react-intl';

import { Header2, PrimaryButton, Input, Paragraph } from '../../styleguide';

import {
  LightBox,
  LightBoxBackArrow,
  LightBoxFieldLabel,
  LightBoxErrorMessageBanner,
} from '../../components';

import animationData from '../../../images/animations/white-dots.json';
import messages from './messages';
import LinkSent from '../LinkSent';

const ForgotPasswordWrapper = styled(LightBox)``;

const BackArrow = styled(LightBoxBackArrow)``;

const StepWrapper = styled.div`
  border: none;
`;

const Header = styled(Header2)`
  padding-bottom: 16px;
`;

const Info = styled(Paragraph)`
  margin-bottom: 26px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled(LightBoxFieldLabel)`
  margin-left: 8px;
`;

const ErrorMessageBanner = styled(LightBoxErrorMessageBanner)`
  left: 0px;
  right: 0px;
  top: 83px !important;
`;

const ErrorMessage = styled(ErrorMessageBanner)`
  position: absolute;
  top: 80px;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
    top: 80px;
    left: 0;
    right: 0;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -2px;
  left: 24px;
  background-color: #ed5565;
  transform: rotate(-45deg);
  z-index: -1;
`;

const Footer = styled.div`
  position: absolute;
  height: 50px;
  line-height: 50px;
  bottom: 40px;
  left: 40px;
  right: 40px;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
    line-height: auto;
  }
`;

const ButtonWrapper = styled.div`
  float: right;

  @media ${(props) => props.theme.device.mobile} {
    float: none;
    text-align: center;
  }
`;

const Button = styled(PrimaryButton)`
  min-width: 200px;

  @media ${(props) => props.theme.device.mobile} {
    min-width: 231px;
  }
`;

const AnimationWrapper = styled.div`
  .react-bodymovin-container {
    height: 18px;
  }
`;

const whiteOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

/* eslint-disable react/prefer-stateless-function */
class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isValidEmail: true,
    };

    this.forgotRef = React.createRef();
  }

  onChangeEmail = (event) => {
    const email = event.target.value;
    this.setState({ email, isValidEmail: true });
  };

  onSendResetLink = () => {
    const { email } = this.state;
    const isValidEmail = email.match(/@/g) !== null;

    this.setState({ isValidEmail });

    if (isValidEmail) {
      this.props.onSendResetLink(this.state.email);
    }
  };

  onHandleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onSendResetLink();
    }
  };

  disableSendResetLinkButton = () =>
    this.state.email === '' || !this.state.isValidEmail;

  render() {
    const { isSending } = this.props;

    return (
      <>
        <ForgotPasswordWrapper>
          {!this.props.isResetLinkSent && (
            <StepWrapper>
              <BackArrow
                src="/arrow-back.svg"
                onClick={this.props.onShowLogin}
              />
              <Header>
                <FormattedMessage {...messages.header} />
              </Header>
              {this.props.resetLinkError && (
                <ErrorMessageBanner id="sendResetLinkGenericError">
                  <FormattedMessage {...messages.sendResetLinkGenericError} />
                </ErrorMessageBanner>
              )}
              <Info>
                <FormattedMessage {...messages.info} />
              </Info>
              <InputWrapper>
                <Label>
                  <FormattedMessage {...messages.email} />
                </Label>
                <FormattedMessage {...messages.emailPlaceholder}>
                  {(placeholder) => (
                    <Input
                      onChange={this.onChangeEmail}
                      onKeyDown={this.onHandleKeyDown}
                      id="email"
                      placeholder={placeholder}
                      autoFocus="true"
                      autoComplete="off"
                    />
                  )}
                </FormattedMessage>
                {!this.state.isValidEmail && (
                  <ErrorMessage id="incompleteEmail">
                    <UpArrow />
                    <FormattedMessage {...messages.emailError} />
                  </ErrorMessage>
                )}
              </InputWrapper>
              <Footer>
                <ButtonWrapper>
                  <Button
                    onClick={this.onSendResetLink}
                    disabled={this.disableSendResetLinkButton()}
                    id="reset"
                    ref={this.forgotRef}
                  >
                    {isSending && (
                      <AnimationWrapper>
                        <ReactBodymovin options={whiteOptions} />
                      </AnimationWrapper>
                    )}
                    {!isSending && (
                      <FormattedMessage
                        {...messages.sendPasswordResetLinkButton}
                      />
                    )}
                  </Button>
                </ButtonWrapper>
              </Footer>
            </StepWrapper>
          )}
        </ForgotPasswordWrapper>
        {this.props.isResetLinkSent && (
          <LinkSent onShowLogin={this.props.onShowLogin} resetPassword />
        )}
      </>
    );
  }
}

ForgotPassword.propTypes = {
  isSending: PropTypes.bool.isRequired,
  isResetLinkSent: PropTypes.bool.isRequired,
  resetLinkError: PropTypes.bool.isRequired,
  onSendResetLink: PropTypes.func.isRequired,
  onShowLogin: PropTypes.func.isRequired,
};

export default ForgotPassword;

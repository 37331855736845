import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PaymentRequestShare from '../../../components/PaymentRequest/PaymentRequestShare';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';
import { selectUser } from '../../App/selectors';
import {
  selectPaymentRequestIsLoading,
  selectPaymentRequest,
} from '../selectors';
import { isEmpty } from '../../../utils';
import Loader from '../../../components/OnboardingKYC/Loader';
import {
  selectFetchingQRCode,
  selectFetchQRCodeError,
  selectIsSendingSms,
  selectQRCode,
  selectSmsSendError,
} from './selectors';
import { fetchQRCode, sendSms, resetQRCode } from './actions';
import { loadPaymentRequest } from '../actions';
import paymentRequestReducer from '../reducer';

function PaymentRequestShareContainer(props) {
  const history = useHistory();
  const match = useRouteMatch();

  if (isEmpty(props.user)) {
    return <Loader />;
  }

  const { paymentRequestToken } = match.params;

  if (
    isEmpty(props.paymentRequest) ||
    (!isEmpty(props.paymentRequest) &&
      props.paymentRequest.token !== paymentRequestToken)
  ) {
    if (!props.loadingPaymentRequest) {
      props.loadPaymentRequest(paymentRequestToken);
    }
    return <Loader />;
  }

  const showDashboard = () => {
    history.push('/');
  };

  const showTikkieDetail = (paymentRequest) => {
    history.push(`/paymentrequest/${paymentRequest.token}`);
  };

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <PaymentRequestShare
            showDashboard={showDashboard}
            showTikkieDetail={showTikkieDetail}
            paymentRequest={props.paymentRequest}
            sendSms={props.sendSms}
            isSendingSms={props.isSendingSms}
            hasSmsSendError={props.hasSmsSendError}
            smsOption={props.user.smsOption}
            fetchQRCode={props.fetchQRCode}
            fetchingQRCode={props.fetchingQRCode}
            fetchQRCodeError={props.fetchQRCodeError}
            qrCode={props.qrCode}
            resetQRCode={props.resetQRCode}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

PaymentRequestShareContainer.propTypes = {
  user: PropTypes.object,
  // Send SMS
  sendSms: PropTypes.func,
  isSendingSms: PropTypes.bool,
  hasSmsSendError: PropTypes.string,
  paymentRequest: PropTypes.object,
  fetchingQRCode: PropTypes.bool,
  fetchQRCodeError: PropTypes.bool,
  qrCode: PropTypes.string,
  fetchQRCode: PropTypes.func,
  resetQRCode: PropTypes.func,
  loadingPaymentRequest: PropTypes.bool.isRequired,
  loadPaymentRequest: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  isSendingSms: selectIsSendingSms,
  hasSmsSendError: selectSmsSendError,
  paymentRequest: selectPaymentRequest,
  fetchingQRCode: selectFetchingQRCode,
  fetchQRCodeError: selectFetchQRCodeError,
  qrCode: selectQRCode,
  loadingPaymentRequest: selectPaymentRequestIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  sendSms: (token, phoneNumber) => dispatch(sendSms(token, phoneNumber)),
  fetchQRCode: (link) => dispatch(fetchQRCode(link)),
  resetQRCode: () => dispatch(resetQRCode()),
  loadPaymentRequest: (token) => dispatch(loadPaymentRequest(token)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer(
  {
    key: 'paymentRequest/shareContainer',
    reducer,
  },
  {
    key: 'paymentRequest',
    paymentRequestReducer,
  },
);
const withSaga = injectSaga({ key: 'paymentRequest/shareContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PaymentRequestShareContainer);

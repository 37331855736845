export const getInitialValues = (data) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  phoneNumber: data.phoneNumber,
  currentPassword: '',
  password: '',
});

export const valuesUpdated = (values, originalValues) =>
  values.firstName !== originalValues.firstName ||
  values.lastName !== originalValues.lastName ||
  values.email !== originalValues.email ||
  values.phoneNumber !== originalValues.phoneNumber ||
  values.password !== '';

export const valuesToSave = (values) => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  phoneNumber: values.phoneNumber,
  currentPassword:
    values.currentPassword !== '' ? values.currentPassword : null,
  password: values.password !== '' ? values.password : null,
});

export const getInitialErrors = () => ({
  firstName: false,
  lastName: false,
  phoneNumber: false,
  email: false,
  currentPassword: false,
  password: false,
});

export const hasNoErrors = (errors) => !Object.values(errors).some((e) => e);

export const hasNoNulls = (values) => Object.values(values).some((v) => !!v);

import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import onboardingKYCContainerHOC from '../hoc';

import FormWrapperContainer from '../FormWrapperContainer';
import BirthDate from '../../../components/OnboardingKYC/BirthDate';
import { selectBirthDate } from '../selectors';
import { setBirthDate, submitBirthDate } from '../actions';
function BirthDateContainer(props) {
  const { birthDate } = props;

  return (
    <FormWrapperContainer portalBacking>
      <BirthDate
        birthDate={birthDate}
        setBirthDate={props.setBirthDate}
        submit={() => props.submitBirthDate(birthDate)}
      />
    </FormWrapperContainer>
  );
}

BirthDateContainer.propTypes = {
  setBirthDate: PropTypes.func.isRequired,
  submitBirthDate: PropTypes.func.isRequired,
  birthDate: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  birthDate: selectBirthDate,
});

const mapDispatchToProps = (dispatch) => ({
  setBirthDate: (birthDate) => dispatch(setBirthDate(birthDate)),
  submitBirthDate: (birthDate) => dispatch(submitBirthDate(birthDate)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  onboardingKYCContainerHOC,
  withConnect,
)(BirthDateContainer);

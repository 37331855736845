import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../../config';

import type { Campaign } from '../../../types/cashback-campaigns';
import { CashbackCampaignsListEmpty } from './CashbackCampaignsListEmpty';

import { CashbackCampaignsListError } from './CashbackCampaignsListError';
import { CashbackCampaignsListItem } from './CashbackCampaignsListItem';
import { CashbackCampaignsListLoader } from './CashbackCampaignsListLoader';

export function CashbackCampaignsList() {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);

  const fetchCampaigns = async () => {
    if (isLoading) {
      return;
    }

    try {
      setLoading(true);

      const { data } = await axios.get(
        `${config.API_URL}/ms/deals/v1/campaigns`,
        { withCredentials: true },
      );

      setCampaigns(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [campaigns?.length]);

  return (
    <>
      {isLoading && <CashbackCampaignsListLoader />}
      {isError && <CashbackCampaignsListError onClick={fetchCampaigns} />}

      {!isLoading && !isError && campaigns?.length === 0 && (
        <CashbackCampaignsListEmpty onClick={fetchCampaigns} />
      )}
      {!isLoading && !!campaigns?.length && (
        <section role="list">
          {campaigns.map((c) => (
            <CashbackCampaignsListItem key={c.id} campaign={c} />
          ))}
        </section>
      )}
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  BlokkieButton,
  BlokkieIconButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import '!file-loader?name=[name].[ext]!../../../images/videoGifs/shocked-monkey.mp4';

import { OnboardingParagraph } from '../styledComponents';
import messages from './messages';
import { isEmpty } from '../../../utils';
import Loader from '../Loader';
import OnboardingTextBalloon from '../Shared/OnboardingTextBalloon';
import { Color, Space } from '../../styleguide';
import { InfoScreen } from '../InfoScreen';
import ButtonHeader from '../components/ButtonHeader';
import ButtonFooter from '../components/ButtonFooter';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import InputKeyboard from '../components/InputKeyboard';
import { useOnboardingPageView } from '../../../hooks';
import NavigationHeader from '../components/NavigationHeader';
import Title from '../Title';

interface SearchBusinessProps {
  searchBusiness: (searchTerm: string) => void;
  searchResultPending: boolean;
  searchResultError: {
    statusCode: number;
  };
  searchResult: {
    organisationName: string;
    kvkNumber: string;
  };
  resetSearchResult: () => void;
  startOrganisationOnboarding: (kvkNumber: string, bankCode: string) => void;
  previousStep: () => void;
  bankCode: string;
  startOnboardingPending: boolean;
  kvkAlreadyCustomer: boolean;
  goToLoginScreen: () => void;
}

function SearchBusiness({
  searchBusiness,
  searchResultPending,
  searchResultError,
  searchResult,
  resetSearchResult,
  startOrganisationOnboarding,
  previousStep,
  bankCode,
  startOnboardingPending,
  kvkAlreadyCustomer,
  goToLoginScreen,
}: SearchBusinessProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [info, setInfo] = useState(false);

  useOnboardingPageView('search-business');

  useEffect(() => {
    if (searchTerm && searchTerm.length === 8) {
      searchBusiness(searchTerm);
    } else {
      resetSearchResult();
    }
  }, [searchTerm, searchBusiness, resetSearchResult]);

  const validateInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const c = e.key;
    const ctrlV = (e.ctrlKey || e.metaKey) && e.key === 'v';
    const ctrlC = (e.ctrlKey || e.metaKey) && e.key === 'c';

    if (
      e.key === 'ArrowLeft' ||
      e.key === 'RightArrow' ||
      e.key === 'Backspace' ||
      e.key === 'Tab' ||
      e.key === 'Enter' ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    // only numbers
    if ('0123456789'.indexOf(c) === -1) {
      e.preventDefault();
    }

    // Max 8 characters
    if (searchTerm.length >= 8) {
      e.preventDefault();
    }
  };

  const noKvkSearchResult = isEmpty(searchResult);
  const noKvkResult = searchResultError && searchResultError.statusCode === 404;
  const showInfo = (): void => setInfo(!info);

  const onClickNext = (): void => {
    if (!noKvkSearchResult) {
      startOrganisationOnboarding(searchResult.kvkNumber, bankCode);
    }
  };

  const onPaste = (): void => {
    if (searchTerm.length > 8) {
      setSearchTerm(searchTerm.slice(0, 8));
    }
  };

  if (startOnboardingPending) {
    return (
      <div className={classes.loaderContainer}>
        <div className={classes.loader}>
          <Loader />
        </div>
        <Title textAlign="center">
          {t('onboarding.searchKvk.lookup.title')}
        </Title>
        <OnboardingParagraph textAlign="center">
          {t('onboarding.searchKvk.lookup.body')}
        </OnboardingParagraph>
      </div>
    );
  }

  return (
    <>
      {!kvkAlreadyCustomer && (
        <div className={classes.container}>
          <NavigationHeader
            clickLeft={previousStep as () => void}
            clickRight={showInfo}
          />
          <Title>{t('onboarding.searchKvk.intro.title')}</Title>
          <InputKeyboard
            placeholder={messages.placeholder.defaultMessage}
            onKeyDown={validateInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
            type="number"
            maxLength={8}
            value={searchTerm}
            data-test-id="Onboarding-SearchBusiness-KvkNumber"
            autoFocus
            onPaste={onPaste}
            style={{ marginBottom: blokkieTheme.spacing(1) }}
            relatedButtonId="button-footer"
          />

          <OnboardingTextBalloon
            onClick={onClickNext}
            clickable={searchResultPending || noKvkResult}
            show={searchTerm.length === 8}
          >
            {searchResultPending && <Loader />}
            {!searchResultPending && (
              <>
                {!noKvkResult && (
                  <BlokkieTypography
                    variant="h3"
                    style={{
                      color: blokkieTheme.colors.primary.green,
                      margin: 0,
                      padding: blokkieTheme.spacing(1),
                    }}
                  >
                    {searchResult.organisationName}
                  </BlokkieTypography>
                )}
                {noKvkResult && (
                  <OnboardingParagraph
                    className={classes.noResult}
                    margin={Space.s0}
                    padding={Space.s8}
                  >
                    {messages.noSearchResults.defaultMessage}
                  </OnboardingParagraph>
                )}
              </>
            )}
          </OnboardingTextBalloon>
          <ButtonFooter>
            <BlokkieButton
              variant="primary"
              size="large"
              disabled={noKvkSearchResult}
              data-test-id="Onboarding-SearchBusiness-NextButton"
              onClick={onClickNext}
            >
              {t('next')}
            </BlokkieButton>
          </ButtonFooter>
        </div>
      )}

      {kvkAlreadyCustomer && (
        <div className={classes.container}>
          <ButtonHeader>
            <BlokkieIconButton
              variant="arrowLeft"
              color={blokkieTheme.colors.primary.green}
              onClick={previousStep as () => void}
            />
            <BlokkieTypography
              variant="buttonSmall"
              style={{
                color: blokkieTheme.colors.primary.green,
                cursor: 'pointer',
                padding: blokkieTheme.spacing(),
              }}
              onClick={goToLoginScreen}
            >
              {t('login')}
            </BlokkieTypography>
          </ButtonHeader>
          <div className={classes.center}>
            <BlokkieGifContainer
              source="shocked-monkey.mp4"
              style={{ margin: `${blokkieTheme.spacing()}px 0` }}
            />
          </div>
          <Title textAlign="center">
            {t('onboarding.searchKvk.alreadyRegistered.title')}
          </Title>
          <OnboardingParagraph textAlign="center" marginBottom={Space.s32}>
            {t('onboarding.searchKvk.alreadyRegistered.body', {
              organisationName: searchResult.organisationName || '-',
            })}
          </OnboardingParagraph>
          <div className={classes.bottomButton}>
            <BlokkieButton
              variant="primary"
              size="large"
              data-test-id="onboarding-SearchBusiness-kvkAlreadyCustomer-Login"
              onClick={goToLoginScreen}
              style={{ width: 268 }}
            >
              {t('sendMail')}
            </BlokkieButton>
          </div>
        </div>
      )}
      <InfoScreen
        show={info}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={SearchBusiness.name}
      >
        <OnboardingParagraph>
          {t('onboarding.searchKvk.info.body')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

const useStyles = createUseStyles({
  bottomButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  center: { display: 'flex', justifyContent: 'center' },
  loaderContainer: {
    marginTop: blokkieTheme.spacing(16),
    display: 'flex',
    flex: 1,
    height: 480,
    flexDirection: 'column',
    alignItems: 'center',
  },
  loader: { marginBottom: blokkieTheme.spacing(3) },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'right',
  },
  noResult: {
    color: `${Color.gray01} !important`,
  },
});

export { SearchBusiness };
export default injectIntl(SearchBusiness);

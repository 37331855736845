/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.PaymentRequestList.header',
    defaultMessage: 'Betaalverzoekjes',
  },
  menuTransactions: {
    id: 'app.components.PaymentRequestList.menu.item.transactions',
    defaultMessage: 'Bij- en af',
  },
  menuPaymentRequests: {
    id: 'app.components.PaymentRequestList.menu.item.paymentRequests',
    defaultMessage: 'Betaalverzoekjes',
  },
  tabAll: {
    id: 'app.components.PaymentRequestList.tab.all',
    defaultMessage: 'Alle Tikkies',
  },
  tabBulkUploads: {
    id: 'app.components.PaymentRequestList.tab.bulkUploads',
    defaultMessage: 'Bulk Tikkies',
  },
  oopsTitle: {
    id: 'app.components.PaymentRequestList.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.PaymentRequestList.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  oopsButton: {
    id: 'app.components.PaymentRequestList.oopsButton',
    defaultMessage: 'Probeer opnieuw',
  },
  noResultsTitle: {
    id: 'app.components.PaymentRequestList.noResultsTitle',
    defaultMessage: 'Geen resultaten',
  },
  noResultsText: {
    id: 'app.components.PaymentRequestList.noResultsText',
    defaultMessage:
      'Wij kunnen geen tikkies vinden voor de huidige filter selectie.',
  },
  noResultsButton: {
    id: 'app.components.PaymentRequestList.noResultsButton',
    defaultMessage: 'Laat alle Tikkies zien',
  },
  firstUseTitle: {
    id: 'app.components.PaymentRequestList.firstUseTitle',
    defaultMessage: 'Er zijn nog geen Tikkies.',
  },
  firstUseText: {
    id: 'app.components.PaymentRequestList.firstUseText',
    defaultMessage:
      'Stuur nu je eerste Tikkie. Tip: wil je eerst even kijken hoe het werkt? Stuur jezelf dan een test Tikkie.',
  },
  downloadManual: {
    id: 'app.components.PaymentRequestList.downloadManual',
    defaultMessage: 'Bekijk de handleiding',
  },
  logout: {
    id: 'app.components.PaymentRequestList.logout',
    defaultMessage: 'Uitloggen',
  },
  downloadPayments: {
    id: 'app.components.Filter.downloadPayments',
    defaultMessage: 'Download transacties',
  },
  downloadPaymentRequests: {
    id: 'app.components.Filter.downloadPaymentRequests',
    defaultMessage: 'Download betaalverzoekjes',
  },
});

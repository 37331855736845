import {
  EMPTY_UPLOAD_FILE,
  BULK_UPLOAD,
  BULK_UPLOAD_ERROR,
  BULK_UPLOAD_SUCCESS,
  DELETE_BULK_UPLOAD,
  DELETE_BULK_UPLOAD_ERROR,
  DELETE_BULK_UPLOAD_SUCCESS,
  RESET_BULK_UPLOAD,
  SEND_BULK_UPLOAD,
  SEND_BULK_UPLOAD_ERROR,
  SEND_BULK_UPLOAD_SUCCESS,
} from './constants';

export function emptyUploadFile() {
  return {
    type: EMPTY_UPLOAD_FILE,
  };
}

export function bulkUpload(file) {
  return {
    type: BULK_UPLOAD,
    payload: {
      file,
    },
  };
}

export function bulkUploadSuccess(bulkUploadFile) {
  return {
    type: BULK_UPLOAD_SUCCESS,
    payload: {
      bulkUploadFile,
    },
  };
}

export function bulkUploadError(status, error, errors) {
  return {
    type: BULK_UPLOAD_ERROR,
    payload: {
      status,
      error,
      errors,
    },
  };
}

export function deleteBulkUpload(id) {
  return {
    type: DELETE_BULK_UPLOAD,
    payload: {
      id,
    },
  };
}

export function deleteBulkUploadSuccess() {
  return {
    type: DELETE_BULK_UPLOAD_SUCCESS,
  };
}

export function deleteBulkUploadError(status, error) {
  return {
    type: DELETE_BULK_UPLOAD_ERROR,
    payload: {
      status,
      error,
    },
  };
}

export function sendBulkUpload(id) {
  return {
    type: SEND_BULK_UPLOAD,
    payload: {
      id,
    },
  };
}

export function sendBulkUploadSuccess() {
  return {
    type: SEND_BULK_UPLOAD_SUCCESS,
  };
}

export function sendBulkUploadError(status, error) {
  return {
    type: SEND_BULK_UPLOAD_ERROR,
    payload: {
      status,
      error,
    },
  };
}

export function resetBulkUpload() {
  return {
    type: RESET_BULK_UPLOAD,
  };
}

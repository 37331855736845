import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useTranslation } from 'react-i18next';
import { BlokkieButton } from '@tikkie/blokkie';
import {
  OnboardingParagraph,
  RadioButtonContainer,
  Wrapper,
} from '../styledComponents';
import NavigationHeader from '../components/NavigationHeader';
import { InfoScreen } from '../InfoScreen';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import ButtonFooter from '../components/ButtonFooter';
import { ABN_AMRO_BANK_CODE } from '../../../containers/OnboardingKYCContainer/config';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

export const BANK_WITH_ABN_SCREEN_BACK_BUTTON_TEST_ID =
  'bank-with-abn-screen-back-button-test-id';
export const BANK_WITH_ABN_SCREEN_INFO_BUTTON_TEST_ID =
  'bank-with-abn-screen-info-button-test-id';
export const BANK_WITH_ABN_SCREEN_NEXT_BUTTON_TEST_ID =
  'bank-with-abn-screen-next-button-test-id';

interface BankWithAbnProps {
  previousStep(): void;
  nextStep(): void;
  selectBank: (bank: string) => void;
}

function BankWithAbn({
  previousStep,
  nextStep,
  selectBank,
}: BankWithAbnProps): JSX.Element {
  const { t } = useTranslation();
  const [info, setInfo] = useState(false);
  const [untouched, setUntouched] = useState(true);
  const [banksWithAbn, setBanksWithAbn] = useState(false);
  const [banksWithOtherBank, setBanksWithOtherBank] = useState(false);
  const showInfo = (): void => setInfo(!info);

  useOnboardingPageView('bank-with-abn');

  const clickBanksWithAbn = () => {
    setBanksWithAbn(true);
    setBanksWithOtherBank(false);
    setUntouched(false);
    selectBank(ABN_AMRO_BANK_CODE);
  };

  const clickBanksWithOtherBank = () => {
    setBanksWithAbn(false);
    setBanksWithOtherBank(true);
    setUntouched(false);
    selectBank('FAKENL2A');
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={previousStep as () => void}
          clickRight={showInfo}
        />
        <Title>{`${t('onboarding.bankWithAbnScreen.title')}`}</Title>
        <RadioButtonContainer>
          <OnboardingRadioButton
            key="banksWithAbn"
            id="banksWithAbn"
            onClick={clickBanksWithAbn}
            checked={banksWithAbn}
            text={t('yes')}
            data-test-id="Onboarding-BankWithAbn-Yes"
          />
          <OnboardingRadioButton
            key="notAuthorizedToSign"
            id="notAuthorizedToSign"
            onClick={clickBanksWithOtherBank}
            checked={banksWithOtherBank}
            text={t('no')}
            data-test-id="Onboarding-BankWithAbn-No"
          />
        </RadioButtonContainer>
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            disabled={untouched}
            data-test-id="Onboarding-BankWithAbn-NextButton"
            onClick={nextStep}
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={t('onboarding.bankWithAbnScreen.infoTitle')}
        onClick={showInfo}
        fromPage={BankWithAbn.name}
      >
        <OnboardingParagraph>
          {t('onboarding.bankWithAbnScreen.infoBody')}
        </OnboardingParagraph>
      </InfoScreen>
    </>
  );
}

export default injectIntl(BankWithAbn);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { isEmpty } from 'lodash/lang';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import injectSaga from '../../../../utils/injectSaga';
import saga from '../../saga';
import { fetchUsers, loadPermittedOrganisations } from '../../actions';
import {
  selectPermittedOrganisations,
  selectPermittedOrganisationsError,
  selectPermittedOrganisationsLoading,
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../../selectors';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../../components/components';
import { selectUser } from '../../../MenuContainer/selectors';
import ListUsers from '../../../../components/Settings/UserManagement/ListUsers';
import Loader from '../../../../components/OnboardingKYC/Loader';

function ListUsersContainer({
  settingsLoading,
  user,
  users,
  usersLoading,
  usersError,
  getUsers,
  getPermittedOrganisations,
  permittedOrganisations,
  permittedOrganisationsLoading,
  permittedOrganisationsError,
}) {
  const history = useHistory();

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  const gotoAddUser = () => {
    history.push('/settings/user-management/add-user');
  };

  const gotoAddExistingUser = () => {
    history.push('/settings/user-management/add-existing-user');
  };

  const goToUserDetails = (id) => {
    history.push(`/settings/user-management/user/${id}`);
  };

  useEffect(() => {
    getPermittedOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(settingsLoading || permittedOrganisationsLoading) && <Loader />}
      {!settingsLoading && !permittedOrganisationsLoading && (
        <DashboardWrapper>
          <Container>
            <InnerContainer highMargin>
              <ListUsers
                gotoPortalOverview={gotoPortalOverview}
                gotoSettingsDashboard={gotoSettingsDashboard}
                gotoAddUser={gotoAddUser}
                gotoAddExistingUser={gotoAddExistingUser}
                user={user}
                users={users}
                usersLoading={usersLoading}
                usersError={usersError}
                fetchUsers={getUsers}
                permittedOrganisationsError={permittedOrganisationsError}
                hasOtherPermittedOrganisations={
                  !isEmpty(permittedOrganisations)
                }
                goToUserDetails={goToUserDetails}
              />
            </InnerContainer>
          </Container>
        </DashboardWrapper>
      )}
    </>
  );
}

ListUsersContainer.propTypes = {
  users: PropTypes.shape({
    users: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
  settingsLoading: PropTypes.bool,
  user: PropTypes.object,
  usersLoading: PropTypes.bool.isRequired,
  usersError: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getPermittedOrganisations: PropTypes.func.isRequired,
  permittedOrganisations: PropTypes.array,
  permittedOrganisationsLoading: PropTypes.bool.isRequired,
  permittedOrganisationsError: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  users: selectUsers,
  usersLoading: selectUsersLoading,
  usersError: selectUsersError,
  permittedOrganisations: selectPermittedOrganisations,
  permittedOrganisationsLoading: selectPermittedOrganisationsLoading,
  permittedOrganisationsError: selectPermittedOrganisationsError,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (pagination) => dispatch(fetchUsers(pagination)),
  getPermittedOrganisations: () => dispatch(loadPermittedOrganisations()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'ListUsersContainer', saga });

export default compose(withSaga, withConnect)(ListUsersContainer);

import { defineMessages } from 'react-intl';

export default defineMessages({
  contactPersonUboHeader: {
    id: 'app.components.UBO.contactPersonUboHeader',
    defaultMessage: 'Ben jij een uiteindelijk belanghebbende?',
  },
  contactPersonUboDescription: {
    id: 'app.components.UBO.contactPersonUboDescription',
    defaultMessage:
      "Je bent een 'uiteindelijk belanghebbende' als je voor 25% of meer aandelen, belang of zeggenschap binnen het bedrijf hebt.",
  },
  contactPersonUboYes: {
    id: 'app.components.UBO.contactPersonUboYes',
    defaultMessage: 'Ja, dat ben ik',
  },
  contactPersonUboNo: {
    id: 'app.components.UBO.contactPersonUboNo',
    defaultMessage: 'Nee, ik wil iemand anders opgeven',
  },
  continue: {
    id: 'app.components.UBO.continue',
    defaultMessage: 'Volgende',
  },
  uboOverviewHeader: {
    id: 'app.components.UBO.otherUboHeader',
    defaultMessage:
      'Zijn er nog meer uiteindelijk belanghebbenden in jouw organisatie?',
  },
  uboOverviewContinue: {
    id: 'app.components.UBO.otherUboContinue',
    defaultMessage: 'Nee, volgende',
  },
  uboOverviewAdd: {
    id: 'app.components.UBO.otherUboAdd',
    defaultMessage: 'Toevoegen',
  },
  addressHeader: {
    id: 'app.components.UBO.header',
    defaultMessage: 'Wat is het huidige woonadres van ',
  },
  inputPostalCode: {
    id: 'app.components.UBO.inputPostalCode',
    defaultMessage: 'Postcode',
  },
  inputHouseNumber: {
    id: 'app.components.UBO.inputHouseNumber',
    defaultMessage: 'Huisnummer',
  },
  inputStreet: {
    id: 'app.components.UBO.inputStreet',
    defaultMessage: 'Straatnaam',
  },
  inputCity: {
    id: 'app.components.UBO.inputCity',
    defaultMessage: 'Woonplaats',
  },
  uboHeader: {
    id: 'app.components.UBO.uboHeader',
    defaultMessage: 'Uiteindelijke belanghebbende',
  },
  skipUBOLink: {
    id: 'app.components.UBO.skipUBOLink',
    defaultMessage: 'Deze stap overslaan',
  },
  involvementSelectContactHeader: {
    id: 'app.components.UBO.involvementSelectContactHeader',
    defaultMessage: 'Hoe ben jij betrokken bij',
  },
  involvementSelectOtherUboHeader1: {
    id: 'app.components.UBO.involvementSelectOtherUboHeader1',
    defaultMessage: 'Hoe is',
  },
  involvementSelectOtherUboHeader2: {
    id: 'app.components.UBO.involvementSelectOtherUboHeader2',
    defaultMessage: 'betrokken bij',
  },
  involvementSelectSubHeader1: {
    id: 'app.components.UBO.involvementSelectSubHeader1',
    defaultMessage:
      'Je bent (mede-)eigenaar als je meer dan 25% van de aandelen in de onderneming houdt. Als je op andere gronden invloed op de organisatie hebt, dan heb je feitelijk zeggenschap. ',
  },
  involvementSelectSubHeader2: {
    id: 'app.components.UBO.involvementSelectSubHeader2',
    defaultMessage: 'Meer weten',
  },
  involvementPercentageContactHeader: {
    id: 'app.components.UBO.involvementSelectSubHeader2',
    defaultMessage: 'Voor hoeveel procent ben jij (mede-)eigenaar?',
  },
  involvementPercentageOtherHeader1: {
    id: 'app.components.UBO.involvementPercentageOtherHeader1',
    defaultMessage: 'Voor hoeveel procent is',
  },
  involvementPercentageOtherHeader2: {
    id: 'app.components.UBO.involvementPercentageOtherHeader2',
    defaultMessage: '(mede)eigenaar?',
  },
  involvementPercentageSubHeader1: {
    id: 'app.components.UBO.involvementSelectSubHeader1',
    defaultMessage:
      'Je bent (mede-)eigenaar als je meer dan 25% van de aandelen in de onderneming houdt.  ',
  },
  involvementPercentageSubHeader2: {
    id: 'app.components.UBO.involvementSelectSubHeader2',
    defaultMessage: 'Meer weten',
  },
  involvementErrorTooLow: {
    id: 'app.components.UBO.involvementErrorTooLow',
    defaultMessage: 'Oeps, je moet meer dan 25% eigenaar zijn.',
  },
  involvementErrorTooHigh: {
    id: 'app.components.UBO.involvementErrorTooHigh',
    defaultMessage: 'Oeps, je kan maximaal 100% eigenaar zijn.',
  },
  involvementInfoHeader1: {
    id: 'app.components.UBO.involvementInfoHeader1',
    defaultMessage: 'Wat is Eigendom?',
  },
  involvementInfoParagraph1: {
    id: 'app.components.UBO.involvementInfoParagraph1',
    defaultMessage:
      'Personen die direct of indirect meer dan 25% van de aandelen, van de stemrechten of van het eigendomsbelang in uw organisatie houden.',
  },
  involvementInfoHeader2: {
    id: 'app.components.UBO.involvementInfoHeader2',
    defaultMessage: 'Wat is Zeggenschap?',
  },
  involvementInfoParagraph2: {
    id: 'app.components.UBO.involvementInfoParagraph2',
    defaultMessage: 'Kenmerken van zeggenschap en/of invloed kunnen zijn:',
  },
  involvementInfoParagraph2a: {
    id: 'app.components.UBO.involvementInfoParagraph2a',
    defaultMessage:
      'Het via een overeenkomst of op grond van een afspraak, statuten of andere verklaringen, zeggenschap hebben in de organisatie;',
  },
  involvementInfoParagraph2b: {
    id: 'app.components.UBO.involvementInfoParagraph2b',
    defaultMessage:
      'De bevoegdheid hebben om het bestuur te benoemen en/of te ontslaan;',
  },
  involvementInfoParagraph2c: {
    id: 'app.components.UBO.involvementInfoParagraph2c',
    defaultMessage:
      'Voor 100% aansprakelijk zijn voor de schulden van de organisatie;',
  },
  involvementInfoParagraph2d: {
    id: 'app.components.UBO.involvementInfoParagraph2d',
    defaultMessage:
      'Gebruikmaken van of profiteren van eigendommen van de organisatie (bijv. vruchtgebruik).',
  },
  involvementContactOwner: {
    id: 'app.components.UBO.involvementInfoParagraph2d',
    defaultMessage: 'Ik ben (mede-)eigenaar',
  },
  involvementContactControl: {
    id: 'app.components.UBO.involvementInfoParagraph2d',
    defaultMessage: 'Ik heb zeggenschap',
  },
  involvementOtherOwner: {
    id: 'app.components.UBO.involvementInfoParagraph2d',
    defaultMessage: '(Mede-)eigenaar',
  },
  involvementOtherControl: {
    id: 'app.components.UBO.involvementInfoParagraph2d',
    defaultMessage: 'Zeggenschap',
  },
});

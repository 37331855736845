import React from 'react';
import { BlokkieGif, blokkieTheme } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';

interface BlokkieGifContainerProps {
  source: string;
  gifOffset?: string;
  borderSize?: string;
  style?: React.CSSProperties;
}

export function BlokkieGifContainer(
  props: BlokkieGifContainerProps,
): React.JSX.Element {
  // eslint-disable-next-line no-use-before-define
  const classes = useStyles();
  const { source, style, gifOffset, borderSize } = props;
  return (
    <div className={classes.container} style={style}>
      <BlokkieGif
        source={source}
        positionMargin={gifOffset ?? '0'}
        borderSize={borderSize ?? '4%'}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '168px',
    height: '168px',
    margin: '0 auto',
  },
  [blokkieTheme.mediaQueries.smallPhone]: {
    container: {
      width: '120px',
      height: '120px',
    },
  },
});

import PropTypes from 'prop-types';
import React from 'react';
import {
  VerificationWrapper,
  VerifyIconWrapperNOK,
  VerifyIconWrapperOK,
  VerifyNOK,
  VerifyOK,
} from './components';

export const PasswordMatchVerification = ({ match }) => (
  <VerificationWrapper>
    {match && (
      <VerifyOK id="lengthCheckMark" name="checked">
        <VerifyIconWrapperOK src="/checkmark.svg" />
        Wachtwoorden komen overeen
      </VerifyOK>
    )}
    {!match && (
      <VerifyNOK id="lengthCheckMark" name="unChecked">
        <VerifyIconWrapperNOK src="/checkmark.svg" />
        Wachtwoorden komen overeen
      </VerifyNOK>
    )}
  </VerificationWrapper>
);

PasswordMatchVerification.propTypes = {
  match: PropTypes.bool.isRequired,
};

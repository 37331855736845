import React from 'react';
import { injectIntl } from 'react-intl';
import {
  blokkieTheme,
  BlokkieButton,
  BlokkieTypography,
} from '@tikkie/blokkie';
import '!file-loader?name=[name].[ext]!../../../images/idv-app.svg';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CenterWrapper } from '../styledComponents';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface IDVExplainerProps {
  nextStep: () => void;
}

function IDVExplainer({ nextStep }: IDVExplainerProps): JSX.Element {
  const { t } = useTranslation();
  useOnboardingPageView('idv-explainer');

  const next = (): void => {
    nextStep();
  };

  return (
    <CenterWrapper>
      <img src="/idv-app.svg" alt="" />
      <Title textAlign="center">
        {isMobile
          ? t(`onboarding.idv.explainer.titleMobile`)
          : t(`onboarding.idv.explainer.titleDesktop`)}
      </Title>

      <BlokkieTypography
        style={{
          color: blokkieTheme.colors.grey.veryDark,
          textAlign: 'center',
          lineHeight: '1.5',
          whiteSpace: 'pre-wrap',
          marginBottom: blokkieTheme.spacing(3),
        }}
        variant="pl500"
      >
        {t(`onboarding.idv.explainer.description`)}
      </BlokkieTypography>

      <ButtonFooter>
        <BlokkieButton
          variant="primary"
          size="large"
          onClick={next}
          data-test-id="Onboarding-IDV-Explainer-NextButton"
        >
          {t(`onboarding.idv.explainer.next`)}
        </BlokkieButton>
      </ButtonFooter>
    </CenterWrapper>
  );
}

export default injectIntl(IDVExplainer);

/*
 * PaymentRequest shared constants
 */

export const SAVE_TIKKIE = 'paymentRequest/SAVE_TIKKIE';
export const LOAD_PAYMENT_REQUEST = 'paymentRequest/LOAD_PAYMENT_REQUEST';
export const LOAD_PAYMENT_REQUEST_SUCCESS =
  'paymentRequest/LOAD_PAYMENT_REQUEST_SUCCESS';
export const LOAD_PAYMENT_REQUEST_ERROR =
  'paymentRequest/LOAD_PAYMENT_REQUEST_ERROR';
export const CLEAR_DATA = 'paymentRequest/CLEAR_DATA';

/**
 *
 * MenuContainer
 *
 */

import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import MobileMenu from '../../components/Menu/mobileMenu';
import Menu from '../../components/Menu/menu';
import {
  selectLinkedOrganisations,
  selectLinkedOrganisationsIsLoading,
  selectLinkedOrganisationsLoadError,
  selectUser,
  selectUserErrorMessage,
  selectUserIsLoading,
} from './selectors';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../utils/injectSaga';
import saga from './saga';
import {
  clearLinkedOrganisations,
  loadLinkedOrganisations,
  logoutUser,
} from './actions';
import { resetUserUpdate, switchOrganisation } from '../App/actions';
import { isEmpty } from '../../utils';
import * as constants from '../../global-constants';
import { clearSettings } from '../SettingsContainer/actions';

class MenuContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileMenuOpen: false,
    };
  }

  toggleMobileMenu = () => {
    const { isMobileMenuOpen } = this.state;
    this.setState({
      isMobileMenuOpen: !isMobileMenuOpen,
    });
  };

  handleMobileMenuStateChange = (state) => {
    this.setState({ isMobileMenuOpen: state.isOpen });
  };

  render() {
    const { user } = this.props;
    const isContactOrAdmin =
      user.functionalRole === constants.ROLE_CONTACT_PERSON ||
      user.functionalRole === constants.ROLE_ADMIN;
    const { isMobileMenuOpen } = this.state;
    const switchAndClearOrganisation = (id) => {
      this.props.clearLinkedOrganisations();
      this.props.clearSettings();
      this.props.switchOrganisation(id);
      this.props.turnOnScroll();
    };

    return (
      !isEmpty(user) && (
        <>
          {isMobileOnly && (
            <MobileMenu
              user={user}
              isMobileMenuOpen={isMobileMenuOpen}
              toggleMobileMenu={this.toggleMobileMenu}
              handleMobileMenuStateChange={this.handleMobileMenuStateChange}
              isContactOrAdmin={isContactOrAdmin}
              turnOffBackgroundScroll={this.props.turnOffScroll}
              turnOnBackgroundScroll={this.props.turnOnScroll}
              linkedOrganisations={this.props.linkedOrganisations}
              linkedOrganisationsIsLoading={
                this.props.linkedOrganisationsIsLoading
              }
              linkedOrganisationsLoadError={
                this.props.linkedOrganisationsLoadError
              }
              loadLinkedOrganisations={this.props.loadLinkedOrganisations}
              clearLinkedOrganisations={this.props.clearLinkedOrganisations}
              switchOrganisation={switchAndClearOrganisation}
              resetUserUpdate={this.props.resetUserUpdate}
            />
          )}
          <Menu
            user={user}
            isMobileMenuOpen={isMobileMenuOpen}
            toggleMobileMenu={this.toggleMobileMenu}
            logoutUser={this.props.logoutUser}
            isContactOrAdmin={isContactOrAdmin}
            turnOffBackgroundScroll={this.props.turnOffScroll}
            turnOnBackgroundScroll={this.props.turnOnScroll}
            linkedOrganisations={this.props.linkedOrganisations}
            linkedOrganisationsIsLoading={
              this.props.linkedOrganisationsIsLoading
            }
            linkedOrganisationsLoadError={
              this.props.linkedOrganisationsLoadError
            }
            loadLinkedOrganisations={this.props.loadLinkedOrganisations}
            clearLinkedOrganisations={this.props.clearLinkedOrganisations}
            switchOrganisation={switchAndClearOrganisation}
            resetUserUpdate={this.props.resetUserUpdate}
          />
        </>
      )
    );
  }
}

MenuContainer.propTypes = {
  user: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,

  linkedOrganisations: PropTypes.array,
  linkedOrganisationsIsLoading: PropTypes.bool,
  linkedOrganisationsLoadError: PropTypes.object,
  loadLinkedOrganisations: PropTypes.func.isRequired,
  clearLinkedOrganisations: PropTypes.func.isRequired,
  switchOrganisation: PropTypes.func.isRequired,
  clearSettings: PropTypes.func.isRequired,
  resetUserUpdate: PropTypes.func.isRequired,

  // from App
  turnOnScroll: PropTypes.func.isRequired,
  turnOffScroll: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  // User
  user: selectUser,
  userIsLoading: selectUserIsLoading,
  userErrorMessage: selectUserErrorMessage,
  linkedOrganisations: selectLinkedOrganisations,
  linkedOrganisationsIsLoading: selectLinkedOrganisationsIsLoading,
  linkedOrganisationsLoadError: selectLinkedOrganisationsLoadError,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  loadLinkedOrganisations: () => dispatch(loadLinkedOrganisations()),
  clearLinkedOrganisations: () => dispatch(clearLinkedOrganisations()),
  switchOrganisation: (id) => dispatch(switchOrganisation(id)),
  clearSettings: () => dispatch(clearSettings()),
  resetUserUpdate: () => dispatch(resetUserUpdate()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'menuContainer', reducer });
const withSaga = injectSaga({ key: 'menuContainer', saga });

export default compose(withReducer, withSaga, withConnect)(MenuContainer);

import React from 'react';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { CashbackCampaignsBreadCrumb } from './CashbackCampaignsBreadCrumb';

interface Props {
  title: string;
  children: React.ReactNode;
  breadCrumb?: {
    previousPage: { text: string; link: string };
    currentPage: string;
  };
}

export default function CashbackCampaignsLayout({
  children,
  title,
  breadCrumb,
}: Props) {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {breadCrumb && (
            <CashbackCampaignsBreadCrumb
              previousPage={breadCrumb.previousPage}
              currentPage={breadCrumb.currentPage}
            />
          )}
          <span className={styles.header}>
            <BlokkieTypography
              variant="h2"
              role="heading"
              tabIndex={0}
              aria-level={1}
              style={{
                color: blokkieTheme.colors.grey.veryDark,
              }}
            >
              {t(title)}
            </BlokkieTypography>
          </span>
          <main role="main" className={styles.content}>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: 'auto',
    minHeight: '100%',
    fontSize: '18px',
    padding: `0 ${blokkieTheme.spacing(2)}px`,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  header: {
    '@media (max-width: 712px)': {
      display: 'none',
    },
  },
  content: {
    flex: '1',
    marginTop: '30px',
  },
  innerContainer: {
    maxWidth: '1056px',
    width: '100%',
    position: 'relative',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    paddingBottom: '44px',
    textAlign: 'left',
  },
});

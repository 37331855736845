/**
 *
 * DropDown Component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';

import { DropdownButton, RadioButton } from '../../components';
import { Color, PrimaryButton, TertiaryButton } from '../../styleguide';
import messages from './messages';
import DropdownMenu from '../DropdownMenu';
import DropdownHeader from '../DropdownHeader';
import DropdownFooter from '../DropdownFooter';
import {
  getDatesByLabel,
  getLabelByDate,
} from '../../Transactions/TransactionsFilters/TransactionDateFilter/calendarUtils';

const DropdownWrapper = styled.div`
  display: flex;

  .DateInput,
  .DateRangePickerInput_arrow {
    position: fixed;
    top: -200px;
  }

  .DateRangePicker_picker {
    position: relative;
    top: 16px !important;
    margin-left: -25px;
    margin-right: -55px;
  }

  .DateRangePicker {
    @media ${(props) => props.theme.device.mobile} {
      display: block;
    }
  }

  .DateRangePickerInput__withBorder,
  .DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
  }

  .DayPickerNavigation {
    .DayPickerNavigation_button + .DayPickerNavigation_button {
      left: unset;
      right: 22px;
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 14px;
    left: 22px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 8px 16px;
  }

  .CalendarMonth {
  }

  .CalendarMonth_caption {
    color: ${Color.brandColor01};
    font-size: 16px;
    margin-bottom: 16px;
  }

  .DayPicker_weekHeader {
    font-size: 14px;
    margin-bottom: 8px;
    text-transform: lowercase;
  }

  .CalendarDay__default {
    font-size: 14px;
    border: none;
    outline: none;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span {
    color: ${Color.brandColor01};
    background-color: #e0f2f1 !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    color: ${Color.white};
    background-color: ${Color.brandColor01} !important;
  }

  @media ${(props) => props.theme.device.mobile} {
    .DateRangePicker_picker {
      position: relative;
      top: 0px !important;
      margin-left: -20px;
    }

    .DayPickerNavigation_button {
      position: absolute;
      top: 14px;
      left: 22px;
      line-height: 0.78;
      border-radius: 3px;
      padding: 8px;
    }
  }

  @media ${(props) => props.theme.device.tablet} {
    height: 128px;
  }
`;

const FilterTitle = styled.div`
  height: 56px;
  line-height: 56px;
  font-family: MuseoSansRounded700;
  font-size: 18px;
  padding: 0 24px;
`;

const Options = styled.div`
  overflow: auto;
  max-height: 70%;
`;
const FilterOptionsWrapper = styled.div`
  position: absolute;
  top: 55px;
  left: -50px;
  border-radius: 10px;
  box-shadow: 4px 10px 24px 0 rgba(0, 0, 0, 0.15);
  border: solid 2px #f2f2f3;
  z-index: 99;
  min-width: 280px;

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0px;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -11px;
  left: 0;
  right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ed5565;
  transform: rotate(-45deg);
  background-color: #ffffff;
  color: #57565b;
  border: solid 2px #f2f2f3;
  z-index: -1;

  @media ${(props) => props.theme.device.mobile} {
    display: none;
  }
`;

const FilterOptions = styled.div`
  position: relative;
  padding: 0px 0px 12px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #57565b;
  z-index: 999;

  @media ${(props) => props.theme.device.mobile} {
    height: 100%;
    border: none;
    border-radius: 0px;
  }
`;

const FilterOptionsCalendarWrapper = styled(FilterOptionsWrapper)`
  @media ${(props) => props.theme.device.tablet} {
    margin-left: -34px;
  }
`;

const FilterOptionsCalendarUpArrowLeft = styled(UpArrow)`
  left: -480px;

  @media ${(props) => props.theme.device.tablet} {
    left: -400px;
  }
`;

const BackArrow = styled.img`
  margin-left: 8px;
  font-size: 24px;
  height: 20px;
  width: 27px;
  color: ${Color.brandColor01};
  cursor: pointer;
`;

const FilterCalendar = styled.div`
  position: relative;
  height: 500px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  color: #57565b;
  z-index: 99;

  @media ${(props) => props.theme.device.mobile} {
    height: auto;
  }
`;

const CalendarFooter = styled.div`
  @media ${(props) => props.theme.device.mobile} {
    padding: 0 24px;
    background-color: #ffffff;
    position: fixed;
    height: 80px;
    line-height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 18px;
    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);
    z-index: 9999;
    margin-bottom: 0;
    button {
      position: initial;
      margin: 0px;
      line-height: 30px;
      width: 100%;
      height: 48px;
    }
  }
`;

const CalendarButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 32px;
  right: 32px;
  min-width: 128px;
  z-index: 99;
`;

const NavPrev = styled.img``;

const NavNext = styled.img`
  transform: rotate(180deg);
`;

class DropdownMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownMenu: false,
      selectedOption: {
        date: '',
        transactionType: '',
      },
      showCalendar: false,
      startDate: null,
      endDate: moment(),
      focusedInput: 'startDate',
    };
  }

  UNSAFE_componentWillMount() {
    const selectedOption = {};
    if (this.props.selected && this.props.selected.transactionType) {
      selectedOption.transactionType = this.props.selected.transactionType;
    }
    if (
      this.props.selected &&
      this.props.selected.date.fromDate &&
      this.props.selected.date.toDate
    ) {
      selectedOption.date = getLabelByDate(
        this.props.selected.date.fromDate,
        this.props.selected.date.toDate,
      );
    }
    this.setState({
      selectedOption,
      startDate: this.props.selected
        ? moment(this.props.selected.date.fromDate)
        : null,
      endDate: this.props.selected
        ? moment(this.props.selected.date.toDate)
        : null,
    });
  }

  toggleDropdownMenu = () => {
    this.setState((prevState) => ({
      showDropdownMenu: !prevState.showDropdownMenu,
    }));
  };

  resetDropdownMenu = (event) => {
    event.stopPropagation();
    this.setState({
      selectedOption: {},
      showDropdownMenu: false,
      startDate: null,
    });
    this.props.onSubmit(0, 0, '');
  };

  onOptionClick = (filter, option) => {
    this.setDropdownOption(filter, option);
  };

  setDropdownOption = (filter, option) =>
    this.setState((prevState) => {
      const { selectedOption } = prevState;
      selectedOption[filter] = option;
      return {
        selectedOption,
      };
    });

  hasDropdownOption = (filter, option) =>
    this.state.selectedOption[filter] === option;

  onSubmit = () => {
    this.toggleDropdownMenu();
    const selectedDates = getDatesByLabel(this.state.selectedOption.date);
    this.props.onSubmit(
      selectedDates.fromDate,
      selectedDates.toDate,
      this.state.selectedOption.transactionType,
    );
  };

  getDropdownTitle = () => {
    const filtersCount = Object.keys(this.state.selectedOption).length;
    if (filtersCount) {
      return `${filtersCount} filters`;
    }
    return '';
  };

  handleOutsideClick = () => {
    const selectedOption = this.props.selected;
    this.setState({ selectedOption });
    this.toggleDropdownMenu();
  };

  toggleCalendar = () => {
    this.setState((prevState) => ({ showCalendar: !prevState.showCalendar }));
  };

  isCustomDateSet = () =>
    this.state.startDate !== null && this.state.endDate !== null;

  applyCustomDateAndCloseCalendar = () => {
    this.toggleCalendar();
    const filterDateFrom = this.state.startDate
      .subtract(1, 'day')
      .endOf('day')
      .add(1, 'second')
      .valueOf();
    const filterDateTo = this.state.endDate.endOf('day').valueOf();
    this.props.onSubmit(
      filterDateFrom,
      filterDateTo,
      this.state.selectedOption.transactionType,
    );
  };

  render() {
    const { showDropdownMenu } = this.state;

    return (
      <DropdownWrapper data-test-id={this.props.dataTestId || 'drop-down'}>
        <DropdownButton
          active={showDropdownMenu}
          selected={this.getDropdownTitle()}
          onClick={this.toggleDropdownMenu}
          onReset={this.resetDropdownMenu}
        >
          {this.props.children}
        </DropdownButton>
        <DropdownMenu
          show={showDropdownMenu}
          handleOutsideClick={this.handleOutsideClick}
        >
          <DropdownHeader>
            {this.props.children}
            <TertiaryButton onClick={this.resetDropdownMenu}>
              <FormattedMessage {...messages.reset} />
            </TertiaryButton>
          </DropdownHeader>
          <Options>
            <FilterTitle>
              <FormattedMessage {...messages.transactionType} />
            </FilterTitle>
            <RadioButton
              key="PAYMENT_REQUEST"
              id="PAYMENT_REQUEST"
              onClick={() =>
                this.onOptionClick('transactionType', 'PAYMENT_REQUEST')
              }
              checked={this.hasDropdownOption(
                'transactionType',
                'PAYMENT_REQUEST',
              )}
            >
              <FormattedMessage {...messages.paymentRequest} />
            </RadioButton>
            <RadioButton
              key="REFUND"
              id="REFUND"
              onClick={() => this.onOptionClick('transactionType', 'REFUND')}
              checked={this.hasDropdownOption('transactionType', 'REFUND')}
            >
              <FormattedMessage {...messages.refund} />
            </RadioButton>
            <FilterTitle>
              <FormattedMessage {...messages.date} />
            </FilterTitle>
            <RadioButton
              key="today"
              id="today"
              onClick={() => this.onOptionClick('date', 'today')}
              checked={this.hasDropdownOption('date', 'today')}
            >
              <FormattedMessage {...messages.today} />
            </RadioButton>
            <RadioButton
              key="thisWeek"
              id="thisWeek"
              onClick={() => this.onOptionClick('date', 'thisWeek')}
              checked={this.hasDropdownOption('date', 'thisWeek')}
            >
              <FormattedMessage {...messages.thisWeek} />
            </RadioButton>
            <RadioButton
              key="thisMonth"
              id="thisMonth"
              onClick={() => this.onOptionClick('date', 'thisMonth')}
              checked={this.hasDropdownOption('date', 'thisMonth')}
            >
              <FormattedMessage {...messages.thisMonth} />
            </RadioButton>
            <RadioButton
              key="thisYear"
              id="thisYear"
              onClick={() => this.onOptionClick('date', 'thisYear')}
              checked={this.hasDropdownOption('date', 'thisYear')}
            >
              <FormattedMessage {...messages.thisYear} />
            </RadioButton>
            <RadioButton
              key="customDate"
              id="customDate"
              onClick={() => {
                this.onOptionClick('date', 'customDate');
                this.toggleDropdownMenu();
                this.toggleCalendar();
              }}
              checked={this.hasDropdownOption('date', 'customDate')}
            >
              <FormattedMessage {...messages.customDate} />
            </RadioButton>
          </Options>
          <DropdownFooter>
            <PrimaryButton onClick={() => this.onSubmit()}>
              <FormattedMessage {...messages.filter} />
            </PrimaryButton>
          </DropdownFooter>
        </DropdownMenu>
        {this.state.showCalendar && (
          <FilterOptionsCalendarWrapper>
            <FilterOptionsCalendarUpArrowLeft />
            <FilterOptions>
              <FilterCalendar>
                <BackArrow
                  src="/arrow-back.svg"
                  onClick={() => {
                    this.toggleCalendar();
                    this.toggleDropdownMenu();
                  }}
                />
                <DayPickerRangeController
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({ startDate, endDate })
                  }
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput: focusedInput || 'startDate' })
                  } // PropTypes.func.isRequired,
                  hideKeyboardShortcutsPanel
                  numberOfMonths={isMobileOnly ? 1 : 2}
                  navPrev={<NavPrev src="chevron.svg" />}
                  navNext={<NavNext src="chevron.svg" />}
                  isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
                  initialVisibleMonth={() =>
                    isMobileOnly ? moment() : moment().subtract(1, 'month')
                  }
                  orientation={isMobileOnly ? 'vertical' : 'horizontal'}
                  minimumNights={0}
                />
              </FilterCalendar>
              <CalendarFooter>
                <CalendarButton
                  disabled={!this.isCustomDateSet()}
                  onClick={this.applyCustomDateAndCloseCalendar}
                >
                  <FormattedMessage {...messages.filter} />
                </CalendarButton>
              </CalendarFooter>
            </FilterOptions>
          </FilterOptionsCalendarWrapper>
        )}
      </DropdownWrapper>
    );
  }
}

DropdownMobile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
};

export default DropdownMobile;

import { isEmptyString } from '../../../../utils';

export const handleError = (setErrors, errorId, value) => {
  setErrors((prevErrors) => ({ ...prevErrors, [errorId]: value }));
};

export const validator = (
  setErrors,
  entity,
  value,
  maxValue,
  extraValidation,
) => {
  handleError(
    setErrors,
    entity,
    isEmptyString(value) || value.length > maxValue || !!extraValidation,
  );
};

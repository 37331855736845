import React from 'react';
import { injectIntl } from 'react-intl';
import addressSteps, { Address, PartialAddress } from './constants';
import SelectAddress from './SelectAddress';
import InputAddress from './InputAddress';

interface AddressProps {
  dutchAddress: boolean;
  submitPartialAddress: (value: PartialAddress) => void;
  addressDetails: Address;
  possibleAddresses: Address[];
  submitAddress: (value: Address) => void;
  notFoundError: boolean;
  nextDutchAddressStep: () => void;
  prevDutchAddressStep: () => void;
  currentDutchAddressStep: string;
  ubo?: boolean;
  uboPrevStep?: () => void;
  uboFullName: string;
}

function AddressInput({
  dutchAddress,
  submitPartialAddress,
  addressDetails,
  possibleAddresses,
  submitAddress,
  notFoundError,
  nextDutchAddressStep,
  prevDutchAddressStep,
  currentDutchAddressStep,
  ubo,
  uboPrevStep,
  uboFullName,
}: AddressProps): JSX.Element {
  return (
    <>
      {dutchAddress && (
        <>
          {currentDutchAddressStep === addressSteps.address && (
            <InputAddress
              setPartialAddress={submitPartialAddress}
              addressDetails={addressDetails}
              nextStep={nextDutchAddressStep}
              ubo={ubo}
              prevStep={uboPrevStep}
              uboFullName={uboFullName}
            />
          )}
          {currentDutchAddressStep === addressSteps.selectAddress && (
            <SelectAddress
              possibleAddresses={possibleAddresses}
              submitSelectedAddress={submitAddress}
              addressDetails={addressDetails}
              notFoundError={notFoundError}
              prevStep={prevDutchAddressStep}
              ubo={ubo}
              uboFullName={uboFullName}
            />
          )}
        </>
      )}
      {!dutchAddress && (
        <InputAddress
          isForeign
          setAddress={submitAddress}
          addressDetails={addressDetails}
          ubo={ubo}
          prevStep={uboPrevStep}
          uboFullName={uboFullName}
        />
      )}
    </>
  );
}

export default injectIntl(AddressInput);

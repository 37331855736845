import React from 'react';
import { createUseStyles } from 'react-jss';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';

interface InputLabelProps {
  dataTestId?: string;
  style?: React.CSSProperties;
  text: string;
}

const useStyles = createUseStyles({
  infoBubble: {
    display: 'flex',
    padding: blokkieTheme.spacing(2),
    background: blokkieTheme.colors.grey.veryLight,
    border: '1px',
    borderStyle: 'solid',
    borderColor: blokkieTheme.colors.grey.light,
    borderRadius: blokkieTheme.spacing(1),
    color: blokkieTheme.colors.grey.veryDark,
    marginBottom: blokkieTheme.spacing(3),
  },
});

export function InfoBubble({
  dataTestId,
  style,
  text,
}: InputLabelProps): JSX.Element {
  const classes = useStyles();

  return (
    <div style={style} className={classes.infoBubble} data-test-id={dataTestId}>
      <BlokkieTypography variant="ps500">{text}</BlokkieTypography>
    </div>
  );
}

/**
 * Transaction Bundle Header Messages
 *
 * This contains all the text for the TransactionBundleHeader component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  today: {
    id: 'app.components.TransactionBundleHeader.today',
    defaultMessage: 'Vandaag',
  },
  currentBalance: {
    id: 'app.components.TransactionBundleHeader.currentBalance',
    defaultMessage: 'huidige balans',
  },
  bundledPayout: {
    id: 'app.components.TransactionBundleHeader.bundledPayout',
    defaultMessage: 'Gebundelde transacties',
  },
  singlePayout: {
    id: 'app.components.TransactionBundleHeader.singlePayout',
    defaultMessage: 'Losse transacties',
  },
  total: {
    id: 'app.components.TransactionBundleHeader.total',
    defaultMessage: 'Totaal',
  },
  downloadTransacties: {
    id: 'app.components.TransactionBundleHeader.downloadTransacties',
    defaultMessage: 'Download CAMT 053',
  },
});

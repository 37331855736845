import React from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';

interface InputLabelProps {
  dataTestId?: string;
  style?: React.CSSProperties;
  errorMessage: string;
}

export function ErrorBubble({
  dataTestId,
  style,
  errorMessage,
}: InputLabelProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      style={style}
      className={classes.errorBubble}
      data-test-id={dataTestId}
    >
      <BlokkieTypography
        variant="ps500"
        style={{ color: blokkieTheme.colors.grey.white }}
      >
        {errorMessage}
      </BlokkieTypography>
    </div>
  );
}

const useStyles = createUseStyles({
  errorBubble: {
    display: 'flex',
    backgroundColor: blokkieTheme.colors.signal.error,
    borderRadius: blokkieTheme.spacing(1),
    padding: blokkieTheme.spacing(2),
  },
});

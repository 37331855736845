import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import '!file-loader?name=[name].[ext]!../../../images/arrow-back.svg';
import '!file-loader?name=[name].[ext]!../../../images/gifs/the-office-sorry.gif';
import {
  blokkieTheme,
  BlokkieTypography,
  BlokkieIcon,
  BlokkieButton,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import { createUseStyles } from 'react-jss';
import {
  Header,
  ArrowBack,
  Label,
  OnboardingPrimaryButton,
  Wrapper,
  OnboardingParagraph,
  CenterWrapper,
  OnboardingImage,
} from '../styledComponents';
import { Space, Color, FontSize, PrimaryButton } from '../../styleguide';
import messages from './messages';
import { isEmpty } from '../../../utils';
import OnboardingTextBalloon from '../Shared/OnboardingTextBalloon';
import { BUSINESS_EMAIL_ADDRESS } from '../../../global-constants';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import InputKeyboard from '../components/InputKeyboard';
import PasswordHideOrShowIcon from '../../Password/PasswordHideOrShowIcon';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface PasswordProps {
  prevStep?: () => void;
  sendPassword: (password: string, resetToken: string) => void;
  resetPasswordSuccess?: boolean;
  resetPasswordError?: boolean;
  previousStep?: () => void;
  resetToken: string;
}

function Password(props: PasswordProps): JSX.Element {
  const {
    prevStep,
    sendPassword,
    resetPasswordSuccess,
    resetPasswordError,
    previousStep,
    resetToken,
  } = props;
  const classes = useStyles();

  const [password, setPassword] = useState<string>('');
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [hasUppercaseLetter, setHasUppercaseLetter] = useState<boolean>(false);
  const [hasLowercaseLetter, setHasLowercaseLetter] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialCharacterNoSpaces, setHasSpecialCharacterNoSpaces] =
    useState<boolean>(false);
  const [hasAtLeast8Characters, setHasAtLeast8Characters] =
    useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('password' || 'text');
  useOnboardingPageView('password');

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newPassword = event.target.value;
    const newHasUppercaseLetter = /[A-Z]/.test(newPassword); // ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŸ
    const newHasLowercaseLetter = /[a-z]/.test(newPassword); // àáâãäåæçèéêëìíîïÞßðñòóôõöøùúûüýþÿ
    const newHasNumber = /[0-9]/.test(newPassword);
    const newHasSpecialCharacterNoSpaces = /[-!@#$%^&+=]/.test(newPassword);
    const newHasAtLeast8Characters = newPassword.length > 7;
    const newIsValidPassword =
      newHasUppercaseLetter &&
      newHasLowercaseLetter &&
      newHasNumber &&
      newHasSpecialCharacterNoSpaces &&
      newHasAtLeast8Characters;

    setPassword(newPassword);
    setHasUppercaseLetter(newHasUppercaseLetter);
    setHasLowercaseLetter(newHasLowercaseLetter);
    setHasNumber(newHasNumber);
    setHasSpecialCharacterNoSpaces(newHasSpecialCharacterNoSpaces);
    setHasAtLeast8Characters(newHasAtLeast8Characters);
    setIsValidPassword(newIsValidPassword);
  };

  const onHandleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    setError(false);

    const code = event.key;

    if (code === 'Enter' && isValidPassword) {
      sendPasswordAndGoNext();
    }
  };

  const sendPasswordAndGoNext = (): void => {
    const rightchar = password.match(/^[A-Za-z0-9-!@#$%^&+=]+$/);
    if (isValidPassword && rightchar) {
      sendPassword(password, resetToken);
    } else {
      setError(true);
    }
  };

  if (resetPasswordSuccess) {
    return (
      <CenterWrapper>
        <Header />
        <OnboardingImage src="/succes.gif" alt="" />
        <Label low marginBottom={Space.s0}>
          <FormattedMessage {...messages.resetPasswordSuccessTitle} />
        </Label>
        <OnboardingParagraph narrow textAlign="center" marginBottom={Space.s24}>
          <FormattedMessage {...messages.resetPasswordSuccessText} />
        </OnboardingParagraph>
        <OnboardingPrimaryButton
          onClick={() => {
            window.location = '/login' as string & Location;
          }}
          data-test-id="Onboarding-Login-ResetSuccessButton"
        >
          <FormattedMessage {...messages.resetPasswordSuccessButton} />
        </OnboardingPrimaryButton>
      </CenterWrapper>
    );
  }

  if (!isEmpty(resetPasswordError)) {
    return (
      <>
        <Header>
          <ArrowBack src="/arrow-back.svg" onClick={previousStep} />
        </Header>
        <CenterWrapper {...props}>
          <OnboardingImage src="/the-office-sorry.gif" alt="" />
          <Label marginBottom={Space.s0}>
            <FormattedMessage {...messages.resetPasswordErrorTitle} />
          </Label>
          <OnboardingParagraph>
            <FormattedMessage {...messages.resetPasswordErrorText} />
          </OnboardingParagraph>
          <PrimaryButton
            onClick={() => {
              window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
            }}
          >
            <FormattedMessage {...messages.resetPasswordErrorButton} />
          </PrimaryButton>
        </CenterWrapper>
      </>
    );
  }

  return (
    <Wrapper>
      <NavigationHeader clickLeft={(prevStep as () => void) ?? undefined} />
      <Title>{t('onboarding.choosePassword.title')}</Title>

      <div className={classes.passwordWrapper}>
        <InputKeyboard
          onChange={onChangePassword}
          onKeyDown={onHandleKeyDown}
          id="password"
          placeholder="Zo moeilijk mogelijk"
          autoComplete="off"
          autoCorrect="off"
          value={password}
          data-test-id="Onboarding-Password-Input"
          autoFocus
          relatedButtonId="button-footer"
          style={{
            letterSpacing:
              inputType === 'password' && password.length > 0
                ? '4px'
                : 'normal',
          }}
          type={inputType}
        />
        <PasswordHideOrShowIcon
          setInputType={setInputType}
          inputType={inputType}
        />
      </div>

      <OnboardingTextBalloon show={error} error>
        <OnboardingParagraph
          marginBottom={Space.s0}
          paddingLeft={Space.s8}
          small
        >
          <FormattedMessage {...messages.error} />
        </OnboardingParagraph>
      </OnboardingTextBalloon>
      <div className={classes.verificationWrapper}>
        {hasUppercaseLetter && (
          <div className={classes.verifyOK}>
            <BlokkieIcon
              variant="check"
              color={blokkieTheme.colors.primary.green}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.primary.green,
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.uppercase')}
            </BlokkieTypography>
          </div>
        )}
        {!hasUppercaseLetter && (
          <div className={classes.verifyNOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.transparent.green[33]}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.transparent.green[33],
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.uppercase')}
            </BlokkieTypography>
          </div>
        )}
        {hasLowercaseLetter && (
          <div className={classes.verifyOK}>
            <BlokkieIcon variant="check" />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.primary.green,
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.lowercase')}
            </BlokkieTypography>
          </div>
        )}
        {!hasLowercaseLetter && (
          <div className={classes.verifyNOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.transparent.green[33]}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.transparent.green[33],
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.lowercase')}
            </BlokkieTypography>
          </div>
        )}
        {hasSpecialCharacterNoSpaces && (
          <div className={classes.verifyOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.primary.green}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.primary.green,
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.specialCharacter')}
            </BlokkieTypography>
          </div>
        )}
        {!hasSpecialCharacterNoSpaces && (
          <div className={classes.verifyNOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.transparent.green[33]}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.transparent.green[33],
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.specialCharacter')}
            </BlokkieTypography>
          </div>
        )}
        {hasNumber && (
          <div className={classes.verifyOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.primary.green}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.primary.green,
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.number')}
            </BlokkieTypography>
          </div>
        )}
        {!hasNumber && (
          <div className={classes.verifyNOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.transparent.green[33]}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.transparent.green[33],
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.number')}
            </BlokkieTypography>
          </div>
        )}
        {hasAtLeast8Characters && (
          <div className={classes.verifyOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.primary.green}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.primary.green,
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.length')}
            </BlokkieTypography>
          </div>
        )}
        {!hasAtLeast8Characters && (
          <div className={classes.verifyNOK}>
            <BlokkieIcon
              variant="check"
              color={`${blokkieTheme.colors.transparent.green[33]}`}
            />
            <BlokkieTypography
              style={{
                color: blokkieTheme.colors.transparent.green[33],
              }}
              variant="ps700"
            >
              {t('onboarding.choosePassword.length')}
            </BlokkieTypography>
          </div>
        )}
      </div>
      <ButtonFooter forceAlign="right">
        <BlokkieButton
          disabled={!isValidPassword}
          onClick={sendPasswordAndGoNext}
          data-test-id="Onboarding-Password-NextButton"
          variant="primary"
          size="large"
        >
          {t('next')}
        </BlokkieButton>
      </ButtonFooter>
    </Wrapper>
  );
}

const useStyles = createUseStyles({
  passwordWrapper: {
    marginBottom: blokkieTheme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: FontSize.s22,
    '&::placeholder': {
      letterSpacing: 'normal',
    },
  },
  verificationWrapper: {
    minHeight: '180px',
    fontFamily: 'MuseoSansRounded',
    fontWeight: 'bold',
    fontSize: FontSize.s16,
  },
  verifyOK: {
    color: Color.gray02,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  verifyNOK: {
    extend: 'verifyOK',
    color: Color.gray04,
  },
});

export default Password;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FormWrapper from '../../../components/OnboardingKYC/FormWrapper';
import {
  selectCurrentItemNumber,
  selectGenericError,
  selectGenericPending,
  selectTotalItems,
  selectOrganisationName,
  selectLegalEntity,
  selectExpandOnboardingKYCContainer,
  selectProductType,
} from '../selectors';
import { clearGenericError, clearSession } from '../actions';

function FormWrapperContainer(props) {
  const {
    totalItems,
    currentItemNumber,
    genericError,
    hasSpecificError,
    genericPending,
    organisationName,
    legalEntity,
    noProgressBar,
    clearError,
    expandOnboardingKYCContainer,
    productType,
  } = props;

  const getProgressPercentage = () =>
    Math.floor((currentItemNumber / totalItems) * 100);
  const hasError = !hasSpecificError && !isEmpty(genericError);
  const pending = genericPending !== 0;
  const progressPercentage = noProgressBar ? 0 : getProgressPercentage();

  const logout = () => {
    props.clearSession(true);
  };

  return (
    <FormWrapper
      progressPercentage={progressPercentage}
      error={genericError}
      hasError={hasError}
      pending={pending}
      organisationName={organisationName}
      legalEntity={legalEntity}
      logout={logout}
      clearError={clearError}
      expandOnboardingKYCContainer={expandOnboardingKYCContainer}
      productType={productType}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  currentItemNumber: selectCurrentItemNumber,
  totalItems: selectTotalItems,
  genericError: selectGenericError,
  genericPending: selectGenericPending,
  organisationName: selectOrganisationName,
  legalEntity: selectLegalEntity,
  expandOnboardingKYCContainer: selectExpandOnboardingKYCContainer,
  productType: selectProductType,
});

const mapDispatchToProps = (dispatch) => ({
  clearSession: (redirectBool) => dispatch(clearSession(redirectBool)),
  clearError: () => dispatch(clearGenericError()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

FormWrapperContainer.propTypes = {
  children: PropTypes.any.isRequired,
  genericError: PropTypes.object.isRequired,
  hasSpecificError: PropTypes.bool,
  genericPending: PropTypes.number.isRequired,
  specificPending: PropTypes.bool,
  noProgressBar: PropTypes.bool,
  currentItemNumber: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  organisationName: PropTypes.string.isRequired,
  legalEntity: PropTypes.string.isRequired,
  clearSession: PropTypes.func.isRequired,
  prevStep: PropTypes.func,
  clearError: PropTypes.func.isRequired,
  expandOnboardingKYCContainer: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
};
export default compose(withConnect)(FormWrapperContainer);

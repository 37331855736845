import {
  LOAD_BULK_FILE,
  LOAD_BULK_FILE_ERROR,
  LOAD_BULK_FILE_SUCCESS,
  SEND_BULK_UPLOAD,
  SEND_BULK_UPLOAD_ERROR,
  SEND_BULK_UPLOAD_SUCCESS,
  LOAD_PAYMENT_REQUESTS,
  LOAD_PAYMENT_REQUESTS_ERROR,
  LOAD_PAYMENT_REQUESTS_SUCCESS,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
  CLEAR_DATA,
} from './constants';

export function loadBulkFile(id) {
  return {
    type: LOAD_BULK_FILE,
    payload: {
      id,
    },
  };
}

export function loadBulkFileSuccess(bulkFile) {
  return {
    type: LOAD_BULK_FILE_SUCCESS,
    payload: {
      bulkFile,
    },
  };
}

export function loadBulkFileError(error) {
  return {
    type: LOAD_BULK_FILE_ERROR,
    payload: {
      error,
    },
  };
}

export function sendBulkUpload(id) {
  return {
    type: SEND_BULK_UPLOAD,
    payload: {
      id,
    },
  };
}

export function sendBulkUploadSuccess() {
  return {
    type: SEND_BULK_UPLOAD_SUCCESS,
  };
}

export function sendBulkUploadError(status, error, details) {
  return {
    type: SEND_BULK_UPLOAD_ERROR,
    payload: {
      status,
      error,
      details,
    },
  };
}

export function loadPaymentRequests(
  page,
  label,
  from,
  to,
  status,
  search,
  paymentRequestBatchId,
) {
  return {
    type: LOAD_PAYMENT_REQUESTS,
    payload: {
      page,
      label,
      from,
      to,
      status,
      search,
      paymentRequestBatchId,
    },
  };
}

export function loadPaymentRequestsSuccess(paymentRequests) {
  return {
    type: LOAD_PAYMENT_REQUESTS_SUCCESS,
    payload: {
      paymentRequests,
    },
  };
}

export function loadPaymentRequestsError(error) {
  return {
    type: LOAD_PAYMENT_REQUESTS_ERROR,
    payload: {
      error,
    },
  };
}

export function downloadMultiplePaymentRequests(
  label,
  from,
  to,
  status,
  search,
  allSelected,
  selectedPaymentRequests,
  csvType,
  paymentRequestBatchId,
) {
  return {
    type: DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
    payload: {
      label,
      from,
      to,
      status,
      search,
      allSelected,
      selectedPaymentRequests,
      csvType,
      paymentRequestBatchId,
    },
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

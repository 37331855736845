export const selectTransactions = (state) =>
  state.get('transactionsListContainer').get('transactions').toJS();
export const selectTransactionsIsLoading = (state) =>
  state.get('transactionsListContainer').get('transactionsIsLoading');
export const selectTransactionsErrorMessage = (state) =>
  state.get('transactionsListContainer').get('transactionsErrorMessage');

export const selectFilterSearch = (state) =>
  state.get('transactionsListContainer').get('filterSearch');

export const selectTxFilterPage = (state) =>
  state.get('transactionsListContainer').get('transactionFilterPage');
export const selectTxFilterFromDate = (state) =>
  state.get('transactionsListContainer').get('transactionFilterFromDate');
export const selectTxFilterToDate = (state) =>
  state.get('transactionsListContainer').get('transactionFilterToDate');
export const selectTxFilterType = (state) =>
  state.get('transactionsListContainer').get('transactionFilterType');
export const selectTxFilterSearch = (state) =>
  state.get('transactionsListContainer').get('transactionFilterSearchText');

/*
 * BulkList Messages
 *
 * This contains all the text for the BulkList component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.BulkList.header',
    defaultMessage: 'Betaalverzoekjes',
  },
  tabAll: {
    id: 'app.components.BulkList.tab.all',
    defaultMessage: 'Alle Tikkies',
  },
  tabBulkUploads: {
    id: 'app.components.BulkList.tab.bulkUploads',
    defaultMessage: 'Bulk Tikkies',
  },
  oopsTitle: {
    id: 'app.components.BulkList.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.BulkList.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  oopsButton: {
    id: 'app.components.BulkList.oopsButton',
    defaultMessage: 'Probeer opnieuw',
  },
  firstUseTitle: {
    id: 'app.components.BulkList.firstUseTitle',
    defaultMessage: 'Er zijn nog geen Tikkies via upload.',
  },
  firstUseText: {
    id: 'app.components.BulkList.firstUseText',
    defaultMessage:
      'Stuur nu je eerste Tikkie via upload. Tip: je krijgt een preview te zien voor je alles verstuurt.',
  },
});

import React from 'react';
import styled from 'styled-components';

const EmojiWrap = styled.span`
  ${(props) => props.size && `font-size: ${props.size}px`}
`;

interface EmojiWrapProps {
  label: string;
  symbol: string;
  size: number;
}

function Emoji({ label, symbol, size }: EmojiWrapProps): JSX.Element {
  return (
    <EmojiWrap
      role="img"
      size={size}
      aria-label={label}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
    </EmojiWrap>
  );
}

export default Emoji;

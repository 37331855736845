import '!file-loader?name=[name].[ext]!../../../images/landing-page-example.png';

import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Container, InputTile } from 'amber';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  ContainerProps,
  InputErrorBox,
  PendingStatus,
} from '../styledComponents';
import { isValidUrl } from '../../Util';
import { ImageVerifyUploader } from './ImageVerifyUploader';
import config from '../../../config';
import { PENDING_LOGO } from './constants';
import { PreviewPage } from './PreviewPage';
import { isJpegOrPngFileExtension } from '../../../utils';

function Landing({ values, onChange, validate, errors, displayPendingStatus }) {
  const { t } = useTranslation();
  const validateUrl = () =>
    validate(!isEmpty(values.url) && !isValidUrl(values.url), 'url');

  useEffect(() => {
    validateUrl();
  }, [values, validateUrl]);

  return (
    <>
      <PendingStatus display={displayPendingStatus} />
      <Container col={9}>
        <ContainerProps row mb={24} maxHeight="100%">
          {config.get(config.LOGO_UPLOAD_ENABLED) && (
            <ImageVerifyUploader
              desc={t('portal.settings.pages.landing.description')}
              input={values.inputLogo}
              original={values.logo}
              pending={values.pendingLogo}
              entityName="logo"
              pendingVariable={PENDING_LOGO}
              maxFileSize={512}
              requiredExtensionType="jpeg/jpg/png"
              verifyFileExtension={isJpegOrPngFileExtension}
              onLoad={(file) => {
                onChange('inputLogo', file);
              }}
              onChange={onChange}
            />
          )}
          <Container row mb={24}>
            <InputTile
              title={t('portal.settings.pages.landing.link.title')}
              desc={t('portal.settings.pages.landing.link.description')}
              value={values.url}
              type="text"
              infoDesc={t('portal.settings.pages.landing.link.helpText')}
              id="url"
              dataTestId="Organisation-LandingLinkInput"
              onChange={onChange}
              onBlur={validateUrl}
              placeholder={t('portal.settings.pages.landing.link.placeholder')}
            >
              {errors.url && (
                <InputErrorBox
                  errorText={t('portal.settings.pages.landing.link.error')}
                  withInfo
                />
              )}
            </InputTile>
          </Container>
        </ContainerProps>
      </Container>
      <Container col={3} center={isMobile}>
        <PreviewPage>
          <img src="/landing-page-example.png" alt="landing-page-example" />
        </PreviewPage>
      </Container>
    </>
  );
}

Landing.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  displayPendingStatus: PropTypes.bool.isRequired,
};

export default injectIntl(Landing);

import { defineMessages } from 'react-intl';

export default defineMessages({
  uppercase: {
    id: 'app.components.Password.uppercase',
    defaultMessage: '1 hoofdletter',
  },
  lowercase: {
    id: 'app.components.Password.lowercase',
    defaultMessage: '1 kleine letter',
  },
  specialchar: {
    id: 'app.components.Password.specialchar',
    defaultMessage: '1 speciaal teken: !@#$%^&+-=',
  },
  number: {
    id: 'app.components.Password.number',
    defaultMessage: '1 getal',
  },
  length: {
    id: 'app.components.Password.length',
    defaultMessage: '8 tekens',
  },
  title: {
    id: 'app.components.Password.title',
    defaultMessage: 'Kies je wachtwoord 🤓',
  },
  continue: {
    id: 'app.components.Password.continue',
    defaultMessage: 'Volgende',
  },
  error: {
    id: 'app.components.Password.error',
    defaultMessage: 'Let op, je kunt alleen deze tekens gebruiken: -!@#$%^&+=',
  },
  resetPasswordSuccessTitle: {
    id: 'app.components.Password.resetPasswordSuccessTitle',
    defaultMessage: 'Yes, gelukt!',
  },
  resetPasswordSuccessText: {
    id: 'app.components.Password.resetPasswordSuccessText',
    defaultMessage: 'We hebben je wachtwoord veranderd. Je kunt nu inloggen.',
  },
  resetPasswordSuccessButton: {
    id: 'app.components.Password.resetPasswordSuccessButton',
    defaultMessage: 'Naar inloggen',
  },
  resetPasswordErrorTitle: {
    id: 'app.components.Password.resetPasswordErrorTitle',
    defaultMessage: 'Oeps',
  },
  resetPasswordErrorText: {
    id: 'app.components.Password.resetPasswordErrorText',
    defaultMessage:
      'Er is iets misgegaan bij het veranderen van je wachtwoord.',
  },
  resetPasswordErrorButton: {
    id: 'app.components.Password.resetPasswordErrorButton',
    defaultMessage: 'Neem contact met ons op',
  },
});

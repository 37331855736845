import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectHasAbnBank,
  selectOnboardingFlow,
  selectProductType,
} from './containers/OnboardingKYCContainer/selectors';
import {
  AnalyticCategories,
  analyticsEvent,
  analyticsProductTypeMapper,
} from './ga4';

export function usePrevious(value: never) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useOnboardingPageView(pageName?: string, action = 'pageview') {
  const onboardingFlow = useSelector(selectOnboardingFlow);
  const productType = useSelector(selectProductType);
  const hasAbnBank = useSelector(selectHasAbnBank);

  useEffect(() => {
    // check for present values to prevent null value on initial render
    if (onboardingFlow && productType) {
      analyticsEvent(
        pageName ?? action,
        AnalyticCategories.ONBOARDING,
        'onboarding-event',
        {
          flow: onboardingFlow,
          product: analyticsProductTypeMapper(productType),
          hasAbnBank,
        },
      );
    }
  }, [onboardingFlow, productType]);
}

interface UseOnScrollBottomProps {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
  dataLoaded?: boolean;
}

// hook to verify whether user has reached end of scrollable container
export function useOnScrollBottom({
  scrollRef,
  dataLoaded = true,
}: UseOnScrollBottomProps): boolean {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    if (!dataLoaded) return;

    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      if (scrolledToBottom) return;

      const { scrollTop, clientHeight, scrollHeight } = scrollContainer;
      const threshold = 1;
      const roundedUpScrollTop = Math.ceil(scrollTop);

      if (roundedUpScrollTop + clientHeight >= scrollHeight - threshold) {
        setScrolledToBottom(true);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef, dataLoaded]);

  return scrolledToBottom;
}

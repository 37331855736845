import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ContactPayment.header',
    defaultMessage:
      'Betaal € 0,01 van een betaalrekening die op jouw persoonlijke naam staat.',
  },
  paragraph: {
    id: 'app.components.ContactPayment.paragraph',
    defaultMessage:
      'Daarmee checken we je identiteit. Het mag geen gezamenlijke rekening zijn. Zo houden we Tikkie veilig voor iedereen.',
  },
  button: {
    id: 'app.components.ContactPayment.button',
    defaultMessage: 'Kies jouw persoonlijke bank',
  },
  headerBusiness: {
    id: 'app.components.ContactPayment.headerBusiness',
    defaultMessage:
      'Bijna klaar! Betaal € 0,01 van de zakelijke betaalrekening van ',
  },
  paragraphBusiness: {
    id: 'app.components.ContactPayment.paragraphBusiness',
    defaultMessage:
      'Dit mag geen gezamenlijke rekening zijn. We gebruiken deze rekening straks voor de uitbetaling van je verstuurde Tikkies.',
  },
  buttonBusiness: {
    id: 'app.components.ContactPayment.buttonBusiness',
    defaultMessage: 'Kies jouw zakelijke bank',
  },
  notPaid1: {
    id: 'app.components.ContactPayment.notPaid1',
    defaultMessage: '🤭 Je hebt de betaling geannuleerd...',
  },
  notPaid2: {
    id: 'app.components.ContactPayment.notPaid2',
    defaultMessage: 'Lees ',
  },
  notPaid3: {
    id: 'app.components.ContactPayment.notPaid3',
    defaultMessage: 'hier meer informatie',
  },
  notPaid4: {
    id: 'app.components.ContactPayment.notPaid4',
    defaultMessage: ' over de €0,01 verificatie.',
  },
  notPaid5: {
    id: 'app.components.ContactPayment.notPaid5',
    defaultMessage: 'Kom je er niet uit? ',
  },
  notPaid6: {
    id: 'app.components.ContactPayment.notPaid6',
    defaultMessage: 'Neem contact met ons op',
  },
  notPaid7: {
    id: 'app.components.ContactPayment.notPaid7',
    defaultMessage: '.',
  },
  pendingTitle: {
    id: 'app.components.ContactPayment.pendingTitle',
    defaultMessage: 'Je hebt al een betaling gestart...',
  },
  pendingParagraph: {
    id: 'app.components.ContactPayment.pendingParagraph',
    defaultMessage:
      '... maar het lijkt erop dat je de betaling nog niet hebt afgemaakt.',
  },
  pendingButton: {
    id: 'app.components.ContactPayment.pendingButton',
    defaultMessage: 'Betaling afronden bij iDEAL',
  },
  issuerDown: {
    id: 'app.components.ContactPayment.issuerDown',
    defaultMessage:
      'Sorry, je kan nu niet betalen. Er is een storing met iDEAL-betalingen bij ',
  },
  issuerDownInfo: {
    id: 'app.components.ContactPayment.issuerDownInfo',
    defaultMessage: 'Tik hier voor meer informatie',
  },
  issuerDownInfoTitle: {
    id: 'app.components.ContactPayment.issuerDownInfoTitle',
    defaultMessage: 'Storing bij',
  },
  issuerDownInfoP1: {
    id: 'app.components.ContactPayment.issuerDownInfoP1',
    defaultMessage:
      'Sorry, je kan nu niet betalen. Er is een storing met iDEAL-betalingen bij ',
  },
  issuerDownInfoP2: {
    id: 'app.components.ContactPayment.issuerDownInfoP2',
    defaultMessage: '. Wil je het later nog eens proberen?',
  },
  issuerDownInfoP3: {
    id: 'app.components.ContactPayment.issuerDownInfoP3',
    defaultMessage: 'Bekijk de actuele status op ',
  },
  issuerDownInfoP4Link: {
    id: 'app.components.ContactPayment.issuerDownInfoP4Link',
    defaultMessage: 'beschikbaarheid.ideal.nl',
  },
  issuerDownInfoP5: {
    id: 'app.components.ContactPayment.issuerDownInfoP5',
    defaultMessage: 'Neem voor vragen contact op met je eigen bank.',
  },
  infoScreenTitle: {
    id: 'app.components.ContactPayment.infoScreenTitle',
    defaultMessage: 'Waarom is dit nodig?',
  },
  infoScreenBody1: {
    id: 'app.components.ContactPayment.infoScreenBody1',
    defaultMessage:
      'Met een betaling vanaf een betaalrekening die op jouw persoonlijke naam staat, kunnen we jouw identiteit checken. Zo houden we Tikkie veilig voor iedereen.',
  },
  infoScreenBody2: {
    id: 'app.components.ContactPayment.infoScreenBody2',
    defaultMessage: 'Let op: het mag geen gezamenlijke rekening zijn.',
  },
  infoScreenBody3: {
    id: 'app.components.ContactPayment.infoScreenBody3',
    defaultMessage:
      'We willen zeker weten dat jij toegang hebt tot de zakelijke rekening die gebruikt wordt om Tikkie-betalingen op te ontvangen. We gebruiken dit IBAN ook om jouw bedrijfsgegevens op te halen, zodat je dit niet allemaal zelf hoeft in te vullen.',
  },
  skipPaymentText: {
    id: 'app.components.ContactPayment.skipPaymentText',
    defaultMessage: 'Geen toegang tot deze rekening?',
  },
  skipPaymentLink: {
    id: 'app.components.ContactPayment.skipPaymentLink',
    defaultMessage: 'Sla deze stap over',
  },
  confirmSkipPayment: {
    id: 'app.components.ContactPayment.skipPaymentConfirmation',
    defaultMessage: 'Weet je dat zeker?',
  },
  confirmSkipPaymentExplanation: {
    id: 'app.components.ContactPayment.skipPaymentExplanation',
    defaultMessage:
      'Sla deze stap alleen over als het echt niet anders kan. Als je geen betaling kunt doen, neemt Tikkie Support contact met je op om de betaalrekening als nog te controleren. Wil je verder zonder betaling?',
  },
  yes: {
    id: 'app.components.ContactPayment.DoNotSkipPayment',
    defaultMessage: 'Ja',
  },
  no: {
    id: 'app.components.ContactPayment.doSkipPayment',
    defaultMessage: 'Nee',
  },
});

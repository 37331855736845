export const LOAD_PAYMENT_REQUESTS =
  'app/PaymentRequestListContainer/LOAD_PAYMENT_REQUESTS';
export const LOAD_PAYMENT_REQUESTS_SUCCESS =
  'app/PaymentRequestListContainer/LOAD_PAYMENT_REQUESTS_SUCCESS';
export const LOAD_PAYMENT_REQUESTS_ERROR =
  'app/PaymentRequestListContainer/LOAD_PAYMENT_REQUESTS_ERROR';
export const DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS =
  'app/PaymentRequestListContainer/DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS';
export const RESET_PAYMENT_REQUESTS =
  'app/PaymentRequestListContainer/RESET_PAYMENT_REQUESTS';

import {
  DOWNLOAD_PAYMENTS,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_ERROR,
  LOAD_CAMPAIGN_SUCCESS,
  FETCH_QR_CODE_SUCCESS,
  FETCH_QR_CODE_ERROR,
  RESET_QR_CODE,
} from './constants';

export function loadCampaign(campaignId) {
  return {
    type: LOAD_CAMPAIGN,
    payload: {
      campaignId,
    },
  };
}

export function loadCampaignSuccess(campaign) {
  return {
    type: LOAD_CAMPAIGN_SUCCESS,
    payload: {
      campaign,
    },
  };
}

export function loadCampaignError(error) {
  return {
    type: LOAD_CAMPAIGN_ERROR,
    payload: {
      error,
    },
  };
}

export function downloadPayments(campaignId) {
  return {
    type: DOWNLOAD_PAYMENTS,
    payload: {
      campaignId,
    },
  };
}

export function fetchQRCodeSuccess(response) {
  return {
    type: FETCH_QR_CODE_SUCCESS,
    payload: {
      data: response,
    },
  };
}

export function fetchQRCodeError(error) {
  return {
    type: FETCH_QR_CODE_ERROR,
    payload: {
      error,
    },
  };
}

export function resetQRCode() {
  return {
    type: RESET_QR_CODE,
  };
}

import moment from 'moment-timezone';
import { words, toLower, toUpper, capitalize } from 'lodash';

moment.locale('nl');

export function isEmpty(obj) {
  return obj === null || obj === undefined || JSON.stringify(obj) === '{}';
}

export function isEmptyString(string) {
  return string === null || string === undefined || string === '';
}

export function isEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const getImageFileExtension = (file) => file.split('.').pop();

export const isGifFileExtension = (file) =>
  getImageFileExtension(file) === 'gif';

export const isJpegOrPngFileExtension = (file) => {
  const fileExtenstion = getImageFileExtension(file);
  return (
    fileExtenstion === 'jpeg' ||
    fileExtenstion === 'jpg' ||
    fileExtenstion === 'png'
  );
};

export function toCurrency(cents, decimals) {
  const digits = typeof decimals === 'undefined' ? 2 : decimals;
  const formatter = new Intl.NumberFormat('nl-NL', {
    minimumFractionDigits: digits,
  });
  return `€ ${formatter.format(cents ? cents / 100 : 0)}`;
}

export function formatDate(yesterdayText, timestamp, returnOnlyTime = false) {
  const epochTimestamp = dateToEpoch(timestamp);

  const startYesterday = moment().subtract(1, 'days').startOf('day').valueOf();

  const yesterday = moment().subtract(1, 'days').endOf('day').valueOf();

  const startYear = moment().subtract(1, 'years').endOf('year').valueOf();

  if (epochTimestamp > startYesterday && epochTimestamp <= yesterday) {
    return yesterdayText;
  } else if (epochTimestamp > yesterday || returnOnlyTime) {
    return moment(epochTimestamp).format('HH:mm');
  } else if (epochTimestamp > startYear) {
    return moment(epochTimestamp).format("D MMM 'YY");
  }
  return moment(epochTimestamp).format("D MMM 'YY");
}

export function formatDateOrder(d, out) {
  if (out) return moment(d, 'DD-MM-YYYY').format('YYYY-MM-DD');
  return moment(d, 'YYYY-MM-DD').format('DD-MM-YYYY');
}

export function dateToEpoch(date) {
  return moment(date).valueOf();
}

export const formatDateToPattern = (date, pattern) =>
  moment(date).format(pattern);

export function getCurrentYear() {
  return moment().year();
}

export function getFullName({ firstName, lastNamePrefix, lastName }) {
  if (!lastNamePrefix) {
    return `${firstName} ${lastName}`;
  }
  return `${firstName} ${lastNamePrefix} ${lastName}`;
}

export function formatIban(iban, spacing = 4) {
  let formattedIban = '';
  const length = parseInt(spacing, 10);

  for (let i = 0; i < iban.length; i += 1) {
    if (i % length === 0 && i !== 0) {
      formattedIban += ' ';
    }
    formattedIban += iban.charAt(i);
  }

  return formattedIban;
}

export const businessCustomerIdChars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export function organisationIdToBusinessCustomerId(id) {
  let result = '';
  let orgId = id;
  while (orgId > 0) {
    const rest = orgId % 36;
    result = result.concat(businessCustomerIdChars[rest]);
    orgId = Math.floor(orgId / 36);
  }
  const businessCustomerId = result.split('').reverse().join('');
  return '00000'
    .concat(businessCustomerId)
    .substring(businessCustomerId.length);
}

export const capitalizeDuringTyping = (string) => {
  // // If first letter -> capitalize
  if (string.length === 1) {
    return toUpper(string);
  }
  // If char before last char is space -> capitalize last char
  if (string.charAt(string.length - 2) === ' ') {
    return string.replace(/.$/, capitalizeLastCharacter(string));
  }
  // Else -> toLower
  return string.replace(/.$/, lastCharacterToLowerCase(string));
};

const capitalizeLastCharacter = (string) =>
  toUpper(string.charAt(string.length - 1));

const lastCharacterToLowerCase = (string) =>
  toLower(string.charAt(string.length - 1));

export const capitalizeAndRemoveSpaces = (string) =>
  words(string, /[^ ]+/g).map(toLower).map(capitalize).join(' ');

export const capitalizeFirstChar = (string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export function findFirstWeekDayOnOrBefore(date, weekday) {
  const current = new Date(date);
  while (current.getDay() !== weekday) {
    current.setDate(current.getDate() - 1);
  }
  return current.toISOString().substring(0, 10);
}

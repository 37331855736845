export const selectBulkFiles = (state) =>
  state.get('bulkListContainer').get('bulkFiles').toJS();
export const selectTotalBulkFiles = (state) =>
  state.get('bulkListContainer').get('totalBulkFiles');
export const selectBulkFilesIsLoading = (state) =>
  state.get('bulkListContainer').get('bulkFilesIsLoading');
export const selectBulkFilesErrorMessage = (state) =>
  state.get('bulkListContainer').get('bulkFilesErrorMessage');
export const selectBulkListPage = (state) =>
  state.get('bulkListContainer').get('page');

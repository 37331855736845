/*
 * Otp Messages
 *
 * This contains all the text for the Otp component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Otp.header',
    defaultMessage: 'Security Code',
  },
  info: {
    id: 'app.components.Otp.info',
    defaultMessage: 'Enter the code that we have sent you via SMS:',
  },
  remember: {
    id: 'app.components.Otp.remember',
    defaultMessage: 'Remember for 14 days on this device',
  },
  incorrectOtpError: {
    id: 'app.components.Otp.incorrectOtpError',
    defaultMessage: 'The OTP code that you entered is not correct',
  },
  otpTooFreshError: {
    id: 'app.components.Login.otpTooFreshError',
    defaultMessage: 'Wait a little bit more before requesting a new code.',
  },
  otpExpiredError: {
    id: 'app.components.Login.otpExpiredError',
    defaultMessage: 'The code has expired. New code sent!',
  },
  smsSendError: {
    id: 'app.components.Login.smsSendError',
    defaultMessage: 'A problem occured while sending your OTP code.',
  },
  resendOtpLink: {
    id: 'app.components.Otp.resendOtpLink',
    defaultMessage: 'Send me a new otp code',
  },
  otpLinkHasBeenResent: {
    id: 'app.components.Otp.otpLinkHasBeenResent',
    defaultMessage: 'New code sent!',
  },
  button: {
    id: 'app.components.Otp.button',
    defaultMessage: 'Log in',
  },
});

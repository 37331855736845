/*
 *
 * LoginContainer reducer
 *
 */

import { fromJS } from 'immutable';
import Cookies from 'universal-cookie';

import {
  LOGIN_BLOCKED_USER,
  LOGIN_EXPIRED_OTP_CODE,
  LOGIN_GENERIC_ERROR,
  LOGIN_INVALID_CREDENTIALS,
  LOGIN_INVALID_OTP_CODE,
  LOGIN_ONBOARDING_KYC_USER_SUCCESS,
  LOGIN_RESET,
  LOGIN_SMS_SEND_FAILED,
  LOGIN_TOO_FRESH_OTP_CODE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  OTP,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SEND_RESET_LINK,
  SEND_RESET_LINK_ERROR,
  SEND_RESET_LINK_SUCCESS,
  VALIDATE_PASSWORD_RESET_TOKEN,
  VALIDATE_PASSWORD_RESET_TOKEN_ERROR,
  VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS,
  VALIDATE_REUSED_PASSWORD,
  VALIDATE_REUSED_PASSWORD_ERROR,
  VALIDATE_REUSED_PASSWORD_SUCCESS,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  RESEND_PASSWORD_SET_LINK,
  RESEND_PASSWORD_SET_LINK_SUCCESS,
  RESEND_PASSWORD_SET_LINK_ERROR,
} from './constants';
import tokenCookie from '../../utils/tokenCookie';

const moment = require('moment-timezone');

const OTP_COOKIE_NAME = 'cookieOtp';
const REMEMBER_ME_COOKIE_NAME = 'cookieRememberMe';

const TWO_WEEKS = moment().add(14, 'days').toDate();

const cookies = new Cookies();
export const initialState = fromJS({
  username: '',
  password: '',
  rememberMe: cookies.get(REMEMBER_ME_COOKIE_NAME) !== 'false',
  otp: cookies.get(OTP_COOKIE_NAME) || '',
  action: '',
  isLoading: false,
  isSuccessful: false,
  isResendOtpRequest: false,
  invalidCredentials: false,
  invalidOTPCode: false,
  expiredOTPCode: false,
  tooFreshOTPCode: false,
  blockedUser: false,
  smsSendFailed: false,
  genericError: null,
  isSendingResetLink: false,
  resetLinkSent: false,
  sendResetLinkError: false,
  isResettingPassword: false,
  passwordResetSuccess: false,
  passwordResetError: false,
  isValidatingToken: false,
  validatingTokenSuccess: false,
  validatingTokenError: 0,
  isValidatingReusedPassword: false,
  validatingReusedPasswordSuccess: false,
  validatingReusedPasswordError: false,
  isValidatingPwToken: false,
  validatingPwTokenSuccess: false,
  validatingPwTokenError: 0,
  isSettingPassword: false,
  passwordSetSuccess: false,
  passwordSetError: 0,
  firstName: '',
  isResendingLink: false,
  linkResent: false,
  resendLinkError: 0,
  passwordTokenError: 0,
});

function loginContainerReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      // Should've been done during logout, but do it again just in case
      localStorage.clear();

      if (action.payload.otp) {
        cookies.set(REMEMBER_ME_COOKIE_NAME, action.payload.rememberMe, {
          path: '/',
          expires: TWO_WEEKS,
        });
      }

      return state
        .set('username', action.payload.username)
        .set('password', action.payload.password)
        .set('rememberMe', action.payload.rememberMe)
        .set('otp', action.payload.otp)
        .set('action', action.payload.action)
        .set('isLoading', true)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_USER_SUCCESS:
      cookies.set(
        OTP_COOKIE_NAME,
        action.payload.rememberMe ? action.payload.otp : '',
        {
          path: '/',
          expires: TWO_WEEKS,
        },
      );

      return state
        .set('username', '')
        .set('password', '')
        .set('otp', '')
        .set('isLoading', false)
        .set('isSuccessful', true)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);
    case LOGIN_ONBOARDING_KYC_USER_SUCCESS:
      cookies.set(
        OTP_COOKIE_NAME,
        action.payload.rememberMe ? action.payload.otp : '',
        {
          path: '/',
          expires: TWO_WEEKS,
        },
      );
      tokenCookie(action.payload.res);
      return state
        .set('username', '')
        .set('password', '')
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_INVALID_CREDENTIALS:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', true)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_INVALID_OTP_CODE:
      return state
        .set('otp', '')
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', true)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_EXPIRED_OTP_CODE:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', true)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_TOO_FRESH_OTP_CODE:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', true)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_BLOCKED_USER:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', true)
        .set('smsSendFailed', false)
        .set('genericError', false);

    case LOGIN_SMS_SEND_FAILED:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', true)
        .set('genericError', false);

    case LOGIN_GENERIC_ERROR:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', action.payload.error);

    case LOGIN_RESET:
      return state
        .set('isLoading', false)
        .set('isSuccessful', false)
        .set('invalidCredentials', false)
        .set('invalidOTPCode', false)
        .set('expiredOTPCode', false)
        .set('tooFreshOTPCode', false)
        .set('blockedUser', false)
        .set('smsSendFailed', false)
        .set('genericError', false)
        .set('isSendingResetLink', false)
        .set('resetLinkSent', false)
        .set('sendResetLinkError', false);

    case SEND_RESET_LINK:
      return state
        .set('username', action.payload.email)
        .set('password', '')
        .set('isSendingResetLink', true)
        .set('resetLinkSent', false)
        .set('sendResetLinkError', false)
        .set('blockedUser', false);

    case SEND_RESET_LINK_SUCCESS:
      return state
        .set('isSendingResetLink', false)
        .set('resetLinkSent', true)
        .set('sendResetLinkError', false);

    case SEND_RESET_LINK_ERROR:
      return state
        .set('isSendingResetLink', false)
        .set('resetLinkSent', false)
        .set('sendResetLinkError', true);

    case RESET_PASSWORD:
      return state
        .set('isResettingPassword', true)
        .set('passwordResetSuccess', false);

    case RESET_PASSWORD_SUCCESS:
      return state
        .set('isResettingPassword', false)
        .set('passwordResetSuccess', true)
        .set('passwordResetError', false);

    case RESET_PASSWORD_ERROR:
      return state
        .set('isResettingPassword', false)
        .set('passwordResetSuccess', false)
        .set('passwordResetError', true);

    case VALIDATE_PASSWORD_RESET_TOKEN:
      return state
        .set('isValidatingToken', true)
        .set('validatingTokenSuccess', false)
        .set('validatingTokenError', 0)
        .set('passwordTokenError', 0);

    case VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS:
      return state
        .set('isValidatingToken', false)
        .set('validatingTokenSuccess', true)
        .set('validatingTokenError', 0)
        .set('passwordTokenError', 0);

    case VALIDATE_PASSWORD_RESET_TOKEN_ERROR:
      return state
        .set('isValidatingToken', false)
        .set('validatingTokenSuccess', false)
        .set('validatingTokenError', action.payload.status)
        .set('passwordTokenError', action.payload.status);

    case VALIDATE_REUSED_PASSWORD:
      return state
        .set('isValidatingReusedPassword', true)
        .set('validatingReusedPasswordSuccess', false)
        .set('validatingReusedPasswordError', false);

    case VALIDATE_REUSED_PASSWORD_SUCCESS:
      return state
        .set('isValidatingReusedPassword', false)
        .set('validatingReusedPasswordSuccess', true)
        .set('validatingReusedPasswordError', false);

    case VALIDATE_REUSED_PASSWORD_ERROR:
      return state
        .set('isValidatingReusedPassword', false)
        .set('validatingReusedPasswordSuccess', false)
        .set('validatingReusedPasswordError', true);

    case VALIDATE_TOKEN:
      return state
        .set('isValidatingPwToken', true)
        .set('validatingPwTokenSuccess', false)
        .set('validatingPwTokenError', 0)
        .set('passwordTokenError', 0);

    case VALIDATE_TOKEN_SUCCESS:
      return state
        .set('isValidatingPwToken', false)
        .set('validatingPwTokenSuccess', true)
        .set('validatingPwTokenError', 0)
        .set('firstName', action.payload.firstName)
        .set('passwordTokenError', 0);

    case VALIDATE_TOKEN_ERROR:
      return state
        .set('isValidatingPwToken', false)
        .set('validatingPwTokenSuccess', false)
        .set('validatingPwTokenError', action.payload.status)
        .set('passwordTokenError', action.payload.status);

    case SET_PASSWORD:
      return state
        .set('isSettingPassword', true)
        .set('passwordSetSuccess', false)
        .set('passwordSetError', 0);

    case SET_PASSWORD_SUCCESS:
      return state
        .set('isSettingPassword', false)
        .set('passwordSetSuccess', true)
        .set('passwordSetError', 0);

    case SET_PASSWORD_ERROR:
      return state
        .set('isSettingPassword', false)
        .set('passwordSetSuccess', false)
        .set('passwordSetError', action.payload.status);

    case RESEND_PASSWORD_SET_LINK:
      return state
        .set('isResendingLink', true)
        .set('linkResent', false)
        .set('resendLinkError', 0);

    case RESEND_PASSWORD_SET_LINK_SUCCESS:
      return state
        .set('isResendingLink', false)
        .set('linkResent', true)
        .set('resendLinkError', 0);

    case RESEND_PASSWORD_SET_LINK_ERROR:
      return state
        .set('isResendingLink', false)
        .set('linkResent', false)
        .set('resendLinkError', action.payload.status);

    case OTP: {
      const { otp } = action.payload;
      cookies.set(OTP_COOKIE_NAME, otp, {
        path: '/',
        expires: TWO_WEEKS,
      });
      return state.set('otp', otp);
    }

    default:
      return state;
  }
}

export default loginContainerReducer;

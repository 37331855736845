/*
 * ForgotPassword Messages
 *
 * This contains all the text for the ForgotPassword component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ForgotPassword.header',
    defaultMessage: 'Wagwoord vergeet?',
  },
  info: {
    id: 'app.components.ForgotPassword.info',
    defaultMessage:
      "Gee ons jou email adres en dan stuur ons vir jou 'n link waarmee jy jou wagwoord kan verander.",
  },
  email: {
    id: 'app.components.ForgotPassword.email',
    defaultMessage: 'E-pos adres',
  },
  emailPlaceholder: {
    id: 'app.components.ForgotPassword.emailPlaceholder',
    defaultMessage: 'bv. hoff@gmail.com',
  },
  emailError: {
    id: 'app.components.ForgotPassword.emailError',
    defaultMessage: "Voer 'n geldige e-pos adres in.",
  },
  sendPasswordResetLinkButton: {
    id: 'app.components.ForgotPassword.sendPasswordResetLinkButton',
    defaultMessage: "Stuur vir my 'n link",
  },
  sendResetLinkGenericError: {
    id: 'app.components.ForgotPassword.sendResetLinkGenericError',
    defaultMessage: 'Iets het fout gegaan',
  },
  checkMail: {
    id: 'app.components.ForgotPassword.checkMail',
    defaultMessage: 'Koekeloer in jou mailbox',
  },
  checkMailInfo: {
    id: 'app.components.ForgotPassword.checkMailInfo',
    defaultMessage:
      'Indien ons jou email adres herken dan is die e-pos met jou reset link al oppad na jou mailbox toe.',
  },
  checkMailButton: {
    id: 'app.components.ForgotPassword.checkMailButton',
    defaultMessage: 'Regso, baie dankie!',
  },
});

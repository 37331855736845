import React from 'react';
import styled from 'styled-components';
import {
  Button,
  CaptionSmall,
  Container,
  Header2,
  PageTitle,
  Paragraph,
} from 'amber';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Color, Space, Radii, FontWeight, FontSize } from '../styleguide';
import oops2gif from '../../images/gifs/oops2.gif';
import messages from './messages';
import theme from '../../theme';
import { BUSINESS_EMAIL_ADDRESS } from '../../global-constants';

export const Header2Card = styled(Header2)`
  margin-top: 19px !important;
  margin-bottom: 19px !important;
`;

export const Header2Manual = styled(Header2)`
  font-size: 24px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
`;

export const CaptionSmallPading = styled(CaptionSmall)`
  padding-bottom: 14px !important;
`;

export const ContainerBox = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 24px !important;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 ${Color.gray08};
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 50px;
`;

export const ContainerRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

export const ContainerRowExplanation = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 50px;

  @media ${(props) => props.theme.device.mobile} {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

export const PageDescription = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  flex-direction: column;
  width: 100%;
`;

export const ParagraphSmallWidth = styled(Paragraph)`
  width: 78%;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const ExplanationText = styled(PageDescription)`
  width: 50%;
  text-align: left;

  @media ${(props) => props.theme.device.mobile} {
    width: 80%;
    text-align: ${(props) => (props.center ? 'center' : 'left')};
  }
`;

export const ExplanationHeader = styled(PageDescription)`
  width: 100%;
  text-align: left;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 85px;
  padding-bottom: 50px;

  @media ${(props) => props.theme.device.mobile} {
    width: 80%;
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    padding: 0;
  }
`;

export const ToggleBox = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  justify-content: flex-end;
  flex-direction: column;
  width: 25%;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  justify-content: center;
  width: 17%;
`;

export const ImageWrapperToggle = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.device.mobile} {
    justify-content: center;
    width: 100%;
    padding-top: 29px;
  }
`;

export const ParagraphToggle = styled(Paragraph)`
  margin-right: 15px !important;
`;

export const Image = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;

  img {
    height: 108px;
    width: 108px;
  }
`;

export const ImageWrapperExplanation = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  justify-content: center;
  width: 50%;
`;

export const ImageExplanation = styled.div`
  display: flex;
  margin-top: 19px;
  box-sizing: border-box;
  background-color: inherit;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: 238px;
  }
`;

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: ${(props) => (props.slideIn ? '0px' : '-96px')};
  left: 0px;
  right: 0px;
  background-color: ${Color.white};
  box-shadow: 0 -4px 30px 0 rgba(0, 0, 0, 0.1);
  transition: bottom 0.3s;
  display: flex;
  flex-direction: row;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1056px;
  margin: 0 auto;
  padding: 24px 0px 24px 0px;
  line-height: 48px;
  font-family: MuseoSansRounded;
  font-size: 16px;
  color: ${Color.gray01};

  a {
    font-family: MuseoSansRounded700;
    color: ${Color.brandColor01};
  }

  button {
    float: right;
    margin-right: 19px;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: auto;
    margin: 0;
    padding: 24px 32px 24px 32px;
  }

  @media ${(props) => props.theme.device.mobile} {
    justify-content: center;
  }
`;

// Errorpage
const ContainerBoxError = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 24px !important;
  background-color: ${Color.white};
  box-shadow: 0 2px 4px 0 ${Color.gray08};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 50px;
  height: 100%;
`;

const ErrorWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 35%;

  @media ${(props) => props.theme.device.mobile} {
    width: 100%;
  }
`;

const GifWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  width: 176px;
  height: 176px;
  border: 12px solid rgba(104, 104, 193, 0.1);
  border-radius: 88px;
`;

const GifWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  border: 8px solid ${Color.brandColor02};
  border-radius: 88px;
`;

const Gifje = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 88px;
`;

const ParagraphMargin = styled(Paragraph)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

const ButtonMargin = styled(Button)`
  margin-top: 10px;
  background: transparent;
`;

export function ErrorPage(props) {
  return (
    <Container row ptM={32} prM={16} plM={16} pbM={16}>
      <Container row pb={24}>
        <Button type="secondary" onClick={props.onClickBackButton}>
          <img src="/back.svg" alt="back" />
          <FormattedMessage {...messages.terug} />
        </Button>
      </Container>
      <PageTitle>{props.pageTitle}</PageTitle>
      <ContainerBoxError data-test-id="container-box-error">
        <ErrorWrapper>
          <GifWrapper>
            <GifWrapperInner>
              <Gifje src={props.image} alt="" />
            </GifWrapperInner>
          </GifWrapper>
          <Header2>{props.errorTitle}</Header2>
          <ParagraphMargin>{props.errorDescription}</ParagraphMargin>
          {props.mailButton && (
            <ButtonMargin
              onClick={() => {
                window.location.href = `mailto:${BUSINESS_EMAIL_ADDRESS}`;
              }}
            >
              <FormattedMessage {...messages.errorButton} />
            </ButtonMargin>
          )}
          <ButtonMargin type="secondary" onClick={props.onClickSecondaryButton}>
            {props.secondaryButtonLabel || (
              <FormattedMessage {...messages.errorSecondaryButton} />
            )}
          </ButtonMargin>
        </ErrorWrapper>
      </ContainerBoxError>
    </Container>
  );
}

ErrorPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  errorDescription: PropTypes.string.isRequired,
  mailButton: PropTypes.bool,
  onClickSecondaryButton: PropTypes.func.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  secondaryButtonLabel: PropTypes.string,
};

ErrorPage.defaultProps = {
  secondaryButtonLabel: null,
  mailButton: false,
};

export function ErrorPageWithContact(props) {
  const errorText = !props.errorType
    ? messages.errorTextLoad.defaultMessage
    : messages[`errorText${props.errorType}`].defaultMessage;
  return (
    <ErrorPage
      pageTitle={props.pageTitle}
      image={oops2gif}
      errorTitle={messages.errorTitle.defaultMessage}
      errorDescription={errorText}
      mailButton
      onClickSecondaryButton={props.onClickBackOverview}
      onClickBackButton={props.onClickBackOverview}
    />
  );
}

ErrorPageWithContact.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onClickBackOverview: PropTypes.func.isRequired,
  errorType: PropTypes.oneOf(['NewToken', 'ModifyToken']),
};

export function ErrorPageNoContact(props) {
  const getDescription = () => {
    if (props.description) return props.description;
    if (props.showUnauthorizedDescription)
      return messages.errorTextUnauthorized.defaultMessage;
    return messages.errorTextUpdate.defaultMessage;
  };
  return (
    <ErrorPage
      pageTitle={props.pageTitle || messages.errorTitle.defaultMessage}
      image={oops2gif}
      errorTitle={props.errorTitle || messages.errorTitle.defaultMessage}
      errorDescription={getDescription()}
      onClickBackButton={props.onClickBackOverview}
      secondaryButtonLabel={props.secondaryButtonLabel}
      onClickSecondaryButton={props.onClickBackOverview}
    />
  );
}

ErrorPageNoContact.propTypes = {
  pageTitle: PropTypes.string,
  errorTitle: PropTypes.string,
  onClickBackOverview: PropTypes.func.isRequired,
  showUnauthorizedDescription: PropTypes.bool,
  description: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
};

ErrorPageNoContact.defaultProps = {
  errorTitle: null,
  description: null,
  secondaryButtonLabel: null,
};

export const GreyedOutTextBox = styled.div`
  width: 100%;
  min-height: 48px;
  padding: 16px;
  box-sizing: border-box;
  font-family: MuseoSansRounded700, Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${Color.gray06};
  border-radius: 10px;
  margin-top: 15px;
`;

export const ColoredText = styled.span`
  color: ${(props) => (props.color ? props.color : Color.gray02)};
`;

const EmptyDiv = styled.div``;

export function BackButton(props) {
  return (
    <Container row pb={24}>
      {!isMobile && (
        <Button
          type="secondary"
          onClick={props.goBack}
          dataTestId={props.dataTestId || 'back-button'}
        >
          <img src="/back.svg" alt="back" />
          {props.text}
        </Button>
      )}
      {isMobile && (
        <EmptyDiv onClick={props.goBack}>
          <img src="/back.svg" alt="back" />
        </EmptyDiv>
      )}
    </Container>
  );
}

BackButton.propTypes = {
  goBack: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

const ImageButton = styled(Button)`
  position: ${(props) => (props.relative ? 'relative' : 'absolute')};
  right: 0;
  margin-bottom: ${Space.s8};
`;

export function AddButton(props) {
  const {
    text,
    type,
    alt,
    onClick,
    disabled,
    relative = false,
    dataTestId,
  } = props;
  const src = type === 'secondary' ? '/plusicon.svg' : '/plusicon-o.svg';
  return (
    <ImageButton
      data-test-id={dataTestId}
      type={type || 'primary'}
      alt={alt}
      onClick={onClick}
      relative={relative}
      disabled={disabled}
      dataTestId={dataTestId || 'add-button'}
    >
      <img src={src} alt="plus icon" />
      {text}
    </ImageButton>
  );
}

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  relative: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
};

export const ContainerProps = styled(Container)`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '100px')};
  align-items: ${(props) => props.alignItems} !important;
  @media ${(props) => props.theme.device.mobile} {
    max-height: inherit;
  }
`;

export const ErrorTextContainer = styled.div`
  width: 100%;
  min-height: 48px;
  padding: 16px;
  box-sizing: border-box;
  font-family: MuseoSansRounded700, Arial, Helvetica, sans-serif;
  font-size: 16px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ed5565'};
  border-radius: 10px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};
  margin-bottom: ${(props) => props.marginBottom && `${props.marginBottom}px`};
  text-align: center;
  color: white;
`;

export function SubBox(props) {
  return (
    <>
      {!props.fullWidth && <Container col={3} />}
      <Container col={props.fullWidth ? 12 : 9}>
        <Container col={props.withInfo ? 11 : 12}>
          <ErrorTextContainer
            backgroundColor={props.backgroundColor}
            marginBottom={props.marginBottom}
          >
            {props.children}
          </ErrorTextContainer>
        </Container>
      </Container>
    </>
  );
}

SubBox.propTypes = {
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withInfo: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export function InputErrorBox(props) {
  return (
    <SubBox {...props}>
      <div>{props.errorText}</div>
    </SubBox>
  );
}

InputErrorBox.propTypes = {
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  backgroundColor: PropTypes.string,
  withInfo: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export function NotificationBox(props) {
  return (
    <InputErrorBox
      errorText={props.errorText}
      fullWidth={props.fullWidth}
      marginBottom={props.marginBottom}
      backgroundColor="#F1AB47"
    />
  );
}

NotificationBox.propTypes = {
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export function PendingStatus({ display }) {
  return (
    display && (
      <NotificationBox
        errorText="Deze pagina wordt door ons gecheckt. Dit duurt ongeveer 1 werkdag."
        marginBottom={30}
      />
    )
  );
}

PendingStatus.propTypes = {
  display: PropTypes.bool.isRequired,
};

export const SelectWrapper = styled.div`
  position: relative;
  display: inline;
  width: ${(props) => (props.width ? `${props.width}px` : '240px')};

  :after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid
      ${(props) => (props.color ? props.color : theme.palette.green)};
    right: 24px;
    top: 20px;
  }

  select:focus {
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    border: solid 2px #f2f2f3;
    border-radius: ${(props) => (props.rounded ? Radii.s80 : Radii.s8)};
    height: 46px;
    padding: ${Radii.s8} ${Radii.s24};
    width: ${(props) => (props.width ? `${props.width}px` : '240px')};
    line-height: 28px;
    color: ${(props) =>
      props.textColor ? props.textColor : Color.brandColor01};
    position: relative;
    font-weight: ${FontWeight.s700};
    font-size: ${FontSize.s16};
    font-family: MuseoSansRounded;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  option {
    color: ${(props) => (props.textColor ? props.textColor : Color.gray01)};
    font-weight: ${FontWeight.s500};
  }
`;

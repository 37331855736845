import { takeLatest, call, put } from 'redux-saga/effects';
import moment from 'moment';
import request from '../../../utils/request';
import { LOAD_TRANSACTIONS, DOWNLOAD_CAMT } from './constants';
import { loadTransactionsSuccess, loadTransactionsError } from './actions';
import config from '../../../config';

export default function* watcherSaga() {
  yield takeLatest(LOAD_TRANSACTIONS, loadTransactionsSaga);
  yield takeLatest(DOWNLOAD_CAMT, downloadCamtSaga);
}

export function* loadTransactionsSaga(action) {
  const { page, from, to, transactionType, searchString } = action.payload;
  const currentPage = page - 1;
  let fromDate = '';
  let toDate = '';
  const search = searchString
    ? `&search=${encodeURIComponent(searchString)}`
    : '';
  const type = transactionType ? `&type=${transactionType}` : '';

  if (from > 0 && to > 0) {
    fromDate = `&fromDate=${moment(from).format('YYYY-MM-DD')}`;
    toDate = `&toDate=${moment(to).format('YYYY-MM-DD')}`;
  }

  const requestUrl = `${config.API_URL}/transaction?page=${currentPage}&size=20${fromDate}${toDate}${type}${search}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadTransactionsSuccess(response));
  } catch (err) {
    yield put(loadTransactionsError(err.message));
  }
}

export function* downloadCamtSaga(action) {
  const { date } = action.payload;
  window.location = `${config.API_URL}/camt/${date}`;
}

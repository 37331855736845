import React from 'react';
import { createUseStyles } from 'react-jss';

import { GiphyBox } from '../components';

interface Props {
  gif: string;
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void | Promise<void>;
}

export function GifContainer(props: Props) {
  const { text, gif, title, buttonText, onClick } = props;
  const styles = useStyles();

  return (
    <>
      <div
        role="button"
        hidden
        tabIndex={0}
        onKeyDown={(event) => event.key === 'Enter' && onClick()}
      >
        {text}
      </div>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GiphyBox
            gif={gif}
            onClick={onClick}
            title={title}
            text={text}
            button={buttonText}
          />
        </div>
      </div>
    </>
  );
}

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '564px',
    paddingTop: '116px',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
  },
  wrapper: {
    margin: '0 auto',
    width: '276px',
  },
});

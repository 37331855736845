import { call, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { push } from 'connected-react-router/immutable';

import request from '../../utils/request';
import {
  ADD_USER_TO_ACTIVE_ORGANISATION,
  CREATE_API_TOKEN,
  FETCH_AUDIT_LOGS,
  FETCH_PERMITTED_ORGANISATIONS_USERS,
  FETCH_USER,
  FETCH_USERS,
  LOAD_API_TOKENS,
  LOAD_PERMITTED_ORGANISATIONS,
  ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
  LOAD_SETTINGS,
  MODIFY_API_TOKEN,
  REQUEST_PASSWORD_RESET,
  UPDATE_SETTINGS,
  UPDATE_USER,
  DELETE_USER,
  DEACTIVATE_ORGANISATION,
} from './constants';
import config from '../../config';
import {
  addUserToActiveOrganisationError,
  addUserToActiveOrganisationSuccess,
  createApiTokenError,
  createApiTokenSuccess,
  fetchAuditLogsError,
  fetchAuditLogsSuccess,
  fetchPermittedOrganisationsUsersError,
  fetchPermittedOrganisationsUsersSuccess,
  fetchUserError,
  fetchUsersError,
  fetchUsersSuccess,
  fetchUserSuccess,
  loadApiTokensError,
  loadApiTokensSuccess,
  addExistingUserToActiveOrganisationSuccess,
  addExistingUserToActiveOrganisationError,
  loadPermittedOrganisationsSuccess,
  loadPermittedOrganisationsError,
  loadSettingsError,
  loadSettingsSuccess,
  modifyApiTokenError,
  modifyApiTokenSuccess,
  requestPasswordResetMailError,
  requestPasswordResetMailSuccess,
  updateSettingsError,
  updateSettingsSuccess,
  updateUserError,
  updateUserSuccess,
  deleteUserSuccess,
  deleteUserError,
  deactivationSuccess,
  deactivationError,
} from './actions';
import { loadUser } from '../App/actions';

const cookies = new Cookies();

export default function* watcherSaga() {
  yield takeLatest(LOAD_SETTINGS, loadSettingsSaga);
  yield takeLatest(UPDATE_SETTINGS, updateSettingsSaga);
  yield takeLatest(LOAD_API_TOKENS, loadApiTokensSaga);
  yield takeLatest(CREATE_API_TOKEN, createApiTokenSaga);
  yield takeLatest(MODIFY_API_TOKEN, modifyApiTokenSaga);
  yield takeLatest(FETCH_AUDIT_LOGS, fetchAuditLogs);
  yield takeLatest(FETCH_USERS, fetchUsers);
  yield takeLatest(
    ADD_USER_TO_ACTIVE_ORGANISATION,
    addUserToActiveOrganisation,
  );
  yield takeLatest(
    ADD_EXISTING_USER_TO_ACTIVE_ORGANISATION,
    addExistingUserToActiveOrganisation,
  );
  yield takeLatest(LOAD_PERMITTED_ORGANISATIONS, loadPermittedOrganisations);
  yield takeLatest(
    FETCH_PERMITTED_ORGANISATIONS_USERS,
    fetchPermittedOrganisationsUsers,
  );
  yield takeLatest(FETCH_USER, fetchUserSaga);
  yield takeLatest(UPDATE_USER, updateUserSaga);
  yield takeLatest(REQUEST_PASSWORD_RESET, requestPasswordResetSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(DEACTIVATE_ORGANISATION, deactivateOrganisation);
}

export function* loadSettingsSaga() {
  const requestUrl = `${config.API_URL}/organisation`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadSettingsSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;

    yield put(loadSettingsError({ message: err.message, statusCode }));
  }
}

export function* updateSettingsSaga(action) {
  const requestUrl = `${config.API_URL}/organisation`;
  const body = action.payload;

  try {
    const response = yield call(request, requestUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    yield put(updateSettingsSuccess(response));
  } catch (err) {
    yield put(updateSettingsError(err.response?.status, err.message));
  }
}

export function* loadApiTokensSaga() {
  const requestUrl = `${config.API_URL}/organisation/apps`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadApiTokensSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;

    yield put(loadApiTokensError({ message: err.message, statusCode }));
  }
}

export function* createApiTokenSaga(action) {
  const requestUrl = `${config.API_URL}/organisation/apps`;
  const body = action.payload;

  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    yield put(createApiTokenSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;

    yield put(createApiTokenError({ message: err.message, statusCode }));
  }
}

export function* modifyApiTokenSaga(action) {
  const requestUrl = `${config.API_URL}/organisation/apps/${action.payload.appToken}`;
  const body = {
    enabled: action.payload.enabled,
    name: action.payload.name,
    rights: action.payload.rights,
  };

  try {
    const response = yield call(request, requestUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
    yield put(modifyApiTokenSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;

    yield put(modifyApiTokenError({ message: err.message, statusCode }));
  }
}

export function* fetchAuditLogs(action) {
  let requestUrl = `${config.API_URL}/audit?page=${action.payload.params.page}&size=${action.payload.params.size}`;

  if (action.payload.params.type)
    requestUrl += `&type=${action.payload.params.type}`;
  if (action.payload.params.search)
    requestUrl += `&search=${action.payload.params.search}`;
  if (action.payload.params.date)
    requestUrl += `&date=${action.payload.params.date}`;

  try {
    const response = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(fetchAuditLogsSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(fetchAuditLogsError({ message: err.message, statusCode }));
  }
}

export function* fetchUsers(action) {
  let requestUrl = `${config.API_URL}/active-organisation/users?page=${action.payload.params.page}&size=${action.payload.params.size}`;

  if (action.payload.params.role)
    requestUrl += `&role=${action.payload.params.role}`;
  if (action.payload.params.search)
    requestUrl += `&search=${action.payload.params.search}`;

  try {
    const response = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(fetchUsersSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(fetchUsersError({ message: err.message, statusCode }));
  }
}

export function* addUserToActiveOrganisation(action) {
  const requestUrl = `${config.API_URL}/active-organisation/user`;
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
      body: JSON.stringify(action.payload.user),
    });
    yield put(addUserToActiveOrganisationSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(
      addUserToActiveOrganisationError({ message: err.message, statusCode }),
    );
  }
}

export function* addExistingUserToActiveOrganisation(action) {
  const { user } = action.payload;
  const requestUrl = `${config.API_URL}/active-organisation/user/${user.id}`;
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
      body: JSON.stringify(user),
    });
    yield put(addExistingUserToActiveOrganisationSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(
      addExistingUserToActiveOrganisationError({
        message: err.message,
        statusCode,
      }),
    );
  }
}

export function* loadPermittedOrganisations() {
  const requestUrl = `${config.API_URL}/permitted-organisations`;
  try {
    const response = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(loadPermittedOrganisationsSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(
      loadPermittedOrganisationsError({ message: err.message, statusCode }),
    );
  }
}

export function* fetchPermittedOrganisationsUsers(action) {
  let requestUrl = `${config.API_URL}/permitted-organisations/users?page=${action.payload.params.page}&size=${action.payload.params.size}`;

  if (action.payload.params.orgId)
    requestUrl += `&orgId=${action.payload.params.orgId}`;
  if (action.payload.params.search)
    requestUrl += `&search=${action.payload.params.search}`;

  try {
    const response = yield call(request, requestUrl, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(fetchPermittedOrganisationsUsersSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(
      fetchPermittedOrganisationsUsersError({
        message: err.message,
        statusCode,
      }),
    );
  }
}

export function* fetchUserSaga({ payload }) {
  const requestUrl = `${config.API_URL}/active-organisation/user/${payload.id}`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(fetchUserSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;

    yield put(fetchUserError({ message: err.message, statusCode }));
  }
}

export function* updateUserSaga({ payload }) {
  const { id, user } = payload;
  const requestUrl = `${config.API_URL}/active-organisation/user/${id}`;

  try {
    const response = yield call(request, requestUrl, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      body: JSON.stringify(user),
      credentials: 'include',
    });
    yield put(updateUserSuccess(response));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(updateUserError({ message: err.message, statusCode }));
  }
}

export function* requestPasswordResetSaga(action) {
  const { email } = action.payload;
  const requestUrl = `${config.API_URL}/requestResetLink`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(requestPasswordResetMailSuccess());
  } catch (err) {
    if (!err.response) {
      yield put(requestPasswordResetMailSuccess());
    } else {
      yield put(requestPasswordResetMailError(err));
    }
  }
}

export function* deleteUserSaga(action) {
  const { id, allOrganisations } = action.payload;
  const requestUrl = allOrganisations
    ? `${config.API_URL}/permitted-organisations/user/${id}`
    : `${config.API_URL}/active-organisation/user/${id}`;

  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
    });
    yield put(deleteUserSuccess());
    yield put(push('/settings/user-management/users'));
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(deleteUserError({ message: err.message, statusCode }));
  }
}

export function* deactivateOrganisation(action) {
  const requestUrl = `${config.API_URL}/deactivate`;
  const body = action.payload;
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
      },
      credentials: 'include',
      body: JSON.stringify(body),
    });
    yield put(deactivationSuccess(response));
    yield put(loadUser());
  } catch (err) {
    const statusCode = err.response?.status;
    yield put(deactivationError({ message: err.message, statusCode }));
  }
}

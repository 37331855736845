import '!file-loader?name=[name].[ext]!../../../../images/empty-logo.png';
import '!file-loader?name=[name].[ext]!../../../../images/empty-thankyou.png';
import '!file-loader?name=[name].[ext]!../../../../images/empty-expiry.png';
import '!file-loader?name=[name].[ext]!../../../../images/empty-maxpayments.png';
import '!file-loader?name=[name].[ext]!../../../../images/approved.svg';
import '!file-loader?name=[name].[ext]!../../../../images/pending.svg';

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import {
  Button,
  Caption,
  Card,
  CardActions,
  CardBody,
  CardContent,
  CardTitle,
  Container,
  device,
} from 'amber';
import { Modal, Label } from '../../../../global-components';
import { capitalizeFirstChar } from '../../../../utils';
import {
  PENDING_EXPIRY_IMG,
  PENDING_MAY_PAYMENT_IMG,
  PENDING_THANK_YOU_IMG,
} from '../constants';

const AlertError = styled(Label)`
  margin-left: 0;
  margin-top: 16px;
  color: red;
`;

const Image = styled.img`
  display: block;
  width: 108px;
  height: 108px;
  margin: 24px 31px;
  border-radius: 88px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const MediaContainer = styled(Container)`
  align-self: center;
  justify-content: center;

  @media ${device.tablet} {
    justify-content: center;
    padding-right: 0px;
    padding-bottom: 16px;
  }
  @media ${device.mobile} {
    justify-content: center;
    padding-right: 0px;
    padding-bottom: 16px;
  }
`;

const Status = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 25px;
  right: 35px;
`;

export function ImageVerifyUploader({
  desc,
  original,
  pending,
  input,
  entityName,
  pendingVariable,
  maxFileSize,
  requiredExtensionType,
  verifyFileExtension,
  onLoad,
  onChange,
}) {
  const [showImageError, setShowImageError] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState(null);

  const onDrop = useCallback(
    (files) => {
      const file = files[0];
      const isValidFileExtension = verifyFileExtension(file.name);
      if (file.size / 1024 > maxFileSize) {
        setShowImageError(true);
        setImageErrorMessage(
          `Je ${entityName} mag maximaal ${getMaxFileSizeString()} zijn.`,
        );
      } else if (!isValidFileExtension) {
        setShowImageError(true);
        setImageErrorMessage(
          `Je ${entityName} mag alleen van het bestandtype ${requiredExtensionType} zijn.`,
        );
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          onLoad(reader.result);
        };
        reader.onerror = () => {
          setImageErrorMessage(reader.text);
          setShowImageError(true);
        };
        reader.readAsDataURL(file);
      }
    },
    [maxFileSize, onLoad, setShowImageError],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getConditionalValues = () => {
    const capitalEntityName = capitalizeFirstChar(entityName);

    if (original && !pending && !input) {
      return {
        title: `${capitalEntityName} (geverifieerd)`,
        buttonLabel: `${capitalEntityName} vervangen`,
      };
    }

    if (!original && pending && !input) {
      return {
        title: `${capitalEntityName} in afwachting van verificatie`,
        altDesc: `Je ${entityName} wordt door ons gecheckt. Dit duurt ongeveer 1 werkdag.`,
        buttonLabel: `${capitalEntityName} verwijderen`,
        onClick: (e) => {
          e.stopPropagation();
          onChange(pendingVariable, null);
        },
      };
    }

    if (original && pending && !input) {
      return {
        title: `Vervanging ${entityName} (in afwachting)`,
        altDesc: `Je ${entityName} wordt door ons gecheckt. Dit duurt ongeveer 1 werkdag.`,
        buttonLabel: `Vervanging annuleren`,
        onClick: (e) => {
          e.stopPropagation();
          onChange(pendingVariable, null);
        },
      };
    }

    return {
      title: `Upload je ${entityName}`,
      buttonLabel: `${capitalEntityName} kiezen`,
    };
  };

  const getMaxFileSizeString = () =>
    maxFileSize > 1500
      ? `${Math.floor(maxFileSize / 1024)}mb`
      : `${maxFileSize}kb`;

  const { title, altDesc, buttonLabel, onClick } = getConditionalValues();

  const getDefaultImg = () => {
    if (pendingVariable === PENDING_THANK_YOU_IMG) return '/empty-thankyou.png';

    if (pendingVariable === PENDING_EXPIRY_IMG) return '/empty-expiry.png';

    if (pendingVariable === PENDING_MAY_PAYMENT_IMG)
      return '/empty-maxpayments.png';

    return '/empty-logo.png';
  };

  return (
    <>
      <Container
        row
        mb={24}
        {...getRootProps()}
        style={
          isDragActive
            ? { backgroundColor: 'lightgrey', border: 'dotted gray 1px' }
            : {}
        }
      >
        <Card>
          <CardContent center>
            {original && (pending || input) ? (
              <MediaContainer center>
                <ImageContainer>
                  <Image src={original} />
                  <Status src="/approved.svg" />
                </ImageContainer>
                <ImageContainer>
                  <Image src={pending || input} />
                  {pending && <Status src="/pending.svg" />}
                </ImageContainer>
              </MediaContainer>
            ) : (
              <ImageContainer>
                <Image src={input || original || pending || getDefaultImg()} />
                {!input && (original || pending) && (
                  <Status src={original ? '/approved.svg' : '/pending.svg'} />
                )}
              </ImageContainer>
            )}
            <CardTitle>{title}</CardTitle>
            {isDragActive ? (
              <CardBody>
                <Caption style={{ color: 'green' }}>
                  Sleep je bestand hierheen
                </Caption>
              </CardBody>
            ) : (
              <CardBody>
                {!onClick ? (
                  <Caption style={{ maxWidth: '60%' }}>{desc}</Caption>
                ) : (
                  <Caption style={{ maxWidth: '60%' }}>{altDesc}</Caption>
                )}
              </CardBody>
            )}
            <div style={{ padding: '24px 0px' }}>
              <CardActions>
                {!onClick ? (
                  <Button disabled={isDragActive}>{buttonLabel}</Button>
                ) : (
                  <Button onClick={onClick}>{buttonLabel}</Button>
                )}
              </CardActions>
              {!onClick && (
                <input
                  {...getInputProps()}
                  data-test-id="upload-avatar-input"
                />
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
      {showImageError && (
        <Modal
          onClose={() => setShowImageError(false)}
          title="Ongeldig bestand"
        >
          <AlertError>{imageErrorMessage}</AlertError>
        </Modal>
      )}
    </>
  );
}

ImageVerifyUploader.propTypes = {
  desc: PropTypes.string.isRequired,
  extraDesc: PropTypes.string,
  original: PropTypes.string,
  pending: PropTypes.string,
  input: PropTypes.string,
  entityName: PropTypes.string.isRequired,
  pendingVariable: PropTypes.string.isRequired,
  requiredExtensionType: PropTypes.string.isRequired,
  verifyFileExtension: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

import Loader from '../Loader';
import { Shade, Wrapper } from '../styledComponents';
import OnboardingRadioButton from '../Shared/OnboardingRadioButton';
import { InfoScreen } from '../InfoScreen';
import { UboInfoScreen } from '../UBO/UboInfoScreen';
import { Address } from './constants';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';

interface SelectAddressProps {
  prevStep: () => void;
  possibleAddresses: Address[];
  submitSelectedAddress: (value: Address) => void;
  notFoundError: boolean;
  addressDetails: Address;
  ubo?: boolean;
  uboFullName?: string;
}

function SelectAddress({
  possibleAddresses,
  notFoundError,
  addressDetails,
  submitSelectedAddress,
  prevStep,
  ubo,
  uboFullName,
}: SelectAddressProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  useOnboardingPageView('select-address');

  useEffect(() => {
    if (possibleAddresses.length === 1)
      setSelectedAddress(possibleAddresses[0]);
  }, [possibleAddresses]);

  const findAddress = possibleAddresses.find(
    (possibleAddress) =>
      possibleAddress.street === addressDetails.street &&
      possibleAddress.houseNumber === addressDetails.houseNumber &&
      possibleAddress.addition === addressDetails.addition &&
      possibleAddress.postalCode === addressDetails.postalCode &&
      possibleAddress.city === addressDetails.city,
  );
  const [selectedAddress, setSelectedAddress] = useState(
    findAddress !== undefined ? findAddress : undefined,
  );
  const [info, setInfo] = useState(false);

  const showInfo = (): void => setInfo(!info);

  if (possibleAddresses.length < 1 && !notFoundError) {
    return <Loader />;
  }

  const selectTitle = ubo
    ? t('onboarding.address.select.uboTitle', { uboFullName })
    : t('onboarding.address.select.title');

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={prevStep as () => void}
          clickRight={possibleAddresses.length > 1 ? showInfo : undefined}
          noMarginBottom
        />
        <>
          {ubo && (
            <BlokkieTypography
              variant="pl700"
              style={{
                color: blokkieTheme.colors.grey.dark,
                marginTop: blokkieTheme.spacing(4),
              }}
            >
              {t('onboarding.address.select.ubo')}
            </BlokkieTypography>
          )}
          <BlokkieTypography
            variant="h3"
            style={{
              marginTop: !ubo ? blokkieTheme.spacing(4) : 0,
              marginBottom: blokkieTheme.spacing(2),
              color: blokkieTheme.colors.grey.veryDark,
            }}
          >
            {notFoundError
              ? t('onboarding.address.select.notFoundError')
              : selectTitle}
          </BlokkieTypography>
        </>
        <div className={classes.container}>
          {notFoundError && (
            <BlokkieTypography
              variant="pl500"
              style={{ color: blokkieTheme.colors.grey.veryDark }}
            >
              {t('onboarding.address.notFound.postalCode', {
                postalCode: addressDetails.postalCode,
              })}{' '}
              {t('onboarding.address.notFound.houseNumber', {
                houseNumber: addressDetails.houseNumber,
              })}{' '}
              {t('onboarding.address.notFound.areYouSure')}
            </BlokkieTypography>
          )}
          {!notFoundError &&
            possibleAddresses.map((a) => (
              <OnboardingRadioButton
                key={`${a.street} + ${a.addition}`}
                id={`${a.street} + ${a.addition}`}
                onClick={() => setSelectedAddress(a)}
                checked={isEqual(selectedAddress, a)}
                text={`${a.street} ${a.houseNumber} ${
                  a.addition ? a.addition : ''
                }, ${a.postalCode}`}
                data-test-id="Onboarding-SelectAddress-Select"
              />
            ))}
        </div>
        <Shade />
        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            disabled={isEmpty(selectedAddress)}
            data-test-id="Onboarding-SelectAddress-NextButton"
            onClick={() =>
              selectedAddress && submitSelectedAddress(selectedAddress)
            }
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info && !ubo}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={SelectAddress.name}
      >
        <BlokkieTypography
          variant="pl500"
          style={{ color: blokkieTheme.colors.grey.veryDark }}
        >
          {t('onboarding.address.select.infoBody')}
        </BlokkieTypography>
      </InfoScreen>
      <UboInfoScreen
        show={info && !!ubo}
        showInfo={showInfo}
        fromPage={`${SelectAddress.name}_ubo`}
      />
    </>
  );
}

const useStyles = createUseStyles({
  container: {
    height: '250px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&::after': {
      display: 'block',
      height: '20px',
      content: '""',
    },
    '& button': {
      display: 'block',
      height: '32px',
      lineHeight: '32px',
    },
  },
});

export default SelectAddress;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import axios from 'axios';
import config from '../../config';
import { Color, FontSize, FontWeight } from '../styleguide';
import Emoji from '../Emoji';
import { setExpandOnboardingKYCContainer } from '../../containers/OnboardingKYCContainer/actions';

import '!file-loader?name=[name].[ext]!../../images/warning.svg';
import '!file-loader?name=[name].[ext]!../../images/error.svg';
import '!file-loader?name=[name].[ext]!../../images/close-warning.svg';
import '!file-loader?name=[name].[ext]!../../images/close-error.svg';

const Symbol = styled.div`
  margin-right: 18px;

  @media ${(props) => props.theme.device.mobile} {
    margin-bottom: 16px;
    margin-right: 0px;
  }
`;

const CloseButton = styled.img`
  cursor: pointer;
  margin-left: 18px;

  @media ${(props) => props.theme.device.mobile} {
    position: absolute;
    margin-bottom: 32px;
    right: 10px;
    top: 10px;
  }
`;

const warningColor = {
  fill: 'rgba(241, 171, 71, 0.05)',
  border: 'rgba(241, 171, 71, 0.1)',
};

const errorColor = {
  fill: 'rgba(237, 85, 101, 0.05)',
  border: 'rgba(237, 85, 101, 0.1)',
};

const TextWrap = styled.div`
  flex: 1;
  font-family: MuseoSansRounded;
  font-weight: ${FontWeight.s100};
  font-size: ${FontSize.s16};
  color: ${Color.gray01};
`;

const SdmBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
  position: relative;

  ${(props) =>
    props.isInner &&
    css`
      margin-top: ${props.marginTop ? props.marginTop : '32px'};
      margin-bottom: 10px;
      width: 100%;
    `} @media ${(props) => props.theme.device.mobile} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '32px')};
    margin-right: ${(props) => (props.isInner ? '0px' : '16px')};
    margin-left: ${(props) => (props.isInner ? '0px' : '16px')};
    flex-direction: column;
    text-align: center;
  }
  ${(props) =>
    ['warn', 'info'].includes(props.type) &&
    `background: linear-gradient(
      0deg,
      ${warningColor.fill},
      ${warningColor.fill}
    ),
    ${props.theme.palette.white};`} ${(props) =>
    ['warn', 'info'].includes(props.type) &&
    `border: 4px solid ${warningColor.border}`} ${(props) =>
    props.type === 'error' &&
    `background: linear-gradient(
      0deg,
      ${errorColor.fill},
      ${errorColor.fill}
      ),
      ${props.theme.palette.white};
    `} ${(props) =>
    props.type === 'error' && `border: 4px solid ${errorColor.border};`}
`;

function ShortDisturbanceMessage({ context, isInner, marginTop }) {
  const [activeMessage, setActiveMessage] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const messages = await getActiveMessages();

      if (messages[0]) {
        setActiveMessage(messages[0]);
        dispatch(setExpandOnboardingKYCContainer(true));
      }
    })();
    return () => dispatch(setExpandOnboardingKYCContainer(false));
  }, []); //eslint-disable-line

  const getActiveMessages = async () => {
    const response = await axios.get(
      `${config.API_GATEWAY_URL}/api/short-disturbance/context/${context}/message`,
    );

    return response.data.messages;
  };

  if (!activeMessage || !isVisible) {
    dispatch(setExpandOnboardingKYCContainer(false));
    return null;
  }

  const text = activeMessage?.message?.nl?.body;
  const { type, emoji } = activeMessage;
  const isError = type === 'error';

  const renderSymbol = () => (
    <Symbol>
      {emoji ? (
        <Emoji
          label="Short Disturbance Message Emoji"
          symbol={emoji}
          size={30}
        />
      ) : (
        <img src={isError ? '/error.svg' : '/warning.svg'} alt="symbol" />
      )}
    </Symbol>
  );

  const renderCloseButton = () => (
    <CloseButton
      src={isError ? '/close-error.svg' : '/close-warning.svg'}
      onClick={() => setIsVisible(false)}
      data-test-id="sdm-close-button"
    />
  );

  return (
    <SdmBlock
      type={type}
      isInner={isInner}
      marginTop={marginTop}
      data-test-id="sdm-block"
    >
      {isMobileOnly ? (
        <>
          {renderSymbol()}
          {renderCloseButton()}
          <TextWrap>{text}</TextWrap>
        </>
      ) : (
        <>
          {renderSymbol()}
          <TextWrap>{text}</TextWrap>
          {renderCloseButton()}
        </>
      )}
    </SdmBlock>
  );
}

ShortDisturbanceMessage.propTypes = {
  context: PropTypes.string.isRequired,
  isInner: PropTypes.bool,
  marginTop: PropTypes.string,
};

ShortDisturbanceMessage.defaultProps = {
  isInner: false,
};

export default ShortDisturbanceMessage;

import { takeLatest, call, put } from 'redux-saga/effects';

import request from '../../../utils/request';
import { LOAD_BULK_FILES } from './constants';
import { loadBulkFilesSuccess, loadBulkFilesError } from './actions';
import config from '../../../config';

export default function* watcherSaga() {
  yield takeLatest(LOAD_BULK_FILES, loadBulkFilesSaga);
}

export function* loadBulkFilesSaga(action) {
  const { page } = action.payload;
  const currentPage = page - 1;
  const requestUrl = `${config.API_URL}/batch?page=${currentPage}&size=20`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    yield put(loadBulkFilesSuccess(response));
  } catch (err) {
    yield put(loadBulkFilesError(err.message));
  }
}

/**
 * CashbackCampaignsContainer
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CashbackCampaignsComponent from '../../../components/Cashback/Campaigns';
import {
  Container,
  DashboardWrapper,
  InnerContainer,
} from '../../../components/components';
import { isEmpty } from '../../../utils';
import EmptyLoader from '../../../components/Util/Loader';
import { selectUser } from '../../App/selectors';
import {
  selectCampaigns,
  selectCampaignsErrorMessage,
  selectCampaignsIsLoading,
} from './selectors';
import { loadCampaigns } from './actions';
import injectReducer from '../../../utils/injectReducer';
import reducer from './reducer';
import injectSaga from '../../../utils/injectSaga';
import saga from './saga';

function CashbackCampaignsContainer(props) {
  const history = useHistory();

  if (isEmpty(props.user)) {
    return <EmptyLoader />;
  }
  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <CashbackCampaignsComponent
            user={props.user}
            campaigns={props.campaigns}
            campaignsIsLoading={props.campaignsIsLoading}
            campaignsErrorMessage={props.campaignsErrorMessage}
            loadCampaigns={props.loadCampaigns}
            showCampaignDetail={(campaign) =>
              history.push(`/tikkieterug/${campaign.id}`)
            }
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

CashbackCampaignsContainer.propTypes = {
  user: PropTypes.object,
  campaigns: PropTypes.array,
  campaignsIsLoading: PropTypes.bool,
  campaignsErrorMessage: PropTypes.string,
  loadCampaigns: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  campaigns: selectCampaigns,
  campaignsIsLoading: selectCampaignsIsLoading,
  campaignsErrorMessage: selectCampaignsErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  loadCampaigns: () => dispatch(loadCampaigns()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'cashbackCampaignsContainer',
  reducer,
});
const withSaga = injectSaga({ key: 'cashbackCampaignsContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(CashbackCampaignsContainer);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import '!file-loader?name=[name].[ext]!../../../../images/blank.svg';
import '!file-loader?name=[name].[ext]!../../../../images/checkmark2.svg';

import {
  BlokkieButton,
  BlokkieTextLink,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { t } from 'i18next';
import OnboardingCheckBox from '../../Shared/OnboardingCheckBox';
import ButtonFooter from '../../components/ButtonFooter';
import NavigationHeader from '../../components/NavigationHeader';
import { Wrapper } from '../../styledComponents';
import { UboInvolvementInfoScreen } from './UboInvolvementInfoScreen';
import { involvementTypes } from './constants';
import { useOnboardingPageView } from '../../../../hooks';
import Title from '../../Title';

interface UboInvolvementSelectProps {
  prevStep: () => void;
  nextStep: () => void;
  owner: boolean;
  switchOwner: () => void;
  control: boolean;
  switchControl: () => void;
  organisationName: string;
  uboFullName?: string;
  contactPersonAsUbo?: boolean;
}

function UboInvolvementSelect({
  prevStep,
  nextStep,
  owner,
  switchOwner,
  control,
  switchControl,
  organisationName,
  uboFullName,
  contactPersonAsUbo,
}: UboInvolvementSelectProps): JSX.Element {
  const [info, setInfo] = useState(false);
  const showInfo = (): void => setInfo(!info);
  const validInput = (): boolean => owner || control;

  useOnboardingPageView('ubo-involvement-select');

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={prevStep as () => void}
          clickRight={showInfo}
          noMarginBottom
        />
        {contactPersonAsUbo && (
          <Title>
            {t('onboarding.ubo.involvement.select.header', {
              organisationName,
            })}
          </Title>
        )}
        {!contactPersonAsUbo && (
          <>
            <BlokkieTypography
              style={{
                marginTop: blokkieTheme.spacing(4),
                color: blokkieTheme.colors.grey.dark,
              }}
              variant="pl700"
            >
              {t('onboarding.ubo.involvement.select.otherUboDescription')}
            </BlokkieTypography>
            <Title>
              {t('onboarding.ubo.involvement.select.otherUboHeader', {
                uboFullName,
                organisationName,
              })}
            </Title>
          </>
        )}
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.dark,
          }}
          variant="ps700"
        >
          {t('onboarding.ubo.involvement.select.description')}
        </BlokkieTypography>
        <BlokkieTextLink
          onClick={showInfo}
          underline
          color={blokkieTheme.colors.grey.dark}
          style={{
            marginTop: blokkieTheme.spacing(),
            marginBottom: blokkieTheme.spacing(7),
          }}
          variant="pm700"
        >
          {t('onboarding.ubo.involvement.select.more')}
        </BlokkieTextLink>
        <OnboardingCheckBox
          id={involvementTypes.owner}
          checked={owner}
          onClick={switchOwner}
          data-test-id="Onboarding-UboInvolvementSelect-owner"
        >
          {`${
            contactPersonAsUbo
              ? t('onboarding.ubo.involvement.select.option1')
              : t('onboarding.ubo.involvement.select.noneUboOption1')
          }`}
        </OnboardingCheckBox>
        <OnboardingCheckBox
          id={involvementTypes.control}
          checked={control}
          onClick={switchControl}
          data-test-id="Onboarding-UboInvolvementSelect-control"
        >
          {`${
            contactPersonAsUbo
              ? t('onboarding.ubo.involvement.select.option2')
              : t('onboarding.ubo.involvement.select.noneUboOption2')
          }`}
        </OnboardingCheckBox>
        <ButtonFooter>
          <BlokkieButton
            disabled={!validInput()}
            onClick={nextStep}
            data-test-id="Onboarding-UboInvolvementSelect-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <UboInvolvementInfoScreen
        show={info}
        showInfo={showInfo}
        fromPage={`${UboInvolvementSelect.name}`}
      />
    </>
  );
}

export default UboInvolvementSelect;

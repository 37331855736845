import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as enCommon from './en/common.json';
import * as nlCommon from './nl/common.json';
import * as enAccessibility from './en/accessibility.json';
import * as nlAccessibility from './nl/accessibility.json';

const resources = {
  en: {
    common: enCommon,
    a11y: enAccessibility,
  },
  nl: {
    common: nlCommon,
    a11y: nlAccessibility,
  },
};

export const ns = {
  common: 'common',
  a11y: 'a11y',
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  defaultNS: ns.common,
  resources,
  ns: [ns.common, ns.a11y],
  lng: 'nl',
  fallbackLng: 'nl',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

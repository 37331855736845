import { blokkieTheme } from '@tikkie/blokkie';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { Color, PrimaryButton } from '../../styleguide';
import messages from './messages';

interface SuggestionsProps {
  link: string;
  onCopy(): void;
  disabled?: boolean;
  dataTestId: string;
  isSuggestionLink?: boolean;
  label: FormattedMessage.MessageDescriptor;
}

const gotoTikkieLink = (href: string) =>
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href,
  }).click();

function ShareLink({
  link,
  onCopy,
  disabled,
  dataTestId,
  label,
}: SuggestionsProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={classes.row}
      style={{
        opacity: disabled ? '50%' : '100%',
      }}
    >
      <div className={classes.label}>
        <FormattedMessage {...label} />
      </div>
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          value={link}
          readOnly
          disabled={disabled}
          onClick={() => gotoTikkieLink(link)}
          data-test-id={dataTestId}
        />
      </div>
      <CopyToClipboard text={link} onCopy={onCopy}>
        <PrimaryButton className={classes.button} disabled={disabled}>
          <img src="/copyicon-o.svg" alt="" />
          <FormattedMessage {...messages.buttonCopyLink} />
        </PrimaryButton>
      </CopyToClipboard>
    </div>
  );
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    marginBottom: blokkieTheme.spacing(2),
    padding: 30,
    borderRadius: blokkieTheme.spacing(1),
    backgroundColor: blokkieTheme.colors.grey.white,
    alignItems: 'center',
    gap: 32,
    [blokkieTheme.mediaQueries.allPhone]: {
      flexDirection: 'column',
      gap: 16,
    },
  },
  label: {
    lineHeight: '24px',
    fontFamily: 'MuseoSansRounded700',
    fontSize: '20px',
    color: Color.gray01,
    userSelect: 'none',
    flex: '0 0 158px',
    [blokkieTheme.mediaQueries.allPhone]: {
      flex: 'unset',
      lineHeight: '26px',
      fontSize: '22px',
      marginLeft: 0,
      width: '100%',
    },
  },
  button: {
    [blokkieTheme.mediaQueries.allPhone]: {
      float: 'unset',
      width: '100%',
      marginTop: blokkieTheme.spacing(1),
    },
  },
  inputContainer: {
    flex: 1,
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
    },
  },
  input: {
    width: '100%',
    lineHeight: '24px',
    fontFamily: 'MuseoSansRounded700',
    fontSize: '22px',
    color: Color.brandColor01,
    outline: 'none',
    border: 'none',
    textDecoration: 'underline',
    padding: 0,
    cursor: 'pointer',
    '&::placeholder': {
      color: Color.gray05,
    },
    [blokkieTheme.mediaQueries.allPhone]: {
      width: '100%',
      fontSize: 16,
    },
  },
});

export default ShareLink;

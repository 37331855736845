/**
 *
 * CashbackCampaignsComponent
 *
 */

import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import CampaignLineItem from '../CampaignLineItem';
import { CashbackWrapper, Header } from '../Cashback';
import messages from './messages';
import { GiphyBox } from '../../components';
import { Content, Panel, TabItem, Tabs } from '../../styleguide';
import animationData from '../../../images/animations/green-dots.json';

/* eslint-disable react/prefer-stateless-function */
class CashbackCampaignsComponent extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = props;
    const oopsTitle = intl.formatMessage({
      id: 'app.components.CashbackCampaignsComponent.oopsTitle',
    });
    const oopsText = intl.formatMessage({
      id: 'app.components.CashbackCampaignsComponent.oopsText',
    });
    const oopsButton = intl.formatMessage({
      id: 'app.components.CashbackCampaignsComponent.oopsButton',
    });
    const noResultsTitle = intl.formatMessage({
      id: 'app.components.CashbackCampaignsComponent.noResultsTitle',
    });
    const noResultsText = intl.formatMessage({
      id: 'app.components.CashbackCampaignsComponent.noResultsText',
    });
    const showActiveCampaigns = true;

    this.state = {
      oopsTitle,
      oopsText,
      oopsButton,
      noResultsTitle,
      noResultsText,
      showActiveCampaigns,
    };

    this.props.loadCampaigns();
  }

  showCurrentCampaigns = () => {
    this.setState({
      showActiveCampaigns: true,
    });
  };

  showPastCampaigns = () => {
    this.setState({
      showActiveCampaigns: false,
    });
  };

  render() {
    return (
      <CashbackWrapper>
        <Header>
          <FormattedMessage {...messages.header} />
          <Tabs>
            <TabItem
              to="#"
              onClick={this.showCurrentCampaigns}
              active={this.state.showActiveCampaigns ? 1 : undefined}
            >
              <FormattedMessage {...messages.currentCampaignsTabName} />
            </TabItem>
            <TabItem
              to="#"
              onClick={this.showPastCampaigns}
              active={!this.state.showActiveCampaigns ? 1 : undefined}
            >
              <FormattedMessage {...messages.finishedCampaignsTabName} />
            </TabItem>
          </Tabs>
        </Header>
        <Content>
          {!!this.props.campaignsErrorMessage && (
            <GifjeContainer>
              <GifjeWrapper>
                <GiphyBox
                  gif="oops.gif"
                  onClick={() => {
                    this.props.loadCampaigns();
                  }}
                  title={this.state.oopsTitle}
                  text={this.state.oopsText}
                  button={this.state.oopsButton}
                />
              </GifjeWrapper>
            </GifjeContainer>
          )}
          {!!this.props.campaignsIsLoading && (
            <LoaderWrapper>
              <AnimationWrapper>
                <ReactBodymovin options={options} />
              </AnimationWrapper>
            </LoaderWrapper>
          )}
          {!this.props.campaignsErrorMessage &&
            !this.props.campaignsIsLoading &&
            this.props.campaigns.length === 0 && (
              <GifjeContainer>
                <GifjeWrapper>
                  <GiphyBox
                    gif="no-results.gif"
                    title={this.state.noResultsTitle}
                    text={this.state.noResultsText}
                  />
                </GifjeWrapper>
              </GifjeContainer>
            )}
          {!this.props.campaignsErrorMessage &&
            !this.props.campaignsIsLoading &&
            this.props.campaigns
              .filter((campaign) =>
                this.state.showActiveCampaigns
                  ? moment().isSameOrBefore(campaign.endDate, 'day')
                  : moment().isAfter(campaign.endDate, 'day'),
              )
              .map((campaign) => (
                <CampaignLineItem
                  isCampaignInFuture={moment().isBefore(campaign.startDate)}
                  key={campaign.id}
                  campaign={campaign}
                  showCampaignDetail={() =>
                    this.props.showCampaignDetail(campaign)
                  }
                />
              ))}
        </Content>
      </CashbackWrapper>
    );
  }
}

CashbackCampaignsComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  campaignsIsLoading: PropTypes.bool.isRequired,
  campaignsErrorMessage: PropTypes.string,
  loadCampaigns: PropTypes.func.isRequired,
  showCampaignDetail: PropTypes.func.isRequired,
};

export default injectIntl(CashbackCampaignsComponent);

const GifjeContainer = styled.div`
  width: 100%;
  height: 564px;
  padding-top: 116px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  @media ${(props) => props.theme.device.mobile} {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
  }
`;

const GifjeWrapper = styled.div`
  margin: 0 auto;
  width: 276px;
`;

const LoaderWrapper = styled(Panel)`
  position: relative;
  margin-bottom: 24px;
`;

const AnimationWrapper = styled.div`
  height: 72px;
  padding-top: 21px;
  padding-bottom: 21px;
  text-align: center;

  .react-bodymovin-container {
    height: 30px;
  }
`;

const options = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData,
};

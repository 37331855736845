import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  DashboardWrapper,
  Container,
  InnerContainer,
} from '../../../components/components';
import injectSaga from '../../../utils/injectSaga';
import saga from '../saga';
import EmptyLoader from '../../../components/Util/Loader';
import SmsSettings from '../../../components/Settings/Sms';
import { loadSettings, updateSettings } from '../actions';
import {
  selectSettingsData,
  selectSettingsLoading,
  selectSettingsLoadingError,
  selectSettingsLoadingErrorStatusCode,
  selectSettingsUpdatingSuccess,
  selectSettingsUpdatingError,
  selectSettingsUpdating,
} from '../selectors';
import { isEmpty } from '../../../utils';

export function SmsSettingsContainer(props) {
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(props.settingsData)) {
      props.loadSettings();
    }
  }, []);

  const gotoPortalOverview = () => {
    history.push('/');
  };

  const gotoSettingsDashboard = () => {
    history.push('/settings');
  };

  if (props.settingsLoading) {
    return <EmptyLoader />;
  }

  return (
    <DashboardWrapper>
      <Container>
        <InnerContainer>
          <SmsSettings
            gotoPortalOverview={gotoPortalOverview}
            gotoSettingsDashboard={gotoSettingsDashboard}
            settingsData={props.settingsData}
            settingsLoadingError={props.settingsLoadingError}
            settingsLoadingErrorStatusCode={
              props.settingsLoadingErrorStatusCode
            }
            updateSettings={props.updateSettings}
            settingsUpdatingSuccess={props.settingsUpdatingSuccess}
            settingsUpdatingError={props.settingsUpdatingError}
            isUpdating={props.isUpdating}
          />
        </InnerContainer>
      </Container>
    </DashboardWrapper>
  );
}

SmsSettingsContainer.propTypes = {
  settingsData: PropTypes.object,
  settingsLoading: PropTypes.bool,
  settingsLoadingError: PropTypes.bool.isRequired,
  settingsLoadingErrorStatusCode: PropTypes.number,
  loadSettings: PropTypes.func,
  updateSettings: PropTypes.func,
  settingsUpdatingSuccess: PropTypes.bool,
  settingsUpdatingError: PropTypes.bool,
  isUpdating: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loadSettings,
  settingsLoading: selectSettingsLoading,
  settingsLoadingError: selectSettingsLoadingError,
  settingsLoadingErrorStatusCode: selectSettingsLoadingErrorStatusCode,
  settingsData: selectSettingsData,
  settingsUpdatingSuccess: selectSettingsUpdatingSuccess,
  settingsUpdatingError: selectSettingsUpdatingError,
  isUpdating: selectSettingsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: () => dispatch(loadSettings()),
  updateSettings: (settingsData) => dispatch(updateSettings(settingsData)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'SmsSettingsContainer', saga });

export default compose(withSaga, withConnect)(SmsSettingsContainer);

export const selectSettingsData = (state) =>
  state.get('settings').get('data').toJS();
export const selectSettingsLoading = (state) =>
  state.get('settings').get('settingsLoading');
export const selectSettingsLoadingError = (state) =>
  state.get('settings').get('settingsLoadingError');
export const selectSettingsLoadingErrorStatusCode = (state) =>
  state.get('settings').get('settingsLoadingErrorStatusCode');
export const selectSettingsUpdatingSuccess = (state) =>
  state.get('settings').get('settingsUpdatingSuccess');
export const selectSettingsUpdatingError = (state) =>
  state.get('settings').get('settingsUpdatingError');
export const selectSettingsUpdating = (state) =>
  state.get('settings').get('settingsUpdating');
export const selectApiTokens = (state) =>
  state.get('settings').get('apiTokens').toJS();
export const selectApiTokensLoading = (state) =>
  state.get('settings').get('apiTokensLoading');
export const selectApiTokensLoadingSuccess = (state) =>
  state.get('settings').get('apiTokensLoadingSuccess');
export const selectApiTokensLoadingError = (state) =>
  state.get('settings').get('apiTokensLoadingError');

export const selectCreatingApiToken = (state) =>
  state.get('settings').get('creatingApiToken');
export const selectCreateApiTokenSuccess = (state) =>
  state.get('settings').get('createApiTokenSuccess');
export const selectCreateApiTokenError = (state) =>
  state.get('settings').get('createApiTokenError');

export const selectModifyingApiToken = (state) =>
  state.get('settings').get('modifyingApiToken');
export const selectModifyApiTokenSuccess = (state) =>
  state.get('settings').get('modifyApiTokenSuccess');
export const selectModifyApiTokenError = (state) =>
  state.get('settings').get('modifyApiTokenError');
export const selectModifiedApiToken = (state) =>
  state.get('settings').get('apiToken').toJS();

export const selectAuditLogs = (state) =>
  state.get('settings').get('auditLogs').toJS();
export const selectAuditLogsLoading = (state) =>
  state.get('settings').get('auditLogsLoading');
export const selectAuditLogsError = (state) =>
  state.get('settings').get('auditLogsError');

export const selectUsers = (state) => state.get('settings').get('users').toJS();
export const selectUsersLoading = (state) =>
  state.get('settings').get('usersLoading');
export const selectUsersError = (state) =>
  state.get('settings').get('usersError').toJS();

export const selectAddUserToActiveOrganisationSuccess = (state) =>
  state.get('settings').get('addUserToActiveOrganisationSuccess').toJS();
export const selectAddUserToActiveOrganisationLoading = (state) =>
  state.get('settings').get('addUserToActiveOrganisationLoading');
export const selectAddUserToActiveOrganisationError = (state) =>
  state.get('settings').get('addUserToActiveOrganisationError').toJS();

export const selectAddExistingUserToActiveOrganisationSuccess = (state) =>
  state
    .get('settings')
    .get('addExistingUserToActiveOrganisationSuccess')
    .toJS();
export const selectAddExistingUserToActiveOrganisationLoading = (state) =>
  state.get('settings').get('addExistingUserToActiveOrganisationLoading');
export const selectAddExistingUserToActiveOrganisationError = (state) =>
  state.get('settings').get('addExistingUserToActiveOrganisationError').toJS();

export const selectPermittedOrganisationsLoading = (state) =>
  state.get('settings').get('permittedOrganisationsLoading');
export const selectPermittedOrganisations = (state) =>
  state.get('settings').get('loadPermittedOrganisationsSuccess').toJS();
export const selectPermittedOrganisationsError = (state) =>
  state.get('settings').get('loadPermittedOrganisationsError').toJS();

export const selectPermittedOrganisationsUsers = (state) =>
  state.get('settings').get('permittedOrganisationsUsers').toJS();
export const selectPermittedOrganisationsUsersLoading = (state) =>
  state.get('settings').get('permittedOrganisationsUsersLoading');
export const selectPermittedOrganisationsUsersError = (state) =>
  state.get('settings').get('permittedOrganisationsUsersError').toJS();

export const selectUser = (state) => state.get('settings').get('user').toJS();
export const selectFetchUserLoading = (state) =>
  state.get('settings').get('fetchUserLoading');
export const selectFetchUserError = (state) =>
  state.get('settings').get('fetchUserError').toJS();
export const selectUpdateUserLoading = (state) =>
  state.get('settings').get('updateUserLoading');
export const selectUpdateUserError = (state) =>
  state.get('settings').get('updateUserError').toJS();

export const selectRequestPasswordResetMailError = (state) =>
  state.get('settings').get('requestPasswordResetMailError')?.toJS();
export const selectRequestPasswordResetMailSuccess = (state) =>
  state.get('settings').get('requestPasswordResetMailSuccess')?.toJS();

export const selectDeleteUserLoading = (state) =>
  state.get('settings').get('deleteUserLoading');
export const selectDeleteUserError = (state) =>
  state.get('settings').get('deleteUserError').toJS();

export const selectDeactivationLoading = (state) =>
  state.get('settings').get('deactivationLoading');
export const selectDeactivationSuccess = (state) =>
  state.get('settings').get('deactivationSuccess').toJS();
export const selectDeactivationError = (state) =>
  state.get('settings').get('deactivationError').toJS();

import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  blokkieTheme,
  BlokkieButton,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { useTranslation } from 'react-i18next';
import NavigationHeader from '../components/NavigationHeader';
import { Wrapper } from '../styledComponents';
import {
  capitalizeDuringTyping,
  capitalizeAndRemoveSpaces,
} from '../../../utils';
import { validNameChars } from '../../../utils/validNameChars';
import { InfoScreen } from '../InfoScreen';
import InputKeyboard from '../components/InputKeyboard';
import ButtonFooter from '../components/ButtonFooter';
import { useOnboardingPageView } from '../../../hooks';
import Title from '../Title';

interface NickNameProps {
  saveNickName: (name: string) => void;
}

function NickName({ saveNickName }: NickNameProps): JSX.Element {
  useOnboardingPageView('nickname');

  const { t } = useTranslation();

  const [nickName, setNickName] = useState('');
  const [info, setInfo] = useState(false);

  const showInfo = (): void => setInfo(!info);

  const invalidName = (): boolean => nickName.length < 2;

  const submitNickName = (): void => {
    const capitalizedName = capitalizeAndRemoveSpaces(nickName);
    saveNickName(capitalizedName);
  };

  const onHandleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    const { key } = e;
    const ctrlV = (e.ctrlKey || e.metaKey) && key === 'v';
    const ctrlC = (e.ctrlKey || e.metaKey) && key === 'c';

    if (
      key === 'ArrowLeft' ||
      key === 'RightArrow' ||
      key === 'Backspace' ||
      key === 'Tab' ||
      ctrlV ||
      ctrlC
    ) {
      return;
    }

    if (validNameChars.indexOf(key) === -1) {
      e.preventDefault();
    }

    if (key === 'Enter' && !invalidName()) {
      submitNickName();
    }
  };

  return (
    <>
      <Wrapper>
        <NavigationHeader clickRight={showInfo} />
        <Title>{t(`onboarding.nickname.title`)}</Title>
        <InputKeyboard
          data-test-id="Onboarding-NickName-Input"
          placeholder={t(`onboarding.nickname.placeholder`)}
          value={nickName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNickName(capitalizeDuringTyping(e.target.value))
          }
          onKeyDown={onHandleKeyDown}
          autoFocus
          maxLength={45}
          relatedButtonId="button-footer"
        />

        <ButtonFooter>
          <BlokkieButton
            variant="primary"
            size="large"
            data-test-id="Onboarding-NickName-NextButton"
            onClick={submitNickName}
            disabled={invalidName()}
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={NickName.name}
      >
        <BlokkieTypography variant="pl500">
          {t(`onboarding.nickname.infoscreen.description`)}
        </BlokkieTypography>
      </InfoScreen>
    </>
  );
}

export default injectIntl(NickName);

/*
 * CashbackCampaignsComponent Messages
 *
 * This contains all the text for the CashbackCampaignsComponent component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.CashbackCampaignsComponent.header',
    defaultMessage: 'Tikkie Terug campagnes',
  },
  oopsTitle: {
    id: 'app.components.CashbackCampaignsComponent.oopsTitle',
    defaultMessage: 'Oeps!',
  },
  oopsText: {
    id: 'app.components.CashbackCampaignsComponent.oopsText',
    defaultMessage: 'Er ging iets even helemaal mis ...',
  },
  oopsButton: {
    id: 'app.components.CashbackCampaignsComponent.oopsButton',
    defaultMessage: 'Probeer opnieuw',
  },
  noResultsTitle: {
    id: 'app.components.CashbackCampaignsComponent.noResultsTitle',
    defaultMessage: 'Er zijn nog geen Campagnes.',
  },
  noResultsText: {
    id: 'app.components.CashbackCampaignsComponent.noResultsText',
    defaultMessage: 'Er zijn geen Tikkie Terug campagnes.',
  },
  finishedCampaignsTabName: {
    id: 'app.components.CashbackCampaignsComponent.finishedCampaignsTabName',
    defaultMessage: 'Afgelopen',
  },
  currentCampaignsTabName: {
    id: 'app.components.CashbackCampaignsComponent.finishedCampaignsTabName',
    defaultMessage: 'Actief en gepland',
  },
});

import React from 'react';
import { createUseStyles } from 'react-jss';
import { BlokkieIcon, blokkieTheme } from '@tikkie/blokkie';

import { Radii } from '../../styleguide';
import { OnboardingSecondaryButton } from '../styledComponents';

interface RadioButtonProps {
  id: string;
  checked?: boolean;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  'data-test-id'?: string;
}

function RadioButton({
  id,
  checked,
  onClick,
  text,
  'data-test-id': dataTestId,
}: RadioButtonProps): JSX.Element {
  const classes = useStyles();

  return (
    <OnboardingSecondaryButton
      className={classes.line}
      onClick={onClick}
      id={id}
      data-test-id={dataTestId}
    >
      {checked && (
        <div
          data-test-id={`${dataTestId}-icon-container`}
          className={[classes.activeRadioOption, 'radio-is-selected'].join(' ')}
        >
          <BlokkieIcon variant="check" color={blokkieTheme.colors.grey.white} />
        </div>
      )}
      {!checked && (
        <div
          data-test-id={`${dataTestId}-icon-container`}
          className={[classes.radioOption, 'radio-is-not-selected'].join(' ')}
        />
      )}
      {/* Not using blokkie components here because it gave weird results */}
      <div
        style={{
          display: 'flex',
          whiteSpace: 'pre-line',
        }}
      >
        {text}
      </div>
    </OnboardingSecondaryButton>
  );
}

const useStyles = createUseStyles({
  line: {
    textAlign: 'left',
    border: 'none',
    padding: '0px',
    display: 'flex !important',
    lineHeight: 'unset !important',
    height: 'unset !important',
    alignItems: 'center',
  },
  radioOption: {
    display: 'flex',
    float: 'left',
    flexShrink: 0,
    width: '24px',
    height: '24px',
    borderRadius: Radii.s24,
    border: `solid 2px ${blokkieTheme.colors.transparent.black['33']}`,
    margin: '0px 8px 0px 0px',
  },
  activeRadioOption: {
    extend: 'radioOption',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: blokkieTheme.colors.primary.green,
    border: `solid 2px ${blokkieTheme.colors.primary.green}`,
  },
});

export default RadioButton;

/**
 *
 *
 *Transaction Date Filter Component
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import 'react-dates/lib/css/_datepicker.css';
import { DropdownButton, RadioButton } from '../../../components';
import messages from './messages';
import { PrimaryButton, TertiaryButton, Overlay } from '../../../styleguide';
import {
  getDatesByLabel,
  getLabelByDate,
  customDateText,
} from './calendarUtils';
import DropdownMenu from '../../../Dropdown/DropdownMenu';
import DropdownHeader from '../../../Dropdown/DropdownHeader';
import DropdownFooter from '../../../Dropdown/DropdownFooter';
import { DatePicker } from '../../../DatePicker/DatePicker';

const TransactionDateFilterWrapper = styled.div`
  display: flex;
`;
class TransactionDateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTransactionDateMenu: false,
      showCalendar: false,
      filterTransactionDateOption: '',
      startDate: null,
      endDate: moment(),
      focusedInput: 'startDate',
      isFiltered: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { startDate, endDate } = this.props;
    if (startDate && endDate) {
      this.setState({
        filterTransactionDateOption: getLabelByDate(startDate, endDate),
        startDate: moment(startDate),
        endDate: moment(endDate),
        isFiltered: true,
      });
      this.props.transactionsFiltersCallback(
        this.state.filterTransactionDateOption,
      );
    }
  }

  hideTransactionDateMenu = (event) => {
    const { startDate, endDate } = this.props;
    this.toggleTransactionDateMenu();
    if (!this.state.isFiltered) {
      this.resetTransactionDateSelection(event);
    } else {
      this.setState({
        filterTransactionDateOption: getLabelByDate(startDate, endDate),
      });
      this.props.transactionsFiltersCallback(
        this.state.filterTransactionDateOption,
      );
    }
  };

  toggleTransactionDateMenu = () => {
    this.setState((prevState) => ({
      showTransactionDateMenu: !prevState.showTransactionDateMenu,
    }));
  };

  clearTransactionDateSelection = (event) => {
    event.stopPropagation();
    this.setState({
      filterTransactionDateOption: '',
      isFiltered: false,
      startDate: null,
      endDate: moment(),
    });
    this.props.onSubmit(null, null);
    this.props.transactionsFiltersCallback(
      this.state.filterTransactionDateOption,
    );
  };

  resetTransactionDateSelection = (event) => {
    event.stopPropagation();
    this.setState({
      filterTransactionDateOption: '',
    });
    if (isMobileOnly) {
      this.setState({ showTransactionDateMenu: false });
    }
    this.props.transactionsFiltersCallback(
      this.state.filterTransactionDateOption,
    );
  };

  setTransactionDateFilter = (option) => {
    this.setState({ filterTransactionDateOption: option });
    this.props.transactionsFiltersCallback(
      this.state.filterTransactionDateOption,
    );
  };

  hasTransactionDateOption = (option) =>
    this.state.filterTransactionDateOption === option;

  submitFilter = () => {
    this.toggleTransactionDateMenu();
    const selectedDates = this.getSelectedDates(
      this.state.filterTransactionDateOption,
    );
    this.props.transactionsFiltersCallback(
      this.state.filterTransactionDateOption,
    );

    if (selectedDates.fromDate && selectedDates.fromDate) {
      this.props.onSubmit(selectedDates.fromDate, selectedDates.toDate);
      this.setState({
        isFiltered: true,
      });
    }
  };

  getCustomDateText = () =>
    customDateText(this.state.startDate, this.state.endDate);

  getFilterMenuTitle = (calendar) => {
    if (calendar === '') {
      return calendar;
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      calendar === 'customDate'
    ) {
      return this.getCustomDateText();
    }
    const label = this.props.intl.formatMessage({
      id: `app.components.TransactionDateFilter.${calendar}`,
    });
    return label;
  };

  getSelectedDates = (option) => getDatesByLabel(option);

  toggleCalendar = () => {
    this.setState((prevState) => ({ showCalendar: !prevState.showCalendar }));
  };

  hideCalendar = () => {
    const { startDate, endDate } = this.props;
    if (this.props.startDate && this.props.endDate) {
      this.setState({
        showCalendar: false,
        filterTransactionDateOption: getLabelByDate(startDate, endDate),
        startDate: moment(startDate),
        endDate: moment(endDate),
      });
    } else {
      this.setState({
        showCalendar: false,
        filterTransactionDateOption: '',
        startDate: null,
      });
    }
  };

  applyCustomDateAndCloseCalendar = () => {
    this.toggleCalendar();
    const filterDateFrom = this.state.startDate
      .subtract(1, 'day')
      .endOf('day')
      .add(1, 'second')
      .valueOf();
    const filterDateTo = this.state.endDate.endOf('day').valueOf();
    this.props.onSubmit(filterDateFrom, filterDateTo);
    this.setState({
      isFiltered: true,
    });
  };

  isCustomDateSet = () =>
    this.state.filterTransactionDateOption === 'customDate' &&
    this.state.startDate !== null &&
    this.state.endDate !== null;

  render() {
    const { showTransactionDateMenu, showCalendar } = this.state;
    return (
      <TransactionDateFilterWrapper id="dateFilter">
        <DropdownButton
          active={showTransactionDateMenu}
          selected={this.getFilterMenuTitle(
            this.state.filterTransactionDateOption,
          )}
          onClick={this.toggleTransactionDateMenu}
          onReset={this.clearTransactionDateSelection}
        >
          <FormattedMessage {...messages.filterName} />
        </DropdownButton>
        {showCalendar && <Overlay onClick={this.hideCalendar} />}
        <DropdownMenu
          show={showTransactionDateMenu}
          handleOutsideClick={this.hideTransactionDateMenu}
        >
          <DropdownHeader>
            <FormattedMessage {...messages.filterName} />
            <TertiaryButton
              id="clearDateFilter"
              onClick={this.resetTransactionDateSelection}
            >
              <FormattedMessage {...messages.reset} />
            </TertiaryButton>
          </DropdownHeader>
          <RadioButton
            id="filterToday"
            onClick={() => this.setTransactionDateFilter('today')}
            checked={this.hasTransactionDateOption('today')}
          >
            <FormattedMessage {...messages.today} />
          </RadioButton>
          <RadioButton
            id="filterWeek"
            onClick={() => this.setTransactionDateFilter('thisWeek')}
            checked={this.hasTransactionDateOption('thisWeek')}
          >
            <FormattedMessage {...messages.thisWeek} />
          </RadioButton>
          <RadioButton
            id="filterMonth"
            onClick={() => this.setTransactionDateFilter('thisMonth')}
            checked={this.hasTransactionDateOption('thisMonth')}
          >
            <FormattedMessage {...messages.thisMonth} />
          </RadioButton>
          <RadioButton
            id="filterYear"
            onClick={() => this.setTransactionDateFilter('thisYear')}
            checked={this.hasTransactionDateOption('thisYear')}
          >
            <FormattedMessage {...messages.thisYear} />
          </RadioButton>
          <RadioButton
            id="filterCustomDate"
            onClick={() => {
              this.setTransactionDateFilter('customDate');
              this.toggleTransactionDateMenu();
              this.toggleCalendar();
            }}
            checked={this.hasTransactionDateOption('customDate')}
          >
            <FormattedMessage {...messages.customDate} />
          </RadioButton>
          <DropdownFooter>
            <PrimaryButton
              id="applyDateFilterButton"
              onClick={() => this.submitFilter()}
            >
              <FormattedMessage {...messages.filter} />
            </PrimaryButton>
          </DropdownFooter>
        </DropdownMenu>
        {showCalendar && (
          <DatePicker
            toggle={() => {
              this.toggleCalendar();
              this.toggleTransactionDateMenu();
            }}
            startDate={this.state.startDate}
            setStartDate={(date) => {
              this.setState({ startDate: date });
            }}
            endDate={this.state.endDate}
            setEndDate={(date) => {
              this.setState({ endDate: date });
            }}
            focusedInput={this.state.focusedInput}
            setFocusedInput={(focusedInput) =>
              this.setState({
                focusedInput: focusedInput || 'startDate',
              })
            }
            disabled={!this.isCustomDateSet()}
            apply={this.applyCustomDateAndCloseCalendar}
            reset={() => {
              this.setState({ startDate: null });
              this.setState({ endDate: null });
            }}
            isMobile={isMobileOnly}
            noMinimumNights
          />
        )}
      </TransactionDateFilterWrapper>
    );
  }
}

TransactionDateFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  intl: PropTypes.object.isRequired,
  transactionsFiltersCallback: PropTypes.func,
};

export default injectIntl(TransactionDateFilter);

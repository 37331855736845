import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Footer = styled.div`
  height: 56px;
  line-height: 56px;
  padding: 0 24px;
  margin-bottom: 16px;
  cursor: pointer;
  z-index: 9999;
  background-color: #ffffff;

  button {
    height: 40px;
    line-height: 30px;
    width: 100%;
  }

  @media ${(props) => props.theme.device.mobile} {
    position: fixed;
    height: 80px;
    line-height: 80px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 18px;
    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.05);
    z-index: 9999;
    margin-bottom: 0;

    button {
      margin: 0px;
      height: 48px;
    }
  }
`;

function DropdownFooter(props) {
  return <Footer>{props.children}</Footer>;
}

DropdownFooter.propTypes = {
  children: PropTypes.node,
};

export default DropdownFooter;

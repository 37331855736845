import { fromJS } from 'immutable';

import {
  SEND_BULK_UPLOAD,
  LOAD_BULK_FILE,
  LOAD_BULK_FILE_ERROR,
  LOAD_BULK_FILE_SUCCESS,
  SEND_BULK_UPLOAD_ERROR,
  SEND_BULK_UPLOAD_SUCCESS,
  LOAD_PAYMENT_REQUESTS,
  LOAD_PAYMENT_REQUESTS_ERROR,
  LOAD_PAYMENT_REQUESTS_SUCCESS,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
  CLEAR_DATA,
} from './constants';

const initialState = fromJS({
  isSendingBulkUpload: false,
  sendBulkUploadErrorStatus: 0,
  sendBulkUploadErrorMessage: null,
  sendBulkUploadErrorValidationDetails: null,
  bulkFile: {},
  bulkFileIsLoading: false,
  bulkFileErrorMessage: null,
  paymentRequests: {},
  paymentRequestsIsLoading: false,
  paymentRequestsErrorMessage: null,
  filterDateFrom: 0,
  filterDateTo: 0,
  filterDateLabel: '',
  filterStatus: [],
  filterSearch: '',
});

export default function bulkListContainerReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_BULK_FILE:
      return state
        .set('bulkFileIsLoading', true)
        .set('bulkFileErrorMessage', null)
        .set('bulkFile', fromJS({}));
    case LOAD_BULK_FILE_SUCCESS:
      return state
        .set('bulkFileIsLoading', false)
        .set('bulkFileErrorMessage', null)
        .set('bulkFile', fromJS(action.payload.bulkFile));
    case LOAD_BULK_FILE_ERROR:
      return state
        .set('bulkFileIsLoading', false)
        .set('bulkFileErrorMessage', action.payload.error)
        .set('bulkFile', fromJS({}));
    case SEND_BULK_UPLOAD:
      return state
        .set('isSendingBulkUpload', true)
        .set('sendBulkUploadErrorStatus', 0)
        .set('sendBulkUploadErrorMessage', null)
        .set('sendBulkUploadErrorValidationDetails', null);
    case SEND_BULK_UPLOAD_SUCCESS:
      return state
        .set('isSendingBulkUpload', false)
        .set('sendBulkUploadErrorStatus', 0)
        .set('sendBulkUploadErrorMessage', null)
        .set('sendBulkUploadErrorValidationDetails', null);
    case SEND_BULK_UPLOAD_ERROR:
      return state
        .set('isSendingBulkUpload', false)
        .set('sendBulkUploadErrorStatus', action.payload.status)
        .set('sendBulkUploadErrorMessage', action.payload.error)
        .set('sendBulkUploadErrorValidationDetails', action.payload.details);
    case LOAD_PAYMENT_REQUESTS:
      return state
        .set('filterDateFrom', action.payload.from)
        .set('filterDateTo', action.payload.to)
        .set('filterDateLabel', action.payload.label)
        .set('filterStatus', fromJS(action.payload.status))
        .set('filterSearch', action.payload.search)
        .set('paymentRequestsIsLoading', true)
        .set('paymentRequestsErrorMessage', null)
        .set('paymentRequests', fromJS({}));
    case LOAD_PAYMENT_REQUESTS_SUCCESS:
      return state
        .set('paymentRequestsIsLoading', false)
        .set('paymentRequestsErrorMessage', null)
        .set('paymentRequests', fromJS(action.payload.paymentRequests));
    case LOAD_PAYMENT_REQUESTS_ERROR:
      return state
        .set('paymentRequestsIsLoading', false)
        .set('paymentRequestsErrorMessage', action.payload.error)
        .set('paymentRequests', fromJS({}));
    case CLEAR_DATA:
      return initialState;
    case DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS:
    default:
      return state;
  }
}

export { initialState };

import {
  LOAD_PAYMENT_REQUESTS,
  LOAD_PAYMENT_REQUESTS_ERROR,
  LOAD_PAYMENT_REQUESTS_SUCCESS,
  DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
  RESET_PAYMENT_REQUESTS,
} from './constants';

export function loadPaymentRequests(
  page,
  label,
  from,
  to,
  status,
  search,
  paymentRequestBatchId,
) {
  return {
    type: LOAD_PAYMENT_REQUESTS,
    payload: {
      page,
      label,
      from,
      to,
      status,
      search,
      paymentRequestBatchId,
    },
  };
}

export function loadPaymentRequestsSuccess(paymentRequests) {
  return {
    type: LOAD_PAYMENT_REQUESTS_SUCCESS,
    payload: {
      paymentRequests,
    },
  };
}

export function loadPaymentRequestsError(error) {
  return {
    type: LOAD_PAYMENT_REQUESTS_ERROR,
    payload: {
      error,
    },
  };
}

export function downloadMultiplePaymentRequests(
  label,
  from,
  to,
  status,
  search,
  allSelected,
  selectedPaymentRequests,
  csvType,
  paymentRequestBatchId,
) {
  return {
    type: DOWNLOAD_MULTIPLE_PAYMENT_REQUESTS,
    payload: {
      label,
      from,
      to,
      status,
      search,
      allSelected,
      selectedPaymentRequests,
      csvType,
      paymentRequestBatchId,
    },
  };
}

export function resetPaymentRequests() {
  return {
    type: RESET_PAYMENT_REQUESTS,
  };
}

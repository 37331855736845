import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useTranslation } from 'react-i18next';
import {
  BlokkieButton,
  blokkieTheme,
  BlokkieTypography,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';
import { Wrapper } from '../styledComponents';
import { InfoScreen } from '../InfoScreen';
import { Applicant2InfoScreen } from '../AuthorizedToSign/Applicant2InfoScreen';
import { ns } from '../../../i18n/i18n';
import NavigationHeader from '../components/NavigationHeader';
import ButtonFooter from '../components/ButtonFooter';
import InputKeyboard from '../components/InputKeyboard';
import { useOnboardingPageView } from '../../../hooks';

interface GetEmailProps {
  nextStep: () => void;
  onboardingData: {
    email: string;
  };
  prevStep: () => void;
  sendEmail: (email: string) => void;
  simplifiedHeader?: boolean;
  forApplicant2?: boolean;
}

function GetEmail({
  nextStep,
  onboardingData,
  prevStep,
  sendEmail,
  simplifiedHeader,
  forApplicant2,
}: GetEmailProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState(onboardingData.email);
  const [info, setInfo] = useState(false);
  useOnboardingPageView('get-email');

  const disableButton = (): boolean =>
    email === null ||
    !email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
    );

  const next = (): void => {
    sendEmail(email);
    if (nextStep) nextStep();
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    const code = event.key;
    if (code === 'Enter' && !disableButton()) {
      next();
    }
  };

  const showInfo = (): void => setInfo(!info);

  const titleBVariation = forApplicant2 ? 'applicant2' : 'default';

  return (
    <>
      <Wrapper>
        <NavigationHeader
          clickLeft={(prevStep as () => void) ?? undefined}
          clickRight={showInfo}
        />
        {!simplifiedHeader && (
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.veryDark,
              marginTop: blokkieTheme.spacing(2),
            }}
            variant="h3"
          >
            {t(`onboarding.email.getEmail.titleA`)}
          </BlokkieTypography>
        )}
        <BlokkieTypography
          style={{
            color: blokkieTheme.colors.grey.veryDark,
          }}
          variant="h3"
        >
          {t(`onboarding.email.getEmail.titleB.${titleBVariation}`, {
            ns: ns.common,
          })}
        </BlokkieTypography>
        {forApplicant2 && (
          <BlokkieTypography
            style={{
              color: blokkieTheme.colors.grey.dark,
              marginTop: blokkieTheme.spacing(),
            }}
            variant="ps700"
          >
            {t('onboarding.2ndApplicantDetails.email.description')}
          </BlokkieTypography>
        )}
        <InputKeyboard
          name="email"
          value={email}
          onKeyDown={handleOnKeyDown}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          placeholder={t('onboarding.email.placeholder')}
          autoComplete="off"
          autoCorrect="off"
          autoFocus
          maxLength={50}
          data-test-id="Onboarding-GetEmail-EmailAddress"
          relatedButtonId="button-footer"
        />
        <ButtonFooter>
          <BlokkieButton
            disabled={disableButton()}
            onClick={next}
            data-test-id="Onboarding-GetEmail-NextButton"
            variant="primary"
            size="large"
          >
            {t('next')}
          </BlokkieButton>
        </ButtonFooter>
      </Wrapper>
      <InfoScreen
        show={info && !forApplicant2}
        title={t('onboarding.infoscreenTitle')}
        onClick={showInfo}
        fromPage={GetEmail.name}
      >
        <div className={classes.infoContentContainer}>
          <BlokkieTypography
            variant="pl500"
            style={{
              marginBottom: blokkieTheme.spacing(3),
            }}
          >
            {t(`onboarding.email.getEmail.info.importance`, {
              ns: ns.common,
            })}
          </BlokkieTypography>
          <BlokkieTypography variant="pl500">
            {t(`onboarding.email.getEmail.info.because`, {
              ns: ns.common,
            })}
          </BlokkieTypography>
          <BlokkieTypography variant="pl500">
            {t(`onboarding.email.getEmail.info.reason1`, {
              ns: ns.common,
            })}
          </BlokkieTypography>
          <BlokkieTypography variant="pl500">
            {t(`onboarding.email.getEmail.info.reason2`, {
              ns: ns.common,
            })}
          </BlokkieTypography>
          <BlokkieTypography variant="pl500">
            {t(`onboarding.email.getEmail.info.reason3`, {
              ns: ns.common,
            })}
          </BlokkieTypography>
        </div>
      </InfoScreen>
      <Applicant2InfoScreen
        show={info && !!forApplicant2}
        showInfo={showInfo}
        fromPage={`${GetEmail.name}_applicant2`}
      />
    </>
  );
}

const useStyles = createUseStyles({
  infoContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: blokkieTheme.colors.grey.veryDark,
  },
});

export default injectIntl(GetEmail);

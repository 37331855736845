import { takeLatest, call, put, all } from 'redux-saga/effects';

import request from '../../../utils/request';
import config from '../../../config';
import {
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_SUCCESS,
  DOWNLOAD_PAYMENTS,
  FETCH_QR_CODE,
} from './constants';

import {
  loadCampaignError,
  loadCampaignSuccess,
  fetchQRCodeSuccess,
  fetchQRCodeError,
} from './actions';

export default function* watcherSaga() {
  yield all([
    takeLatest(LOAD_CAMPAIGN, loadCampaignSaga),
    takeLatest(DOWNLOAD_PAYMENTS, downloadPaymentsSaga),
    takeLatest(FETCH_QR_CODE, fetchQRCodeSaga),
    takeLatest(LOAD_CAMPAIGN_SUCCESS, fetchQRCodeSaga),
  ]);
}

export function* loadCampaignSaga(action) {
  const { campaignId } = action.payload;

  const requestUrl = `${config.API_URL}/ms/cashback/campaigns`;

  try {
    const response = yield call(request, requestUrl, {
      credentials: 'include',
    });
    const result =
      response.length > 0 && response.find((i) => i.id === campaignId);
    if (result) yield put(loadCampaignSuccess(result));
    else yield put(loadCampaignError('Not Found'));
  } catch (err) {
    yield put(loadCampaignError(err.message));
  }
}

export function* downloadPaymentsSaga(action) {
  const { campaignId } = action.payload;
  const url = `${config.API_URL}/ms/cashback/campaign/${campaignId}/payments`;
  window.open(url, '_blank');
}

export function* fetchQRCodeSaga(action) {
  const requestUrl = `${config.QR_SERVICE_URL}/qrcode`;
  const body = {
    url: action.payload.campaign.shareUrl,
    size: 30,
    style: 'round',
  };
  try {
    const response = yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    yield put(fetchQRCodeSuccess(response.qrCodeImage));
  } catch (err) {
    yield put(fetchQRCodeError(err.message));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'amber';
import { FooterContent, FooterWrapper } from '../styledComponents';

export function UpdateFooter({ show, onCancel, onUpdate, updateDisabled }) {
  return (
    <FooterWrapper slideIn={show} data-test-id="user-detail-footer">
      {show && (
        <FooterContent>
          <Button
            type="secondary"
            dataTestId="user-cancel-button"
            onClick={onCancel}
          >
            Annuleren
          </Button>
          <Button
            type="primary"
            dataTestId="user-save-button"
            onClick={onUpdate}
            disabled={updateDisabled}
          >
            Wijzigingen opslaan
          </Button>
        </FooterContent>
      )}
    </FooterWrapper>
  );
}

UpdateFooter.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  updateDisabled: PropTypes.bool.isRequired,
};
